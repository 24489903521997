import React, { useEffect, useState, useRef } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useStyles } from './FilterComponent.style'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'

export const FilterComponent = ({
  performSearch,
  searchValue,
  placeholder,
  isRemote
}) => {
  const [searchKey, setSearchKey] = useState(searchValue)
  const classes = useStyles()

  const textInput = useRef(null)

  const handleSearchKeyPress = (searchKey = '') => {
    performSearch(searchKey.trim())
  }

  useEffect(() => {
    setSearchKey('')
  }, [isRemote])

  return (
    <div>
      <div className={classes.form}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          autoFocus
          inputRef={textInput}
          placeholder={placeholder}
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            handleSearchKeyPress(e.target.value)
          }}
          className={classes.autocomplete}
        />
        {searchKey.trim() !== '' && (
          <div className={classes.containerButton}>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => {
                setSearchKey('')
                handleSearchKeyPress()
                textInput.current.focus()
              }}>
              <ClearIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
export default FilterComponent
