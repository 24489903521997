import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox
} from '@material-ui/core'
import { IMaskInput } from 'react-imask'
import { ROUTES } from '~/views/routes'
import { popupWindow } from '~/views/utils/popupWindow'
import { isNotIntegerNumber } from 'views/utils/functions'
import { useStyles } from './AccountCreationDialog.style'

const COUNTRY = process.env.REACT_APP_COUNTRY_CODE

const validationSchema = (isForeign) =>
  Yup.object().shape({
    FirstName: Yup.string().required('Ingrese su nombre'),
    LastName: Yup.string().required('Ingrese su apellido'),
    DNI: Yup.string()
      .required(
        isForeign
          ? 'Ingrese su número de identificación'
          : `${COUNTRY === 'ar' ? 'Ingrese su DNI' : 'Ingrese su Cédula de Identidad'}`
      )
      .min(
        isForeign ? 5 : 7,
        isForeign
          ? 'Debe ingresar al menos 5 caracteres'
          : 'Debe ingresar al menos 7 dígitos'
      ),
    Email: Yup.string()
      .required('Ingrese su email')
      .email('Ingrese un correo válido'),
    EmailConfirm: Yup.string()
      .required('Ingrese su email nuevamente')
      .email('Ingrese un correo válido')
      .oneOf([Yup.ref('Email'), null], 'Los emails no coinciden')
  })

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, value, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={`${
        COUNTRY === 'ar'
          ? value?.length <= 9
            ? '0.000.0000'
            : '00.000.000'
          : '0000000-0'
      }`}
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  )
})
const validateValues = (values, isForeign) => {
  for (const key in values) {
    if (!isForeign) {
      if (key === 'DNI') {
        values[key] = values[key].replace(/[.-]/g, '')
      }
      if (COUNTRY === 'ar') {
        values.IdentificationType = 'DNI'
      } else {
        values.IdentificationType = 'CI'
      }
    } else {
      values.IdentificationType = 'Otro'
    }
    if (key !== 'TermsAccepted') {
      values[key] = values[key].trim()
    }
  }
}

const handleSearchKeyPress = (event, onSubmit, values, isForeign) => {
  if (event.key === 'Enter') {
    validateValues(values, isForeign)
    onSubmit()
  }
}

export const CreateAccountForm = ({
  formRef,
  onSubmit,
  values,
  pending,
  returnToLoginButton,
  returnToLogin
}) => {
  const classes = useStyles()
  const [isForeign, setIsForeign] = useState(false)
  const [labelId, setLabelId] = useState(
    COUNTRY === 'ar' ? 'DNI' : 'Cédula de Identidad'
  )

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={values}
      validationSchema={validationSchema(isForeign)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {returnToLoginButton === false && (
            <div>
              <TextField
                label={
                  (errors.FirstName && touched.FirstName && errors.FirstName) ||
                  'Nombre'
                }
                className={classes.textField}
                name='FirstName'
                disabled={isSubmitting}
                value={values.FirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.FirstName && Boolean(errors.FirstName)}
                fullWidth
                size='small'
                required
                variant='outlined'
                autoFocus
              />
              <TextField
                label={
                  (errors.LastName && touched.LastName && errors.LastName) ||
                  'Apellido'
                }
                className={classes.textField}
                name='LastName'
                disabled={isSubmitting}
                value={values.LastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.LastName && Boolean(errors.LastName)}
                fullWidth
                size='small'
                required
                variant='outlined'
              />
              <FormControl variant='outlined' className={classes.fieldRow}>
                <InputLabel id='type-id'>Tipo de Identificación</InputLabel>
                <Select
                  labelId='type-id'
                  className={classes.field}
                  style={{ marginRight: 8 }}
                  label='Tipo de Identificación'
                  value={isForeign}
                  required
                  onChange={(v) => {
                    setIsForeign(v?.target?.value)
                    values.DNI = ''
                    delete errors?.DNI
                    v.target.value
                      ? setLabelId('Número de identificación')
                      : setLabelId(
                          COUNTRY === 'ar' ? 'DNI' : 'Cédula de Identidad'
                        )
                  }}>
                  <MenuItem value={false}>
                    {COUNTRY === 'ar' ? 'DNI' : 'Cédula de Identidad'}
                  </MenuItem>
                  <MenuItem value={true}>{'Otro'}</MenuItem>
                </Select>
                <TextField
                  label={(errors.DNI && touched.DNI && errors.DNI) || labelId}
                  name='DNI'
                  disabled={isSubmitting}
                  value={values.DNI}
                  autoComplete='off'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: isForeign ? 20 : null }}
                  InputProps={{
                    inputComponent: isForeign ? undefined : TextMaskCustom,
                    value: values.DNI,
                    onChange: handleChange
                  }}
                  onKeyPress={(e) => !isForeign && isNotIntegerNumber(e)}
                  onKeyDown={(e) => !isForeign && isNotIntegerNumber(e)}
                  error={touched.DNI && Boolean(errors.DNI)}
                  fullWidth
                  size='small'
                  required
                  variant='outlined'
                />
              </FormControl>
              <TextField
                label={
                  (errors.Email && touched.Email && errors.Email) || 'Correo'
                }
                className={classes.textField}
                name='Email'
                disabled={isSubmitting}
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Email && Boolean(errors.Email)}
                fullWidth
                size='small'
                required
                variant='outlined'
              />
              <TextField
                label={
                  (errors.EmailConfirm &&
                    touched.EmailConfirm &&
                    errors.EmailConfirm) ||
                  'Repita su Email'
                }
                className={classes.textField}
                name='EmailConfirm'
                disabled={isSubmitting}
                value={values.EmailConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.EmailConfirm && Boolean(errors.EmailConfirm)}
                fullWidth
                onKeyPress={(e) =>
                  handleSearchKeyPress(e, handleSubmit, values, isForeign)
                }
                size='small'
                required
                variant='outlined'
              />
              <div className={classes.checkContainer}>
                <Checkbox
                  checked={values.TermsAccepted}
                  onChange={handleChange}
                  name='TermsAccepted'
                  className={classes.check}
                />
                <Typography
                  variant='body1'
                  color='textSecondary'
                  className={classes.checkLabel}>
                  {'He leído, entendido y aceptado los'}&nbsp;
                </Typography>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  onClick={() =>
                    popupWindow('#' + ROUTES.PUBLIC.TERMS, 'Terms', 700, 400)
                  }
                  className={classes.checkLabelTerms}>
                  {'Términos y Condiciones'}
                </Typography>
              </div>
            </div>
          )}
          <div className={classes.buttonContainer}>
            {returnToLoginButton ? (
              <Button
                autoFocus
                variant={'contained'}
                onClick={returnToLogin}
                className={classes.login}>
                {'Volver a Iniciar Sesión'}
              </Button>
            ) : (
              <Button
                autoFocus
                variant={'contained'}
                color='primary'
                onClick={() => {
                  validateValues(values, isForeign)
                  handleSubmit()
                }}
                className={classes.login}
                disabled={isSubmitting || !values.TermsAccepted || pending}>
                {isSubmitting || pending ? (
                  <CircularProgress size={24} />
                ) : (
                  'Crear Cuenta'
                )}
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default CreateAccountForm
