import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDrawer, AppToolbar } from '~/views/shared'
import { closeDrawer } from '~/state/modules/app'
import { useStyles } from './AppContainer.style'
import { loadProfile } from '~/state/modules/profiles'

export const AppContainer = ({ children, title }) => {
  const classes = useStyles()
  const { isDrawerOpen } = useSelector((state) => state.app)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadProfile())
  }, [dispatch])

  return (
    <main className={classes.container}>
      <div className={classes.sideBar}>
        <AppDrawer
          closeDrawer={() => dispatch(closeDrawer())}
          isOpen={isDrawerOpen}
        />
      </div>
      <div className={classes.appContent}>
        <AppToolbar title={`ticketPass Manager`} />
        <div className={classes.content}>{children}</div>
      </div>
    </main>
  )
}
