import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './UsersList.style'
import { Typography } from '@material-ui/core'
import { OrganizerConfirmationDialog } from '../OrganizerConfirmationDialog'
import { showSnackbarError } from 'views/utils/snackbar'

export const UsersList = ({
  onSuccess,
  user,
  message,
  entityIdentifier,
  organizerIdentifier
}) => {
  const classes = useStyles()
  const { actionPending } = useSelector((state) => state.organizers)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const handleConfirmation = async () => {
    try {
      onSuccess(user.Id)
      setShowConfirmationDialog(false)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <div className={classes.container}>
      <div
        onClick={() => {
          setShowConfirmationDialog(true)
        }}
        className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {user?.FullName}
          </Typography>
        </div>
        <div className={classes.nameStyle}>
          <Typography variant='h6' color='primary' component='span'>
            {user?.Id}
          </Typography>
        </div>
        <hr className={classes.separator} />
      </div>
      <OrganizerConfirmationDialog
        open={showConfirmationDialog}
        loading={actionPending}
        handleConfirmation={handleConfirmation}
        onCancel={() => setShowConfirmationDialog(false)}
        title={'CONFIRMACIÓN'}
        message={message}
        entityIdentifier={entityIdentifier}
        organizerIdentifier={organizerIdentifier}
      />
    </div>
  )
}

export default UsersList
