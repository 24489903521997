import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { Save, Add, Delete } from '@material-ui/icons'
import {
  createColumn,
  createCustomColumn,
  TableGrid,
  TableGridOrder
} from 'views/shared'
import { useDispatch, useSelector } from 'react-redux'
import { loadTicketByUsage } from 'state/modules/events'
import { TICKET_USAGE } from 'config/constants'
import { useStyles } from './TicketsComboDialog.style'

const makeColumns = (handleRemoveItem) => {
  return [
    createColumn('EventName', 'Evento'),
    createColumn('TicketName', 'Ticket'),
    createCustomColumn(
      (c) => (
        <Button onClick={() => handleRemoveItem(c.TicketId)}>
          <Delete />
        </Button>
      ),
      'Quitar'
    )
  ]
}

const makeFooterActions = () => []

export const TicketsComboDialog = ({
  comboTickets,
  idsTickets,
  openDialogTickets,
  setOpenDialogTickets,
  setFieldValue
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    event,
    eventsWithCombo,
    ticketList,
    pendingEventsCombo,
    pending: pendingTickets
  } = useSelector((state) => state.events)
  const [eventIdSelected, setEventIdSelected] = useState('')
  const [eventTitleSelected, setEventTitleSelected] = useState('')
  const [ticketIdSelected, setTicketIdSelected] = useState('')
  const [ticketNameSelected, setTicketNameSelected] = useState('')
  const [comboList, setComboList] = useState(comboTickets)
  const [listIdsTickets, setListIdsTickets] = useState(idsTickets)

  const handleAddItem = () => {
    setComboList([
      ...comboList,
      {
        EventName: eventTitleSelected,
        TicketName: `(${ticketIdSelected}) ${ticketNameSelected}`,
        TicketId: ticketIdSelected
      }
    ])
    setListIdsTickets([...listIdsTickets, ticketIdSelected])
    setTicketIdSelected('')
    setTicketNameSelected('')
  }

  const handleRemoveItem = (ticketId) => {
    setComboList(comboList.filter((i) => i.TicketId !== ticketId))
    setListIdsTickets(listIdsTickets.filter((l) => l !== ticketId))
  }

  const handleSave = () => {
    setFieldValue('ComboTickets', listIdsTickets.toString())
    setOpenDialogTickets(false)
    setEventIdSelected('')
  }

  useEffect(() => {
    async function fetchData() {
      if (event && ticketList) {
        if (
          eventIdSelected === '' &&
          eventTitleSelected === '' &&
          ticketList.length > 0
        ) {
          setEventIdSelected(event.Id)
          setEventTitleSelected(event.Title)
        }
      }
    }
    fetchData().then()
  }, [event, eventIdSelected, ticketList, eventTitleSelected])

  useEffect(() => {
    async function fetchData() {
      if (eventIdSelected !== '' && !pendingEventsCombo) {
        await dispatch(loadTicketByUsage(eventIdSelected, TICKET_USAGE.COMBO))
      }
    }
    fetchData().then()
  }, [dispatch, eventIdSelected, pendingEventsCombo])

  return (
    <Dialog
      open={openDialogTickets}
      className={classes.ticketsDialog}
      onClose={() => setOpenDialogTickets(false)}
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle className={classes.titleDialog}>
        Tickets combo Asociados
      </DialogTitle>
      <DialogContent className={classes.contentTickets}>
        <div className={classes.comboForm}>
          <FormControl variant='outlined' fullWidth>
            {pendingEventsCombo ? (
              <div className={classes.loadingCard}>
                <CircularProgress size={24} style={{ marginRight: '1rem' }} />
                <Typography variant={'caption'}>Cargando eventos...</Typography>
              </div>
            ) : (
              <>
                <InputLabel>Seleccione un evento</InputLabel>
                <Select
                  labelId='event-id-select-label'
                  id='event-id-select'
                  value={eventIdSelected}
                  size='small'
                  className={classes.fieldDialog}
                  label='eventId'
                  onChange={(e, i) => {
                    setTicketIdSelected('')
                    setEventTitleSelected(i.props.children)
                    setEventIdSelected(e.target.value)
                  }}>
                  {eventsWithCombo &&
                    eventsWithCombo.length > 0 &&
                    eventsWithCombo.map((e) => (
                      <MenuItem key={e.Id} value={e.Id}>
                        {e.Title}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
          </FormControl>
          <FormControl variant='outlined' fullWidth>
            {pendingTickets || pendingEventsCombo ? (
              <div className={classes.loadingCard}>
                <CircularProgress size={24} style={{ marginRight: '1rem' }} />
                <Typography variant={'caption'}>Cargando tickets...</Typography>
              </div>
            ) : (
              <>
                <InputLabel>Seleccione un ticket</InputLabel>
                <Select
                  labelId='ticket-id-select-label'
                  id='ticket-id-select'
                  value={ticketIdSelected}
                  size='small'
                  disabled={!eventIdSelected}
                  className={classes.fieldDialog}
                  defaultValue=''
                  label='ticketId'
                  onChange={(e, i) => {
                    setTicketNameSelected(i.props.children)
                    setTicketIdSelected(e.target.value)
                  }}>
                  {ticketList &&
                    ticketList.map((t) => (
                      <MenuItem key={t.Id} value={t.Id}>
                        {t.Name}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
          </FormControl>
          <Button
            className={classes.buttonDialog}
            variant='contained'
            onClick={() => handleAddItem()}
            disabled={
              pendingTickets ||
              ticketIdSelected === '' ||
              listIdsTickets?.includes(ticketIdSelected)
            }
            startIcon={<Add />}>
            Agregar
          </Button>
        </div>
        <div className={classes.tableCombo}>
          <TableGrid
            title='Tickets Seleccionados'
            columns={makeColumns(handleRemoveItem)}
            footerActions={makeFooterActions()}
            loadItemsPage={() => {}}
            isLoading={pendingEventsCombo}
            pagedList={{ items: comboList }}
            keyName='EventName'
            order={TableGridOrder.ASC}
            orderBy={'EventName'}
            useNewFormat
            hideFooter
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialogTickets(false)} color='primary'>
          Cancelar
        </Button>
        <Button
          onClick={() => handleSave()}
          color='primary'
          variant='contained'
          autoFocus
          startIcon={<Save />}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TicketsComboDialog
