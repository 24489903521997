import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: { width: '100%', display: 'flex', justifyContent: 'center' },
  contentDialog: { marginBlock: theme.spacing(1) },
  menu: {
    zIndex: '1400 !important'
  }
}))

export default useStyles
