import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import * as moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { useStyles } from './UserOrderDetailsPage.style'
import { loadOrderDetails, generateBilling } from '~/state/modules/orders'
import { DOCUMENT_TYPE } from 'config/constants'
import { showSnackbarSuccess } from 'views/utils/snackbar'

export const SelectedDateModal = ({
  open,
  onClose,
  orderId,
  isInvoice,
  invoiceDate,
  updateMessageError,
  showMessageError
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pendingBilling } = useSelector((state) => state.orders)
  const currentDate = moment().subtract(3, 'hours')

  const initialDate = () => {
    if (isInvoice) {
      return currentDate
    } else {
      return moment(invoiceDate, 'DD/MM/YYYY')
    }
  }

  const [selectedDate, setSelectedDate] = useState(moment(initialDate()))

  const handleGenerate = async () => {
    try {
      await dispatch(
        generateBilling(
          orderId,
          isInvoice ? DOCUMENT_TYPE.INVOICE : DOCUMENT_TYPE.CREDIT_NOTE,
          moment(selectedDate).format('YYYY-MM-DD')
        )
      )
      dispatch(loadOrderDetails(orderId))
      showMessageError(false)
      if (isInvoice) {
        showSnackbarSuccess('¡Factura generada con éxito!')
      } else {
        showSnackbarSuccess('Nota de crédito generada con éxito!')
      }
      onClose()
      setTimeout(() => {
        setSelectedDate(currentDate)
      }, 500)
    } catch (error) {
      updateMessageError(error?.response?.data?.Message)
      showMessageError(true)
    }
  }

  const shouldDisableDate = (date) => {
    const initialDateMoment = moment(initialDate())
    const isDateBeforeInvoiceDate = date.isBefore(initialDateMoment)
    const isDateInRange = date.isBetween(
      currentDate.clone().subtract(11, 'days'),
      currentDate.clone().add(10, 'days'),
      null,
      '[]'
    )

    if (isInvoice) {
      return !isDateInRange
    } else {
      return !isDateInRange || isDateBeforeInvoiceDate
    }
  }

  const onCloseModal = () => {
    onClose()
    setTimeout(() => {
      setSelectedDate(currentDate)
    }, 500)
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
      className={classes.dialog}
      aria-labelledby='date-picker-dialog'>
      <DialogContent>
        <DialogContentText>
          {isInvoice
            ? 'Selecciona la fecha para la factura.'
            : 'Selecciona la fecha para la nota de crédito.'}
        </DialogContentText>
        <DatePicker
          locale='es'
          autoOk
          variant='inline'
          size='small'
          inputVariant='outlined'
          label='Fecha de Facturación'
          format='DD/MM/YYYY'
          disableToolbar
          InputLabelProps={{
            shrink: true
          }}
          value={selectedDate}
          onChange={(date) => {
            console.log(date)
            setSelectedDate(date)
          }}
          shouldDisableDate={shouldDisableDate}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseModal}
          color='primary'
          disabled={pendingBilling}>
          Cancelar
        </Button>
        <Button
          onClick={handleGenerate}
          variant='contained'
          disabled={pendingBilling}
          color='primary'>
          {pendingBilling ? (
            <CircularProgress size={24} color='secondary' />
          ) : (
            'Aceptar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectedDateModal
