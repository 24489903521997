import { Typography } from '@material-ui/core'
import { TagSection } from 'views/shared'
import useStyles from './TermsPage.style'

export const TermsPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant='h3' className={classes.title}>
        CONTRATO DE ACEPTACION DE TERMINOS Y CONDICIONES Y PRESTACIÓN DE
        SERVICIOS.
      </Typography>
      <br />
      <Typography variant='body2'>
        Usted está por comenzar a utilizar nuestros servicios, y para ello
        resulta indispensable que lea atentamente los Términos y Condiciones de
        Uso para PRODUCTORAS, que se expondrán a continuación. Los mismos harán
        las veces de un contrato que regirá la relación jurídica de consumo que
        tendrá a TICKETPASS de oferente de un Servicio y a las PRODUCTORAS como
        consumidoras del mismo. Por ello, para poder operar en nuestra
        plataforma, deberá de manera indefectible aceptar los mismos, prestando
        así conformidad y entendimiento respecto de lo aquí expresado.
      </Typography>
      <br />
      <TagSection
        title={'PRIMERO: CONSENTIMIENTO Y ACEPTACION EXPRESA DE LA PRODUCTORA'}
        body={
          <>
            Al aceptar los Términos y Condiciones establecidos, usted está
            manifestando libre y expresamente su conformidad con los mismos, por
            lo que luego de ello, no podrá realizar reclamo alguno respecto de
            todo lo que en el presente contrato se encuentre pautado y regulado.
          </>
        }
      />
      <TagSection
        title={'SEGUNDO: GENERALIDADES DEL SERVICIO'}
        body={
          <>
            XPASS GROUP S.A.S. (en adelante “TICKETPASS”), C.U.I.T.
            30-71681931-7 es una empresa cuyo objeto comercial recae en prestar
            servicios de gestión de venta de Entradas para eventos de distinta
            índole. Dicha compra y venta de entradas puede ser realizada tanto
            mediante forma virtual, como también físicamente en puntos de venta
            dispuestos al efecto.
            <br />
            <br />
            La función de TICKETPASS, debe quedar en claro, no resulta ser la de
            Organizadora de Eventos, sino que simplemente limita su función a
            ser intermediara entre el Organizador de un Evento (en adelante
            “PRODUCTORA”) y el Usuario Final (en adelante “USUARIO”), quien
            compra una entrada para asistir a determinado evento, o bien quien
            vaya a ser el poseedor de un ticket.
            <br />
            <br />
            TICKETPASS, le brinda a la PRODUCTORA, por cuenta, mandato y orden
            de esta, el servicio de venta de Entradas para un determinado
            Evento. Dicha venta de entradas se realizará tanto de forma virtual
            como física en caso de ser requerida.
            <br />
            <br />
            Es por ello, que el único y exclusivo responsable por la
            organización, modificación, realización, calidad, licitud, daños,
            lesiones, irregularidades impositivas, etc., del Evento para el cual
            un USUARIO adquirió, o posea una entrada, es la PRODUCTORA del
            evento. TICKETPASS no tendrá responsabilidad alguna por todo lo que
            tenga que ver con ello.
            <br />
            <br />
            TICKETPASS es el único titular y responsable de la propiedad
            intelectual de la plataforma virtual de venta de entradas. La
            PRODUCTORA no posee en ningún momento titularidad respecto de la
            misma, sino que solo tendrá la disposición de la misma respecto a su
            cuenta y evento registrado, siempre bajo los lineamientos de
            presente contrato.
          </>
        }
      />
      <TagSection
        title={'TERCERO: SERVICIO BRINDADO A LA PRODUCTORA'}
        body={
          <>
            TICKETPASS, pondrá a disposición de la PRODUCTORA su plataforma
            virtual de venta de entradas para que esta, por intermedio de
            TICKETPASS pueda vender y poner a disposición del comprador las
            entradas de los eventos organizados.
            <br />
            <br />
            Para ello, LA PRODUCTORA deberá crear una cuenta en la plataforma de
            venta online utilizada al efecto y a posterior deberá generar la
            configuración de su producto y/o servicio (el evento). Para esto,
            TICKETPASS, se compromete a prestarle la debida asesoría al efecto.
            Es menester destacar que la consignación, configuración y
            disposición de datos del EVENTO creado por la PRODUCTORA, como así
            también su veracidad, queda bajo su exclusiva responsabilidad,
            quedando exento TICKETPASS de cualquier error al respecto.
            <br />
            <br />
            TICKETPASS, a su vez, se encuentra facultada a verificar en forma
            permanente el estado de la cuenta creada por la PRODUCTORA, a los
            fines de tener noción de la cantidad de personas que adquirieron el
            producto y/o servicio; los pagos efectuados por los usuarios; el
            ingreso total de pagos; los egresos por pagos de comisiones al
            prestador, como así también para mantener un control de la calidad y
            funcionalidad del servicio prestado. Asimismo, también tendrá la
            facultad de verificar, si así lo considerara, los datos incorporados
            por la PRODUCTORA en la plataforma y solicitar que se realicen
            modificaciones o bien que se completen los mismos. Esto no lo
            transforma en solidario de la PRODUCTORA en cuanto a la
            responsabilidad indicada en el párrafo que antecede.
            <br />
            <br />
            La PRODUCTORA no goza de exclusividad respecto del uso de la
            plataforma de venta virtual de TICKETPASS, y por tanto, dicha
            plataforma podrá brindar servicio a varias PRODUCTORAS a la vez, sin
            que esto genere perjuicio alguno a ellas, ni tampoco dará lugar a
            reclamo alguno de parte de estas.
            <br />
            <br />
            TICKETPASS no garantiza bajo ningún aspecto a la PRODUCTORA que el
            evento organizado y para el cual se utilizan los servicios de
            TICKETPASS, reúna la cantidad de participantes esperada, como así
            tampoco el correcto desarrollo ni la licitud del mismo. De todo ello
            se hará cargo y será exclusivamente responsable la PRODUCTORA.
            <br />
            <br />
            Se aclara también, en este punto, que la PRODUCTORA tendrá la
            posibilidad de realizar ventas de entradas de manera física y
            cobrando las mismas en efectivo, por cuenta y responsabilidad de sí
            misma, dejando exenta de responsabilidad a TICKETPASS de cualquier
            inconveniente que pueda surgir con dicha modalidad de venta y cobro.
            Asimismo, la PRODUCTORA será exclusiva responsable de responder
            frente a los USUARIOS por cualquier circunstancia relacionada a ello
            como así también al evento.
          </>
        }
      />
      <TagSection
        title={'CUARTO: CAPACIDAD DE LA PRODUCTORA'}
        body={
          <>
            La PRODUCTORA declara expresamente, ser una persona (física o
            jurídica) hábil, mayor de edad y que posee capacidad legal para
            contratar y recibir servicios, todo ello en el marco de la
            Legislación Argentina vigente.
            <br />
            <br />
            En idéntico sentido, se obliga a suministrar a TICKETPASS datos
            verdaderos, correctos, actuales y completos respecto de su persona,
            ya sea física o jurídica, o bien sus apoderados al momento de
            registrarse en la plataforma virtual, como así también a informar
            cualquier actualización de los mismos.
            <br />
            <br />
            TICKETPASS se reserva el derecho de suspender o cancelar, sin previo
            aviso, ni previa notificación, la cuenta registrada de la PRODUCTORA
            de la cual haya certeza o sospechas de que haya suministrado datos
            no fidedignos, incompletos o maliciosos, sin que la PRODUCTORA tenga
            nada por reclamar.
          </>
        }
      />
      <TagSection
        title={'QUINTO: DEL EVENTO OFRECIDO POR LA PRODUCTORA'}
        body={
          <>
            TICKETPASS no tendrá responsabilidad alguna respecto de la efectiva
            realización del evento puesto a la venta por la PRODUCTORA; como así
            tampoco de su veracidad, habilitación, licitud, seguridad, calidad,
            modificación, suspensión, y cualquier otra cuestión relativa al
            correcto desempeño del evento, como también al lugar en el que se
            desarrolle el mismo. Tampoco será responsable de todo daño que pueda
            ser generado con motivo de la realización u organización del evento
            ofrecido y organizado por la PRODUCTORA.
            <br />
            <br />
            Cabe mencionar que la PRODUCTORA podrá establecer rangos de edades
            de ingreso mínimas y/o máximas a sus eventos, lo cual la obliga a
            comunicar de manera explicita dicha información para el pleno
            conocimiento de los USUARIOS. Es responsabilidad absoluta de la
            PRODUCTORA el control y cumplimiento del acceso de los USUARIOS para
            garantizar que los mismos se encuadran en los parámetros de edades
            preestablecidos y comunicados oportunamente.
            <br />
            <br />
            La PRODUCTORA, en tanto, se hace expresamente responsable por todo
            ello, y manifiesta que TICKETPASS nada tendrá que responder por todo
            lo mencionado.
            <br />
            <br />
            Por este acto, la PRODUCTORA declara su conformidad con la dinámica
            antes descripta y se compromete a dar información verídica de todos
            los datos relativos a su Evento.
          </>
        }
      />
      <TagSection
        title={'SEXTO: MODALIDAD DE COBRO DE ENTRADAS Y PAGO A LA PRODUCTORA'}
        body={
          <>
            En cuanto a la modalidad de venta electrónica/virtual, se aclara que
            TICKETPASS utiliza para el cobro de las mismas la plataforma virtual
            de MERCADOPAGO, motivo por el cual los cobros de las entradas
            mencionadas se automatizarán directamente, derivándose de manera
            automática los importes que le corresponda a cada parte, a la cuenta
            de MercadoPago que hayan registrado y autorizado al efecto. Es
            importante destacar que TICKETPASS no percibirá de ninguna manera el
            dinero total correspondiente a cualquier venta, sino que solo
            percibirá lo correspondiente a la comisión que le es debida por cada
            entrada vendida por su sistema de gestión de venta, mientras el
            importe correspondiente a LA PRODUCTORA, será automáticamente
            derivado a su cuenta de MercadoPago.
            <br />
            <br />
            Tanto LA PRODUCTORA como TICKETPASS, convienen y aceptan conocer el
            sistema de recaudación que brinda MercadoPago, siendo que este se
            basa en un “Split de Pago” mediante el cual se separa
            automáticamente el dinero cobrado por cada entrada vendida en 3
            partes: 1) El dinero correspondiente a la comisión que le
            corresponda a TICKETPASS por el servicio brindado, se deriva
            automáticamente en la cuenta de MercadoPago de esta; 2) El dinero
            correspondiente a la comisión que cobra MercadoPago por ser el
            gestor de pago utilizado, se deriva directamente a su cuenta propia
            de MercadoPago; 3) El saldo restante del total del valor de la
            entrada, se deposita automáticamente en la cuenta de MercadoPago de
            LA PRODUCTORA.
            <br />
            <br />
            Para el caso de los medios de venta físicos de cobro en efectivo que
            proponga LA PRODUCTORA, se aclara que TICKETPASS solamente brindara
            el sistema de Gestión de la generación de la entrada propiamente
            dicha. LA PRODUCTORA asume la responsabilidad de la venta y cobro de
            entradas en efectivo, como así también del personal que se disponga
            al efecto.
            <br />
            <br />
            Por dicho servicio, se pacta expresamente que TICKETPASS cobrará una
            comisión por aquellas ventas realizadas en efectivo mediante el uso
            de la plataforma, la cual se acordará previamente con LA PRODUCTORA,
            y que deberá ser integrada dentro de los plazos previamente
            definidos.
          </>
        }
      />
      <TagSection
        title={
          'SEPTIMO: COMISION DE TICKETPASS POR LA PRESTACION DEL SERVICIO DE VENTA DE ENTRADAS A LA PRODUCTORA'
        }
        body={
          <>
            Para brindar el servicio contratado, TICKETPASS utilizará un sistema
            de gestión propio, y por dicho servicio prestado cobrará un
            porcentaje (que será previamente acordado con la PRODUCTORA en un
            anexo comercial) sobre el valor de cada entrada vendida, ya sea bajo
            modalidad virtual o en efectivo. Es importante destacar, que dicho
            porcentaje mencionado incluye IVA, lo cual se acepta y conviene
            entre las partes. Este importe, se destinará automáticamente en la
            cuenta de MercadoPago de TICKETPASS en el caso de las ventas online,
            mientras para el caso de la venta física en efectivo, deben
            remitirse a lo estipulado en la Cláusula SEXTA.
          </>
        }
      />
      <TagSection
        title={
          'OCTAVO: RESPOSABILIDADES DE LA PRODUCTORA RESPECTO A LA NO REALIZACION DEL EVENTO EN EL MARCO DE LA COMISION DE TICKETPASS'
        }
        body={
          <>
            La PRODUCTORA, entiende, asume y presta conformidad de que la
            función de TICKETPASS está basada pura y exclusivamente en la
            prestación del servicio de venta de entradas, por medio de su
            plataforma virtual, en favor del evento organizado ofrecido.
            <br />
            <br />
            Es por ello, que la PRODUCTORA asume exclusivamente la
            responsabilidad por la no realización del evento ofrecido, motivo
            por el cual en el caso de que el evento no se realice por causas
            exclusivamente imputables a la PRODUCTORA, TICKETPASS igualmente
            deberá percibir, por parte de la PRODUCTORA, la totalidad de las
            comisiones correspondientes por las entradas que se hayan vendido,
            es decir el servicio brindado y efectivamente realizado.
          </>
        }
      />
      <TagSection
        title={
          'NOVENO: COMISION DE MERCADOPAGO COMO PLATAFORMA DE GESTION DE PAGOS'
        }
        body={
          <>
            La PRODUCTORA acepta y expresa conocer, que MERCADOPAGO cobrará
            también un porcentaje del valor total de la entrada vendida de
            manera virtual en concepto de comisión por gestión de pago, en
            virtud del plazo que LA PRODUCTORA elija para recibir el dinero en
            la aplicación de MERCADOPAGO. Así también acepta conocer los
            términos y condiciones de MercadoPago en relación a los aumentos del
            mencionado porcentaje para el caso de que LA PRODUCTORA quiera
            percibir el dinero en un plazo más rápido.
            <br />
            <br />
            Se aclara, y así se conviene, que TICKETPASS no se responsabiliza
            bajo ningún aspecto por los posibles aumentos de comisión que pueda
            imponer MercadoPago durante la vigencia del presente contrato.
            TICKETPASS no se responsabiliza por los errores que cometiera la
            PRODUCTORA en la configuración de su cuenta tanto de la plataforma
            de TICKETPASS como de MERCADOPAGO.
          </>
        }
      />
      <TagSection
        title={
          'DECIMO: CANCELACION O MODIFICACION DE LAS CONDICIONES DE REALIZACION DEL EVENTO ORGANIZADO POR LA PRODUCTORA'
        }
        body={
          <>
            La PRODUCTORA, será única y exclusiva responsable por la realización
            o no del evento organizado, como así también por cualquier
            modificación que puedan sufrir las condiciones iniciales del mismo.
            En tanto a ello, responderá frente a los USUARIOS por dichas
            cuestiones, motivo por el cual deberá tener a disposición de estos
            una Política, dejando expresamente ajeno a TICKETPASS de tener que
            responder por los términos y condiciones propios de la PRODUCTORA.
            <br />
            <br />
            La PRODUCTORA se obliga a informar a TICKETPASS cualquier
            circunstancia relativa a ello, a los efectos de que TICKETPASS pause
            o de fin a la venta de entradas de dicho evento.
            <br />
            <br />
            La PRODUCTORA será única y exclusiva responsable por las políticas
            que adopte para el caso de modificación de las condiciones iniciales
            del evento ofrecido, como así también para aquellas que adopte en
            caso de cancelación. Esto quiere decir que se hará cargo
            expresamente por aquellos casos en los que haya que realizar
            devoluciones de entradas, siendo la única responsable por ello
            frente a los USUARIOS. Dichas políticas deberán ser comunicadas a
            los USUARIOS por los canales que la PRODUCTORA crea más apropiados,
            o incluso los mismos que esta haya utilizado para promocionar el
            evento.
            <br />
            <br />
            En virtud de lo expuesto, la PRODUCTORA presta aceptación a la
            política de comunicación de TICKETPASS cumpliendo con el derecho a
            la información por parte de los usuarios.
            <br />
            <br />
            En caso de cancelación de un evento por parte de la PRODUCTORA,
            TICKETPASS establece un plazo máximo de 2 (dos) días desde la fecha
            de cancelación, para que la PRODUCTORA comunique a los usuarios, a
            través de los medios que acuerden entre ambas partes, fecha de
            reprogramación del evento; en caso de no contar con posibilidad de
            reprogramación o la misma exceda los 60 (sesenta) días, TICKETPASS
            podrá exigir y así lo acepta la PRODUCTORA, el reintegro del dinero
            total abonado a los usuarios.
            <br />
            <br />
            En caso de exceder el plazo máximo de 2 (dos) días y frente a la
            falta de comunicación por parte de la PRODUCTORA, TICKETPASS
            establece un plazo límite de 7 (siete) días adicionales al ya
            otorgado, para hacer público los datos correspondientes de la
            PRODUCTORA, sin ninguna obligación de consultar con la misma el
            canal de difusión.
            <br />
            <br />
            Los datos que TICKETPASS podrá publicar son aquellos que figuran en
            el correspondiente contrato firmado entre ambas partes, los cuales
            la PRODUCTORA declaró como verdaderos y actuales, justificados con
            la documentación presentada: Nombre y Apellido del firmante;
            DNI/CUIT del firmante; Razón Social de la Empresa (persona
            Jurídica), Nombre y Apellido (persona Física); CUIT de la Empresa;
            Domicilio; Teléfono y demás datos adicionales que considere
            TICKETPASS como ser mail y redes sociales. La PRODUCTORA reconoce
            haber leído la política de comunicación de TICKETPASS y acepta la
            misma sin ninguna objeción.
          </>
        }
      />
      <TagSection
        title={'DECIMOPRIMERO: ENTREGA DE LA ENTRADA A USUARIOS'}
        body={
          <>
            La PRODUCTORA manifiesta conocer que, respecto de la entrega de las
            entradas a los USUARIOS, una vez realizada la compra de la ENTRADA
            por el USUARIO, se activará en la cuenta que este haya registrado en
            la plataforma de TICKETPASS su “e-ticket” (Ticket electrónico), el
            cual podrá utilizar personalmente o transferirlo a un tercero y
            junto a su Documento Nacional de Identidad, será el documento que le
            permitirá ingresar al evento adquirido.
            <br />
            <br />
            Asimismo, recibirá vía electrónica, más precisamente por correo
            electrónico, un aviso de cortesía de que su “e-ticket” ya fue
            generado y se encuentra disponible en la plataforma.
            <br />
            <br />
            Dicho envío del E-TICKET, dependerá también de la forma de pago
            elegida por el USUARIO. De esa manera, para el caso del que el
            USUARIO realice la compra con tarjeta de crédito, recibirá en su
            correo electrónico consignado al efecto, la notificación de que
            dispone de “e-ticket” en su cuenta de la plataforma de TICKETPASS,
            una vez procesado y autorizado el pago, tanto por la empresa emisora
            de la tarjeta, como también por MERCADOPAGO.
            <br />
            <br />
            Si el USUARIO elige como método de pago un cupón de pago de las
            entidades de cobro habilitadas al efecto, se deberá esperar un plazo
            de entre 48 y 72 horas para que el mismo sea acreditado, y de esa
            forma, una vez acreditado el pago, el USUARIO recibirá el código en
            la forma indicada.
          </>
        }
      />
      <TagSection
        title={'DECIMOSEGUNDO: DEVOLUCION DE LAS ENTRADAS A LOS USUARIOS'}
        body={
          <>
            La PRODUCTORA acepta conocer que la plataforma de gestión de cobros
            utilizada por TICKETPASS, es decir MERCADOPAGO, establece un proceso
            de devolución automática que será aplicada en el caso de suspensión
            del evento objeto del presente contrato, para gestionar la
            devolución del valor de las entradas vendidas a los USUARIOS de
            manera automática.
            <br />
            <br />
            Es dable destacar, asimismo, que dichas pautas establecidas por
            MERCADOPAGO, están sujetas a los cambios que considere realizar
            dicha entidad. Frente a estas políticas que están estipuladas por
            MERCADOPAGO, queda bajo la exclusiva responsabilidad de la
            PRODUCTORA hacérselas conocer a los USUARIOS, quedando exento de
            responsabilidad TICKETPASS para el caso de que los USUARIOS tengan
            algo por reclamar respecto al desconocimiento de cualquier
            información y sus modificaciones.
            <br />
            <br />
            Dicha devolución automatizada, será pura y exclusivamente para los
            usuarios que hayan adquirido el producto en el plazo estipulado
            oportunamente por MERCADOPAGO. Pasado dicho plazo, LA PRODUCTORA
            deberá hacerse cargo de realizar las devoluciones por sus propios
            medios, responsabilizándose expresamente por ello, y
            comprometiéndose a no reclamar importe alguno a TICKETPASS.
            <br />
            <br />
            Para el caso de que se realicen devoluciones de manera automática
            utilizando el servicio de gestión de pagos de MERCADOPAGO, las
            partes aceptan conocer que se le devolverá al usuario el 100% del
            valor que este hubiere abonado, es decir se le reintegrará también
            la comisión por servicio que le correspondiere tanto a TICKETPASS
            como a MERCADOPAGO. Frente a esto es menester recordar, que, en
            dicho caso, LA PRODUCTORA deberá abonar a TICKETPASS el valor
            correspondiente a la comisión pactada por las entradas que hubieren
            sido vendidas.
          </>
        }
      />
      <TagSection
        title={
          'DECIMOTERCERO: PROCESO DE DEVOLUCIÓN AUTOMATICA POR INTERMEDIO DE MERCADOPAGO'
        }
        body={
          <>
            La gestión y políticas de devolución del dinero por la compra de las
            entradas es pura y exclusiva responsabilidad de la PRODUCTORA, la
            cual deberá informar a los USUARIOS el procedimiento de reintegro.
            Queda expresamente acordado que TICKETPASS no devolverá la comisión
            correspondiente aplicada a la gestión de sus servicios ya prestados.
          </>
        }
      />
      <TagSection
        title={'DECIMOCUARTO: POLITICA DE PRIVACIDAD.'}
        body={
          <>
            TICKETPASS utilizara los datos personales de las PRODUCTORAS
            registradas con el único fin de validar y brindar el servicio de
            venta de entradas de los eventos organizados, como así también para
            mejorar sus servicios prestados y la comercialización de las
            entradas vendidas. También serán utilizados con el fin de llevar un
            control y recuento de las entradas vendidas de un evento,
            información que podrá ser solicitada a TICKETPASS por la PRODUCTORA
            organizadora.
            <br />
            <br />
            Sin perjuicio de ello, se aclara que el servicio antes mencionado
            está disponible en la plataforma de TICKETPASS para la PRODUCTORA y
            en casos excepcionales, TICKETPASS brindará dicha información.
            <br />
            <br />
            TICKETPASS se compromete a no traspasar los datos mencionados a
            terceros ajenos a la prestación del servicio brindado, salvo a
            aquellas personas o empresas relacionadas con TICKETPASS, como ser
            el USUARIO que adquirió una entrada.
            <br />
            <br />
            TICKETPASS podrá generar bases de datos propias con la información
            ingresada a la plataforma por la PRODUCTORA, para utilizarlos
            exclusivamente con fines comerciales y de promoción.
            <br />
            <br />
            La PRODUCTORA acepta y deja expresa constancia que toda la
            información solicitada por TICKETPASS, es proveída por ésta, de
            manera voluntaria y consentida, como así también entendiendo y
            aceptando la política de privacidad, como así también la totalidad
            del presente contrato.
          </>
        }
      />
      <TagSection
        title={'DECIMOQUINTO: DESLINDE DE RESPONSABILIDAD DE TICKETPASS'}
        body={
          <>
            La PRODUCTORA acepta expresamente, que TICKETPASS quedará excluido
            de cualquier tipo de responsabilidad que pueda surgir por cuestiones
            relativas al fin último que tenga la realización del evento
            organizado por ella, como ser fines ilícitos, delictivos, evasivos
            en términos impositivos, o cualquier otro fin que exceda el de
            entretenimiento. Así tampoco será responsable por todo lo
            relacionado con la realización del mismo, sus modificaciones, su
            calidad, accidentes/daños/lesiones dentro del evento, y demás
            contratiempos e inconvenientes imputables a la PRODUCTORA, como así
            tampoco en todas las cuestiones relativas al lugar en donde se
            realice físicamente el evento.
            <br />
            <br />
            Todas aquellas cuestiones de índole legal, ya sea delictual,
            infraccionaría o impositiva, que se puedan suscitar en el marco del
            evento ofrecido por la PRODUCTORA, quedan bajo la expresa órbita de
            responsabilidad de esta última, quien deja expresa constancia de
            asumirla, dejando exenta a TICKETPASS de responder por cualquier
            tipo de responsabilidad mencionada.
            <br />
            <br />
            Asimismo, TICKETPASS también quedará exento de la responsabilidad
            por aquellos USUARIOS que utilicen las entradas con otro fin más que
            el de asistir al evento, para el cual compraron la entrada, como
            ser, por ejemplo, la reventa ilegal de entradas, etc. Al respecto,
            LA PRODUCTORA deslinda expresamente de responsabilidad a TICKETPASS,
            por cualquier ilegalidad que se pueda cometer con respecto al uso y
            disposición de las entradas vendidas, asumiendo LA PRODUCTORA dichas
            responsabilidades de control y recaudos.
            <br />
            <br />
            Es menester recordar en este punto que la única responsabilidad que
            tiene TICKETPASS, es la de poner al servicio de la PRODUCTORA y el
            USUARIO un canal de intermediario para que un oferente (LA
            PRODUCTORA) pueda poner a la venta las entradas de un evento por el
            organizado y el USUARIO pueda adquirirlas. Se deja expresa
            constancia que TICKETPASS responde solamente por el correcto
            funcionamiento de su plataforma de gestión de ventas, respecto al
            servicio brindado a la PRODUCTORA.
            <br />
            <br />
            Asimismo, acepta la PRODUCTORA, que TICKETPASS queda totalmente
            exenta de responsabilidad por cualquier daño y perjuicio que pueda
            generarle el uso de la plataforma, en lo relacionado a la presencia
            de virus u cualquier otro elemento en los contenidos que en la
            plataforma se muestran y que puedan generar alteraciones en los
            sistemas operativos, informativos o dispositivos
            móviles/computadoras que la PRODUCTORA utilice para operar en la
            plataforma.
            <br />
            <br />
            TICKETPASS no será responsable, y la PRODUCTORA asume que nada
            tendrá que reclamarle por cualquier daño o perjuicio, ya sea pérdida
            económica, lucro cesante, etc., que pueda surgir por el uso de los
            servicios de la plataforma de venta de entradas, siempre y cuando
            estas fueran producidas a pesar del buen y correcto funcionamiento
            de la misma, incluso cuando haya sido por alguna interrupción
            momentánea del servicio de venta de entradas, por cuestiones
            técnicas ajenas a TICKETPASS, como ser cortes de suministros
            eléctricos, inconvenientes técnicos de la conectividad a internet, y
            cualquier otro motivo que dentro de la lógica normal exceda la
            responsabilidad de TICKETPASS.
          </>
        }
      />
      <TagSection
        title={'DECIMOSEXTO: CUESTIONES IMPOSITIVAS'}
        body={
          <>
            Cada una de las partes intervinientes en el presente contrato será
            responsable de todas aquellas obligaciones impositivas que deba
            cumplir, dejando expresamente asentado que TICKETPASS será
            responsable por cualquier posible incumplimiento y/o evasión que
            realice la PRODUCTORA por los ingresos percibidos ni otras
            cuestiones impositivas que deba informar o cumplir a los entes
            correspondientes.
            <br />
            <br />
            Cada una de las partes será estrictamente responsables de sí misma,
            por aquellas retenciones o gastos extras que pudieran surgir durante
            el transcurso de la presente relación contractual, con motivo de no
            haberse inscripto en la categoría impositiva que le corresponda, en
            función de las normas impositivas tanto nacionales, como
            provinciales o municipales, al igual que para el caso de no haberse
            registrado correctamente ni acompañado la debida documentación
            correspondiente que fuera solicitada por MercadoPago (o cualquier
            otro organismo). al momento de registrarse en dicha plataforma. En
            virtud de ello, ninguna de las partes tendrá nada que reclamarle a
            la otra por las cuestiones planteadas en esta cláusula y que fueran
            por su exclusiva culpa, omisión y/o responsabilidad.
            <br />
            <br />
            TICKETPASS, por cuestiones impositivas, hace saber que tiene la
            obligación de facturar, una vez al mes, las ganancias percibidas por
            las comisiones de la venta de entradas. Dicha facturación será
            realizada a nombre de la persona o empresa que sea asignada por la
            PRODUCTORA en su cuenta de MercadoPago. LA PRODUCTORA acepta esta
            obligación y se compromete a colaborar en dicha facturación, y nada
            tendrá por reclamar ni entorpecer respecto de ello, quedando a su
            exclusiva responsabilidad, los inconvenientes que puedan surgir en
            caso de no colaborar con dicha obligación.
            <br />
            <br />
            La PRODUCTORA acepta expresamente que MERCADOPAGO oficiará como
            agente de retenciónn o percepción, en el marco de los regímenes
            impositivos por los que se encuentren alcanzadas las partes, como
            ser IVA, impuesto a las ganancias, ingresos brutos, impuestos a los
            débitos y créditos, como así también cualquier otra obligación
            impositiva que pueda surgir en lo sucesivo.
          </>
        }
      />
      <TagSection
        title={'DECIMOSEPTIMO: PERMISOS PARA PUBLICITAR'}
        body={
          <>
            La PRODUCTORA le otorga expresamente a TICKETPASS licencia para
            realizar, ya sea en forma coordinada o separada, la publicidad del
            evento objeto del presente contrato. Incluso, reconoce a TICKETPASS
            la posibilidad de utilizar el contenido que la PRODUCTORA cargó en
            la plataforma, con motivos publicitarios.
            <br />
            <br />
            En virtud de ello, el material a publicitar por TICKETPASS será
            presentado a la PRODUCTORA previo a su publicación, para que esta lo
            revise, lo ponga a consideración del artista que se presentará en el
            evento, a los fines de mantener indemne su Derecho a la Imagen, y lo
            autorice.
            <br />
            <br />
            Una vez autorizado y publicado el material publicitario, las partes
            dejan expresa constancia en el presente, de que nada tendrán que
            reclamarse por la publicidad que cada uno realice, siempre y cuando,
            la publicidad realizada guarde el debido respeto y decoro respecto
            de la otra parte, y no manche su honor y buen nombre.
            <br />
            <br />
            La PRODUCTORA, acepta colaborar en cuanto al material a publicitar,
            como así también a mencionar a TICKETPASS en el material
            publicitario que la PRODUCTORA publique, previa puesta a
            consideración de esta y su correspondiente aceptación y
            autorización. Así también, TICKETPASS se compromete a nombrar a la
            PRODUCTORA del evento en cualquier material publicitario que
            publique.
          </>
        }
      />
      <TagSection
        title={'DECIMOCTAVO: INDEMNIDAD'}
        body={
          <>
            LA PRODUCTORA se obliga con la aceptación de los presentes términos
            y condiciones a mantener indemne al/los titular/es de la plataforma
            de TICKETPASS, sus funcionarios, directores y empleados, incluyendo
            honorarios de abogados, frente a cualquier reclamo de terceros
            basado en su utilización de esta Plataforma y/o de sus contenidos, o
            por violación del presente contrato.
          </>
        }
      />
      <TagSection
        title={'DECIMONOVENO: CONDICIONES Y LIMITACIONES DE USO'}
        body={
          <>
            TICKETPASS se reserva el derecho de establecer a las PRODUCTORAS las
            condiciones de uso generales, como así también los límites del
            servicio. Esto puede aplicarse, sin limitarse, al plazo durante el
            cual estarán los eventos disponibles para la venta, al monto máximo
            y mínimo que se puede cobrar por un evento, a la cantidad de tickets
            que puedan ser puestos a la venta, el plazo de inactividad de
            cuentas, etc.
            <br />
            <br />
            La PRODUCTORA acepta expresamente que TICKETPASS no tendrá
            responsabilidad alguna respecto de la posible cancelación de cuentas
            de PRODUCTORAS, que se encuentren inactivas por un determinado
            periodo de tiempo o que hayan incumplido alguno de los términos y
            condiciones aquí estipulados.
            <br />
            <br />
            LA PRODUCTORA también acepta expresamente la facultad de TICKETPASS
            de modificar las condiciones de uso, en cualquier momento, sin
            necesidad de comunicación previa.
            <br />
            <br />
            TICKETPASS tampoco será responsable, y nada podrá reclamarle la
            PRODUCTORA por modificar, o suprimir el servicio o cualquier parte
            del mismo, ya sea de manera temporal o permanente, frente a
            cualquier irregularidad o ilegalidad que detecte respecto al evento
            publicado por la PRODUCTORA, reservándose asimismo este derecho,
            todo lo cual la PRODUCTORA entiende y acepta.
          </>
        }
      />
      <TagSection
        title={'VIGESIMO: CANCELACION DEL SERVICIO'}
        body={
          <>
            La PRODUCTORA acepta expresamente que TICKETPASS podrá en
            determinadas circunstancias, y sin la obligación de notificar
            previamente, dar de baja la cuenta de la PRODUCTORA por motivos que
            tengan que ver con incumplimientos o violaciones a los presentes
            términos y condiciones, sus modificaciones o apartados; solicitudes
            de alguna autoridad legal o gubernamental; pedido expreso de
            registrado; cuestiones de seguridad informática, jurídica, persona,
            etc.; periodos de inactividad; entre otras, todo lo cual queda a
            discreción pura y exclusiva de TICKETPASS.
            <br />
            <br />
            La baja de la cuenta de la PRODUCTORA traerá aparejada la
            prohibición de acceso al servicio brindado por TICKETPASS, como así
            también la baja de todo el contenido publicado oportunamente por la
            cuenta cancelada.
            <br />
            <br />
            La PRODUCTORA acepta y reconoce que TICKETPASS no será responsable
            por ello, y nada tendrá por responder frente a ella ni a terceros.
          </>
        }
      />
      <TagSection
        title={
          'VIGESIMOPRIMERO: EXCENCION DE GARANTIAS POR PARTE DE TICKETPASS'
        }
        body={
          <>
            TICKETPASS no garantiza, y así lo acepta la PRODUCTORA, que la
            plataforma no sufra interrupciones o errores momentáneos, los cuales
            se compromete a solucionarlos lo más rápido posible dentro de sus
            posibilidades; ni el asesoramiento constante a la PRODUCTORA; como
            así tampoco a que se alcancen las ventas esperadas por la
            PRODUCTORA.
          </>
        }
      />
      <TagSection
        title={
          'VIGESIMOSEGUNDO: ACEPTACION DE LOS PRESENTES TERMINOS Y CONDICIONES'
        }
        body={
          <>
            La PRODUCTORA presta conformidad, de manera libre y voluntaria, a
            los presentes términos y condiciones, dejando expresa constancia que
            es su voluntad someterse a ellos, habiendo entendido los mismos.
            <br />
            <br />
            Sin perjuicio de ello, y por fuera de esto, la PRODUCTORA y
            TICKETPASS podrán pactar en caso de necesitarlo, un contrato privado
            entre partes distinto, o bien anexos y aclaraciones al presente.
          </>
        }
      />
      <TagSection
        title={'VIGESIMOTERCERO: DOMICILIO'}
        body={
          <>
            Se fija como domicilio de TICKETPASS “Paloma 715” de la Ciudad de
            Córdoba, Republica Argentina.
          </>
        }
      />
      <TagSection
        title={
          'VIGESIMOCUARTO: VIGENCIA Y CAMBIOS EN LOS TERMINOS y CONDICIONES'
        }
        body={
          <>
            TICKETPASS se reserva el derecho de hacer modificaciones,
            reemplazar, remover o extender los presentes Términos y Condiciones
            en cualquier momento y hacerlas entrar en vigencia de manera
            inmediata sin la obligación de dar previo aviso.
          </>
        }
      />
      <TagSection
        title={'VIGESIMOQUINTO: JURISDICCION Y LEY APLICABLE'}
        body={
          <>
            Los presentes Términos y Condiciones se regirán en lo sucesivo por
            las Leyes vigentes en la República Argentina.
            <br />
            <br />
            Asimismo, en el caso de existir cualquier conflicto que derive del
            presente, su contenido, interpretación, alcance o cumplimiento, será
            sometido exclusivamente a los Tribunales Ordinarios de la Ciudad de
            Córdoba, renunciando la PRODUCTORA a cualquier otra jurisdicción
            aplicable al caso concreto.
          </>
        }
      />
      {/*<Typography variant='body2' className={classes.lastUpdate}>
        Fecha de última actualización: 13/06/2018
      </Typography>*/}
    </div>
  )
}
