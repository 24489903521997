export const PAGEDLIST_ATTEMPT = 'admin/events/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/events/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/events/PAGEDLIST_FAILURE'

export const LOAD_EVENTS_ATTEMPT = 'admin/events/LOAD_EVENTS_ATTEMPT'
export const LOAD_EVENTS_SUCCESS = 'admin/events/LOAD_EVENTS_SUCCESS'
export const LOAD_EVENTS_FAILURE = 'admin/events/LOAD_EVENTS_FAILURE'

export const LOAD_EVENTS_WITH_COMBO_ATTEMPT =
  'admin/events/LOAD_EVENTS_WITH_COMBO_ATTEMPT'
export const LOAD_EVENTS_WITH_COMBO_SUCCESS =
  'admin/events/LOAD_EVENTS_WITH_COMBO_SUCCESS'
export const LOAD_EVENTS_WITH_COMBO_FAILURE =
  'admin/events/LOAD_EVENTS_WITH_COMBO_FAILURE'

export const LOAD_ATTEMPT = 'admin/events/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/events/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/events/LOAD_FAILURE'

export const CREATE_ATTEMPT = 'admin/events/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'admin/events/CREATE_SUCCESS'
export const CREATE_FAILURE = 'admin/events/CREATE_FAILURE'

export const UPDATE_ATTEMPT = 'admin/events/UPDATE_ATTEMPT'
export const UPDATE_SUCCESS = 'admin/events/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'admin/events/UPDATE_FAILURE'

export const ENABLE_ATTEMPT = 'admin/events/ENABLE_ATTEMPT'
export const ENABLE_SUCCESS = 'admin/events/ENABLE_SUCCESS'
export const ENABLE_FAILURE = 'admin/events/ENABLE_FAILURE'

export const DISABLE_ATTEMPT = 'admin/events/DISABLE_ATTEMPT'
export const DISABLE_SUCCESS = 'admin/events/DISABLE_SUCCESS'
export const DISABLE_FAILURE = 'admin/events/DISABLE_FAILURE'

export const DELETE_ATTEMPT = 'admin/events/DELETE_ATTEMPT'
export const DELETE_SUCCESS = 'admin/events/DELETE_SUCCESS'
export const DELETE_FAILURE = 'admin/events/DELETE_FAILURE'

export const SEND_NOTIFICATION_ATTEMPT =
  'admin/events/SEND_NOTIFICATION_ATTEMPT'
export const SEND_NOTIFICATION_SUCCESS =
  'admin/events/SEND_NOTIFICATION_SUCCESS'
export const SEND_NOTIFICATION_FAILURE =
  'admin/events/SEND_NOTIFICATION_FAILURE'

export const LOAD_IMAGES_ATTEMPT = 'admin/events/LOAD_IMAGES_ATTEMPT'
export const LOAD_IMAGES_SUCCESS = 'admin/events/LOAD_IMAGES_SUCCESS'
export const LOAD_IMAGES_FAILURE = 'admin/events/LOAD_IMAGES_FAILURE'

export const SET_DEFAULT_IMAGE_ATTEMPT =
  'admin/events/SET_DEFAULT_IMAGE_ATTEMPT'
export const SET_DEFAULT_IMAGE_SUCCESS =
  'admin/events/SET_DEFAULT_IMAGE_SUCCESS'
export const SET_DEFAULT_IMAGE_FAILURE =
  'admin/events/SET_DEFAULT_IMAGE_FAILURE'

export const ADD_IMAGE_ATTEMPT = 'admin/events/ADD_IMAGE_ATTEMPT'
export const ADD_IMAGE_SUCCESS = 'admin/events/ADD_IMAGE_SUCCESS'
export const ADD_IMAGE_FAILURE = 'admin/events/ADD_IMAGE_FAILURE'

export const DELETE_IMAGE_ATTEMPT = 'admin/events/DELETE_IMAGE_ATTEMPT'
export const DELETE_IMAGE_SUCCESS = 'admin/events/DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAILURE = 'admin/events/DELETE_IMAGE_FAILURE'

export const RESET = 'admin/events/RESET'
export const RESET_TABLE = 'admin/events/RESET_TABLE'
export const RESET_ACTIONS = 'admin/locations/RESET_ACTIONS'
export const RESET_PARAMS = 'admin/locations/RESET_PARAMS'

export const PAGEDLIST_TICKET_ATTEMPT = 'admin/tickets/PAGEDLIST_TICKET_ATTEMPT'
export const PAGEDLIST_TICKET_SUCCESS = 'admin/tickets/PAGEDLIST_TICKET_SUCCESS'
export const PAGEDLIST_TICKET_FAILURE = 'admin/tickets/PAGEDLIST_TICKET_FAILURE'

export const LOAD_TICKET_ATTEMPT = 'admin/tickets/LOAD_TICKET_ATTEMPT'
export const LOAD_TICKET_SUCCESS = 'admin/tickets/LOAD_TICKET_SUCCESS'
export const LOAD_TICKET_FAILURE = 'admin/tickets/LOAD_TICKET_FAILURE'

export const LOAD_TICKET_WITH_COMBO_ATTEMPT =
  'admin/tickets/LOAD_TICKET_WITH_COMBO_ATTEMPT'
export const LOAD_TICKET_WITH_COMBO_SUCCESS =
  'admin/tickets/LOAD_TICKET_WITH_COMBO_SUCCESS'
export const LOAD_TICKET_WITH_COMBO_FAILURE =
  'admin/tickets/LOAD_TICKET_WITH_COMBO_FAILURE'

export const CREATE_TICKET_ATTEMPT = 'admin/tickets/CREATE_TICKET_ATTEMPT'
export const CREATE_TICKET_SUCCESS = 'admin/tickets/CREATE_TICKET_SUCCESS'
export const CREATE_TICKET_FAILURE = 'admin/tickets/CREATE_TICKET_FAILURE'

export const UPDATE_TICKET_ATTEMPT = 'admin/tickets/UPDATE_TICKET_ATTEMPT'
export const UPDATE_TICKET_SUCCESS = 'admin/tickets/UPDATE_TICKET_SUCCESS'
export const UPDATE_TICKET_FAILURE = 'admin/tickets/UPDATE_TICKET_FAILURE'

export const DELETE_TICKET_ATTEMPT = 'admin/tickets/DELETE_TICKET_ATTEMPT'
export const DELETE_TICKET_SUCCESS = 'admin/tickets/DELETE_TICKET_SUCCESS'
export const DELETE_TICKET_FAILURE = 'admin/tickets/DELETE_TICKET_FAILURE'

export const GET_TICKET_TYPES_REPORT_ATTEMPT =
  'admin/tickets/GET_TICKET_TYPES_REPORT_ATTEMPT'
export const GET_TICKET_TYPES_REPORT_SUCCESS =
  'admin/tickets/GET_TICKET_TYPES_REPORT_SUCCESS'
export const GET_TICKET_TYPES_REPORT_FAILURE =
  'admin/tickets/GET_TICKET_TYPES_REPORT_FAILURE'

export const SHARE_URL_ATTEMPT = 'admin/events/SHARE_URL_ATTEMPT'
export const SHARE_URL_SUCCESS = 'admin/events/SHARE_URL_SUCCESS'
export const SHARE_URL_FAILURE = 'admin/events/SHARE_URL_FAILURE'

export const RESET_TICKETS = 'admin/events/RESET_TICKETS'

export const SET_TICKET_TYPE = 'admin/events/SET_TICKET_TYPE'

export const LOAD_RULE_TICKETS_ATTEMPT =
  'admin/events/LOAD_RULE_TICKETS_ATTEMPT'
export const LOAD_RULE_TICKETS_SUCCESS =
  'admin/events/LOAD_RULE_TICKETS_SUCCESS'
export const LOAD_RULE_TICKETS_FAILURE =
  'admin/events/LOAD_RULE_TICKETS_FAILURE'
