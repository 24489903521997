import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem 1.4rem 1.4rem'
  },
  title: {
    marginBottom: '.4rem'
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    fontWeight: 'normal'
  }
}))

export default useStyles
