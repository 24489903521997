import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import { TICKET_RULE } from 'config/constants'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { isEqual, debounce } from 'lodash'
import useStyles from './TicketRules.style'
import EventTicketSelector from './EventTicketSelector'

const validationSchema = Yup.object().shape({
  Type: Yup.number(),
  RequestedEventIds: Yup.array(),
  RequestedTicketIds: Yup.array(),
  Min: Yup.number(),
  MaxPurchases: Yup.number()
})

const SubmitListener = () => {
  const formik = useFormikContext()
  const [lastValues, updateState] = useState(formik.values)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitForm = useCallback(
    debounce(() => formik.submitForm(), 500, { maxWait: 1500 }),
    []
  )

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values)
    const valuesEqualInitialValues = isEqual(
      formik.values,
      formik.initialValues
    )

    if (!valuesEqualLastValues) {
      updateState(formik.values)
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues) {
      submitForm()
    }
  }, [
    formik.values,
    formik.isValid,
    formik.initialValues,
    formik.submitForm,
    submitForm,
    lastValues
  ])

  return null
}

export const RuleForm = ({
  values,
  events,
  tickets,
  pendingEvents,
  pendingTickets,
  onRequestTicketsOfEvent,
  onSubmit
}) => {
  const [selectedEvents, setSelectedEvents] = useState([])
  const [selectedTickets, setSelectedTickets] = useState([])
  const classes = useStyles()

  const handleSubmit = (values) => {
    if (events.length) {
      const { Type } = values
      const payload = values

      payload.RequestedEventIds = selectedEvents.map((e) => e.Id)
      payload.RequestedTicketIds = selectedTickets

      if (Type === TICKET_RULE.DID_NOT_BUY_FROM_ANY_EVENT) {
        payload.RequestedEventIds = []
        payload.RequestedTicketIds = []
      }

      if (Type !== TICKET_RULE.BOUGHT_ANY_TICKET_FROM_EVENT)
        payload.RequestedTicketIds = []

      onSubmit(payload)
    }
  }

  const handleEventTicketSelectorChange = useCallback((events, tickets) => {
    setSelectedEvents(events)
    setSelectedTickets(tickets)
  }, [])

  return (
    <Formik
      initialValues={{
        Type: values?.Type || TICKET_RULE.BOUGHT_FROM_ANY_EVENT,
        Min: values?.Min || 1,
        MaxPurchases: values?.MaxPurchases || '',
        RequestedEventIds: values?.RequestedEventIds || [],
        RequestedTicketIds: values?.RequestedTicketIds || []
      }}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <>
          <SubmitListener />
          <FormControl variant='outlined' className={classes.fieldRow}>
            <InputLabel required>
              {(errors.Type && touched.Type && errors.Type) || 'Condición'}
            </InputLabel>
            <Select
              labelId='type-label'
              name='Type'
              id='type-select'
              value={values.Type}
              onChange={handleChange}
              className={classes.field}
              fullWidth
              size='small'
              label='Condición'>
              <MenuItem value={TICKET_RULE.BOUGHT_FROM_ANY_EVENT}>
                Compro cualquier otro evento
              </MenuItem>
              <MenuItem value={TICKET_RULE.BOUGHT_FROM_ALL_EVENTS}>
                Compro todos los eventos
              </MenuItem>
              <MenuItem value={TICKET_RULE.DID_NOT_BUY_FROM_ANY_EVENT}>
                No compro ningún evento
              </MenuItem>
              <MenuItem value={TICKET_RULE.BOUGHT_ANY_TICKET_FROM_EVENT}>
                Compro cualquier otro ticket
              </MenuItem>
            </Select>
          </FormControl>

          {values.Type === TICKET_RULE.BOUGHT_ANY_TICKET_FROM_EVENT ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}>
              <div className={classes.fieldRow}>
                <TextField
                  label={
                    (errors.Min && touched.Min && errors.Min) ||
                    'Min. de Tickets Incluidos'
                  }
                  name='Min'
                  type='number'
                  inputProps={{ min: 1, step: 1 }}
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = Number(value) <= 0 ? 1 : value
                  }}
                  fullWidth
                  value={values.Min}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Min && Boolean(errors.Min)}
                  required
                  size='small'
                  variant='outlined'
                  className={classes.field}
                />
              </div>
              <div className={classes.fieldRow}>
                <TextField
                  label={
                    (errors.MaxPurchases &&
                      touched.MaxPurchases &&
                      errors.MaxPurchases) ||
                    'Max. a comprar'
                  }
                  name='MaxPurchases'
                  type='number'
                  fullWidth
                  inputProps={{ min: 1, step: 1 }}
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = Number(value) <= 0 ? 1 : value
                  }}
                  value={values.MaxPurchases}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.MaxPurchases && Boolean(errors.MaxPurchases)}
                  required
                  size='small'
                  variant='outlined'
                  className={classes.field}
                />
              </div>
            </div>
          ) : null}

          {values.Type !== TICKET_RULE.DID_NOT_BUY_FROM_ANY_EVENT ? (
            <EventTicketSelector
              events={events}
              tickets={tickets}
              type={values.Type}
              selectedEventIds={values.RequestedEventIds}
              selectedTicketIds={values.RequestedTicketIds}
              pendingEvents={pendingEvents}
              pendingTickets={pendingTickets}
              onRequestTicketsOfEvent={onRequestTicketsOfEvent}
              onChange={handleEventTicketSelectorChange}
            />
          ) : null}
        </>
      )}
    </Formik>
  )
}

export default RuleForm
