import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableCell: {
    whiteSpace: 'break-spaces',
    minWidth: 100
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    padding: theme.spacing(2),
    textTransform: 'none',
    fontSize: 'large'
  },
  status: {
    width: 'fit-content',
    fontSize: 12,
    paddingInline: '6px',
    paddingBlock: '4px',
    borderRadius: '18px'
  },
  statusApproved: {
    color: 'white',
    backgroundColor: ' #4CAF50'
  },
  statusRejected: {
    color: 'black',
    backgroundColor: 'lightgrey'
  },
  statusPending: {
    color: 'black',
    backgroundColor: '#FFEB3B'
  },
  statusInProgress: {
    color: 'white',
    backgroundColor: '#2196F3'
  },
  statusNotSet: {
    color: 'white',
    backgroundColor: 'grey'
  },
  statusChargedBack: {
    color: 'white',
    backgroundColor: '#E91E63'
  },
  statusRefunded: {
    color: 'white',
    backgroundColor: '#673AB7'
  },
  statusCancelled: {
    color: 'white',
    backgroundColor: '#F44336'
  },
  statusInMediation: {
    color: 'white',
    backgroundColor: '#FF9800'
  }
}))

export default useStyles
