import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core'
import { PaperContainer } from '~/views/shared'
import { ROUTES } from '~/views/routes'
import { loadUser, mergeAccounts, resetUsers } from 'state/modules/users'
import history from '~/config/history'
import { useStyles } from './UnifyAccountsPage.style'
import { showSnackbarSuccess, showSnackbarError } from 'views/utils/snackbar'
import SearchIcon from '@material-ui/icons/Search'

export const UnifyAccountsPage = ({
  match,
  onBack,
  userSelected,
  setUserSelected
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [ids, setIds] = useState({})
  const { user, pending } = useSelector((state) => state.users)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'

  const createRow = (label, value, valueSelected = '') => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant='h6' className={classes.textValue}>
            {value}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant='h6' className={classes.textValue}>
            {valueSelected}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  const unifyAccounts = async () => {
    try {
      await dispatch(mergeAccounts(ids))
      setUserSelected(null)
      onBack(ids.UserId)
      showSnackbarSuccess('¡Cuentas Unificadas!')
    } catch (error) {
      console.error(error)
      showSnackbarError(error)
    } finally {
      setOpen(false)
    }
  }

  useEffect(() => {
    async function fetchData() {
      dispatch(resetUsers())
      dispatch(loadUser(match.params.userId))
    }
    fetchData().then()
  }, [dispatch, match])

  return (
    <PaperContainer title='Unificación de Cuentas'>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <div>
          <div className={classes.containerUsers}>
            <Button
              variant='contained'
              color='primary'
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.USERS.UNIFY.SEARCH.replace(
                    ':userId',
                    match.params.userId
                  )
                )
              }
              disabled={pending}
              startIcon={<SearchIcon />}>
              {pending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                'Buscar Usuario para unificar'
              )}
            </Button>
          </div>
          {pending ? (
            <CircularProgress size={24} color='secondary' />
          ) : (
            <div style={{ display: 'flex' }}>
              <Table style={{ width: '100%' }}>
                <TableBody>
                  {createRow('ID', user?.Id, userSelected?.Id)}
                  {createRow(
                    'Nombre de Usuario',
                    user?.UserName,
                    userSelected?.UserName
                  )}
                  {createRow(
                    documentId,
                    user?.DNI ? user?.DNI : '-',
                    userSelected
                      ? userSelected?.DNI
                        ? userSelected?.DNI
                        : '-'
                      : ''
                  )}
                  {createRow(
                    'Nombre Completo',
                    user?.FullName,
                    userSelected?.FullName
                  )}
                  {createRow(
                    'Nombre',
                    user?.FirstName,
                    userSelected?.FirstName
                  )}
                  {createRow(
                    'Apellido',
                    user?.LastName,
                    userSelected?.LastName
                  )}
                  {createRow('Email', user?.Email, userSelected?.Email)}
                  {createRow(
                    '¿Email confirmado?',
                    user?.EmailConfirmed ? 'Sí' : 'No',
                    userSelected
                      ? userSelected.EmailConfirmed
                        ? 'Sí'
                        : 'No'
                      : ''
                  )}
                  {createRow(
                    'Teléfono',
                    user?.PhoneNumber ? user?.PhoneNumber : '-',
                    userSelected
                      ? userSelected?.PhoneNumber
                        ? userSelected?.PhoneNumber
                        : '-'
                      : ''
                  )}
                  {createRow(
                    '¿Teléfono Confirmado?',
                    user?.PhoneNumberConfirmed ? 'Sí' : 'No',
                    userSelected
                      ? userSelected?.PhoneNumberConfirmed
                        ? 'Sí'
                        : 'No'
                      : ''
                  )}
                  {createRow(
                    '¿Cliente Gold?',
                    user?.Profile?.IsGold ? 'Sí' : 'No',
                    userSelected
                      ? userSelected?.Profile?.IsGold
                        ? 'Sí'
                        : 'No'
                      : ''
                  )}
                  {createRow(
                    'Cantidad de eventos asistidos',
                    user?.Profile?.PurchasedEventsCount,
                    userSelected?.Profile?.PurchasedEventsCount
                  )}
                  {createRow(
                    '¿Cliente baneado?',
                    user?.Profile?.IsBanned ? 'Sí' : 'No',
                    userSelected
                      ? userSelected?.Profile?.IsBanned
                        ? 'Sí'
                        : 'No'
                      : ''
                  )}
                </TableBody>
              </Table>
            </div>
          )}
          <div className={classes.containerButtons}>
            <div className={classes.containerButtonLeft}>
              <Button
                variant='contained'
                color='primary'
                disabled={userSelected == null}
                onClick={() => {
                  setOpen(true)
                  setIds({
                    UserId: user.Id,
                    DuplicatedUserId: userSelected.Id
                  })
                }}>
                Usar como Principal
              </Button>
            </div>
            <div className={classes.containerButtonRight}>
              <Button
                variant='contained'
                color='primary'
                disabled={userSelected == null}
                onClick={() => {
                  setOpen(true)
                  setIds({
                    UserId: userSelected.Id,
                    DuplicatedUserId: user.Id
                  })
                }}>
                Usar como Principal
              </Button>
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={() => onBack(match.params.userId)} color='primary'>
              Volver
            </Button>
          </div>
        </div>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>¡ADVERTENCIA!</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginRight: 20 }}>
            ¿Esta seguro de unificar las cuentas?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            disabled={false}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={unifyAccounts}
            disabled={false}
            color='primary'
            variant='contained'
            autoFocus>
            {pending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Unificar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

export default UnifyAccountsPage
