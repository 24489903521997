import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  loadingCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 300,
    minWidth: 300
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  containerTable: {
    padding: 5
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  containerRoles: {
    display: 'flex',
    marginBottom: -10
  },
  label: {
    marginTop: 10
  },
  labelReseller: {
    width: 230,
    fontWeight: 'bold'
  },
  value: {
    fontWeight: 'normal'
  },
  jsonPretty: {
    display: 'flex',
    width: 'fit-content'
  },
  button: {
    textTransform: 'none',
    marginBlock: -10
  },
  textButton: {
    display: 'flex',
    margin: 8
  },
  textDetails: {
    whiteSpace: 'break-spaces',
    minWidth: 100
  },
  checked: {
    height: 'min-content',
    alignSelf: 'center',
    '&.Mui-disabled': {
      color: 'green'
    }
  },
  notChecked: {
    height: 'min-content',
    alignSelf: 'center',
    '&.Mui-disabled': {
      color: 'darkred'
    }
  },
  separator: {
    backgroundColor: 'lightgray',
    border: 0,
    height: 1,
    marginBlock: 15
  },
  checkProducer: {
    display: 'flex',
    color: 'green',
    marginLeft: 18
  },
  textProducer: {
    display: 'flex',
    marginLeft: 25
  },
  checkReseller: {
    display: 'flex',
    color: 'green',
    marginLeft: 8
  },
  textReseller: {
    display: 'flex',
    marginLeft: 15
  },
  checkWithdrawpoint: {
    display: 'flex',
    color: 'green',
    marginLeft: 60
  },
  textWithdrawpoint: {
    display: 'flex',
    marginLeft: 67
  }
}))

export default useStyles
