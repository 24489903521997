import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Info } from '@material-ui/icons'
import JSONPretty from 'react-json-pretty'
import {
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import {
  PaperContainer,
  createColumn,
  createCustomColumn,
  TableGrid,
  TableGridOrder
} from '~views/shared'
import { useStyles } from './OrderRecordPage.style'
import { loadEvent } from '~/state/modules/events'
import moment from 'moment'
import {
  loadOrderHistoryPage,
  loadOrderHistoryPaged
} from '~/state/modules/orders'
import { useHistory } from 'react-router-dom'

const makeColumns = (setSelectedOrder, setShowInfoDialog, classes) => {
  return [
    createColumn('CreatedDate', 'Fecha', 'left', (x) =>
      moment(x).format('DD/MM/YYYY HH:mm')
    ),
    createColumn('Type', 'Tipo'),
    createCustomColumn(
      (c) => (
        <Typography className={classes.textDetails} variant='subtitle2'>
          {c.Details}
        </Typography>
      ),
      'Detalles'
    ),
    createColumn('Reason', 'Motivo'),
    createCustomColumn(
      (c) => (
        <Button
          className={classes.button}
          onClick={() => {
            setSelectedOrder(c)
            setShowInfoDialog(true)
          }}>
          <div className={classes.textButton}>
            <Info />
            &nbsp; Ver Detalle
          </div>
        </Button>
      ),
      'Información técnica'
    )
  ]
}
const makeFooterActions = () => []

export const OrderRecordPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [order, setOrder] = useState(null)
  const [user, setUser] = useState(null)
  const [pagedListFiltered, setPagedListFiltered] = useState(null)
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  const { pending, pagedList, reqParams } = useSelector((state) => state.orders)
  const { event } = useSelector((state) => state.events)
  const history = useHistory()
  const JSONPrettyMon = require('react-json-pretty/dist/monikai')

  const createLabel = (label, value) => {
    return (
      <Typography variant='h6' className={classes.label}>
        {label}:&nbsp;&nbsp;
        <Typography variant='h6' component={'span'} className={classes.value}>
          {value}
        </Typography>
      </Typography>
    )
  }

  const getDetailsArray = (detail) => {
    return detail?.split('(')[0]
  }

  const getReason = (detail) => {
    const reason = detail?.split('(')[1]
    return reason?.substring(0, reason.length - 1)
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
    if (pagedList && pagedList.items.length > 0) {
      setOrder(pagedList.items[0].Order)
      const firstValidOriginUser = pagedList.items.find(
        (item) => item.OriginUser !== null
      )
      if (firstValidOriginUser) {
        setUser(firstValidOriginUser.OriginUser)
      }
      const itemsFiltered = pagedList.items.map((v) => {
        return {
          ...v,
          Reason: getReason(v.Details),
          Details: getDetailsArray(v.Details)
        }
      })
      setPagedListFiltered({ ...pagedList, items: itemsFiltered })
    }
  }, [dispatch, match, pagedList])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrderHistoryPage(match.params.orderId))
      await dispatch(
        loadOrderHistoryPaged(match.params.orderId, {
          searchParams: '',
          pageNumber: 0,
          orderBy: 'Id',
          order: TableGridOrder.DESC
        })
      )
    }

    fetchData().then()
  }, [dispatch, match])

  const paymentStatus = {
    Pending: 'Pendiente',
    InProgress: 'En Progreso',
    InMediation: 'En Mediación',
    Approved: 'Aprobada',
    Rejected: 'Rechazada',
    Cancelled: 'Cancelada',
    Refunded: 'Devuelta',
    ChargedBack: 'Contracargo'
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Historial'>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <TableGrid
              title=''
              description={
                <>
                  &nbsp;
                  {createLabel('Id de Orden', order?.Id)}
                  {createLabel('Nombre', user?.FullName)}
                  {createLabel(
                    'Fecha de Compra',
                    moment(order?.CreatedDate).format('DD/MM/YYYY HH:mm')
                  )}
                  {createLabel(
                    'Estado Actual de Pago',
                    paymentStatus[order?.PaymentStatus]
                  )}
                </>
              }
              columns={makeColumns(
                setSelectedOrder,
                setShowInfoDialog,
                classes
              )}
              footerActions={makeFooterActions()}
              searchForm={() => {}}
              searchParams={''}
              loadItemsPage={() => {}}
              isLoading={pending}
              pagedList={pagedListFiltered}
              keyName='Id'
              pageSize={reqParams?.pageSize || 5}
              order={reqParams?.order || TableGridOrder.ASC}
              orderBy={reqParams?.orderBy || 'CreatedDate'}
              useNewFormat
              hideFooter
            />
            <Dialog
              open={showInfoDialog}
              onClose={() => setShowInfoDialog(false)}
              className={classes.dialog}>
              <DialogTitle>
                <Typography variant='h4'>
                  Detalle de Información técnica
                </Typography>
              </DialogTitle>
              <DialogContent>
                {selectedOrder?.RawData ? (
                  <JSONPretty
                    className={classes.jsonPretty}
                    data={selectedOrder.RawData}
                    theme={JSONPrettyMon}></JSONPretty>
                ) : (
                  <DialogContentText>
                    La orden no registra datos Históricos.
                  </DialogContentText>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowInfoDialog(false)}
                  color='primary'
                  autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <div className={classes.actionsContainer}>
              <Button onClick={() => history.goBack()} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default OrderRecordPage
