import axios from 'axios'
import BaseProxy from './BaseProxy'

export class DrawsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/draws`
  }

  async getAll() {
    const response = await axios.get(`${this._baseUrl}`)

    return response.data
  }

  async getById(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/manager`)

    return response.data
  }

  async getByEventId(eventId) {
    const response = await axios.get(`${this._baseUrl}/event/${eventId}`)

    return response.data
  }

  async getParticipants(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/participants`)

    return response.data
  }

  async post(draw) {
    const response = await axios.post(`${this._baseUrl}`, draw)

    return response.data
  }

  async run(id) {
    const response = await axios.post(`${this._baseUrl}/${id}/run`)

    return response.data
  }

  async deliver(id) {
    const response = await axios.post(`${this._baseUrl}/${id}/deliver`)

    return response.data
  }

  async put(id, draw) {
    const response = await axios.put(`${this._baseUrl}/${id}`, draw)

    return response.data
  }

  async delete(id) {
    const response = await axios.delete(`${this._baseUrl}/${id}`)
    return response.data
  }
}

export default DrawsProxy
