import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing()
  },
  containerCard: {
    marginBlock: 10,
    width: '100%',
    boxShadow: ' 0 3px 3px 0 rgba(0,0,0,0.2)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F3F4'
    }
  },
  typography: {
    color: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 10
  },
  containerDates: {
    paddingLeft: 10
  },
  typographyTitle: {
    backgroundColor: '#D3D3D3'
  }
}))

export default useStyles
