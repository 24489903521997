import React, { useState } from 'react'
import { useStyles } from './UsersList.style'
import { Typography } from '@material-ui/core'
import { ConfirmationDialog } from '~/views/shared'

export const UsersList = ({
  onSuccess,
  onCancel,
  loading,
  user,
  message,
  secondMessage,
  role
}) => {
  const classes = useStyles()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const handleConfirmation = async () => {
    await onSuccess(user.Id)
    setShowConfirmationDialog(false)
  }

  return (
    <div className={classes.container}>
      <div
        onClick={() => {
          setShowConfirmationDialog(true)
        }}
        className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {user?.FullName}
          </Typography>
        </div>
        <div className={classes.nameStyle}>
          <Typography variant='h6' color='primary' component='span'>
            {user?.Id}
          </Typography>
        </div>
        <hr className={classes.separator} />
      </div>
      <ConfirmationDialog
        open={showConfirmationDialog}
        loading={loading}
        handleConfirmation={handleConfirmation}
        onCancel={() => onCancel()}
        title='CONFIRMACIÓN'
        message={message}
        entityIdentifier={user?.FullName}
        optionalMessage={secondMessage}
        secondEntity={role}
      />
    </div>
  )
}

export default UsersList
