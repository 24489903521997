import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  dashboardContainer: {
    height: '100%'
  },
  loadingCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default useStyles
