import * as types from './types'

const initialState = {
  locations: [],
  organizers: [],
  eventTypes: [],
  users: null,
  pending: false,
  error: null,
  pendingUsers: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LOCATIONS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        locations: null
      }
    case types.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        locations: action.locations
      }
    case types.FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        locations: []
      }
    case types.FETCH_ORGANIZERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        organizers: null
      }
    case types.FETCH_ORGANIZERS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        organizers: action.organizers
      }
    case types.FETCH_ORGANIZERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        organizers: []
      }
    case types.FETCH_EVENTTYPES_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        eventTypes: null
      }
    case types.FETCH_EVENTTYPES_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        eventTypes: action.eventTypes
      }
    case types.FETCH_EVENTTYPES_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        eventTypes: []
      }
    case types.FETCH_USERS_ATTEMPT:
      return {
        ...state,
        pendingUsers: true,
        error: null,
        users: null
      }
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pendingUsers: false,
        error: null,
        users: action.users
      }
    case types.FETCH_USERS_FAILURE:
      return {
        ...state,
        pendingUsers: false,
        error: action.error,
        users: null
      }
    default:
      return state
  }
}
