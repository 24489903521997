import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useStyles } from './OrganizerProducersList.style'
import { Button, Typography } from '@material-ui/core'
import { DeleteDialog } from '~/views/shared'
import { deleteProducer } from '~/state/modules/organizers'
import { Delete } from '@material-ui/icons'
import { showSnackbarError } from 'views/utils/snackbar'

export const OrganizerProducersList = ({
  onSuccess,
  organizer,
  producer,
  id
}) => {
  const classes = useStyles()
  const { actionPending } = useSelector((state) => state.organizers)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const dispatch = useDispatch()

  const handleDelete = async () => {
    try {
      await dispatch(deleteProducer(id, producer.Id))
      setShowDeleteDialog(false)
      onSuccess(id)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {producer?.FullName}
          </Typography>
        </div>
        <hr className={classes.separator} />
      </div>

      <div className={classes.containerDelete}>
        <Button
          onClick={() => {
            setShowDeleteDialog(true)
          }}
          color='inherit'
          className={classes.delete}>
          <Delete />
          Borrar
        </Button>
      </div>
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title={'Quitar Usuario'}
        message={'Está por quitar el Usuario Administrador'}
        entityIdentifier={producer.FullName}
        optionalMessage={'del organizador'}
        secondEntity={organizer}
      />
    </div>
  )
}

export default OrganizerProducersList
