import React from 'react'
import { useStyles } from './TicketsList.style'
import numeral from 'numeral'
import { Button, Typography } from '@material-ui/core'

export const TicketsList = ({
  eventId,
  ticket,
  totalEntries,
  setTotalEntries,
  totalToPay,
  setTotalToPay,
  handleOrder,
  handleDecrementUnits
}) => {
  const classes = useStyles()

  const incrementTotals = () => {
    if (ticket.Units < ticket.Remaining) {
      setTotalEntries(totalEntries + 1)
      setTotalToPay(totalToPay + ticket.Price)
      ticket.Units = ticket.Units + 1
      handleOrder(
        {
          TicketTypeId: ticket.Id,
          Units: ticket.Units,
          AllowRemoteSell: ticket.AllowRemoteSell
        },
        eventId
      )
    }
  }

  const decrementTotals = () => {
    if (ticket.Units > 0) {
      setTotalEntries(totalEntries - 1)
      setTotalToPay(totalToPay - ticket.Price)
      ticket.Units = ticket.Units - 1
      handleOrder({
        TicketTypeId: ticket.Id,
        Units: ticket.Units,
        AllowRemoteSell: ticket.AllowRemoteSell
      })
      handleDecrementUnits(eventId)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerList}>
        <Typography variant='h5' className={classes.title}>
          {ticket.Name}
          <Typography className={classes.labelSubtitle}>
            Precio: $ {ticket.Price} &nbsp; Se rinde a:{' '}
            {numeral(ticket?.Cost).format('$ 0,0[.]00')} &nbsp; Quedan:{' '}
            {ticket.Remaining}
          </Typography>
        </Typography>
        <hr className={classes.separator} />
      </div>
      <div className={classes.containerButtons}>
        <Button className={classes.delete} onClick={() => incrementTotals()}>
          +
        </Button>
        <Typography>{ticket.Units}</Typography>
        <Button className={classes.delete} onClick={() => decrementTotals()}>
          -
        </Button>
      </div>
    </div>
  )
}

export default TicketsList
