import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core'

import { ROLES } from 'config/constants'
import { ROUTES } from '~/views/routes'
import { login } from '~/state/modules/login'
import { useStyles } from './LoginForm.style'

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Ingrese su nombre de usuario'),
  password: Yup.string().required('Ingrese su contraseña')
})

export const LoginForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { error } = useSelector((state) => state.login)

  const JWT_KEY = process.env.REACT_APP_JWT_KEY

  const handleSubmit = async (values, { setSubmitting }) => {
    const { username, password } = values

    try {
      setSubmitting(true)
      await dispatch(login(username, password))
      const roles = JSON.parse(localStorage.getItem(JWT_KEY)).roles
      if (roles?.includes(ROLES.ADMIN) || roles?.includes(ROLES.PRODUCER)) {
        history.push(ROUTES.ADMIN.ROOT)
      } else {
        history.push(ROUTES.ADMIN.EVENTS.INDEX)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    username: '',
    password: ''
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div>
            <Typography variant='h5' className={classes.title}>
              Iniciar Sesión
            </Typography>
            <Typography variant='h6' className={classes.subTitle}>
              #ticketPass Manager
            </Typography>
          </div>
          <div>
            <TextField
              label={
                (errors.username && touched.username && errors.username) ||
                'Usuario'
              }
              name='username'
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.username && Boolean(errors.username)}
              fullWidth
              size='small'
              required
              variant='outlined'
              className={classes.field}
            />
            <TextField
              label={
                (errors.password && touched.password && errors.password) ||
                'Contraseña'
              }
              name='password'
              type='password'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              fullWidth
              size='small'
              required
              variant='outlined'
              className={classes.field}
            />
          </div>
          {error ? (
            <Typography
              variant='caption'
              color='error'
              className={classes.errorMessage}>
              {error.response &&
                error.response.data &&
                error.response.data.message}
            </Typography>
          ) : null}
          <div className={classes.formButtons}>
            <Button
              className={classes.textButton}
              color='primary'
              variant='contained'
              type='submit'
              disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                <span>Ingresar con Usuario y Contraseña</span>
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
