import * as types from './types'
import { StatisticsProxy } from '~/services'
import { showSnackbarError } from '~/state/modules/app'

export const loadEventStatisticsAttempt = () => ({
  type: types.LOAD_EVENT_STATISTICS_ATTEMPT
})

export const loadEventStatisticsSuccess = (eventStatistics) => ({
  type: types.LOAD_EVENT_STATISTICS_SUCCESS,
  eventStatistics
})

export const loadEventStatisticsFailure = (error) => ({
  type: types.LOAD_EVENT_STATISTICS_FAILURE,
  error
})

export const loadTicketTypeStatisticsAttempt = () => ({
  type: types.LOAD_TICKETTYPE_STATISTICS_ATTEMPT
})

export const loadTicketTypeStatisticsSuccess = (ticketsStatistics) => ({
  type: types.LOAD_TICKETTYPE_STATISTICS_SUCCESS,
  ticketsStatistics
})

export const loadTicketTypeStatisticsFailure = (error) => ({
  type: types.LOAD_TICKETTYPE_STATISTICS_FAILURE,
  error
})

export const updateStatisticsAttempt = () => ({
  type: types.UPDATE_STATISTICS_ATTEMPT
})

export const updateStatisticsSuccess = (updateStatistics) => ({
  type: types.UPDATE_STATISTICS_SUCCESS,
  updateStatistics
})

export const updateStatisticsFailure = (error) => ({
  type: types.UPDATE_STATISTICS_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const loadEventStatistics = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventStatisticsAttempt())

    const proxy = new StatisticsProxy()
    const response = await proxy.getStatisticsOfEventById(eventId)

    dispatch(loadEventStatisticsSuccess(response))
    return true
  } catch (error) {
    dispatch(loadEventStatisticsFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const loadTicketsStatisticsByEvent = (eventId) => async (dispatch) => {
  try {
    dispatch(loadTicketTypeStatisticsAttempt())

    const proxy = new StatisticsProxy()
    const response = await proxy.getStatisticsOfEventByTicketType(eventId)

    dispatch(loadTicketTypeStatisticsSuccess(response))
    return true
  } catch (error) {
    dispatch(loadTicketTypeStatisticsFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const updateStatistics = (eventId) => async (dispatch) => {
  try {
    dispatch(updateStatisticsAttempt())

    const proxy = new StatisticsProxy()
    const response = await proxy.updateStatistics(eventId)

    dispatch(updateStatisticsSuccess(response))
    return true
  } catch (error) {
    dispatch(updateStatisticsFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}
