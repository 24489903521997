import * as types from './types'

const initialState = {
  pending: false,
  error: null,
  profile: null,
  user: null,
  pendingUser: false,
  pendingOrganizers: false,
  reqParams: null,
  pagedList: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_PROFILE_ATTEMPT:
      return {
        ...state,
        pending: true,
        profile: null,
        error: null
      }
    case types.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        profile: action.profile,
        error: null
      }
    case types.LOAD_PROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        profile: null,
        error: action.error
      }
    case types.SUBSCRIBE_ATTEMPT:
      return {
        ...state,
        pending: true
      }
    case types.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        pending: false
      }
    case types.SUBSCRIBE_FAILURE:
      return {
        ...state,
        pending: false
      }
    case types.CLEAN_DNI_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.CLEAN_DNI_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null
      }
    case types.CLEAN_DNI_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.CHANGE_EMAIL_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null
      }
    case types.CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.ALLOW_CHANGE_EMAIL_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.ALLOW_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null
      }
    case types.ALLOW_CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.LOAD_ALL_ROLES_ATTEMPT:
      return {
        ...state,
        pendingUser: true,
        user: null,
        error: null
      }
    case types.LOAD_ALL_ROLES_SUCCESS:
      return {
        ...state,
        pendingUser: false,
        user: action.user,
        error: null
      }
    case types.LOAD_ALL_ROLES_FAILURE:
      return {
        ...state,
        pendingUser: false,
        user: null,
        error: action.error
      }
    case types.LOAD_ORGANIZERS_PAGED_ATTEMPT:
      return {
        ...state,
        pendingOrganizers: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.LOAD_ORGANIZERS_PAGED_SUCCESS:
      return {
        ...state,
        pendingOrganizers: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.LOAD_ORGANIZERS_PAGED_FAILURE:
      return {
        ...state,
        pendingOrganizers: false,
        error: action.error,
        pagedList: null
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
