import React from 'react'
import { useDispatch } from 'react-redux'
import { UsersSearch } from '../../components/UsersSearch'
import { createProducer, loadProducers } from '~/state/modules/organizers'
import { showSnackbarError } from 'views/utils/snackbar'

export const ProducerCreatePage = ({
  match,
  onSuccess: onSuccessOrganizerPage,
  onCancel
}) => {
  const dispatch = useDispatch()

  const onSuccess = async (userId) => {
    try {
      await dispatch(createProducer(match.params.id, userId))
      dispatch(loadProducers(match.params.id))
      onSuccessOrganizerPage(match.params.id)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <UsersSearch
      match={match}
      onSuccess={onSuccess}
      onCancel={onCancel}
      message={'Está seguro que desea agregar el Usuario'}
    />
  )
}

export default ProducerCreatePage
