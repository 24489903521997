import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBillingSettings, loadOrganizer } from '~/state/modules/organizers'
import { loadAllCollections } from '~/state/modules/billing'
import { PaperContainer } from '~/views/shared'
import { BillingSettingsForm } from './BillingSettingsForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const BillingSettingsPage = ({ match }) => {
  const { pendingCollections, collections } = useSelector(
    (state) => state.billing
  )
  const { pendingSettings, organizer, pending } = useSelector(
    (state) => state.organizers
  )
  const organizerId = match?.params?.id
  const {
    Accounts,
    CashDrawers,
    CostCenters,
    PaymentMethods,
    DocumentTypes,
    VatTaxAliquots
  } = collections || {}
  const initialValues = useMemo(
    () => ({
      Account: '',
      Cash: '',
      VatTaxAliquot: '',
      CostCenter: '',
      PaymentMethod: '',
      InvoiceType: '',
      InvoiceBook: '',
      CreditNoteType: '',
      CreditNoteBook: ''
    }),
    []
  )
  const [values, setValues] = useState(initialValues)
  const [invoiceTypeId, setInvoiceTypeId] = useState('')
  const [creditNoteTypeId, setCreditNoteTypeId] = useState('')
  const dispatch = useDispatch()

  const onBack = () => {
    history.push(
      ROUTES.ADMIN.ORGANIZERS.BILLING.CREDENTIALS.replace(':id', organizerId)
    )
  }

  const handleSubmit = async (values) => {
    const payload = {
      Account: { Id: values.Account },
      CashDrawer: { Id: values.Cash },
      CostCenter: { Id: values.CostCenter },
      PaymentMethod: { Id: values.PaymentMethod },
      InvoiceDocumentType: { Id: values.InvoiceType },
      InvoiceDocumentBook: { Id: values.InvoiceBook },
      CreditNoteDocumentType: { Id: values.CreditNoteType },
      CreditNoteDocumentBook: { Id: values.CreditNoteBook },
      VatTaxAliquot: { Id: values.VatTaxAliquot }
    }
    try {
      await dispatch(addBillingSettings(organizerId, payload))
      showSnackbarSuccess(
        'La Configuración de Facturación se guardó correctamente.'
      )
      onBack()
    } catch (error) {
      showSnackbarError(error, 4500)
    }
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(organizerId))
      await dispatch(loadAllCollections(organizerId))
    }
    fetchData().then()
  }, [dispatch, organizerId])

  useEffect(() => {
    if (!!organizer) {
      if (!!organizer.BillingProviderSettings) {
        const {
          Account,
          CashDrawer,
          CostCenter,
          PaymentMethod,
          InvoiceDocumentType,
          InvoiceDocumentBook,
          CreditNoteDocumentType,
          CreditNoteDocumentBook,
          VatTaxAliquot
        } = JSON.parse(organizer.BillingProviderSettings)
        setInvoiceTypeId(InvoiceDocumentType.Id)
        setCreditNoteTypeId(CreditNoteDocumentType.Id)
        setValues({
          Account: Account.Id || '',
          Cash: CashDrawer.Id || '',
          VatTaxAliquot: VatTaxAliquot.Id || '',
          CostCenter: CostCenter.Id || '',
          PaymentMethod: PaymentMethod.Id || '',
          InvoiceType: InvoiceDocumentType.Id || '',
          InvoiceBook: InvoiceDocumentBook.Id || '',
          CreditNoteType: CreditNoteDocumentType.Id || '',
          CreditNoteBook: CreditNoteDocumentBook.Id || ''
        })
      } else {
        setValues({
          Account: collections?.Accounts[0]?.Id || '',
          Cash: collections?.CashDrawers[0]?.Id || '',
          VatTaxAliquot: collections?.VatTaxAliquots[0]?.Id || '',
          CostCenter: collections?.CostCenters[0]?.Id || '',
          PaymentMethod: collections?.PaymentMethods[0]?.Id || '',
          InvoiceType: '',
          InvoiceBook: '',
          CreditNoteType: '',
          CreditNoteBook: ''
        })
      }
    }
    return () => {
      setValues(initialValues)
    }
  }, [organizer, initialValues, collections])

  return (
    <PaperContainer
      title={`${
        pendingCollections || pending
          ? 'Cargando Datos...'
          : 'Configuración de Facturación'
      }`}
      loading={pendingCollections}>
      <BillingSettingsForm
        values={values}
        handleSubmit={handleSubmit}
        actionPending={pendingCollections || pendingSettings || pending}
        onCancel={onBack}
        organizerId={organizerId}
        Accounts={Accounts}
        CashDrawers={CashDrawers}
        CostCenters={CostCenters}
        PaymentMethods={PaymentMethods}
        DocumentTypes={DocumentTypes}
        VatTaxAliquots={VatTaxAliquots}
        InvoiceTypeId={invoiceTypeId}
        CreditNoteTypeId={creditNoteTypeId}
      />
    </PaperContainer>
  )
}

export default BillingSettingsPage
