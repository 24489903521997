import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Save } from '@material-ui/icons'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core'
import { isNotIntegerNumber } from 'views/utils/functions'
import { useStyles } from '../EventTicketForm.style'

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Es requerido ingresar nombre del ticket')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  TicketUsage: Yup.number(),
  Remaining: Yup.number()
    .required('Es requerido ingresar cantidad de tickets')
    .integer('Solo se permiten valores enteros')
    .min(0, 'Cantidad no puede ser menor a 0')
    .max(999999999, 'Excedió cantidad máxima de dígitos permitidos')
})

export const TicketInvitationForm = ({
  values,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const [state] = useState({
    checkedC: !values.DisabledDatesForm,
    checkedD: false
  })

  const validateValues = (values, handleSubmit) => {
    values.PaymentGatewayCommission = 0
    values.DisabledDatesForm = state.checkedC
    let allowedToRole = 0
    values.RoleAdministrator && (allowedToRole |= 1)
    values.RoleProducer && (allowedToRole |= 4)
    values.RoleRRPP && (allowedToRole |= 8)
    values.AllowedToRole = allowedToRole
    if (values.MaxByReseller !== '' && values.MaxByReseller != null) {
      values.MaxByReseller = parseInt(values.MaxByReseller)
    } else {
      values.MaxByReseller = null
    }
    values.Commissions = []
    handleSubmit()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.actionsContainerTop}>
              <Button
                onClick={onCancel}
                disabled={actionSuccess}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  validateValues(values, handleSubmit)
                }}
                disabled={actionPending || actionSuccess}
                color='primary'
                variant='contained'
                startIcon={<Save />}>
                {actionPending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  actionText
                )}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del ticket (visible al usuario)'
                }
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
                autoFocus
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Remaining && touched.Remaining && errors.Remaining) ||
                  'Cantidad'
                }
                name='Remaining'
                type='number'
                value={values.Remaining}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Remaining && Boolean(errors.Remaining)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label='Cantidad Máxima por RRPP (opcional)'
                name='MaxByReseller'
                type='string'
                value={
                  values.MaxByReseller ? parseInt(values.MaxByReseller) : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                onPaste={(e) => e.preventDefault()}
                inputProps={{ maxLength: 5 }}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                error={touched.MaxByReseller && Boolean(errors.MaxByReseller)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <Typography>Autorizados a enviar Invitaciones</Typography>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.RoleAdministrator}
                    onChange={handleChange}
                    name='RoleAdministrator'
                  />
                }
                label='Administrador'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.RoleProducer}
                    onChange={handleChange}
                    name='RoleProducer'
                  />
                }
                label='Productor'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                translate='no'
                control={
                  <Checkbox
                    checked={values.RoleRRPP}
                    onChange={handleChange}
                    name='RoleRRPP'
                  />
                }
                label='RRPP'
              />
            </div>
            &nbsp;
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsTransferable}
                    onChange={handleChange}
                    name='IsTransferable'
                  />
                }
                label='Es transferible?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Active}
                    onChange={handleChange}
                    name='Active'
                  />
                }
                label='Habilitado?'
              />
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={actionSuccess} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                validateValues(values, handleSubmit)
              }}
              disabled={actionPending || actionSuccess}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default TicketInvitationForm
