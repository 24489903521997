import * as types from './types'
import { AccountsProxy } from '~/services'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const createAccountAttempt = () => ({
  type: types.CREATE_ACCOUNT_ATTEMPT
})

export const createAccountSuccess = (macroAccount) => ({
  type: types.CREATE_ACCOUNT_SUCCESS,
  macroAccount
})

export const createAccountFailure = (error) => ({
  type: types.CREATE_ACCOUNT_FAILURE,
  error
})

export const refreshTokenAttempt = () => ({
  type: types.REFRESH_TOKEN_ATTEMPT
})

export const refreshTokenSuccess = (accountData) => ({
  type: types.REFRESH_TOKEN_SUCCESS,
  accountData
})

export const refreshTokenFailure = (error) => ({
  type: types.REFRESH_TOKEN_FAILURE,
  error
})

export const enableAccountAttempt = () => ({
  type: types.ENABLE_ACCOUNT_ATTEMPT
})

export const enableAccountSuccess = (organizerId, accountId) => ({
  type: types.ENABLE_ACCOUNT_SUCCESS,
  organizerId,
  accountId
})

export const enableAccountFailure = (error) => ({
  type: types.ENABLE_ACCOUNT_FAILURE,
  error
})

export const updateCommissionAttempt = () => ({
  type: types.UPDATE_COMMISSION_ATTEMPT
})

export const updateCommissionSuccess = (account) => ({
  type: types.UPDATE_COMMISSION_SUCCESS,
  account
})

export const updateCommissionFailure = (error) => ({
  type: types.UPDATE_COMMISSION_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const resetActions = () => ({
  type: types.RESET_ACTIONS
})

export const resetParamsLocations = () => ({
  type: types.RESET_PARAMS
})

export const loadPage =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageNumber, pageSize })
      )

      const proxy = new AccountsProxy()
      const pagedList = await proxy.get(
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(pagedListSuccess(pagedList))
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }

export const createAccount = (payload) => async (dispatch) => {
  try {
    dispatch(createAccountAttempt())

    const proxy = new AccountsProxy()
    const response = await proxy.createAccount(payload)

    dispatch(createAccountSuccess(response))
  } catch (error) {
    dispatch(createAccountFailure(error))
    throw error
  }
}

export const refreshToken = (accountId) => async (dispatch) => {
  try {
    dispatch(refreshTokenAttempt())

    const proxy = new AccountsProxy()
    const response = await proxy.refreshToken(accountId)

    dispatch(refreshTokenSuccess(response))
  } catch (error) {
    dispatch(refreshTokenFailure(error))
    throw error
  }
}

export const enableAccount = (organizerId, accountId) => async (dispatch) => {
  try {
    dispatch(enableAccountAttempt())

    const proxy = new AccountsProxy()
    const response = await proxy.enableAccount(organizerId, accountId)

    dispatch(enableAccountSuccess(response))
  } catch (error) {
    dispatch(enableAccountFailure(error))
    throw error
  }
}

export const updateCommission = (account) => async (dispatch) => {
  try {
    dispatch(updateCommissionAttempt())

    const proxy = new AccountsProxy()
    const response = await proxy.updateCommission(account)

    dispatch(updateCommissionSuccess(response))
  } catch (error) {
    dispatch(updateCommissionFailure(error))
    throw error
  }
}
