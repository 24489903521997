import axios from 'axios'
import qs from 'qs'
import BaseProxy from './BaseProxy'

export class AuthProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/token`
  }

  async login(username, password) {
    const response = await axios({
      method: 'post',
      url: this._baseUrl,
      data: qs.stringify({
        username,
        password,
        grant_type: 'password'
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })

    return response.data
  }
}

export default AuthProxy
