import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Save } from '@material-ui/icons'
import * as moment from 'moment'
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core'
import { isNotIntegerNumber } from 'views/utils/functions'
import { useStyles } from './OrganizerForm.style'

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Es requerido ingresar un nombre')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  Commission: Yup.number()
    .required('Es requerido ingresar la comisión')
    .moreThan(0, 'La Comisión debe ser mayor a 0'),
  FacebookPixelId: Yup.string().max(
    20,
    "La cantidad máxima de caracteres es de '20'"
  ),
  TaxIdentificationNumber: Yup.string()
    .required('Es requerido ingresar el Cuil')
    .max(20, "La cantidad máxima de dígitos es '20'"),
  CustomerName: Yup.string()
    .required('Es requerido ingresar el Nombre')
    .max(100, "La cantidad máxima de caracteres es de '100'"),
  Address: Yup.string()
    .required('Es requerido ingresar la Dirección')
    .max(100, "La cantidad máxima de caracteres es de '100'"),
  Phone: Yup.string()
    .required('Es requerido ingresar un Teléfono')
    .max(20, "La cantidad máxima de dígitos es '20'"),
  Email: Yup.string()
    .required('Es requerido ingresar un Email')
    .email('Ingrese un email válido')
})

export const OrganizerForm = ({
  values,
  mpCommissions,
  mpAutorized,
  mpUserId,
  mpExpirationDate,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  AutomaticInvoiceEnabled
}) => {
  const classes = useStyles()

  const createLabel = (label, value, isDate) => {
    return (
      <div className={classes.containerText}>
        <Typography variant='h6'>
          {label}:&nbsp;&nbsp;
          <Typography variant='h6' component='span' className={classes.value}>
            {isDate ? moment(value).format('DD/MM/YYYY HH:mm') : value}
          </Typography>
        </Typography>
      </div>
    )
  }

  const validateValues = (values) => {
    for (const key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim()
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.actionsContainerTop}>
              <Button
                onClick={onCancel}
                disabled={isSubmitting}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  validateValues(values)
                  handleSubmit()
                }}
                disabled={actionPending || isSubmitting}
                color='primary'
                variant='contained'
                startIcon={<Save />}>
                {actionPending || isSubmitting ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  actionText
                )}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del Organizador'
                }
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Commission &&
                    touched.Commission &&
                    errors.Commission) ||
                  '% Comisión ticketPass'
                }
                name='Commission'
                type='number'
                value={values.Commission}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Commission && Boolean(errors.Commission)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.FacebookPixelId &&
                    touched.FacebookPixelId &&
                    errors.FacebookPixelId) ||
                  'Facebook Pixel'
                }
                name='FacebookPixelId'
                value={values.FacebookPixelId}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                error={
                  touched.FacebookPixelId && Boolean(errors.FacebookPixelId)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel className={classes.selectLabelStyle}>
                Comisión MP por defecto
              </InputLabel>
              <Select
                name='DefaultCommissionMp'
                value={values.DefaultCommissionMp}
                className={classes.selectStyle}
                fullWidth
                required
                onChange={handleChange}
                onBlur={handleBlur}
                size='small'
                label='DefaultCommissionMp'>
                {mpCommissions &&
                  !mpCommissions.find(
                    (c) => c.Fee === values.DefaultCommissionMp
                  ) && (
                    <MenuItem value={values.DefaultCommissionMp}>
                      {values.DefaultCommissionMp}% (Comisión MP No Vigente)
                    </MenuItem>
                  )}
                {mpCommissions &&
                  mpCommissions.map((c) => (
                    <MenuItem key={c.Fee} value={c.Fee}>
                      {c.TypeFee}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Typography className={classes.billingLabel} variant='h6'>
              Datos de facturación
            </Typography>
            <hr className={classes.separator} />

            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.TaxIdentificationNumber &&
                    touched.TaxIdentificationNumber &&
                    errors.TaxIdentificationNumber) ||
                  'CUIL'
                }
                name='TaxIdentificationNumber'
                value={values.TaxIdentificationNumber}
                onChange={handleChange}
                required
                onBlur={handleBlur}
                autoComplete='off'
                inputProps={{ maxLength: 11 }}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                error={
                  touched.TaxIdentificationNumber &&
                  Boolean(errors.TaxIdentificationNumber)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.CustomerName &&
                    touched.CustomerName &&
                    errors.CustomerName) ||
                  'Nombre'
                }
                name='CustomerName'
                required
                value={values.CustomerName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.CustomerName && Boolean(errors.CustomerName)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel className={classes.selectLabelStyle}>
                tipo de persona
              </InputLabel>
              <Select
                labelId='CustomerType-label'
                name='CustomerType'
                id='ticketId-select'
                value={values?.CustomerType || 0}
                className={classes.selectStyle}
                fullWidth
                required
                onChange={handleChange}
                size='small'
                label={'CustomerType'}>
                <MenuItem value={0}>Persona Física</MenuItem>
                <MenuItem value={1}>Persona Jurídica</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Address && touched.Address && errors.Address) ||
                  'Dirección'
                }
                name='Address'
                required
                value={values.Address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Address && Boolean(errors.Address)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Phone && touched.Phone && errors.Phone) || 'Teléfono'
                }
                name='Phone'
                value={values.Phone}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Phone && Boolean(errors.Phone)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Email && touched.Email && errors.Email) || 'Email'
                }
                name='Email'
                required
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Email && Boolean(errors.Email)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>
          {mpAutorized && (
            <>
              <hr className={classes.separator} />
              {createLabel('MercadoPago Autorizado', 'Si')}
              {createLabel('MercadoPago UserId', mpUserId)}
              {createLabel(
                'MercadoPago Fecha de Expiración',
                mpExpirationDate,
                true
              )}
            </>
          )}
          {AutomaticInvoiceEnabled != null &&
            createLabel(
              'Facturación Automática',
              `${AutomaticInvoiceEnabled ? 'Si' : 'No'}`
            )}
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={isSubmitting} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                validateValues(values)
                handleSubmit()
              }}
              disabled={actionPending || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default OrganizerForm
