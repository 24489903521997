import axios from 'axios'
import BaseProxy from './BaseProxy'
export class ResellersProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/resellers`
  }

  async get(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getAll() {
    const response = await axios.get(`${this._baseUrl}/all`)
    return response.data
  }

  async getById(userId) {
    const response = await axios.get(`${this._baseUrl}/userid/${userId}`)
    return response.data
  }

  async post(reseller) {
    const response = await axios.post(`${this._baseUrl}`, reseller)
    return response.data
  }

  async put(userId, reseller) {
    const response = await axios.put(`${this._baseUrl}/${userId}`, reseller)

    return response.data
  }

  async delete(userId) {
    const response = await axios.delete(`${this._baseUrl}/${userId}`)
    return response.data
  }

  //RRPP

  async getResellerEventPaged(
    eventId,
    searchParams,
    pageNumber,
    pageSize,
    orderBy,
    order
  ) {
    const response = await axios.get(
      `${
        this._baseUrl
      }/event/${eventId}/sales/organizer/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getResellerEvent(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/sales/organizer`
    )
    return { content: response.data }
  }

  async loadSalesResellerDetails(resellerId, eventId) {
    const response = await axios.get(
      `${this._baseUrl}/${resellerId}/event/${eventId}/sales`
    )
    return response.data
  }

  //RRPP TP

  async getResellersTPSalesPaged(
    eventId,
    searchParams,
    pageNumber,
    pageSize,
    orderBy,
    order
  ) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/sales/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async loadResellersTPSales(eventId) {
    const response = await axios.get(`${this._baseUrl}/event/${eventId}/sales`)
    return { content: response.data }
  }

  async loadResellerTPDetails(resellerId, eventId) {
    const response = await axios.get(
      `${this._baseUrl}/${resellerId}/event/${eventId}/sales`
    )
    return response.data
  }

  //RRPP cash

  async getSalesResellerCashPaged(
    eventId,
    searchParams,
    pageNumber,
    pageSize,
    orderBy,
    order
  ) {
    const response = await axios.get(
      `${
        this._baseUrl
      }/v2/event/${eventId}/sales/cash/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async loadSalesResellerCash(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/v2/event/${eventId}/sales/cash`
    )
    return { content: response.data }
  }

  async loadSalesInfoResellerCash(resellerId, eventId) {
    const response = await axios.get(
      `${this._baseUrl}/${resellerId}/event/${eventId}/sales/cash`
    )
    return response.data
  }

  //PIN

  async generatePIN(pin) {
    const response = await axios.post(`${this._baseUrl}/me/pin`, pin)
    return response.data
  }

  async updatePIN(updatedPin) {
    const response = await axios.put(`${this._baseUrl}/me/pin`, updatedPin)
    return response.data
  }

  async resetPIN(userId, pin) {
    const response = await axios.post(`${this._baseUrl}/${userId}/pin`, pin)
    return response.data
  }

  async getEventsWithTicketsCash() {
    const response = await axios.get(`${this._baseUrl}/tickets/cash`)
    return response.data.sort((a, b) => a.Title.localeCompare(b.Title))
  }

  async generateQR(userId, eventId, ticketsOrder, pin) {
    const payload = {
      UserId: userId,
      EventId: eventId,
      TicketsOrder: ticketsOrder
    }
    const response = await axios.post(`${this._baseUrl}/sales`, payload, {
      headers: {
        'X-Reseller-PIN': pin
      }
    })

    return response.data
  }
}

export default ResellersProxy
