import * as types from './types'
import { OrdersProxy } from 'services/OrdersProxy'
import { mockPagedList } from '../../../views/shared'

export const loadOrdersListAttempt = () => ({
  type: types.LOAD_ORDERS_LIST_ATTEMPT
})

export const loadOrdersListSuccess = (ordersList) => ({
  type: types.LOAD_ORDERS_LIST_SUCCESS,
  ordersList
})

export const loadOrdersListFailure = (error) => ({
  type: types.LOAD_ORDERS_LIST_FAILURE,
  error
})

export const loadTotalTicketsAttempt = () => ({
  type: types.LOAD_TOTAL_TICKETS_ATTEMPT
})

export const loadTotalTicketsSuccess = (totalTickets, totalWithdrawn) => ({
  type: types.LOAD_TOTAL_TICKETS_SUCCESS,
  totalTickets,
  totalWithdrawn
})

export const loadTotalTicketsFailure = (error) => ({
  type: types.LOAD_TOTAL_TICKETS_FAILURE,
  error
})

export const loadOrdersByTicketAttempt = () => ({
  type: types.LOAD_ORDERS_BY_TICKET_ATTEMPT
})

export const loadOrdersByTicketSuccess = (orders) => ({
  type: types.LOAD_ORDERS_BY_TICKET_SUCCESS,
  orders
})

export const loadOrdersByTicketFailure = (error) => ({
  type: types.LOAD_ORDERS_BY_TICKET_FAILURE,
  error
})

export const loadAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadSuccess = (orderObj) => ({
  type: types.LOAD_SUCCESS,
  orderObj
})

export const loadFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (orderObj) => ({
  type: types.CREATE_SUCCESS,
  orderObj
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateSuccess = (orderObj) => ({
  type: types.UPDATE_SUCCESS,
  orderObj
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteSuccess = (orderObj) => ({
  type: types.DELETE_SUCCESS,
  orderObj
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

export const loadOrderEventAttempt = () => ({
  type: types.LOAD_ORDERS_EVENT_ATTEMPT
})

export const loadOrderEventSuccess = (orderEvent) => ({
  type: types.LOAD_ORDERS_EVENT_SUCCESS,
  orderEvent
})

export const loadOrderEventFailure = (error) => ({
  type: types.LOAD_ORDERS_EVENT_FAILURE,
  error
})

export const loadOrderEventPagedAttempt = (reqParams) => ({
  type: types.LOAD_ORDERS_EVENT_PAGED_ATTEMPT,
  reqParams
})

export const loadOrderEventPagedSuccess = (pagedListByEvent) => ({
  type: types.LOAD_ORDERS_EVENT_PAGED_SUCCESS,
  pagedListByEvent
})

export const loadOrderEventPagedFailure = (error) => ({
  type: types.LOAD_ORDERS_EVENT_PAGED_FAILURE,
  error
})

export const loadOrderHistoryAttempt = () => ({
  type: types.LOAD_ORDERS_HISTORY_ATTEMPT
})

export const loadOrderHistorySuccess = (orderEvent) => ({
  type: types.LOAD_ORDERS_HISTORY_SUCCESS,
  orderEvent
})

export const loadOrderHistoryFailure = (error) => ({
  type: types.LOAD_ORDERS_HISTORY_FAILURE,
  error
})

export const loadOrderHistoryPagedAttempt = (reqParams) => ({
  type: types.LOAD_ORDER_HISTORY_PAGED_ATTEMPT,
  reqParams
})

export const loadOrderHistoryPagedSuccess = (pagedList) => ({
  type: types.LOAD_ORDER_HISTORY_PAGED_SUCCESS,
  pagedList
})

export const loadOrderHistoryPagedFailure = (error) => ({
  type: types.LOAD_ORDER_HISTORY_PAGED_FAILURE,
  error
})

export const loadPaymentsPagedAttempt = (reqParams) => ({
  type: types.LOAD_PAYMENTS_PAGED_ATTEMPT,
  reqParams
})

export const loadPaymentsPagedSuccess = (pagedList) => ({
  type: types.LOAD_PAYMENTS_PAGED_SUCCESS,
  pagedList
})

export const loadPaymentsPagedFailure = (error) => ({
  type: types.LOAD_PAYMENTS_PAGED_FAILURE,
  error
})

export const loadOrderDetailsAttempt = () => ({
  type: types.LOAD_ORDER_DETAILS_ATTEMPT
})

export const loadOrderDetailsSuccess = (order) => ({
  type: types.LOAD_ORDER_DETAILS_SUCCESS,
  order
})

export const loadOrderDetailsFailure = (error) => ({
  type: types.LOAD_ORDER_DETAILS_FAILURE,
  error
})

export const loadTicketsPerOrderAttempt = () => ({
  type: types.LOAD_TICKETS_PER_ORDER_ATTEMPT
})

export const loadTicketsPerOrderSuccess = (orderEvent) => ({
  type: types.LOAD_TICKETS_PER_ORDER_SUCCESS,
  orderEvent
})

export const loadTiketsPerOrderFailure = (error) => ({
  type: types.LOAD_TICKETS_PER_ORDER_FAILURE,
  error
})

export const cancelOrderAttempt = () => ({
  type: types.CANCEL_ORDER_ATTEMPT
})

export const cancelOrderSuccess = (orderEvent) => ({
  type: types.CANCEL_ORDER_SUCCESS,
  orderEvent
})

export const cancelOrderFailure = (error) => ({
  type: types.CANCEL_ORDER_FAILURE,
  error
})

export const refundOrderAttempt = () => ({
  type: types.REFUND_ORDER_ATTEMPT
})

export const refundOrderSuccess = (refundObj) => ({
  type: types.REFUND_ORDER_SUCCESS,
  refundObj
})

export const refundOrderFailure = (error) => ({
  type: types.REFUND_ORDER_FAILURE,
  error
})

export const transferOrderAttempt = () => ({
  type: types.TRANSFER_ORDER_ATTEMPT
})

export const transferOrderSuccess = (transferObj) => ({
  type: types.TRANSFER_ORDER_SUCCESS,
  transferObj
})

export const transferOrderFailure = (error) => ({
  type: types.TRANSFER_ORDER_FAILURE,
  error
})

export const refreshOrderAttempt = () => ({
  type: types.REFRESH_ORDER_MP_ATTEMPT
})

export const refreshOrderSuccess = (orderObj) => ({
  type: types.REFRESH_ORDER_MP_SUCCESS,
  orderObj
})

export const refreshOrderFailure = (error) => ({
  type: types.REFRESH_ORDER_MP_FAILURE,
  error
})

export const manualUpdatePaymentStatusAttempt = () => ({
  type: types.MANUAL_UPDATE_PAYMENT_STATE_ATTEMPT
})

export const manualUpdatePaymentStatusSuccess = (paymentStatus) => ({
  type: types.MANUAL_UPDATE_PAYMENT_STATE_SUCCESS,
  paymentStatus
})

export const manualUpdatePaymentStatusFailure = (error) => ({
  type: types.MANUAL_UPDATE_PAYMENT_STATE_FAILURE,
  error
})

export const loadOrdersUserAttempt = () => ({
  type: types.LOAD_ORDERS_USER_ATTEMPT
})

export const loadOrdersUserSuccess = (ordersUser) => ({
  type: types.LOAD_ORDERS_USER_SUCCESS,
  ordersUser
})

export const loadOrdersUserFailure = (error) => ({
  type: types.LOAD_ORDERS_USER_FAILURE,
  error
})

export const loadOrdersUserPagedAttempt = (reqParams) => ({
  type: types.LOAD_ORDERS_USER_PAGED_ATTEMPT,
  reqParams
})

export const loadOrdersUserPagedSuccess = (ordersPagedList) => ({
  type: types.LOAD_ORDERS_USER_PAGED_SUCCESS,
  ordersPagedList
})

export const loadOrdersUserPagedFailure = (error) => ({
  type: types.LOAD_ORDERS_USER_PAGED_FAILURE,
  error
})

export const loadOrdersPagedAttempt = (reqParams) => ({
  type: types.LOAD_ORDERS_PAGED_ATTEMPT,
  reqParams
})

export const loadOrdersPagedSuccess = (ordersPaged) => ({
  type: types.LOAD_ORDERS_PAGED_SUCCESS,
  ordersPaged
})

export const loadOrdersPagedFailure = (error) => ({
  type: types.LOAD_ORDERS_PAGED_FAILURE,
  error
})

export const loadTicketsUserAttempt = () => ({
  type: types.LOAD_TICKETS_USER_ATTEMPT
})

export const loadTicketsUserSuccess = (ticketsUser) => ({
  type: types.LOAD_TICKETS_USER_SUCCESS,
  ticketsUser
})

export const loadTicketsUserFailure = (error) => ({
  type: types.LOAD_TICKETS_USER_FAILURE,
  error
})

export const sendGiftTicketAttempt = () => ({
  type: types.SEND_GIFT_TICKET_ATTEMPT
})

export const sendGiftTicketSuccess = () => ({
  type: types.SEND_GIFT_TICKET_SUCCESS
})

export const sendGiftTicketFailure = (error) => ({
  type: types.SEND_GIFT_TICKET_FAILURE,
  error
})

export const postQRAttempt = () => ({
  type: types.POST_QR_ATTEMPT
})

export const postQRSuccess = (postSuccess) => ({
  type: types.POST_QR_SUCCESS,
  postSuccess
})

export const postQRFailure = (error) => ({
  type: types.POST_QR_FAILURE,
  error
})

export const assignPaymentAttempt = () => ({
  type: types.ASSIGN_PAYMENT_ATTEMPT
})

export const assignPaymentSuccess = () => ({
  type: types.ASSIGN_PAYMENT_SUCCESS
})

export const assignPaymentFailure = (error) => ({
  type: types.ASSIGN_PAYMENT_FAILURE,
  error
})

export const refundPaymentAttempt = () => ({
  type: types.REFUND_PAYMENT_ATTEMPT
})

export const refundPaymentSuccess = () => ({
  type: types.REFUND_PAYMENT_SUCCESS
})

export const refundPaymentFailure = (error) => ({
  type: types.REFUND_PAYMENT_FAILURE,
  error
})

export const loadTicketsAmountAttempt = () => ({
  type: types.LOAD_TICKETS_AMOUNT_ATTEMPT
})

export const loadTicketsAmountSuccess = (ticketsAmount) => ({
  type: types.LOAD_TICKETS_AMOUNT_SUCCESS,
  ticketsAmount
})

export const loadTicketsAmountFailure = (error) => ({
  type: types.LOAD_TICKETS_AMOUNT_FAILURE,
  error
})

export const recoverTicketsAttempt = () => ({
  type: types.RECOVER_TICKETS_ATTEMPT
})

export const recoverTicketsSuccess = (ticketsRecovered) => ({
  type: types.RECOVER_TICKETS_SUCCESS,
  ticketsRecovered
})

export const recoverTicketsFailure = (error) => ({
  type: types.RECOVER_TICKETS_FAILURE,
  error
})

export const generateBillingAttempt = () => ({
  type: types.GENERATE_BILLING_ATTEMPT
})

export const generateBillingSuccess = (billingGenerated) => ({
  type: types.GENERATE_BILLING_SUCCESS,
  billingGenerated
})

export const generateBillingFailure = (error) => ({
  type: types.GENERATE_BILLING_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const filtersSet = (filters, searchKey) => ({
  type: types.SET_FILTERS,
  filters,
  searchKey
})

export const filtersSearchSet = (filtersSearch) => ({
  type: types.SET_FILTERS_SEARCH,
  filtersSearch
})

export const loadOrdersList = (searchKey, id) => async (dispatch) => {
  try {
    dispatch(loadOrdersListAttempt())

    const proxy = new OrdersProxy()
    const ticketsByEvent = await proxy.getTicketsByEvent(id)
    if (searchKey == null) {
      dispatch(loadOrdersListSuccess(ticketsByEvent))
    } else {
      const response = ticketsByEvent.filter(
        (order) =>
          order.FullName?.toUpperCase()
            .trim()
            .includes(searchKey.toUpperCase().trim()) ||
          order.DNI?.trim().startsWith(searchKey.trim()) ||
          order.Tickets.some((t) =>
            t.ConfirmationCode?.toUpperCase()
              .trim()
              .includes(searchKey.toUpperCase().trim())
          )
      )
      dispatch(loadOrdersListSuccess(response))
    }
  } catch (error) {
    dispatch(loadOrdersListFailure(error))
    throw error
  }
}

export const loadTotalTickets = (id) => async (dispatch) => {
  try {
    dispatch(loadTotalTicketsAttempt())

    const proxy = new OrdersProxy()
    const ticketsByEvent = await proxy.getTicketsByEvent(id)
    const totalTickets = proxy.getTotalTickets(ticketsByEvent)
    const totalWithdrawn = proxy.getTotalWithdrawn(ticketsByEvent)
    dispatch(loadTotalTicketsSuccess(totalTickets, totalWithdrawn))
  } catch (error) {
    dispatch(loadTotalTicketsFailure(error))
    throw error
  }
}

export const loadOrdersByTicket = (idEvent, idTicket) => async (dispatch) => {
  try {
    dispatch(loadOrdersByTicketAttempt({}))

    const proxy = new OrdersProxy()
    const orders = await proxy.getOrdersByTicket(idEvent, idTicket)

    dispatch(loadOrdersByTicketSuccess(orders))
  } catch (error) {
    dispatch(loadOrdersByTicketFailure(error))
    throw error
  }
}

export const update = (orderObj) => async (dispatch) => {
  try {
    dispatch(updateAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.put(orderObj)

    dispatch(updateSuccess(response))
  } catch (error) {
    dispatch(updateFailure(error))
    throw error
  }
}

export const loadOrderPage = (x, id) => async (dispatch) => {
  try {
    dispatch(loadOrderEventAttempt({}))

    const proxy = new OrdersProxy()
    const response = await proxy.getOrdersByEvent(id)
    dispatch(loadOrderEventSuccess({ content: response }))
  } catch (error) {
    dispatch(loadOrderEventFailure(error))
    throw error
  }
}

export const loadOrderByEventPage = (idEvent, filters) => async (dispatch) => {
  try {
    dispatch(loadOrderEventAttempt())
    const proxy = new OrdersProxy()
    const response = await proxy.getOrdersByEventByStatus(
      idEvent,
      filters.status,
      filters.searchKey
    )
    dispatch(loadOrderEventSuccess({ content: response }))
    return response
  } catch (error) {
    dispatch(loadOrderEventFailure(error))
    throw error
  }
}

export const loadOrderByEventPaged =
  (idEvent, status, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadOrderEventPagedAttempt({ searchParams, order, orderBy }))
      const proxy = new OrdersProxy()
      const pagedList = await proxy.getOrdersByEventByStatusPaged(
        idEvent,
        status,
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )
      dispatch(loadOrderEventPagedSuccess(pagedList))
    } catch (error) {
      dispatch(loadOrderEventPagedFailure(error))
      throw error
    }
  }

export const loadOrderHistoryPage = (id) => async (dispatch) => {
  try {
    dispatch(loadOrderHistoryAttempt({}))

    const proxy = new OrdersProxy()
    const response = await proxy.getHistoryByOrder(id)
    dispatch(loadOrderHistorySuccess(response))
  } catch (error) {
    dispatch(loadOrderHistoryFailure(error))
    throw error
  }
}

export const loadOrderHistoryPaged =
  (id, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadOrderHistoryPagedAttempt({ searchParams, order, orderBy }))

      const proxy = new OrdersProxy()
      const response = await proxy.getHistoryByOrder(id)

      dispatch(
        loadOrderHistoryPagedSuccess(
          mockPagedList(response, pageSize, pageNumber, order, orderBy)
        )
      )
    } catch (e) {
      dispatch(loadOrderHistoryPagedFailure(e))
      throw e
    }
  }

export const loadPaymentsPaged =
  (id, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadPaymentsPagedAttempt({ searchParams, order, orderBy }))

      const proxy = new OrdersProxy()
      const response = await proxy.getPaymentsByOrder(id)
      dispatch(
        loadPaymentsPagedSuccess(
          mockPagedList(response, pageSize, pageNumber, order, orderBy)
        )
      )
    } catch (e) {
      dispatch(loadPaymentsPagedFailure(e))
      throw e
    }
  }

export const loadOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch(loadOrderDetailsAttempt({}))

    const proxy = new OrdersProxy()
    const response = await proxy.getOrderById(id)
    dispatch(loadOrderDetailsSuccess(response))
    return response
  } catch (error) {
    dispatch(loadOrderDetailsFailure(error))
    throw error
  }
}

export const loadTicketPerOrderPage = (orderId) => async (dispatch) => {
  try {
    dispatch(loadTicketsPerOrderAttempt({}))

    const proxy = new OrdersProxy()
    const response = await proxy.getTicketPerOrder(orderId)
    dispatch(loadTicketsPerOrderSuccess(response))
  } catch (error) {
    dispatch(loadTiketsPerOrderFailure(error))
    throw error
  }
}

export const cancelOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(cancelOrderAttempt({}))

    const proxy = new OrdersProxy()
    const response = await proxy.cancelOrder(orderId)
    dispatch(cancelOrderSuccess(response))
  } catch (error) {
    dispatch(cancelOrderFailure(error))
    throw error
  }
}

export const refreshOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(refreshOrderAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.refreshOrder(orderId)
    dispatch(refreshOrderSuccess(response))
  } catch (error) {
    dispatch(refreshOrderFailure(error))
    throw error
  }
}

export const changeManualUpdatePaymentStatus =
  (orderId, paymentStatus, reason) => async (dispatch) => {
    try {
      dispatch(manualUpdatePaymentStatusAttempt({}))

      const proxy = new OrdersProxy()
      const response = await proxy.manualUpdatePaymentStatusOrder(
        orderId,
        paymentStatus,
        reason
      )
      dispatch(manualUpdatePaymentStatusSuccess(response))
    } catch (error) {
      dispatch(manualUpdatePaymentStatusFailure(error))
      throw error
    }
  }

export const refundOrder = (orderId, ChangeReason) => async (dispatch) => {
  try {
    dispatch(refundOrderAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.refundOrder(orderId, ChangeReason)
    dispatch(refundOrderSuccess(response))
  } catch (error) {
    dispatch(refundOrderFailure(error))
    throw error
  }
}

export const transferOrder = (orderId, userId) => async (dispatch) => {
  try {
    dispatch(transferOrderAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.transferOrder(orderId, userId)
    dispatch(transferOrderSuccess(response))
  } catch (error) {
    dispatch(transferOrderFailure(error))
    throw error
  }
}

export const loadOrdersUser = (userId) => async (dispatch) => {
  try {
    dispatch(loadOrdersUserAttempt({}))
    const proxy = new OrdersProxy()
    const response = await proxy.getOrdersUser(userId)
    dispatch(loadOrdersUserSuccess(response))
  } catch (e) {
    dispatch(loadOrdersUserFailure(e))
    throw e
  }
}

export const loadOrdersUserPaged =
  (userId, searchKey, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadOrdersUserPagedAttempt({ searchParams, order, orderBy }))

      const proxy = new OrdersProxy()
      const response = await proxy.getOrdersUser(userId)
      if (searchKey == null || searchKey === '') {
        dispatch(
          loadOrdersUserPagedSuccess(
            mockPagedList(response, pageSize, pageNumber, order, orderBy)
          )
        )
      } else {
        const filterResponse = response.filter((order) =>
          order.Evento?.toUpperCase()
            .trim()
            .includes(searchKey.toUpperCase().trim())
        )
        dispatch(
          loadOrdersUserPagedSuccess(
            mockPagedList(filterResponse, pageSize, pageNumber, order, orderBy)
          )
        )
      }
      return response
    } catch (error) {
      dispatch(loadOrdersUserPagedFailure(error))
      throw error
    }
  }

export const loadOrdersPaged =
  (payload, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        loadOrdersPagedAttempt({
          searchParams,
          order,
          orderBy,
          pageNumber,
          pageSize
        })
      )

      const proxy = new OrdersProxy()
      const pagedList = await proxy.getOrdersPaged(
        payload,
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )
      dispatch(loadOrdersPagedSuccess(pagedList))
      return pagedList
    } catch (error) {
      dispatch(loadOrdersPagedFailure(error))
      throw error
    }
  }

export const loadTicketsUser = (userId) => async (dispatch) => {
  try {
    dispatch(loadTicketsUserAttempt({}))
    const proxy = new OrdersProxy()
    const response = await proxy.getTicketsUser(userId)
    dispatch(loadTicketsUserSuccess(response))
  } catch (e) {
    dispatch(loadTicketsUserFailure(e))
    throw e
  }
}

export const sendGiftTicket = (giftTicket) => async (dispatch) => {
  try {
    dispatch(sendGiftTicketAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.sendGiftTicket(giftTicket)
    dispatch(sendGiftTicketSuccess(response))
  } catch (error) {
    dispatch(sendGiftTicketFailure(error))
    throw error
  }
}

export const postQR = (code) => async (dispatch) => {
  try {
    dispatch(postQRAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.postQR(code)
    dispatch(postQRSuccess(response))
  } catch (error) {
    dispatch(postQRFailure(error))
    throw error
  }
}

export const assignPayment =
  (orderId, providerPaymentId, changeReason) => async (dispatch) => {
    try {
      dispatch(assignPaymentAttempt())

      const proxy = new OrdersProxy()
      const response = await proxy.assignPayment(
        orderId,
        providerPaymentId,
        changeReason
      )
      dispatch(assignPaymentSuccess(response))
    } catch (error) {
      dispatch(assignPaymentFailure(error))
      throw error
    }
  }

export const refundPayment =
  (orderId, providerPaymentId, changeReason) => async (dispatch) => {
    try {
      dispatch(refundPaymentAttempt())

      const proxy = new OrdersProxy()
      const response = await proxy.refundPayment(
        orderId,
        providerPaymentId,
        changeReason
      )
      dispatch(refundPaymentSuccess(response))
    } catch (error) {
      dispatch(refundPaymentFailure(error))
      throw error
    }
  }

export const loadTicketsAmount = (eventId, status) => async (dispatch) => {
  try {
    dispatch(loadTicketsAmountAttempt({}))
    const proxy = new OrdersProxy()
    const response = await proxy.getTicketsAmount(eventId, status)
    dispatch(loadTicketsAmountSuccess(response))
  } catch (e) {
    dispatch(loadTicketsAmountFailure(e))
    throw e
  }
}

export const recoverTickets = (orderId) => async (dispatch) => {
  try {
    dispatch(recoverTicketsAttempt())

    const proxy = new OrdersProxy()
    const response = await proxy.recoverTickets(orderId)
    dispatch(recoverTicketsSuccess(response))
  } catch (error) {
    dispatch(recoverTicketsFailure(error))
    throw error
  }
}

export const generateBilling =
  (orderId, type, billingDate) => async (dispatch) => {
    try {
      dispatch(generateBillingAttempt())

      const proxy = new OrdersProxy()
      const response = await proxy.generateBilling(orderId, type, billingDate)
      dispatch(generateBillingSuccess(response))
    } catch (error) {
      dispatch(generateBillingFailure(error))
      throw error
    }
  }

export const setFilters = (filters, searchKey) => (dispatch) => {
  dispatch(filtersSet({ status: filters, searchKey }))
}

export const setFiltersSearch = (filtersSearch) => (dispatch) => {
  dispatch(filtersSearchSet(filtersSearch))
}
