import React from 'react'
import ContentLoader from 'react-content-loader'
import { useStyles } from './TableContentLoading.style'
export const TableContentLoading = () => {
  const classes = useStyles()
  return (
    <ContentLoader className={classes.container}>
      <rect x='0' y='0' rx='0' ry='0' width='100%' height='30' />
      <rect x='0' y='35' rx='0' ry='0' width='100%' height='1' />
      <rect x='0' y='40' rx='0' ry='0' width='100%' height='30' />
      <rect x='0' y='75' rx='0' ry='0' width='100%' height='1' />
      <rect x='0' y='80' rx='0' ry='0' width='100%' height='30' />
      <rect x='0' y='115' rx='0' ry='0' width='100%' height='1' />
      <rect x='0' y='120' rx='0' ry='0' width='100%' height='30' />
    </ContentLoader>
  )
}

export default TableContentLoading
