import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useStyles } from './UserOrdersIndexPage.style'
import { ROUTES } from 'views/routes'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import {
  Info,
  Visibility,
  FileCopyOutlined,
  ArrowBack
} from '@material-ui/icons'
import { SearchComponent } from '~views/shared'
import { loadOrdersUserPaged, setFilters } from '~/state/modules/orders'
import { loadUser } from 'state/modules/users'
import { ROLES } from 'config/constants'
import copy from 'copy-to-clipboard'
import { TableGrid, createCustomColumn, TableGridOrder } from '~/views/shared'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

const makeColumns = (
  userId,
  isAdmin,
  history,
  paymentStatus,
  handleOpen,
  classes
) => {
  return [
    ...(isAdmin
      ? [
          createCustomColumn(
            (c) => (
              <IconButton
                onClick={() => {
                  handleOpen(c.Id)
                }}>
                <Visibility />
              </IconButton>
            ),
            'ID'
          )
        ]
      : []),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>{c.FechaDeCompra}</Typography>
      ),
      'Fecha de Compra'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>
          {c.EventoFechaInicio}
        </Typography>
      ),
      'Fecha del Evento'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>{`${
          isAdmin ? `(${c.EventoId}) ` : ''
        }${c.Evento}`}</Typography>
      ),
      'Nombre del Evento'
    ),
    createCustomColumn((c) => {
      const tickets = c.Entradas
      return tickets.map((t) => (
        <Typography
          key={t.Id}
          className={
            classes.tableCell
          }>{`${t.Tipo}: ${t.Cantidad}`}</Typography>
      ))
    }, 'Tickets'),
    createCustomColumn(
      (c) => (
        <Typography
          className={clsx({
            [classes.status]: true,
            [classes.statusApproved]: c.Status === 'Approved',
            [classes.statusRejected]: c.Status === 'Rejected',
            [classes.statusInProgress]: c.Status === 'InProgress',
            [classes.statusPending]: c.Status === 'Pending',
            [classes.statusNotSet]: c.Status === 'NotSet',
            [classes.statusChargedBack]: c.Status === 'ChargedBack',
            [classes.statusRefunded]: c.Status === 'Refunded',
            [classes.statusCancelled]: c.Status === 'Cancelled',
            [classes.statusInMediation]: c.Status === 'InMediation'
          })}>
          {paymentStatus[c.Status]}
        </Typography>
      ),
      'Estado de la Orden'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>{c.MedioDePago}</Typography>
      ),
      'Metodo de Pago'
    ),
    createCustomColumn(
      (c) => <Typography className={classes.tableCell}>{c.RRPP}</Typography>,
      'RRPP'
    ),
    createCustomColumn(
      (c) => <Typography className={classes.tableCell}>${c.Total}</Typography>,
      'Total'
    ),
    createCustomColumn(
      (c) => (
        <IconButton
          onClick={() => {
            history.push(
              ROUTES.ADMIN.USERS.ORDERS.DETAILS.replace(
                ':userId',
                userId
              ).replace(':orderId', c.Id)
            )
          }}>
          <Info />
        </IconButton>
      ),
      'Ver Detalle'
    )
  ]
}

const makeFooterActions = () => []

export const UserOrdersIndexPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { ordersPagedList, pendingOrders, filters, reqParams } = useSelector(
    (state) => state.orders
  )
  const { user } = useSelector((state) => state.users)
  const { user: userLogin } = useSelector((state) => state.login)
  const rolesArray = userLogin?.roles?.split(',') || []
  const [searchValue, setSearchValue] = useState(filters.searchKey)
  const [open, setOpen] = useState(false)
  const [orderId, setOrderId] = useState('')

  useEffect(() => {
    if (reqParams?.searchParams != null) {
      setSearchValue(reqParams?.searchParams)
    }
  }, [reqParams])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadUser(match.params.userId))
    }

    fetchData().then()
  }, [dispatch, match])

  const paymentStatus = {
    Pending: 'Pendiente',
    InProgress: 'En Progreso',
    InMediation: 'En Mediación',
    Approved: 'Aprobada',
    Rejected: 'Rechazada',
    Cancelled: 'Cancelada',
    Refunded: 'Devuelta',
    ChargedBack: 'Contracargo',
    NotSet: 'En Proceso'
  }

  const handleOpen = (id) => {
    setOrderId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOrderId('')
  }

  const handleBack = () => {
    history.push(
      ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', match.params.userId)
    )
  }

  const copyToClipBoard = () => {
    try {
      copy(orderId)
      showSnackbarSuccess('¡Id copiado al Portapapeles!', '')
      setOpen(false)
      setOrderId('')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const performSearch = async (searchKey) => {
    dispatch(setFilters('', searchKey))
    setSearchValue(searchKey)
    try {
      await dispatch(
        loadOrdersUserPaged(match.params.userId, searchKey, {
          searchParams: searchKey,
          pageNumber: 0,
          orderBy: '',
          order: TableGridOrder.ASC
        })
      )
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const makeSearchForm = () => {
    return (
      <SearchComponent
        performSearch={performSearch}
        searchValue={searchValue}
        placeholder='Filtrar por nombre del Evento'
        pending={pendingOrders}
      />
    )
  }

  return (
    <>
      <TableGrid
        title={
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              Ventas por Usuario
            </Typography>
            <Button
              onClick={handleBack}
              color='primary'
              startIcon={<ArrowBack />}>
              Volver
            </Button>
          </div>
        }
        columns={makeColumns(
          user?.Id,
          rolesArray?.includes(ROLES.ADMIN),
          history,
          paymentStatus,
          handleOpen,
          classes
        )}
        footerActions={makeFooterActions()}
        searchForm={makeSearchForm}
        loadItemsPage={(x) =>
          dispatch(loadOrdersUserPaged(match.params.userId, searchValue, { x }))
        }
        searchParams={searchValue}
        isLoading={pendingOrders}
        pagedList={ordersPagedList}
        keyName='Id'
        order={TableGridOrder.ASC}
        orderBy=''
        hideFooter
        useNewFormat
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Button
            onClick={copyToClipBoard}
            endIcon={<FileCopyOutlined />}
            className={classes.button}>
            {orderId}
          </Button>
        </DialogTitle>
      </Dialog>
    </>
  )
}

export default UserOrdersIndexPage
