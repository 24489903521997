import * as types from './types'
import { RolesProxy } from 'services'

export const loadRolesAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadRolesSuccess = (roles) => ({
  type: types.LOAD_SUCCESS,
  roles
})
export const loadRolesFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const loadUsersByRoleAttempt = () => ({
  type: types.LOAD_USERS_BY_ROLE_ATTEMPT
})

export const loadUsersByRoleSuccess = (users) => ({
  type: types.LOAD_USERS_BY_ROLE_SUCCESS,
  users
})
export const loadUsersByRoleFailure = (error) => ({
  type: types.LOAD_USERS_BY_ROLE_FAILURE,
  error
})

export const removeUserAttempt = () => ({
  type: types.REMOVE_USER_ATTEMPT
})

export const removeUserSuccess = () => ({
  type: types.REMOVE_USER_SUCCESS
})

export const removeUserFailure = (error) => ({
  type: types.REMOVE_USER_FAILURE,
  error
})

export const addUserAttempt = () => ({
  type: types.ADD_USER_ATTEMPT
})

export const addUserSuccess = () => ({
  type: types.ADD_USER_SUCCESS
})

export const addUserFailure = (error) => ({
  type: types.ADD_USER_FAILURE,
  error
})

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const resetParamsRoles = () => ({
  type: types.RESET_PARAMS
})

export const resetRoles = () => ({
  type: types.RESET
})

export const loadRoles = () => async (dispatch) => {
  try {
    dispatch(loadRolesAttempt())

    const proxy = new RolesProxy()
    const response = await proxy.getRoles()
    dispatch(loadRolesSuccess(response))
  } catch (error) {
    dispatch(loadRolesFailure(error))
    throw error
  }
}

export const loadUsersByRole = (roleId) => async (dispatch) => {
  try {
    dispatch(loadUsersByRoleAttempt())

    const proxy = new RolesProxy()
    const response = await proxy.getUsersByRole(roleId)
    dispatch(loadUsersByRoleSuccess(response))
  } catch (error) {
    dispatch(loadUsersByRoleFailure(error))
    throw error
  }
}

export const removeUser = (userToRemove) => async (dispatch) => {
  try {
    dispatch(removeUserAttempt())

    const proxy = new RolesProxy()
    const response = await proxy.removeUserFromRole(userToRemove)

    dispatch(removeUserSuccess(response))
  } catch (error) {
    dispatch(removeUserFailure(error))
    throw error
  }
}

export const addUser = (userToAdd) => async (dispatch) => {
  try {
    dispatch(addUserAttempt())

    const proxy = new RolesProxy()
    const response = await proxy.addUserToRole(userToAdd)

    dispatch(addUserSuccess(response))
  } catch (error) {
    dispatch(addUserFailure(error))
    throw error
  }
}

export const loadUsersByRolePaged =
  (roleId, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageNumber, pageSize })
      )

      const proxy = new RolesProxy()
      const pagedList = await proxy.getUsersByRolePaged(
        roleId,
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(pagedListSuccess(pagedList))
      return pagedList
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }
