import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, TextField } from '@material-ui/core'
import { useStyles } from './UserSearchComponent.style'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import { isNotIntegerNumber } from 'views/utils/functions'
import SearchIcon from '@material-ui/icons/Search'

export const UserSearchComponent = ({
  performSearch,
  searchValue,
  pending,
  isForeign,
  handleForeignChange
}) => {
  const classes = useStyles()
  const [searchKey, setSearchKey] = useState(searchValue)
  const { country } = useSelector((state) => state.app)
  const documentType = country === 'ar' ? 'DNI' : 'CI'
  const [labelId, setLabelId] = useState(`Ingresar ${documentType} del cliente`)

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch(isForeign, searchKey.trim())
    }
  }

  return (
    <div>
      <div className={classes.form}>
        <Select
          labelId='type-id'
          className={classes.field}
          label='Tipo de identificación'
          value={isForeign}
          required
          onChange={(v) => {
            handleForeignChange(v.target.value)
            setSearchKey('')
            v.target.value
              ? setLabelId('Ingresar identificación del cliente')
              : setLabelId(`Ingresar ${documentType} del cliente`)
          }}>
          <MenuItem value={false}>{documentType}</MenuItem>
          <MenuItem value={true}>Otro</MenuItem>
        </Select>
        <TextField
          placeholder={labelId}
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
          }}
          inputProps={{ maxLength: isForeign ? 20 : 8 }}
          onKeyPress={handleSearchKeyPress}
          onKeyDown={(e) => !isForeign && isNotIntegerNumber(e)}
          className={classes.autocomplete}
        />
        <div className={classes.containerButton}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              performSearch(isForeign, searchKey.trim())
            }}
            disabled={pending || searchKey.trim().length < 3}
            startIcon={<SearchIcon />}>
            {pending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Buscar'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default UserSearchComponent
