import React, { useEffect, useState } from 'react'
import { AppContainer, PaperContainer } from '~/views/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadReseller,
  update,
  resetActions,
  generatePin,
  updatePin
} from '~/state/modules/resellers'
import { ProfileForm } from './ProfileForm'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField
} from '@material-ui/core'
import { Typography, CircularProgress } from '@material-ui/core'
import { useStyles } from './ProfilePage.style'
import QRCode from 'react-qr-code'
import copy from 'copy-to-clipboard'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { validPin } from 'views/utils/functions'

export const ProfilePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pending, user } = useSelector((state) => state.login)
  const {
    reseller,
    pending: pendingReseller,
    actionPending,
    actionSuccess
  } = useSelector((state) => state.resellers)

  const [roles, setRoles] = useState()
  const [isReseller, setIsReseller] = useState(false)
  const [userId, setUserId] = useState()
  const [open, setOpen] = useState(false)
  const [openDialogPIN, setOpenDialogPIN] = useState(false)
  const [openDialogOldPIN, setOpenDialogOldPIN] = useState(false)
  const [newPin, setNewPin] = useState()
  const [oldPin, setOldPin] = useState()
  const [dialogTitle, setDialogTitle] = useState('')

  useEffect(() => {
    if (user != null) {
      const roles = user.roles.split(',')
      setUserId(user.id)
      setRoles(user.roles)
      setIsReseller(roles.includes('Reseller'))
    }
  }, [user])

  useEffect(() => {
    if (userId && isReseller) {
      dispatch(loadReseller(userId))
    }
  }, [dispatch, userId, isReseller])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(update(userId, values))
      dispatch(resetActions())
      setDialogTitle('¡Tus datos han sido actualizados!')
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleGeneratePin = async () => {
    const generatedPin = {
      PIN: newPin
    }
    try {
      await dispatch(generatePin(generatedPin))
      dispatch(resetActions())
      setOpenDialogPIN(false)
      setDialogTitle('¡PIN generado exitosamente!')
      setOpen(true)
    } catch (error) {
      setOpenDialogPIN(false)
      showSnackbarError(error)
    }
    setOldPin(null)
    setNewPin(null)
  }

  const handleChangePin = async () => {
    const updatedPin = {
      OldPIN: oldPin,
      NewPIN: newPin
    }
    try {
      await dispatch(updatePin(updatedPin))
      dispatch(resetActions())
      setOpenDialogPIN(false)
      setDialogTitle('¡PIN modificado exitosamente!')
      setOpen(true)
    } catch (error) {
      setOpenDialogPIN(false)
      showSnackbarError(error)
    }
    setOldPin(null)
    setNewPin(null)
  }

  const copyToClipBoard = (label, value) => {
    try {
      copy(value)
      showSnackbarSuccess(`¡${label} copiado al Portapapeles!`, '')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPressOldPin = (event) => {
    if (event.key === 'Enter') {
      setOpenDialogOldPIN(false)
      setOpenDialogPIN(true)
    }
  }

  const handleSearchKeyPressNewPin = (event) => {
    if (event.key === 'Enter') {
      reseller?.HasPINGenerated ? handleChangePin() : handleGeneratePin()
    }
  }

  const values = {
    BankAccountNumber: reseller?.BankAccountNumber || '',
    Cuit: reseller?.Cuit || '',
    MercadoPagoEmail: reseller?.MercadoPagoEmail || '',
    HasPINGenerated: reseller?.HasPINGenerated
  }

  return (
    <AppContainer>
      <PaperContainer>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <div className={classes.container}>
            {user != null && <QRCode value={user?.id} size={200} />}
            <div onClick={() => copyToClipBoard('Id', user?.id)}>
              <Typography variant='h6' className={classes.id}>
                Id:&nbsp;
                <Typography
                  className={classes.value}
                  color='inherit'
                  variant='h6'
                  component='span'>
                  {user?.id}
                </Typography>
              </Typography>
            </div>
            <div onClick={() => copyToClipBoard('Roles', roles)}>
              <Typography variant='h6'>
                Roles:&nbsp;
                <Typography
                  className={classes.value}
                  color='inherit'
                  variant='h6'
                  component='span'>
                  {roles}
                </Typography>
              </Typography>
            </div>
            {isReseller && (
              <PaperContainer title='MIS DATOS COMO RRPP'>
                {pendingReseller ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  <ProfileForm
                    values={values}
                    actionText='Actualizar mis datos como RRPP'
                    handleSubmit={handleSubmit}
                    actionPending={actionPending}
                    actionSuccess={actionSuccess}
                    generatePIN={() => setOpenDialogPIN(true)}
                    changePIN={() => setOpenDialogOldPIN(true)}
                  />
                )}
              </PaperContainer>
            )}
          </div>
        )}
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogPIN}
        onClose={() => {
          setOldPin(null)
          setNewPin(null)
          setOpenDialogPIN(false)
        }}>
        <DialogContent className={classes.dialogPin}>
          <TextField
            autoFocus
            margin='dense'
            autoComplete='off'
            id='pin'
            label={
              reseller?.HasPINGenerated
                ? 'Ingrese su nuevo PIN:'
                : 'Ingrese un PIN de 4 dígitos:'
            }
            type='string'
            placeholder='****'
            inputProps={{ maxLength: 4 }}
            onKeyPress={handleSearchKeyPressNewPin}
            onKeyDown={(e) => validPin(e)}
            onChange={(e) => setNewPin(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOldPin(null)
              setNewPin(null)
              setOpenDialogPIN(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={
              reseller?.HasPINGenerated ? handleChangePin : handleGeneratePin
            }
            color='primary'
            disabled={actionPending || newPin?.length !== 4}>
            {actionPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Ok'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogOldPIN}
        onClose={() => {
          setOldPin(null)
          setOpenDialogOldPIN(false)
        }}>
        <DialogContent className={classes.dialogPin}>
          <TextField
            autoFocus
            margin='dense'
            id='pin'
            autoComplete='off'
            label='Ingrese su PIN actual:'
            type='string'
            placeholder='****'
            inputProps={{ maxLength: 4 }}
            onKeyPress={handleSearchKeyPressOldPin}
            onKeyDown={(e) => validPin(e)}
            onChange={(e) => setOldPin(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOldPin(null)
              setOpenDialogOldPIN(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenDialogOldPIN(false)
              setOpenDialogPIN(true)
            }}
            color='primary'
            disabled={oldPin?.length !== 4}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </AppContainer>
  )
}

export default ProfilePage
