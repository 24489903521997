import React from 'react'
import { createRoot } from 'react-dom/client'
import history from '~/config/history'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import store from '~/state/store'
import { theme } from '~/config/theme'
import App from './App'
import './index.css'
import moment from 'moment-timezone'
import 'moment/locale/es'

moment.locale('es')
moment.tz.setDefault('Etc/UTC')

const container = document.getElementById('app')
const rootContainer = createRoot(container)

rootContainer.render(
  <Provider store={store}>
    <ReactNotifications />
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale={'es'}
      libInstance={moment}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <App />
        </Router>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </Provider>
)
