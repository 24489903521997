import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField
} from '@material-ui/core'
import { PaperContainer } from 'views/shared'
import { loadUser, changePassword } from 'state/modules/users'
import { sendGiftTicket } from 'state/modules/orders'
import { clearProfileCache } from 'state/modules/cache'
import { sendNotificationToUser, reset } from 'state/modules/notifications'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { useStyles } from './UsersInfoPage.style'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { isNotIntegerNumber } from 'views/utils/functions'
import { ROLES } from 'config/constants'
import {
  subscribeEmail,
  toggleAlertsDraws,
  toggleAlertsEvents,
  userBan,
  userUnban,
  changeEmail,
  cleanDNI,
  allowChangeEmail
} from 'state/modules/profiles'
import { Apple, Facebook } from '@material-ui/icons'
import { ConfirmationDialog } from '~/views/shared'

export const UsersInfoPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [alertEvents, setAlertEvents] = useState(false)
  const [alertDraws, setAlertDraws] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [openDialogBanConfirm, setOpenDialogBanConfirm] = useState(false)
  const [openDialogUnbanConfirm, setOpenDialogUnbanConfirm] = useState(false)
  const [openDialogUpdateEmail, setOpenDialogUpdateEmail] = useState(false)
  const [openDialogPassword, setOpenDialogPassword] = useState(false)
  const [openDialogConfirmPassword, setOpenDialogConfirmPassword] =
    useState(false)
  const [openDialogSendTicket, setOpenDialogSendTicket] = useState(false)
  const [openDialogCleanDNI, setOpenDialogCleanDNI] = useState(false)
  const [openDialogSendNotification, setOpenDialogSendNotification] =
    useState(false)
  const [openDialogCleanCache, setOpenDialogCleanCache] = useState(false)
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [openSuccessNotification, setOpenSuccessNotification] = useState(false)
  const [labelDialog, setLabelDialog] = useState('')
  const [email, setEmail] = useState()
  const [ticketId, setTicketId] = useState()
  const [notification, setNotification] = useState()
  const { user, pending, previousRoute } = useSelector((state) => state.users)
  const { pending: pendingSubscriptions } = useSelector(
    (state) => state.profiles
  )
  const { user: userLogin } = useSelector((state) => state.login)
  const rolesArray = userLogin?.roles?.split(',') || []
  const { pending: pendingOrders } = useSelector((state) => state.orders)
  const { actionPending, response } = useSelector(
    (state) => state.notifications
  )
  const { pendingClearCache } = useSelector((state) => state.cache)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'

  const createRow = (label, value, formatter) => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {formatter ? formatter(value) : value}
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    async function fetchData() {
      dispatch(loadUser(match.params.searchKey))
    }
    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    if (user != null) {
      setSubscribed(user.Subscribed)
      setAlertDraws(user.Profile?.AlertsDraws)
      setAlertEvents(user.Profile?.AlertsEvents)
    }
  }, [user])

  const handleChangeSubscribed = async () => {
    try {
      await dispatch(subscribeEmail(user.Id, !subscribed))
      setSubscribed(!subscribed)
    } catch (e) {
      showSnackbarError(e)
    }
  }

  const handleChangeAlertsDraws = async () => {
    try {
      await dispatch(toggleAlertsDraws(user.UserName, !alertDraws))
      setAlertDraws(!alertDraws)
    } catch (e) {
      showSnackbarError(e)
    }
  }

  const handleBanUser = async () => {
    try {
      await dispatch(userBan(user.Id))
      setOpenDialogBanConfirm(false)
      history.push(ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', user?.Id))
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleUnbanUser = async () => {
    try {
      await dispatch(userUnban(user.Id))
      setOpenDialogUnbanConfirm(false)
      history.push(ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', user?.Id))
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleChangeAlertsEvents = async () => {
    try {
      await dispatch(toggleAlertsEvents(user.UserName, !alertEvents))
      setAlertEvents(!alertEvents)
    } catch (e) {
      showSnackbarError(e)
    }
  }

  const updateEmail = async () => {
    const emailChanged = {
      Email: email
    }
    try {
      await dispatch(changeEmail(user.Id, emailChanged))
      await dispatch(loadUser(match.params.searchKey))
      setOpenDialogUpdateEmail(false)
      setLabelDialog('¡Email Cambiado!')
      setOpenSuccessDialog(true)
    } catch (error) {
      setOpenDialogUpdateEmail(false)
      showSnackbarError(error, 9000)
    }
  }

  const isValidEmail = (emailEntered) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/
    if (regex.test(emailEntered)) {
      return true
    } else return false
  }

  const clearUserDNI = async () => {
    try {
      await dispatch(cleanDNI(user.UserName))
      await dispatch(loadUser(match.params.searchKey))
      setOpenDialogCleanDNI(false)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const clearCache = async () => {
    try {
      await dispatch(clearProfileCache(user.Id))
      setOpenDialogCleanCache(false)
      showSnackbarSuccess('Se ha actualizado el perfil del usuario!')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const updatePassword = async () => {
    const passwordChanged = {
      NewPassword: password?.trim(),
      ConfirmNewPassword: confirmPassword?.trim()
    }
    try {
      await dispatch(changePassword(user.Id, passwordChanged))
      setOpenDialogConfirmPassword(false)
      setLabelDialog('¡Contraseña Cambiada!')
      setOpenSuccessDialog(true)
    } catch (error) {
      setOpenDialogConfirmPassword(false)
      showSnackbarError(error)
    }
    setPassword(null)
    setConfirmPassword(null)
  }

  const sendTicket = async () => {
    const giftTicket = {
      TicketTypeId: ticketId,
      UserIds: [user.Id],
      NotifyManager: true,
      Units: 1
    }
    try {
      await dispatch(sendGiftTicket(giftTicket))
      setOpenDialogSendTicket(false)
      setLabelDialog('¡Ticket Enviado!')
      setOpenSuccessDialog(true)
    } catch (error) {
      setOpenDialogSendTicket(false)
      showSnackbarError(error)
    }
    setTicketId(null)
  }

  const sendNotification = async () => {
    const pushNotification = {
      Title: 'IMPORTANTE',
      Message: notification
    }
    try {
      await dispatch(sendNotificationToUser(user.UserName, pushNotification))
      setOpenDialogSendNotification(false)
      setOpenSuccessNotification(true)
    } catch (error) {
      setOpenDialogSendNotification(false)
      showSnackbarError(error)
    }
    setNotification(null)
  }

  const allowEmail = async () => {
    try {
      await dispatch(allowChangeEmail(user?.UserName, user?.EmailConfirmed))
      await dispatch(loadUser(match.params.searchKey))
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleBack = () => {
    if (previousRoute) {
      history.push(previousRoute)
    } else {
      history.push(ROUTES.ADMIN.USERS.INDEX)
    }
  }

  const viewOrders = () => {
    history.push(ROUTES.ADMIN.USERS.ORDERS.INDEX.replace(':userId', user.Id))
  }

  const viewTickets = () => {
    history.push(ROUTES.ADMIN.USERS.TICKETS.INDEX.replace(':userId', user.Id))
  }

  const viewUnifyAccounts = () => {
    history.push(ROUTES.ADMIN.USERS.UNIFY.INDEX.replace(':userId', user.Id))
  }

  const viewRoles = () => {
    history.push(ROUTES.ADMIN.USERS.ROLES.INDEX.replace(':userId', user.Id))
  }

  return (
    <PaperContainer title='Información del usuario'>
      <div className={classes.actionsContainer}>
        <Button onClick={handleBack} color='primary'>
          Volver
        </Button>
        <Button
          disabled={user?.Profile?.IsBanned}
          onClick={() => setOpenDialogBanConfirm(true)}>
          Banear Usuario
        </Button>
        <Button
          disabled={!user?.Profile?.IsBanned}
          onClick={() => setOpenDialogUnbanConfirm(true)}>
          Desbanear Usuario
        </Button>
        <Button
          disabled={!user?.DNI}
          onClick={() => setOpenDialogCleanDNI(true)}>
          {`Limpiar ${documentId}`}
        </Button>
        <Button
          disabled={user?.EmailConfirmed == null || pendingSubscriptions}
          onClick={allowEmail}>
          {user?.EmailConfirmed
            ? 'Habilitar cambiar email'
            : 'Deshabilitar cambiar email'}
        </Button>
        <Button onClick={() => setOpenDialogUpdateEmail(true)}>
          Cambiar email
        </Button>
        <Button onClick={viewOrders}>Ver ordenes</Button>
        <Button onClick={viewTickets}>Ver tickets</Button>
        <Button onClick={() => setOpenDialogSendNotification(true)}>
          Enviar Push
        </Button>
        <Button onClick={() => setOpenDialogSendTicket(true)}>
          Enviar Ticket
        </Button>
        <Button onClick={viewUnifyAccounts} disabled={pending}>
          Unificar cuentas
        </Button>
        <Button onClick={() => setOpenDialogPassword(true)}>
          Cambiar contraseña
        </Button>
        {rolesArray?.includes(ROLES.ADMIN) && (
          <Button onClick={viewRoles}>Roles</Button>
        )}
        <Button onClick={() => setOpenDialogCleanCache(true)}>
          Limpiar Cache de Perfil
        </Button>
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          <Table>
            <TableBody>
              {createRow('ID', user?.Id)}
              {createRow('Nombre de Usuario', user?.UserName)}
              {createRow(documentId, user?.DNI)}
              {createRow('Nombre Completo', user?.FullName)}
              {createRow('Nombre', user?.FirstName)}
              {createRow('Apellido', user?.LastName)}
              {createRow('Email', user?.Email)}
              {createRow(
                '¿Email confirmado?',
                user?.EmailConfirmed ? 'Sí' : 'No'
              )}
              {createRow('Teléfono', user?.PhoneNumber)}
              {createRow(
                '¿Teléfono Confirmado?',
                user?.PhoneNumberConfirmed ? 'Sí' : 'No'
              )}
              {createRow('¿Cliente Gold?', user?.Profile?.IsGold ? 'Sí' : 'No')}
              {createRow(
                'Cantidad de eventos asistidos',
                user?.Profile?.PurchasedEventsCount
              )}
              {createRow(
                '¿Cliente baneado?',
                user?.Profile?.IsBanned ? 'Sí' : 'No'
              )}
            </TableBody>
          </Table>
          <div className={classes.checkboxesContainer}>
            <FormControlLabel
              className={classes.checkboxContainer}
              disabled={pendingSubscriptions}
              control={
                <Checkbox
                  checked={alertEvents}
                  onChange={handleChangeAlertsEvents}
                />
              }
              label='¿Enviar notificaciones de eventos?'
            />
            <FormControlLabel
              className={classes.checkboxContainer}
              disabled={pendingSubscriptions}
              control={
                <Checkbox
                  checked={alertDraws}
                  onChange={handleChangeAlertsDraws}
                />
              }
              label='¿Enviar notificaciones de sorteos?'
            />
            <FormControlLabel
              className={classes.checkboxContainer}
              disabled={pendingSubscriptions}
              control={
                <Checkbox
                  checked={subscribed}
                  onChange={handleChangeSubscribed}
                />
              }
              label='¿Enviar notificaciones por correo?'
            />
          </div>

          {user?.Logins?.map((login, index) =>
            login.LoginProvider === 'Facebook' ? (
              <Facebook className={classes.socialMediaIcon}></Facebook>
            ) : (
              <Apple className={classes.socialMediaIcon}></Apple>
            )
          )}
          <ConfirmationDialog
            open={openDialogBanConfirm}
            loading={pending}
            handleConfirmation={handleBanUser}
            onCancel={() => setOpenDialogBanConfirm(false)}
            title='¡ADVERTENCIA!'
            message='Está por banear al usuario '
          />
          <ConfirmationDialog
            open={openDialogUnbanConfirm}
            loading={pending}
            handleConfirmation={handleUnbanUser}
            onCancel={() => setOpenDialogUnbanConfirm(false)}
            title='¡ADVERTENCIA!'
            message='Está por desbanear al usuario '
          />
          <Dialog
            open={openDialogUpdateEmail}
            onClose={() => setOpenDialogUpdateEmail(false)}>
            <DialogContent className={classes.dialog}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Nuevo Email:'
                type='email'
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenDialogUpdateEmail(false)}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={updateEmail}
                color='primary'
                disabled={!isValidEmail(email)}>
                {pendingSubscriptions ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Cambiar'
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogPassword}
            onClose={() => {
              setOpenDialogPassword(false)
              setPassword(null)
            }}>
            <DialogContent className={classes.dialog}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Ingrese su nueva Contraseña:'
                type='string'
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenDialogPassword(false)
                  setPassword(null)
                }}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  setOpenDialogPassword(false)
                  setOpenDialogConfirmPassword(true)
                }}
                color='primary'
                disabled={
                  password == null ||
                  password?.trim().length < 6 ||
                  password?.trim() === ''
                }>
                {pending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Cambiar'
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogConfirmPassword}
            onClose={() => {
              setOpenDialogConfirmPassword(false)
              setPassword(null)
              setConfirmPassword(null)
            }}>
            <DialogContent className={classes.dialog}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Confirme su nueva Contraseña:'
                type='string'
                onChange={(event) => setConfirmPassword(event.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenDialogConfirmPassword(false)
                  setPassword(null)
                  setConfirmPassword(null)
                }}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={updatePassword}
                color='primary'
                disabled={
                  confirmPassword == null ||
                  confirmPassword?.trim().length < 6 ||
                  confirmPassword?.trim() === ''
                }>
                {pending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Cambiar'
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogSendTicket}
            onClose={() => {
              setTicketId(null)
              setOpenDialogSendTicket(false)
            }}>
            <DialogContent className={classes.dialogTicket}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Id de Ticket a Enviar:'
                type='string'
                inputProps={{ maxLength: 10 }}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                onChange={(event) => setTicketId(event.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setTicketId(null)
                  setOpenDialogSendTicket(false)
                }}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={sendTicket}
                color='primary'
                disabled={pendingOrders || ticketId == null || ticketId === ''}>
                {pendingOrders ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Enviar'
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogSendNotification}
            onClose={() => {
              setNotification(null)
              setOpenDialogSendNotification(false)
            }}>
            <DialogContent className={classes.dialog}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Escriba el mensaje a enviar:'
                type='string'
                onChange={(event) => setNotification(event.target.value.trim())}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setNotification(null)
                  setOpenDialogSendNotification(false)
                }}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={sendNotification}
                color='primary'
                disabled={
                  actionPending ||
                  notification == null ||
                  notification?.trim() === ''
                }>
                {actionPending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Enviar'
                )}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openSuccessDialog}>
            <DialogTitle>{labelDialog}</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => setOpenSuccessDialog(false)}
                color='primary'
                autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openSuccessNotification}>
            <DialogTitle>
              ¡Tu mensaje llegó a {response?.Recipients} dispositivos!
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenSuccessNotification(false)
                  dispatch(reset())
                }}
                color='primary'
                autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmationDialog
            open={openDialogCleanDNI}
            loading={pendingSubscriptions}
            handleConfirmation={clearUserDNI}
            onCancel={() => setOpenDialogCleanDNI(false)}
            title='¡ADVERTENCIA!'
            message={`Está por eliminar el ${documentId}`}
          />
          <ConfirmationDialog
            open={openDialogCleanCache}
            loading={pendingClearCache}
            handleConfirmation={clearCache}
            onCancel={() => setOpenDialogCleanCache(false)}
            title='¡ADVERTENCIA!'
            message='Está por limpiar la cache del perfil del usuario'
          />
        </>
      )}
    </PaperContainer>
  )
}

export default UsersInfoPage
