import React, { useState } from 'react'
import history from '~/config/history'
import { Switch, Route } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import RichTextEditor from 'react-rte'
import { UsersSearch } from './UsersSearch'
import { CustomNotifications } from './CustomNotifications'
import { AppContainer } from '~/views/shared'

export const CustomNotificationsPage = () => {
  const [emailsList, setEmailsList] = useState([])
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [userName, setUserName] = useState('')
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  )

  const redirectToCustomNotification = (selectedUser) => {
    if (selectedUser != null && !emailsList.includes(selectedUser?.Email)) {
      setEmailsList([...emailsList, selectedUser.Email])
      setUserName(selectedUser.FirstName)
    }
    history.push(ROUTES.ADMIN.NOTIFICATIONS.CUSTOM)
  }

  const handleRemoveEmail = (email) => {
    setEmailsList(emailsList.filter((e) => e !== email))
  }

  const assignEditorValue = (value = RichTextEditor.createEmptyValue()) => {
    setEditorValue(value)
  }

  const assignMessage = (msg = '') => {
    setMessage(msg)
  }

  const assignSubject = (subj = '') => {
    setSubject(subj)
  }

  const handleClearEmails = () => {
    setEmailsList([])
  }

  return (
    <AppContainer title='Mail Personalizado'>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN.NOTIFICATIONS.CUSTOM}
          render={(props) => (
            <CustomNotifications
              {...props}
              handleRemoveEmail={handleRemoveEmail}
              handleClearEmails={handleClearEmails}
              emailsList={emailsList}
              message={message}
              subject={subject}
              userName={userName}
              editorValue={editorValue}
              assignEditorValue={assignEditorValue}
              assignMessage={assignMessage}
              assignSubject={assignSubject}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.NOTIFICATIONS.SEARCH}
          render={(props) => (
            <UsersSearch
              {...props}
              onSuccess={redirectToCustomNotification}
              onCancel={redirectToCustomNotification}
            />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default CustomNotificationsPage
