import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AppContainer } from 'views/shared'
import { ROUTES } from 'views/routes'
import { OrderRecordPage, OrderPaymentsPage } from '../EventsPage/Orders'
import { TicketsPerOrderPage } from '../EventsPage/Orders/TicketsPerOrderPage'
import { OrdersIndexPage } from './OrdersIndexPage'
import { UserOrderDetailsPage } from '../UsersPage/Orders/UserOrdersDetailsPage'
import { TransferOrderPage } from '../UsersPage/Orders/TransferOrderPage/TransferOrderPage'

export const OrdersPage = () => {
  return (
    <AppContainer title='Soporte'>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN.SUPPORT.ORDERS.INDEX}
          render={(props) => <OrdersIndexPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.SUPPORT.ORDERS.DETAILS}
          render={(props) => <UserOrderDetailsPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.SUPPORT.ORDERS.HISTORY}
          render={(props) => <OrderRecordPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.SUPPORT.ORDERS.PAYMENTS}
          render={(props) => <OrderPaymentsPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.SUPPORT.ORDERS.TICKETS}
          render={(props) => <TicketsPerOrderPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.SUPPORT.ORDERS.TRANSFER}
          render={(props) => <TransferOrderPage {...props} />}
        />
      </Switch>
    </AppContainer>
  )
}

export default OrdersPage
