import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.primary.main,
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textTransform: 'uppercase'
  },
  personInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  personName: {
    fontSize: '.9rem',
    fontWeight: theme.typography.fontWeightBold
  },
  personTaxIdNumber: {
    fontSize: '.85rem',
    fontWeight: theme.typography.fontWeightMedium
  }
}))

export default useStyles
