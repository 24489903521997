import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { PaperContainer, ConfirmationDialog } from '~views/shared'
import { useStyles } from './UserOrderDetailsPage.style'
import {
  loadOrderDetails,
  refundOrder,
  refreshOrder,
  recoverTickets
} from '~/state/modules/orders'
import { SelectedDateModal } from './SelectedDateModal'
import { ROUTES } from 'views/routes'
import history from '~/config/history'
import { ChangeStateManually } from '../components/ChangeStateManually'
import { loadUser } from 'state/modules/users'
import { Save } from '@material-ui/icons'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const UserOrderDetailsPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { userId, orderId } = match.params
  const [orders, setOrders] = useState([])
  const { order, pending, refundPending, actionPending, recoverPending } =
    useSelector((state) => state.orders)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const [openRefund, setOpenRefund] = useState(false)
  const [openRefundCash, setOpenRefundCash] = useState(false)
  const [successNotification, setSuccessNotification] = useState(false)
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [showMessageError, setShowMessageError] = useState(false)
  const [openReason, setOpenReason] = useState(false)
  const [reason, setReason] = useState('')
  const [openRecoverDialog, setOpenRecoverDialog] = useState(false)
  const [openDatePickerModal, setOpenDatePickerModal] = useState(false)
  const [isInvoice, setIsInvoice] = useState()
  const [invoiceDate, setInvoiceDate] = useState()
  const currentURL = window.location.href

  const createRow = (label, value, i) => {
    return (
      <TableRow className={classes.tableRow} key={i}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>{value}</TableCell>
      </TableRow>
    )
  }

  const updateFromMP = async () => {
    try {
      await dispatch(refreshOrder(orderId))
      dispatch(loadOrderDetails(orderId))
    } catch (error) {
      setMessageError(error?.response?.data?.Message)
      setShowMessageError(true)
    }
    setOpenDialogConfirm(false)
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrderDetails(orderId))
      await dispatch(loadUser(userId))
    }

    fetchData().then()
  }, [dispatch, orderId, userId])

  useEffect(() => {
    if (order) {
      setOrders(Object.values(order))
      setInvoiceDate(order[0].InvoiceGeneratedDate)
    }
  }, [order])

  const paymentStatus = {
    Pending: 'Pendiente',
    InProgress: 'En Progreso',
    InMediation: 'En Mediacion',
    Approved: 'Aprobada',
    Rejected: 'Rechazada',
    Cancelled: 'Cancelada',
    Refunded: 'Devuelta',
    ChargedBack: 'Contracargo'
  }

  const handleChange = (event) => {
    setReason(event.target.value.trim())
  }

  const handleOpenInvoice = () => {
    setOpenDatePickerModal(true)
    setIsInvoice(true)
  }

  const handleOpenCreditNote = () => {
    setOpenDatePickerModal(true)
    setIsInvoice(false)
  }

  const onCloseModal = () => {
    setOpenDatePickerModal(false)
  }

  const handleRedirectPayments = () => {
    if (userId) {
      if (currentURL.includes('bytickets')) {
        history.push(
          ROUTES.ADMIN.USERS.TICKETS.PAYMENTS.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      } else {
        history.push(
          ROUTES.ADMIN.USERS.ORDERS.PAYMENTS.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      }
    } else {
      history.push(
        ROUTES.ADMIN.SUPPORT.ORDERS.PAYMENTS.replace(
          ':eventId',
          orders[0].EventoId
        ).replace(':orderId', orders[0].Id)
      )
    }
  }

  const handleRedirectTransfer = () => {
    if (userId) {
      if (currentURL.includes('bytickets')) {
        history.push(
          ROUTES.ADMIN.USERS.TICKETS.TRANSFER.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      } else {
        history.push(
          ROUTES.ADMIN.USERS.ORDERS.TRANSFER.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      }
    } else {
      history.push(
        ROUTES.ADMIN.SUPPORT.ORDERS.TRANSFER.replace(
          ':eventId',
          orders[0].EventoId
        ).replace(':orderId', orders[0].Id)
      )
    }
  }

  const handleRedirectTickets = () => {
    if (userId) {
      if (currentURL.includes('bytickets')) {
        history.push(
          ROUTES.ADMIN.USERS.TICKETS.TICKET_LIST.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      } else {
        history.push(
          ROUTES.ADMIN.USERS.ORDERS.TICKETS.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      }
    } else {
      history.push(
        ROUTES.ADMIN.SUPPORT.ORDERS.TICKETS.replace(
          ':eventId',
          orders[0].EventoId
        ).replace(':orderId', orders[0].Id)
      )
    }
  }

  const handleRedirectHistory = () => {
    if (userId) {
      if (currentURL.includes('bytickets')) {
        history.push(
          ROUTES.ADMIN.USERS.TICKETS.HISTORY.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      } else {
        history.push(
          ROUTES.ADMIN.USERS.ORDERS.HISTORY.replace(
            ':eventId',
            orders[0].EventoId
          ).replace(':orderId', orders[0].Id)
        )
      }
    } else {
      history.push(
        ROUTES.ADMIN.SUPPORT.ORDERS.HISTORY.replace(
          ':eventId',
          orders[0].EventoId
        ).replace(':orderId', orders[0].Id)
      )
    }
  }

  const handleConfirmNotification = async () => {
    try {
      await dispatch(refundOrder(orderId, reason))
      setSuccessNotification(true)
    } catch (error) {
      showSnackbarError(error)
    }
    setReason('')
    setOpenRefund(false)
    setOpenRefundCash(false)
  }

  const handleRecoverTickets = async () => {
    try {
      await dispatch(recoverTickets(orderId))
      showSnackbarSuccess('¡Tickets Recuperados!')
    } catch (error) {
      showSnackbarError(error)
    }
    setOpenRecoverDialog(false)
  }

  const handleBack = () => {
    if (currentURL.includes('support')) {
      history.push(ROUTES.ADMIN.SUPPORT.ORDERS.INDEX)
    } else {
      if (currentURL.includes('bytickets')) {
        history.push(
          ROUTES.ADMIN.USERS.TICKETS.INDEX.replace(':userId', userId)
        )
      } else {
        history.push(ROUTES.ADMIN.USERS.ORDERS.INDEX.replace(':userId', userId))
      }
    }
  }

  return (
    <PaperContainer title='Orden'>
      <div className={classes.actionsContainer}>
        <Button onClick={handleBack} color='primary'>
          Volver
        </Button>
        <Button
          onClick={handleRedirectHistory}
          variant='contained'
          color='primary'>
          Ver Historial
        </Button>
        <Button
          onClick={handleRedirectTickets}
          color='primary'
          variant='contained'>
          Ver Tickets
        </Button>
        <Button
          disabled={orders[0]?.Status !== 'Approved'}
          onClick={handleRedirectTransfer}
          color='primary'
          variant='contained'>
          Transferir
        </Button>
        <Button
          onClick={() => setOpenDialogConfirm(true)}
          variant='contained'
          disabled={
            orders[0]?.MercadoPagoId == null ||
            orders[0]?.MercadoPagoId === 'NotSet'
          }
          color='primary'>
          Actualizar desde MP
        </Button>
        <Button disabled variant='contained' color='primary'>
          Cancelar
        </Button>
        <Button
          disabled={orders[0]?.Status !== 'Approved'}
          variant='contained'
          color='primary'
          onClick={() => {
            if (orders[0]?.MedioDePago === 'Cupón') {
              setOpenRefundCash(true)
            } else {
              setOpenRefund(true)
            }
          }}>
          Devolver
        </Button>
        <ChangeStateManually order={orders[0]} />
        <Button
          onClick={handleRedirectPayments}
          variant='contained'
          color='primary'>
          Ver Pagos Asociados
        </Button>
        <Button
          disabled={orders[0]?.Status !== 'Approved'}
          variant='contained'
          color='primary'
          onClick={() => setOpenRecoverDialog(true)}>
          Recuperar Transferidos
        </Button>
        {(orders[0]?.Status === 'Approved' ||
          orders[0]?.Status === 'InMediation') &&
          orders[0]?.InvoiceId == null && (
            <Button
              variant='contained'
              color='primary'
              onClick={handleOpenInvoice}>
              Generar Factura
            </Button>
          )}
        {(orders[0]?.Status === 'Cancelled' ||
          orders[0]?.Status === 'ChargedBack' ||
          orders[0]?.Status === 'Refunded') &&
          orders[0]?.CreditNoteId == null &&
          orders[0]?.InvoiceId != null && (
            <Button
              variant='contained'
              color='primary'
              onClick={handleOpenCreditNote}>
              Generar Nota de Crédito
            </Button>
          )}
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {orders &&
            orders.map((o, i) => (
              <Table key={i}>
                <TableBody>
                  {createRow('Orden Id', o?.Id)}
                  {createRow('Evento', o?.Evento)}
                  {createRow('MercadoPago Id', o?.MercadoPagoId)}
                  {createRow('Nombre', o?.Nombre)}
                  {createRow(documentId, o?.DNI)}
                  {createRow('Teléfono', o?.Telefono)}
                  {createRow('Fecha de Compra', o?.FechaDeCompra)}
                  {createRow('Estado de pago', paymentStatus[o?.Status])}
                  {createRow('Método de pago', o?.MedioDePago)}
                  {createRow('RRPP', o?.RRPP)}
                  {createRow('Total', `$${o?.Total}`)}
                  {createRow('Facturación', o?.BillingStatus)}
                  {o?.InvoiceId && createRow('Id Factura', o?.InvoiceId)}
                  {o?.InvoiceId &&
                    createRow(
                      'Fecha creación factura',
                      o?.InvoiceGeneratedDate
                    )}
                  {o?.CreditNoteId &&
                    createRow('Id Nota de crédito', o?.CreditNoteId)}
                  {o?.CreditNoteId &&
                    createRow(
                      'Fecha creación nota de crédito',
                      o?.CreditNoteGeneratedDate
                    )}
                  {o?.Entradas &&
                    o?.Entradas.map((t, i) => (
                      <>
                        <hr className={classes.separator} />
                        {createRow(t?.Tipo, t?.Cantidad, i)}
                      </>
                    ))}
                </TableBody>
              </Table>
            ))}
        </>
      )}
      {openDatePickerModal && (
        <SelectedDateModal
          open={openDatePickerModal}
          onClose={onCloseModal}
          orderId={orderId}
          isInvoice={isInvoice}
          invoiceDate={invoiceDate}
          updateMessageError={(m) => setMessageError(m)}
          showMessageError={(v) => setShowMessageError(v)}
        />
      )}
      <ConfirmationDialog
        open={openRefund}
        loading={refundPending}
        handleConfirmation={() => {
          setOpenRefund(false)
          setOpenReason(true)
        }}
        onCancel={() => setOpenRefund(false)}
        title={'ADVERTENCIA!'}
        message={'Está por devolver el dinero de esta orden.'}
      />
      <Dialog
        open={openRefundCash}
        onClose={() => setOpenRefundCash(false)}
        backdropClick={refundPending}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle>ADVERTENCIA!</DialogTitle>
        <DialogContent>
          <Typography>
            Está por devolver la orden correspondiente a un CUPON.
          </Typography>
          <Typography>¿Desea continuar?</Typography>
          <Typography
            className={`${classes.textDialogBold} ${classes.textDialog}`}>
            Recuerde
            <Typography component='span' className={classes.textDialog}>
              &nbsp;que el cliente debe tener su cuenta de mercado pago activa.
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenRefundCash(false)}
            disabled={refundPending}
            color='primary'>
            No
          </Button>
          <Button
            onClick={() => {
              setOpenRefundCash(false)
              setOpenReason(true)
            }}
            disabled={refundPending}
            color='primary'
            variant='contained'
            autoFocus>
            {refundPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Si'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={successNotification}>
        <DialogTitle>¡Orden devuelta!</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessNotification(false)
              dispatch(loadOrderDetails(orderId))
            }}
            color='primary'
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDialogConfirm}
        loading={actionPending}
        handleConfirmation={() => updateFromMP()}
        onCancel={() => setOpenDialogConfirm(false)}
        title={'¡ADVERTENCIA!'}
        message={'Está por actualizar el estado de la orden desde MercadoPago.'}
      />
      <Dialog
        open={showMessageError}
        onClose={() => setShowMessageError(false)}>
        <DialogTitle>¡ERROR!</DialogTitle>
        <DialogContent>
          <DialogContentText>{messageError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowMessageError(false)}
            color='primary'
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReason}
        onClose={() => {
          setReason('')
          setOpenReason(false)
        }}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent className={classes.contentDialog}>
          <TextField
            label='Motivo'
            fullWidth
            autoFocus
            inputProps={{ maxLength: 250 }}
            placeholder='Ingrese una pequeña descripción indicando causas o motivos de la devolución.'
            onChange={handleChange}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReason('')
              setOpenReason(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenReason(false)
              handleConfirmNotification()
            }}
            color='primary'
            variant='contained'
            startIcon={<Save />}
            disabled={reason.length < 3}
            autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRecoverDialog}
        onClose={() => setOpenRecoverDialog(false)}
        backdropClick={recoverPending}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle>ADVERTENCIA!</DialogTitle>
        <DialogContent>
          <Typography>
            Está por recuperar los tickets transferidos de esta orden.
          </Typography>
          <Typography>¿Desea continuar?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenRecoverDialog(false)}
            disabled={recoverPending}
            color='primary'>
            No
          </Button>
          <Button
            onClick={handleRecoverTickets}
            disabled={recoverPending}
            color='primary'
            variant='contained'
            autoFocus>
            {recoverPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Si'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

export default UserOrderDetailsPage
