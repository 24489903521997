import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Collapse,
  Typography
} from '@material-ui/core'
import TableGridHead from './TableGridHead'
import TableGridToolbar from './TableGridToolbar'
import { TableContentLoading } from './TableContentLoading'

export const createColumn = (
  key,
  label,
  align = 'left',
  formatter = null,
  className = ''
) => ({
  key,
  label,
  align,
  formatter,
  className
})

export const createCustomColumn = (content, label, align = 'left') => ({
  content,
  label,
  align
})

export const mockPagedList = (
  items = [],
  pageSize = null,
  pageNumber = 1,
  order = TableGridOrder.ASC,
  orderBy = ''
) => {
  const totalItemCount = items.length
  pageNumber <= 0 && (pageNumber = 1)

  // SORTING
  if (orderBy !== '') {
    items = items.sort((a, b) => (a[orderBy] > b[orderBy] ? 1 : -1))
  }
  if (order === TableGridOrder.DESC) items = items.reverse()

  // PAGINATION
  if (pageSize != null) {
    let x = (pageNumber - 1) * pageSize
    items = items.slice(x, x + pageSize)
  }

  return {
    // For migration purposes
    useNewFormat: true,
    items,
    metaData: {
      TotalItemCount: totalItemCount,
      PageNumber: pageNumber,
      PageSize: pageSize || items.length
    },
    order,
    orderBy
  }
}

const styles = (theme) => ({
  root: {
    width: '100%'
    // marginTop: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  table: {
    // minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  actionFooter: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing()
  },
  actionFooterPagination: {
    flexGrow: 1
  },
  searchFormContainer: {
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  tableCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 250,
    paddingBlock: 6
  },
  noDataMessage: {
    color: 'dimgrey',
    fontStyle: 'italic',
    fontWeight: 'bold'
  }
})

export const TableGridOrder = {
  ASC: 'asc',
  DESC: 'desc'
}

class TableGridComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      showConfirmDialog: false,
      confirmTitle: '',
      confirmMessage: ''
    }
  }

  componentDidMount() {
    const {
      loadItemsPage,
      pagedList,
      orderBy,
      order,
      searchParams,
      pageSize: pageSizeAtr = 5
    } = this.props
    const pageNumber = pagedList?.metaData?.PageNumber || 0
    const pageSize = pageSizeAtr || pagedList?.metaData?.PageSize
    const params = { searchParams, pageNumber, pageSize, orderBy, order }
    loadItemsPage(params)
  }

  onRequestSort = (event, property) => {
    const { loadItemsPage, pagedList, order, orderBy, searchParams } =
      this.props
    const pageNumber = pagedList?.MetaData?.PageNumber || 0
    const pageSize = pagedList?.MetaData?.PageSize || 5
    const isDesc = orderBy === property && order === TableGridOrder.DESC
    loadItemsPage({
      searchParams,
      pageNumber,
      pageSize,
      orderBy: property,
      order: isDesc ? TableGridOrder.ASC : TableGridOrder.DESC
    })
  }

  onSelectAllClick = (event) => {
    const { pagedList, keyName, useNewFormat } = this.props
    if (event.target.checked) {
      const newSelection = (
        useNewFormat || pagedList?.useNewFormat
          ? pagedList?.Items
          : pagedList?.content
      ).map((n) => n[keyName])
      this.setState({
        selected: newSelection
      })

      return
    }
    this.setState({
      selected: []
    })
  }

  onRowClick = (event, name) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({
      selected: newSelected
    })
  }

  handleChangePage = (event, newPage) => {
    const { loadItemsPage, pagedList, orderBy, order, searchParams } =
      this.props
    const size = pagedList?.MetaData?.PageSize || 5
    loadItemsPage({
      searchParams,
      pageSize: size,
      pageNumber: newPage + 1,
      orderBy,
      order
    })
  }

  handleChangeRowsPerPage = (event) => {
    const { loadItemsPage, pagedList, orderBy, order, searchParams } =
      this.props
    const pageNumber = pagedList?.MetaData?.PageNumber || 0
    loadItemsPage({
      searchParams,
      pageNumber: pageNumber || 0,
      pageSize: event.target.value,
      orderBy,
      order
    })
  }

  isSelected(name) {
    const { selected } = this.state
    return selected.indexOf(name) !== -1
  }

  onRequestAction = (actionConfig) => {
    this._currentAction = actionConfig.action

    if (actionConfig.confirm) {
      this.setState({
        showConfirmDialog: true,
        confirmTitle: actionConfig.title,
        confirmMessage: actionConfig.message
      })
    } else {
      this.onActionOk().then()
    }
  }

  onActionCancel = () => {
    this.clearConfirm()
  }

  onActionOk = async () => {
    try {
      await this._currentAction(this.state.selected)
    } finally {
      this.clearConfirm()
    }
  }

  clearConfirm() {
    this._currentAction = null
    this.setState({
      showConfirmDialog: false,
      confirmTitle: '',
      confirmMessage: ''
    })
  }

  render() {
    const {
      classes,
      isLoading,
      title,
      description,
      pagedList,
      columns = [],
      keyName,
      orderBy = 'Id',
      order = TableGridOrder.ASC,
      footerActions = [],
      toolbarActions = [],
      rowClasses = undefined,
      allowSelection,
      useNewFormat,
      pageSize: pageSizeAttr,
      searchForm,
      hideFooter,
      elevation = 2,
      noDataMessage = 'No se encontraron datos',
      expandedRow = null,
      ExpandedRowComponent = null
    } = this.props
    const { selected } = this.state

    const rows =
      (useNewFormat || pagedList?.useNewFormat
        ? pagedList?.Items || pagedList?.items
        : pagedList?.content) || []
    const rowCount = pagedList?.MetaData?.TotalItemCount || 0
    let pageNumber = pagedList?.MetaData?.PageNumber - 1 || 0
    const pageSize = pageSizeAttr || pagedList?.MetaData?.PageSize || 5
    const emptyRows = pageSize - Math.min(pageSize, rows.length)
    const columnCount = allowSelection ? columns.length + 1 : columns.length
    if (
      pagedList?.MetaData?.PageCount > 0 &&
      pageNumber >= pagedList?.MetaData?.PageCount
    ) {
      pageNumber = pagedList?.MetaData?.PageCount - 1
      this.handleChangePage(null, pageNumber)
    }
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={elevation}>
          <>
            {(!!title || !!description || !!toolbarActions.length) && (
              <TableGridToolbar
                title={title}
                description={description}
                numSelected={selected.length}
                actions={toolbarActions}
                onRequestAction={this.onRequestAction}
                allowSelection={allowSelection}
              />
            )}
            {searchForm && (
              <div className={classes.searchFormContainer}>{searchForm()}</div>
            )}

            <div className={classes.tableWrapper}>
              {isLoading ? (
                <TableContentLoading />
              ) : (
                <Table
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size='medium'>
                  <TableGridHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.onSelectAllClick}
                    onRequestSort={this.onRequestSort}
                    rowCount={rows.length}
                    columns={columns}
                    allowSelection={allowSelection}
                  />

                  <TableBody>
                    {!isLoading && rows.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={columnCount} align='center'>
                          <Typography
                            variant='h5'
                            className={classes.noDataMessage}>
                            {noDataMessage}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {rows.map((row, index) => {
                      const isItemSelected = this.isSelected(row[keyName])
                      const labelId = `enhanced-table-checkbox-${index}`

                      return allowSelection ? (
                        <TableRow
                          hover
                          onClick={(event) =>
                            this.onRowClick(event, row[keyName])
                          }
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row[keyName]}-${index}`}
                          selected={isItemSelected}>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          {columns.map((c) =>
                            !c.content ? (
                              <TableCell
                                align={c.align}
                                key={c.key}
                                className={classes.tableCell}>
                                {c.formatter
                                  ? c.formatter(row[c.key], row)
                                  : row[c.key]}
                              </TableCell>
                            ) : (
                              <TableCell key={c.label}>
                                {c.content(row)}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ) : (
                        <>
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={`${row[keyName]}-${index}`}
                            className={`${classes.tableCell} ${
                              rowClasses != null &&
                              typeof rowClasses === 'function'
                                ? rowClasses(row)
                                : typeof rowClasses === 'string'
                                  ? rowClasses
                                  : ''
                            }`}>
                            {columns.map((c) =>
                              !c.content ? (
                                <TableCell
                                  align={c.align}
                                  key={c.key}
                                  title={
                                    c.formatter
                                      ? c.formatter(row[c.key], row)
                                      : row[c.key]
                                  }
                                  className={`${classes.tableCell} ${c.className}`}>
                                  {c.formatter
                                    ? c.formatter(row[c.key], row)
                                    : row[c.key]}
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={c.label}
                                  className={`${classes.tableCell} ${c.className}`}>
                                  {c.content(row)}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}>
                              <Collapse
                                in={expandedRow === row[keyName]}
                                timeout='auto'
                                unmountOnExit>
                                {ExpandedRowComponent}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={columnCount} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
            {hideFooter ? null : (
              <div className={classes.actionFooter}>
                {footerActions.map((x) => (
                  <Button
                    variant='text'
                    color='primary'
                    key={x.title}
                    onClick={x.action}>
                    {x.title} {x.icon}
                  </Button>
                ))}

                <TablePagination
                  className={classes.actionFooterPagination}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage='Filas por página'
                  component='div'
                  count={rowCount}
                  rowsPerPage={pageSize}
                  page={pageNumber}
                  backIconButtonProps={{
                    title: 'Página anterior',
                    'aria-label': 'Back page'
                  }}
                  nextIconButtonProps={{
                    title: 'Siguiente página',
                    'aria-label': 'Next page'
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            )}
          </>
        </Paper>
      </div>
    )
  }
}

TableGridComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool,
  labelLoading: PropTypes.string,
  allowSelection: PropTypes.bool,
  noDataMessage: PropTypes.string
}

export const TableGrid = withStyles(styles)(TableGridComponent)

export default TableGrid
