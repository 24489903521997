import axios from 'axios'
import BaseProxy from './BaseProxy'

export class BillingProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/billing/organizer`
  }

  async getAll(organizerId) {
    const response = await axios.get(`${this._baseUrl}/${organizerId}/all`)

    return response.data
  }

  async getInvoiceBooks(organizerId, documentTypeId) {
    const response = await axios.get(
      `${this._baseUrl}/${organizerId}/document-type/${documentTypeId}/document-book`
    )

    return response.data
  }
}

export default BillingProxy
