import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUsersSearch, resetUsers } from '~/state/modules/users'
import { CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer, SearchComponent } from '~views/shared'
import { removeAccents } from 'views/utils/functions'
import { useStyles } from './UsersSearch.style'
import { UsersList } from '../../components/UsersList'

export const UsersSearch = ({ onSuccess }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const [usersSearchActive, setUsersSearchActive] = useState(false)
  const [usersFilter, setUsersFilter] = useState()
  const dispatch = useDispatch()
  const { usersSearch, pending } = useSelector((state) => state.users)

  const performSearch = async (searchKey) => {
    if (searchKey.length >= 3) {
      setSearchValue(searchKey)
      await dispatch(loadUsersSearch(searchKey))
      setUsersSearchActive(true)
    }
  }

  const performSearchFilter = (searchKey) => {
    setSearchValue(searchKey)
    setUsersFilter(
      usersSearch.filter(
        (c) =>
          removeAccents(c.FullName?.toUpperCase()).includes(
            removeAccents(searchKey.toUpperCase())
          ) || c.Id?.toString().startsWith(searchKey)
      )
    )
    setUsersSearchActive(false)
  }

  useEffect(() => {
    return () => {
      dispatch(resetUsers())
    }
  }, [dispatch])

  return (
    <PaperContainer
      title={
        <div className={classes.title}>
          <Typography variant='h5'>Seleccione un usuario</Typography>
        </div>
      }>
      <div className={classes.titleContainer}>
        <SearchComponent
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder='Ingresar datos...'
          pending={pending}
          performSearchFilter={performSearchFilter}
        />
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {usersFilter && !usersSearchActive
            ? usersFilter.map((user, i) => (
                <UsersList onSuccess={onSuccess} user={user} key={i} />
              ))
            : usersSearch.map((user, i) => (
                <UsersList onSuccess={onSuccess} user={user} key={i} />
              ))}
        </>
      )}
    </PaperContainer>
  )
}

export default UsersSearch
