import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAccount } from '~/state/modules/accounts'
import { loadOrganizers } from 'state/modules/organizers'
import { PaperContainer } from '~/views/shared'
import { AccountsForm } from '../AccountsForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const AccountsCreatePage = ({ onSuccess }) => {
  const { organizers, pending: pendingOrganizers } = useSelector(
    (state) => state.organizers
  )
  const { pendingCreate } = useSelector((state) => state.accounts)

  const dispatch = useDispatch()

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(createAccount(values))
      onSuccess()
      showSnackbarSuccess('Cuenta Creada!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    Cuit: '',
    RazonSocial: '',
    CommerceId: '',
    SecretKey: '',
    Phrase: '',
    Organizers: [],
    OrganizersSelected: null
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizers())
    }
    fetchData().then()
  }, [dispatch])

  return (
    <PaperContainer title='Creación de Cuenta'>
      <AccountsForm
        values={values}
        organizers={organizers}
        actionText='Crear'
        handleSubmit={handleSubmit}
        pendingOrganizers={pendingOrganizers}
        pendingCreate={pendingCreate}
        onCancel={() => history.push(ROUTES.ADMIN.ACCOUNTS.INDEX)}
      />
    </PaperContainer>
  )
}

export default AccountsCreatePage
