import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaperContainer } from '~/views/shared'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField
} from '@material-ui/core'
import { ResellersForm } from '../components/ResellersForm'
import {
  loadReseller,
  resetPin,
  update,
  resetActions
} from '~/state/modules/resellers'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { validPin } from 'views/utils/functions'

export const ResellersUpdatePage = ({ onSuccess, match }) => {
  const { reseller, pending, actionPending, actionSuccess } = useSelector(
    (state) => state.resellers
  )
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openDialogPIN, setOpenDialogPIN] = useState(false)
  const [newPin, setNewPin] = useState()

  const handleReset = async () => {
    const generatedPin = {
      PIN: newPin
    }
    try {
      await dispatch(resetPin(reseller?.UserId, generatedPin))
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
    setNewPin(null)
    setOpenDialogPIN(false)
    dispatch(resetActions())
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleReset()
    }
  }

  useEffect(() => {
    dispatch(loadReseller(match.params.userId))
  }, [dispatch, match])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(update(values.UserId, values))
      onSuccess()
      showSnackbarSuccess('RRPP Actualizado!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    UserId: reseller?.UserId || '',
    Id: reseller?.Id || '',
    DisplayName: reseller?.DisplayName || '',
    BankAccountNumber: reseller?.BankAccountNumber || '',
    Cuit: reseller?.Cuit || '',
    MercadoPagoEmail: reseller?.MercadoPagoEmail || '',
    Active: reseller?.Active,
    SellForTicketPass: reseller?.SellForTicketPass,
    HasPINGenerated: reseller?.HasPINGenerated
  }

  return (
    <>
      <PaperContainer title='Editar Rrpp'>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <ResellersForm
            values={values}
            actionText='Actualizar RRPP'
            handleSubmit={handleSubmit}
            actionPending={actionPending}
            actionSuccess={actionSuccess}
            onCancel={() => history.push(ROUTES.ADMIN.RESELLERS.INDEX)}
            updateForm
            generatePIN={() => setOpenDialogPIN(true)}
          />
        )}
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>¡PIN reseteado exitosamente!</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogPIN}
        onClose={() => {
          setNewPin(null)
          setOpenDialogPIN(false)
        }}>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='pin'
            autoComplete='off'
            label='Ingrese el nuevo PIN:'
            type='string'
            placeholder='****'
            inputProps={{ maxLength: 4 }}
            onKeyPress={handleSearchKeyPress}
            onKeyDown={(e) => validPin(e)}
            onChange={(e) => setNewPin(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewPin(null)
              setOpenDialogPIN(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleReset()
            }}
            color='primary'
            disabled={newPin?.length !== 4 || actionPending}>
            {actionPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'OK'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResellersUpdatePage
