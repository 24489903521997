export const PAGEDLIST_ATTEMPT = 'admin/resellers/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/resellers/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/resellers/PAGEDLIST_FAILURE'

export const LOAD_ATTEMPT = 'admin/resellers/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/resellers/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/resellers/LOAD_FAILURE'

export const CREATE_ATTEMPT = 'admin/resellers/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'admin/resellers/CREATE_SUCCESS'
export const CREATE_FAILURE = 'admin/resellers/CREATE_FAILURE'

export const UPDATE_ATTEMPT = 'admin/resellers/UPDATE_ATTEMPT'
export const UPDATE_SUCCESS = 'admin/resellers/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'admin/resellers/UPDATE_FAILURE'

export const DELETE_ATTEMPT = 'admin/resellers/DELETE_ATTEMPT'
export const DELETE_SUCCESS = 'admin/resellers/DELETE_SUCCESS'
export const DELETE_FAILURE = 'admin/resellers/DELETE_FAILURE'
//rrpp

export const LOAD_EVENT_RESELLERS_ATTEMPT =
  'admin/resellers/LOAD_EVENT_RESELLERS_ATTEMPT'
export const LOAD_EVENT_RESELLERS_FAILURE =
  'admin/resellers/LOAD_EVENT_RESELLERS_FAILURE'
export const LOAD_EVENT_RESELLERS_SUCCESS =
  'admin/resellers/LOAD_EVENT_RESELLERS_SUCCESS'

export const LOAD_EVENT_RESELLERS_PAGED_ATTEMPT =
  'admin/resellers/LOAD_EVENT_RESELLERS_PAGED_ATTEMPT'
export const LOAD_EVENT_RESELLERS_PAGED_FAILURE =
  'admin/resellers/LOAD_EVENT_RESELLERS_PAGED_FAILURE'
export const LOAD_EVENT_RESELLERS_PAGED_SUCCESS =
  'admin/resellers/LOAD_EVENT_RESELLERS_PAGED_SUCCESS'

export const LOAD_RESELLERS_SALES_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_SALES_ATTEMPT'
export const LOAD_RESELLERS_SALES_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_SALES_SUCCESS'
export const LOAD_RESELLERS_SALES_FAILURE =
  'admin/resellers/LOAD_RESELLERS_SALES_FAILURE'

//RRPP TP

export const LOAD_RESELLERS_TP_SALES_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_ATTEMPT'
export const LOAD_RESELLERS_TP_SALES_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_SUCCESS'
export const LOAD_RESELLERS_TP_SALES_FAILURE =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_FAILURE'

export const LOAD_RESELLERS_TP_SALES_PAGED_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_PAGED_ATTEMPT'
export const LOAD_RESELLERS_TP_SALES_PAGED_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_PAGED_SUCCESS'
export const LOAD_RESELLERS_TP_SALES_PAGED_FAILURE =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_PAGED_FAILURE'

export const LOAD_RESELLERS_TP_SALES_DETAILS_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_DETAILS_ATTEMPT'
export const LOAD_RESELLERS_TP_SALES_DETAILS_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_DETAILS_SUCCESS'
export const LOAD_RESELLERS_TP_SALES_DETAILS_FAILURE =
  'admin/resellers/LOAD_RESELLERS_TP_SALES_DETAILS_FAILURE'

//RRPP CASH

export const LOAD_RESELLERS_CASH_SALES_PAGED_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_PAGED_ATTEMPT'
export const LOAD_RESELLERS_CASH_SALES_PAGED_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_PAGED_SUCCESS'
export const LOAD_RESELLERS_CASH_SALES_PAGED_FAILURE =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_PAGED_FAILURE'

export const LOAD_RESELLERS_CASH_SALES_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_ATTEMPT'
export const LOAD_RESELLERS_CASH_SALES_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_SUCCESS'
export const LOAD_RESELLERS_CASH_SALES_FAILURE =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_FAILURE'

export const LOAD_RESELLERS_CASH_SALES_DETAILS_ATTEMPT =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_DETAILS_ATTEMPT'
export const LOAD_RESELLERS_CASH_SALES_DETAILS_SUCCESS =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_DETAILS_SUCCESS'
export const LOAD_RESELLERS_CASH_SALES_DETAILS_FAILURE =
  'admin/resellers/LOAD_RESELLERS_CASH_SALES_DETAILS_FAILURE'

export const SET_FILTERS = 'admin/resellers/SET_FILTERS'

export const RESET = 'admin/resellers/RESET'
export const RESET_ACTIONS = 'admin/resellers/RESET_ACTIONS'
export const RESET_PARAMS = 'admin/resellers/RESET_PARAMS'

//PIN

export const GENERATE_PIN_ATTEMPT = 'admin/resellers/GENERATE_PIN_ATTEMPT'
export const GENERATE_PIN_SUCCESS = 'admin/resellers/GENERATE_PIN_SUCCESS'
export const GENERATE_PIN_FAILURE = 'admin/resellers/GENERATE_PIN_FAILURE'

export const SET_RESELLER_TYPE = 'admin/orders/SET_RESELLER_TYPE'

export const LOAD_EVENT_WITH_TICKETS_ATTEMPT =
  'admin/resellers/LOAD_EVENT_WITH_TICKETS_ATTEMPT'
export const LOAD_EVENT_WITH_TICKETS_SUCCESS =
  'admin/resellers/LOAD_EVENT_WITH_TICKETS_SUCCESS'
export const LOAD_EVENT_WITH_TICKETS_FAILURE =
  'admin/resellers/LOAD_EVENT_WITH_TICKETS_FAILURE'

export const GENERATE_QR_ATTEMPT = 'admin/resellers/GENERATE_QR_ATTEMPT'
export const GENERATE_QR_SUCCESS = 'admin/resellers/GENERATE_QR_SUCCESS'
export const GENERATE_QR_FAILURE = 'admin/resellers/GENERATE_QR_FAILURE'
