import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { create } from '~/state/modules/locations'
import { PaperContainer } from '~/views/shared'
import { LocationForm } from '../components/LocationForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const LocationCreatePage = ({ onSuccess }) => {
  const { pending, actionPending, actionSuccess } = useSelector(
    (state) => state.locations
  )

  const dispatch = useDispatch()

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(create(values))
      onSuccess()
      showSnackbarSuccess('Localidad Creada!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    Name: '',
    FullAddress: '',
    Longitude: '',
    Latitude: ''
  }

  return (
    <PaperContainer title='Creación de lugar'>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <LocationForm
          values={values}
          actionText='Crear'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          onCancel={() => history.push(ROUTES.ADMIN.LOCATIONS.INDEX)}
        />
      )}
    </PaperContainer>
  )
}

export default LocationCreatePage
