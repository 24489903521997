import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './OrganizerIndexPage.style'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { loadPage, deleteOrganizer, reset } from '~/state/modules/organizers'
import {
  TableGrid,
  createColumn,
  createCustomColumn,
  TableGridOrder
} from '~/views/shared'
import { DeleteDialog } from '~/views/shared'
import history from '~/config/history'
import * as moment from 'moment'
import { OrganizerActionsMenu } from '../components/OrganizerActionsMenu'
import { ROUTES } from '~/views/routes'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = (
  setSelectedOrganizer,
  setShowDeleteDialog,
  performSearch
) => {
  return [
    createColumn('Id', 'ID'),
    createColumn('Name', 'Nombre'),
    createColumn('Commission', 'Comisión'),
    createColumn('MercadoPagoUserId', 'UserId de MP'),
    createColumn(
      'MercadoPagoExpirationDate',
      'Expiración de MP',
      'left',
      (x) => (x === null ? '' : moment(x).format('DD/MM/YYYY'))
    ),
    createColumn(
      'AutomaticInvoiceEnabled',
      'Facturación Automática',
      'left',
      (x) => (x ? 'Si' : 'No')
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            history.push(ROUTES.ADMIN.ORGANIZERS.UPDATE.replace(':id', c.Id))
          }}>
          <Edit />
        </Button>
      ),
      'Editar'
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            setSelectedOrganizer(c)
            setShowDeleteDialog(true)
          }}>
          <Delete />
        </Button>
      ),
      'Eliminar'
    ),
    createCustomColumn(
      (c) => (
        <OrganizerActionsMenu organizer={c} refreshItems={performSearch} />
      ),
      'Acciones'
    )
  ]
}

const makeFooterActions = () => [
  {
    title: 'Crear',
    icon: <Add />,
    action: () => {
      history.push(ROUTES.ADMIN.ORGANIZERS.CREATE)
    }
  }
]

export const OrganizerIndexPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pagedList, pending, actionPending, reqParams } = useSelector(
    (state) => state.organizers
  )
  const [selectedOrganizer, setSelectedOrganizer] = useState(null)
  const [requestParams, setRequestParams] = useState({})
  const [search, setSearch] = useState(reqParams?.searchParams || '')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [open, setOpen] = useState(false)

  const handleClose = async () => {
    setOpen(false)
    await dispatch(reset())
    await dispatch(loadPage(requestParams))
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteOrganizer(selectedOrganizer?.Id))
      setShowDeleteDialog(false)
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    dispatch(
      loadPage({
        searchParams: search,
        pageNumber: 0,
        pageSize: 5,
        orderBy: reqParams?.orderBy || 'Name',
        order: reqParams?.order || TableGridOrder.ASC
      })
    )
  }

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Nombre'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button variant='contained' color='primary' onClick={performSearch}>
          Buscar
        </Button>
      </div>
    )
  }

  return (
    <>
      <TableGrid
        title='Organizadores'
        rowClasses={(x) => x?.IsEnabled && `${classes.conditionalRow}`}
        columns={makeColumns(
          setSelectedOrganizer,
          setShowDeleteDialog,
          performSearch
        )}
        footerActions={makeFooterActions()}
        searchForm={makeSearchForm}
        searchParams={search}
        loadItemsPage={(x) => {
          setRequestParams(x)
          dispatch(
            loadPage({
              searchParams: search,
              pageNumber: x.pageNumber || reqParams?.pageNumber,
              pageSize: x.pageSize,
              orderBy: x.orderBy,
              order: x.order
            })
          )
        }}
        isLoading={pending}
        pagedList={pagedList}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.ASC}
        orderBy={reqParams?.orderBy || 'Name'}
        useNewFormat
      />
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title='Eliminar Organizador'
        message='Está por eliminar el organizador'
        entityIdentifier={selectedOrganizer?.Name}
      />
      <Dialog open={open}>
        <DialogTitle>Organizador eliminado</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OrganizerIndexPage
