import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginInline: theme.spacing(2),
    color: '#4e5754'
  },
  title: {
    textTransform: 'uppercase'
  },
  lastUpdate: {
    fontStyle: 'italic'
  }
}))

export default useStyles
