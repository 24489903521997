import React, { useState } from 'react'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import WcIcon from '@material-ui/icons/Wc'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'

export const ProducersActionsMenu = ({ organizer }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.PRODUCERS.INDEX.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <SupervisorAccountIcon fontSize='small' />
          </ListItemIcon>
          Mis Administradores
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.RESELLERS.INDEX.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <WcIcon fontSize='small' />
          </ListItemIcon>
          Mis RRPP
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.CONTROLLERS.INDEX.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <MonetizationOnIcon fontSize='small' />
          </ListItemIcon>
          Mis Controladores de Ingreso
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.INVITATIONS.replace(':id', organizer.Id)
            )
          }>
          <ListItemIcon>
            <InsertInvitationIcon fontSize='small' />
          </ListItemIcon>
          Enviar Invitaciones
        </MenuItem>
      </Menu>
    </div>
  )
}

export default ProducersActionsMenu
