import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1)
  },
  imagePickerContainer: {
    display: 'flex',
    flexDirection: 'row',

    marginBottom: theme.spacing(2)
  },
  imagePicker: {
    flexGrow: '4',
    height: 200,
    margin: '0 auto',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'lightgray',
    [theme.breakpoints.up('md')]: {
      height: 300
    }
  },
  imagePickerSideBar: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2)
  },
  imagePickerInfo: {
    flexGrow: '1'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  tickets: {
    marginLeft: 20
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

export default useStyles
