import { colors } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import typography from './typography'
import shadows from './shadows'

// REF: https://material-ui.com/es/customization/default-theme/
export const theme = createTheme({
  /*typography: {
    fontFamily: '"Montserrat", sans-serif',
    useNextVariants: true
  },*/
  palette: {
    primary: {
      light: '#ff616f',
      main: '#ff1744',
      dark: '#c4001d',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#66ffa6',
      main: '#00e676',
      dark: '#00b248',
      contrastText: '#ffffff'
    },
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  typography,
  shadows
})
