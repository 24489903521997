import * as types from './types'
import { UsersProxy } from 'services'
import { showSnackbarError } from '~/state/modules/app'
import { mockPagedList } from '../../../views/shared'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const loadUserAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadUserSuccess = (user) => ({
  type: types.LOAD_SUCCESS,
  user
})
export const loadUserFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const loadUsersSearchAttempt = () => ({
  type: types.LOAD_USERS_SEARCH_ATTEMPT
})

export const loadUsersSearchSuccess = (usersSearch) => ({
  type: types.LOAD_USERS_SEARCH_SUCCESS,
  usersSearch
})

export const loadUsersSearchFailure = (error) => ({
  type: types.LOAD_USERS_SEARCH_FAILURE,
  error
})

export const loadUsersAttempt = () => ({
  type: types.LOAD_USERS_ATTEMPT
})

export const loadUsersSuccess = (users) => ({
  type: types.LOAD_USERS_SUCCESS,
  users
})

export const loadUsersFailure = (error) => ({
  type: types.LOAD_USERS_FAILURE,
  error
})

export const changePasswordAttempt = () => ({
  type: types.CHANGE_PASSWORD_ATTEMPT
})

export const changePasswordSuccess = () => ({
  type: types.CHANGE_PASSWORD_SUCCESS
})

export const changePasswordFailure = (error) => ({
  type: types.CHANGE_PASSWORD_FAILURE,
  error
})

export const mergeAccountsAttempt = () => ({
  type: types.MERGE_ACCOUNTS_ATTEMPT
})

export const mergeAccountsSuccess = () => ({
  type: types.MERGE_ACCOUNTS_SUCCESS
})

export const mergeAccountsFailure = (error) => ({
  type: types.MERGE_ACCOUNTS_FAILURE,
  error
})

export const previousRouteSet = (previousRoute) => ({
  type: types.SET_PREVIOUS_ROUTE,
  previousRoute
})

export const resetUsers = () => ({
  type: types.RESET
})

export const loadUsersSearch = (searchKey) => async (dispatch) => {
  try {
    dispatch(loadUsersSearchAttempt())

    const proxy = new UsersProxy()
    const response = await proxy.getUser(searchKey)
    dispatch(loadUsersSearchSuccess(response))
    return true
  } catch (error) {
    dispatch(loadUsersSearchFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const loadUserPaged =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(pagedListAttempt({ searchParams, order, orderBy }))

      const proxy = new UsersProxy()
      const response = await proxy.getUsers(searchParams)

      dispatch(
        pagedListSuccess(
          mockPagedList(response, pageSize, pageNumber, order, orderBy)
        )
      )
    } catch (e) {
      dispatch(pagedListFailure(e))
      throw e
    }
  }

export const loadUser = (userId) => async (dispatch) => {
  try {
    dispatch(loadUserAttempt())

    const proxy = new UsersProxy()
    const response = await proxy.getUserById(userId)

    dispatch(loadUserSuccess(response))
  } catch (e) {
    dispatch(loadUserFailure(e))
    throw e
  }
}

export const changePassword = (userId, passwordChanged) => async (dispatch) => {
  try {
    dispatch(changePasswordAttempt())

    const proxy = new UsersProxy()
    const response = await proxy.changePassword(userId, passwordChanged)

    dispatch(changePasswordSuccess(response))
  } catch (error) {
    dispatch(changePasswordFailure(error))
    throw error
  }
}

export const mergeAccounts = (ids) => async (dispatch) => {
  try {
    dispatch(mergeAccountsAttempt())

    const proxy = new UsersProxy()
    const response = await proxy.mergeAccounts(ids)

    dispatch(mergeAccountsSuccess(response))
  } catch (error) {
    dispatch(mergeAccountsFailure(error))
    throw error
  }
}

export const loadUsers = (payload) => async (dispatch) => {
  try {
    dispatch(loadUsersAttempt())

    const proxy = new UsersProxy()
    const response = await proxy.getUsers(payload)

    dispatch(loadUsersSuccess(response))
    return response
  } catch (error) {
    dispatch(loadUsersFailure(error))
    throw error
  }
}

export const setPreviousRoute = (previousRoute) => (dispatch) => {
  dispatch(previousRouteSet(previousRoute))
}
