import * as types from './types'
import { BillingProxy } from '~/services'

export const loadAllCollectionsAttempt = () => ({
  type: types.LOAD_ALL_COLLECTIONS_ATTEMPT
})

export const loadAllCollectionsSuccess = (collections) => ({
  type: types.LOAD_ALL_COLLECTIONS_SUCCESS,
  collections
})

export const loadAllCollectionsFailure = (error) => ({
  type: types.LOAD_ALL_COLLECTIONS_FAILURE,
  error
})

export const loadInvoiceBooksAttempt = () => ({
  type: types.LOAD_INVOICE_BOOKS_ATTEMPT
})

export const loadInvoiceBooksSuccess = (invoiceBooks) => ({
  type: types.LOAD_INVOICE_BOOKS_SUCCESS,
  invoiceBooks
})

export const loadInvoiceBooksFailure = (error) => ({
  type: types.LOAD_INVOICE_BOOKS_FAILURE,
  error
})

export const loadCreditNoteBookletsAttempt = () => ({
  type: types.LOAD_CREDIT_NOTE_BOOKLETS_ATTEMPT
})

export const loadCreditNoteBookletsSuccess = (creditNoteBooklets) => ({
  type: types.LOAD_CREDIT_NOTE_BOOKLETS_SUCCESS,
  creditNoteBooklets
})

export const loadCreditNoteBookletsFailure = (error) => ({
  type: types.LOAD_CREDIT_NOTE_BOOKLETS_FAILURE,
  error
})

export const loadAllCollections = (organizerId) => async (dispatch) => {
  try {
    dispatch(loadAllCollectionsAttempt())

    const proxy = new BillingProxy()
    const response = await proxy.getAll(organizerId)

    dispatch(loadAllCollectionsSuccess(response))
  } catch (error) {
    dispatch(loadAllCollectionsFailure(error))
    throw error
  }
}

export const loadInvoiceBooks =
  (organizerId, creditNoteTypeId) => async (dispatch) => {
    try {
      dispatch(loadInvoiceBooksAttempt())

      const proxy = new BillingProxy()
      const response = await proxy.getInvoiceBooks(
        organizerId,
        creditNoteTypeId
      )

      dispatch(loadInvoiceBooksSuccess(response))
    } catch (error) {
      dispatch(loadInvoiceBooksFailure(error))
      throw error
    }
  }

export const loadCreditNoteBooklets =
  (organizerId, creditNoteBookletId) => async (dispatch) => {
    try {
      dispatch(loadCreditNoteBookletsAttempt())

      const proxy = new BillingProxy()
      const response = await proxy.getInvoiceBooks(
        organizerId,
        creditNoteBookletId
      )

      dispatch(loadCreditNoteBookletsSuccess(response))
    } catch (error) {
      dispatch(loadCreditNoteBookletsFailure(error))
      throw error
    }
  }
