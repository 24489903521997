export const FETCH_INVITATIONS_ATTEMPT =
  'admin/invitations/FETCH_INVITATIONS_ATTEMPT'
export const FETCH_INVITATIONS_SUCCESS =
  'admin/invitations/FETCH_INVITATIONS_SUCCESS'
export const FETCH_INVITATIONS_FAILURE =
  'admin/invitations/FETCH_INVITATIONS_FAILURE'

export const SEND_ATTEMPT = 'admin/invitations/SEND_ATTEMPT'
export const SEND_SUCCESS = 'admin/invitations/SEND_SUCCESS'
export const SEND_FAILURE = 'admin/invitations/SEND_FAILURE'

export const RESET = 'admin/invitations/RESET'

export const SET_INVITATION_DETAILS = 'admin/invitations/SET_INVITATION_DETAILS'
