import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  drawer: {
    background: theme.palette.common.white,
    zIndex: 2000
  },
  menuItem: {
    height: theme.spacing(6)
  },
  activeItem: {
    backgroundColor: '#F5F5F5'
  },
  menuIcon: {
    justifyContent: 'center'
  },
  primaryText: {
    maxWidth: 160,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'dimgray'
  },
  roles: {
    display: 'grid'
  },
  subItems: {
    marginTop: 20,
    marginLeft: 170
  },
  subItemsSupport: {
    marginLeft: 170
  },
  profile: {
    color: theme.palette.primary.dark
  },
  button: {
    display: 'flex'
  },
  textButton: {
    textTransform: 'none',
    marginLeft: 15
  }
}))

export default useStyles
