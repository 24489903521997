export const PAGEDLIST_ATTEMPT = 'admin/users/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/users/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/users/PAGEDLIST_FAILURE'

export const LOAD_ATTEMPT = 'admin/users/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/users/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/users/LOAD_FAILURE'

export const LOAD_USERS_SEARCH_ATTEMPT = 'admin/users/LOAD_USERS_SEARCH_ATTEMPT'
export const LOAD_USERS_SEARCH_SUCCESS = 'admin/users/LOAD_USERS_SEARCH_SUCCESS'
export const LOAD_USERS_SEARCH_FAILURE = 'admin/users/LOAD_USERS_SEARCH_FAILURE'

export const LOAD_USERS_ATTEMPT = 'admin/users/LOAD_USERS_ATTEMPT'
export const LOAD_USERS_SUCCESS = 'admin/users/LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAILURE = 'admin/users/LOAD_USERS_FAILURE'

export const CHANGE_PASSWORD_ATTEMPT = 'admin/users/CHANGE_PASSWORD_ATTEMPT'
export const CHANGE_PASSWORD_SUCCESS = 'admin/users/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'admin/users/CHANGE_PASSWORD_FAILURE'

export const MERGE_ACCOUNTS_ATTEMPT = 'admin/users/MERGE_ACCOUNTS_ATTEMPT'
export const MERGE_ACCOUNTS_SUCCESS = 'admin/users/MERGE_ACCOUNTS_SUCCESS'
export const MERGE_ACCOUNTS_FAILURE = 'admin/users/MERGE_ACCOUNTS_FAILURE'

export const SET_PREVIOUS_ROUTE = 'admin/users/SET_PREVIOUS_ROUTE'

export const RESET = 'admin/users/RESET'
