import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginInblock: 20
  },
  value: {
    fontWeight: 'bold'
  },
  id: {
    marginTop: 15
  },
  dialogPin: {
    width: 300
  }
}))

export default useStyles
