import * as types from './types'

const initialState = {
  pagedList: null,
  user: null,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null,
  usersSearch: [],
  users: [],
  pendingUsers: false,
  previousRoute: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        pending: true,
        user: null,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        user: action.user,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        pending: false,
        user: null,
        error: action.error
      }
    case types.LOAD_USERS_SEARCH_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        usersSearch: []
      }
    case types.LOAD_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        usersSearch: action.usersSearch
      }
    case types.LOAD_USERS_SEARCH_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        usersSearch: []
      }
    case types.LOAD_USERS_ATTEMPT:
      return {
        ...state,
        pendingUsers: true,
        error: null,
        users: []
      }
    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        pendingUsers: false,
        error: null,
        users: action.users
      }
    case types.LOAD_USERS_FAILURE:
      return {
        ...state,
        pendingUsers: false,
        error: action.error,
        users: []
      }
    case types.CHANGE_PASSWORD_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null
      }
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.MERGE_ACCOUNTS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.MERGE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null
      }
    case types.MERGE_ACCOUNTS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.SET_PREVIOUS_ROUTE:
      return {
        ...state,
        previousRoute: action.previousRoute
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
