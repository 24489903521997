import axios from 'axios'
import BaseProxy from './BaseProxy'

export class LocationsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/locations`
  }

  async get(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getAll() {
    const response = await axios.get(`${this._baseUrl}`)

    return response.data
  }

  async getById(id) {
    const response = await axios.get(`${this._baseUrl}/${id}`)

    return response.data
  }

  async post(location) {
    const response = await axios.post(`${this._baseUrl}`, location)

    return response.data
  }

  async put(id, location) {
    const response = await axios.put(`${this._baseUrl}/${id}`, location)

    return response.data
  }

  async delete(id) {
    const response = await axios.delete(`${this._baseUrl}/${id}`)
    return response.data
  }
}

export default LocationsProxy
