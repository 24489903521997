import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableRow,
  Select,
  MenuItem
} from '@material-ui/core'
import { loadEvent } from '~/state/modules/events'
import { RESELLER_TYPES } from '~config/constants'
import { PaperContainer, TableGridOrder } from '~views/shared'
import { ResellerSearchComponent } from './components/ResellerSearchComponent'
import { createColumn, createCustomColumn, TableGrid } from 'views/shared'
import { Info } from '@material-ui/icons'
import {
  loadEventResellerPage,
  loadEventResellerPaged,
  setResellerType,
  setFilters,
  loadResellersTPSales,
  loadResellerCashSales,
  loadResellersTPSalesPaged,
  loadResellerCashSalesPaged
} from 'state/modules/resellers'
import { useStyles } from './ResellerEventPage.style'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'

const makeColumns = (eventId, rrpp, classes) => {
  return [
    createColumn('Id', 'ID'),
    createColumn(
      'DisplayName',
      'Nombre',
      'left',
      (c, o) => `${c}${!!o.BankAccountNumber ? ' $' : ''}`,
      classes.nameColumn
    ),
    createColumn('Sales', 'Ventas'),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            switch (rrpp) {
              case RESELLER_TYPES.RESELLER:
                history.push(
                  ROUTES.ADMIN.EVENTS.RESELLERS.VIEW.replace(
                    ':userId',
                    c.Id
                  ).replace(':eventId', eventId)
                )
                break
              case RESELLER_TYPES.RESELLER_TP:
                history.push(
                  ROUTES.ADMIN.EVENTS.RESELLERS.TPVIEW.replace(
                    ':eventId',
                    eventId
                  ).replace(':userId', c.Id)
                )
                break
              case RESELLER_TYPES.RESELLER_CASH:
                history.push(
                  ROUTES.ADMIN.EVENTS.RESELLERS.CASHVIEW.replace(
                    ':userId',
                    c.Id
                  ).replace(':eventId', eventId)
                )
                break
              default:
                return 0
            }
          }}>
          <Info />
        </Button>
      ),
      'Ver Ventas'
    )
  ]
}

const makeFooterActions = () => [
  {
    title: 'Volver',
    action: () => {
      history.push(ROUTES.ADMIN.EVENTS.INDEX)
    }
  }
]

export const ResellerEventPage = ({ match }) => {
  const {
    resellerEvent,
    resellerTPSalesEvent,
    resellerCashSales,
    resellerEventPaged,
    resellerTPSalesEventPaged,
    resellerCashSalesPaged,
    reqParams,
    resellerSelected,
    filters,
    pending: resellersPending
  } = useSelector((state) => state.resellers)

  const [totalVentas, setTotalVentas] = useState(0)
  const { pending, event } = useSelector((state) => state.events)
  const classes = useStyles()
  const [data, setData] = useState(null)
  const [search, setSearch] = useState(filters.searchKey)
  const [rrpp, setRrpp] = useState(resellerSelected)

  const dispatch = useDispatch()

  const { pending: pendingStatistics } = useSelector(
    (state) => state.statistics
  )

  const performSearch = useCallback(
    async (rrpp, searchKey = search) => {
      dispatch(setFilters('', searchKey.trim()))
      const params = {
        searchParams: searchKey,
        pageNumber: 0,
        pageSize: 5,
        orderBy: 'Sales',
        order: TableGridOrder.DESC
      }
      switch (rrpp) {
        case RESELLER_TYPES.RESELLER_TP:
          await dispatch(loadResellersTPSales(match.params.eventId))
          await dispatch(
            loadResellersTPSalesPaged(match.params.eventId, params)
          )
          break
        case RESELLER_TYPES.RESELLER_CASH:
          await dispatch(loadResellerCashSales(match.params.eventId))
          await dispatch(
            loadResellerCashSalesPaged(match.params.eventId, params)
          )
          break
        case RESELLER_TYPES.RESELLER:
        default:
          await dispatch(loadEventResellerPaged(match.params.eventId, params))
      }
    },
    [match, dispatch, search]
  )

  const changeResellerType = (rrppSelect) => {
    dispatch(setResellerType(rrppSelect))
    setSearch('')
    dispatch(setFilters('', ''))
    setRrpp(rrppSelect)
    performSearch(rrppSelect, '')
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    if (resellerSelected === RESELLER_TYPES.RESELLER) {
      async function fetchData() {
        await dispatch(loadEventResellerPage(match.params.eventId))
      }

      fetchData().then()
    }
  }, [dispatch, match, resellerSelected])

  useEffect(() => {
    switch (resellerSelected) {
      case RESELLER_TYPES.RESELLER_TP:
        setData(resellerTPSalesEventPaged)
        break
      case RESELLER_TYPES.RESELLER_CASH:
        setData(resellerCashSalesPaged)
        break
      case RESELLER_TYPES.RESELLER:
      default:
        setData(resellerEventPaged)
    }
  }, [
    setData,
    resellerSelected,
    resellerEventPaged,
    resellerTPSalesEventPaged,
    resellerCashSalesPaged
  ])

  useEffect(() => {
    switch (rrpp) {
      case RESELLER_TYPES.RESELLER_TP:
        resellerTPSalesEvent != null && calculate(resellerTPSalesEvent.content)
        break
      case RESELLER_TYPES.RESELLER_CASH:
        resellerCashSales != null && calculate(resellerCashSales.content)
        break
      case RESELLER_TYPES.RESELLER:
      default:
        resellerEvent != null && calculate(resellerEvent.content)
    }
  }, [resellerEvent, resellerTPSalesEvent, resellerCashSales, rrpp])

  const calculate = (rrppList) => {
    let ventas = 0

    rrppList?.map((v) => {
      ventas += v.Sales
      return {
        ventas
      }
    })
    setTotalVentas(ventas)
  }

  const createRow = (label, value, formatter) => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {formatter ? formatter(value) : value}
        </TableCell>
      </TableRow>
    )
  }
  const selectRrpp = [
    { label: 'RRPP (Venta Online Productora)', value: 0 },
    { label: 'RRPP TP (Venta Online TicketPass)', value: 1 },
    { label: 'RRPP $ (Venta en Efectivo)', value: 2 }
  ]

  const loadItemsPage = (x) => {
    switch (rrpp) {
      case 1:
        dispatch(loadResellersTPSalesPaged(match.params.eventId, x))
        break
      case 2:
        dispatch(loadResellerCashSalesPaged(match.params.eventId, x))
        break
      default:
        dispatch(loadEventResellerPaged(match.params.eventId, x))
    }
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer
        title={
          <div className={classes.containerTitleSales}>
            <Typography variant='h5'>Información de ventas</Typography>
            <Select
              labelId='RRPP-label'
              name='rrpp'
              id='RRPP-select'
              value={resellerSelected}
              className={classes.field}
              required
              translate='no'
              onChange={(e) => changeResellerType(e.target.value)}
              size='small'
              label='Lista'>
              {selectRrpp &&
                selectRrpp.map((status) => (
                  <MenuItem
                    key={status.value}
                    value={status.value}
                    translate='no'>
                    {status.label}
                  </MenuItem>
                ))}
            </Select>
          </div>
        }>
        <hr />
        {pending || pendingStatistics ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <Table>
              <TableBody>{createRow('Total Ventas', totalVentas)}</TableBody>
            </Table>
            <hr />
            <ResellerSearchComponent
              performSearch={performSearch}
              searchValue={search}
              setSearch={setSearch}
              pending={resellersPending}
              rrpp={rrpp}
            />
            <hr />
            <TableGrid
              title='Lista de RRPP'
              rowClasses={(r) => !r?.Active && `${classes.conditionalRow}`}
              columns={makeColumns(match.params.eventId, rrpp, classes)}
              footerActions={makeFooterActions()}
              searchParams={search}
              loadItemsPage={(x) =>
                loadItemsPage({
                  searchParams: search,
                  pageNumber: x.pageNumber,
                  pageSize: x.pageSize,
                  orderBy: x.orderBy,
                  order: x.order
                })
              }
              isLoading={pending}
              pagedList={data}
              keyName='Id'
              order={reqParams?.order || TableGridOrder.DESC}
              orderBy={reqParams?.orderBy || 'Sales'}
              useNewFormat
            />
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default ResellerEventPage
