import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  idTicket: {
    marginLeft: theme.spacing(2)
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

export default useStyles
