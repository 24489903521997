import React from 'react'
import { useStyles } from './UsersList.style'
import { Typography } from '@material-ui/core'
import { showSnackbarError } from 'views/utils/snackbar'

export const UsersList = ({ onSuccess, user }) => {
  const classes = useStyles()

  const handleConfirmation = () => {
    try {
      onSuccess(user)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <div className={classes.container}>
      <div onClick={handleConfirmation} className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {user?.FullName}
          </Typography>
        </div>
        <div className={classes.nameStyle}>
          <Typography variant='h5' color='primary' component='span'>
            {user?.Email}
          </Typography>
        </div>
        <hr className={classes.separator} />
      </div>
    </div>
  )
}

export default UsersList
