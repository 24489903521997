export const PAGEDLIST_ATTEMPT = 'admin/organizers/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/organizers/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/organizers/PAGEDLIST_FAILURE'

export const LOAD_ATTEMPT = 'admin/organizers/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/organizers/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/organizers/LOAD_FAILURE'

export const CREATE_ATTEMPT = 'admin/organizers/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'admin/organizers/CREATE_SUCCESS'
export const CREATE_FAILURE = 'admin/organizers/CREATE_FAILURE'

export const UPDATE_ATTEMPT = 'admin/organizers/UPDATE_ATTEMPT'
export const UPDATE_SUCCESS = 'admin/organizers/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'admin/organizers/UPDATE_FAILURE'

export const DELETE_ATTEMPT = 'admin/organizers/DELETE_ATTEMPT'
export const DELETE_SUCCESS = 'admin/organizers/DELETE_SUCCESS'
export const DELETE_FAILURE = 'admin/organizers/DELETE_FAILURE'

export const LOAD_ALL_RESELLERS_ATTEMPT =
  'admin/organizers/LOAD_ALL_RESELLERS_ATTEMPT'
export const LOAD_ALL_RESELLERS_SUCCESS =
  'admin/organizers/LOAD_ALL_RESELLERS_SUCCESS'
export const LOAD_ALL_RESELLERS_FAILURE =
  'admin/organizers/LOAD_ALL_RESELLERS_FAILURE'

export const LOAD_CONTROLLERS_ATTEMPT =
  'admin/organizers/LOAD_CONTROLLERS_ATTEMPT'
export const LOAD_CONTROLLERS_SUCCESS =
  'admin/organizers/LOAD_CONTROLLERS_SUCCESS'
export const LOAD_CONTROLLERS_FAILURE =
  'admin/organizers/LOAD_CONTROLLERS_FAILURE'

export const CREATE_CONTROLLER_ATTEMPT =
  'admin/organizers/CREATE_CONTROLLER_ATTEMPT'
export const CREATE_CONTROLLER_SUCCESS =
  'admin/organizers/CREATE_CONTROLLER_SUCCESS'
export const CREATE_CONTROLLER_FAILURE =
  'admin/organizers/CREATE_CONTROLLER_FAILURE'

export const LOAD_RESELLERS_ATTEMPT = 'admin/organizers/LOAD_RESELLERS_ATTEMPT'
export const LOAD_RESELLERS_SUCCESS = 'admin/organizers/LOAD_RESELLERS_SUCCESS'
export const LOAD_RESELLERS_FAILURE = 'admin/organizers/LOAD_RESELLERS_FAILURE'

export const CREATE_RESELLER_ATTEMPT =
  'admin/organizers/CREATE_RESELLER_ATTEMPT'
export const CREATE_RESELLER_SUCCESS =
  'admin/organizers/CREATE_RESELLER_SUCCESS'
export const CREATE_RESELLER_FAILURE =
  'admin/organizers/CREATE_RESELLER_FAILURE'

export const LOAD_PRODUCERS_ATTEMPT = 'admin/organizers/LOAD_PRODUCERS_ATTEMPT'
export const LOAD_PRODUCERS_SUCCESS = 'admin/organizers/LOAD_PRODUCERS_SUCCESS'
export const LOAD_PRODUCERS_FAILURE = 'admin/organizers/LOAD_PRODUCERS_FAILURE'

export const CREATE_PRODUCER_ATTEMPT =
  'admin/organizers/CREATE_PRODUCER_ATTEMPT'
export const CREATE_PRODUCER_SUCCESS =
  'admin/organizers/CREATE_PRODUCER_SUCCESS'
export const CREATE_PRODUCER_FAILURE =
  'admin/organizers/CREATE_PRODUCER_FAILURE'

export const LOAD_EVENTS_ATTEMPT = 'admin/organizers/LOAD_EVENTS_ATTEMPT'
export const LOAD_EVENTS_SUCCESS = 'admin/organizers/LOAD_EVENTS_SUCCESS'
export const LOAD_EVENTS_FAILURE = 'admin/organizers/LOAD_EVENTS_FAILURE'

export const LOAD_URL_ATTEMPT = 'admin/organizers/LOAD_URL_ATTEMPT'
export const LOAD_URL_SUCCESS = 'admin/organizers/LOAD_URL_SUCCESS'
export const LOAD_URL_FAILURE = 'admin/organizers/LOAD_URL_FAILURE'

export const LOAD_ORGANIZERS_PAGED_ATTEMPT =
  'admin/organizers/LOAD_ORGANIZERS_PAGED_ATTEMPT'
export const LOAD_ORGANIZERS_PAGED_SUCCESS =
  'admin/organizers/LOAD_ORGANIZERS_PAGED_SUCCESS'
export const LOAD_ORGANIZERS_PAGED_FAILURE =
  'admin/organizers/LOAD_ORGANIZERS_PAGED_FAILURE'

export const LOAD_ORGANIZERS_ATTEMPT =
  'admin/organizers/LOAD_ORGANIZERS_ATTEMPT'
export const LOAD_ORGANIZERS_SUCCESS =
  'admin/organizers/LOAD_ORGANIZERS_SUCCESS'
export const LOAD_ORGANIZERS_FAILURE =
  'admin/organizers/LOAD_ORGANIZERS_FAILURE'

export const LOAD_MP_INFO_ATTEMPT = 'admin/organizers/LOAD_MP_INFO_ATTEMPT'
export const LOAD_MP_INFO_SUCCESS = 'admin/organizers/LOAD_MP_INFO_SUCCESS'
export const LOAD_MP_INFO_FAILURE = 'admin/organizers/LOAD_MP_INFO_FAILURE'

export const ENABLE_BILLING_ATTEMPT = 'admin/organizers/ENABLE_BILLING_ATTEMPT'
export const ENABLE_BILLING_SUCCESS = 'admin/organizers/ENABLE_BILLING_SUCCESS'
export const ENABLE_BILLING_FAILURE = 'admin/organizers/ENABLE_BILLING_FAILURE'

export const DISABLE_BILLING_ATTEMPT =
  'admin/organizers/DISABLE_BILLING_ATTEMPT'
export const DISABLE_BILLING_SUCCESS =
  'admin/organizers/DISABLE_BILLING_SUCCESS'
export const DISABLE_BILLING_FAILURE =
  'admin/organizers/DISABLE_BILLING_FAILURE'

export const ADD_BILLING_CREDENTIAL_ATTEMPT =
  'admin/organizers/ADD_BILLING_CREDENTIALATTEMPT'
export const ADD_BILLING_CREDENTIAL_SUCCESS =
  'admin/organizers/ADD_BILLING_CREDENTIAL_SUCCESS'
export const ADD_BILLING_CREDENTIAL_FAILURE =
  'admin/organizers/ADD_BILLING_CREDENTIAL_FAILURE'

export const ADD_BILLING_SETTINGS_ATTEMPT =
  'admin/organizers/ADD_BILLING_SETTINGSATTEMPT'
export const ADD_BILLING_SETTINGS_SUCCESS =
  'admin/organizers/ADD_BILLING_SETTINGS_SUCCESS'
export const ADD_BILLING_SETTINGS_FAILURE =
  'admin/organizers/ADD_BILLING_SETTINGS_FAILURE'

export const RESET = 'admin/organizers/RESET'
export const RESET_EVENTS = 'admin/organizers/RESET_EVENTS'
export const RESET_ACTIONS = 'admin/organizers/RESET_ACTIONS'
export const RESET_PARAMS = 'admin/organizers/RESET_PARAMS'
