import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import history from '~/config/history'
import { Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { ROUTES } from '~/views/routes'
import { Search } from '@material-ui/icons'
import RichTextEditor from 'react-rte'
import { PaperContainer } from '~/views/shared'
import {
  sendNotificationToMaillistCustom,
  reset
} from '~/state/modules/notifications'
import { CustomNotificationsForm } from './CustomNotificationsForm'
import { showSnackbarError } from 'views/utils/snackbar'
import { ConfirmationDialog, PreviewDialog } from '~/views/shared'

export const CustomNotifications = ({
  handleRemoveEmail,
  handleClearEmails,
  emailsList,
  message,
  subject,
  userName,
  editorValue,
  assignEditorValue,
  assignMessage,
  assignSubject
}) => {
  const formikRef = useRef()
  const dispatch = useDispatch()
  const { actionPending } = useSelector((state) => state.notifications)
  const [notification, setNotification] = useState()
  const [open, setOpen] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [openSendingSuccessful, setOpenSendingSuccessful] = useState(false)

  const handleSubmit = (values) => {
    setNotification(values)
    setShowConfirmationDialog(true)
  }

  const handlePreview = () => {
    setNotification(values)
    setOpen(true)
  }

  const handleClose = () => {
    dispatch(reset())
    handleClearEmails()
    assignSubject('')
    assignMessage('')
    assignEditorValue(RichTextEditor.createEmptyValue())
    setOpenSendingSuccessful(false)
  }

  const handleSubmitConfirmation = async () => {
    setShowConfirmationDialog(false)
    const { Subject, Message, Emails } = values
    const parameters = {
      Emails
    }
    const payload = {
      Subject,
      Message,
      Parameters: JSON.stringify(parameters)
    }
    try {
      await dispatch(sendNotificationToMaillistCustom(payload))
      formikRef.current.resetForm()
      setOpenSendingSuccessful(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const values = {
    Subject: subject || '',
    Message: message || '',
    Emails: emailsList || []
  }

  return (
    <>
      <PaperContainer title='Enviar Email Personalizado por usuarios'>
        <Button
          style={{ margin: '10px', textTransform: 'none' }}
          variant='contained'
          color='primary'
          onClick={() => history.push(ROUTES.ADMIN.NOTIFICATIONS.SEARCH)}
          startIcon={<Search />}>
          Buscar Usuario para enviar mail
        </Button>
        <CustomNotificationsForm
          values={values}
          formikRef={formikRef}
          actionText='Enviar'
          pending={actionPending}
          handleSubmit={handleSubmit}
          openPreview={handlePreview}
          handleRemoveEmail={handleRemoveEmail}
          handleClearEmails={handleClearEmails}
          assignMessage={assignMessage}
          assignSubject={assignSubject}
          assignEditorValue={assignEditorValue}
          editorValue={editorValue}
        />
        <PreviewDialog
          open={open}
          onClose={() => setOpen(false)}
          subject={notification?.Subject}
          message={notification?.Message}
          userName={userName}
        />
      </PaperContainer>
      <ConfirmationDialog
        open={showConfirmationDialog}
        loading={actionPending}
        handleConfirmation={handleSubmitConfirmation}
        onCancel={() => setShowConfirmationDialog(false)}
        title='ADVERTENCIA'
        message={'Está por enviar el mensaje a los siguientes correos: '}
        entityIdentifier={
          <ul style={{ marginBlock: 0 }}>
            {emailsList.map((email) => (
              <li key={email}>{email}</li>
            ))}
          </ul>
        }
      />
      <Dialog open={openSendingSuccessful}>
        <DialogTitle>Notificación Enviada!</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomNotifications
