import BaseProxy from './BaseProxy'
import axios from 'axios'
import { ORDER_STATUS } from 'config/constants'
import { handleReportFile } from 'views/utils/functions'

export class DashboardProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/dashboard`
  }

  async getPastEvents(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/events/past${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getUpcomingEvents(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/events/upcoming${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async fetchTotalizatorsData(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/totalizers`
    )

    return response.data
  }

  async fetchTicketTypes(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/tickettype`
    )

    return response.data
  }

  async fetchInvitationsData(eventId) {
    const response = await axios.get(`${this._baseUrl}/invitations/${eventId}`)

    return response.data
  }

  async fetchOperationsByDay(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/operations/by/day`
    )

    return response.data
  }

  async getTicketsReport(eventId) {
    await axios
      .get(`${this._baseUrl}/event/${eventId}/ticketType/report`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Detalle por tipo de Ticket.xlsx`
        )
      )
  }

  async getOperationsByDayReport(eventId) {
    await axios
      .get(`${this._baseUrl}/event/${eventId}/operations/by/day/report`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Operaciones por Día.xlsx`
        )
      )
  }

  async getInvitationsReport(eventId) {
    await axios
      .get(`${this._baseUrl}/invitations/${eventId}/report`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Invitaciones.xlsx`
        )
      )
  }

  async getOperationsPercentages(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/totalOperations`
    )

    return response.data
  }

  async getTotalSalesPercentages(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/totalsale/tickettype`
    )

    return response.data
  }

  async getTicketsCodesReport(eventId) {
    await axios
      .get(`${this._baseUrl}/event/${eventId}/tickets/report`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Códigos de Ticket.xlsx`
        )
      )
  }

  async getCashSales(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/sales/cash`
    )

    return response.data
  }

  async getOnlineSales(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/sales/online`
    )

    return response.data
  }

  async getServiceChargeSales(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/sales/serviceCharge`
    )

    return response.data
  }

  async getRRPPSalesReport(eventId) {
    await axios
      .get(`${this._baseUrl}/event/${eventId}/reseller/report`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Ventas RRPP.xlsx`
        )
      )
  }

  async getRRPPOnlineReport(eventId) {
    await axios
      .get(`${this._baseUrl}/event/${eventId}/reseller/online/report`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Ventas Online RRPP.xlsx`
        )
      )
  }

  async getOrdersReport(eventId, paymentStatus) {
    await axios
      .get(
        `${this._baseUrl}/event/${eventId}/paymentStatus/${paymentStatus}/report`,
        {
          responseType: 'blob'
        }
      )
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Estado '${ORDER_STATUS[paymentStatus]}'.xlsx`
        )
      )
  }
}

export default DashboardProxy
