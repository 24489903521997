import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROLES } from 'config/constants'
import { CircularProgress } from '@material-ui/core'
import { PaperContainer } from '~/views/shared'
import { InvitationForm } from '../components/InvitationForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { loadEvents, resetEvents } from 'state/modules/organizers'
import {
  fetchInvitations,
  sendInvitations,
  resetInvitations
} from 'state/modules/invitations'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import copy from 'copy-to-clipboard'
import moment from 'moment'

export const InvitationIndexPage = ({ match }) => {
  const dispatch = useDispatch()
  const [selectedEvent, setSelectedEvent] = useState(null)
  const { user } = useSelector((state) => state.login)
  const { country } = useSelector((state) => state.app)
  const rolesArray = user?.roles?.split(',') || []
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const link =
    country === 'ar'
      ? 'http://onelink.to/ticketpass_ar'
      : 'http://onelink.to/ticketpass_uy'
  const [valuesInvitations, setValuesInvitations] = useState(false)
  const [name, setName] = useState()
  const [dni, setDni] = useState()
  const [email, setEmail] = useState()
  const { pending, actionPending, actionSuccess } = useSelector(
    (state) => state.common
  )
  const { events, pending: pendingEvents } = useSelector(
    (state) => state.organizers
  )
  const { invitations, pending: pendingInvitations } = useSelector(
    (state) => state.invitations
  )

  const handleChangeEvent = (eventId, { Name, DNI, Email }) => {
    setSelectedEvent(events.find((e) => e.Id === eventId))
    setName(Name)
    setDni(DNI)
    setEmail(Email)
    loadTicketsByEvent(eventId)
  }

  const loadTicketsByEvent = useCallback(
    (eventId) => {
      dispatch(fetchInvitations(eventId))
    },
    [dispatch]
  )

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvents(match.params.id))
    }
    fetchData().then()

    return () => {
      dispatch(resetEvents())
      dispatch(resetInvitations())
    }
  }, [dispatch, match])

  useEffect(() => {
    if (events.length < 1) {
      return
    }

    const minDate = moment.min(events.map((e) => moment(e.StartDateTime)))
    const event = events.find((e) => moment(e.StartDateTime).isSame(minDate))
    loadTicketsByEvent(event.Id)

    setSelectedEvent(event)
  }, [events, loadTicketsByEvent])

  const values = {
    Title: selectedEvent?.Title || '',
    EventId: selectedEvent?.Id || '',
    TicketTypeId: invitations[0]?.Id || '',
    Units: 1,
    Name: name || '',
    Email: email || '',
    DNI: dni || ''
  }

  const handleCloseConfirmation = () => {
    window.location.reload()
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await dispatch(sendInvitations(values))
      setValuesInvitations(true)
      setSubmitting(true)
      showSnackbarSuccess('La Invitación fue enviada')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const copyToClipBoard = (values) => {
    const message = `
    ${values.Name}, te obsequiamos una invitación para ${values.Title}!

    Si tenés cuenta ticketPass, verás tu QR en Mis Compras.

    Si no tenés cuenta ticketPass, tu invitación está asociada a tu ${documentId} (${values.DNI}).
    Descargá la APP, registrate con tu ${documentId} ó ingresá con Facebook ó Apple ID y cargá tu ${documentId} en Mi Perfil. Luego recibirás el QR en Mis Compras!

    ${link}`
    try {
      copy(message)
      showSnackbarSuccess('Instrucciones copiadas al portapapeles! ')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleBack = () => {
    history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)
  }

  const handleBackProducers = () => {
    history.push(ROUTES.ADMIN.PRODUCERS.INDEX)
  }

  return (
    <PaperContainer title='Enviar Invitación'>
      {pending || pendingEvents ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <InvitationForm
          event={selectedEvent}
          invitations={invitations}
          pendingInvitations={pendingInvitations}
          events={events}
          values={values}
          onChangeEvent={handleChangeEvent}
          actionText='Enviar Invitación'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          onCancel={
            rolesArray?.includes(ROLES.PRODUCER) && rolesArray?.length === 1
              ? handleBackProducers
              : handleBack
          }
          valuesInvitations={valuesInvitations}
          copyToClipBoard={copyToClipBoard}
          handleCloseConfirmation={handleCloseConfirmation}
        />
      )}
    </PaperContainer>
  )
}

export default InvitationIndexPage
