import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useStyles } from './RolesIndexPage.style'
import {
  Button,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Delete, Add, Info } from '@material-ui/icons'
import {
  loadUsersByRolePaged,
  resetParamsRoles,
  removeUser
} from '~/state/modules/roles'
import { setPreviousRoute } from 'state/modules/users'
import {
  TableGrid,
  createColumn,
  createCustomColumn,
  TableGridOrder
} from '~/views/shared'
import { DeleteDialog } from '~/views/shared'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = (setUserSelected, setShowDeleteDialog) => {
  return [
    createColumn('UserName', 'Nombre de Usuario'),
    createColumn('Id', 'Id de Usuario'),
    createColumn('LastName', 'Apellido'),
    createColumn('FirstName', 'Nombres'),
    createColumn('Email', 'Email'),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            setUserSelected(c)
            setShowDeleteDialog(true)
          }}>
          <Delete />
        </Button>
      ),
      'Eliminar'
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            history.push(ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', c.Id))
          }}>
          <Info />
        </Button>
      ),
      'Información'
    )
  ]
}
const makeFooterActions = () => {}

export const RolesIndexPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { roleId } = useParams()
  const [prevRoleId, setPrevRoleId] = useState(roleId)
  const { pagedList, pendingPagedList, actionPending, reqParams } = useSelector(
    (state) => state.roles
  )
  const [userSelected, setUserSelected] = useState(null)
  const [requestParams, setRequestParams] = useState({})
  const [search, setSearch] = useState(reqParams?.searchParams || '')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [role, setRole] = useState()

  const handleClose = async () => {
    await dispatch(resetParamsRoles())
    dispatch(loadUsersByRolePaged(roleId, requestParams))
    setSearch('')
  }

  const handleDelete = async () => {
    const userToRemove = {
      userId: userSelected.Id,
      role: role
    }
    try {
      await dispatch(removeUser(userToRemove))
      handleClose()
      setShowDeleteDialog(false)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    dispatch(
      loadUsersByRolePaged(roleId, {
        searchParams: search,
        pageNumber: 0,
        pageSize: 5,
        orderBy: reqParams?.orderBy || 'UserName',
        order: reqParams?.order || TableGridOrder.ASC
      })
    )
  }

  useEffect(() => {
    if (pagedList != null) {
      setRole(pagedList?.Name)
    }
    dispatch(
      setPreviousRoute(ROUTES.ADMIN.ROLES.INDEX.replace(':roleId', roleId))
    )
  }, [pagedList, roleId, dispatch])

  useEffect(() => {
    if (roleId !== prevRoleId) {
      setSearch('')
      setPrevRoleId(roleId)
    }
  }, [roleId, prevRoleId])

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Nombre de Usuario, Apellido o Nombres'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button
          disabled={pendingPagedList}
          variant='contained'
          color='primary'
          onClick={performSearch}>
          {pendingPagedList ? (
            <CircularProgress size={24} color='primary' />
          ) : (
            'Buscar'
          )}
        </Button>
      </div>
    )
  }

  return (
    <>
      <TableGrid
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography>Usuarios con Rol: {role ? role : ''}</Typography>
            <Button
              variant='text'
              color='primary'
              key='Crear'
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.ROLES.SEARCH.replace(
                    ':roleId',
                    match.params.roleId
                  )
                )
              }>
              Agregar Usuario <Add />
            </Button>
          </div>
        }
        rowClasses={(x) => x?.IsEnabled && `${classes.conditionalRow}`}
        columns={makeColumns(setUserSelected, setShowDeleteDialog)}
        footerActions={makeFooterActions()}
        searchForm={makeSearchForm}
        searchParams={search}
        loadItemsPage={(x) => {
          setRequestParams(x)
          dispatch(
            loadUsersByRolePaged(roleId, {
              searchParams: search,
              pageNumber: x.pageNumber || reqParams?.pageNumber,
              pageSize: x.pageSize,
              orderBy: x.orderBy,
              order: x.order
            })
          )
        }}
        isLoading={pendingPagedList}
        pagedList={pagedList?.Users}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.ASC}
        orderBy={reqParams?.orderBy || 'UserName'}
        useNewFormat
      />
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title={'Quitar Usuario'}
        message={'Está por quitar el usuario'}
        entityIdentifier={userSelected?.UserName}
        optionalMessage={'del rol'}
        secondEntity={role}
      />
    </>
  )
}

export default RolesIndexPage
