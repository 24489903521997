import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import EmailIcon from '@material-ui/icons/Email'
import RichTextEditor from 'react-rte'
import clsx from 'clsx'
import {
  Button,
  InputLabel,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useStyles } from './NotificationsCustomToProducersForm.style'

const validationSchema = Yup.object().shape({
  Subject: Yup.string()
    .trim()
    .required('Es requerido ingresar el asunto')
    .max(60, "La cantidad máxima de caracteres es de '60'"),
  Message: Yup.string()
    .transform((value) => value.replace(/(<([^>]+)>|&nbsp;)/gi, ''))
    .trim()
    .required('Es requerido ingresar un mensaje')
    .max(10000, "La cantidad máxima de caracteres es de '10.000'")
})

export const NotificationsCustomToProducersForm = ({
  values,
  editorValue,
  setEditorValue,
  formikRef,
  actionText,
  handleSubmit,
  actionPending,
  actionSuccess,
  openPreview
}) => {
  const classes = useStyles()

  const toolbarConfig = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'TEXT_ALIGNMENT_BUTTON',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
      'IMAGE_BUTTON'
    ],
    INLINE_STYLE_BUTTONS: [
      { label: 'Negrita', style: 'BOLD' },
      { label: 'Cursiva', style: 'ITALIC' },
      { label: 'Subrayado', style: 'UNDERLINE' },
      { label: 'Tachado', style: 'STRIKETHROUGH' },
      { label: 'Código', style: 'CODE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Título 1', style: 'header-one' },
      { label: 'Título 2', style: 'header-two' },
      { label: 'Título 3', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'Viñetas', style: 'unordered-list-item' },
      { label: 'Numeración', style: 'ordered-list-item' }
    ]
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        handleBlur
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Subject && touched.Subject && errors.Subject) ||
                  'Asunto'
                }
                name='Subject'
                value={values.Subject}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Subject && Boolean(errors.Subject)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <InputLabel
                shrink={true}
                required
                className={clsx({
                  [classes.inputLabel]: true,
                  [classes.inputLabelError]:
                    errors.Message && touched.Message && errors.Message
                })}>
                {(errors.Message && touched.Message && errors.Message) ||
                  'Mensaje'}
              </InputLabel>
              <RichTextEditor
                value={editorValue}
                toolbarConfig={toolbarConfig}
                className={clsx({
                  [classes.fieldEditorText]: true,
                  [classes.fieldEditorTextError]:
                    errors.Message && touched.Message && errors.Message
                })}
                placeholder='Escriba aquí su mensaje...'
                onChange={(value) => {
                  setEditorValue(value)
                  handleChange({
                    target: {
                      name: 'Message',
                      value: value.toString('html')
                    }
                  })
                }}
                onBlur={handleBlur}
              />
            </div>
            <FormControlLabel
              className={classes.containerCheckbox}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  checked={values.showHtmlMessage}
                  onChange={() => {
                    setFieldValue('showHtmlMessage', !values.showHtmlMessage)
                  }}
                  name='showHTML'
                />
              }
              label='Mostrar HTML'
            />
            {values.showHtmlMessage && (
              <div className={classes.fieldRow}>
                <TextField
                  label={
                    (errors.Message && touched.Message && errors.Message) ||
                    'Mensaje'
                  }
                  name='Message'
                  value={values.Message}
                  onChange={(e) => {
                    handleChange(e)
                    const editorValue = RichTextEditor.createValueFromString(
                      e.target.value,
                      'html'
                    )
                    setEditorValue(editorValue)
                  }}
                  onBlur={handleBlur}
                  error={touched.Message && Boolean(errors.Message)}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={10}
                  required
                  size='small'
                  variant='outlined'
                  className={classes.field}
                />
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={() => openPreview(values)}
              disabled={
                values.Message.replace(/(<([^>]+)>|&nbsp;)/gi, '').trim() ===
                  '' ||
                values.Subject.trim() === '' ||
                values.PaymentStatus === ''
              }
              color='secondary'
              variant='contained'>
              Vista Previa
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={actionPending || actionSuccess}
              color='primary'
              variant='contained'
              startIcon={<EmailIcon />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default NotificationsCustomToProducersForm
