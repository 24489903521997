import * as types from './types'
import { LocationsProxy } from '~/services'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (location) => ({
  type: types.CREATE_SUCCESS,
  location
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateSuccess = (location) => ({
  type: types.UPDATE_SUCCESS,
  location
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const loadLocationAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadLocationSuccess = (location) => ({
  type: types.LOAD_SUCCESS,
  location
})

export const loadLocationFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteSuccess = (location) => ({
  type: types.DELETE_SUCCESS,
  location
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const resetActions = () => ({
  type: types.RESET_ACTIONS
})

export const resetParamsLocations = () => ({
  type: types.RESET_PARAMS
})

export const loadPage =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageNumber, pageSize })
      )

      const proxy = new LocationsProxy()
      const pagedList = await proxy.get(
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(pagedListSuccess(pagedList))
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }

export const loadLocation = (id) => async (dispatch) => {
  try {
    dispatch(loadLocationAttempt())

    const proxy = new LocationsProxy()
    const response = await proxy.getById(id)

    dispatch(loadLocationSuccess(response))
  } catch (error) {
    dispatch(loadLocationFailure(error))
    throw error
  }
}

export const create = (location) => async (dispatch) => {
  try {
    dispatch(createAttempt())

    const proxy = new LocationsProxy()
    const response = await proxy.post(location)

    dispatch(createSuccess(response))
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const update = (id, location) => async (dispatch) => {
  try {
    dispatch(updateAttempt())

    const proxy = new LocationsProxy()
    const response = await proxy.put(id, location)

    dispatch(updateSuccess(response))
  } catch (error) {
    dispatch(updateFailure(error))
    throw error
  }
}

export const deleteLocation = (id) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new LocationsProxy()
    const response = await proxy.delete(id)

    dispatch(deleteSuccess(response))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}
