import * as types from './types'
import { restoreAuthorization, setupAuthorization } from '~/config/axios'
import history from '~/config/history'
import { AuthProxy, UsersProxy, AuthenticationProxy } from '~/services'
import { ROUTES } from '~/views/routes'
import { showSnackbarError } from '../app'

const JWT_KEY = process.env.REACT_APP_JWT_KEY

// Action Creators
export const loginAttempt = () => ({
  type: types.LOGIN_ATTEMPT
})

export const loginSuccess = ({ userId, userName, roles }, picture) => ({
  type: types.LOGIN_SUCCESS,
  user: {
    id: userId,
    name: userName,
    roles: roles,
    loggedAt: new Date(),
    picture: picture
  }
})

export const loginNewUserSuccess = (newExternalUser) => ({
  type: types.LOGIN_NEW_USER_SUCCESS,
  newExternalUser
})

export const loginFailure = (error) => ({
  type: types.LOGIN_FAILURE,
  error
})

export const createUserFromExternalLoginAttempt = () => ({
  type: types.CREATE_USER_FROM_EXTERNAL_LOGIN_ATTEMPT
})

export const createUserFromExternalLoginSuccess = (newUser) => ({
  type: types.CREATE_USER_FROM_EXTERNAL_LOGIN_SUCCESS,
  newUser
})

export const createUserFromExternalLoginFailure = (error) => ({
  type: types.CREATE_USER_FROM_EXTERNAL_LOGIN_FAILURE,
  error
})

export const restoreSession = ({ userId, userName, roles }) => ({
  type: types.RESTORE_SESSION,
  user: {
    id: userId,
    name: userName,
    roles: roles,
    loggedAt: new Date()
  }
})

export const closeSession = () => ({
  type: types.CLOSE_SESSION
})

// Thunks
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch(loginAttempt())

    const proxy = new AuthProxy()
    const jwt = await proxy.login(username, password)

    localStorage.setItem(JWT_KEY, JSON.stringify(jwt))
    setupAuthorization(jwt.access_token, () => dispatch(logout()))

    dispatch(loginSuccess(jwt))
  } catch (error) {
    dispatch(showSnackbarError(error))
    dispatch(loginFailure(error))
    throw error
  }
}

export const loginFacebook = (accessToken, picture) => async (dispatch) => {
  try {
    dispatch(loginAttempt())

    const proxy = new UsersProxy()
    const jwt = await proxy.loginFacebook(accessToken)
    if (jwt.status !== 203) {
      localStorage.setItem(JWT_KEY, JSON.stringify(jwt))
      setupAuthorization(jwt.access_token, () => dispatch(logout()))

      dispatch(loginSuccess(jwt, picture))
    } else {
      dispatch(loginNewUserSuccess(jwt))
    }
    return jwt
  } catch (error) {
    if (
      error?.response?.data?.Message ===
      'The ExternalAccessToken field is required.'
    ) {
      showSnackbarError('Usuario y/o Contraseña inválidos.')
    } else {
      showSnackbarError(error)
    }
    dispatch(loginFailure(error))
    throw error
  }
}

export const externalLogin = (jwt) => async (dispatch) => {
  try {
    dispatch(loginAttempt())

    localStorage.setItem(JWT_KEY, JSON.stringify(jwt))
    setupAuthorization(jwt.access_token, () => dispatch(logout()))
    dispatch(loginSuccess(jwt))
  } catch (error) {
    dispatch(loginFailure(error))
    throw error
  }
}

export const createUserFromExternalLogin = (payload) => async (dispatch) => {
  try {
    dispatch(createUserFromExternalLoginAttempt())

    const proxy = new AuthenticationProxy()
    const response = await proxy.createFromExternalLogin(payload)

    dispatch(createUserFromExternalLoginSuccess(response))
    return response
  } catch (error) {
    dispatch(createUserFromExternalLoginFailure(error))
    throw error
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem(JWT_KEY)

  restoreAuthorization()

  dispatch(closeSession())
  history.push(ROUTES.PUBLIC.LOGIN)
}

export const verifyToken = (returnUrl) => async (dispatch) => {
  try {
    const jwt = JSON.parse(localStorage.getItem(JWT_KEY))

    setupAuthorization(jwt.access_token, () => dispatch(logout()))
    dispatch(restoreSession(jwt))
    history.push(returnUrl)
  } catch (error) {
    dispatch(logout())
  }
}
