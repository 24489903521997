import * as types from './types'

const initialState = {
  pagedList: null,
  location: null,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        pending: true,
        location: null,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        location: action.location,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        pending: false,
        location: null,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        location: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        location: action.location,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        location: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        // location: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        location: action.location,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        location: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        // location: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        // location: action.location,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        // location: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    case types.RESET_ACTIONS:
      return {
        ...state,
        pending: false,
        actionPending: false,
        actionSuccess: false
      }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    default:
      return state
  }
}
