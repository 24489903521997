import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Dialog
} from '@material-ui/core'
import { Save, ArrowDropDown } from '@material-ui/icons'
import { FilterComponent } from '~/views/shared'
import { useStyles } from './AccountsForm.style'

const validationSchema = Yup.object().shape({
  Cuit: Yup.string()
    .trim()
    .required('Es requerido ingresar el Cuit')
    .max(20, 'La cantidad máxima es de 20 caracteres'),
  RazonSocial: Yup.string()
    .trim()
    .required('Es requerido ingresar la razón social')
    .max(300, 'La cantidad máxima es de 300 caracteres'),
  CommerceId: Yup.string()
    .trim()
    .required('Es requerido ingresar comercio Id')
    .max(250, 'La cantidad máxima es de 250 caracteres'),
  SecretKey: Yup.string()
    .trim()
    .required('Es requerido ingresar la clave secreta')
    .max(250, 'La cantidad máxima es de 250 caracteres'),
  Phrase: Yup.string()
    .trim()
    .required('Es requerido ingresar la frase')
    .max(250, 'La cantidad máxima es de 250 caracteres'),
  Organizers: Yup.array()
    .min(1, 'Es requerido seleccionar al menos una productora')
    .required('Es requerido seleccionar al menos una productora')
})

export const AccountsForm = ({
  values,
  organizers,
  actionText,
  handleSubmit,
  onCancel,
  pendingOrganizers,
  pendingCreate
}) => {
  const classes = useStyles()
  const [openOrganizer, setOpenOrganizer] = useState(false)
  const [organizersSelected, setOrganizersSelected] = useState(
    values.OrganizersSelected
  )
  const [organizerIds, setOrganizerIds] = useState(values.Organizers)
  const [searchValueOrganizer, setSearchValueOrganizer] = useState('')
  const [organizersFilter, setOrganizersFilter] = useState(organizers)

  const handleClickOpenOrganizers = () => {
    setSearchValueOrganizer('')
    setOrganizersFilter(organizers)
    setOpenOrganizer(true)
  }

  const performSearchOrganizers = (searchKey) => {
    setSearchValueOrganizer(searchKey)
    setOrganizersFilter(
      organizers?.filter((e) =>
        e?.Name?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  const handleClickSelect = (e) => {
    const organizerId = e.Id
    //handleChangeSuccess()

    const isSelected = organizerIds.includes(organizerId)

    const updatedOrganizerIds = isSelected
      ? organizerIds.filter((id) => id !== organizerId)
      : [...organizerIds, organizerId]

    setOrganizerIds(updatedOrganizerIds)

    const updatedOrganizerTitles = organizers
      .filter((organizer) => updatedOrganizerIds.includes(organizer.Id))
      .map((organizer) => organizer.Name)

    setOrganizersSelected(
      updatedOrganizerTitles.join('\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0')
    )
  }

  useEffect(() => {
    return () => {
      setOrganizersSelected(null)
      setOrganizerIds([])
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={(errors.Cuit && touched.Cuit && errors.Cuit) || 'Cuit'}
                name='Cuit'
                value={values.Cuit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Cuit && Boolean(errors.Cuit)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.RazonSocial &&
                    touched.RazonSocial &&
                    errors.RazonSocial) ||
                  'Razón Social'
                }
                name='RazonSocial'
                value={values.RazonSocial}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.RazonSocial && Boolean(errors.RazonSocial)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.CommerceId &&
                    touched.CommerceId &&
                    errors.CommerceId) ||
                  'Comercio Id'
                }
                name='CommerceId'
                value={values.CommerceId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.CommerceId && Boolean(errors.CommerceId)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.SecretKey && touched.SecretKey && errors.SecretKey) ||
                  'Clave Secreta'
                }
                name='SecretKey'
                value={values.SecretKey}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.SecretKey && Boolean(errors.SecretKey)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Phrase && touched.Phrase && errors.Phrase) || 'Frase'
                }
                name='Phrase'
                value={values.Phrase}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Phrase && Boolean(errors.Phrase)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
              <InputLabel shrink={true} required>
                {(errors.Organizers &&
                  touched.Organizers &&
                  errors.Organizers) ||
                  'Productoras'}
              </InputLabel>
              <Button
                disableFocusRipple
                onClick={handleClickOpenOrganizers}
                variant='outlined'
                disabled={pendingOrganizers}
                className={classes.textButton}>
                <div className={classes.containerSelectButton}>
                  <span
                    style={
                      !organizersSelected ||
                      (Array.isArray(organizersSelected) &&
                        organizersSelected.length === 0)
                        ? { color: 'gray' }
                        : { color: 'black' }
                    }>
                    {organizersSelected && organizersSelected.length > 0
                      ? organizersSelected
                      : pendingOrganizers
                        ? 'Cargando...'
                        : 'Seleccione una o más productoras'}
                  </span>
                  {pendingOrganizers ? (
                    <CircularProgress size={24} color='secondary' />
                  ) : (
                    <ArrowDropDown />
                  )}
                </div>
              </Button>
              <Dialog
                open={openOrganizer}
                onClose={() => setOpenOrganizer(false)}>
                <div className={classes.containerDialog}>
                  <div>
                    <FilterComponent
                      performSearch={performSearchOrganizers}
                      searchValue={searchValueOrganizer}
                      placeholder='Ingrese Nombre de la productora...'
                    />
                  </div>
                  <Select
                    multiple
                    native
                    labelId='organizer-label'
                    name='Organizers'
                    id='Organizers-select'
                    value={organizerIds}
                    onChange={handleChange}
                    className={classes.fieldSelect}>
                    {organizersFilter &&
                      organizersFilter.map((o) => (
                        <option
                          key={o.Id}
                          className={classes.items}
                          value={o.Id}
                          onClick={() => handleClickSelect(o)}>
                          {o.Name}
                        </option>
                      ))}
                  </Select>
                  <Button
                    onClick={() => setOpenOrganizer(false)}
                    color='primary'
                    className={classes.dialogButton}
                    variant='contained'>
                    OK
                  </Button>
                </div>
              </Dialog>
            </FormControl>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={onCancel}
              disabled={isSubmitting}
              autoFocus={false}
              color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                values.Organizers = organizerIds.map((id) => ({
                  OrganizerId: id
                }))
                handleSubmit()
              }}
              disabled={pendingOrganizers || pendingCreate || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<Save />}
              autoFocus={false}>
              {pendingOrganizers || pendingCreate || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default AccountsForm
