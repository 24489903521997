import React from 'react'
import { useDispatch } from 'react-redux'
import { UsersSearch } from '../../components/UsersSearch'
import { createReseller, loadResellers } from '~/state/modules/organizers'
import { showSnackbarError } from 'views/utils/snackbar'

export const ResellerCreatePage = ({
  match,
  onSuccess: onSuccessOrganizerPage,
  onCancel
}) => {
  const dispatch = useDispatch()

  const onSuccess = async (userId) => {
    try {
      await dispatch(createReseller(match.params.id, userId))
      dispatch(loadResellers(match.params.id))
      onSuccessOrganizerPage(match.params.id)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <UsersSearch
      match={match}
      onSuccess={onSuccess}
      onCancel={onCancel}
      message={'Está seguro que desea agregar el RRPP'}
    />
  )
}

export default ResellerCreatePage
