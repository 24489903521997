import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import { verifyToken } from '~/state/modules/login'

class PrivateRoute extends React.Component {
  componentDidMount() {
    const { isAuthenticated, verifyToken, location } = this.props
    if (!isAuthenticated) {
      verifyToken(location.pathname)
    }
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      isAuthorized,
      ...rest
    } = this.props
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated && isAuthorized ? (
            <Component {...props} />
          ) : isAuthenticated ? (
            <Redirect
              to={{
                pathname: ROUTES.PUBLIC.UNAUTHORIZED,
                state: { from: props.location }
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.PUBLIC.LANDING,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }
}

const hasAnyAuthority = (user, roles) => {
  if (user) {
    if (!roles || roles.length === 0) {
      return true
    }

    const userRoles = user?.roles?.split(',')

    return roles.some((r) => userRoles.indexOf(r) !== -1)
  }

  return false
}

const mapStateToProps = (state, { roles }) => {
  return {
    isAuthenticated: Boolean(state.login.user),
    isAuthorized: hasAnyAuthority(state.login.user, roles)
  }
}

const mapDispatchToProps = {
  verifyToken
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
