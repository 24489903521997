import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  userData: {
    marginLeft: 15
  },
  ticket: {
    marginBottom: 10,
    paddingBlock: theme.spacing(1),
    backgroundColor: 'aliceblue'
  },
  picture: {
    width: 100
  },
  detailsTickets: {
    fontWeight: 'bold',
    marginLeft: 10
  },
  totals: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  totalTickets: {
    marginLeft: 20
  },
  ticketName: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center'
  },
  line: {
    border: 0,
    borderTop: '1px solid lightgray',
    marginBottom: '1em 0'
  },
  labelKeys: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  loadingCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  }
}))

export default useStyles
