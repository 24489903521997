import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button
} from '@material-ui/core'
import { externalLogin, createUserFromExternalLogin } from 'state/modules/login'
import { Close } from '@material-ui/icons'
import CreateAccountForm from './CreateAccountForm'
import ReactCountryFlag from 'react-country-flag'
import { Alert } from '@material-ui/lab'
import { useStyles } from './AccountCreationDialog.style'

const COUNTRY = process.env.NEXT_PUBLIC_COUNTRY_CODE
export const AccountCreationDialog = ({
  open,
  onRegistrationSuccess,
  onCancel,
  newExternalUser,
  pending
}) => {
  const [error, setError] = useState('')
  const [info, setInfo] = useState('')
  const [associatedEmail, setAssociatedEmail] = useState('')
  const [showButtons, setShowButtons] = useState(false)
  const [hideForm, setHideForm] = useState(false)
  const [returnToLoginButton, setReturnToLoginButton] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const registrationFormRef = useRef()
  const values = {
    FirstName: newExternalUser?.FirstName || '',
    LastName: newExternalUser?.LastName || '',
    DNI: '',
    Email: newExternalUser?.Email.endsWith('@ticketpass.com.ar')
      ? ''
      : newExternalUser?.Email || '',
    EmailConfirm: newExternalUser?.Email.endsWith('@ticketpass.com.ar')
      ? ''
      : newExternalUser?.Email || '',
    IdentificationType: '',
    TermsAccepted: false
  }
  const [newValues, setNewValues] = useState({ ...values })

  const handleRegistration = async (values, { setSubmitting }) => {
    let payload = { ...values }
    delete payload.TermsAccepted
    delete payload.EmailConfirm
    if (payload.IdentificationType !== 'Otro') {
      payload.DNI = values.DNI.replace(/[.-]/g, '')
    }
    payload.ExternalLoginId = newExternalUser.Id
    payload.Provider = 'Facebook'
    setNewValues(payload)
    try {
      setError('')
      setInfo('')
      setSubmitting(true)
      const response = await dispatch(createUserFromExternalLogin(payload))
      if (response.status !== 202) {
        await dispatch(externalLogin(response))
        onRegistrationSuccess()
      } else {
        setInfo(response.message)
        setReturnToLoginButton(true)
      }
    } catch (e) {
      setInfo('')
      console.error(e)
      const messageError = e?.response?.data?.Message
      if (e?.response?.status === 409) {
        setInfo(messageError)
        setShowButtons(true)
        setAssociatedEmail(
          messageError.split('<strong>')[2].split('</strong>')[0]
        )
      } else {
        setError(messageError)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const accountCreation = async () => {
    try {
      setError('')
      setInfo('')
      newValues.Email = associatedEmail
      const response = await dispatch(createUserFromExternalLogin(newValues))
      if (response.status !== 202) {
        await dispatch(externalLogin(response))
        onRegistrationSuccess()
      } else {
        setInfo(response.message)
        setReturnToLoginButton(true)
      }
    } catch (e) {
      setInfo('')
      console.error(e)
      setError(e?.response?.data?.Message)
    }
  }

  const handleAccountCreation = () => {
    accountCreation()
    setShowButtons(false)
  }

  const handleCancelAccountCreation = () => {
    setShowButtons(false)
    setHideForm(true)
    setInfo(
      'Por favor, comuníquese con nuestro equipo de soporte técnico para obtener asistencia en la recuperación de su cuenta de ticketPass.'
    )
  }

  const handleCancel = () => {
    onCancel()
    setError('')
    setInfo('')
    setReturnToLoginButton(false)
    setHideForm(false)
    setShowButtons(false)
    setAssociatedEmail('')
    setNewValues({ ...values })
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <section className={classes.sectionContainer}>
          <div className={classes.titleContainer}>
            <Typography style={{ marginTop: 10 }} variant='h4'>
              Creación de cuenta
              <ReactCountryFlag
                countryCode={COUNTRY}
                svg
                className={classes.flag}
              />
            </Typography>
            <IconButton
              aria-label='close'
              onClick={handleCancel}
              className={classes.button}>
              <Close />
            </IconButton>
          </div>
        </section>
      </DialogTitle>
      <DialogContent>
        <section className={classes.sectionContainer}>
          {!!error ? (
            <div className={classes.errorContainer}>
              <Typography color={'red'}>{error}</Typography>
            </div>
          ) : null}
          {!!info ? (
            <div className={classes.infoContainer}>
              <div
                dangerouslySetInnerHTML={{
                  __html: info
                }}></div>
              {showButtons && (
                <div className={classes.buttonsContainerInfo}>
                  <Button
                    size='small'
                    color='info'
                    variant={'contained'}
                    disabled={pending}
                    onClick={handleAccountCreation}>
                    Si
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    size='small'
                    color='inherit'
                    variant={'contained'}
                    className={classes.buttonInfo}
                    disabled={pending}
                    onClick={handleCancelAccountCreation}>
                    No
                  </Button>
                </div>
              )}
            </div>
          ) : null}
          {hideForm ? (
            <Alert
              severity='warning'
              variant='outlined'
              className={classes.warning}>
              {info}
            </Alert>
          ) : (
            <CreateAccountForm
              formRef={registrationFormRef}
              onSubmit={handleRegistration}
              values={values}
              pending={pending}
              returnToLoginButton={returnToLoginButton}
              returnToLogin={handleCancel}
            />
          )}
        </section>
      </DialogContent>
    </Dialog>
  )
}

export default AccountCreationDialog
