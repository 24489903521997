export const getPaymentGatewayCommission = (commissions, commission) => {
  if (commission == null || commission === 0) {
    return commissions ? commissions.mpCommissions[0].Fee : ''
  } else {
    return commission
  }
}

export const getPaymentGatewayAccreditationDays = (commissions, commission) => {
  if (commission == null || commission === 0) {
    return commissions ? commissions.mpCommissions[0].AccreditationDays : ''
  } else {
    return commission
  }
}

export const disableFormDate = (ticket) => {
  return ticket?.SaleStartDate == null
}
