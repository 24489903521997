import * as types from './types'
import { LocationsProxy, OrganizersProxy, UsersProxy } from '~/services'
import { EventTypesProxy } from '../../../services'

export const fetchOrganizersAttempt = () => ({
  type: types.FETCH_ORGANIZERS_ATTEMPT
})

export const fetchOrganizersSuccess = (organizers) => ({
  type: types.FETCH_ORGANIZERS_SUCCESS,
  organizers
})

export const fetchOrganizersFailure = (error) => ({
  type: types.FETCH_ORGANIZERS_FAILURE,
  error
})

export const fetchLocationsAttempt = () => ({
  type: types.FETCH_LOCATIONS_ATTEMPT
})

export const fetchLocationsSuccess = (locations) => ({
  type: types.FETCH_LOCATIONS_SUCCESS,
  locations
})

export const fetchLocationsFailure = (error) => ({
  type: types.FETCH_LOCATIONS_FAILURE,
  error
})

export const fetchEventTypesAttempt = () => ({
  type: types.FETCH_EVENTTYPES_ATTEMPT
})

export const fetchEventTypesSuccess = (eventTypes) => ({
  type: types.FETCH_EVENTTYPES_SUCCESS,
  eventTypes
})

export const fetchEventTypesFailure = (error) => ({
  type: types.FETCH_EVENTTYPES_FAILURE,
  error
})

export const fetchUsersAttempt = () => ({
  type: types.FETCH_USERS_ATTEMPT
})

export const fetchUsersSuccess = (users) => ({
  type: types.FETCH_USERS_SUCCESS,
  users
})

export const fetchUsersFailure = (error) => ({
  type: types.FETCH_USERS_FAILURE,
  error
})

export const fetchOrganizers = () => async (dispatch) => {
  try {
    dispatch(fetchOrganizersAttempt())

    const proxy = new OrganizersProxy()
    const organizers = await proxy.getAll()

    dispatch(fetchOrganizersSuccess(organizers))
  } catch (error) {
    dispatch(fetchOrganizersFailure(error))
    throw error
  }
}

export const fetchLocations = () => async (dispatch) => {
  try {
    dispatch(fetchLocationsAttempt())

    const proxy = new LocationsProxy()
    const locations = await proxy.getAll()

    dispatch(fetchLocationsSuccess(locations))
  } catch (error) {
    dispatch(fetchLocationsFailure(error))
    throw error
  }
}

export const fetchEventTypes = () => async (dispatch) => {
  try {
    dispatch(fetchEventTypesAttempt())

    const proxy = new EventTypesProxy()
    const eventTypes = await proxy.getAll()

    dispatch(fetchEventTypesSuccess(eventTypes))
  } catch (error) {
    dispatch(fetchEventTypesFailure(error))
    throw error
  }
}

export const fetchUsers = (searchKey) => async (dispatch) => {
  try {
    dispatch(fetchUsersAttempt())

    const proxy = new UsersProxy()
    const users = await proxy.getUser(searchKey?.trim())

    dispatch(fetchUsersSuccess(users))
  } catch (error) {
    dispatch(fetchUsersFailure(error))
    throw error
  }
}
