import React from 'react'
import {
  Typography,
  Dialog,
  DialogActions,
  Button,
  IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Logo from 'assets/img/logo_color.png'
import Stars from 'assets/img/stars.png'
import Footer from 'assets/img/footer.png'
import { useStyles } from './PreviewDialog.style'

export const PreviewDialog = ({
  open,
  onClose,
  subject,
  message,
  userName
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} className={classes.dialog} onClose={() => onClose()}>
      <div className={classes.title}>
        <Typography variant='h3'>Vista Previa</Typography>
        <IconButton
          aria-label='close'
          onClick={() => onClose()}
          className={classes.button}>
          <Close />
        </IconButton>
      </div>
      <div className={classes.containerPreview}>
        <div className={classes.subject}>
          <Typography variant='h5' className={classes.label}>
            Asunto:{' '}
            <Typography
              variant='h5'
              component={'span'}
              className={classes.value}>
              {subject}
            </Typography>
          </Typography>
        </div>
        <hr className={classes.separator} />
        <div className={classes.greeting}>
          <img width='90' alt={'Logo'} src={Logo} />
          <Typography variant='h3'>Hola, {userName}</Typography>
          <img alt={'Stars'} src={Stars} />
          <div
            dangerouslySetInnerHTML={{
              __html: message
            }}
            className={classes.message}
          />
          <img alt={'Stars'} src={Footer} className={classes.img} />
        </div>
      </div>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => onClose()} variant='contained' color='primary'>
          Cerrar Vista Previa
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewDialog
