import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { AppContainer } from 'views/shared'
import { UsersInfoPage } from './UsersInfoPage'
import { ROUTES } from 'views/routes'
import { UsersIndexPage } from './UsersIndexPage/UsersIndexPage'
import { UserOrdersIndexPage } from './Orders/UserOrdersIndexPage/UserOrdersIndexPage'
import { OrderRecordPage, OrderPaymentsPage } from '../EventsPage/Orders'
import { TicketsPerOrderPage } from '../EventsPage/Orders/TicketsPerOrderPage'
import { UserOrderDetailsPage } from './Orders/UserOrdersDetailsPage/UserOrderDetailsPage'
import { TransferOrderPage } from './Orders/TransferOrderPage/TransferOrderPage'
import { UserTicketsPage } from './Tickets/UserTicketsPage/UserTicketPage'
import { UsersSearch } from './components/UsersSearch'
import { UnifyAccountsPage } from './UnifyAccountsPage/UnifyAccountsPage'
import history from '~/config/history'
import { UserRolesPage } from './UserRolesPage/UserRolesPage'

export const UsersPage = () => {
  const [user, setUser] = useState()

  const redirectToInfoUser = async (userId) => {
    setUser(null)
    history.push(ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', userId))
  }

  const redirectToUnifyAccountsPage = (selectedUser, userId) => {
    if (selectedUser != null) {
      setUser(selectedUser)
    }
    history.push(ROUTES.ADMIN.USERS.UNIFY.INDEX.replace(':userId', userId))
  }

  return (
    <AppContainer title='Usuarios'>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN.USERS.INDEX}
          render={(props) => <UsersIndexPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.USERS.VIEW}
          render={(props) => <UsersInfoPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.USERS.ORDERS.INDEX}
          render={(props) => <UserOrdersIndexPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.USERS.ORDERS.DETAILS}
          render={(props) => <UserOrderDetailsPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.USERS.TICKETS.DETAILS}
          render={(props) => <UserOrderDetailsPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.ORDERS.HISTORY}
          render={(props) => <OrderRecordPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.TICKETS.HISTORY}
          render={(props) => <OrderRecordPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.ORDERS.PAYMENTS}
          render={(props) => <OrderPaymentsPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.TICKETS.PAYMENTS}
          render={(props) => <OrderPaymentsPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.ORDERS.TICKETS}
          render={(props) => <TicketsPerOrderPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.TICKETS.TICKET_LIST}
          render={(props) => <TicketsPerOrderPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.ORDERS.TRANSFER}
          render={(props) => <TransferOrderPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.USERS.TICKETS.TRANSFER}
          render={(props) => <TransferOrderPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.USERS.TICKETS.INDEX}
          render={(props) => <UserTicketsPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.USERS.UNIFY.INDEX}
          render={(props) => (
            <UnifyAccountsPage
              {...props}
              onBack={redirectToInfoUser}
              userSelected={user}
              setUserSelected={setUser}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.USERS.UNIFY.SEARCH}
          render={(props) => (
            <UsersSearch
              {...props}
              onSuccess={redirectToUnifyAccountsPage}
              onCancel={redirectToUnifyAccountsPage}
            />
          )}
        />

        <Route
          exact
          path={ROUTES.ADMIN.USERS.ROLES.INDEX}
          render={(props) => <UserRolesPage {...props} />}
        />
      </Switch>
    </AppContainer>
  )
}

export default UsersPage
