import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: '300px'
  },
  containerTitleSales: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  nameColumn: {
    color: theme.palette.primary.main
  },
  field: {
    width: 300
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

export default useStyles
