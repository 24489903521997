import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: '1rem',
    marginBottom: '1rem',
    display: 'flex'
  },
  form: {
    display: 'flex',
    width: '-webkit-fill-available',
    paddingTop: 5,
    marginLeft: 20
  },
  field: {
    display: 'flex',
    flexGrow: 1,
    width: 300
  },
  input: {
    display: 'flex',
    flexGrow: 1,
    marginTop: 5
  }
}))

export default useStyles
