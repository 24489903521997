import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { loadOrderDetails } from 'state/modules/orders'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { loadEvent } from '~/state/modules/events'
import { useStyles } from './OrderDetailsPage.style'

export const OrderDetailsPage = ({ onBack }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { eventId, orderId, status } = useParams()
  const [order, setOrder] = useState(null)
  const { pending } = useSelector((state) => state.orders)
  const { event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'

  const createRow = (label, value, i) => {
    return (
      <TableRow className={classes.tableRow} key={i}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>{value}</TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(eventId))
    }

    fetchData().then()
  }, [dispatch, eventId])

  useEffect(() => {
    async function fetchData() {
      const order = await dispatch(loadOrderDetails(orderId))
      setOrder(order[0])
    }
    fetchData().then()
  }, [dispatch, eventId, status, orderId])

  const paymentStatus = {
    Pending: 'Pendiente',
    InProgress: 'En Progreso',
    InMediation: 'En Mediación',
    Approved: 'Aprobada',
    Rejected: 'Rechazada',
    Cancelled: 'Cancelada',
    Refunded: 'Devuelta',
    ChargedBack: 'Contracargo'
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Orden'>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <Table>
              <TableBody>
                {createRow('Id', order?.Id)}
                {createRow('MercadoPago Id', order?.MercadoPagoId)}
                {createRow('Nombre', order?.Nombre)}
                {createRow(documentId, order?.DNI)}
                {createRow('Teléfono', order?.Telefono)}
                {createRow('Fecha de Compra', order?.FechaDeCompra)}
                {createRow('Estado de pago', paymentStatus[order?.Status])}
                {createRow('Método de pago', order?.MedioDePago)}
                {createRow('RRPP', order?.RRPP)}
                {order?.Entradas &&
                  order?.Entradas.map((t, i) =>
                    createRow(t?.Tipo, t?.Cantidad, i)
                  )}
              </TableBody>
            </Table>
            <div className={classes.actionsContainer}>
              <Button onClick={() => onBack(eventId)} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default OrderDetailsPage
