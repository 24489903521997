import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUsers, resetUsers } from '~/state/modules/users'
import { loadOrganizer } from '~/state/modules/organizers'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer, SearchUserComponent } from '~views/shared'
import { useStyles } from './UsersSearch.style'
import { UsersList } from '../../components/UsersList'

export const UsersSearch = ({ match, onSuccess, onCancel, message }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const { users, pendingUsers } = useSelector((state) => state.users)
  const { organizer } = useSelector((state) => state.organizers)

  const performSearch = async (payload) => {
    const { SearchPattern } = payload
    if (SearchPattern.length >= 3) {
      setSearchValue(SearchPattern)
    }
    await dispatch(loadUsers(payload))
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(match.params.id))
    }
    fetchData().then()

    return () => {
      dispatch(resetUsers())
    }
  }, [dispatch, match])

  return (
    <PaperContainer
      title={
        <div className={classes.title}>
          <Typography variant='h5'>Usuarios</Typography>
        </div>
      }>
      <div className={classes.titleContainer}>
        <SearchUserComponent
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder='Ingresar datos...'
          pending={pendingUsers}
        />
      </div>
      {pendingUsers ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {users.map((user, i) => (
            <UsersList
              onSuccess={onSuccess}
              user={user}
              message={message}
              entityIdentifier={user.FullName}
              organizerIdentifier={organizer?.Name}
              key={i}
            />
          ))}
          <div className={classes.actionsContainer}>
            <Button onClick={() => onCancel(match.params.id)} color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default UsersSearch
