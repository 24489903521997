import * as types from './types'

const initialState = {
  pendingClearCache: false,
  error: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_PROFILE_ATTEMPT:
      return {
        ...state,
        pendingClearCache: true,
        error: null
      }
    case types.CLEAR_PROFILE_SUCCESS:
      return {
        ...state,
        pendingClearCache: false,
        error: null
      }
    case types.CLEAR_PROFILE_FAILURE:
      return {
        ...state,
        pendingClearCache: false,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
