import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createColumn,
  createCustomColumn,
  TableGrid,
  TableGridOrder
} from 'views/shared'
import { loadUserPaged } from 'state/modules/users'
import { showSnackbarError } from 'views/utils/snackbar'
import { Info } from '@material-ui/icons'
import { setPreviousRoute } from 'state/modules/users'
import { Button, Typography } from '@material-ui/core'
import history from 'config/history'
import { SearchUserComponent } from '~views/shared'
import { ROUTES } from 'views/routes'

const makeColumns = (documentId) => {
  return [
    createColumn('Id', 'ID'),
    createColumn('FullName', 'Nombre y Apellido'),
    createColumn('Email', 'Email'),
    createCustomColumn(
      (c) => (
        <Typography variant='subtitle2'>
          {c.IdentificationType !== 0 && c.IdentificationType !== 1
            ? 'Otro'
            : documentId}
          : {c.DNI}
        </Typography>
      ),
      `${documentId} / Identificación`
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            history.push(ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', c.Id))
          }}>
          <Info />
        </Button>
      ),
      'Información'
    )
  ]
}

const makeFooterActions = () => []

export const UsersIndexPage = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const { pending, pagedList, reqParams } = useSelector((state) => state.users)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'

  useEffect(() => {
    if (reqParams != null) {
      setSearch(reqParams.searchParams.SearchPattern)
    }
  }, [reqParams])

  useEffect(() => {
    dispatch(setPreviousRoute(ROUTES.ADMIN.USERS.INDEX))
  }, [dispatch])

  const performSearch = async (payload) => {
    const { SearchPattern } = payload
    if (SearchPattern.length >= 3) {
      setSearch(SearchPattern)
    }
    try {
      await dispatch(
        loadUserPaged({
          searchParams: payload,
          pageNumber: 0,
          orderBy: 'Id',
          order: TableGridOrder.DESC
        })
      )
    } catch (e) {
      showSnackbarError(e)
    }
  }

  const makeSearchForm = () => {
    return (
      <SearchUserComponent
        performSearch={performSearch}
        searchValue={search}
        placeholder='Ingresar datos...'
        pending={pending}
      />
    )
  }

  return (
    <TableGrid
      title='Usuarios'
      columns={makeColumns(documentId)}
      footerActions={makeFooterActions()}
      searchForm={makeSearchForm}
      loadItemsPage={() => {}}
      searchParams={search}
      isLoading={pending}
      pagedList={pagedList}
      keyName='Id'
      order={reqParams?.order || TableGridOrder.DESC}
      orderBy={reqParams?.orderBy || 'Id'}
      useNewFormat
      hideFooter
    />
  )
}
