import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  fieldSelect: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '20rem',
    marginInline: theme.spacing(2)
  },
  eventLengthCaption: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(0)
  },
  items: {
    fontSize: 'larger',
    marginBottom: 3,
    '&:hover': {
      backgroundColor: '#EAEDED'
    }
  },
  itemRoot: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
    cursor: 'pointer',
    width: '100%',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: '#EAEDED'
    }
  },
  itemLabel: {
    flex: 1
  },
  itemLabelSelected: {
    color: theme.palette.primary.main
  },
  itemAction: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  dialogContent: {
    margin: '0 !important',
    padding: '0 !important'
  },
  dialogActions: {
    justifyContent: 'space-between',
    margin: '0 1rem',
    paddingRight: 0
  }
}))

export default useStyles
