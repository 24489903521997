import React from 'react'
import moment from 'moment'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './EventTitle.style'

export const EventTitle = ({ event }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Grid item lg={4}>
          <Typography variant={'h1'} className={classes.title}>
            {event?.Title}
          </Typography>
          <Typography>{event?.Organizer?.Name}</Typography>
        </Grid>
        <Grid item lg={4}>
          <Typography className={classes.label}>
            Fecha de Inicio:{' '}
            <Typography component={'span'} className={classes.value}>
              {moment(event?.StartDateTime).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Typography>
          <Typography className={classes.label}>
            Fecha de Fin:{' '}
            <Typography component={'span'} className={classes.value}>
              {moment(event?.EndDateTime).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Typography>
          <Typography className={classes.label}>
            Fecha de Corte de Venta:{' '}
            <Typography component={'span'} className={classes.value}>
              {moment(event?.SalesEndDateTime).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item lg={4}>
          <Typography className={classes.label}>
            Id:{' '}
            <Typography component={'span'} className={classes.value}>
              {event?.Id}
            </Typography>
          </Typography>
          <Typography className={classes.label}>
            Habilitado:{' '}
            <Typography component={'span'} className={classes.value}>
              {event?.IsEnabled ? 'Sí' : 'No'}
            </Typography>
          </Typography>
          <Typography className={classes.label}>
            Lugar:{' '}
            <Typography component={'span'} className={classes.value}>
              {event?.Location?.Name}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default EventTitle
