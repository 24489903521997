import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './ProducersIndexPage.style'
import { Button, TextField } from '@material-ui/core'
import { loadOrganizersPaged } from '~/state/modules/organizers'
import {
  TableGrid,
  createColumn,
  createCustomColumn,
  TableGridOrder
} from '~/views/shared'
import { ProducersActionsMenu } from '../components/ProducersActionsMenu'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = () => {
  return [
    createColumn('Id', 'ID'),
    createColumn('Name', 'Nombre'),
    createColumn('Commission', 'Comisión', 'center'),
    createCustomColumn(
      (c) => <ProducersActionsMenu organizer={c} />,
      'Acciones'
    )
  ]
}

const makeFooterActions = () => []

export const ProducersIndexPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const { pending, organizersPaged, reqParams } = useSelector(
    (state) => state.organizers
  )

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    try {
      dispatch(
        loadOrganizersPaged({
          searchParams: search,
          page: 0,
          size: 10,
          orderBy: 'Id',
          order: TableGridOrder.DESC
        })
      )
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Nombre'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button variant='contained' color='primary' onClick={performSearch}>
          Buscar
        </Button>
      </div>
    )
  }

  return (
    <TableGrid
      title='Mis Productoras'
      rowClasses={(x) => x?.IsEnabled && `${classes.conditionalRow}`}
      columns={makeColumns(dispatch)}
      footerActions={makeFooterActions()}
      searchForm={makeSearchForm}
      loadItemsPage={(x) => dispatch(loadOrganizersPaged(x))}
      searchParams={search}
      isLoading={pending}
      pagedList={organizersPaged}
      keyName='Id'
      order={reqParams?.order || TableGridOrder.ASC}
      orderBy={reqParams?.orderBy || 'Name'}
      useNewFormat
    />
  )
}

export default ProducersIndexPage
