import React, { useState } from 'react'
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody
} from '@material-ui/core'
import clsx from 'clsx'
import { round } from 'views/utils/functions'
import { MacroActionMenu } from './MacroActionMenu'
import { useStyles } from './ExpandedRowMacro.style'

export const ExpandedRowMacro = ({ macroData }) => {
  const classes = useStyles()
  const { Id, CommerceId, SecretKey, Phrase, Commission, Vat } = macroData
  const [commission, setCommission] = useState(Commission)
  const [vat, setVat] = useState(Vat)

  const changeCommissions = (com, iva) => {
    setCommission(com)
    setVat(iva)
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant='h5' className={classes.title} component='div'>
        Datos de la Cuenta
      </Typography>
      <Table size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>Id del Comercio</TableCell>
            <TableCell className={classes.cellHead}>SecretId</TableCell>
            <TableCell className={classes.cellHead}>Frase</TableCell>
            <TableCell className={classes.cellHead}>Comisión</TableCell>
            <TableCell className={classes.cellHead}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.bodyStyle}>
          <TableRow>
            <TableCell title={CommerceId} className={classes.cellRow}>
              {CommerceId}
            </TableCell>
            <TableCell title={SecretKey} className={classes.cellRow}>
              {SecretKey}
            </TableCell>
            <TableCell title={Phrase} className={classes.cellRow}>
              {Phrase}
            </TableCell>
            <TableCell
              className={clsx({
                [classes.cellRow]: true,
                [classes.noCommissions]: commission == null
              })}>
              {commission != null
                ? `${round(commission * 100)}% - IVA: ${round(vat * 100)}%`
                : 'Sin configurar'}
            </TableCell>
            <TableCell className={classes.cellRow}>
              <MacroActionMenu
                macroId={Id}
                commission={commission}
                vat={vat}
                changeCommissions={changeCommissions}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

export default ExpandedRowMacro
