import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    paddingBlock: theme.spacing(2)
    // paddingInline: theme.spacing(1)
  },
  containerButton: {
    paddingLeft: theme.spacing(1)
  },
  button: {
    minWidth: theme.spacing(4),
    padding: 4
  },
  autocomplete: {
    width: '100%'
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(4)
  }
}))

export default useStyles
