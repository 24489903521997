import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  loadInvoiceBooks,
  loadCreditNoteBooklets
} from '~/state/modules/billing'
import { Save } from '@material-ui/icons'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { useStyles } from './BillingSettingsForm.style'

const validationSchema = Yup.object().shape({
  Account: Yup.string().required('Es requerido ingresar una cuenta'),
  Cash: Yup.string().required('Es requerido ingresar una caja'),
  VatTaxAliquot: Yup.string().required(
    'Es requerido ingresar la Alicuota de IVA'
  ),
  CostCenter: Yup.string().required(
    'Es requerido ingresar el Centro de Costos'
  ),
  PaymentMethod: Yup.string().required(
    'Es requerido ingresar el Medio de Pago'
  ),
  InvoiceType: Yup.string().required(
    'Es requerido ingresar el Tipo de Factura para Venta'
  ),
  InvoiceBook: Yup.string().required(
    'Es requerido ingresar el Talonario de Factura para Venta'
  ),
  CreditNoteType: Yup.string().required(
    'Es requerido ingresar el Tipo de Factura para Nota de Crédito'
  ),
  CreditNoteBook: Yup.string().required(
    'Es requerido ingresar el Talonario de Factura para Nota de Crédito'
  )
})

export const BillingSettingsForm = ({
  values,
  handleSubmit,
  onCancel,
  actionPending,
  organizerId,
  Accounts,
  CashDrawers,
  CostCenters,
  PaymentMethods,
  DocumentTypes,
  VatTaxAliquots,
  InvoiceTypeId,
  CreditNoteTypeId
}) => {
  const classes = useStyles()
  const {
    pendingInvoiceBooks,
    invoiceBooks,
    creditNoteBooklets,
    pendingCreditNoteBooklets
  } = useSelector((state) => state.billing)
  const dispatch = useDispatch()

  const handleChangeInvoiceType = async (creditNoteTypeId) => {
    try {
      await dispatch(loadInvoiceBooks(organizerId, creditNoteTypeId))
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeCreditNoteBookletType = async (creditNoteBookletId) => {
    try {
      await dispatch(loadCreditNoteBooklets(organizerId, creditNoteBookletId))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!!InvoiceTypeId && !!CreditNoteTypeId) {
      async function fetchData() {
        await dispatch(loadInvoiceBooks(organizerId, InvoiceTypeId))
        await dispatch(loadCreditNoteBooklets(organizerId, CreditNoteTypeId))
      }

      fetchData().then()
    }
  }, [dispatch, organizerId, InvoiceTypeId, CreditNoteTypeId])

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.Account && Boolean(errors.Account)}>
                {(errors.Account && touched.Account && errors.Account) ||
                  'Cuenta'}
              </InputLabel>
              <Select
                name='Account'
                value={values.Account}
                fullWidth
                required
                disabled={actionPending}
                onChange={handleChange}
                error={touched.Account && Boolean(errors.Account)}
                size='small'
                label={
                  (errors.Account && touched.Account && errors.Account) ||
                  'Cuenta'
                }>
                {Accounts &&
                  Accounts.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.Cash && Boolean(errors.Cash)}>
                {(errors.Cash && touched.Cash && errors.Cash) || 'Caja'}
              </InputLabel>
              <Select
                name='Cash'
                value={values.Cash}
                fullWidth
                required
                disabled={actionPending}
                onChange={handleChange}
                error={touched.Cash && Boolean(errors.Cash)}
                size='small'
                label={(errors.Cash && touched.Cash && errors.Cash) || 'Caja'}>
                {CashDrawers &&
                  CashDrawers.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.VatTaxAliquot && Boolean(errors.VatTaxAliquot)}>
                {(errors.VatTaxAliquot &&
                  touched.VatTaxAliquot &&
                  errors.VatTaxAliquot) ||
                  'Alicuota de IVA'}
              </InputLabel>
              <Select
                name='VatTaxAliquot'
                value={values.VatTaxAliquot}
                fullWidth
                required
                disabled={actionPending}
                onChange={handleChange}
                error={touched.VatTaxAliquot && Boolean(errors.VatTaxAliquot)}
                size='small'
                label={
                  (errors.VatTaxAliquot &&
                    touched.VatTaxAliquot &&
                    errors.VatTaxAliquot) ||
                  'Alicuota de IVA'
                }>
                {VatTaxAliquots &&
                  VatTaxAliquots.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.CostCenter && Boolean(errors.CostCenter)}>
                {(errors.CostCenter &&
                  touched.CostCenter &&
                  errors.CostCenter) ||
                  'Centro de Costos'}
              </InputLabel>
              <Select
                name='CostCenter'
                value={values.CostCenter}
                fullWidth
                required
                disabled={actionPending}
                onChange={handleChange}
                error={touched.CostCenter && Boolean(errors.CostCenter)}
                size='small'
                label={
                  (errors.CostCenter &&
                    touched.CostCenter &&
                    errors.CostCenter) ||
                  'Centro de Costos'
                }>
                {CostCenters &&
                  CostCenters.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.PaymentMethod && Boolean(errors.PaymentMethod)}>
                {(errors.PaymentMethod &&
                  touched.PaymentMethod &&
                  errors.PaymentMethod) ||
                  'Medio de Pago'}
              </InputLabel>
              <Select
                name='PaymentMethod'
                value={values.PaymentMethod}
                fullWidth
                required
                disabled={actionPending}
                onChange={handleChange}
                error={touched.PaymentMethod && Boolean(errors.PaymentMethod)}
                size='small'
                label={
                  (errors.PaymentMethod &&
                    touched.PaymentMethod &&
                    errors.PaymentMethod) ||
                  'Medio de Pago'
                }>
                {PaymentMethods &&
                  PaymentMethods.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.InvoiceType && Boolean(errors.InvoiceType)}>
                {(errors.InvoiceType &&
                  touched.InvoiceType &&
                  errors.InvoiceType) ||
                  'Tipo de Factura para Venta'}
              </InputLabel>
              <Select
                name='InvoiceType'
                value={values.InvoiceType}
                fullWidth
                required
                disabled={actionPending}
                onChange={(e) => {
                  handleChange(e)
                  handleChangeInvoiceType(e.target.value)
                }}
                error={touched.InvoiceType && Boolean(errors.InvoiceType)}
                size='small'
                label={
                  (errors.InvoiceType &&
                    touched.InvoiceType &&
                    errors.InvoiceType) ||
                  'Tipo de Factura para Venta'
                }>
                {DocumentTypes &&
                  DocumentTypes.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={touched.InvoiceBook && Boolean(errors.InvoiceBook)}>
                {(errors.InvoiceBook &&
                  touched.InvoiceBook &&
                  errors.InvoiceBook) ||
                  'Talonario de Factura para Venta'}
              </InputLabel>
              <Select
                name='InvoiceBook'
                value={values.InvoiceBook}
                fullWidth
                required
                disabled={actionPending || pendingInvoiceBooks}
                onChange={handleChange}
                error={touched.InvoiceBook && Boolean(errors.InvoiceBook)}
                size='small'
                label={
                  (errors.InvoiceBook &&
                    touched.InvoiceBook &&
                    errors.InvoiceBook) ||
                  'Talonario de Factura para Venta'
                }>
                {invoiceBooks &&
                  invoiceBooks.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={
                  touched.CreditNoteType && Boolean(errors.CreditNoteType)
                }>
                {(errors.CreditNoteType &&
                  touched.CreditNoteType &&
                  errors.CreditNoteType) ||
                  'Tipo de Factura para Nota de Crédito'}
              </InputLabel>
              <Select
                name='CreditNoteType'
                value={values.CreditNoteType}
                fullWidth
                required
                disabled={actionPending}
                onChange={(e) => {
                  handleChange(e)
                  handleChangeCreditNoteBookletType(e.target.value)
                }}
                error={touched.CreditNoteType && Boolean(errors.CreditNoteType)}
                size='small'
                label={
                  (errors.CreditNoteType &&
                    touched.CreditNoteType &&
                    errors.CreditNoteType) ||
                  'Tipo de Factura para Nota de Crédito'
                }>
                {DocumentTypes &&
                  DocumentTypes.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel
                className={classes.selectLabelStyle}
                error={
                  touched.CreditNoteBook && Boolean(errors.CreditNoteBook)
                }>
                {(errors.CreditNoteBook &&
                  touched.CreditNoteBook &&
                  errors.CreditNoteBook) ||
                  'Talonario de Factura para Nota de Crédito'}
              </InputLabel>
              <Select
                name='CreditNoteBook'
                value={values.CreditNoteBook}
                fullWidth
                required
                disabled={actionPending || pendingCreditNoteBooklets}
                onChange={handleChange}
                error={touched.CreditNoteBook && Boolean(errors.CreditNoteBook)}
                size='small'
                label={
                  (errors.CreditNoteBook &&
                    touched.CreditNoteBook &&
                    errors.CreditNoteBook) ||
                  'Talonario de Factura para Nota de Crédito'
                }>
                {creditNoteBooklets &&
                  creditNoteBooklets.map((i) => (
                    <MenuItem key={i.Id} value={i.Id}>
                      {i.Description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={isSubmitting} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={actionPending || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                'Actualizar Configuración'
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default BillingSettingsForm
