import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useStyles } from './OrdersIndexPage.style'
import { ROUTES } from 'views/routes'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import { Info, Visibility, FileCopyOutlined } from '@material-ui/icons'
import { SearchOrderComponent } from '../SearchOrderComponent'
import { loadOrdersPaged } from '~/state/modules/orders'
import { currencyIntegerFormat } from 'views/utils/functions'
import { ROLES } from 'config/constants'
import copy from 'copy-to-clipboard'
import { TableGrid, createCustomColumn, TableGridOrder } from '~/views/shared'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

const makeColumns = (isAdmin, history, paymentStatus, handleOpen, classes) => {
  return [
    ...(isAdmin
      ? [
          createCustomColumn(
            (c) => (
              <IconButton
                onClick={() => {
                  handleOpen(c.Id)
                }}>
                <Visibility />
              </IconButton>
            ),
            'ID'
          )
        ]
      : []),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>
          {c.MercadoPagoId === 'NotSet' ? '---' : c.MercadoPagoId}
        </Typography>
      ),
      'N° de Operación'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>
          {c.EventoFechaInicio}
        </Typography>
      ),
      'Fecha del Evento'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>{`${
          isAdmin ? `(${c.EventoId}) ` : ''
        }${c.Evento}`}</Typography>
      ),
      'Nombre del Evento'
    ),
    createCustomColumn((c) => {
      const tickets = c.Entradas
      return tickets.map((t) => (
        <Typography
          key={t.Id}
          className={
            classes.tableCell
          }>{`${t.Tipo}: ${t.Cantidad}`}</Typography>
      ))
    }, 'Tickets'),
    createCustomColumn(
      (c) => (
        <Typography
          className={clsx({
            [classes.status]: true,
            [classes.statusApproved]: c.Status === 'Approved',
            [classes.statusRejected]: c.Status === 'Rejected',
            [classes.statusInProgress]: c.Status === 'InProgress',
            [classes.statusPending]: c.Status === 'Pending',
            [classes.statusNotSet]: c.Status === 'NotSet',
            [classes.statusChargedBack]: c.Status === 'ChargedBack',
            [classes.statusRefunded]: c.Status === 'Refunded',
            [classes.statusCancelled]: c.Status === 'Cancelled',
            [classes.statusInMediation]: c.Status === 'InMediation'
          })}>
          {paymentStatus[c.Status]}
        </Typography>
      ),
      'Estado de la Orden'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>{c.FechaDeCompra}</Typography>
      ),
      'Fecha de Compra'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>{c.MedioDePago}</Typography>
      ),
      'Metodo de Pago'
    ),
    createCustomColumn(
      (c) => (
        <Typography className={classes.tableCell}>
          {currencyIntegerFormat(c?.Total)}
        </Typography>
      ),
      'Monto'
    ),
    createCustomColumn(
      (c) => (
        <IconButton
          onClick={() => {
            history.push(
              ROUTES.ADMIN.SUPPORT.ORDERS.DETAILS.replace(':orderId', c.Id)
            )
          }}>
          <Info />
        </IconButton>
      ),
      'Ver Detalle'
    )
  ]
}

const makeFooterActions = () => []

export const OrdersIndexPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { ordersPaged, pendingOrdersPaged, reqParams, filtersSearch } =
    useSelector((state) => state.orders)
  const { user: userLogin } = useSelector((state) => state.login)
  const [payload, setPayload] = useState({
    MercadoPagoId: filtersSearch?.MercadoPagoId,
    OrderId: filtersSearch?.OrderId,
    PaymentStatus: filtersSearch?.PaymentStatus,
    StartDate: filtersSearch?.StartDate,
    EndDate: filtersSearch?.EndDate,
    MinPrice: filtersSearch?.MinPrice,
    MaxPrice: filtersSearch?.MaxPrice
  })
  const rolesArray = userLogin?.roles?.split(',') || []
  const [open, setOpen] = useState(false)
  const [orderId, setOrderId] = useState('')

  const paymentStatus = {
    Pending: 'Pendiente',
    InProgress: 'En Progreso',
    InMediation: 'En Mediación',
    Approved: 'Aprobada',
    Rejected: 'Rechazada',
    Cancelled: 'Cancelada',
    Refunded: 'Devuelta',
    ChargedBack: 'Contracargo',
    NotSet: 'En Proceso'
  }

  const handleOpen = (id) => {
    setOrderId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOrderId('')
  }

  const copyToClipBoard = () => {
    try {
      copy(orderId)
      showSnackbarSuccess('¡Id copiado al Portapapeles!', '')
      setOpen(false)
      setOrderId('')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const performSearch = async (filters) => {
    setPayload(filters)
    try {
      await dispatch(
        loadOrdersPaged(filters, {
          searchParams: '',
          pageNumber: 1,
          pageSize: 5,
          orderBy: reqParams?.orderBy || 'CreatedDate',
          order: reqParams?.order || TableGridOrder.ASC
        })
      )
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const makeSearchForm = () => {
    return (
      <SearchOrderComponent
        performSearch={performSearch}
        pending={pendingOrdersPaged}
      />
    )
  }

  return (
    <>
      <TableGrid
        title={
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Ordenes</Typography>
          </div>
        }
        columns={makeColumns(
          rolesArray?.includes(ROLES.ADMIN),
          history,
          paymentStatus,
          handleOpen,
          classes
        )}
        footerActions={makeFooterActions()}
        searchForm={makeSearchForm}
        loadItemsPage={(x) => {
          if (ordersPaged) {
            dispatch(
              loadOrdersPaged(payload, {
                searchParams: '',
                pageNumber: x.pageNumber || reqParams?.pageNumber,
                pageSize: x.pageSize,
                orderBy: x.orderBy,
                order: x.order
              })
            )
          }
        }}
        searchParams={''}
        isLoading={pendingOrdersPaged}
        pagedList={ordersPaged}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.ASC}
        orderBy={reqParams?.orderBy || 'CreatedDate'}
        useNewFormat
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Button
            onClick={copyToClipBoard}
            endIcon={<FileCopyOutlined />}
            className={classes.button}>
            {orderId}
          </Button>
        </DialogTitle>
      </Dialog>
    </>
  )
}

export default OrdersIndexPage
