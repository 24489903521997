import * as types from './types'
import { MercadoPagoProxy } from '~/services'

export const loadCommissionsAttempt = () => ({
  type: types.LOAD_COMMISSIONS_ATTEMPT
})

export const loadCommissionsSuccess = (commissions) => ({
  type: types.LOAD_COMMISSIONS_SUCCESS,
  commissions
})

export const loadCommissionsFailure = (error) => ({
  type: types.LOAD_COMMISSIONS_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const loadCommissions = () => async (dispatch) => {
  try {
    dispatch(loadCommissionsAttempt())

    const proxy = new MercadoPagoProxy()
    const response = await proxy.getCommissions()

    dispatch(loadCommissionsSuccess(response))
  } catch (error) {
    dispatch(loadCommissionsFailure(error))
    throw error
  }
}
