import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex'
  },
  input: {
    flexGrow: 1
  },
  containerButton: {
    paddingLeft: 10
  },
  fieldRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      gap: theme.spacing(0)
    }
  },
  field: {
    minWidth: 150,
    height: 40
  },
  documentField: {
    minWidth: 250,
    marginInline: theme.spacing(2)
  },
  searchKeyField: {
    marginTop: 5,
    minWidth: 200
  }
}))

export default useStyles
