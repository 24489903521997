import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  CircularProgress,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { Save } from '@material-ui/icons'
import { useStyles } from './EventDrawForm.style'

const validationSchema = Yup.object().shape({
  Title: Yup.string()
    .required('Es requerido ingresar un nombre')
    .max(250, 'La cantidad máxima es de 250 caracteres'),
  Description: Yup.string()
    .required('Es requerido ingresar la descripción')
    .max(250, 'La cantidad máxima es de 250 caracteres'),
  TicketsAmount: Yup.number()
    .integer('Solo se permiten valores enteros')
    .min(1, 'Cantidad debe ser mayor a 0'),
  TicketTypeId: Yup.string().required('Es requerido ingresar un ticket'),
  ScheduledOn: Yup.date().required()
})

export const EventDrawForm = ({
  values,
  ticketList,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Title && touched.Title && errors.Title) ||
                  'Nombre del Sorteo'
                }
                name='Title'
                autoFocus
                value={values.Title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Title && Boolean(errors.Title)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Description &&
                    touched.Description &&
                    errors.Description) ||
                  'Información Adicional del Sorteo'
                }
                name='Description'
                value={values.Description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Description && Boolean(errors.Description)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl variant='outlined' className={classes.fieldRow}>
              <DatePicker
                locale='es'
                id='ScheduledOn'
                format='DD/MM/YYYY'
                name='ScheduledOn'
                value={values.ScheduledOn}
                label={
                  (errors.ScheduledOn &&
                    touched.ScheduledOn &&
                    errors.ScheduledOn) ||
                  'Fecha del Sorteo'
                }
                onChange={(value) => {
                  setFieldValue('ScheduledOn', value)
                }}
                onBlur={handleBlur}
                error={touched.ScheduledOn && Boolean(errors.ScheduledOn)}
                disableToolbar
                className={classes.field}
                fullWidth
                required
                size='small'
                variant='inline'
                InputLabelProps={{
                  shrink: true
                }}
                animateYearScrolling
              />
              <TimePicker
                locale='es'
                name='ScheduledOn'
                className={classes.field}
                value={values.ScheduledOn}
                ampm={false}
                label={
                  (errors.ScheduledOn &&
                    touched.ScheduledOn &&
                    errors.ScheduledOn) ||
                  'Hora del Sorteo'
                }
                onChange={(value) => {
                  setFieldValue('ScheduledOn', value.format())
                }}
                error={touched.ScheduledOn && Boolean(errors.ScheduledOn)}
                fullWidth
                required
                size='small'
                variant='inline'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.selectRow}`}>
              <InputLabel className={classes.selectLabelStyle} required>
                {errors.TicketTypeId || 'Tipo de Ticket'}
              </InputLabel>
              <Select
                labelId='ticketId-label'
                name='TicketTypeId'
                id='ticketId-select'
                value={values?.TicketTypeId}
                className={classes.selectStyle}
                fullWidth
                required
                onChange={(value) => {
                  handleChange(value)
                  setFieldValue(
                    'TicketsAmount',
                    ticketList.find(
                      (ticket) => ticket.Id === value.target.value
                    ).Remaining || 0
                  )
                }}
                size='small'
                label={'TicketTypeId'}>
                {ticketList &&
                  ticketList.map((ticket) => (
                    <MenuItem key={ticket.Name} value={ticket.Id}>
                      {ticket.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.TicketsAmount &&
                    touched.TicketsAmount &&
                    errors.TicketsAmount) ||
                  'Cantidad de Tickets'
                }
                name='TicketsAmount'
                type='number'
                value={values.TicketsAmount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.TicketsAmount && Boolean(errors.TicketsAmount)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={onCancel}
              disabled={actionSuccess || isSubmitting}
              color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={actionPending || actionSuccess || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default EventDrawForm
