import axios from 'axios'
import BaseProxy from './BaseProxy'

export class MercadoPagoProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/mercadopago`
  }

  async getCommissions() {
    const response = await axios.get(`${this._baseUrl}/v2/commissions`)
    return response.data
  }
}

export default MercadoPagoProxy
