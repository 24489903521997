import React, { useEffect, useState } from 'react'
import { useStyles } from './EventsList.style'
import { Typography } from '@material-ui/core'
import { TicketsList } from './TitcketsList/TicketsList'

export const EventsList = ({
  event,
  totalEntries,
  setTotalEntries,
  totalToPay,
  setTotalToPay,
  handleOrder,
  handleDecrementUnits,
  isRemote
}) => {
  const classes = useStyles()
  const [ticketsFiltered, setTicketsFiltered] = useState([])

  useEffect(() => {
    if (event) {
      const filteredTickets = event.TicketTypes.filter((ticket) =>
        isRemote ? ticket.AllowRemoteSell : !ticket.AllowRemoteSell
      )
      setTicketsFiltered(
        filteredTickets.map((t) => ({
          ...t,
          Units: 0
        }))
      )
    }
  }, [event, isRemote])

  return (
    <div className={classes.container}>
      <div className={classes.containerList}>
        <>
          <Typography color='primary' variant='h4' className={classes.title}>
            {event.Title}
            <hr className={classes.separator} />
          </Typography>
          {ticketsFiltered.length ? (
            ticketsFiltered.map((ticket, i) => (
              <TicketsList
                eventId={event.Id}
                ticket={ticket}
                totalEntries={totalEntries}
                setTotalEntries={setTotalEntries}
                totalToPay={totalToPay}
                setTotalToPay={setTotalToPay}
                handleOrder={handleOrder}
                handleDecrementUnits={handleDecrementUnits}
                key={i}
              />
            ))
          ) : (
            <Typography variant='h5' className={classes.ticketsNotFound}>
              No se encontraron Tickets.
            </Typography>
          )}
        </>
      </div>
    </div>
  )
}

export default EventsList
