import BaseProxy from './BaseProxy'
import axios from 'axios'
import { handleReportFile } from '../views/utils/functions'

export class EventsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/events`
  }

  async get(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getAll() {
    const response = await axios.get(`${this._baseUrl}/all/manager`)

    return response.data
  }

  async getLastEvents(quantity) {
    const response = await axios.get(
      `${this._baseUrl}/availablefornotification/${quantity}`
    )

    return response.data
  }

  async getEventsWithCombo() {
    const response = await axios.get(`${this._baseUrl}/combo`)

    return response.data
  }

  async getById(eventId) {
    const response = await axios.get(`${this._baseUrl}/${eventId}`)

    return response.data
  }

  async getByIdManager(eventId) {
    const response = await axios.get(`${this._baseUrl}/${eventId}/manager`)

    return response.data
  }

  async post(eventObj) {
    const response = await axios.post(`${this._baseUrl}`, eventObj)

    return response.data
  }

  async put(eventId, eventObj) {
    const response = await axios.put(`${this._baseUrl}/${eventId}`, eventObj)

    return response.data
  }

  async delete(eventId) {
    const response = await axios.delete(`${this._baseUrl}/${eventId}`)

    return response.data
  }

  async enableEvent(eventId) {
    const response = await axios.put(`${this._baseUrl}/${eventId}/enable`)

    return response.data
  }

  async disableEvent(eventId) {
    const response = await axios.put(`${this._baseUrl}/${eventId}/disable`)

    return response.data
  }

  async sendNotify(eventId) {
    const response = await axios.put(`${this._baseUrl}/${eventId}/notify`)

    return response.data
  }

  async getImages(eventId) {
    const response = await axios.get(`${this._baseUrl}/${eventId}/images`)

    return response.data
  }

  async setDefaultImage(eventId, imageId) {
    const response = await axios.put(
      `${this._baseUrl}/${eventId}/images/default/${imageId}`
    )

    return response.data
  }

  async addImage(eventId, formData) {
    const response = await axios.post(
      `${this._baseUrl}/${eventId}/images`,
      formData
    )

    return response.data
  }

  async deleteImage(eventId, imageId) {
    const response = await axios.delete(
      `${this._baseUrl}/${eventId}/images/${imageId}`
    )

    return response.data
  }

  async getTickets(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/v2/${eventId}/tickets/all`
    )

    return response.data
  }

  async getTicketById(eventId, ticketId) {
    const response = await axios.get(
      `${this._baseUrl}/${eventId}/tickets/${ticketId}`
    )

    return response.data
  }

  async getTicketByIdWithCombo(eventId, ticketId) {
    const response = await axios.get(
      `${this._baseUrl}/v2/${eventId}/tickets/${ticketId}`
    )

    return response.data
  }

  async postTicket(eventId, ticket) {
    const response = await axios.post(
      `${this._baseUrl}/v2/${eventId}/tickets`,
      ticket
    )

    return response.data
  }

  async postTicketCash(eventId, ticket) {
    const response = await axios.post(
      `${this._baseUrl}/${eventId}/tickets/cash`,
      ticket
    )

    return response.data
  }

  async putTicket(eventId, ticketId, ticket) {
    let response
    if (ticket.Commissions.length === 0 && ticket.TicketUsage === 0) {
      response = await axios.put(
        `${this._baseUrl}/${eventId}/tickets/${ticketId}`,
        ticket
      )
    } else {
      response = await axios.put(
        `${this._baseUrl}/v2/${eventId}/tickets/${ticketId}`,
        ticket
      )
    }

    return response.data
  }

  async putTicketCash(eventId, ticketId, ticket) {
    let response
    if (ticket.Commissions.length === 0) {
      response = await axios.put(
        `${this._baseUrl}/${eventId}/tickets/${ticketId}`,
        ticket
      )
    } else {
      response = await axios.put(
        `${this._baseUrl}/${eventId}/tickets/cash/${ticketId}`,
        ticket
      )
    }

    return response.data
  }

  async deleteTicket(eventId, ticketId) {
    const response = await axios.delete(
      `${this._baseUrl}/${eventId}/tickets/${ticketId}`
    )
    return response.data
  }

  async getTicketByUsage(eventId, ticketUsage) {
    const response = await axios.get(
      `${this._baseUrl}/${eventId}/tickets/usage/${ticketUsage}`
    )
    return response.data
  }

  async getAllInvitations(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/${eventId}/tickets/usage/for/invitation`
    )
    return response.data
  }

  async getTicketsReport(eventId) {
    await axios
      .get(`${this._baseUrl}/${eventId}/report/tickets/sold`, {
        responseType: 'blob'
      })
      .then((response) =>
        handleReportFile(
          response,
          `Evento ${eventId} - Reporte de Tickets vendidos.csv`
        )
      )
  }

  async shareUrl(eventId) {
    const response = await axios.get(`${this._baseUrl}/${eventId}/share-url`)
    return response.data
  }
}

export default EventsProxy
