import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer } from '~/views/shared'
import { InvitationForm } from '../components/InvitationForm'
import { useStyles } from './InvitationIndexPage.style'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { fetchInvitations, sendInvitations } from 'state/modules/invitations'
import { loadEvent } from '~state/modules/events'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import copy from 'copy-to-clipboard'

export const InvitationIndexPage = ({ match }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const link =
    country === 'ar'
      ? 'http://onelink.to/ticketpass_ar'
      : 'http://onelink.to/ticketpass_uy'
  const { pending } = useSelector((state) => state.common)

  const { invitations, actionPending } = useSelector(
    (state) => state.invitations
  )

  const [valuesInvitations, setValuesInvitations] = useState(false)

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
      await dispatch(fetchInvitations(match.params.eventId))
    }
    fetchData().then()
  }, [dispatch, match])

  const values = {
    Title: event?.Title || '',
    EventId: event?.Id || '',
    TicketTypeId: '',
    Units: 1,
    Name: '',
    Email: '',
    DNI: ''
  }

  const handleCloseConfirmation = () => {
    dispatch(loadEvent(match.params.eventId))
    setValuesInvitations(false)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!actionPending) {
      try {
        await dispatch(sendInvitations(values))
        setValuesInvitations(true)
        setSubmitting(true)
        showSnackbarSuccess('La Invitación fue enviada')
      } catch (error) {
        showSnackbarError(error)
      } finally {
        setSubmitting(false)
      }
    }
  }

  const copyToClipBoard = (values) => {
    const message = `
    ${values.Name}, te obsequiamos una invitación para ${values.Title}!

    Si tenés cuenta ticketPass, verás tu QR en Mis Compras.

    Si no tenés cuenta ticketPass, tu invitación está asociada a tu ${documentId} (${values.DNI}).
    Descargá la APP, registrate con tu ${documentId} ó ingresá con Facebook ó Apple ID y cargá tu ${documentId} en Mi Perfil. Luego recibirás el QR en Mis Compras!

    ${link}`
    try {
      copy(message)
      showSnackbarSuccess('Instrucciones copiadas al portapapeles! ')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Enviar Invitación'>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <InvitationForm
            event={event}
            invitations={invitations}
            values={values}
            actionText='Enviar Invitación'
            handleSubmit={handleSubmit}
            actionPending={actionPending}
            onCancel={() => history.push(ROUTES.ADMIN.EVENTS.INDEX)}
            valuesInvitations={valuesInvitations}
            copyToClipBoard={copyToClipBoard}
            handleCloseConfirmation={handleCloseConfirmation}
          />
        )}
      </PaperContainer>
    </>
  )
}

export default InvitationIndexPage
