import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update } from '~/state/modules/draws'
import { loadTicketByUsage, loadEvent } from '~/state/modules/events'
import { useStyles } from './DrawsUpdatePage.style'
import { PaperContainer } from '~/views/shared'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { EventDrawForm } from 'views/pages/EventsPage/components/EventDrawForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { TICKET_USAGE } from 'config/constants'
import { loadDraw, resetDraws } from '~/state/modules/draws'
import * as moment from 'moment'
import { showSnackbarError } from 'views/utils/snackbar'

export const DrawsUpdatePage = ({ match, onSuccess }) => {
  const classes = useStyles()
  const { draw, pending, actionPending, actionSuccess } = useSelector(
    (state) => state.draws
  )
  const {
    event,
    ticketList,
    pending: pendingTickets
  } = useSelector((state) => state.events)

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    onSuccess(match.params.eventId)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadTicketByUsage(match.params.eventId, TICKET_USAGE.DRAW))
      await dispatch(loadEvent(match.params.eventId))
      await dispatch(loadDraw(match.params.drawId))
    }

    fetchData().then()

    return () => {
      dispatch(resetDraws())
    }
  }, [dispatch, match])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      if (values.TicketsAmount === '') {
        values.TicketsAmount = 1
      }
      await dispatch(update(match.params.drawId, values))
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    Title: draw?.Title || '',
    Description: draw?.Description || '',
    TicketsAmount: draw?.TicketsAmount || 0,
    TicketTypeId: draw?.TicketTypeId || '',
    ScheduledOn:
      draw?.ScheduledOn || moment(event?.StartDateTime).subtract(3, 'hours')
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Editar sorteo'>
        {pending || pendingTickets ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <Typography variant='subtitle2' className={classes.idDraw}>
              Id: {match.params.drawId}
            </Typography>
            <EventDrawForm
              values={values}
              ticketList={ticketList}
              actionText='Editar'
              handleSubmit={handleSubmit}
              actionPending={actionPending}
              actionSuccess={actionSuccess}
              onCancel={() =>
                history.push(
                  ROUTES.ADMIN.EVENTS.DRAWS.INDEX.replace(
                    ':eventId',
                    match.params.eventId
                  )
                )
              }
            />
          </>
        )}
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>Sorteo Actualizado!</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DrawsUpdatePage
