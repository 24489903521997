export const LOAD_ATTEMPT = 'admin/roles/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/roles/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/roles/LOAD_FAILURE'

export const LOAD_USERS_BY_ROLE_ATTEMPT =
  'admin/roles/LOAD_USERS_BY_ROLE_ATTEMPT'
export const LOAD_USERS_BY_ROLE_SUCCESS =
  'admin/roles/LOAD_USERS_BY_ROLE_SUCCESS'
export const LOAD_USERS_BY_ROLE_FAILURE =
  'admin/roles/LOAD_USERS_BY_ROLE_FAILURE'

export const REMOVE_USER_ATTEMPT = 'admin/roles/REMOVE_USER_ATTEMPT'
export const REMOVE_USER_SUCCESS = 'admin/roles/REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'admin/roles/REMOVE_USER_FAILURE'

export const ADD_USER_ATTEMPT = 'admin/roles/ADD_USER_ATTEMPT'
export const ADD_USER_SUCCESS = 'admin/roles/ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'admin/roles/ADD_USER_FAILURE'

export const PAGEDLIST_ATTEMPT = 'admin/roles/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/roles/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/roles/PAGEDLIST_FAILURE'

export const RESET = 'admin/roles/RESET'

export const RESET_PARAMS = 'admin/roles/RESET_PARAMS'
