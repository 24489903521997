import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { Link } from '@material-ui/icons'
import { showSnackbarInfo } from 'views/utils/snackbar'

export const ReportsWidget = ({
  title,
  pendingTicketTypesReport,
  pendingOperationsReport,
  pendingInvitationsReport,
  pendingTicketCodesReport,
  pendingRRPPSalesReport,
  pendingRRPPOnlineReport,
  pendingOrdersReport,
  onRequestOperationsReport,
  onRequestInvitationsReport,
  onRequestTicketTypesReport,
  onRequestTicketCodesReport,
  onRequestRRPPSalesReport,
  onRequestRRPPOnlineReport,
  onRequestOrdersReport
}) => {
  const message =
    'Recuerde habilitar la edición del archivo una vez descargado para visualizar los totalizadores'
  const [paymentStatus, setPaymentStatus] = useState('Approved')

  const paymentStatusArray = [
    { label: 'Aprobada', value: 'Approved' },
    { label: 'Pendiente', value: 'Pending' },
    { label: 'En Progreso', value: 'InProgress' },
    { label: 'En Mediación', value: 'InMediation' },
    { label: 'Rechazada', value: 'Rejected' },
    { label: 'Cancelada', value: 'Cancelled' },
    { label: 'Devuelta', value: 'Refunded' },
    { label: 'Contracargo', value: 'ChargedBack' }
  ]

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader title={title} />
      <Divider />
      <CardContent style={{ padding: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
          }}>
          <Typography component={'p'}>
            Tabla de detalles por tipo de ticket
          </Typography>
          <Button
            onClick={onRequestTicketTypesReport}
            variant={'outlined'}
            color={'primary'}
            disabled={pendingTicketTypesReport}
            endIcon={<Link />}>
            {pendingTicketTypesReport ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              'Exportar'
            )}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
          }}>
          <Typography component={'p'}>Ventas por día</Typography>
          <Button
            onClick={() => {
              showSnackbarInfo(message, 'Información', 5000)
              onRequestOperationsReport()
            }}
            variant={'outlined'}
            color={'primary'}
            disabled={pendingOperationsReport}
            endIcon={<Link />}>
            {pendingOperationsReport ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              'Exportar'
            )}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
          }}>
          <Typography component={'p'}>Invitaciones</Typography>
          <Button
            onClick={() => {
              showSnackbarInfo(message, 'Información', 5000)
              onRequestInvitationsReport()
            }}
            variant={'outlined'}
            color={'primary'}
            disabled={pendingInvitationsReport}
            endIcon={<Link />}>
            {pendingInvitationsReport ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              'Exportar'
            )}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
          }}>
          <Typography component={'p'}>Ventas en Efectivo por RRPP</Typography>
          <Button
            onClick={() => {
              showSnackbarInfo(message, 'Información', 5000)
              onRequestRRPPSalesReport()
            }}
            variant={'outlined'}
            color={'primary'}
            disabled={pendingRRPPSalesReport}
            endIcon={<Link />}>
            {pendingRRPPSalesReport ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              'Exportar'
            )}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
          }}>
          <Typography component={'p'}>Ventas Online por RRPP</Typography>
          <Button
            onClick={() => {
              showSnackbarInfo(message, 'Información', 5000)
              onRequestRRPPOnlineReport()
            }}
            variant={'outlined'}
            color={'primary'}
            disabled={pendingRRPPOnlineReport}
            endIcon={<Link />}>
            {pendingRRPPOnlineReport ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              'Exportar'
            )}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '.5rem'
          }}>
          <Typography component={'p'}>Códigos de Ticket</Typography>
          <Button
            onClick={onRequestTicketCodesReport}
            variant={'outlined'}
            color={'primary'}
            disabled={pendingTicketCodesReport}
            endIcon={<Link />}>
            {pendingTicketCodesReport ? (
              <CircularProgress size={24} color='primary' />
            ) : (
              'Exportar'
            )}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <Typography component={'p'}>Ordenes por estado de pago</Typography>
          <div
            style={{
              display: 'flex',
              gap: 8,
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}>
            <FormControl variant='outlined' size='small'>
              <InputLabel id='status-label' style={{ marginLeft: -2 }}>
                Estado de orden
              </InputLabel>
              <Select
                id='PaymentStatus-select'
                labelId='status-label'
                value={paymentStatus}
                style={{
                  width: 160,
                  height: 38,
                  marginBottom: 8
                }}
                onChange={(v) => {
                  setPaymentStatus(v.target.value)
                }}
                size='small'
                label='PaymentStatus'>
                {paymentStatusArray &&
                  paymentStatusArray.map((status) => (
                    <MenuItem key={status.label} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              onClick={() => onRequestOrdersReport(paymentStatus)}
              variant={'outlined'}
              color={'primary'}
              style={{ height: 38 }}
              disabled={pendingOrdersReport}
              endIcon={<Link />}>
              {pendingOrdersReport ? (
                <CircularProgress size={24} color='primary' />
              ) : (
                'Exportar'
              )}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ReportsWidget
