import * as types from './types'

const initialState = {
  pagedList: null,
  drawList: [],
  participantList: [],
  draw: null,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null,
  deliverPending: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        pending: true,
        draw: null,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        draw: action.draw,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        pending: false,
        draw: null,
        error: action.error
      }
    case types.LOAD_BY_EVENT_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        drawList: [],
        reqParams: action.reqParams
      }
    case types.LOAD_BY_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        drawList: action.drawList
      }
    case types.LOAD_BY_EVENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        drawList: []
      }
    case types.LOAD_PARTICIPANTS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        participantList: [],
        reqParams: action.reqParams
      }
    case types.LOAD_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        participantList: action.participantList
      }
    case types.LOAD_PARTICIPANTS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        participantList: []
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        draw: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        draw: action.draw,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        draw: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        draw: action.draw,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.RUN_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.RUN_SUCCESS:
      return {
        ...state,
        draw: action.draw,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.RUN_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELIVER_ATTEMPT:
      return {
        ...state,
        deliverPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELIVER_SUCCESS:
      return {
        ...state,
        draw: action.draw,
        deliverPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELIVER_FAILURE:
      return {
        ...state,
        deliverPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        // draw: action.draw,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        // draw: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
