import React from 'react'
import { useDispatch } from 'react-redux'
import { addUser } from '~/state/modules/roles'
import { UsersSearch } from '../UsersSearch'
import { showSnackbarError } from 'views/utils/snackbar'

export const UsersSearchPage = ({
  onSuccess,
  onCancel,
  roleName,
  actionPending
}) => {
  const dispatch = useDispatch()

  const onSuccessSearch = async (userId) => {
    const userToAdd = {
      userId: userId,
      role: roleName
    }
    try {
      await dispatch(addUser(userToAdd))
    } catch (error) {
      showSnackbarError(error)
    }
    onSuccess()
  }

  return (
    <UsersSearch
      loading={actionPending}
      onSuccess={onSuccessSearch}
      onCancel={onCancel}
      message={'Está por agregar el usuario'}
      secondMessage={'al rol'}
      role={roleName}
    />
  )
}

export default UsersSearchPage
