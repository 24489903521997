import React, { useEffect, useState } from 'react'
import { AppContainer } from '~/views/shared'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RolesIndexPage } from './components/RolesIndexPage'
import { UsersSearchPage } from './components/UsersSearchPage'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'

export const RolesPage = () => {
  const { pagedList, actionPending } = useSelector((state) => state.roles)
  const [roleName, setRoleName] = useState('')
  const { roleId } = useParams()

  const redirectToIndex = () => {
    history.push(ROUTES.ADMIN.ROLES.INDEX.replace(':roleId', roleId))
  }

  useEffect(() => {
    if (pagedList != null) {
      setRoleName(pagedList?.Name)
    }
  }, [pagedList])

  return (
    <AppContainer title='ROLES'>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN.ROLES.INDEX}
          render={(props) => <RolesIndexPage {...props} />}
        />

        <Route
          path={ROUTES.ADMIN.ROLES.SEARCH}
          render={(props) => (
            <UsersSearchPage
              {...props}
              onSuccess={redirectToIndex}
              onCancel={redirectToIndex}
              roleName={roleName}
              actionPending={actionPending}
            />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default RolesPage
