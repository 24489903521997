// Action Types
export const LOGIN_ATTEMPT = 'pub/login/LOGIN_ATTEMPT'
export const LOGIN_SUCCESS = 'pub/login/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'pub/login/LOGIN_FAILURE'
export const LOGIN_NEW_USER_SUCCESS = 'pub/login/LOGIN_NEW_USER_SUCCESS'

export const CREATE_USER_FROM_EXTERNAL_LOGIN_ATTEMPT =
  'pub/login/CREATE_USER_FROM_EXTERNAL_LOGIN_ATTEMPT'
export const CREATE_USER_FROM_EXTERNAL_LOGIN_SUCCESS =
  'pub/login/CREATE_USER_FROM_EXTERNAL_LOGIN_SUCCESS'
export const CREATE_USER_FROM_EXTERNAL_LOGIN_FAILURE =
  'pub/login/CREATE_USER_FROM_EXTERNAL_LOGIN_FAILURE'

export const CLOSE_SESSION = 'pub/login/CLOSE_SESSION'
export const RESTORE_SESSION = 'pub/login/RESTORE_SESSION'
