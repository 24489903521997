import BaseProxy from './BaseProxy'
import axios from 'axios'

export class RolesProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/role`
  }

  async getRoles() {
    const response = await axios.get(`${this._baseUrl}`)
    return response.data
  }

  async getUsersByRole(roleId) {
    const response = await axios.get(`${this._baseUrl}/${roleId}/users`)
    return response.data
  }

  async removeUserFromRole(userToRemove) {
    const response = await axios.put(
      `${this._baseUrl}/removeuser`,
      userToRemove
    )
    return response.data
  }

  async addUserToRole(userToAdd) {
    const response = await axios.put(`${this._baseUrl}/adduser`, userToAdd)
    return response.data
  }

  async getUsersByRolePaged(
    roleId,
    searchParams,
    pageNumber,
    pageSize,
    orderBy,
    order
  ) {
    const response = await axios.get(
      `${this._baseUrl}/${roleId}/users/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }
}

export default RolesProxy
