import * as types from './types'

const initialState = {
  commissions: null,
  pending: false,
  error: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_COMMISSIONS_ATTEMPT:
      return {
        ...state,
        pending: true,
        commissions: null,
        error: null
      }
    case types.LOAD_COMMISSIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        commissions: action.commissions,
        error: null
      }
    case types.LOAD_COMMISSIONS_FAILURE:
      return {
        ...state,
        pending: false,
        commissions: null,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
