import * as types from './types'

const initialState = {
  pagedList: null,
  reseller: null,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null,
  resellerEvent: null,
  resellerEventPaged: null,
  resellerSalesEvent: null,
  resellerTPSalesEvent: null,
  resellerTPSalesEventPaged: null,
  resellerTPSalesEventDetails: null,
  resellerCashSales: null,
  resellerCashSalesPaged: null,
  resellerCashSalesDetails: null,
  resellerSelected: 0,
  filters: { rrpp: '', searchKey: '' },
  pin: null,
  events: null,
  qr: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        pending: true,
        reseller: null,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        reseller: action.reseller,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        pending: false,
        reseller: null,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        reseller: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        reseller: action.reseller,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        reseller: null,
        actionPending: false,
        error: action.error
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        reseller: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        reseller: action.reseller,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        reseller: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }

    //RRPP

    case types.LOAD_EVENT_RESELLERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerEvent: null,
        error: null
      }
    case types.LOAD_EVENT_RESELLERS_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerEvent: action.resellerEvent,
        error: null
      }
    case types.LOAD_EVENT_RESELLERS_FAILURE:
      return {
        ...state,
        pending: false,
        resellerEvent: null,
        error: action.error
      }
    case types.LOAD_EVENT_RESELLERS_PAGED_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerEventPaged: null,
        error: null,
        reqParams: action.reqParams
      }
    case types.LOAD_EVENT_RESELLERS_PAGED_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerEventPaged: action.resellerEventPaged,
        error: null
      }
    case types.LOAD_EVENT_RESELLERS_PAGED_FAILURE:
      return {
        ...state,
        pending: false,
        resellerEventPaged: null,
        error: action.error
      }

    case types.LOAD_RESELLERS_SALES_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerSalesEvent: null,
        error: null
      }
    case types.LOAD_RESELLERS_SALES_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerSalesEvent: action.resellerSalesEvent,
        error: null
      }
    case types.LOAD_RESELLERS_SALES_FAILURE:
      return {
        ...state,
        pending: false,
        resellerSalesEvent: null,
        error: action.error
      }

    //RRPP TP

    case types.LOAD_RESELLERS_TP_SALES_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerTPSalesEvent: null,
        error: null
      }
    case types.LOAD_RESELLERS_TP_SALES_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerTPSalesEvent: action.resellerTPSalesEvent,
        error: null
      }
    case types.LOAD_RESELLERS_TP_SALES_FAILURE:
      return {
        ...state,
        pending: false,
        resellerTPSalesEvent: null,
        error: action.error
      }
    case types.LOAD_RESELLERS_TP_SALES_PAGED_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerTPSalesEventPaged: null,
        error: null,
        reqParams: action.reqParams
      }
    case types.LOAD_RESELLERS_TP_SALES_PAGED_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerTPSalesEventPaged: action.resellerTPSalesEventPaged,
        error: null
      }
    case types.LOAD_RESELLERS_TP_SALES_PAGED_FAILURE:
      return {
        ...state,
        pending: false,
        resellerTPSalesEventPaged: null,
        error: action.error
      }
    case types.LOAD_RESELLERS_TP_SALES_DETAILS_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerTPSalesEventDetails: null,
        error: null
      }
    case types.LOAD_RESELLERS_TP_SALES_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerTPSalesEventDetails: action.resellerTPSalesEventDetails,
        error: null
      }
    case types.LOAD_RESELLERS_TP_SALES_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        resellerTPSalesEventDetails: null,
        error: action.error
      }

    //RRPP CASH

    case types.LOAD_RESELLERS_CASH_SALES_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerCashSales: null,
        error: null
      }
    case types.LOAD_RESELLERS_CASH_SALES_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerCashSales: action.resellerCashSales,
        error: null
      }
    case types.LOAD_RESELLERS_CASH_SALES_FAILURE:
      return {
        ...state,
        pending: false,
        resellerCashSales: null,
        error: action.error
      }
    case types.LOAD_RESELLERS_CASH_SALES_PAGED_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerCashSalesPaged: null,
        error: null,
        reqParams: action.reqParams
      }
    case types.LOAD_RESELLERS_CASH_SALES_PAGED_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerCashSalesPaged: action.resellerCashSalesPaged,
        error: null
      }
    case types.LOAD_RESELLERS_CASH_SALES_PAGED_FAILURE:
      return {
        ...state,
        pending: false,
        resellerCashSalesPaged: null,
        error: action.error
      }

    case types.LOAD_RESELLERS_CASH_SALES_DETAILS_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellerCashSalesDetails: null,
        error: null
      }
    case types.LOAD_RESELLERS_CASH_SALES_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        resellerCashSalesDetails: action.resellerCashSalesDetails,
        error: null
      }
    case types.LOAD_RESELLERS_CASH_SALES_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        resellerCashSalesDetails: null,
        error: action.error
      }
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    case types.GENERATE_PIN_ATTEMPT:
      return {
        ...state,
        pin: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.GENERATE_PIN_SUCCESS:
      return {
        ...state,
        pin: action.pin,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.GENERATE_PIN_FAILURE:
      return {
        ...state,
        pin: null,
        actionPending: false,
        error: action.error
      }
    case types.RESET_ACTIONS:
      return {
        ...state,
        pending: false,
        actionPending: false,
        actionSuccess: false
      }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    case types.SET_RESELLER_TYPE:
      return {
        ...state,
        resellerSelected: action.resellerSelected
      }
    case types.LOAD_EVENT_WITH_TICKETS_ATTEMPT:
      return {
        ...state,
        events: null,
        pending: true,
        error: null
      }
    case types.LOAD_EVENT_WITH_TICKETS_SUCCESS:
      return {
        ...state,
        events: action.events,
        pending: false,
        error: null
      }
    case types.LOAD_EVENT_WITH_TICKETS_FAILURE:
      return {
        ...state,
        events: null,
        pending: false,
        error: action.error
      }
    case types.GENERATE_QR_ATTEMPT:
      return {
        ...state,
        qr: null,
        actionPending: true,
        error: null
      }
    case types.GENERATE_QR_SUCCESS:
      return {
        ...state,
        qr: action.qr,
        actionPending: false,
        error: null
      }
    case types.GENERATE_QR_FAILURE:
      return {
        ...state,
        qr: null,
        actionPending: false,
        error: action.error
      }
    default:
      return state
  }
}
