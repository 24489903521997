import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around'
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: 0
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.grey[700]
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'center'
  },
  textButton: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium
  },
  activationContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  activationText: {
    color: theme.palette.primary.contrastText
  }
}))

export default useStyles
