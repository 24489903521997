export const RESELLER_TYPES = {
  RESELLER: 0,
  RESELLER_TP: 1,
  RESELLER_CASH: 2
}

export const ROLES = {
  ADMIN: 'Administrator',
  PRODUCER: 'Producer',
  RESELLER: 'Reseller',
  USER: 'User',
  WITHDRAWPOINT: 'WithdrawPoint',
  FACEBOOK: ''
}

export const PAYMENT_IDS = {
  CREDIT_CARD: 1 << 0, //1
  DEBIT_CARD: 1 << 1, //2
  PREPAID_CARD: 1 << 2, //4
  TICKET: 1 << 3, //8
  ATM: 1 << 4, //16
  ACCOUNT_MONEY: 1 << 5, //32
  DIGITAL_CURRENCY: 1 << 6, //64
  BANK_TRANSFER: 1 << 7 //128
}

export const PAYMENT_TYPE_IDS = {
  1: 'Tarjeta de crédito',
  2: 'Tarjeta de débito',
  4: 'Tarjeta prepago',
  8: 'Ticket',
  16: 'Cajero automático',
  32: 'Dinero en cuenta',
  64: 'Moneda digital',
  128: 'Transferencia bancaria'
}

export const TICKET_USAGE = {
  // Venta Online
  SALE: 0,
  // Sorteo
  DRAW: 1,
  // Venta en efectivo
  CASH: 2,
  // Invitación
  INVITATION: 3,
  // Canje
  SWAP: 4,
  // Combo
  COMBO: 5,
  // Gratuito
  FREE: 6,
  // Solo Cargo por Servicio
  SERVICE_CHARGE_ONLY: 7
}

export const TICKET_RULE = {
  // Compró de cualquier evento seleccionado
  BOUGHT_FROM_ANY_EVENT: 0,
  // Compró de todos los eventos seleccionados
  BOUGHT_FROM_ALL_EVENTS: 1,
  // No compró de ningún evento
  DID_NOT_BUY_FROM_ANY_EVENT: 2,
  // Compro cualquier ticket seleccionado de un evento
  BOUGHT_ANY_TICKET_FROM_EVENT: 3
}

export const EVENT_RESELLER_TYPE = {
  BY_ORGANIZER: 0,
  BY_TICKET_PASS: 1,
  ALL: 2,
  NONE: 3
}

export const COMMISSION_TYPE = {
  PLATFORM: 0,
  PAYMENT_GATEWAY: 1,
  RESELLER: 2
}

export const COMMISSION_USED = {
  BY_PERCENT: 0,
  BY_AMOUNT: 1
}

export const ROLES_IDS = {
  ADMIN: '6687d11a-81b1-42f7-bc24-1c2ffb081111',
  PRODUCER: '0a21239b-50cc-4d36-91a3-27568b256696',
  RESELLER: 'a03ab50e-ffb7-4373-a240-31493c5c0389',
  USER: '5443462a-0411-4edc-8e2c-ba617731275f',
  WITHDRAWPOINT: '874816ff-1415-4d0b-b613-12a20700b6de'
}

export const IDENTIFICATION_TYPES = {
  0: 'DNI',
  1: 'CI',
  2: 'LC',
  3: 'LE',
  4: 'RUT',
  10: 'OTRO'
}

export const PAYMENTS_STATUS = {
  NOT_SET: 0,
  PENDING: 1,
  IN_PROGRESS: 2,
  IN_MEDIATION: 3,
  APPROVED: 4,
  REJACTED: 5,
  CANCELLED: 6,
  REFUNDED: 7,
  CHARGED_BACK: 8
}

export const ORDER_STATUS = {
  Pending: 'Pendiente',
  InProgress: 'En Progreso',
  InMediation: 'En Mediación',
  NotSet: 'En Proceso',
  Approved: 'Aprobada',
  Rejected: 'Rechazada',
  Cancelled: 'Cancelada',
  Refunded: 'Devuelta',
  ChargedBack: 'Contracargo'
}

export const ACCOUNTS = {
  NONE: 0,
  MERCADO_PAGO: 1,
  MACRO: 2,
  BOTH: 3
}

export const DOCUMENT_TYPE = {
  INVOICE: 0,
  CREDIT_NOTE: 1
}

export const TICKET_VERSION = {
  V1: 0,
  V2: 1
}
