import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      padding: theme.spacing(1)
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: theme.spacing(1),
    '& h3': {
      color: '#c4001d',
      marginTop: -4
    }
  },
  containerPreview: {
    borderStyle: 'solid',
    borderColor: '#8c8c8c',
    borderWidth: 2,
    borderRadius: 4
  },
  button: {
    position: 'absolute',
    right: 1,
    top: 1
  },
  subject: {
    display: 'flex',
    background: 'white',
    paddingLeft: theme.spacing(2),
    '& h5': {
      marginBlock: theme.spacing(1)
    }
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    fontWeight: 'normal'
  },
  greeting: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    '& h3': {
      margin: theme.spacing(1)
    }
  },
  message: {
    width: '-webkit-fill-available',
    paddingInline: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  separator: {
    backgroundColor: '#8c8c8c',
    border: 0,
    height: theme.spacing(1),
    marginTop: 0,
    marginInline: -1,
    marginBottom: theme.spacing(2)
  },
  img: {
    width: '-webkit-fill-available',
    marginTop: theme.spacing(1),
    marginInline: -1,
    marginBottom: -1
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -8,
    '& button': {
      textTransform: 'none',
      fontSize: 'small'
    }
  }
}))

export default useStyles
