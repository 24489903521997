import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Menu,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import { changeManualUpdatePaymentStatus } from '~/state/modules/orders'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { loadOrderDetails } from 'state/modules/orders'
import { useStyles } from './ChangeStateManually.style'
import { ConfirmationDialog } from '~/views/shared'
import { Save } from '@material-ui/icons'

export const ChangeStateManually = ({ order }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false)
  const [openReason, setOpenReason] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [reason, setReason] = useState('')
  const { pending } = useSelector((state) => state.orders)

  const dispatch = useDispatch()
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event) => {
    setReason(event.target.value.trim())
  }

  const manualUpdatePaymentStatusOrder = async () => {
    try {
      await dispatch(
        changeManualUpdatePaymentStatus(order.Id, paymentStatus, reason)
      )
      await dispatch(loadOrderDetails(order.Id))
      showSnackbarSuccess('La orden fue actualizada')
    } catch (err) {
      showSnackbarError(err)
    }
    setReason('')
    setAnchorEl(null)
    setOpenDialogConfirm(false)
  }

  const createMenuItem = (status, label) => {
    return (
      <MenuItem
        disabled={order?.Status === status}
        onClick={() => {
          setPaymentStatus(status)
          setOpenDialogConfirm(true)
        }}>
        {label}
      </MenuItem>
    )
  }

  return (
    <div>
      <Button
        color='primary'
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        Cambiar de estado manualmente
      </Button>
      <Menu
        id='customized-menu'
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {createMenuItem('Pending', 'Pendiente')}
        {createMenuItem('InProgress', 'En Progreso')}
        {createMenuItem('InMediation', 'En Mediación')}
        {createMenuItem('Approved', 'Aprobada')}
        {createMenuItem('Rejected', 'Rechazada')}
        {createMenuItem('Cancelled', 'Cancelada')}
        {createMenuItem('Refunded', 'Devuelta')}
        {createMenuItem('ChargedBack', 'Contracargo')}
        <div className={classes.buttonContainer}>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
        </div>
      </Menu>
      <ConfirmationDialog
        open={openDialogConfirm}
        loading={pending}
        handleConfirmation={() => {
          setOpenDialogConfirm(false)
          setOpenReason(true)
        }}
        onCancel={() => setOpenDialogConfirm(false)}
        title='¡ADVERTENCIA!'
        message='Está por cambiar el estado de esta orden. '
      />
      <Dialog
        open={openReason}
        onClose={() => {
          setReason('')
          setOpenReason(false)
        }}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent className={classes.contentDialog}>
          <TextField
            label='Motivo'
            fullWidth
            autoFocus
            inputProps={{ maxLength: 250 }}
            placeholder='Ingrese una pequeña descripción indicando causas o motivos del cambio realizado.'
            onChange={handleChange}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReason('')
              setOpenReason(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenReason(false)
              manualUpdatePaymentStatusOrder()
            }}
            color='primary'
            variant='contained'
            startIcon={<Save />}
            disabled={reason.length < 3}
            autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ChangeStateManually
