import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { useStyles } from './ResellersForm.style'

const validationSchema = Yup.object().shape({
  UserId: Yup.string().required(
    'Se debe realizar una búsqueda de usuario para obtener el UserId'
  ),
  DisplayName: Yup.string().required('Es requerido un nombre'),
  MercadoPagoEmail: Yup.string().email('Ingrese un email válido')
})

export const ResellersForm = ({
  values,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess,
  updateForm,
  generatePIN
}) => {
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                disabled={true}
                label={
                  (errors.UserId && touched.UserId && errors.UserId) || 'UserId'
                }
                inputProps={{ readOnly: true }}
                name='UserId'
                value={values.UserId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.UserId && Boolean(errors.UserId)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.DisplayName &&
                    touched.DisplayName &&
                    errors.DisplayName) ||
                  'Nombre a mostrar'
                }
                name='DisplayName'
                value={values.DisplayName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.DisplayName && Boolean(errors.DisplayName)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.BankAccountNumber &&
                    touched.BankAccountNumber &&
                    errors.BankAccountNumber) ||
                  'CBU'
                }
                name='BankAccountNumber'
                value={values.BankAccountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.BankAccountNumber && Boolean(errors.BankAccountNumber)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={(errors.Cuit && touched.Cuit && errors.Cuit) || 'CUIT'}
                name='Cuit'
                translate='no'
                value={values.Cuit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Cuit && Boolean(errors.Cuit)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.MercadoPagoEmail &&
                    touched.MercadoPagoEmail &&
                    errors.MercadoPagoEmail) ||
                  'MercadoPago Email'
                }
                name='MercadoPagoEmail'
                value={values.MercadoPagoEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.MercadoPagoEmail && Boolean(errors.MercadoPagoEmail)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                label='Mostrar en listado de ticketPass?'
                control={
                  <Checkbox
                    checked={values.SellForTicketPass}
                    onChange={handleChange}
                    name='SellForTicketPass'
                  />
                }
              />
            </div>
            {updateForm && (
              <div>
                PIN Generado?: &nbsp;
                <span className={classes.pinGeneradoText}>
                  {values.HasPINGenerated ? 'Si' : 'No'}
                </span>
              </div>
            )}

            <div className={classes.fieldRow}>
              <FormControlLabel
                label='Activo?'
                control={
                  <Checkbox
                    checked={values.Active}
                    onChange={handleChange}
                    name='Active'
                  />
                }
              />
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={onCancel}
              disabled={actionSuccess || isSubmitting}
              autoFocus={false}
              color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={actionPending || actionSuccess || isSubmitting}
              color='primary'
              autoFocus={false}
              variant='contained'
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
            {updateForm && (
              <Button
                onClick={generatePIN}
                autoFocus={false}
                disabled={!values.HasPINGenerated}
                color='primary'>
                Resetear PIN
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ResellersForm
