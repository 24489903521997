import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppContainer } from '~/views/shared'
import { ResellersCreatePage } from './ResellersCreatePage'
import { ResellersIndexPage } from './ResellersIndexPage'
import { ResellersUpdatePage } from './ResellersUpdatePage'
import { UsersSearch } from './components/UsersSearch'
import { resetActions } from '~/state/modules/resellers'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'

export const ResellersPage = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState()

  const redirectToIndex = async () => {
    setUser(null)
    await dispatch(resetActions())
    history.push(ROUTES.ADMIN.RESELLERS.INDEX)
  }

  const redirectToCreateReseller = (selectedUser) => {
    if (selectedUser != null) {
      setUser(selectedUser)
    }
    history.push(ROUTES.ADMIN.RESELLERS.CREATE)
  }

  return (
    <AppContainer title='RRPP'>
      <Switch>
        <Route
          path={ROUTES.ADMIN.RESELLERS.INDEX}
          component={ResellersIndexPage}
          exact
        />

        <Route
          path={ROUTES.ADMIN.RESELLERS.CREATE}
          render={(props) => (
            <ResellersCreatePage
              {...props}
              onSuccess={redirectToIndex}
              onCancel={redirectToIndex}
              user={user}
            />
          )}
        />

        <Route
          path={ROUTES.ADMIN.RESELLERS.UPDATE}
          render={(props) => (
            <ResellersUpdatePage {...props} onSuccess={redirectToIndex} />
          )}
        />

        <Route
          path={ROUTES.ADMIN.RESELLERS.SEARCH}
          render={(props) => (
            <UsersSearch
              {...props}
              onSuccess={redirectToCreateReseller}
              onCancel={redirectToCreateReseller}
            />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default ResellersPage
