import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Save } from '@material-ui/icons'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField
} from '@material-ui/core'
import { useStyles } from '../EventTicketForm.style'

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Es requerido ingresar nombre del ticket')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  TicketUsage: Yup.number(),
  Remaining: Yup.number()
    .required('Es requerido ingresar cantidad de tickets')
    .integer('Solo se permiten valores enteros')
    .min(0, 'Cantidad no puede ser menor a 0')
    .max(999999999, 'Excedió cantidad máxima de dígitos permitidos')
})

export const DrawSwapComboForm = ({
  values,
  actionText,
  handleSubmit,
  onCancel,
  drawFinalized,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const [state] = useState({
    checkedC: !values.DisabledDatesForm,
    checkedD: false
  })

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del ticket (visible al usuario)'
                }
                name='Name'
                disabled={drawFinalized}
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
                autoFocus
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Remaining && touched.Remaining && errors.Remaining) ||
                  'Cantidad'
                }
                name='Remaining'
                type='number'
                value={values.Remaining}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Remaining && Boolean(errors.Remaining)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsTransferable}
                    onChange={handleChange}
                    name='IsTransferable'
                  />
                }
                label='Es transferible?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Active}
                    onChange={handleChange}
                    name='Active'
                  />
                }
                label='Habilitado?'
              />
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={actionSuccess} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                values.DisabledDatesForm = state.checkedC
                values.PaymentGatewayCommission = 0
                values.Commissions = []
                handleSubmit()
              }}
              disabled={actionPending || actionSuccess}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default DrawSwapComboForm
