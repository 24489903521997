import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { loadTicket } from 'state/modules/events'
import * as moment from 'moment'
import { Save } from '@material-ui/icons'
import ShareIcon from '@material-ui/icons/Share'
import { useStyles } from './InvitationForm.style'

const validationSchema = (documentId) =>
  Yup.object().shape({
    Name: Yup.string()
      .required('El nombre es requerido')
      .max(250, 'La cantidad máxima es de 250 caracteres'),
    DNI: Yup.number().required(`El ${documentId} es requerido`),
    Email: Yup.string()
      .required('El email es requerido')
      .max(250, 'La cantidad máxima es de 250 caracteres')
      .email('Ingrese un email válido'),
    Units: Yup.number()
      .min(1, 'La cantidad no puede ser menor a 1')
      .integer('La cantidad no puede ser un numero decimal'),
    TicketTypeId: Yup.string().required(
      'Es requerido un ticket de tipo invitacion'
    )
  })

export const InvitationForm = ({
  event,
  invitations,
  values,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  valuesInvitations,
  copyToClipBoard,
  handleCloseConfirmation
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const dateCompared = moment()
    .subtract(3, 'hours')
    .isSameOrAfter(event?.SalesEndDateTime)

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema(documentId)}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                disabled={true}
                label={errors.Title && touched.Title && errors.Title}
                inputProps={{ readOnly: true }}
                name='Title'
                value={values.Title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Title && Boolean(errors.Title)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl variant='outlined' className={classes.fieldRow}>
              <InputLabel required className={classes.inputLabel}>
                {(errors.TicketTypeId &&
                  touched.TicketTypeId &&
                  errors.TicketTypeId) ||
                  'Selecciona un Ticket'}
              </InputLabel>
              <Select
                name='TicketTypeId'
                value={values?.TicketTypeId}
                onChange={handleChange}
                className={classes.field}
                fullWidth
                required
                size='small'
                label='Selecciona un Ticket'>
                {invitations &&
                  invitations.map((ticket) => (
                    <MenuItem key={ticket.Id} value={ticket.Id}>
                      {ticket.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className={classes.fieldRow}>
              <TextField
                label={(errors.Name && touched.Name && errors.Name) || 'Nombre'}
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                type='number'
                pattern='^[0-9]+'
                label={(errors.DNI && touched.DNI && errors.DNI) || documentId}
                name='DNI'
                value={values.DNI}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.DNI && Boolean(errors.DNI)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Email && touched.Email && errors.Email) || 'Email'
                }
                name='Email'
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Email && Boolean(errors.Email)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                min='1'
                pattern='^[0-9]+'
                label={
                  (errors.Units && touched.Units && errors.Units) ||
                  'Cantidad de entradas'
                }
                name='Units'
                type='number'
                value={values.Units}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Units && Boolean(errors.Units)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>
          <div className={classes.containerButtons}>
            <Button
              onClick={async (e) => {
                await dispatch(loadTicket(event.Id, values.TicketTypeId))
                handleSubmit(e)
              }}
              disabled={
                actionPending ||
                isSubmitting ||
                valuesInvitations ||
                dateCompared
              }
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
            <Button
              startIcon={<ShareIcon />}
              onClick={() => copyToClipBoard(values)}
              disabled={!valuesInvitations}
              variant='contained'
              color='primary'>
              Compartir Instrucciones
            </Button>
            <Button
              onClick={() => {
                handleCloseConfirmation()
              }}
              type='reset'
              disabled={!valuesInvitations}
              variant='contained'
              color='primary'>
              Nueva Invitación
            </Button>
            <Button onClick={onCancel} disabled={isSubmitting} color='primary'>
              Cancelar
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default InvitationForm
