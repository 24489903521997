import React from 'react'
import { useDispatch } from 'react-redux'
import { resetActions } from 'state/modules/locations'
import { AppContainer } from '~/views/shared'
import { LocationIndexPage } from './LocationIndexPage'
import { LocationCreatePage } from './LocationCreatePage'
import { LocationUpdatePage } from './LocationUpdatePage'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'

export const LocationsPage = () => {
  const dispatch = useDispatch()

  const handleSuccessfulSubmit = async () => {
    await dispatch(resetActions())
    history.push(ROUTES.ADMIN.LOCATIONS.INDEX)
  }

  return (
    <AppContainer title='Lugares'>
      <Switch>
        <Route
          path={ROUTES.ADMIN.LOCATIONS.INDEX}
          component={LocationIndexPage}
          exact
        />

        <Route
          path={ROUTES.ADMIN.LOCATIONS.CREATE}
          render={(props) => (
            <LocationCreatePage {...props} onSuccess={handleSuccessfulSubmit} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.LOCATIONS.UPDATE}
          render={(props) => (
            <LocationUpdatePage {...props} onSuccess={handleSuccessfulSubmit} />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default LocationsPage
