import * as types from './types'
import { CacheProxy } from '~/services'

export const clearProfileAttempt = () => ({
  type: types.CLEAR_PROFILE_ATTEMPT
})

export const clearProfileSuccess = (response) => ({
  type: types.CLEAR_PROFILE_SUCCESS,
  response
})

export const clearProfileFailure = (error) => ({
  type: types.CLEAR_PROFILE_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const clearProfileCache = (userId) => async (dispatch) => {
  try {
    dispatch(clearProfileAttempt())

    const proxy = new CacheProxy()
    const response = await proxy.clearProfileCache(userId)

    dispatch(clearProfileSuccess(response))
  } catch (error) {
    dispatch(clearProfileFailure(error))
    throw error
  }
}
