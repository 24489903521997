import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  field: {
    margin: theme.spacing()
  },
  buttons: {
    margin: theme.spacing(1)
  },
  fieldEditorText: {
    margin: theme.spacing(),
    width: '100%',
    borderRadius: 5,
    fontFamily: 'sans-serif',
    minHeight: 160,
    lineHeight: 1.2
  },
  fieldEditorTextError: {
    border: '1px solid red'
  },
  containerCheckbox: {
    marginLeft: theme.spacing(1)
  },
  inputLabel: {
    marginLeft: theme.spacing(2),
    marginBottom: -6
  },
  inputLabelError: {
    color: 'red'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  }
}))

export default useStyles
