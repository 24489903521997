import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROLES } from 'config/constants'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  Avatar,
  Divider,
  List,
  ListItem,
  Button,
  Typography,
  Menu,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core'
import {
  AccountCircle,
  Event,
  Home,
  Map,
  MoreHoriz,
  AccountCircleOutlined,
  SupervisorAccount,
  CloudDownload,
  RecentActors,
  Send,
  Wc,
  AttachMoney,
  ContactSupport,
  AccountBalance
} from '@material-ui/icons'
import { loadRoles, loadUsersByRolePaged } from '~/state/modules/roles'
import { resetFilters, resetParamsDashboard } from 'state/modules/dashboard'
import { resetUsers } from 'state/modules/users'
import { resetParamsEvents } from 'state/modules/events'
import { resetParamsLocations } from 'state/modules/locations'
import { resetParamsOrganizers } from 'state/modules/organizers'
import { resetParamsResellers } from 'state/modules/resellers'
import { resetParamsRoles } from 'state/modules/roles'
import { TableGridOrder } from '~/views/shared'
import { ROUTES } from '~/views/routes'
import { useStyles } from './AppDrawer.style'
import history from 'config/history'

const items = [
  {
    to: ROUTES.ADMIN.EVENTS.INDEX,
    label: 'Eventos',
    icon: <Event />,
    location: '/events'
  },
  {
    to: ROUTES.ADMIN.LOCATIONS.INDEX,
    label: 'Lugares',
    icon: <Map />,
    location: '/locations'
  },
  {
    to: ROUTES.ADMIN.ORGANIZERS.INDEX,
    label: 'Organizadores',
    icon: <SupervisorAccount />,
    location: '/organizers'
  },
  {
    to: ROUTES.ADMIN.RESELLERS.INDEX,
    label: 'RRPP',
    icon: <Wc />,
    location: '/resellers'
  },
  {
    to: ROUTES.ADMIN.ACCOUNTS.INDEX,
    label: 'Cuentas',
    icon: <AccountBalance />,
    location: '/accounts'
  },
  {
    to: ROUTES.ADMIN.USERS.INDEX,
    label: 'Usuarios',
    icon: <AccountCircleOutlined />,
    location: '/users'
  }
]

const subItems = [
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.ALL,
    label: 'Enviar a todos (Push)'
  },
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.EVENT,
    label: 'Enviar por evento y estado de pago (Push)'
  },
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.TICKET,
    label: 'Enviar por evento, estado de pago y ticket (Push)'
  },
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.CUSTOM_ALL,
    label: 'Enviar email personalizado a todos'
  },
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.PRODUCERS,
    label: 'Enviar email personalizado a los productores'
  },
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.CUSTOM,
    label: 'Enviar email personalizado por usuarios'
  },
  {
    to: ROUTES.ADMIN.NOTIFICATIONS.EVENTS,
    label: 'Enviar email personalizado por eventos'
  }
]

const subItemsSupport = [
  {
    to: ROUTES.ADMIN.SUPPORT.ORDERS.INDEX,
    label: 'Búsqueda de orden'
  }
]

export const AppDrawer = ({ isOpen }) => {
  const { user } = useSelector((state) => state.login)
  const { profile } = useSelector((state) => state.profiles)
  const { pendingPagedList, roles } = useSelector((state) => state.roles)
  const { roleId: currentRole } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const userName = user?.name || ''
  const picture = profile?.User?.Profile?.Picture || ''
  const rolesArray = user?.roles?.split(',') || []
  const role = rolesArray[0]
  const [anchorEl, setAnchorEl] = useState(false)
  const [anchorElRole, setAnchorElRole] = useState(false)
  const [anchorElSupport, setAnchorElSupport] = useState(false)
  const [open, setOpen] = useState(false)
  const [openRole, setOpenRole] = useState(false)
  const [openSupport, setOpenSupport] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    const arrayRoles = user?.roles?.split(',')
    if (arrayRoles?.includes(ROLES.ADMIN)) {
      async function fetchData() {
        await dispatch(loadRoles())
      }

      fetchData().then()
    }
  }, [dispatch, user])

  const handleClickRole = (event) => {
    setAnchorElRole(event.currentTarget)
    dispatch(resetParamsRoles())
  }

  const redirectIndexRole = async (roleId) => {
    setOpenRole(false)
    if (currentRole !== roleId) {
      await dispatch(
        loadUsersByRolePaged(roleId, {
          searchParams: '',
          pageNumber: 0,
          pageSize: 5,
          orderBy: 'UserName',
          order: TableGridOrder.ASC
        })
      )
      history.push(ROUTES.ADMIN.ROLES.INDEX.replace(':roleId', roleId))
    }
  }

  const handleClick = (event) => {
    setOpen(!open)
    setAnchorEl(event.currentTarget)
  }

  const handleClickSupport = (event) => {
    setOpenSupport(!openSupport)
    setAnchorElSupport(event.currentTarget)
  }

  const cleanFilters = (label) => {
    switch (label) {
      case 'Usuarios':
        dispatch(resetUsers())
        break
      case 'Eventos':
        dispatch(resetParamsEvents())
        break
      case 'Lugares':
        dispatch(resetParamsLocations())
        break
      case 'Organizadores':
        dispatch(resetParamsOrganizers())
        break
      case 'RRPP':
        dispatch(resetParamsResellers())
        break
      default:
        break
    }
  }

  return (
    <>
      <nav className={classes.drawer}>
        <List>
          <ListItem
            style={{ cursor: 'pointer' }}
            component={Link}
            to={ROUTES.ADMIN.PROFILE}>
            <ListItemAvatar>
              <Avatar alt={userName} src={picture}>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              classes={{
                primary: classes.primaryText
              }}
              primary={userName}
              secondary={
                <span className={classes.roles}>
                  <span>
                    {role}
                    {rolesArray.length > 1 && (
                      <Tooltip
                        title={'Roles: ' + rolesArray.join(', ')}
                        interactive>
                        <MoreHoriz fontSize={'small'} />
                      </Tooltip>
                    )}
                  </span>
                  <span
                    component={Link}
                    href={ROUTES.ADMIN.PROFILE}
                    className={classes.profile}>
                    MI PERFIL
                  </span>
                </span>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          {(rolesArray?.includes(ROLES.ADMIN) ||
            rolesArray?.includes(ROLES.PRODUCER)) && (
            <ListItem
              button
              component={Link}
              onClick={() => {
                dispatch(resetFilters())
                dispatch(resetParamsDashboard())
              }}
              to={ROUTES.ADMIN.DASHBOARD.INDEX}
              key={ROUTES.ADMIN.DASHBOARD.INDEX}
              className={`${
                pathname.startsWith('/dashboard')
                  ? classes.menuItem && classes.activeItem
                  : classes.menuItem
              }`}>
              <ListItemIcon className={classes.menuIcon}>
                <Home />
              </ListItemIcon>
              {isOpen && <ListItemText primary={'Dashboard'} />}
            </ListItem>
          )}
          {rolesArray?.includes(ROLES.ADMIN) ? (
            items.map((item, i) => (
              <ListItem
                button
                component={Link}
                onClick={() => cleanFilters(item.label)}
                to={item.to}
                disabled={item.disabled}
                key={i}
                className={`${
                  pathname.startsWith(item.location)
                    ? classes.menuItem && classes.activeItem
                    : classes.menuItem
                }`}>
                <ListItemIcon className={classes.menuIcon}>
                  {item.icon}
                </ListItemIcon>
                {isOpen && <ListItemText translate='no' primary={item.label} />}
              </ListItem>
            ))
          ) : (
            <ListItem
              button
              component={Link}
              to={ROUTES.ADMIN.EVENTS.INDEX}
              key={ROUTES.ADMIN.EVENTS.INDEX}
              className={`${
                pathname.startsWith('/events')
                  ? classes.menuItem && classes.activeItem
                  : classes.menuItem
              }`}>
              <ListItemIcon className={classes.menuIcon}>
                <Event />
              </ListItemIcon>
              {isOpen && <ListItemText primary={'Eventos'} />}
            </ListItem>
          )}
          {(rolesArray?.includes(ROLES.RESELLER) ||
            rolesArray?.includes(ROLES.PRODUCER)) && (
            <ListItem
              button
              component={Link}
              to={ROUTES.ADMIN.SELL.INDEX}
              key={ROUTES.ADMIN.SELL.INDEX}
              className={`${
                pathname.startsWith('/sellForCash')
                  ? classes.menuItem && classes.activeItem
                  : classes.menuItem
              }`}>
              <ListItemIcon className={classes.menuIcon}>
                <AttachMoney />
              </ListItemIcon>
              {isOpen && <ListItemText primary={'Vender en Efectivo'} />}
            </ListItem>
          )}
          {rolesArray?.includes(ROLES.ADMIN) && (
            <>
              <ListItem
                button
                onClick={(e) => {
                  handleClickRole(e)
                  setOpenRole(true)
                }}
                className={`${
                  pathname.startsWith('/role')
                    ? classes.menuItem && classes.activeItem
                    : classes.menuItem
                }`}>
                <ListItemIcon className={classes.menuIcon}>
                  <RecentActors />
                </ListItemIcon>
                <ListItemText primary='Roles' />
              </ListItem>
              <ListItem
                button
                onClick={handleClick}
                className={`${
                  pathname.startsWith('/notifications')
                    ? classes.menuItem && classes.activeItem
                    : classes.menuItem
                }`}>
                <ListItemIcon className={classes.menuIcon}>
                  <Send />
                </ListItemIcon>
                <ListItemText primary='Notificaciones' />
              </ListItem>
              <ListItem
                button
                onClick={handleClickSupport}
                className={`${
                  pathname.startsWith('/support')
                    ? classes.menuItem && classes.activeItem
                    : classes.menuItem
                }`}>
                <ListItemIcon className={classes.menuIcon}>
                  <ContactSupport />
                </ListItemIcon>
                <ListItemText primary='Soporte' />
              </ListItem>
            </>
          )}
          {rolesArray?.includes(ROLES.PRODUCER) && rolesArray?.length === 1 && (
            <ListItem
              button
              component={Link}
              to={ROUTES.ADMIN.PRODUCERS.INDEX}
              className={`${
                pathname.startsWith('/producers')
                  ? classes.menuItem && classes.activeItem
                  : classes.menuItem
              }`}>
              <ListItemIcon className={classes.menuIcon}>
                <SupervisorAccount />
              </ListItemIcon>
              {isOpen && <ListItemText primary={'Mis Productoras'} />}
            </ListItem>
          )}
          <Menu
            id='simple-menu'
            anchorEl={anchorElRole}
            keepMounted
            open={openRole}
            onClose={() => setOpenRole(false)}
            className={classes.subItems}>
            <List component='div' disablePadding>
              {roles
                ?.filter((r) => r.Name !== 'GoogleSpreadSheets')
                .map((item) => (
                  <ListItem
                    button
                    onClick={() => redirectIndexRole(item?.Id)}
                    disabled={pendingPagedList}
                    key={item?.Id}>
                    {isOpen && <ListItemText primary={item?.Name} />}
                  </ListItem>
                ))}
            </List>
          </Menu>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClick}
            className={classes.subItems}>
            <List component='div' disablePadding>
              {subItems.map((item) => (
                <ListItem
                  button
                  component={Link}
                  to={item.to}
                  disabled={item.disabled}
                  key={item.to}>
                  {isOpen && <ListItemText primary={item.label} />}
                </ListItem>
              ))}
            </List>
          </Menu>
          <Menu
            id='simple-menu'
            anchorEl={anchorElSupport}
            keepMounted
            open={openSupport}
            onClose={handleClickSupport}
            className={classes.subItemsSupport}>
            <List component='div' disablePadding>
              {subItemsSupport.map((item) => (
                <ListItem
                  button
                  component={Link}
                  to={item.to}
                  disabled={item.disabled}
                  key={item.to}>
                  {isOpen && <ListItemText primary={item.label} />}
                </ListItem>
              ))}
            </List>
          </Menu>
        </List>
        <Divider />
      </nav>
      <Button
        className={classes.button}
        href={
          'https://play.google.com/store/apps/details?id=manager.ticketpass.com.ar'
        }
        target='_blank'>
        <CloudDownload />
        <Typography
          component='span'
          variant='h6'
          className={classes.textButton}
          color='inherit'>
          Descargar App <br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;Manager
        </Typography>
      </Button>
    </>
  )
}

export default AppDrawer
