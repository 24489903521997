import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core'
import { useStyles } from './SearchOrderComponent.style'
import { reset, setFiltersSearch } from '~/state/modules/orders'
import * as moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { isNotIntegerNumber } from 'views/utils/functions'
import { PAYMENTS_STATUS } from 'config/constants'
import { showSnackbarError } from 'views/utils/snackbar'
import { Search, DeleteOutlined, AttachMoney } from '@material-ui/icons'

export const SearchOrderComponent = ({ performSearch, pending }) => {
  const dispatch = useDispatch()
  const { filtersSearch } = useSelector((state) => state.orders)
  const [startCalendarOpen, setStartCalendarOpen] = useState(false)
  const [mpId, setMpId] = useState(filtersSearch?.MercadoPagoId)
  const [orderId, setOrderId] = useState(filtersSearch?.OrderId)
  const [startDate, setStartDate] = useState(filtersSearch?.StartDate)
  const [endDate, setEndDate] = useState(filtersSearch?.EndDate)
  const [endCalendarOpen, setEndCalendarOpen] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(
    filtersSearch?.PaymentStatus
  )
  const [minPrice, setMinPrice] = useState(filtersSearch?.MinPrice)
  const [maxPrice, setMaxPrice] = useState(filtersSearch?.MaxPrice)
  const classes = useStyles()

  const handledKeyPress = (event, value) => {
    if (event.key === 'Enter' && value.length) {
      handlePerformSearch()
    }
  }

  const clear = () => {
    dispatch(reset())
    setMpId('')
    setOrderId('')
    setStartDate(moment().subtract(1, 'month').subtract(3, 'hours'))
    setEndDate(moment().subtract(3, 'hours'))
    setMinPrice('')
    setPaymentStatus(PAYMENTS_STATUS.APPROVED)
    setMaxPrice('')
  }

  const handleChangeStatus = (e) => {
    setPaymentStatus(e.target.value)
  }

  const isValidOrderId = () => {
    const guidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

    if (orderId == null || orderId.trim() === '') {
      return true
    }

    return guidRegex.test(orderId.trim())
  }

  const adjustedStartDate = () => {
    // Ajusta la fecha de inicio para que sea siempre 00:00:00
    return moment(startDate).startOf('day')
  }

  const adjustedEndDate = () => {
    // Ajusta la fecha de finalización para que sea siempre 23:59:59,
    // a menos que sea la fecha actual, en cuyo caso usa la hora actual
    const currentDate = moment().subtract(3, 'hours')
    return moment(endDate).isSame(currentDate, 'day')
      ? currentDate
      : moment(endDate).endOf('day')
  }

  const isValidPrice = (value) => {
    if (value == null || value === '') {
      setMinPrice('')
      setMaxPrice('')
      return true
    }
    return !isNaN(value)
  }

  const handlePerformSearch = () => {
    if (!isValidOrderId()) {
      showSnackbarError('Id de orden inválido!')
      return
    }

    if (!isValidPrice(minPrice) || !isValidPrice(maxPrice)) {
      showSnackbarError('Debe ingresar un número!')
      return
    }

    const payload = {
      MercadoPagoId: mpId,
      OrderId: orderId.trim(),
      PaymentStatus: paymentStatus,
      StartDate: adjustedStartDate().toISOString(),
      EndDate: adjustedEndDate().toISOString(),
      MinPrice: minPrice,
      MaxPrice: maxPrice
    }

    dispatch(setFiltersSearch(payload))

    performSearch(payload)
  }

  const paymentStatusArray = [
    { label: 'Pendiente', value: 1 },
    { label: 'En Progreso', value: 2 },
    { label: 'En Mediación', value: 3 },
    { label: 'Aprobada', value: 4 },
    { label: 'Rechazada', value: 5 },
    { label: 'Cancelada', value: 6 },
    { label: 'Devuelta', value: 7 },
    { label: 'Contracargo', value: 8 }
  ]

  return (
    <div className={classes.form}>
      <TextField
        label='Número de operación'
        name='mpId'
        className={classes.mpIdField}
        value={mpId}
        autoComplete='off'
        onChange={(e) => {
          setOrderId('')
          setMpId(e.target.value)
        }}
        inputProps={{ maxLength: 12 }}
        onKeyPress={(e) => handledKeyPress(e, e.target.value)}
        onKeyDown={(e) => isNotIntegerNumber(e)}
        size='small'
        variant='outlined'
      />
      <TextField
        label='Id de la orden'
        name='orderId'
        className={classes.orderIdField}
        value={orderId}
        autoComplete='off'
        onChange={(e) => {
          setMpId('')
          setOrderId(e.target.value)
        }}
        inputProps={{ maxLength: 40 }}
        onKeyPress={(e) => handledKeyPress(e, e.target.value)}
        size='small'
        variant='outlined'
      />
      <FormControl variant='outlined' className={classes.paymentStatus}>
        <InputLabel className={classes.inputLabel}>
          Seleccione un estado
        </InputLabel>
        <Select
          name='PaymentStatus'
          value={paymentStatus}
          onChange={handleChangeStatus}
          className={classes.field}
          fullWidth
          size='small'
          label='Selecciona un estado'>
          {paymentStatusArray &&
            paymentStatusArray.map((status) => (
              <MenuItem
                key={status.value}
                value={status.value}
                className={classes.menuItem}>
                {status.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div className={classes.datesContainer}>
        <DatePicker
          locale='es'
          id='StartDate'
          format='DD/MM/YYYY'
          name='StartDate'
          className={classes.dateField}
          value={startDate}
          label='Fecha Inicio'
          open={startCalendarOpen}
          onOpen={() => setStartCalendarOpen(true)}
          onClose={() => setStartCalendarOpen(false)}
          onChange={(value) => {
            setStartDate(value)
            setStartCalendarOpen(false)
          }}
          disableToolbar
          size='small'
          variant='inline'
          InputLabelProps={{
            shrink: true
          }}
          animateYearScrolling
        />
        <DatePicker
          locale='es'
          id='EndDate'
          format='DD/MM/YYYY'
          name='EndDate'
          className={classes.dateField}
          value={endDate}
          label='Fecha Fin'
          open={endCalendarOpen}
          onOpen={() => setEndCalendarOpen(true)}
          onClose={() => setEndCalendarOpen(false)}
          onChange={(value) => {
            setEndDate(value)
            setEndCalendarOpen(false)
          }}
          disableToolbar
          size='small'
          variant='inline'
          InputLabelProps={{
            shrink: true
          }}
          animateYearScrolling
        />
      </div>
      <div className={classes.pricesContainer}>
        <TextField
          label='precio mínimo'
          name='minPrice'
          className={classes.priceField}
          value={minPrice}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <AttachMoney fontSize='small' className={classes.priceIcon} />
              </InputAdornment>
            )
          }}
          autoComplete='off'
          onChange={(e) => setMinPrice(e.target.value)}
          inputProps={{ maxLength: 12 }}
          onKeyDown={(e) => isNotIntegerNumber(e)}
          size='small'
          variant='outlined'
        />
        <TextField
          label='precio máximo'
          name='maxPrice'
          className={classes.priceField}
          value={maxPrice}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <AttachMoney fontSize='small' className={classes.priceIcon} />
              </InputAdornment>
            )
          }}
          autoComplete='off'
          onChange={(e) => setMaxPrice(e.target.value)}
          inputProps={{ maxLength: 12 }}
          onKeyDown={(e) => isNotIntegerNumber(e)}
          size='small'
          variant='outlined'
        />
      </div>
      <Button
        variant='contained'
        onClick={clear}
        className={classes.button}
        disabled={pending}
        startIcon={<DeleteOutlined />}>
        Limpiar
      </Button>
      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={handlePerformSearch}
        disabled={pending}
        startIcon={<Search />}>
        {pending ? <CircularProgress size={24} color='secondary' /> : 'Buscar'}
      </Button>
    </div>
  )
}
export default SearchOrderComponent
