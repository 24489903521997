import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  containerDelete: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e4e7e7 !important'
    }
  },
  containerList: {
    width: '100%',
    marginLeft: 10
  },
  delete: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    display: 'block',
    fontSize: 'medium'
  },
  labelSubtitle: {
    color: 'gray'
  },
  separator: {
    backgroundColor: '#ff1744',
    border: 0,
    height: 1,
    marginTop: theme.spacing(1)
  },
  title: {
    marginTop: 5
  },
  ticketsNotFound: {
    textAlign: 'center'
  }
}))

export default useStyles
