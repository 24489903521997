import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loadUsers } from '~/state/modules/users'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer, SearchUserComponent } from '~views/shared'
import { useStyles } from './UsersSearch.style'
import { UsersList } from '../../components/UsersList'

export const UsersSearch = ({ onSuccess: onSuccessSearch, onCancel }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { users, pendingUsers } = useSelector((state) => state.users)
  const [listUsers, setListUsers] = useState(users)

  const onSuccess = (user) => {
    onSuccessSearch(user, userId)
  }

  const performSearch = async (payload) => {
    const { SearchPattern } = payload
    if (SearchPattern.length >= 3) {
      setSearchValue(SearchPattern)
    }
    await dispatch(loadUsers(payload))
  }

  useEffect(() => {
    setListUsers(users)
  }, [dispatch, users])

  return (
    <PaperContainer
      title={
        <div className={classes.title}>
          <Typography variant='h5'>Usuarios</Typography>
        </div>
      }>
      <div className={classes.titleContainer}>
        <SearchUserComponent
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder='Ingresar datos...'
          pending={pendingUsers}
        />
      </div>
      {pendingUsers ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {listUsers?.map((user, i) => (
            <UsersList onSuccess={onSuccess} user={user} key={i} />
          ))}
          <div className={classes.actionsContainer}>
            <Button onClick={() => onCancel(null, userId)} color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default UsersSearch
