import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing()
  },
  containerCard: {
    marginBlock: 10,
    width: '100%',
    boxShadow: ' 0 3px 3px 0 rgba(0,0,0,0.2)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F3F4'
    }
  },
  typography: {
    color: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 10
  },
  containerDates: {
    paddingLeft: 10
  },
  typographyTitle: {
    backgroundColor: '#D3D3D3'
  },
  containerUsers: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: '15px',
    marginBottom: '15px'
  },
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '-webkit-fill-available'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 240
  },
  textValue: {
    fontWeight: 'bold',
    [theme.breakpoints.up(1380)]: {
      marginRight: '-150px'
    }
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '15px'
  },
  containerButtonLeft: {
    display: 'flex',
    justifyContent: 'center',
    width: '70%'
  },
  containerButtonRight: {
    display: 'flex',
    justifyContent: 'center',
    width: '40%'
  }
}))

export default useStyles
