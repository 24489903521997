export const LOAD_ALL_COLLECTIONS_ATTEMPT =
  'admin/billing/LOAD_ALL_COLLECTIONS_ATTEMPT'
export const LOAD_ALL_COLLECTIONS_SUCCESS =
  'admin/billing/LOAD_ALL_COLLECTIONS_SUCCESS'
export const LOAD_ALL_COLLECTIONS_FAILURE =
  'admin/billing/LOAD_ALL_COLLECTIONS_FAILURE'

export const LOAD_INVOICE_BOOKS_ATTEMPT =
  'admin/billing/LOAD_INVOICE_BOOKS_ATTEMPT'
export const LOAD_INVOICE_BOOKS_SUCCESS =
  'admin/billing/LOAD_INVOICE_BOOKS_SUCCESS'
export const LOAD_INVOICE_BOOKS_FAILURE =
  'admin/billing/LOAD_INVOICE_BOOKS_FAILURE'

export const LOAD_CREDIT_NOTE_BOOKLETS_ATTEMPT =
  'admin/billing/LOAD_CREDIT_NOTE_BOOKLETS_ATTEMPT'
export const LOAD_CREDIT_NOTE_BOOKLETS_SUCCESS =
  'admin/billing/LOAD_CREDIT_NOTE_BOOKLETS_SUCCESS'
export const LOAD_CREDIT_NOTE_BOOKLETS_FAILURE =
  'admin/billing/LOAD_CREDIT_NOTE_BOOKLETS_FAILURE'
