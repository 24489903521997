import React from 'react'
import { useStyles } from './EventStatisticsTicketList.style'
import { Typography } from '@material-ui/core'
import numeral from 'numeral'

export const EventStatisticsTicketList = ({ ticket, isNotWithdrawpoint }) => {
  const classes = useStyles()

  const createLabel = (label, value, formatted = true) => {
    return (
      <div className={classes.labelContainer}>
        <Typography variant='subtitle2' className={classes.label}>
          {label}:&nbsp;
          <Typography variant='subtitle2' component='span'>
            {formatted ? numeral(value).format('$0.00') : value}
          </Typography>
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography
            variant='subtitle1'
            color='primary'
            className={classes.title}>
            {ticket?.TicketName}
          </Typography>
        </div>
        <div className={classes.labelsInline}>
          {createLabel('Total', ticket?.TotalTickets, false)}
          {createLabel('Retiradas', ticket?.TotalWithdrawals, false)}
        </div>
        {isNotWithdrawpoint && (
          <>
            {createLabel('Vendidas', ticket?.TotalSales, false)}
            <div className={classes.labelsInline}>
              {createLabel('Precio Final', ticket?.TicketPrice)}
              {createLabel('Se rinde a', ticket?.TicketCost)}
            </div>
            <div className={classes.labelsInline}>
              {createLabel(
                'Fact. Bruta',
                ticket?.TotalProfit + ticket?.TotalLossDueToChargeBacks
              )}
              {createLabel('Fact. Neta', ticket?.TotalProfit)}
            </div>
            <div className={classes.labelsInline}>
              {createLabel('Contracargos', ticket?.TotalChargeBacks, false)}
              {createLabel(
                'Costo x Contracargos',
                ticket?.TotalLossDueToChargeBacks
              )}
            </div>
          </>
        )}
        <hr className={classes.separator} />
      </div>
    </div>
  )
}

export default EventStatisticsTicketList
