import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  conditionalRow: {
    backgroundColor: '#ffcccc',
    '&:hover': {
      backgroundColor: '#fcb0b1 !important'
    }
  },
  form: {
    display: 'flex'
  },
  input: {
    flexGrow: 1
  },
  badge: {
    [`& span`]: {
      paddingBlock: '11px',
      paddingInline: '8px',
      borderRadius: '20px',
      fontSize: 'small',
      backgroundColor: 'cadetblue'
    }
  },
  DrawsQuantity: {
    marginLeft: 25,
    cursor: 'pointer'
  },
  typography: {
    fontSize: 'x-small',
    backgroundColor: 'red',
    color: 'white',
    paddingInline: '6px',
    paddingBlock: '4px',
    borderRadius: '18px'
  }
}))

export default useStyles
