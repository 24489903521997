import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ROLES } from 'config/constants'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { useStyles } from './OrganizerProducersPage.style'
import { OrganizerProducersList } from '../components/OrganizerProducersList'
import { SearchComponent } from '~views/shared'
import { loadOrganizer } from 'state/modules/organizers'
import { ROUTES } from '~/views/routes'
import { Add } from '@material-ui/icons'
import history from '~/config/history'

export const OrganizerProducersPage = ({ match, onSuccess }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [producersFilter, setProducersFilter] = useState()
  const { user } = useSelector((state) => state.login)
  const rolesArray = user?.roles?.split(',') || []
  const { organizer, producers, pending } = useSelector(
    (state) => state.organizers
  )

  const performSearch = (searchKey) => {
    setSearchValue(searchKey)
    setProducersFilter(
      producers.filter(
        (c) =>
          c.FullName.toUpperCase().includes(searchKey.toUpperCase()) ||
          c.DNI?.includes(searchKey) ||
          c.UserName?.toUpperCase().includes(searchKey.toUpperCase()) ||
          c.Email?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(match.params.id))
    }
    fetchData().then()

    setProducersFilter(producers)
  }, [dispatch, match, producers, setProducersFilter])

  useEffect(() => {
    onSuccess(match.params.id)
  }, [match, onSuccess])

  const handleBack = () => {
    history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)
  }

  const handleBackProducers = () => {
    history.push(ROUTES.ADMIN.PRODUCERS.INDEX)
  }

  return (
    <PaperContainer
      title={
        <div className={classes.title}>
          <Typography variant='h5'>
            Usuarios Administradores x Organizador
          </Typography>
          <Button
            variant='text'
            color='primary'
            key='Crear'
            onClick={() => {
              history.push(
                ROUTES.ADMIN.ORGANIZERS.PRODUCERS.CREATE.replace(
                  ':id',
                  match.params.id
                )
              )
            }}>
            Agregar Nuevo
            <Add />
          </Button>
        </div>
      }>
      <div className={classes.titleContainer}>
        <SearchComponent
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder='Ingresar Nombre'
        />
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {producersFilter &&
            producersFilter.map((producer, i) => (
              <OrganizerProducersList
                onSuccess={onSuccess}
                organizer={organizer?.Name}
                producer={producer}
                id={match.params.id}
                key={i}
              />
            ))}

          <div className={classes.actionsContainer}>
            <Button
              onClick={
                rolesArray?.includes(ROLES.PRODUCER) && rolesArray?.length === 1
                  ? handleBackProducers
                  : handleBack
              }
              color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default OrganizerProducersPage
