import * as types from './types'
import { DrawsProxy } from '~/services'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (draw) => ({
  type: types.CREATE_SUCCESS,
  draw
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateSuccess = (draw) => ({
  type: types.UPDATE_SUCCESS,
  draw
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const loadDrawAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadDrawSuccess = (draw) => ({
  type: types.LOAD_SUCCESS,
  draw
})

export const loadDrawFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const loadByEventAttempt = (reqParams) => ({
  type: types.LOAD_BY_EVENT_ATTEMPT,
  reqParams
})

export const loadByEventSuccess = (drawList) => ({
  type: types.LOAD_BY_EVENT_SUCCESS,
  drawList
})

export const loadByEventFailure = (error) => ({
  type: types.LOAD_BY_EVENT_FAILURE,
  error
})

export const loadParticipantsAttempt = (reqParams) => ({
  type: types.LOAD_PARTICIPANTS_ATTEMPT,
  reqParams
})

export const loadParticipantsSuccess = (participantList) => ({
  type: types.LOAD_PARTICIPANTS_SUCCESS,
  participantList
})

export const loadParticipantsFailure = (error) => ({
  type: types.LOAD_PARTICIPANTS_FAILURE,
  error
})

export const runAttempt = () => ({
  type: types.RUN_ATTEMPT
})

export const runSuccess = (draw) => ({
  type: types.RUN_SUCCESS,
  draw
})

export const runFailure = (error) => ({
  type: types.RUN_FAILURE,
  error
})

export const deliverAttempt = () => ({
  type: types.DELIVER_ATTEMPT
})

export const deliverSuccess = (draw) => ({
  type: types.DELIVER_SUCCESS,
  draw
})

export const deliverFailure = (error) => ({
  type: types.DELIVER_FAILURE,
  error
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteSuccess = (draw) => ({
  type: types.DELETE_SUCCESS,
  draw
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

export const resetDraws = () => ({
  type: types.RESET
})

export const loadPage = () => async (dispatch) => {
  try {
    dispatch(pagedListAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.getAll()

    dispatch(pagedListSuccess(response))
  } catch (error) {
    dispatch(pagedListFailure(error))
    throw error
  }
}

export const loadByEventPage = (eventId) => async (dispatch) => {
  try {
    dispatch(loadByEventAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.getByEventId(eventId)

    dispatch(loadByEventSuccess(response))
    return response
  } catch (error) {
    dispatch(loadByEventFailure(error))
    throw error
  }
}

export const loadParticipants = (id) => async (dispatch) => {
  try {
    dispatch(loadParticipantsAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.getParticipants(id)

    dispatch(loadParticipantsSuccess(response))
  } catch (error) {
    dispatch(loadParticipantsFailure(error))
    throw error
  }
}

export const loadDraw = (id) => async (dispatch) => {
  try {
    dispatch(loadDrawAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.getById(id)

    dispatch(loadDrawSuccess(response))
  } catch (error) {
    dispatch(loadDrawFailure(error))
    throw error
  }
}

export const create = (draw) => async (dispatch) => {
  try {
    dispatch(createAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.post(draw)

    dispatch(createSuccess(response))
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const update = (id, draw) => async (dispatch) => {
  try {
    dispatch(updateAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.put(id, draw)

    dispatch(updateSuccess(response))
  } catch (error) {
    dispatch(updateFailure(error))
    throw error
  }
}

export const runDraw = (id) => async (dispatch) => {
  try {
    dispatch(runAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.run(id)

    dispatch(runSuccess(response))
  } catch (error) {
    dispatch(runFailure(error))
    throw error
  }
}

export const deliverAwards = (id) => async (dispatch) => {
  try {
    dispatch(deliverAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.deliver(id)

    dispatch(deliverSuccess(response))
  } catch (error) {
    dispatch(deliverFailure(error))
    throw error
  }
}

export const deleteDraw = (id) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new DrawsProxy()
    const response = await proxy.delete(id)

    dispatch(deleteSuccess(response))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}
