import React from 'react'
import {
  FormControl,
  InputAdornment,
  TextField,
  Input,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useStyles } from './ResellerCommission.style'
import { isNotNumber, round } from 'views/utils/functions'

export const ResellerCommission = ({
  byPercentPlatform,
  byPercentReseller,
  setByPercentReseller,
  helperTextPercent,
  handleBlur,
  commission,
  error,
  setFieldValue,
  values,
  calculateFields,
  validateNaNValue,
  calculatePercentage
}) => {
  const classes = useStyles()

  return (
    <div className={classes.containerInputs}>
      <FormControlLabel
        className={classes.textCheckBox}
        control={
          <Checkbox
            checked={byPercentReseller}
            onChange={(e) => setByPercentReseller(e.target.checked)}
            name='ByPercentReseller'
          />
        }
        label='Por porcentaje?'
      />
      <div>
        <TextField
          label='Comisión RRPP'
          id='standard-start-adornment'
          style={{ width: 300 }}
          type='string'
          name='CommissionRrppAmount'
          value={round(commission?.CommissionWithTaxAmount)}
          disabled={byPercentReseller}
          onBlur={handleBlur}
          onChange={(e) => {
            calculateFields(
              e.target.value,
              'CommissionWithTaxAmount',
              values,
              setFieldValue,
              commission,
              byPercentPlatform,
              byPercentReseller
            )
            setFieldValue('CommissionRrppAmount', e.target.value)
          }}
          onKeyPress={(e) => isNotNumber(e)}
          onKeyDown={(e) => isNotNumber(e)}
          className={`${classes.fieldCommission} ${classes.fieldCommissionAmount}`}
          helperText={`(${validateNaNValue(round(calculatePercentage))}%)`}
          inputProps={{ maxLength: 8 }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          variant='standard'
        />

        <FormControl
          variant='standard'
          className={`${classes.formControl} ${classes.formControlCommission}`}>
          <Input
            name='CommissionRrpp'
            value={validateNaNValue(round(commission?.Commission))}
            error={Boolean(error)}
            type='string'
            onChange={(e) => {
              calculateFields(
                e.target.value,
                'Commission',
                values,
                setFieldValue,
                commission,
                byPercentPlatform,
                byPercentReseller
              )
              setFieldValue(
                'CommissionRrpp',
                validateNaNValue(parseFloat(e.target.value))
              )
            }}
            onBlur={handleBlur}
            disabled={!byPercentReseller}
            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
            className={`${classes.fieldCommission} ${classes.fieldCommissionPercent}`}
            aria-describedby='standard-weight-helper-text'
            onKeyPress={(e) => isNotNumber(e)}
            onKeyDown={(e) => isNotNumber(e)}
            inputProps={{
              'aria-label': 'weight',
              maxLength: 5
            }}
          />
          <FormHelperText
            id='standard-weight-helper-text'
            error={Boolean(error)}>
            {helperTextPercent}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

export default ResellerCommission
