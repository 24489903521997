import * as types from './types'

const initialState = {
  notification: null,
  error: null,
  actionSuccess: false,
  actionPending: false,
  response: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SEND_NOTIFICATION_ATTEMPT:
      return {
        ...state,
        notification: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.notification,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        notification: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.SEND_NOTIFICATION_TO_USER_ATTEMPT:
      return {
        ...state,
        response: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.SEND_NOTIFICATION_TO_USER_SUCCESS:
      return {
        ...state,
        response: action.response,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.SEND_NOTIFICATION_TO_USER_FAILURE:
      return {
        ...state,
        response: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
