import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  field: {
    margin: theme.spacing()
  },
  buttons: {
    margin: theme.spacing(1)
  },
  containerText: {
    marginLeft: theme.spacing(1),
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0
    }
  },
  value: {
    fontWeight: 'bold'
  },
  separator: {
    margin: theme.spacing(1),
    backgroundColor: 'lightgray',
    border: 0,
    height: 1
  },
  selectRow: {
    margin: 8,
    '& .MuiOutlinedInput-root': {
      position: 'sticky',
      borderRadius: 4,
      marginBlock: 2,
      display: 'flex',
      height: 48
    }
  },
  billingLabel: {
    marginLeft: 16,
    color: 'grey',
    marginTop: 12
  },
  formControl: {
    width: 200,
    marginTop: 18,
    marginLeft: 24
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  actionsContainerTop: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: -theme.spacing(4),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  }
}))

export default useStyles
