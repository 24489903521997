import axios from 'axios'
import BaseProxy from './BaseProxy'

export class CacheProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/cache`
  }

  async clearProfileCache(userId) {
    const response = await axios.post(`${this._baseUrl}/profile/user/${userId}`)

    return response.data
  }
}

export default CacheProxy
