import { Typography } from '@material-ui/core'

export const TagSection = ({ title, body }) => {
  return (
    <>
      <Typography variant='h4' gutterBottom>
        {title}
      </Typography>
      <Typography variant='body2'>{body}</Typography>
      <br />
    </>
  )
}

export default TagSection
