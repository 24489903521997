export const LOAD_PROFILE_ATTEMPT = 'admin/profiles/LOAD_PROFILE_ATTEMPT'
export const LOAD_PROFILE_SUCCESS = 'admin/profiles/LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'admin/profiles/LOAD_PROFILE_FAILURE'

export const SUBSCRIBE_ATTEMPT = 'admin/profiles/SUBSCRIBE_ATTEMPT'
export const SUBSCRIBE_SUCCESS = 'admin/profiles/SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_FAILURE = 'admin/profiles/SUBSCRIBE_FAILURE'

export const USER_BAN_ATTEMPT = 'admin/profiles/USER_BAN_ATTEMPT'
export const USER_BAN_SUCCESS = 'admin/profiles/USER_BAN_SUCCESS'
export const USER_BAN_FAILURE = 'admin/profiles/USER_BAN_FAILURE'

export const USER_UNBAN_ATTEMPT = 'admin/profiles/USER_UNBAN_ATTEMPT'
export const USER_UNBAN_SUCCESS = 'admin/profiles/USER_UNBAN_SUCCESS'
export const USER_UNBAN_FAILURE = 'admin/profiles/USER_UNBAN_FAILURE'

export const CLEAN_DNI_ATTEMPT = 'admin/profiles/CLEAN_DNI_ATTEMPT'
export const CLEAN_DNI_SUCCESS = 'admin/profiles/CLEAN_DNI_SUCCESS'
export const CLEAN_DNI_FAILURE = 'admin/profiles/CLEAN_DNI_FAILURE'

export const CHANGE_EMAIL_ATTEMPT = 'admin/profiles/CHANGE_EMAIL_ATTEMPT'
export const CHANGE_EMAIL_SUCCESS = 'admin/profiles/CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAILURE = 'admin/profiles/CHANGE_EMAIL_FAILURE'

export const ALLOW_CHANGE_EMAIL_ATTEMPT =
  'admin/profiles/ALLOW_CHANGE_EMAIL_ATTEMPT'
export const ALLOW_CHANGE_EMAIL_SUCCESS =
  'admin/profiles/ALLOW_CHANGE_EMAIL_SUCCESS'
export const ALLOW_CHANGE_EMAIL_FAILURE =
  'admin/profiles/ALLOW_CHANGE_EMAIL_FAILURE'

export const LOAD_ALL_ROLES_ATTEMPT = 'admin/profiles/LOAD_ALL_ROLES_ATTEMPT'
export const LOAD_ALL_ROLES_SUCCESS = 'admin/profiles/LOAD_ALL_ROLES_SUCCESS'
export const LOAD_ALL_ROLES_FAILURE = 'admin/profiles/LOAD_ALL_ROLES_FAILURE'

export const LOAD_ORGANIZERS_PAGED_ATTEMPT =
  'admin/profiles/LOAD_ORGANIZERS_PAGED_ATTEMPT'
export const LOAD_ORGANIZERS_PAGED_SUCCESS =
  'admin/profiles/LOAD_ORGANIZERS_PAGED_SUCCESS'
export const LOAD_ORGANIZERS_PAGED_FAILURE =
  'admin/profiles/LOAD_ORGANIZERS_PAGED_FAILURE'

export const RESET = 'admin/profiles/RESET'
