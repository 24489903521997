import * as types from './types'
import { ProfileProxy } from 'services'
import { mockPagedList } from '~/views/shared'

export const loadProfileAttempt = () => ({
  type: types.LOAD_PROFILE_ATTEMPT
})

export const loadProfileSuccess = (profile) => ({
  type: types.LOAD_PROFILE_SUCCESS,
  profile
})

export const loadProfileFailure = (error) => ({
  type: types.LOAD_PROFILE_FAILURE,
  error
})

export const subscribeAttempt = () => ({
  type: types.SUBSCRIBE_ATTEMPT
})

export const subscribeSuccess = () => ({
  type: types.SUBSCRIBE_SUCCESS
})

export const subscribeFailure = (error) => ({
  type: types.SUBSCRIBE_FAILURE,
  error
})

export const userBanAttempt = () => ({
  type: types.USER_BAN_ATTEMPT
})

export const userBanSuccess = () => ({
  type: types.USER_BAN_SUCCESS
})

export const userBanFailure = (error) => ({
  type: types.USER_BAN_FAILURE,
  error
})

export const userUnbanAttempt = () => ({
  type: types.USER_UNBAN_ATTEMPT
})

export const userUnbanSuccess = () => ({
  type: types.USER_UNBAN_SUCCESS
})

export const userUnbanFailure = (error) => ({
  type: types.USER_UNBAN_FAILURE,
  error
})

export const changeEmailAttempt = () => ({
  type: types.CHANGE_EMAIL_ATTEMPT
})

export const changeEmailSuccess = () => ({
  type: types.CHANGE_EMAIL_SUCCESS
})

export const changeEmailFailure = (error) => ({
  type: types.CHANGE_EMAIL_FAILURE,
  error
})

export const allowChangeEmailAttempt = () => ({
  type: types.ALLOW_CHANGE_EMAIL_ATTEMPT
})

export const allowChangeEmailSuccess = () => ({
  type: types.ALLOW_CHANGE_EMAIL_SUCCESS
})

export const allowChangeEmailFailure = (error) => ({
  type: types.ALLOW_CHANGE_EMAIL_FAILURE,
  error
})

export const cleanDniAttempt = () => ({
  type: types.CLEAN_DNI_ATTEMPT
})

export const cleanDniSuccess = () => ({
  type: types.CLEAN_DNI_SUCCESS
})

export const cleanDniFailure = (error) => ({
  type: types.CLEAN_DNI_FAILURE,
  error
})

export const loadAllRolesAttempt = () => ({
  type: types.LOAD_ALL_ROLES_ATTEMPT
})

export const loadAllRolesSuccess = (user) => ({
  type: types.LOAD_ALL_ROLES_SUCCESS,
  user
})

export const loadAllRolesFailure = (error) => ({
  type: types.LOAD_ALL_ROLES_FAILURE,
  error
})

export const loadOrganizersPagedAttempt = (reqParams) => ({
  type: types.LOAD_ORGANIZERS_PAGED_ATTEMPT,
  reqParams
})

export const loadOrganizersPagedSuccess = (pagedList) => ({
  type: types.LOAD_ORGANIZERS_PAGED_SUCCESS,
  pagedList
})

export const loadOrganizersPagedFailure = (error) => ({
  type: types.LOAD_ORGANIZERS_PAGED_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const loadProfile = () => async (dispatch) => {
  try {
    dispatch(loadProfileAttempt())

    const proxy = new ProfileProxy()
    const response = await proxy.getProfile()

    dispatch(loadProfileSuccess(response))
  } catch (error) {
    dispatch(loadProfileFailure(error))
    throw error
  }
}

export const subscribeEmail = (userId, subscribe) => async (dispatch) => {
  try {
    dispatch(subscribeAttempt())

    const proxy = new ProfileProxy()
    await proxy.subscribe(userId, subscribe)

    dispatch(subscribeSuccess())
  } catch (e) {
    dispatch(subscribeFailure(e))
    throw e
  }
}

export const toggleAlertsDraws = (userName, active) => async (dispatch) => {
  try {
    dispatch(subscribeAttempt())

    const proxy = new ProfileProxy()
    await proxy.alertsDraws(userName, active)

    dispatch(subscribeSuccess())
  } catch (e) {
    dispatch(subscribeFailure(e))
    throw e
  }
}

export const toggleAlertsEvents = (userName, active) => async (dispatch) => {
  try {
    dispatch(subscribeAttempt())

    const proxy = new ProfileProxy()
    await proxy.alertsEvents(userName, active)

    dispatch(subscribeSuccess())
  } catch (e) {
    dispatch(subscribeFailure(e))
    throw e
  }
}

export const userBan = (userName) => async (dispatch) => {
  try {
    dispatch(userBanAttempt())

    const proxy = new ProfileProxy()
    await proxy.userBan(userName)

    dispatch(userBanSuccess())
  } catch (error) {
    dispatch(userBanFailure(error))
    throw error
  }
}

export const userUnban = (userName) => async (dispatch) => {
  try {
    dispatch(userUnbanAttempt())

    const proxy = new ProfileProxy()
    await proxy.userUnban(userName)

    dispatch(userUnbanSuccess())
  } catch (error) {
    dispatch(userUnbanFailure(error))
    throw error
  }
}

export const changeEmail = (userId, email) => async (dispatch) => {
  try {
    dispatch(changeEmailAttempt())

    const proxy = new ProfileProxy()
    await proxy.changeEmail(userId, email)

    dispatch(changeEmailSuccess())
  } catch (error) {
    dispatch(changeEmailFailure(error))
    throw error
  }
}

export const cleanDNI = (userName) => async (dispatch) => {
  try {
    dispatch(cleanDniAttempt())

    const proxy = new ProfileProxy()
    await proxy.cleanDNI(userName)

    dispatch(cleanDniSuccess())
  } catch (error) {
    dispatch(cleanDniFailure(error))
    throw error
  }
}

export const allowChangeEmail = (userName, value) => async (dispatch) => {
  try {
    dispatch(allowChangeEmailAttempt())

    const proxy = new ProfileProxy()
    await proxy.allowChangeEmail(userName, value)

    dispatch(allowChangeEmailSuccess())
  } catch (error) {
    dispatch(allowChangeEmailFailure(error))
    throw error
  }
}

export const loadAllRoles = (userId) => async (dispatch) => {
  try {
    dispatch(loadAllRolesAttempt())

    const proxy = new ProfileProxy()
    const response = await proxy.getAllRoles(userId)

    dispatch(loadAllRolesSuccess(response))
    return response
  } catch (error) {
    dispatch(loadAllRolesFailure(error))
    throw error
  }
}

export const loadOrganizersPaged =
  (userId, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadOrganizersPagedAttempt({ searchParams, order, orderBy }))

      const proxy = new ProfileProxy()
      const response = await proxy.getAllRoles(userId)

      dispatch(
        loadOrganizersPagedSuccess(
          mockPagedList(
            response?.Organizers,
            pageSize,
            pageNumber,
            order,
            orderBy
          )
        )
      )
      return response?.Organizers
    } catch (error) {
      dispatch(loadOrganizersPagedFailure(error))
      throw error
    }
  }
