import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 300,
    minWidth: 300
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  containerTable: {
    padding: 5
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    fontWeight: 'normal'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '45rem'
    }
  },
  jsonPretty: {
    display: 'flex',
    width: 'fit-content'
  },
  button: {
    textTransform: 'none',
    marginBlock: -10
  },
  textButton: {
    display: 'flex',
    margin: 8
  },
  textDetails: {
    whiteSpace: 'break-spaces',
    minWidth: 100
  },
  status: {
    width: 'fit-content',
    fontSize: 12,
    paddingInline: '6px',
    paddingBlock: '4px',
    borderRadius: '18px'
  },
  statusApproved: {
    color: 'white',
    backgroundColor: ' #4CAF50'
  },
  statusRejected: {
    color: 'black',
    backgroundColor: 'lightgrey'
  },
  statusPending: {
    color: 'black',
    backgroundColor: '#FFEB3B'
  },
  statusInProgress: {
    color: 'white',
    backgroundColor: '#2196F3'
  },
  statusNotSet: {
    color: 'white',
    backgroundColor: 'grey'
  },
  statusChargedBack: {
    color: 'white',
    backgroundColor: '#E91E63'
  },
  statusRefunded: {
    color: 'white',
    backgroundColor: '#673AB7'
  },
  statusCancelled: {
    color: 'white',
    backgroundColor: '#F44336'
  },
  statusInMediation: {
    color: 'white',
    backgroundColor: '#FF9800'
  }
}))

export default useStyles
