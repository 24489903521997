import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  orderContainer: {
    margin: 20,
    padding: 10
  },
  containersGreen: {
    backgroundColor: '#54FF9F'
  },
  containersPink: {
    backgroundColor: '#FF6EB4'
  },
  containersOrange: {
    backgroundColor: '#F59542'
  },
  labelValues: {
    fontWeight: 'bold'
  },
  labelValuesThrough: {
    fontWeight: 'bold',
    textDecoration: 'line-through'
  },
  labelKeys: {
    fontWeight: 100
  },
  title: {
    fontWeight: 'bold',
    marginBlock: 10
  },
  button: {
    width: 500,
    margin: 15,
    '&:disabled': {
      backgroundColor: '#D5D5D5'
    }
  },
  separator: {
    backgroundColor: 'black',
    border: 0,
    height: 1
  }
}))

export default useStyles
