import * as types from './types'
import { Store } from 'react-notifications-component'

export const openDrawer = () => ({
  type: types.OPEN_DRAWER
})

export const closeDrawer = () => ({
  type: types.CLOSE_DRAWER
})

export const setSnackbarNotification = (notification) => ({
  type: types.SET_SNACKBAR_NOTIFICATION,
  notification
})

export const setCountry = (country) => ({
  type: types.SET_COUNTRY,
  country
})

// Thunks
export const showSnackbarNotification =
  (title, message, type, timeout = 3000) =>
  (dispatch) => {
    Store.addNotification({
      title: title,
      message:
        typeof message === 'string' ? message : message.message || message,
      type: type || 'default',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: timeout,
        onScreen: true
      }
    })

    dispatch(setSnackbarNotification(message))
  }

export const showSnackbarError = (error, timeout = 3000) => {
  const message =
    error?.response?.data?.Message ||
    error?.response?.data?.error ||
    'Error desconocido'

  return showSnackbarNotification('Error', message, 'danger', timeout)
}

export const showSnackbarSuccess = (message, timeout) =>
  showSnackbarNotification('Success', message, 'success', timeout)

export const restoreCountry = () => (dispatch) => {
  const country = process.env.REACT_APP_COUNTRY_CODE
  dispatch(setCountry(country))
}
