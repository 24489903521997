export const PAGEDLIST_ATTEMPT = 'admin/draws/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/draws/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/draws/PAGEDLIST_FAILURE'

export const LOAD_ATTEMPT = 'admin/draws/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/draws/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/draws/LOAD_FAILURE'

export const LOAD_BY_EVENT_ATTEMPT = 'admin/draws/LOAD_BY_EVENT_ATTEMPT'
export const LOAD_BY_EVENT_SUCCESS = 'admin/draws/LOAD_BY_EVENT_SUCCESS'
export const LOAD_BY_EVENT_FAILURE = 'admin/draws/LOAD_BY_EVENT_FAILURE'

export const LOAD_PARTICIPANTS_ATTEMPT = 'admin/draws/LOAD_PARTICIPANTS_ATTEMPT'
export const LOAD_PARTICIPANTS_SUCCESS = 'admin/draws/LOAD_PARTICIPANTS_SUCCESS'
export const LOAD_PARTICIPANTS_FAILURE = 'admin/draws/LOAD_PARTICIPANTS_FAILURE'

export const CREATE_ATTEMPT = 'admin/draws/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'admin/draws/CREATE_SUCCESS'
export const CREATE_FAILURE = 'admin/draws/CREATE_FAILURE'

export const UPDATE_ATTEMPT = 'admin/draws/UPDATE_ATTEMPT'
export const UPDATE_SUCCESS = 'admin/draws/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'admin/draws/UPDATE_FAILURE'

export const RUN_ATTEMPT = 'admin/draws/RUN_ATTEMPT'
export const RUN_SUCCESS = 'admin/draws/RUN_SUCCESS'
export const RUN_FAILURE = 'admin/draws/RUN_FAILURE'

export const DELIVER_ATTEMPT = 'admin/draws/DELIVER_ATTEMPT'
export const DELIVER_SUCCESS = 'admin/draws/DELIVER_SUCCESS'
export const DELIVER_FAILURE = 'admin/draws/DELIVER_FAILURE'

export const DELETE_ATTEMPT = 'admin/draws/DELETE_ATTEMPT'
export const DELETE_SUCCESS = 'admin/draws/DELETE_SUCCESS'
export const DELETE_FAILURE = 'admin/draws/DELETE_FAILURE'

export const RESET = 'admin/draws/RESET'
