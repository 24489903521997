import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBlock: theme.spacing(),
    gap: theme.spacing(2)
  },
  input: {
    flexGrow: 1
  },
  mpIdField: {
    minWidth: 190
  },
  priceField: {
    width: 150,
    '& div': {
      paddingLeft: '4px !important'
    }
  },
  priceIcon: {
    color: 'gray',
    marginRight: -8
  },
  datesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    borderRadius: 4,
    padding: theme.spacing(1),
    marginTop: -14,
    minWidth: 'max-content'
  },
  pricesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    borderRadius: 4,
    minWidth: 'max-content'
  },
  dateField: {
    width: 90
  },
  orderIdField: {
    minWidth: 335
  },
  button: {
    minWidth: 'min-content',
    height: theme.spacing(5)
  },
  autocomplete: {
    width: '100%'
  },
  paymentStatus: {
    width: 160,
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 10
    }
  }
}))

export default useStyles
