import * as types from './types'

const initialState = {
  collections: null,
  pendingCollections: false,
  invoiceBooks: null,
  pendingInvoiceBooks: false,
  creditNoteBooklets: null,
  pendingCreditNoteBooklets: false,
  error: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ALL_COLLECTIONS_ATTEMPT:
      return {
        ...state,
        pendingCollections: true,
        collections: null,
        error: null
      }
    case types.LOAD_ALL_COLLECTIONS_SUCCESS:
      return {
        ...state,
        pendingCollections: false,
        collections: action.collections,
        error: null
      }
    case types.LOAD_ALL_COLLECTIONS_FAILURE:
      return {
        ...state,
        pendingCollections: false,
        collections: null,
        error: action.error
      }
    case types.LOAD_INVOICE_BOOKS_ATTEMPT:
      return {
        ...state,
        pendingInvoiceBooks: true,
        invoiceBooks: null,
        error: null
      }
    case types.LOAD_INVOICE_BOOKS_SUCCESS:
      return {
        ...state,
        pendingInvoiceBooks: false,
        invoiceBooks: action.invoiceBooks,
        error: null
      }
    case types.LOAD_INVOICE_BOOKS_FAILURE:
      return {
        ...state,
        pendingInvoiceBooks: false,
        invoiceBooks: null,
        error: action.error
      }
    case types.LOAD_CREDIT_NOTE_BOOKLETS_ATTEMPT:
      return {
        ...state,
        pendingCreditNoteBooklets: true,
        creditNoteBooklets: null,
        error: null
      }
    case types.LOAD_CREDIT_NOTE_BOOKLETS_SUCCESS:
      return {
        ...state,
        pendingCreditNoteBooklets: false,
        creditNoteBooklets: action.creditNoteBooklets,
        error: null
      }
    case types.LOAD_CREDIT_NOTE_BOOKLETS_FAILURE:
      return {
        ...state,
        pendingCreditNoteBooklets: false,
        creditNoteBooklets: null,
        error: action.error
      }
    default:
      return state
  }
}
