import React, { useState } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'

const useStyles = makeStyles((theme) => ({
  profileImageButton: {
    cursor: 'pointer',
    position: 'relative',
    display: 'block'
  },
  editLayer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .75)',
    opacity: 0,
    transition: 'opacity ease-in-out 400ms',
    '&.hover': {
      opacity: 1
    }
  },
  cameraIcon: {
    width: 64,
    height: 64
  },
  progressSuperContainer: {
    position: 'relative'
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .75)'
  }
}))

export const ImagePicker = ({
  className,
  url,
  canEdit,
  settingImage,
  onImageChange,
  onClickImage
}) => {
  const [newImageUrl, setNewImageUrl] = useState(null)
  const [showEditLayer, setShowEditLayer] = useState(false)
  const classes = useStyles()

  const generatePreviewImgUrl = (file, callback) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => callback(reader.result)
  }

  const handleFile = (event) => {
    if (event.target.files.length === 0) return

    const file = event.target.files[0]
    onImageChange(file)
    generatePreviewImgUrl(file, (newImageUrl) => {
      setNewImageUrl(newImageUrl)
    })
  }

  const imageWithButton = () => {
    return !settingImage ? (
      <label
        className={classes.profileImageButton}
        title='Agregar foto'
        role='button'
        onMouseOver={() => setShowEditLayer(true)}
        onMouseOut={() => setShowEditLayer(false)}>
        <div
          style={{ backgroundImage: `url(${newImageUrl || url})` }}
          className={className}
        />
        <input type='file' style={{ display: 'none' }} onChange={handleFile} />
        <div
          onClick={() => onClickImage(url)}
          className={`${className} ${classes.editLayer} ${
            showEditLayer ? 'hover' : ''
          }`}>
          <PhotoCameraIcon className={classes.cameraIcon} />
          <Typography component='h4' align='center'>
            Agregar Foto
          </Typography>
        </div>
      </label>
    ) : (
      <div
        onClick={() => onClickImage(url)}
        className={`${className} ${classes.progressSuperContainer}`}
        style={{ backgroundImage: `url(${newImageUrl || url})` }}>
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      </div>
    )
  }

  const imageOnly = () => {
    return (
      <div
        onClick={() => onClickImage(url)}
        style={{ backgroundImage: `url(${newImageUrl || url})` }}
        className={className}
      />
    )
  }

  return canEdit ? imageWithButton() : imageOnly()
}

export default ImagePicker
