import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { useStyles } from './BarChartDetailsPage.style'
import { fetchUsers } from 'state/modules/common'
import { loadEvent } from 'state/modules/events'

export const BarChartDetailsPage = ({ onBack, match }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { users, pendingUsers } = useSelector((state) => state.common)
  const { pending, event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const invitationsData = JSON.parse(
    sessionStorage.getItem('invitationsDetails')
  )

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }
    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchUsers(match.params.userId))
    }
    fetchData().then()
  }, [dispatch, match])

  return pendingUsers || pending ? (
    <div className={classes.loadingCard}>
      <CircularProgress size={24} style={{ marginRight: '1rem' }} />
      <Typography variant={'caption'}>Cargando...</Typography>
    </div>
  ) : (
    <PaperContainer title={event?.Title}>
      <div className={classes.userData}>
        <Typography variant='h6' className={classes.labelKeys}>
          Nombre:&nbsp;
          <Typography variant='h6' component='span'>
            {users && users[0]?.FullName}
          </Typography>
        </Typography>
        <Typography variant='h6' className={classes.labelKeys}>
          Email:&nbsp;
          <Typography variant='h6' component='span'>
            {users && users[0]?.Email}
          </Typography>
        </Typography>
        <Typography variant='h6' className={classes.labelKeys}>
          {documentId}:&nbsp;
          <Typography variant='h6' component='span'>
            {users && users[0]?.DNI}
          </Typography>
        </Typography>
      </div>
      {invitationsData?.Invitations?.map((invitation) => (
        <div key={invitation.InvitationId} className={classes.ticket}>
          <Typography variant='h6' className={classes.ticketName}>
            {invitation.Name}
          </Typography>
          <hr className={classes.line} />
          <div className={classes.detailsTickets}>
            <Typography variant='subtitle2' className={classes.totals}>
              Enviadas: {invitation.TotalCreated}
            </Typography>
            <div className={classes.totalTickets}>
              <Typography variant='subtitle2'>
                Generadas: {invitation.TotalGenerated}
              </Typography>
              <Typography variant='subtitle2'>
                No Generadas: {invitation.TotalNotGenerated}
              </Typography>
            </div>
          </div>
        </div>
      ))}
      <div className={classes.actionsContainer}>
        <Button onClick={() => onBack(match.params.eventId)} color='primary'>
          Volver
        </Button>
      </div>
    </PaperContainer>
  )
}

export default BarChartDetailsPage
