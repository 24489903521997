import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  containerInputs: {
    marginLeft: '10px',
    marginBlock: '10px',
    '& .Mui-disabled': {
      color: 'gray',
      '& input': {
        backgroundColor: '#f3f3f3'
      },
      '& p': {
        paddingBlock: 4,
        backgroundColor: '#f3f3f3'
      }
    }
  },
  fieldCommission: {
    '& input': {
      textAlign: 'end'
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'end'
    },
    '& .MuiInput-formControl': {
      marginTop: '20px'
    }
  },
  fieldCommissionAmount: {
    '& p': {
      marginRight: -8
    }
  },
  formControl: {
    width: 200,
    marginTop: 18,
    marginLeft: 24
  },
  formControlCommission: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: 5
    }
  },
  fieldCommissionPercent: {
    '& p': {
      marginLeft: -8,
      padding: 8
    }
  },
  textCheckBox: {
    color: 'grey',
    marginTop: -16
  }
}))

export default useStyles
