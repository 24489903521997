import axios from 'axios'
import BaseProxy from './BaseProxy'

export class InvitationsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/invitations`
  }

  async post(invitation) {
    const response = await axios.post(`${this._baseUrl}`, invitation)

    return response.data
  }
}

export default InvitationsProxy
