import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    flex: 1,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  field: {
    margin: theme.spacing()
  },
  form: {
    display: 'flex',
    width: '-webkit-fill-available',
    paddingTop: 5,
    margin: '0 15px'
  },
  input: {
    display: 'flex',
    flexGrow: 1,
    marginTop: 5
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  ticketUsageStyle: {
    marginRight: theme.spacing(2)
  },
  etsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing()
  },
  selectorContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    width: '100%',
    height: '20rem',
    '&:first-child': {
      marginRight: theme.spacing(2)
    }
  },
  selectorLoadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
    flex: 1,
    height: '100%'
  },
  selector: {
    padding: 0,
    flex: '1',
    overflowY: 'auto',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: '.2rem',
    listStyle: 'none'
  },
  selectorItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  selectorItemSelected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  tag: {
    float: 'right',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '.25rem .25rem',
    borderRadius: '1rem',
    backgroundColor: theme.palette.primary.main
  }
}))

export default useStyles
