import BaseProxy from './BaseProxy'
import axios from 'axios'

export class EventTypesProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/eventtypes`
  }

  async getAll() {
    const response = await axios.get(`${this._baseUrl}`)

    return response.data
  }

  async getById(id) {
    const response = await axios.get(`${this._baseUrl}/${id}`)

    return response.data
  }

  async post(eventObj) {
    const response = await axios.post(`${this._baseUrl}`, eventObj)

    return response.data
  }

  async put(id, eventObj) {
    const response = await axios.put(`${this._baseUrl}/${id}`, eventObj)

    return response.data
  }

  async delete(id) {
    const response = await axios.delete(`${this._baseUrl}/${id}`)
    return response.data
  }
}

export default EventTypesProxy
