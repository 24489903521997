import axios from 'axios'
import BaseProxy from './BaseProxy'

export class AuthenticationProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/Authentication`
  }

  async createFromExternalLogin(payload) {
    const response = await axios.post(
      `${this._baseUrl}/CreateExternalUser`,
      payload
    )
    let newObj = {}
    if (response.status === 202) {
      newObj.message = response.data
    } else {
      newObj = response.data
    }
    newObj.status = response.status
    return newObj
  }
}

export default AuthenticationProxy
