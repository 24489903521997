import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing()
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1)
  },
  title: {
    width: '-webkit-fill-available',
    '& div': {
      alignItems: 'center'
    }
  },
  main: {
    padding: theme.spacing()
  }
}))

export default useStyles
