import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { PaperContainer, SearchComponent } from '~views/shared'
import { useStyles } from './ResellerInfoSalesPage.style'
import { loadEvent } from '~/state/modules/events'
import {
  loadSalesResellerDetails,
  loadResellerTPSalesDetails,
  loadResellerCashSalesDetails
} from '~/state/modules/resellers'

export const ResellerInfoSalesPage = ({ match, resellerType }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [totalVentas, setTotalVentas] = useState(0)
  const [search, setSearch] = useState('')
  const [dataFilter, setDataFilter] = useState(null)

  const {
    resellerSalesEvent,
    resellerTPSalesEventDetails,
    resellerCashSalesDetails
  } = useSelector((state) => state.resellers)

  const { pending, event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'

  const createRow = (label, value, i) => {
    return (
      <TableRow className={classes.tableRow} key={i}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>{value}</TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    setDataFilter(data)
    if (data !== null) {
      let ventas = 0

      const calculate = (resellers) => {
        resellers.forEach((v) => {
          v.Entradas &&
            v.Entradas.forEach((v) => {
              ventas += v.Cantidad
            })
        })
        return {
          ventas
        }
      }
      calculate(data)
      setTotalVentas(ventas)
    }
  }, [data])

  const filter = (searchKey) => {
    setSearch(searchKey)
    if (data != null) {
      const item = data.filter(
        (i) =>
          i.Nombre.toLowerCase()
            .trim()
            .includes(searchKey.toLowerCase().trim()) ||
          i.DNI.trim().includes(searchKey.trim()) ||
          i.FechaDeCompra.trim().includes(searchKey.trim())
      )
      setDataFilter(item)
    }
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      switch (resellerType) {
        case 0:
          await dispatch(
            loadSalesResellerDetails(match.params.userId, match.params.eventId)
          )
          break
        case 1:
          await dispatch(
            loadResellerTPSalesDetails(
              match.params.userId,
              match.params.eventId
            )
          )
          break
        case 2:
          await dispatch(
            loadResellerCashSalesDetails(
              match.params.userId,
              match.params.eventId
            )
          )
          break

        default:
          return 0
      }
    }
    fetchData().then()
  }, [dispatch, match, resellerType, setData])

  useEffect(() => {
    switch (resellerType) {
      case 0:
        setData(resellerSalesEvent)
        break
      case 1:
        setData(resellerTPSalesEventDetails)
        break
      case 2:
        setData(resellerCashSalesDetails)
        break

      default:
        return 0
    }
  }, [
    resellerType,
    setData,
    resellerSalesEvent,
    resellerTPSalesEventDetails,
    resellerCashSalesDetails
  ])

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Ventas por RRPP'>
        <div className={classes.formHeader}>
          <SearchComponent
            performSearch={filter}
            searchValue={search}
            placeholder={`Ingresar Nombre, ${documentId} o fecha de compra`}
          />
        </div>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <Table>
              <TableBody>
                {createRow('Total Ventas', Math.ceil(totalVentas))}
              </TableBody>
            </Table>
            <hr />
            {dataFilter &&
              dataFilter?.map((s, i) => (
                <div key={i}>
                  <Table>
                    <TableBody>
                      {createRow('Nombre', s.Nombre)}
                      {createRow(documentId, s.DNI)}
                      {createRow('Teléfono', s.Telefono)}
                      {createRow('Fecha de Compra', s.FechaDeCompra)}
                      {s?.Entradas &&
                        s?.Entradas.map((t, i) =>
                          createRow(t?.Tipo, t?.Cantidad, i)
                        )}
                    </TableBody>
                  </Table>
                  <hr />
                </div>
              ))}
            <div className={classes.actionsContainer}>
              <Button
                onClick={() =>
                  history.push(
                    ROUTES.ADMIN.EVENTS.RESELLERS.INDEX.replace(
                      ':eventId',
                      match.params.eventId
                    )
                  )
                }
                color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default ResellerInfoSalesPage
