import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex'
  },
  input: {
    flexGrow: 1
  },
  conditionalRow: {
    backgroundColor: '#ffcccc',
    '&:hover': {
      backgroundColor: '#fcb0b1 !important'
    }
  }
}))

export default useStyles
