import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  CircularProgress,
  Typography,
  MenuItem
} from '@material-ui/core'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { reset } from 'state/modules/orders'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {
  Image,
  Info,
  MoreVert,
  LocalActivity,
  HowToVote
} from '@material-ui/icons'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import EditIcon from '@material-ui/icons/Edit'
import SendIcon from '@material-ui/icons/Send'
import history from '~/config/history'
import {
  disable,
  enable,
  sendNotification,
  resetActions,
  setTicketType,
  getTicketTypesReport,
  shareUrl
} from '~/state/modules/events'
import { ROUTES } from '~/views/routes'
import {
  FindInPage,
  Spellcheck,
  PhoneIphone,
  MarkunreadMailbox
} from '@material-ui/icons'
import {
  showSnackbarError,
  showSnackbarSuccess,
  showSnackbarInfo
} from 'views/utils/snackbar'
import copy from 'copy-to-clipboard'
import { ConfirmationDialog } from '~/views/shared'
import { useStyles } from './EventActionsMenu.style'

export const EventActionMenus = ({
  event,
  performSearch,
  isReseller,
  isWithDrawPoint
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false)
  const [successNotification, setSuccessNotification] = useState(false)
  const [title, setTitle] = useState(null)
  const [text, setText] = useState(null)
  const { pendingNotification, pendingReport, pendingUrl } = useSelector(
    (state) => state.events
  )
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClickOpen = async (habilitado) => {
    if (habilitado) {
      try {
        const resp = await dispatch(enable(event.Id))
        if (resp) {
          setTitle('Evento Habilitado')
          setText(`El evento '${event.Title}' ha sido habilitado con éxito!`)
          setOpen(true)
        }
      } catch (error) {}
    } else {
      try {
        const resp = await dispatch(disable(event.Id))
        if (resp) {
          setTitle('Evento Deshabilitado')
          setText(`El evento '${event.Title}' ha sido deshabilitado con éxito!`)
          setOpen(true)
        }
      } catch (error) {}
    }
    dispatch(resetActions())
  }

  const handleClosed = () => {
    setOpen(false)
    performSearch()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirmNotification = async () => {
    try {
      await dispatch(sendNotification(event.Id))
      setOpenDialogConfirm(false)
      setSuccessNotification(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const copyToClipBoard = async () => {
    try {
      if (event.IsEnabled) {
        const url = await dispatch(shareUrl(event.Id))
        copy(url)
        showSnackbarSuccess('Copia realizada con Éxito! ')
      } else {
        showSnackbarError(
          'Para poder compartir la URL del Evento primero tienes que habilitarlo.',
          4000
        )
      }
    } catch (err) {
      console.error(err)
      showSnackbarError(err)
    }
  }

  const message =
    'Para hacer uso de esta funcionalidad debe instalar la App Manager en su dispositivo móvil.'

  return (
    <div>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {isWithDrawPoint ? (
          <div>
            <MenuItem
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.EVENTS.VIEW.replace(':eventId', event.Id)
                )
              }>
              <ListItemIcon>
                <Info fontSize='small' />
              </ListItemIcon>
              Ver información
            </MenuItem>
            <MenuItem
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.EVENTS.TICKETS.ORDERS.replace(
                    ':eventId',
                    event.Id
                  )
                )
              }>
              <ListItemIcon>
                <MonetizationOnIcon fontSize='small' />
              </ListItemIcon>
              Ver Tickets Vendidos
            </MenuItem>
            <MenuItem
              onClick={() => showSnackbarInfo(message, 'Información', 5000)}>
              <ListItemIcon>
                <PhoneIphone fontSize='small' />
              </ListItemIcon>
              Modo Lector Pulsera
            </MenuItem>
            <MenuItem
              onClick={() => showSnackbarInfo(message, 'Información', 5000)}>
              <ListItemIcon>
                <PhoneIphone fontSize='small' />
              </ListItemIcon>
              Activar Pulsera
            </MenuItem>
            <MenuItem
              onClick={() => showSnackbarInfo(message, 'Información', 5000)}>
              <ListItemIcon>
                <PhoneIphone fontSize='small' />
              </ListItemIcon>
              Reset Seguridad
            </MenuItem>
            <MenuItem
              onClick={() => showSnackbarInfo(message, 'Información', 5000)}>
              <ListItemIcon>
                <PhoneIphone fontSize='small' />
              </ListItemIcon>
              Ver Datos Pulsera
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.EVENTS.RESELLERS.INDEX.replace(
                    ':eventId',
                    event.Id
                  )
                )
              }>
              <ListItemIcon>
                <Info fontSize='small' />
              </ListItemIcon>
              Ver RRPP
            </MenuItem>
            <MenuItem onClick={() => copyToClipBoard()}>
              <ListItemIcon>
                <Spellcheck fontSize='small' />
              </ListItemIcon>
              {pendingUrl ? (
                <div className={classes.containerProgress}>
                  <CircularProgress
                    size={24}
                    className={classes.circularProgress}
                  />
                  <Typography>Cargando...</Typography>
                </div>
              ) : (
                'Copiar url de evento'
              )}
            </MenuItem>
            <MenuItem
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.EVENTS.INVITATIONS.replace(':eventId', event.Id)
                )
              }>
              <ListItemIcon>
                <SendIcon fontSize='small' />
              </ListItemIcon>
              Enviar Invitaciones
            </MenuItem>
            {!isReseller && (
              <div>
                <MenuItem onClick={() => setOpenDialogConfirm(true)}>
                  <ListItemIcon>
                    <MarkunreadMailbox fontSize='small' />
                  </ListItemIcon>
                  Enviar Notificación
                </MenuItem>
                <hr />
                <MenuItem
                  onClick={() =>
                    history.push(
                      ROUTES.ADMIN.EVENTS.VIEW.replace(':eventId', event.Id)
                    )
                  }>
                  <ListItemIcon>
                    <Info fontSize='small' />
                  </ListItemIcon>
                  Ver información
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push(
                      ROUTES.ADMIN.EVENTS.UPDATE.replace(':eventId', event.Id)
                    )
                  }}>
                  <ListItemIcon>
                    <EditIcon fontSize='small' />
                  </ListItemIcon>
                  Editar Evento
                </MenuItem>
                {event.IsEnabled ? (
                  <MenuItem onClick={() => handleClickOpen(false)}>
                    <ListItemIcon>
                      <NotInterestedIcon fontSize='small' />
                    </ListItemIcon>
                    Deshabilitar Evento
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => handleClickOpen(true)}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon fontSize='small' />
                    </ListItemIcon>
                    Habilitar Evento
                  </MenuItem>
                )}
                <hr />
                <MenuItem
                  onClick={() => {
                    history.push(
                      ROUTES.ADMIN.EVENTS.IMAGES.replace(':eventId', event.Id)
                    )
                  }}>
                  <ListItemIcon>
                    <Image fontSize='small' />
                  </ListItemIcon>
                  Editar Imágenes
                </MenuItem>
                <hr />
                <MenuItem
                  onClick={() => {
                    dispatch(setTicketType(-1))
                    history.push(
                      ROUTES.ADMIN.EVENTS.TICKETS.INDEX.replace(
                        ':eventId',
                        event.Id
                      )
                    )
                  }}>
                  <ListItemIcon>
                    <LocalActivity fontSize='small' />
                  </ListItemIcon>
                  Crear / Editar Tickets
                </MenuItem>
                <hr />
                <MenuItem
                  onClick={() =>
                    history.push(
                      ROUTES.ADMIN.EVENTS.TICKETS.ORDERS.replace(
                        ':eventId',
                        event.Id
                      )
                    )
                  }>
                  <ListItemIcon>
                    <MonetizationOnIcon fontSize='small' />
                  </ListItemIcon>
                  Ver Tickets Vendidos
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(reset())
                    history.push(
                      ROUTES.ADMIN.EVENTS.ORDERS.INDEX.replace(
                        ':eventId',
                        event.Id
                      )
                    )
                  }}>
                  <ListItemIcon>
                    <FindInPage fontSize='small' />
                  </ListItemIcon>
                  Ver Ordenes
                </MenuItem>
                <hr />
                <MenuItem
                  onClick={() =>
                    history.push(
                      ROUTES.ADMIN.EVENTS.DRAWS.INDEX.replace(
                        ':eventId',
                        event.Id
                      )
                    )
                  }>
                  <ListItemIcon>
                    <HowToVote fontSize='small' />
                  </ListItemIcon>
                  Sorteos
                </MenuItem>
                <hr />
                <MenuItem
                  onClick={() => dispatch(getTicketTypesReport(event.Id))}>
                  <ListItemIcon>
                    <CloudDownloadIcon fontSize='small' />
                  </ListItemIcon>
                  {pendingReport ? (
                    <div className={classes.containerProgress}>
                      <CircularProgress
                        size={24}
                        className={classes.circularProgress}
                      />
                      <Typography>Cargando...</Typography>
                    </div>
                  ) : (
                    'Descargar CSV (Molinete)'
                  )}
                </MenuItem>
              </div>
            )}
          </div>
        )}
      </Menu>
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosed} color='primary' autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDialogConfirm}
        loading={pendingNotification}
        handleConfirmation={handleConfirmNotification}
        onCancel={() => setOpenDialogConfirm(false)}
        title={'CONFIRMACIÓN'}
        message={'Está por enviar una notificación de nuevo evento.'}
      />
      <Dialog open={successNotification}>
        <DialogTitle>¡Notificación Enviada!</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setSuccessNotification(false)}
            color='primary'
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EventActionMenus
