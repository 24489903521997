import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Save, Settings } from '@material-ui/icons'
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { useStyles } from './BillingCredentialsForm.style'

const validationSchema = Yup.object().shape({
  Username: Yup.string()
    .required('Es requerido ingresar el nombre de usuario')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  Password: Yup.string()
    .required('Es requerido ingresar una clave')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  CompanyId: Yup.string()
    .required('Es requerido ingresar el CompanyId')
    .max(250, "La cantidad máxima de caracteres es de '250'")
})

export const BillingCredentialsForm = ({
  values,
  handleSubmit,
  onCancel,
  actionPending,
  organizerId,
  credentialsEnabled
}) => {
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Username && touched.Username && errors.Username) ||
                  'Usuario'
                }
                name='Username'
                value={values.Username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Username && Boolean(errors.Username)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Password && touched.Password && errors.Password) ||
                  'Clave'
                }
                name='Password'
                value={values.Password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Password && Boolean(errors.Password)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.CompanyId && touched.CompanyId && errors.CompanyId) ||
                  'CompanyId'
                }
                name='CompanyId'
                value={values.CompanyId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.CompanyId && Boolean(errors.CompanyId)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>
          <hr className={classes.separator} />
          <div className={classes.containerText}>
            <Typography variant='h6'>
              Una vez que hayas completado las credenciales de facturación,
              debes proceder a completar la sección de&nbsp;
              <Typography
                variant='h6'
                component='span'
                className={classes.value}>
                "Configuración de Facturación"
              </Typography>
            </Typography>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={isSubmitting} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                history.push(
                  ROUTES.ADMIN.ORGANIZERS.BILLING.SETTINGS.replace(
                    ':id',
                    organizerId
                  )
                )
              }}
              disabled={actionPending || isSubmitting || !credentialsEnabled}
              style={{ textTransform: 'none' }}
              color='secondary'
              variant='contained'
              startIcon={<Settings />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                'Configuración de Facturación'
              )}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={actionPending || isSubmitting}
              color='primary'
              variant='contained'
              style={{ textTransform: 'none' }}
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                'Agregar Credencial de Facturación'
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default BillingCredentialsForm
