import * as types from './types'

const initialState = {
  invitations: [],
  invitation: null,
  pending: false,
  error: null,
  actionSuccess: false,
  actionPending: false,
  invitationsDetails: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_INVITATIONS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        invitations: []
      }
    case types.FETCH_INVITATIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        invitations: action.invitations
      }
    case types.FETCH_INVITATIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        invitations: []
      }
    case types.SEND_ATTEMPT:
      return {
        ...state,
        invitation: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.SEND_SUCCESS:
      return {
        ...state,
        invitation: action.invitation,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.SEND_FAILURE:
      return {
        ...state,
        invitation: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.SET_INVITATION_DETAILS:
      return {
        ...state,
        invitationsDetails: action.invitationsDetails
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
