import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  contentTickets: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonDialog: {
    height: 38,
    alignSelf: 'center',
    paddingInline: 50,
    textTransform: 'none'
  },
  titleDialog: {
    '& .MuiTypography-h6': {
      fontSize: 'large'
    }
  },
  ticketsDialog: {
    '& .MuiPaper-rounded': {
      maxInlineSize: 'none',
      marginInline: '15em'
    }
  },
  comboForm: {
    display: 'flex'
  },
  fieldDialog: {
    marginRight: theme.spacing(2),
    marginBlock: theme.spacing(1),
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 10
    }
  },
  loadingCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableCombo: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    '& .MuiPaper-rounded': {
      marginLeft: 'auto',
      borderStyle: 'none !important'
    }
  }
}))

export default useStyles
