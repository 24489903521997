import * as types from './types'
import { OrganizersProxy } from '~/services'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (organizer) => ({
  type: types.CREATE_SUCCESS,
  organizer
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateSuccess = (organizer) => ({
  type: types.UPDATE_SUCCESS,
  organizer
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const loadOrganizerAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadOrganizerSuccess = (organizer) => ({
  type: types.LOAD_SUCCESS,
  organizer
})

export const loadOrganizerFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteSuccess = (organizer) => ({
  type: types.DELETE_SUCCESS,
  organizer
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

export const loadAllResellersAttempt = () => ({
  type: types.LOAD_ALL_RESELLERS_ATTEMPT
})

export const loadAllResellersSuccess = (allResellers) => ({
  type: types.LOAD_ALL_RESELLERS_SUCCESS,
  allResellers
})

export const loadAllResellersFailure = (error) => ({
  type: types.LOAD_ALL_RESELLERS_FAILURE,
  error
})

export const loadControllersAttempt = () => ({
  type: types.LOAD_CONTROLLERS_ATTEMPT
})

export const loadControllersSuccess = (controllers) => ({
  type: types.LOAD_CONTROLLERS_SUCCESS,
  controllers
})

export const loadControllersFailure = (error) => ({
  type: types.LOAD_CONTROLLERS_FAILURE,
  error
})

export const createControllerAttempt = () => ({
  type: types.CREATE_CONTROLLER_ATTEMPT
})

export const createControllerSuccess = (controller) => ({
  type: types.CREATE_CONTROLLER_SUCCESS,
  controller
})

export const createControllerFailure = (error) => ({
  type: types.CREATE_CONTROLLER_FAILURE,
  error
})

export const loadResellersAttempt = () => ({
  type: types.LOAD_RESELLERS_ATTEMPT
})

export const loadResellersSuccess = (resellers) => ({
  type: types.LOAD_RESELLERS_SUCCESS,
  resellers
})

export const loadResellersFailure = (error) => ({
  type: types.LOAD_RESELLERS_FAILURE,
  error
})

export const createResellerAttempt = () => ({
  type: types.CREATE_RESELLER_ATTEMPT
})

export const createResellerSuccess = (reseller) => ({
  type: types.CREATE_RESELLER_SUCCESS,
  reseller
})

export const createResellerFailure = (error) => ({
  type: types.CREATE_RESELLER_FAILURE,
  error
})

export const loadProducersAttempt = () => ({
  type: types.LOAD_PRODUCERS_ATTEMPT
})

export const loadProducersSuccess = (producers) => ({
  type: types.LOAD_PRODUCERS_SUCCESS,
  producers
})

export const loadProducersFailure = (error) => ({
  type: types.LOAD_PRODUCERS_FAILURE,
  error
})

export const createProducerAttempt = () => ({
  type: types.CREATE_PRODUCER_ATTEMPT
})

export const createProducerSuccess = (producer) => ({
  type: types.CREATE_PRODUCER_SUCCESS,
  producer
})

export const createProducerFailure = (error) => ({
  type: types.CREATE_PRODUCER_FAILURE,
  error
})

export const loadEventsAttempt = () => ({
  type: types.LOAD_EVENTS_ATTEMPT
})

export const loadEventsSuccess = (events) => ({
  type: types.LOAD_EVENTS_SUCCESS,
  events
})

export const loadEventsFailure = (error) => ({
  type: types.LOAD_EVENTS_FAILURE,
  error
})

export const loadUrlAttempt = () => ({
  type: types.LOAD_URL_ATTEMPT
})

export const loadUrlSuccess = (generatedUrl) => ({
  type: types.LOAD_URL_SUCCESS,
  generatedUrl
})

export const loadUrlFailure = (error) => ({
  type: types.LOAD_URL_FAILURE,
  error
})

export const loadOrganizersPagedAttempt = (reqParams) => ({
  type: types.LOAD_ORGANIZERS_PAGED_ATTEMPT,
  reqParams
})

export const loadOrganizersPagedSuccess = (organizersPaged) => ({
  type: types.LOAD_ORGANIZERS_PAGED_SUCCESS,
  organizersPaged
})

export const loadOrganizersPagedFailure = (error) => ({
  type: types.LOAD_ORGANIZERS_PAGED_FAILURE,
  error
})

export const loadOrganizersAttempt = () => ({
  type: types.LOAD_ORGANIZERS_ATTEMPT
})

export const loadOrganizersSuccess = (organizers) => ({
  type: types.LOAD_ORGANIZERS_SUCCESS,
  organizers
})

export const loadOrganizersFailure = (error) => ({
  type: types.LOAD_ORGANIZERS_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const resetEvents = () => ({
  type: types.RESET_EVENTS
})

export const resetActions = () => ({
  type: types.RESET_ACTIONS
})

export const resetParamsOrganizers = () => ({
  type: types.RESET_PARAMS
})

export const enableBillingAttempt = () => ({
  type: types.ENABLE_BILLING_ATTEMPT
})

export const enableBillingSuccess = (enableResponse) => ({
  type: types.ENABLE_BILLING_SUCCESS,
  enableResponse
})

export const enableBillingFailure = (error) => ({
  type: types.ENABLE_BILLING_FAILURE,
  error
})

export const disableBillingAttempt = () => ({
  type: types.DISABLE_BILLING_ATTEMPT
})

export const disableBillingSuccess = (disableResponse) => ({
  type: types.DISABLE_BILLING_SUCCESS,
  disableResponse
})

export const disableBillingFailure = (error) => ({
  type: types.DISABLE_BILLING_FAILURE,
  error
})

export const addBillingCredentialAttempt = () => ({
  type: types.ADD_BILLING_CREDENTIAL_ATTEMPT
})

export const addBillingCredentialSuccess = (credentialResponse) => ({
  type: types.ADD_BILLING_CREDENTIAL_SUCCESS,
  credentialResponse
})

export const addBillingCredentialFailure = (error) => ({
  type: types.ADD_BILLING_CREDENTIAL_FAILURE,
  error
})

export const addBillingSettingsAttempt = () => ({
  type: types.ADD_BILLING_SETTINGS_ATTEMPT
})

export const addBillingSettingsSuccess = (settingsResponse) => ({
  type: types.ADD_BILLING_SETTINGS_SUCCESS,
  settingsResponse
})

export const addBillingSettingsFailure = (error) => ({
  type: types.ADD_BILLING_SETTINGS_FAILURE,
  error
})
export const loadMpInfoAttempt = () => ({
  type: types.LOAD_MP_INFO_ATTEMPT
})

export const loadMpInfoSuccess = (mpInfo) => ({
  type: types.LOAD_MP_INFO_SUCCESS,
  mpInfo
})

export const loadMpInfoFailure = (error) => ({
  type: types.LOAD_MP_INFO_FAILURE,
  error
})

export const loadPage =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageNumber, pageSize })
      )

      const proxy = new OrganizersProxy()
      const pagedList = await proxy.get(
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(pagedListSuccess(pagedList))
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }

export const loadOrganizer = (id) => async (dispatch) => {
  try {
    dispatch(loadOrganizerAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getById(id)

    dispatch(loadOrganizerSuccess(response))
  } catch (error) {
    dispatch(loadOrganizerFailure(error))
    throw error
  }
}

export const create = (organizer) => async (dispatch) => {
  try {
    dispatch(createAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.post(organizer)

    dispatch(createSuccess(response))
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const update = (id, organizer) => async (dispatch) => {
  try {
    dispatch(updateAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.put(id, organizer)

    dispatch(updateSuccess(response))
  } catch (error) {
    dispatch(updateFailure(error))
    throw error
  }
}

export const deleteOrganizer = (id) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.delete(id)

    dispatch(deleteSuccess(response))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}

export const loadAllResellers = (id) => async (dispatch) => {
  try {
    dispatch(loadAllResellersAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getAllResellers(id)

    dispatch(loadAllResellersSuccess(response))
  } catch (error) {
    dispatch(loadAllResellersFailure(error))
    throw error
  }
}

export const loadControllers = (id) => async (dispatch) => {
  try {
    dispatch(loadControllersAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getControllers(id)

    dispatch(loadControllersSuccess(response))
  } catch (error) {
    dispatch(loadControllersFailure(error))
    throw error
  }
}

export const deleteController = (id, userId) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.deleteController(id, userId)

    dispatch(deleteSuccess(response))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}

export const createController = (id, userId) => async (dispatch) => {
  try {
    dispatch(createControllerAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.postController(id, userId)

    dispatch(createControllerSuccess(response))
  } catch (error) {
    dispatch(createControllerFailure(error))
    throw error
  }
}

export const loadResellers = (id) => async (dispatch) => {
  try {
    dispatch(loadResellersAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getResellers(id)

    dispatch(loadResellersSuccess(response))
  } catch (error) {
    dispatch(loadResellersFailure(error))
    throw error
  }
}

export const deleteReseller = (id, resellerId) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.deleteReseller(id, resellerId)

    dispatch(deleteSuccess(response))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}

export const createReseller = (id, userId) => async (dispatch) => {
  try {
    dispatch(createResellerAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.postReseller(id, userId)

    dispatch(createResellerSuccess(response))
  } catch (error) {
    dispatch(createResellerFailure(error))
    throw error
  }
}

export const loadProducers = (id) => async (dispatch) => {
  try {
    dispatch(loadProducersAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getProducers(id)

    dispatch(loadProducersSuccess(response))
  } catch (error) {
    dispatch(loadProducersFailure(error))
    throw error
  }
}

export const deleteProducer = (id, producerId) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.deleteProducer(id, producerId)

    dispatch(deleteSuccess(response))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}

export const createProducer = (id, userId) => async (dispatch) => {
  try {
    dispatch(createProducerAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.postProducer(id, userId)

    dispatch(createProducerSuccess(response))
  } catch (error) {
    dispatch(createProducerFailure(error))
    throw error
  }
}

export const loadEvents = (id) => async (dispatch) => {
  try {
    dispatch(loadEventsAttempt())
    const proxy = new OrganizersProxy()
    const response = await proxy.getEvents(id)

    dispatch(loadEventsSuccess(response))
  } catch (error) {
    dispatch(loadEventsFailure(error))
    throw error
  }
}

export const loadUrl = (organizerId) => async (dispatch) => {
  try {
    dispatch(loadUrlAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getUrl(organizerId)

    dispatch(loadUrlSuccess(response))
    return response
  } catch (error) {
    dispatch(loadUrlFailure(error))
    throw error
  }
}

export const loadOrganizersPaged =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadOrganizersPagedAttempt({ searchParams, order, orderBy }))

      const proxy = new OrganizersProxy()
      const pagedList = await proxy.getOrganizersPaged(
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(loadOrganizersPagedSuccess(pagedList))
    } catch (error) {
      dispatch(loadOrganizersPagedFailure(error))
      throw error
    }
  }

export const loadOrganizers = () => async (dispatch) => {
  try {
    dispatch(loadOrganizersAttempt())
    const proxy = new OrganizersProxy()
    const response = await proxy.getOrganizers()

    dispatch(loadOrganizersSuccess(response))
  } catch (error) {
    dispatch(loadOrganizersFailure(error))
    throw error
  }
}

export const loadMpInfo = (ordanizerId) => async (dispatch) => {
  try {
    dispatch(loadMpInfoAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.getMpInfo(ordanizerId)

    dispatch(loadMpInfoSuccess(response))
    return response
  } catch (error) {
    dispatch(loadMpInfoFailure(error))
    throw error
  }
}

export const enableBilling = (ordanizerId) => async (dispatch) => {
  try {
    dispatch(enableBillingAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.enableBilling(ordanizerId)

    dispatch(enableBillingSuccess(response))
    return response
  } catch (error) {
    dispatch(enableBillingFailure(error))
    throw error
  }
}

export const disableBilling = (ordanizerId) => async (dispatch) => {
  try {
    dispatch(disableBillingAttempt())

    const proxy = new OrganizersProxy()
    const response = await proxy.disableBilling(ordanizerId)

    dispatch(disableBillingSuccess(response))
    return response
  } catch (error) {
    dispatch(disableBillingFailure(error))
    throw error
  }
}

export const addBillingCredential =
  (ordanizerId, payload) => async (dispatch) => {
    try {
      dispatch(addBillingCredentialAttempt())

      const proxy = new OrganizersProxy()
      const response = await proxy.addBillingCredential(ordanizerId, payload)

      dispatch(addBillingCredentialSuccess(response))
      return response
    } catch (error) {
      dispatch(addBillingCredentialFailure(error))
      throw error
    }
  }

export const addBillingSettings =
  (ordanizerId, payload) => async (dispatch) => {
    try {
      dispatch(addBillingSettingsAttempt())

      const proxy = new OrganizersProxy()
      const response = await proxy.addBillingSettings(ordanizerId, payload)

      dispatch(addBillingSettingsSuccess(response))
      return response
    } catch (error) {
      dispatch(addBillingSettingsFailure(error))
      throw error
    }
  }
