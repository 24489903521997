export const LOAD_EVENT_STATISTICS_ATTEMPT =
  'admin/statistics/LOAD_EVENT_STATISTICS_ATTEMPT'
export const LOAD_EVENT_STATISTICS_SUCCESS =
  'admin/statistics/LOAD_EVENT_STATISTICS_SUCCESS'
export const LOAD_EVENT_STATISTICS_FAILURE =
  'admin/statistics/LOAD_EVENT_STATISTICS_FAILURE'

export const LOAD_TICKETTYPE_STATISTICS_ATTEMPT =
  'admin/statistics/LOAD_TICKETTYPE_STATISTICS_ATTEMPT'
export const LOAD_TICKETTYPE_STATISTICS_SUCCESS =
  'admin/statistics/LOAD_TICKETTYPE_STATISTICS_SUCCESS'
export const LOAD_TICKETTYPE_STATISTICS_FAILURE =
  'admin/statistics/LOAD_TICKETTYPE_STATISTICS_FAILURE'

export const UPDATE_STATISTICS_ATTEMPT =
  'admin/statistics/UPDATE_STATISTICS_ATTEMPT'
export const UPDATE_STATISTICS_SUCCESS =
  'admin/statistics/UPDATE_STATISTICS_SUCCESS'
export const UPDATE_STATISTICS_FAILURE =
  'admin/statistics/UPDATE_STATISTICS_FAILURE'

export const RESET = 'admin/statistics/RESET'
