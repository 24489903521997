import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    padding: theme.spacing(),
    marginBlock: 20
  },
  containerButton: {
    paddingLeft: theme.spacing(1)
  }
}))

export default useStyles
