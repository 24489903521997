import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useStyles } from './EventDrawList.style'
import { Button, Typography } from '@material-ui/core'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { DeleteDialog } from '~/views/shared'
import { deleteDraw } from '~/state/modules/draws'
import * as moment from 'moment'
import { Delete, Edit } from '@material-ui/icons'
import { showSnackbarError } from 'views/utils/snackbar'

export const EventDrawList = ({ onSuccess, draw, eventId }) => {
  const classes = useStyles()
  const { actionPending } = useSelector((state) => state.draws)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const dispatch = useDispatch()

  const handleDelete = async () => {
    try {
      await dispatch(deleteDraw(draw.Id))
      setShowDeleteDialog(false)
      onSuccess()
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <div className={classes.container}>
      <div
        onClick={() =>
          history.push(
            ROUTES.ADMIN.EVENTS.DRAWS.DETAILS.replace(
              ':eventId',
              eventId
            ).replace(':drawId', draw.Id)
          )
        }
        className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {draw?.Title}
          </Typography>
        </div>
        <div className={classes.labels}>
          <Typography variant='h6' component='span'>
            {moment(draw?.ScheduledOn).format('DD/MM/yyyy HH:mm')}.
          </Typography>
        </div>
        <hr className={classes.separator} />
      </div>
      <div className={classes.containerDelete}>
        <Button
          onClick={() =>
            history.push(
              ROUTES.ADMIN.EVENTS.DRAWS.UPDATE.replace(
                ':eventId',
                eventId
              ).replace(':drawId', draw.Id)
            )
          }
          color='inherit'
          className={classes.delete}>
          <Edit />
          Editar
        </Button>
        <Button
          onClick={() => {
            setShowDeleteDialog(true)
          }}
          color='inherit'
          className={classes.delete}>
          <Delete />
          Borrar
        </Button>
      </div>
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title={'Eliminar Sorteo'}
        message={'Está por eliminar el sorteo'}
        entityIdentifier={draw.Title}
      />
    </div>
  )
}

export default EventDrawList
