import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  containerProgress: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  circularProgress: {
    marginRight: '.7rem'
  }
}))

export default useStyles
