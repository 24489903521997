import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 400
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  formHeader: {
    alignItems: 'center',
    marginBlock: 10,
    width: 460
  },
  form: {
    display: 'flex',
    padding: theme.spacing()
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

export default useStyles
