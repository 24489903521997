import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBlock: 20,
    gap: theme.spacing(6)
  },
  header: {
    fontSize: theme.spacing(2)
  },
  form: {
    display: 'flex',
    padding: theme.spacing()
  },
  containerButton: {
    paddingLeft: 20
  },
  table: {
    display: 'flex'
  },
  field: {
    width: 150,
    paddingLeft: 10
  },
  autocomplete: {
    width: 300,
    height: 35
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  textDialogBold: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1)
  },
  textDialog: {
    fontStyle: 'italic'
  },
  label: {
    fontSize: 14
  },
  value: {
    fontSize: 14,
    fontWeight: 'bold'
  }
}))

export default useStyles
