import * as types from './types'

const initialState = {
  pagedList: null,
  organizer: null,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null,
  allResellers: [],
  controllers: [],
  controller: null,
  resellers: [],
  reseller: null,
  producers: [],
  producer: null,
  events: [],
  generatedUrl: '',
  pendingUrl: false,
  organizersPaged: null,
  organizers: [],
  mpInfo: null,
  pendingMpInfo: false,
  pendingCreate: false,
  pendingUpdate: false,
  enableResponse: null,
  disableResponse: null,
  pendingTogglingBilling: false,
  credentialResponse: null,
  pendingCredential: false,
  settingsResponse: null,
  pendingSettings: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        pending: true,
        organizer: null,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        organizer: action.organizer,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        pending: false,
        organizer: null,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        organizer: null,
        pendingCreate: true,
        error: null
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        organizer: action.organizer,
        pendingCreate: false,
        error: null
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        organizer: null,
        pendingCreate: false,
        error: action.error
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        // organizer: null,
        pendingUpdate: true,
        error: null
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        organizer: action.organizer,
        pendingUpdate: false,
        error: null
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        organizer: null,
        pendingUpdate: false,
        error: action.error
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        // organizer: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        // organizer: action.organizer,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        // organizer: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.LOAD_ALL_RESELLERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        allResellers: [],
        error: null
      }
    case types.LOAD_ALL_RESELLERS_SUCCESS:
      return {
        ...state,
        pending: false,
        allResellers: action.allResellers,
        error: null
      }
    case types.LOAD_ALL_RESELLERS_FAILURE:
      return {
        ...state,
        pending: false,
        allResellers: [],
        error: action.error
      }
    case types.LOAD_CONTROLLERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        controllers: [],
        error: null
      }
    case types.LOAD_CONTROLLERS_SUCCESS:
      return {
        ...state,
        pending: false,
        controllers: action.controllers,
        error: null
      }
    case types.LOAD_CONTROLLERS_FAILURE:
      return {
        ...state,
        pending: false,
        controllers: [],
        error: action.error
      }
    case types.CREATE_CONTROLLER_ATTEMPT:
      return {
        ...state,
        controller: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_CONTROLLER_SUCCESS:
      return {
        ...state,
        controller: action.controller,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_CONTROLLER_FAILURE:
      return {
        ...state,
        controller: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.LOAD_RESELLERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        resellers: [],
        error: null
      }
    case types.LOAD_RESELLERS_SUCCESS:
      return {
        ...state,
        pending: false,
        resellers: action.resellers,
        error: null
      }
    case types.LOAD_RESELLERS_FAILURE:
      return {
        ...state,
        pending: false,
        resellers: [],
        error: action.error
      }
    case types.CREATE_RESELLER_ATTEMPT:
      return {
        ...state,
        reseller: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_RESELLER_SUCCESS:
      return {
        ...state,
        reseller: action.reseller,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_RESELLER_FAILURE:
      return {
        ...state,
        reseller: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.LOAD_PRODUCERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        producers: [],
        error: null
      }
    case types.LOAD_PRODUCERS_SUCCESS:
      return {
        ...state,
        pending: false,
        producers: action.producers,
        error: null
      }
    case types.LOAD_PRODUCERS_FAILURE:
      return {
        ...state,
        pending: false,
        producers: [],
        error: action.error
      }
    case types.CREATE_PRODUCER_ATTEMPT:
      return {
        ...state,
        producer: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_PRODUCER_SUCCESS:
      return {
        ...state,
        producer: action.producer,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_PRODUCER_FAILURE:
      return {
        ...state,
        producer: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.LOAD_EVENTS_ATTEMPT:
      return {
        ...state,
        pending: true,
        events: [],
        error: null
      }
    case types.LOAD_EVENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        events: action.events,
        error: null
      }
    case types.LOAD_EVENTS_FAILURE:
      return {
        ...state,
        pending: false,
        events: [],
        error: action.error
      }
    case types.LOAD_URL_ATTEMPT:
      return {
        ...state,
        pendingUrl: true,
        generatedUrl: '',
        error: null
      }
    case types.LOAD_URL_SUCCESS:
      return {
        ...state,
        pendingUrl: false,
        generatedUrl: action.generatedUrl,
        error: null
      }
    case types.LOAD_URL_FAILURE:
      return {
        ...state,
        pendingUrl: false,
        generatedUrl: '',
        error: action.error
      }
    case types.RESET_EVENTS:
      return {
        ...state,
        events: []
      }
    case types.LOAD_ORGANIZERS_PAGED_ATTEMPT:
      return {
        ...state,
        pending: true,
        organizersPaged: null,
        error: null,
        reqParams: action.reqParams
      }
    case types.LOAD_ORGANIZERS_PAGED_SUCCESS:
      return {
        ...state,
        pending: false,
        organizersPaged: action.organizersPaged,
        error: null
      }
    case types.LOAD_ORGANIZERS_PAGED_FAILURE:
      return {
        ...state,
        pending: false,
        organizersPaged: null,
        error: action.error
      }
    case types.LOAD_ORGANIZERS_ATTEMPT:
      return {
        ...state,
        pending: true,
        organizers: [],
        error: null
      }
    case types.LOAD_ORGANIZERS_SUCCESS:
      return {
        ...state,
        pending: false,
        organizers: action.organizers,
        error: null
      }
    case types.LOAD_ORGANIZERS_FAILURE:
      return {
        ...state,
        pending: false,
        organizers: [],
        error: action.error
      }
    case types.LOAD_MP_INFO_ATTEMPT:
      return {
        ...state,
        pendingMpInfo: true,
        mpInfo: null,
        error: null
      }
    case types.LOAD_MP_INFO_SUCCESS:
      return {
        ...state,
        pendingMpInfo: false,
        mpInfo: action.mpInfo,
        error: null
      }
    case types.LOAD_MP_INFO_FAILURE:
      return {
        ...state,
        pendingMpInfo: false,
        mpInfo: null,
        error: action.error
      }
    case types.ENABLE_BILLING_ATTEMPT:
      return {
        ...state,
        pendingTogglingBilling: true,
        enableResponse: null,
        error: null
      }
    case types.ENABLE_BILLING_SUCCESS:
      return {
        ...state,
        pendingTogglingBilling: false,
        enableResponse: action.enableResponse,
        error: null
      }
    case types.ENABLE_BILLING_FAILURE:
      return {
        ...state,
        pendingTogglingBilling: false,
        enableResponse: null,
        error: action.error
      }
    case types.DISABLE_BILLING_ATTEMPT:
      return {
        ...state,
        pendingTogglingBilling: true,
        disableResponse: null,
        error: null
      }
    case types.DISABLE_BILLING_SUCCESS:
      return {
        ...state,
        pendingTogglingBilling: false,
        disableResponse: action.disableResponse,
        error: null
      }
    case types.DISABLE_BILLING_FAILURE:
      return {
        ...state,
        pendingTogglingBilling: false,
        disableResponse: null,
        error: action.error
      }
    case types.ADD_BILLING_CREDENTIAL_ATTEMPT:
      return {
        ...state,
        pendingCredential: true,
        credentialResponse: null,
        error: null
      }
    case types.ADD_BILLING_CREDENTIAL_SUCCESS:
      return {
        ...state,
        pendingCredential: false,
        credentialResponse: action.credentialResponse,
        error: null
      }
    case types.ADD_BILLING_CREDENTIAL_FAILURE:
      return {
        ...state,
        pendingCredential: false,
        credentialResponse: null,
        error: action.error
      }
    case types.ADD_BILLING_SETTINGS_ATTEMPT:
      return {
        ...state,
        pendingSettings: true,
        settingsResponse: null,
        error: null
      }
    case types.ADD_BILLING_SETTINGS_SUCCESS:
      return {
        ...state,
        pendingSettings: false,
        settingsResponse: action.settingsResponse,
        error: null
      }
    case types.ADD_BILLING_SETTINGS_FAILURE:
      return {
        ...state,
        pendingSettings: false,
        settingsResponse: null,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    case types.RESET_ACTIONS:
      return {
        ...state,
        pending: false,
        actionPending: false,
        actionSuccess: false
      }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    default:
      return state
  }
}
