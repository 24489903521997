import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useStyles } from './EventTicketList.style'
import {
  Button,
  Typography,
  CircularProgress,
  Badge,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { DeleteDialog } from '~/views/shared'
import { deleteTicket, resetActions } from '~/state/modules/events'
import * as moment from 'moment'
import JSONPretty from 'react-json-pretty'
import { Delete, FileCopy, History } from '@material-ui/icons'
import { showSnackbarError } from 'views/utils/snackbar'
import { TICKET_USAGE } from 'config/constants'
import { loadTicket } from 'state/modules/events/actions'

export const EventTicketList = ({ onSuccess, ticket, id }) => {
  const classes = useStyles()
  const { actionPending } = useSelector((state) => state.events)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showHistoryDialog, setShowHistoryDialog] = useState(false)
  const JSONPrettyMon = require('react-json-pretty/dist/monikai')

  const dispatch = useDispatch()
  const createLabel = (label, value, isId = false) => {
    return (
      <div className={classes.labelContainer}>
        <Typography variant='h6' className={`${isId && classes.labelKeys}`}>
          {label}
          <Typography
            variant='h6'
            component='span'
            className={`${isId && classes.labelKeys}`}>
            {`${isId ? value : `${value}.`}`}
          </Typography>
        </Typography>
      </div>
    )
  }

  const ticketUsage = (t) => {
    switch (t) {
      case 0:
        return 'Venta Online'
      case 1:
        return 'Sorteo'
      case 2:
        return 'Venta en Efectivo'
      case 3:
        return 'Invitación'
      case 4:
        return 'Canje'
      case 5:
        return 'Combo'
      case 6:
        return 'Gratuito'
      case 7:
        return 'Solo Cargo por Servicio'
      default:
        return ''
    }
  }

  const weekDay = (d) => {
    switch (d) {
      case 1:
        return 'Lunes'
      case 2:
        return 'Martes'
      case 3:
        return 'Miércoles'
      case 4:
        return 'Jueves'
      case 5:
        return 'Viernes'
      case 6:
        return 'Sábado'
      case 7:
        return 'Domingo'
      default:
        return ''
    }
  }

  const isSalesFreeCashOrServiceCharge = () => {
    return (
      ticket?.TicketUsage === TICKET_USAGE.SALE ||
      ticket?.TicketUsage === TICKET_USAGE.CASH ||
      ticket?.TicketUsage === TICKET_USAGE.SERVICE_CHARGE_ONLY ||
      ticket?.TicketUsage === TICKET_USAGE.FREE
    )
  }

  const handleClone = async () => {
    await dispatch(loadTicket(ticket.EventId, ticket.Id))
    history.push(
      ROUTES.ADMIN.EVENTS.TICKETS.CLONE.replace(':eventId', id)
        .replace(':originalId', ticket.Id)
        .replace(':ticketUsage', ticket.TicketUsage)
    )
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteTicket(id, ticket.Id))
      setShowDeleteDialog(false)
      onSuccess()
    } catch (error) {
      showSnackbarError(error)
    }
    dispatch(resetActions())
  }

  return (
    <div className={classes.container}>
      <div
        onClick={() =>
          history.push(
            ROUTES.ADMIN.EVENTS.TICKETS.UPDATE.replace(':eventId', id).replace(
              ':ticketId',
              ticket.Id
            )
          )
        }
        className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {ticket?.Name}
          </Typography>
          <Typography variant='subtitle2' className={classes.ticketUsage}>
            ({ticketUsage(ticket?.TicketUsage)})
          </Typography>
          {!ticket?.Active ||
          moment().subtract(3, 'hours').isAfter(ticket?.SaleEndDate) ||
          moment().subtract(3, 'hours').isBefore(ticket?.SaleStartDate) ? (
            <Badge
              badgeContent='Deshabilitado'
              className={classes.badgeInactive}
              color='primary'
            />
          ) : (
            ticket?.Remaining === 0 && (
              <Badge badgeContent='Agotado' className={classes.badgeSoldOut} />
            )
          )}
        </div>
        <div className={classes.labels}>
          {createLabel('Id: ', ticket?.Id, true)}
          {createLabel(
            'Precio $',
            isSalesFreeCashOrServiceCharge() ? ticket?.Price?.toFixed(2) : 0
          )}
          {createLabel(
            'Se rinde a $',
            isSalesFreeCashOrServiceCharge()
              ? ticket?.Cost?.toFixed(2) || Number(0).toFixed(2)
              : 0
          )}
          {createLabel('Quedan ', ticket?.Remaining)}
        </div>
        <div className={classes.labels}>
          {isSalesFreeCashOrServiceCharge() &&
          moment().subtract(3, 'hours').isAfter(ticket?.SaleStartDate) &&
          moment().subtract(3, 'hours').isBefore(ticket?.SaleEndDate) ? (
            <>
              {createLabel(
                'Habilitado hasta el día ',
                weekDay(moment(ticket?.SaleEndDate)?.day()) +
                  ' ' +
                  moment(ticket?.SaleEndDate).format('DD/MM/YYYY HH:mm')
              )}
            </>
          ) : moment().subtract(3, 'hours').isAfter(ticket?.SaleEndDate) ? (
            <>
              {createLabel(
                'Finalizó la venta el día ',
                weekDay(moment(ticket?.SaleEndDate)?.day()) +
                  ' ' +
                  moment(ticket?.SaleEndDate).format('DD/MM/YYYY HH:mm')
              )}
            </>
          ) : moment().subtract(3, 'hours').isBefore(ticket?.SaleStartDate) ? (
            <>
              {createLabel(
                'Se habilita a las ',
                moment(ticket?.SaleStartDate).format('HH:mm') +
                  ' del día ' +
                  weekDay(moment(ticket?.SaleStartDate)?.day()) +
                  ' ' +
                  moment(ticket?.SaleStartDate).format('DD/MM/YYYY') +
                  ' hasta las ' +
                  moment(ticket?.SaleEndDate).format('HH:mm') +
                  ' del día ' +
                  weekDay(moment(ticket?.SaleEndDate)?.day()) +
                  ' ' +
                  moment(ticket?.SaleEndDate).format('DD/MM/YYYY')
              )}
            </>
          ) : (
            ''
          )}
        </div>
        <hr className={classes.separator} />
      </div>
      <div className={classes.containerButtons}>
        <Button
          onClick={() => setShowHistoryDialog(true)}
          color='inherit'
          className={classes.button}>
          <History />
          {actionPending ? (
            <CircularProgress size={24} color='secondary' />
          ) : (
            'Historial'
          )}
        </Button>
        <Button
          onClick={handleClone}
          color='inherit'
          className={classes.button}>
          <FileCopy />
          {actionPending ? (
            <CircularProgress size={24} color='secondary' />
          ) : (
            'Clonar'
          )}
        </Button>
        <Button
          onClick={() => {
            setShowDeleteDialog(true)
          }}
          color='inherit'
          className={classes.button}>
          <Delete />
          Borrar
        </Button>
      </div>
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title={'Eliminar Ticket'}
        message={'Está por eliminar el ticket'}
        entityIdentifier={ticket.Name}
      />
      <Dialog
        open={showHistoryDialog}
        onClose={() => setShowHistoryDialog(false)}
        className={classes.dialog}>
        <DialogTitle>
          <Typography variant='h4'>Historial de cambios</Typography>
        </DialogTitle>
        <DialogContent>
          {ticket?.ChangeLogs ? (
            <JSONPretty
              className={classes.jsonPretty}
              data={ticket.ChangeLogs}
              theme={JSONPrettyMon}></JSONPretty>
          ) : (
            <DialogContentText>
              El ticket no registra datos Históricos.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowHistoryDialog(false)}
            color='primary'
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EventTicketList
