import * as types from './types'
import { EventsProxy, InvitationsProxy } from '~/services'

export const fetchInvitationsAttempt = () => ({
  type: types.FETCH_INVITATIONS_ATTEMPT
})

export const fetchInvitationsSuccess = (invitations) => ({
  type: types.FETCH_INVITATIONS_SUCCESS,
  invitations
})

export const fetchInvitationsFailure = (error) => ({
  type: types.FETCH_INVITATIONS_FAILURE,
  error
})

export const sendAttempt = () => ({
  type: types.SEND_ATTEMPT
})

export const sendSuccess = (invitations) => ({
  type: types.SEND_SUCCESS,
  invitations
})

export const sendFailure = (error) => ({
  type: types.SEND_FAILURE,
  error
})

export const resetInvitations = () => ({
  type: types.RESET
})

export const invitationsDetailsSet = (invitationsDetails) => ({
  type: types.SET_INVITATION_DETAILS,
  invitationsDetails
})

export const fetchInvitations = (eventId) => async (dispatch) => {
  try {
    dispatch(fetchInvitationsAttempt())

    const proxy = new EventsProxy()
    const invitations = await proxy.getAllInvitations(eventId)
    dispatch(fetchInvitationsSuccess(invitations))
  } catch (error) {
    dispatch(fetchInvitationsFailure(error))
    throw error
  }
}

export const sendInvitations = (invitation) => async (dispatch) => {
  try {
    dispatch(sendAttempt())

    const proxy = new InvitationsProxy()
    const response = await proxy.post(invitation)
    dispatch(sendSuccess(response))
  } catch (error) {
    dispatch(sendFailure(error))
    throw error
  }
}

export const setInvitationsDetails = (invitationsDetails) => (dispatch) => {
  dispatch(invitationsDetailsSet(invitationsDetails))
}
