import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'

export const OrganizerConfirmationDialog = ({
  open,
  loading,
  handleConfirmation,
  onCancel,
  title,
  message,
  entityIdentifier,
  organizerIdentifier,
  startIcon
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      backdropClick={loading}
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>
          {message}
          {entityIdentifier && (
            <Typography color='primary' component='span'>
              &nbsp;{entityIdentifier}&nbsp;
            </Typography>
          )}
          al organizador
          {organizerIdentifier && (
            <Typography color='primary' component='span'>
              &nbsp;{organizerIdentifier}
            </Typography>
          )}
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading} color='primary'>
          No
        </Button>
        <Button
          onClick={handleConfirmation}
          disabled={loading}
          color='primary'
          variant='contained'
          startIcon={startIcon}
          autoFocus>
          {loading ? <CircularProgress size={24} color='secondary' /> : 'Si'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
