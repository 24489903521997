import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadOrderByEventPaged,
  setFilters,
  refreshOrder,
  refundOrder,
  loadTicketsAmount
} from 'state/modules/orders'
import { loadEvent } from '~/state/modules/events'
import {
  ActionsMenu,
  createColumn,
  createCustomColumn,
  createMenuItem,
  TableGrid,
  TableGridOrder
} from 'views/shared'
import {
  Select,
  MenuItem,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@material-ui/core'
import * as moment from 'moment'
import history from 'config/history'
import { useStyles } from './OrdersIndexPage.style'
import { ROUTES } from 'views/routes'
import { Bookmark, Visibility } from '@material-ui/icons'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ChangeStateManually } from '../components'
import { ConfirmationDialog } from '~/views/shared'
import { showSnackbarError } from 'views/utils/snackbar'
import { Save } from '@material-ui/icons'

const makeActionItems = (
  c,
  eventId,
  setOpenDialogConfirm,
  setOrderId,
  handleClick,
  setOpenRefund,
  setOpenRefundCash
) => [
  createMenuItem('Ver orden completa', <Visibility />, () => {
    sessionStorage.setItem('orderSelected', JSON.stringify(c))
    history.push(
      ROUTES.ADMIN.EVENTS.ORDERS.DETAILS.replace(':eventId', eventId)
        .replace(':orderId', c.Id)
        .replace(':status', c.Status)
    )
  }),
  createMenuItem('Ver historial', <Visibility />, () => {
    history.push(
      ROUTES.ADMIN.EVENTS.ORDERS.HISTORY.replace(':eventId', eventId).replace(
        ':orderId',
        c.Id
      )
    )
  }),
  createMenuItem('Ver tickets por orden', <Visibility />, () => {
    history.push(
      ROUTES.ADMIN.EVENTS.ORDERS.TICKETS.replace(':eventId', eventId).replace(
        ':orderId',
        c.Id
      )
    )
  }),
  createMenuItem(
    'Actualizar desde MP',
    <RefreshIcon />,
    () => {
      setOpenDialogConfirm(true)
      setOrderId(c.Id)
    },
    true,
    c.MercadoPagoId == null || c.MercadoPagoId === 'NotSet'
  ),
  createMenuItem('Cancelar', <Bookmark />, null, true, true),
  createMenuItem(
    'Devolver',
    <Bookmark />,
    () => {
      if (c.MedioDePago === 'Cupón') {
        setOpenRefundCash(true)
      } else {
        setOpenRefund(true)
      }
      setOrderId(c.Id)
    },
    true,
    c.Status !== 'Approved'
  ),
  createMenuItem(
    '',
    <Button
      color='primary'
      aria-controls='customized-menu'
      aria-haspopup='true'
      autoFocus={false}
      disableFocusRipple
      variant='contained'
      onClick={(e) => handleClick(e, c)}>
      Cambiar de estado manualmente
    </Button>,
    null
  )
]

const makeColumns = (
  eventId,
  setOpenDialogConfirm,
  setOrderId,
  handleClick,
  setOpenRefund,
  setOpenRefundCash,
  documentId
) => {
  return [
    createColumn('Nombre', 'Nombre'),
    createColumn('DNI', documentId),
    createColumn('Telefono', 'Teléfono'),
    createColumn('FechaDeCompra', 'Fecha de Compra', 'left', (x) =>
      moment(x, 'DD/MM/yyyy HH:mm').format('DD/MM/YYYY HH:mm')
    ),
    createColumn('RRPP', 'RRPP'),
    createColumn('Entradas', 'Tickets', 'left', (x) => {
      let arrayConcat = ''
      x.forEach((t, i) => {
        if (i === x.length - 1) {
          arrayConcat += `${t.Tipo}: ${t.Cantidad}`
        } else {
          arrayConcat += `${t.Tipo}: ${t.Cantidad}, \n`
        }
      })
      return arrayConcat
    }),
    createCustomColumn(
      (c) => (
        <ActionsMenu
          items={makeActionItems(
            c,
            eventId,
            setOpenDialogConfirm,
            setOrderId,
            handleClick,
            setOpenRefund,
            setOpenRefundCash
          )}
        />
      ),
      'Acciones'
    )
  ]
}

const makeFooterActions = () => [
  {
    title: 'Volver',
    action: () => {
      history.push(ROUTES.ADMIN.EVENTS.INDEX)
    }
  }
]

export const OrdersIndexPage = ({ match }) => {
  const dispatch = useDispatch()
  const {
    pagedListByEvent,
    pending,
    filters,
    reqParams,
    actionPending,
    refundPending,
    ticketsAmount
  } = useSelector((state) => state.orders)
  const { event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const classes = useStyles()
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false)
  const [searchKey, setSearchKey] = useState(filters?.searchKey || '')
  const [status, setStatus] = useState(filters?.status || 'Approved')
  const [orderId, setOrderId] = useState()
  const [openReason, setOpenReason] = useState(false)
  const [reason, setReason] = useState('')
  const [confirmChangeState, setConfirmChangeState] = useState(false)
  const [openDialogChangeState, setOpenDialogChangeState] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [order, setOrder] = useState(null)
  const [openRefund, setOpenRefund] = useState(false)
  const [openRefundCash, setOpenRefundCash] = useState(false)
  const [successNotification, setSuccessNotification] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [showMessageError, setShowMessageError] = useState(false)
  const [totalOrders, setTotalOrders] = useState(0)
  const [openReasonRefund, setOpenReasonRefund] = useState(false)
  const [reasonRefund, setReasonRefund] = useState('')
  const isMounted = useRef(true)

  const handleChange = (event) => {
    setReason(event.target.value.trim())
  }

  const handleClick = (event, order) => {
    setAnchorEl(event.currentTarget)
    setOrder(order)
  }

  const handleList = async (
    status = filters.status,
    search = filters.searchKey
  ) => {
    dispatch(setFilters(status, search))
    try {
      await dispatch(
        loadOrderByEventPaged(match.params.eventId, status, {
          searchParams: search,
          pageNumber: 0,
          pageSize: 5,
          orderBy: 'Id',
          order: TableGridOrder.DESC
        })
      )
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchKey(filters.searchKey)
      handleList()
    }
  }

  const updateFromMP = async () => {
    try {
      await dispatch(refreshOrder(orderId))
      handleList()
    } catch (error) {
      setMessageError(error?.response?.data?.Message)
      setShowMessageError(true)
    }
    setOpenDialogConfirm(false)
  }

  const paymentStatusArray = [
    { label: 'Aprobada', value: 'Approved' },
    { label: 'Pendiente', value: 'Pending' },
    { label: 'En Progreso', value: 'InProgress' },
    { label: 'En Mediación', value: 'InMediation' },
    { label: 'Rechazada', value: 'Rejected' },
    { label: 'Cancelada', value: 'Cancelled' },
    { label: 'Devuelta', value: 'Refunded' },
    { label: 'Contracargo', value: 'ChargedBack' }
  ]

  const handleChangeReason = (event) => {
    setReasonRefund(event.target.value.trim())
  }

  const handleConfirmNotification = async () => {
    try {
      await dispatch(refundOrder(orderId, reasonRefund))
      setSuccessNotification(true)
      window.location.reload()
    } catch (error) {
      showSnackbarError(error)
    }
    setReasonRefund('')
    setOpenRefund(false)
    setOpenRefundCash(false)
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (pagedListByEvent != null) {
      setTotalOrders(pagedListByEvent.MetaData.TotalItemCount)
    }
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match, pagedListByEvent])

  useEffect(() => {
    if (isMounted.current) {
      async function fetchData() {
        dispatch(loadTicketsAmount(match.params.eventId, status))
      }

      fetchData().then()
    }
  }, [dispatch, match, status, searchKey])

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <TableGrid
        className={classes.table}
        title={
          <div>
            <div className={classes.formHeader}>
              <Typography className={classes.header} variant='h6'>
                Ordenes del Evento
              </Typography>
              <Select
                labelId='paymentStatus-label'
                name='PaymentStatus'
                id='PaymentStatus-select'
                value={filters.status}
                className={classes.field}
                fullWidth
                required
                onChange={(v) => {
                  setStatus(v.target.value)
                  setSearchKey('')
                  handleList(v.target.value, '')
                }}
                size='small'
                label='PaymentStatus'>
                {paymentStatusArray &&
                  paymentStatusArray.map((status) => (
                    <MenuItem key={status.label} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
              </Select>
              <div>
                <Typography className={classes.label}>
                  Total Ordenes:{' '}
                  <Typography component={'span'} className={classes.value}>
                    {totalOrders}
                  </Typography>
                </Typography>
                <Typography className={classes.label}>
                  Total Tickets:{' '}
                  <Typography component={'span'} className={classes.value}>
                    {ticketsAmount}
                  </Typography>
                </Typography>
              </div>
            </div>

            <div className={classes.formHeader}>
              <div className={classes.form}>
                <TextField
                  placeholder={`Ingresar Nombre ó ${documentId}`}
                  value={filters.searchKey}
                  onChange={(e) => {
                    dispatch(setFilters(filters.status, e.target.value))
                  }}
                  onKeyPress={handleSearchKeyPress}
                  className={classes.autocomplete}
                />
                <div className={classes.containerButton}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      setSearchKey(filters?.searchKey)
                      handleList()
                    }}
                    disabled={pending}
                    startIcon={<SearchIcon />}>
                    {pending ? (
                      <CircularProgress size={24} color='secondary' />
                    ) : (
                      'Buscar'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
        columns={makeColumns(
          match.params.eventId,
          setOpenDialogConfirm,
          setOrderId,
          handleClick,
          setOpenRefund,
          setOpenRefundCash,
          documentId
        )}
        loadItemsPage={(x) => {
          if (isMounted.current) {
            dispatch(
              loadOrderByEventPaged(match.params.eventId, filters.status, x)
            )
          }
        }}
        isLoading={pending}
        searchParams={filters.searchKey}
        footerActions={makeFooterActions()}
        pagedList={pagedListByEvent}
        keyName='Id'
        order={reqParams?.order || TableGridOrder.ASC}
        orderBy={reqParams?.orderBy || 'Nombre'}
        useNewFormat
      />
      <ConfirmationDialog
        open={openDialogConfirm}
        loading={actionPending}
        handleConfirmation={() => updateFromMP()}
        onCancel={() => setOpenDialogConfirm(false)}
        title={'¡ADVERTENCIA!'}
        message={'Está por actualizar el estado de la orden desde MercadoPago.'}
      />
      <Dialog
        open={showMessageError}
        onClose={() => setShowMessageError(false)}>
        <DialogTitle>¡ERROR!</DialogTitle>
        <DialogContent>
          <DialogContentText>{messageError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowMessageError(false)}
            color='primary'
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDialogChangeState}
        handleConfirmation={() => {
          setOpenDialogChangeState(false)
          setOpenReason(true)
        }}
        onCancel={() => setOpenDialogChangeState(false)}
        title='¡ADVERTENCIA!'
        message='Está por cambiar el estado de esta orden. '
      />
      <Dialog
        open={openReason}
        onClose={() => {
          setReason('')
          setOpenReason(false)
        }}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent className={classes.contentDialog}>
          <TextField
            label='Motivo'
            fullWidth
            autoFocus
            inputProps={{ maxLength: 250 }}
            placeholder='Ingrese una pequeña descripción indicando causas o motivos del cambio realizado.'
            onChange={handleChange}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReason('')
              setOpenReason(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenReason(false)
              setConfirmChangeState(true)
            }}
            color='primary'
            variant='contained'
            startIcon={<Save />}
            disabled={reason.length < 3}
            autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <ChangeStateManually
        order={order}
        eventId={event?.Id}
        status={status}
        setReason={setReason}
        reason={reason}
        confirmChangeState={confirmChangeState}
        setConfirmChangeState={setConfirmChangeState}
        setOpenDialogConfirm={setOpenDialogChangeState}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onClose={() => setAnchorEl(null)}
      />
      <ConfirmationDialog
        open={openRefund}
        loading={refundPending}
        handleConfirmation={() => {
          setOpenRefund(false)
          setOpenReasonRefund(true)
        }}
        onCancel={() => setOpenRefund(false)}
        title={'ADVERTENCIA!'}
        message={'Está por devolver el dinero de esta orden.'}
      />
      <Dialog
        open={openRefundCash}
        onClose={() => setOpenRefundCash(false)}
        backdropClick={refundPending}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle>ADVERTENCIA!</DialogTitle>
        <DialogContent>
          <Typography>
            Está por devolver la orden correspondiente a un CUPON.
          </Typography>
          <Typography>¿Desea continuar?</Typography>
          <Typography
            className={`${classes.textDialogBold} ${classes.textDialog}`}>
            Recuerde
            <Typography component='span' className={classes.textDialog}>
              &nbsp;que el cliente debe tener su cuenta de mercado pago activa.
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenRefundCash(false)}
            disabled={refundPending}
            color='primary'>
            No
          </Button>
          <Button
            onClick={() => {
              setOpenRefundCash(false)
              setOpenReasonRefund(true)
            }}
            disabled={refundPending}
            color='primary'
            variant='contained'
            autoFocus>
            {refundPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Si'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={successNotification}>
        <DialogTitle>¡Orden devuelta!</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setSuccessNotification(false)}
            color='primary'
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReasonRefund}
        onClose={() => {
          setReasonRefund('')
          setOpenReasonRefund(false)
        }}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent className={classes.contentDialog}>
          <TextField
            label='Motivo'
            fullWidth
            autoFocus
            inputProps={{ maxLength: 250 }}
            placeholder='Ingrese una pequeña descripción indicando causas o motivos de la devolución.'
            onChange={handleChangeReason}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReasonRefund('')
              setOpenReasonRefund(false)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenReasonRefund(false)
              handleConfirmNotification()
            }}
            color='primary'
            variant='contained'
            startIcon={<Save />}
            disabled={reasonRefund.length < 3}
            autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OrdersIndexPage
