import * as types from './types'

const initialState = {
  pagedList: null,
  events: [],
  ticketList: [],
  ruleTicketsPending: false,
  ruleTickets: [], // Temp fix
  event: null,
  images: [],
  ticket: null,
  pending: false,
  pendingTicket: false,
  actionPending: false,
  actionSuccess: false,
  pendingNotification: false,
  error: null,
  reqParams: null,
  ticketType: -1,
  eventsWithCombo: [],
  pendingEventsCombo: false,
  report: null,
  pendingReport: false,
  url: null,
  pendingUrl: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_EVENTS_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        error: null,
        events: []
      }
    case types.LOAD_EVENTS_SUCCESS:
      return {
        ...state,
        actionPending: false,
        error: null,
        events: action.events
      }
    case types.LOAD_EVENTS_FAILURE:
      return {
        ...state,
        actionPending: false,
        error: action.error,
        events: []
      }
    case types.LOAD_EVENTS_WITH_COMBO_ATTEMPT:
      return {
        ...state,
        pendingEventsCombo: true,
        error: null,
        eventsWithCombo: []
      }
    case types.LOAD_EVENTS_WITH_COMBO_SUCCESS:
      return {
        ...state,
        pendingEventsCombo: false,
        error: null,
        eventsWithCombo: action.eventsWithCombo
      }
    case types.LOAD_EVENTS_WITH_COMBO_FAILURE:
      return {
        ...state,
        pendingEventsCombo: false,
        error: action.error,
        eventsWithCombo: []
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        event: null,
        pending: true,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        event: action.eventObj,
        pending: false,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        event: null,
        pending: false,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        event: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        event: action.eventObj,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        event: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.LOAD_RULE_TICKETS_ATTEMPT:
      return {
        ...state,
        ruleTicketsPending: true,
        ruleTickets: [],
        error: null
      }
    case types.LOAD_RULE_TICKETS_SUCCESS:
      return {
        ...state,
        ruleTicketsPending: false,
        ruleTickets: action.tickets,
        error: null
      }
    case types.LOAD_RULE_TICKETS_FAILURE:
      return {
        ...state,
        ruleTicketsPending: false,
        ruleTickets: [],
        error: action.error
      }
    case types.LOAD_TICKET_ATTEMPT:
      return {
        ...state,
        ticket: null,
        pending: true,
        pendingTicket: true,
        error: null
      }
    case types.LOAD_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.ticket,
        pending: false,
        pendingTicket: false,
        error: null
      }
    case types.LOAD_TICKET_FAILURE:
      return {
        ...state,
        ticket: null,
        pending: false,
        pendingTicket: false,
        error: action.error
      }
    case types.LOAD_TICKET_WITH_COMBO_ATTEMPT:
      return {
        ...state,
        ticket: null,
        pendingTicket: true,
        error: null
      }
    case types.LOAD_TICKET_WITH_COMBO_SUCCESS:
      return {
        ...state,
        ticket: action.ticket,
        pendingTicket: false,
        error: null
      }
    case types.LOAD_TICKET_WITH_COMBO_FAILURE:
      return {
        ...state,
        ticketWithCombo: null,
        pendingTicket: false,
        error: action.error
      }
    case types.CREATE_TICKET_ATTEMPT:
      return {
        ...state,
        ticket: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.ticket,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_TICKET_FAILURE:
      return {
        ...state,
        ticket: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.UPDATE_TICKET_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.ticket,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.UPDATE_TICKET_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_TICKET_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_TICKET_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_TICKET_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.PAGEDLIST_TICKET_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        ticketList: []
      }
    case types.PAGEDLIST_TICKET_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        ticketList: action.ticketList
      }
    case types.PAGEDLIST_TICKET_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        ticketList: []
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.ENABLE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.ENABLE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        event: action.eventObj,
        error: null
      }
    case types.ENABLE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DISABLE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DISABLE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        event: action.eventObj,
        error: null
      }
    case types.DISABLE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.SEND_NOTIFICATION_ATTEMPT:
      return {
        ...state,
        pendingNotification: true,
        error: null
      }
    case types.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pendingNotification: false,
        error: null
      }
    case types.SEND_NOTIFICATION_FAILURE:
      return {
        ...state,
        pendingNotification: false,
        error: action.error
      }
    case types.LOAD_IMAGES_ATTEMPT:
      return {
        ...state,
        images: [],
        pending: true,
        error: null
      }
    case types.LOAD_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.images,
        pending: false,
        error: null
      }
    case types.LOAD_IMAGES_FAILURE:
      return {
        ...state,
        images: [],
        pending: false,
        error: action.error
      }
    case types.SET_DEFAULT_IMAGE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.SET_DEFAULT_IMAGE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.SET_DEFAULT_IMAGE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.ADD_IMAGE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.ADD_IMAGE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.ADD_IMAGE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_IMAGE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.GET_TICKET_TYPES_REPORT_ATTEMPT:
      return {
        ...state,
        report: null,
        pendingReport: true,
        error: null
      }
    case types.GET_TICKET_TYPES_REPORT_SUCCESS:
      return {
        ...state,
        report: action.report,
        pendingReport: false,
        error: null
      }
    case types.GET_TICKET_TYPES_REPORT_FAILURE:
      return {
        ...state,
        report: null,
        pendingReport: false,
        error: action.error
      }
    case types.SHARE_URL_ATTEMPT:
      return {
        ...state,
        url: null,
        pendingUrl: true,
        error: null
      }
    case types.SHARE_URL_SUCCESS:
      return {
        ...state,
        url: action.url,
        pendingUrl: false,
        error: null
      }
    case types.SHARE_URL_FAILURE:
      return {
        ...state,
        url: null,
        pendingUrl: false,
        error: action.error
      }
    case types.SET_TICKET_TYPE:
      return {
        ...state,
        ticketType: action.ticketType
      }
    case types.RESET_TABLE:
      return {
        ...state,
        pagedList: null,
        pending: false,
        actionPending: false,
        actionSuccess: false
      }
    case types.RESET_TICKETS:
      return {
        ...state,
        ticketList: []
      }
    case types.RESET_ACTIONS:
      return {
        ...state,
        pending: false,
        actionPending: false,
        actionSuccess: false
      }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
