import axios from 'axios'

let responseInterceptor = null

export const setupAuthorization = (token, onUnauthorized) => {
  axios.defaults.headers.common.authorization = `Bearer ${token}`
  responseInterceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        onUnauthorized()
      }
      return Promise.reject(error)
    }
  )
}

export const restoreAuthorization = () => {
  delete axios.defaults.headers.common.authorization
  axios.interceptors.request.eject(responseInterceptor)
}
