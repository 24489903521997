import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './LocationIndexPage.style'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { loadPage, deleteLocation, reset } from '~/state/modules/locations'
import {
  TableGrid,
  createColumn,
  createCustomColumn,
  TableGridOrder
} from '~/views/shared'
import { DeleteDialog } from '~/views/shared'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = (setSelectedLocation, setShowDeleteDialog) => {
  return [
    createColumn('Id', 'ID'),
    createColumn('Name', 'Nombre'),
    createColumn('FullAddress', 'Dirección'),
    createColumn('Latitude', 'Latitud'),
    createColumn('Longitude', 'Longitud'),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            history.push(ROUTES.ADMIN.LOCATIONS.UPDATE.replace(':id', c.Id))
          }}>
          <Edit />
        </Button>
      ),
      'Editar'
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            setSelectedLocation(c)
            setShowDeleteDialog(true)
          }}>
          <Delete />
        </Button>
      ),
      'Eliminar'
    )
  ]
}
const makeFooterActions = () => [
  {
    title: 'Crear',
    icon: <Add />,
    action: () => {
      history.push(ROUTES.ADMIN.LOCATIONS.CREATE)
    }
  }
]

export const LocationIndexPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pagedList, pending, actionPending, reqParams } = useSelector(
    (state) => state.locations
  )
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [requestParams, setRequestParams] = useState({})
  const [search, setSearch] = useState(reqParams?.searchParams || '')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [open, setOpen] = useState(false)

  const handleClose = async () => {
    setOpen(false)
    await dispatch(reset())
    dispatch(loadPage(requestParams))
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteLocation(selectedLocation?.Id))
      setShowDeleteDialog(false)
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    dispatch(
      loadPage({
        searchParams: search,
        pageNumber: 0,
        pageSize: 5,
        orderBy: reqParams?.orderBy || 'Name',
        order: reqParams?.order || TableGridOrder.ASC
      })
    )
  }

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Nombre'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button variant='contained' color='primary' onClick={performSearch}>
          Buscar
        </Button>
      </div>
    )
  }

  return (
    <>
      <TableGrid
        title='Lugares'
        rowClasses={(x) => x?.IsEnabled && `${classes.conditionalRow}`}
        columns={makeColumns(setSelectedLocation, setShowDeleteDialog)}
        footerActions={makeFooterActions()}
        searchForm={makeSearchForm}
        searchParams={search}
        loadItemsPage={(x) => {
          setRequestParams(x)
          dispatch(
            loadPage({
              searchParams: search,
              pageNumber: x.pageNumber || reqParams?.pageNumber,
              pageSize: x.pageSize,
              orderBy: x.orderBy,
              order: x.order
            })
          )
        }}
        isLoading={pending}
        pagedList={pagedList}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.ASC}
        orderBy={reqParams?.orderBy || 'Name'}
        useNewFormat
      />
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title={'Eliminar Localidad'}
        message={'Está por eliminar la localidad'}
        entityIdentifier={selectedLocation?.Name}
      />
      <Dialog open={open}>
        <DialogTitle>Localidad eliminada</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LocationIndexPage
