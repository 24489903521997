import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  field: {
    margin: theme.spacing()
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  }
}))

export default useStyles
