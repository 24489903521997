import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROLES } from 'config/constants'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  LinearProgress,
  TableRow
} from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { loadEvent, loadEventManager } from '~state/modules/events'
import {
  loadEventStatistics,
  loadTicketsStatisticsByEvent,
  updateStatistics
} from '~state/modules/statistics'
import { useStyles } from './EventsInfoPage.style'
import { ROUTES } from '~/views/routes'
import { EventStatisticsTicketList } from '../components'
import history from '~/config/history'
import moment from 'moment'
import numeral from 'numeral'
import { showSnackbarSuccess } from 'views/utils/snackbar'

export const EventsInfoPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.login)
  const rolesArray = user?.roles?.split(',') || []
  const isAdmin = rolesArray?.includes(ROLES.ADMIN)
  const isNotWithdrawpoint = !rolesArray?.includes(ROLES.WITHDRAWPOINT)
  const { event, pending } = useSelector((state) => state.events)
  const [progress, setProgress] = useState(0)

  const {
    eventStatistics,
    ticketsStatistics,
    pending: pendingStatistics
  } = useSelector((state) => state.statistics)

  const createRow = (label, value, formatter) => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {formatter ? formatter(value) : value}
        </TableCell>
      </TableRow>
    )
  }

  const handleBack = () => {
    history.push(ROUTES.ADMIN.EVENTS.INDEX)
  }

  const update = async () => {
    await dispatch(updateStatistics(match.params.eventId))
    await dispatch(loadEventStatistics(match.params.eventId))
    await dispatch(loadTicketsStatisticsByEvent(match.params.eventId))
    showSnackbarSuccess(
      '¡Se ha generado una solicitud para actualizar estadísticas!',
      ''
    )
  }

  useEffect(() => {
    async function fetchData() {
      if (isAdmin) {
        await dispatch(loadEventManager(match.params.eventId))
      } else {
        await dispatch(loadEvent(match.params.eventId))
      }
      await dispatch(loadEventStatistics(match.params.eventId))
      await dispatch(loadTicketsStatisticsByEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match, isAdmin])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          dispatch(updateStatistics(match.params.eventId))
          dispatch(loadEventStatistics(match.params.eventId))
          dispatch(loadTicketsStatisticsByEvent(match.params.eventId))
          return 0
        }
        return Math.min(oldProgress + 1, 100)
      })
    }, 600)

    return () => {
      clearInterval(timer)
    }
  }, [dispatch, match])

  return (
    <PaperContainer title={'Información del evento'}>
      <div className={classes.time}>
        <Button disabled={pendingStatistics} onClick={update} color='primary'>
          Actualizar
        </Button>
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          <Table>
            <TableBody>
              {createRow('ID', event?.Id)}
              {createRow('Nombre', event?.Title)}
              {createRow('Fecha', event?.StartDateTime, (x) =>
                moment(x).format('DD/MM/YYYY HH:mm')
              )}
              {createRow('Organizador', event?.Organizer?.Name)}
              {isAdmin &&
                createRow(
                  'Rendir directo al organizador',
                  event?.PayDirectToOrganizer ? 'Sí' : 'No'
                )}
              {isAdmin &&
                createRow(
                  'Visible para el organizador',
                  event?.IsVisibleForOrganizer ? 'Sí' : 'No'
                )}
              {isAdmin &&
                createRow(
                  'Incluir en contabilidad de MP',
                  event?.IncludeInMercadoPagoAccounting ? 'Sí' : 'No'
                )}
              {createRow('Lugar', event?.Location?.Name)}
              {createRow('Habilitado', event?.IsEnabled ? 'Sí' : 'No')}
              {isAdmin && createRow('Oculto', event?.IsHidden ? 'Sí' : 'No')}
              {createRow('Facturable', event?.Billable ? 'Sí' : 'No')}
            </TableBody>
          </Table>
          <LinearProgress variant='determinate' value={progress} />
          <Table>
            <TableBody>
              {createRow('Total Entradas', eventStatistics?.TotalTickets)}
              {createRow('Retiradas', eventStatistics?.TotalWithdrawals)}
              {isNotWithdrawpoint &&
                createRow('Total Vendidas', eventStatistics?.TotalSales)}
              {isNotWithdrawpoint &&
                createRow('Contracargo', eventStatistics?.TotalChargeBacks)}
              {isNotWithdrawpoint &&
                createRow('Productor', eventStatistics?.TotalProfit, (x) =>
                  numeral(x).format('$0,0[.]00')
                )}
              {isNotWithdrawpoint &&
                createRow(
                  'Costo x Contracargos',
                  eventStatistics?.TotalLossDueToChargeBacks,
                  (x) => numeral(x).format('$0,0[.]00')
                )}
              {isAdmin &&
                createRow(
                  'TicketPass Comisión (bruta)',
                  eventStatistics?.TotalTicketPassProfit,
                  (x) => numeral(x).format('$0,0[.]00')
                )}
              {isAdmin &&
                createRow(
                  'TicketPass Costo x Contracargos',
                  eventStatistics?.TotalTicketPassLossDueToChargeBacks,
                  (x) => numeral(x).format('$0,0[.]00')
                )}
              {isAdmin &&
                createRow(
                  'TicketPass Descuento por Fidelización',
                  eventStatistics?.TotalTicketPassDiscountDueToLoyaltyProgram,
                  (x) => numeral(x).format('$0,0[.]00')
                )}
            </TableBody>
          </Table>

          {ticketsStatistics &&
            ticketsStatistics.map((ticket, i) => (
              <EventStatisticsTicketList
                ticket={ticket}
                key={i}
                isNotWithdrawpoint={isNotWithdrawpoint}
              />
            ))}

          <div className={classes.actionsContainer}>
            <Button onClick={handleBack} color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default EventsInfoPage
