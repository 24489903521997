import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  containerDelete: {
    display: 'flex',
    alignItems: 'center'
  },
  containerList: {
    width: '100%',
    backgroundColor: '#F2F3F4'
  },
  delete: {
    display: 'block'
  },
  title: {
    fontWeight: 'bold',
    marginBlock: 10
  },
  separator: {
    backgroundColor: 'black',
    border: 0,
    height: 1
  },
  nameStyle: {
    display: 'flex',
    marginLeft: 10
  }
}))

export default useStyles
