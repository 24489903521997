import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  labels: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#666666',
    marginLeft: 20
  },
  XAxisLabel: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export default useStyles
