import { makeStyles } from '@material-ui/core/styles'
import logoImg from '~/assets/img/logo.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.grey[700]
  },
  logo: {
    backgroundImage: `url(${logoImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 150,
    height: 150
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginTop: 15,
    marginRight: 15
  },
  flag: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(1)
  }
}))

export default useStyles
