import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  containerList: {
    width: '100%'
  },
  labelsInline: {
    display: 'flex'
  },
  labelContainer: {
    marginLeft: theme.spacing(1)
  },
  label: {
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bold',
    marginBlock: theme.spacing(1)
  },
  separator: {
    backgroundColor: 'lightgray',
    border: 0,
    height: 1
  },
  nameStyle: {
    display: 'flex',
    marginLeft: theme.spacing(1)
  }
}))

export default useStyles
