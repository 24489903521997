import React from 'react'
import { Button, TextField } from '@material-ui/core'
import { useStyles } from './ResellerSearchComponent.style'
import { CircularProgress } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export const ResellerSearchComponent = ({
  performSearch,
  searchValue,
  setSearch,
  pending,
  rrpp
}) => {
  const classes = useStyles()

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch(rrpp)
    }
  }

  return (
    <div>
      <div className={classes.form}>
        <TextField
          placeholder='Ingresar Nombre'
          value={searchValue}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          className={classes.field}
          onKeyPress={handleSearchKeyPress}
        />
        <div className={classes.containerButton}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => performSearch(rrpp)}
            disabled={pending}
            startIcon={<SearchIcon />}>
            {pending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Buscar'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default ResellerSearchComponent
