import React, { useCallback, useEffect, useState } from 'react'
import useStyles from './TicketRules.style'
import clsx from 'clsx'
import { CircularProgress, Typography } from '@material-ui/core'
import { TICKET_RULE } from 'config/constants'
import { useFormikContext } from 'formik'

const SelectorItem = ({ label, selected, showTag, onClick }) => {
  const classes = useStyles()
  return (
    <li
      className={clsx({
        [classes.selectorItem]: true,
        [classes.selectorItemSelected]: selected
      })}
      onClick={onClick}>
      <span style={{ flex: '1', fontWeight: selected ? 'bold' : 'normal' }}>
        {label}
      </span>
      {selected || showTag ? (
        <div className={classes.tag}>
          {selected ? (
            <Typography style={{ fontSize: '.70rem' }} component={'b'}>
              Seleccionado
            </Typography>
          ) : null}
        </div>
      ) : null}
    </li>
  )
}

const Selector = ({
  label,
  pending,
  items = [],
  itemLabel,
  showTag,
  selected,
  onItemClick
}) => {
  const classes = useStyles()

  const handleItemClick = (item) => {
    onItemClick(item)
  }

  return (
    <div className={classes.selectorContainer}>
      <Typography variant={'h4'}>{label}</Typography>
      <ul className={classes.selector}>
        {pending ? (
          <div className={classes.selectorLoadingBox}>
            <CircularProgress size={24} />
            <Typography>Cargando...</Typography>
          </div>
        ) : (
          items.map((i) => (
            <SelectorItem
              key={i?.Id}
              showTag={showTag ? showTag(i) : false}
              selected={selected ? selected(i) : false}
              label={i[itemLabel]}
              onClick={() => handleItemClick(i)}
            />
          ))
        )}
      </ul>
    </div>
  )
}

export const EventTicketSelector = ({
  events = [],
  tickets = [],
  pendingEvents,
  pendingTickets,
  type,
  selectedEventIds = [],
  selectedTicketIds = [],
  onRequestTicketsOfEvent,
  onChange
}) => {
  const { setFieldValue } = useFormikContext()
  const [selectedEvents, setSelectedEvents] = useState([])
  const [lastSelectedEvent, setLastSelectedEvent] = useState(null)
  const [selectedTickets, setSelectedTickets] = useState([])
  const classes = useStyles()

  const onInputChange = useCallback(
    (events, tickets) => {
      setFieldValue('RequestedTicketIds', tickets)
      onChange(events, tickets)
    },
    [setFieldValue, onChange]
  )

  const handleEventSelect = (event) => {
    setLastSelectedEvent(event)

    if (type === TICKET_RULE.BOUGHT_ANY_TICKET_FROM_EVENT) {
      if (lastSelectedEvent?.Id === event.Id) return

      onRequestTicketsOfEvent(event)
    } else {
      setSelectedEvents((prev) => {
        let newArray
        if (prev.find((e) => e.Id === event.Id)) {
          newArray = [...prev.filter((e) => e.Id !== event.Id)]
        } else {
          newArray = [...prev, event]
        }

        onInputChange(newArray)
        return newArray
      })
    }
  }

  const updateSelectedEvents = (selectedTickets) => {
    setSelectedEvents((prevState) => {
      let newArray
      let ticketsOfEvent = tickets.filter((t) => selectedTickets.includes(t.Id))

      if (ticketsOfEvent?.length) {
        if (prevState.find((e) => e.Id === lastSelectedEvent.Id))
          return prevState

        newArray = [...prevState, lastSelectedEvent]
      } else {
        newArray = [...prevState.filter((e) => e.Id !== lastSelectedEvent.Id)]
      }

      return newArray
    })
  }

  const handleTicketSelect = (ticket) => {
    setSelectedTickets((prev) => {
      let newArray

      if (prev.includes(ticket.Id)) {
        newArray = [...prev.filter((e) => e !== ticket.Id)]
      } else {
        newArray = [...prev, ticket.Id]
      }

      updateSelectedEvents(newArray)
      return newArray
    })
  }

  useEffect(() => {
    if (events?.length) {
      setSelectedEvents((prevState) => {
        if (prevState.length) {
          return prevState
        }

        return events.filter((e) => selectedEventIds.includes(e.Id))
      })
    }
  }, [events, selectedEventIds])

  useEffect(() => {
    if (selectedTicketIds) {
      setSelectedTickets((prevState) => {
        if (prevState.length) {
          return prevState
        }

        return selectedTicketIds
      })
    }
  }, [selectedTicketIds])

  return (
    <div className={classes.etsContainer}>
      <Selector
        label={'Eventos'}
        itemLabel={'Title'}
        items={events}
        pending={pendingEvents}
        showTag={(i) => !!selectedEvents.find((t) => t?.Id === i?.Id)}
        selected={(i) =>
          (type === TICKET_RULE.BOUGHT_ANY_TICKET_FROM_EVENT
            ? [lastSelectedEvent]
            : selectedEvents
          )?.find((t) => t?.Id === i?.Id)
        }
        onItemClick={handleEventSelect}
      />
      {type === TICKET_RULE.BOUGHT_ANY_TICKET_FROM_EVENT ? (
        <Selector
          label={'Tickets'}
          itemLabel={'Name'}
          items={tickets}
          pending={pendingTickets}
          showTag={() => false}
          selected={(i) => selectedTickets.find((t) => t === i.Id)}
          onItemClick={handleTicketSelect}
        />
      ) : null}
    </div>
  )
}

export default EventTicketSelector
