import React from 'react'
import { useDispatch } from 'react-redux'
import { useStyles } from './EventTicketOrder.style'
import { Button, Typography } from '@material-ui/core'
import { update } from '~/state/modules/orders'
import { showSnackbarError } from 'views/utils/snackbar'

export const EventTicketOrder = ({
  onSuccess,
  order,
  id,
  pending,
  documentId
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const arraySummary = Object.entries(order?.TicketSummary)
  const tickets = order?.Tickets

  const TicketIds = () => {
    let ticketIds = []
    tickets.forEach(function (ticket) {
      ticketIds.push(ticket.Id)
    })
    return ticketIds
  }

  const values = {
    TicketIds: TicketIds(),
    Withdrawn: false,
    EventId: id
  }

  const marcarRetirado = async (retirado) => {
    try {
      values.Withdrawn = retirado
      await dispatch(update(values))
      onSuccess()
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const createLabel = (label, value, strikethrough = false) => {
    return (
      <>
        <Typography className={classes.labelKeys}>
          {label}:{' '}
          <Typography
            className={
              strikethrough ? classes.labelValuesThrough : classes.labelValues
            }
            component='span'>
            {value}
          </Typography>
        </Typography>
      </>
    )
  }

  return (
    <div
      className={`${classes.orderContainer} ${
        tickets.every((x) => x.Withdrawn)
          ? classes.containersPink
          : tickets.some((x) => x.Withdrawn)
            ? classes.containersOrange
            : classes.containersGreen
      }`}>
      {createLabel('Nombre', order?.FullName)}
      {createLabel(documentId, order?.DNI)}
      {createLabel('Teléfono', order?.PhoneNumber)}
      <hr className={classes.separator} />
      <Typography className={classes.title}>Resumen</Typography>
      {arraySummary.map((field, i) => (
        <div key={i}>
          <Typography className={classes.labelValues}>
            {field[1]}{' '}
            <Typography className={classes.labelKeys} component='span'>
              {field[0]}
            </Typography>
          </Typography>
        </div>
      ))}
      <hr className={classes.separator} />
      <Typography className={classes.title}>Detalle</Typography>
      {tickets.map((ticket, i) => (
        <div key={i}>
          {createLabel(
            'Código',
            ticket.ConfirmationCode + '   (' + ticket.Type + ')',
            ticket.Withdrawn
          )}
        </div>
      ))}
      {tickets.every((x) => x.Withdrawn) ? (
        <Button
          className={classes.button}
          variant='contained'
          disabled={pending}
          onClick={() => marcarRetirado(false)}>
          MARCAR COMO NO RETIRADA
        </Button>
      ) : (
        <Button
          className={classes.button}
          variant='contained'
          disabled={pending}
          onClick={() => marcarRetirado(true)}>
          MARCAR COMO RETIRADA
        </Button>
      )}
    </div>
  )
}

export default EventTicketOrder
