import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { loadEvent } from '~/state/modules/events'
import { useStyles } from './TransferOrderPage.style'
import { loadOrderDetails, transferOrder } from '~/state/modules/orders'
import { UsersSearch } from '../components/UsersSearch'
import { useHistory } from 'react-router-dom'
import { ConfirmationDialog } from '~/views/shared'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const Labels = ({ label, value }) => (
  <Typography>
    {label}:&nbsp;
    <Typography component='span' style={{ fontWeight: 'bold' }}>
      {value}
    </Typography>
  </Typography>
)

export const TransferOrderPage = ({ match }) => {
  const { eventId, orderId } = match.params
  const classes = useStyles()
  const dispatch = useDispatch()
  const { country } = useSelector((state) => state.app)
  const document = country === 'ar' ? 'DNI' : 'CI'
  const { order, pending, transferPending } = useSelector(
    (state) => state.orders
  )
  const { event } = useSelector((state) => state.events)
  const [user, setUser] = useState()
  const [open, setOpen] = useState(false)
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(eventId))
    }

    fetchData().then()
  }, [dispatch, eventId])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrderDetails(orderId))
    }

    fetchData().then()
  }, [dispatch, orderId])

  const onSuccess = (user) => {
    setUser(user)
    window.scroll(0, 0)
  }

  const handleConfirmation = async () => {
    try {
      await dispatch(transferOrder(orderId, user?.Id))
      showSnackbarSuccess('Orden transferida!')
      history.goBack()
    } catch (error) {
      showSnackbarError(error, 4500)
    }
    setOpen(false)
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer>
        <div className={classes.containerDetails}>
          <div className={classes.labels}>
            <Labels label={'De'} value={order != null && order[0].Nombre} />
            <Labels label={'Para'} value={user?.FullName} />
            <Labels label={document} value={user?.DNI} />
          </div>
          <Button
            className={classes.buttonTransfer}
            onClick={() => setOpen(true)}
            disabled={user == null || transferPending}
            variant='contained'
            color='primary'>
            Transferir
          </Button>
        </div>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <UsersSearch onSuccess={onSuccess} />
            <div className={classes.actionsContainer}>
              <Button onClick={() => history.goBack()} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
      <ConfirmationDialog
        open={open}
        loading={transferPending}
        handleConfirmation={handleConfirmation}
        onCancel={() => setOpen(false)}
        title={'ADVERTENCIA!'}
        message={'Está por transferir la orden del usuario'}
        entityIdentifier={order != null && order[0].Nombre}
        optionalMessage={'correspondiente al evento'}
        secondEntity={event?.Title}
        thirdMessage={'al usuario'}
        thirdEntity={user?.FullName}
      />
    </>
  )
}

export default TransferOrderPage
