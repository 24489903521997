import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  containerDetails: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  labels: {
    marginRight: theme.spacing(10)
  },
  buttonTransfer: {
    height: 'fit-content',
    alignSelf: 'center',
    width: 150
  }
}))

export default useStyles
