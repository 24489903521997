import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import {
  loadTicketPage,
  setTicketType,
  loadEvent
} from '~/state/modules/events'
import { useStyles } from './TicketsIndexPage.style'
import { EventTicketList } from '../../components'
import { ROUTES } from '~/views/routes'
import { Add } from '@material-ui/icons'
import history from '~/config/history'
import { TICKET_USAGE } from 'config/constants'

export const TicketsIndexPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { ticketList, pending, event, ticketType } = useSelector(
    (state) => state.events
  )
  const [ticketUsage, setTicketUsage] = useState(ticketType)
  const [filteredTicketList, setFilteredTicketList] = useState()
  const [anchor, setAnchor] = useState(null)

  const handleClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const redirect = (e) => {
    const ticketUsage = e.target.value
    history.push(
      ROUTES.ADMIN.EVENTS.TICKETS.CREATE.replace(
        ':eventId',
        match.params.eventId
      ).replace(':ticketUsage', ticketUsage)
    )
    setAnchor(null)
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadTicketPage(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    if (ticketList != null) {
      if (ticketUsage === -1) {
        setFilteredTicketList(ticketList)
      } else {
        const newList = ticketList.filter((t) => ticketUsage === t.TicketUsage)
        setFilteredTicketList(newList)
      }
    }
  }, [ticketList, setFilteredTicketList, ticketUsage])

  const handleBack = () => {
    history.push(ROUTES.ADMIN.EVENTS.INDEX)
  }

  const onSuccess = async () => {
    await dispatch(loadTicketPage(match.params.eventId))
  }

  const ticketUsages = [
    {
      label: 'Mostrar todos',
      value: -1
    },
    {
      label: 'Venta Online',
      value: TICKET_USAGE.SALE
    },
    {
      label: 'Sorteo',
      value: TICKET_USAGE.DRAW
    },
    {
      label: 'Venta en Efectivo',
      value: TICKET_USAGE.CASH
    },
    {
      label: 'Invitación',
      value: TICKET_USAGE.INVITATION
    },
    {
      label: 'Canje',
      value: TICKET_USAGE.SWAP
    },
    {
      label: 'Combo',
      value: TICKET_USAGE.COMBO
    },
    {
      label: 'Gratuito',
      value: TICKET_USAGE.FREE
    },
    {
      label: 'Solo Cargo por Servicio',
      value: TICKET_USAGE.SERVICE_CHARGE_ONLY
    }
  ]

  const ticketTypeFilter = (ticketUsage) => {
    dispatch(setTicketType(ticketUsage))
    if (ticketUsage === -1) {
      setFilteredTicketList(ticketList)
    } else {
      const newList = ticketList.filter((t) => ticketUsage === t.TicketUsage)
      setFilteredTicketList(newList)
    }
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer
        title={
          <div className={classes.titleContainer}>
            <div className={classes.titleAndSelect}>
              <Typography variant='h6'>LISTADO DE TICKETS</Typography>
              <Select
                className={classes.field}
                value={ticketUsage}
                required
                onChange={(v) => {
                  setTicketUsage(v.target.value)
                  ticketTypeFilter(v.target.value)
                }}
                size='small'>
                {ticketUsages.map((t, i) => (
                  <MenuItem key={i} value={t.value}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button
              variant='text'
              color='primary'
              key='Crear'
              onClick={handleClick}>
              Crear Ticket <Add />
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchor}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={!!anchor}
              keepMounted
              onClose={handleClose}>
              {ticketUsages.splice(1).map((t, i) => (
                <MenuItem key={i} value={t.value} onClick={redirect}>
                  {t.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        }>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            {filteredTicketList &&
              filteredTicketList.map((ticket, i) => (
                <EventTicketList
                  ticket={ticket}
                  onSuccess={onSuccess}
                  id={match.params.eventId}
                  key={i}
                />
              ))}

            <div className={classes.actionsContainer}>
              <Button onClick={handleBack} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default TicketsIndexPage
