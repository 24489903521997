import React from 'react'
import { AppContainer } from 'views/shared'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from 'views/routes'
import history from 'config/history'
import { DashboardIndexPage } from './DashboardIndexPage'
import { DashboardInfoPage } from './DashboardInfoPage'
import { BarChartDetailsPage } from './components/StackedBarChartWidget/BarChartDetailsPage'

export const DashboardPage = () => {
  const redirectToDashboard = async (eventId) => {
    history.push(ROUTES.ADMIN.DASHBOARD.VIEW.replace(':eventId', eventId))
  }

  return (
    <AppContainer title='Dashboard'>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN.DASHBOARD.INDEX}
          component={DashboardIndexPage}
        />

        <Route
          exact
          path={ROUTES.ADMIN.DASHBOARD.VIEW}
          component={DashboardInfoPage}
        />

        <Route
          exact
          path={ROUTES.ADMIN.DASHBOARD.DETAILS}
          render={(props) => (
            <BarChartDetailsPage {...props} onBack={redirectToDashboard} />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default DashboardPage
