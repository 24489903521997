import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Button } from '@material-ui/core'
import { create } from '~/state/modules/resellers'
import { PaperContainer } from '~/views/shared'
import { ResellersForm } from '../components/ResellersForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import SearchIcon from '@material-ui/icons/Search'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const ResellersCreatePage = ({ onSuccess, onCancel, user }) => {
  const dispatch = useDispatch()
  const { pending, actionPending, actionSuccess } = useSelector(
    (state) => state.resellers
  )

  const values = {
    DisplayName: user?.FullName || '',
    UserId: user?.Id || '',
    BankAccountNumber: '',
    Cuit: '',
    MercadoPagoEmail: '',
    Active: true,
    SellForTicketPass: true
  }

  const handleSubmit = async (reseller, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(create(reseller))
      onSuccess()
      showSnackbarSuccess('RRPP Creado!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <PaperContainer title='Creación de Rrpp'>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <div>
          <Button
            style={{ margin: '10px' }}
            variant='contained'
            color='primary'
            onClick={() => history.push(ROUTES.ADMIN.RESELLERS.SEARCH)}
            disabled={actionPending || actionSuccess}
            startIcon={<SearchIcon />}>
            {actionPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Buscar Usuario para crear RRPP'
            )}
          </Button>
          <ResellersForm
            values={values}
            actionText='Crear'
            handleSubmit={handleSubmit}
            actionPending={actionPending}
            actionSuccess={actionSuccess}
            onCancel={onCancel}
          />
        </div>
      )}
    </PaperContainer>
  )
}

export default ResellersCreatePage
