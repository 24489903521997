import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -10
  },
  button: {
    position: 'absolute',
    right: 3,
    top: 3,
    color: theme.palette.grey[600]
  },
  errorContainer: {
    border: '1px solid red',
    borderRadius: '.2rem',
    backgroundColor: '#ffdcdc',
    margin: '0 0 ' + theme.spacing(1),
    padding: theme.spacing(1)
  },
  infoContainer: {
    border: '1px solid #9EEAF9',
    borderRadius: '.2rem',
    color: '#0A58CA',
    backgroundColor: '#CFF4FC',
    margin: '0 0 ' + theme.spacing(1),
    padding: theme.spacing(1)
  },
  buttonsContainerInfo: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  },
  buttonInfo: {
    color: 'black',
    background: 'whitesmoke',
    '&:hover': {
      background: '#dfdfdf'
    }
  },
  textField: {
    marginTop: 12
  },
  login: {
    textTransform: 'none',
    marginBottom: theme.spacing(1)
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    lineHeight: 'inherit',
    marginInline: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#4e4646'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
    flexWrap: 'wrap'
  },
  check: {
    '& .MuiSvgIcon-root': { fontSize: 30 }
  },
  checkLabel: {
    textAlign: 'center'
  },
  checkLabelTerms: {
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  fieldRow: {
    display: 'block',
    marginTop: 14,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  field: {
    width: theme.breakpoints.up('sm') ? 200 : '-webkit-fill-available',
    height: 40,
    marginRight: theme.breakpoints.up('sm') && 10,
    marginBottom: theme.breakpoints.down('sm') && 10
  },
  flag: {
    marginInline: theme.spacing(1),
    fontSize: 25
  },
  warning: {
    marginTop: 8,
    alignItems: 'center',
    fontSize: 16,
    fontStyle: 'italic',
    '& svg': {
      fontSize: 30
    }
  }
}))

export default useStyles
