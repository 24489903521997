import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { ImagePicker, PaperContainer } from '~views/shared'
import {
  addImage,
  loadImages,
  setDefaultImage,
  loadEvent
} from '~state/modules/events'
import { useStyles } from './EventsImagePage.style'
import { Add, Delete, Image } from '@material-ui/icons'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import copy from 'copy-to-clipboard'
import { EventImageDeleteDialog } from '../components'

export const EventsImagePage = ({ match, onAddImage, onCancel }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const fileInput = useRef(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [imageId, setImageId] = useState('')
  const { images, pending, actionPending, actionSuccess, event } = useSelector(
    (state) => state.events
  )

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  const handleDeleteSuccess = async () => {
    setTimeout(() => {
      setShowDeleteDialog(false)
      window.location.reload()
    }, 2000)
  }

  const handleSetDefaultImage = async (imageId) => {
    try {
      const success = await dispatch(
        setDefaultImage(match.params.eventId, imageId)
      )
      if (success) {
        reloadPage()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validateExtension = (fileName) => {
    const extension = fileName
      .substring(fileName.lastIndexOf('.'), fileName.length)
      .toLowerCase()
    if (
      document
        .getElementById('imageFile')
        .getAttribute('accept')
        .split(',')
        .indexOf(extension) < 0
    ) {
      showSnackbarError(
        `Archivo inválido. No se permite la extensión ${extension}`
      )
      return false
    }
    return true
  }

  const handleFile = async (event) => {
    if (event.target.files.length === 0) return
    const file = event.target.files[0]
    if (validateExtension(file.name)) {
      try {
        const formData = new FormData()
        formData.append('image', file)

        const success = await dispatch(addImage(match.params.eventId, formData))
        if (success) {
          onAddImage(match.params.eventId)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const copyToClipBoard = (urlImage) => {
    try {
      copy(urlImage)
      showSnackbarSuccess('imagen copiada al portapapeles! ')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadImages(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Imágenes del Evento'>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <input
              type='file'
              id='imageFile'
              style={{ display: 'none' }}
              accept='.png,.jpg,.jpeg,.pjp,.pjpeg,.jfif'
              onChange={handleFile}
              ref={fileInput}
            />
            {images &&
              images.map((i) => (
                <div key={i.Id} className={classes.imagePickerContainer}>
                  <ImagePicker
                    className={classes.imagePicker}
                    onImageChange={() => {}}
                    onClickImage={copyToClipBoard}
                    url={i.Uri}
                  />
                  <div className={classes.imagePickerSideBar}>
                    <div className={classes.imagePickerInfo}>
                      <Typography>
                        Es imagen predeterminada: {i.IsDefault ? 'Sí' : 'No'}
                      </Typography>
                    </div>

                    <Button
                      className={classes.button}
                      onClick={() => {
                        handleSetDefaultImage(i.Id)
                      }}
                      disabled={actionPending || actionSuccess || i.IsDefault}
                      color='primary'
                      startIcon={<Image />}>
                      Usar como imagen predeterminada
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        setImageId(i.Id)
                        setShowDeleteDialog(true)
                      }}
                      disabled={actionPending || actionSuccess || i.IsDefault}
                      color='primary'
                      startIcon={<Delete />}>
                      Eliminar Imagen
                    </Button>
                  </div>
                </div>
              ))}
            <div className={classes.actionsContainer}>
              <Button onClick={onCancel} color='primary'>
                Volver
              </Button>
              <Button
                onClick={() => fileInput.current.click()}
                color='primary'
                disabled={actionPending || actionSuccess}
                variant='contained'
                startIcon={<Add />}>
                {actionPending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Agregar Imagen'
                )}
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
      <EventImageDeleteDialog
        open={showDeleteDialog}
        eventId={match.params.eventId}
        imageId={imageId}
        onSuccess={handleDeleteSuccess}
        onCancel={() => setShowDeleteDialog(false)}
        actionPending={actionPending}
        actionSuccess={actionSuccess}
      />
    </>
  )
}
