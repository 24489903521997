import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Menu, MenuItem, Button } from '@material-ui/core'
import {
  changeManualUpdatePaymentStatus,
  loadOrderByEventPaged,
  loadOrderDetails,
  loadTicketsAmount
} from '~/state/modules/orders'
import { TableGridOrder } from 'views/shared'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { useStyles } from './ChangeStateManually.style'

export const ChangeStateManually = ({
  order,
  eventId,
  status,
  setReason,
  reason,
  confirmChangeState,
  setConfirmChangeState,
  setOpenDialogConfirm,
  anchorEl,
  setAnchorEl,
  onClose
}) => {
  const [paymentStatus, setPaymentStatus] = useState('')

  const dispatch = useDispatch()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  const manualUpdatePaymentStatusOrder = useCallback(async () => {
    if (confirmChangeState && paymentStatus !== '' && paymentStatus != null) {
      setConfirmChangeState(false)
      try {
        await dispatch(
          changeManualUpdatePaymentStatus(order.Id, paymentStatus, reason)
        )
        await dispatch(loadOrderDetails(order.Id))
        await dispatch(
          loadOrderByEventPaged(eventId, status, {
            searchParams: '',
            pageNumber: 0,
            pageSize: 5,
            orderBy: 'Id',
            order: TableGridOrder.DESC
          })
        )
        let totalTickets = 0
        order.Entradas.forEach((t) => (totalTickets += t.Cantidad))
        dispatch(loadTicketsAmount(eventId, status))
        showSnackbarSuccess('La orden fue actualizada')
      } catch (err) {
        showSnackbarError(err)
      }
      setReason('')
      onClose()
    }
  }, [
    dispatch,
    confirmChangeState,
    setConfirmChangeState,
    eventId,
    order,
    paymentStatus,
    reason,
    setReason,
    status,
    onClose
  ])

  const createMenuItem = (status, label) => {
    return (
      <MenuItem
        disabled={order?.Status === status}
        onClick={() => {
          setPaymentStatus(status)
          setAnchorEl(null)
          setOpenDialogConfirm(true)
        }}>
        {label}
      </MenuItem>
    )
  }

  useEffect(() => {
    manualUpdatePaymentStatusOrder()
  }, [manualUpdatePaymentStatusOrder])

  return (
    <Menu
      className={classes.menu}
      id='customized-menu'
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={handleClose}>
      {createMenuItem('Pending', 'Pendiente')}
      {createMenuItem('InProgress', 'En Progreso')}
      {createMenuItem('InMediation', 'En Mediación')}
      {createMenuItem('Approved', 'Aprobada')}
      {createMenuItem('Rejected', 'Rechazada')}
      {createMenuItem('Cancelled', 'Cancelada')}
      {createMenuItem('Refunded', 'Devuelta')}
      {createMenuItem('ChargedBack', 'Contracargo')}
      <div className={classes.buttonContainer}>
        <Button onClick={handleClose} color='primary'>
          Cancelar
        </Button>
      </div>
    </Menu>
  )
}

export default ChangeStateManually
