import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    padding: theme.spacing()
  },
  input: {
    flexGrow: 1
  },
  containerButton: {
    paddingLeft: 20
  },
  autocomplete: {
    width: '100%'
  },
  field: {
    minWidth: 60,
    marginRight: 10,
    marginBottom: 10
  }
}))

export default useStyles
