import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ROLES } from 'config/constants'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { useStyles } from './OrganizerControllersPage.style'
import { OrganizerControllersList } from '../components/OrganizerControllersList'
import { SearchComponent } from '~views/shared'
import { loadOrganizer } from 'state/modules/organizers'
import { ROUTES } from '~/views/routes'
import { Add } from '@material-ui/icons'
import history from '~/config/history'

export const OrganizerControllersPage = ({ match, onSuccess }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [controllersFilter, setControllersFilter] = useState()
  const { user } = useSelector((state) => state.login)
  const rolesArray = user?.roles?.split(',') || []
  const { organizer, controllers, pending } = useSelector(
    (state) => state.organizers
  )

  const performSearch = (searchKey) => {
    setSearchValue(searchKey)
    setControllersFilter(
      controllers.filter(
        (c) =>
          c.FullName.toUpperCase().includes(searchKey.toUpperCase()) ||
          c.DNI?.includes(searchKey) ||
          c.UserName?.toUpperCase().includes(searchKey.toUpperCase()) ||
          c.Email?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(match.params.id))
    }
    fetchData().then()

    setControllersFilter(controllers)
  }, [dispatch, match, controllers, setControllersFilter])

  useEffect(() => {
    onSuccess(match.params.id)
  }, [match, onSuccess])

  const handleBack = () => {
    history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)
  }

  const handleBackProducers = () => {
    history.push(ROUTES.ADMIN.PRODUCERS.INDEX)
  }

  return (
    <PaperContainer
      title={
        <div className={classes.title}>
          <Typography variant='h5'>
            Controladores de Ingreso x Organizador
          </Typography>
          <Button
            variant='text'
            color='primary'
            key='Crear'
            onClick={() => {
              history.push(
                ROUTES.ADMIN.ORGANIZERS.CONTROLLERS.CREATE.replace(
                  ':id',
                  match.params.id
                )
              )
            }}>
            Agregar Nuevo
            <Add />
          </Button>
        </div>
      }>
      <div className={classes.titleContainer}>
        <SearchComponent
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder='Ingresar Nombre'
        />
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {controllersFilter &&
            controllersFilter.map((controller, i) => (
              <OrganizerControllersList
                onSuccess={onSuccess}
                organizer={organizer?.Name}
                controller={controller}
                id={match.params.id}
                key={i}
              />
            ))}

          <div className={classes.actionsContainer}>
            <Button
              onClick={
                rolesArray?.includes(ROLES.PRODUCER) && rolesArray?.length === 1
                  ? handleBackProducers
                  : handleBack
              }
              color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default OrganizerControllersPage
