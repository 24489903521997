import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import {
  PaperContainer,
  createColumn,
  createCustomColumn,
  TableGrid,
  TableGridOrder
} from '~views/shared'
import { useStyles } from './OrderPaymentsPage.style'
import { loadEvent } from '~/state/modules/events'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { PAYMENT_TYPE_IDS } from 'config/constants'
import { OrderActionsMenu } from '../components/OrderActionsMenu'
import { currencyFormat } from 'views/utils/functions'
import moment from 'moment'
import clsx from 'clsx'
import { loadOrderHistoryPage, loadPaymentsPaged } from '~/state/modules/orders'

const makeColumns = (classes, paymentStatus, orderId) => {
  return [
    createColumn('id', 'Id de Pago'),
    createColumn('last_modified', 'Fecha', 'left', (x) =>
      moment(x).subtract(3, 'hours').format('DD/MM/YYYY HH:mm')
    ),
    createColumn('PaymentTypeId', 'Tipo', 'left', (x) => PAYMENT_TYPE_IDS[x]),
    createCustomColumn(
      (p) => (
        <Typography
          className={clsx({
            [classes.status]: true,
            [classes.statusApproved]: p.status === 'approved',
            [classes.statusRejected]: p.status === 'rejected',
            [classes.statusInProgress]: p.status === 'in_progress',
            [classes.statusPending]: p.status === 'pending',
            [classes.statusNotSet]: p.status === 'not_set',
            [classes.statusChargedBack]: p.status === 'charged_back',
            [classes.statusRefunded]: p.status === 'refunded',
            [classes.statusCancelled]: p.status === 'cancelled',
            [classes.statusInMediation]: p.status === 'in_mediation'
          })}>
          {paymentStatus[p.status?.replace('_', '').toLowerCase()]}
        </Typography>
      ),
      'Estado'
    ),
    createColumn('status_detail', 'StatusDetail'),
    createColumn('TransactionAmount', 'Monto', 'left', (x) =>
      currencyFormat(x)
    ),
    createCustomColumn(
      (p) => (
        <OrderActionsMenu
          paymentId={p.id}
          status={p.status}
          orderId={orderId}
          transactionAmount={currencyFormat(p.TransactionAmount)}
        />
      ),
      'Acciones'
    )
  ]
}
const makeFooterActions = () => []

export const OrderPaymentsPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { eventId, orderId } = match.params
  const [order, setOrder] = useState(null)
  const [user, setUser] = useState(null)
  const { pendingPayments, pagedList, orderEvent, reqParams } = useSelector(
    (state) => state.orders
  )
  const { event } = useSelector((state) => state.events)

  const createLabel = (label, value) => {
    return (
      <Typography variant='h6' className={classes.label}>
        {label}:&nbsp;&nbsp;
        <Typography variant='h6' component={'span'} className={classes.value}>
          {value}
        </Typography>
      </Typography>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(eventId))
    }

    fetchData().then()
    if (orderEvent && orderEvent.length > 0) {
      setOrder(orderEvent[0].Order)
      setUser(orderEvent.find((o) => o.OriginUser != null)?.OriginUser)
    }
  }, [dispatch, eventId, pagedList, orderEvent])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrderHistoryPage(orderId))
      await dispatch(
        loadPaymentsPaged(orderId, {
          searchParams: '',
          pageNumber: 0,
          orderBy: 'id',
          order: TableGridOrder.DESC
        })
      )
    }

    fetchData().then()
  }, [dispatch, orderId])

  const paymentStatus = {
    pending: 'Pendiente',
    inprogress: 'En Progreso',
    inmediation: 'En Mediación',
    notset: 'En Proceso',
    approved: 'Aprobada',
    rejected: 'Rechazada',
    cancelled: 'Cancelada',
    refunded: 'Devuelta',
    chargedback: 'Contracargo'
  }

  const handleBack = () => {
    const currentURL = window.location.href
    if (currentURL.includes('support')) {
      history.push(
        ROUTES.ADMIN.SUPPORT.ORDERS.DETAILS.replace(':orderId', orderId)
      )
    } else {
      if (currentURL.includes('bytickets')) {
        history.push(
          ROUTES.ADMIN.USERS.TICKETS.DETAILS.replace(
            ':userId',
            user.Id
          ).replace(':orderId', orderId)
        )
      } else {
        history.push(
          ROUTES.ADMIN.USERS.ORDERS.DETAILS.replace(':userId', user.Id).replace(
            ':orderId',
            orderId
          )
        )
      }
    }
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Pagos asociados a una orden'>
        {pendingPayments ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <TableGrid
              title=''
              description={
                <>
                  &nbsp;
                  {createLabel('Id de Orden', order?.Id)}
                  {createLabel('Monto', currencyFormat(order?.TotalAmount))}
                  {createLabel(
                    'MercadoPago Id',
                    currencyFormat(order?.ProviderPaymentId)
                  )}
                  {createLabel(
                    'Estado',
                    currencyFormat(
                      paymentStatus[
                        order?.PaymentStatus?.replace('_', '').toLowerCase()
                      ]
                    )
                  )}
                  {createLabel(
                    'Fecha de Creación',
                    moment(order?.CreatedDate).format('DD/MM/YYYY HH:mm')
                  )}
                  {createLabel('Email', user?.Email)}
                </>
              }
              columns={makeColumns(classes, paymentStatus, orderId)}
              footerActions={makeFooterActions()}
              searchForm={() => {}}
              searchParams={''}
              loadItemsPage={() => {}}
              isLoading={pendingPayments}
              pagedList={pagedList}
              keyName='id'
              pageSize={reqParams?.pageSize || 5}
              order={reqParams?.order || TableGridOrder.ASC}
              orderBy={reqParams?.orderBy || 'date_created'}
              useNewFormat
              hideFooter
              noDataMessage='No se encontraron pagos asociados'
            />
            <div className={classes.actionsContainer}>
              <Button onClick={handleBack} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default OrderPaymentsPage
