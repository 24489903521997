import BaseProxy from './BaseProxy'
import axios from 'axios'

export class ProfileProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/profile`
  }

  async getProfile() {
    const response = await axios.get(`${this._baseUrl}`)

    return response.data
  }

  async subscribe(userId, subscribe) {
    const response = subscribe
      ? await axios.post(`${this._baseUrl}/subscribe/userid/${userId}`)
      : await axios.post(`${this._baseUrl}/unsubscribe/userid/${userId}`)

    return response.data
  }

  async alertsEvents(userName, active) {
    const response = await axios.put(
      `${this._baseUrl}/alertsevents/${userName}/${!!active}`,
      {}
    )

    return response.data
  }

  async alertsDraws(userName, active) {
    const response = await axios.put(
      `${this._baseUrl}/alertsdraws/${userName}/${!!active}`,
      {}
    )

    return response.data
  }

  async userBan(userId) {
    const response = await axios.put(`${this._baseUrl}/ban/user/${userId}`)

    return response.data
  }

  async userUnban(userId) {
    const response = await axios.put(`${this._baseUrl}/unban/user/${userId}`)
    return response.data
  }

  async cleanDNI(userName) {
    const response = await axios.put(`${this._baseUrl}/cleandni/${userName}/`)
    return response.data
  }

  async changeEmail(userId, email) {
    const response = await axios.post(
      `${this._baseUrl}/update/email/${userId}`,
      email
    )
    return response.data
  }

  async allowChangeEmail(userName, value) {
    const response = await axios.put(
      `${this._baseUrl}/email/allow/${userName}/${value}`
    )

    return response.data
  }

  async getAllRoles(userId) {
    const response = await axios.get(`${this._baseUrl}/${userId}/roles/all`)

    return response.data
  }
}

export default ProfileProxy
