import * as types from './types'
import { ResellersProxy } from '~/services'
import { showSnackbarError } from '~/state/modules/app'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (reseller) => ({
  type: types.CREATE_SUCCESS,
  reseller
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateSuccess = (reseller) => ({
  type: types.UPDATE_SUCCESS,
  reseller
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const loadAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadSuccess = (reseller) => ({
  type: types.LOAD_SUCCESS,
  reseller
})

export const loadFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteSuccess = (reseller) => ({
  type: types.DELETE_SUCCESS,
  reseller
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

//rrpp

export const loadEventResellersPagedAttempt = (reqParams) => ({
  type: types.LOAD_EVENT_RESELLERS_PAGED_ATTEMPT,
  reqParams
})

export const loadEventResellerPagedSuccess = (resellerEventPaged) => ({
  type: types.LOAD_EVENT_RESELLERS_PAGED_SUCCESS,
  resellerEventPaged
})

export const loadEventResellerPagedFailure = (error) => ({
  type: types.LOAD_EVENT_RESELLERS_PAGED_FAILURE,
  error
})

export const loadEventResellersAttempt = () => ({
  type: types.LOAD_EVENT_RESELLERS_ATTEMPT
})

export const loadEventResellerSuccess = (resellerEvent) => ({
  type: types.LOAD_EVENT_RESELLERS_SUCCESS,
  resellerEvent
})

export const loadEventResellerFailure = (error) => ({
  type: types.LOAD_EVENT_RESELLERS_FAILURE,
  error
})

export const loadSalesResellerDetailsAttempt = () => ({
  type: types.LOAD_RESELLERS_SALES_ATTEMPT
})

export const loadSalesResellerDetailsSuccess = (resellerSalesEvent) => ({
  type: types.LOAD_RESELLERS_SALES_SUCCESS,
  resellerSalesEvent
})

export const loadSalesResellerDetailsFailure = (error) => ({
  type: types.LOAD_RESELLERS_SALES_FAILURE,
  error
})

//rrpp TP

export const loadResellersTPSalesPagedAttempt = (reqParams) => ({
  type: types.LOAD_RESELLERS_TP_SALES_PAGED_ATTEMPT,
  reqParams
})

export const loadResellersTPSalesPagedSuccess = (
  resellerTPSalesEventPaged
) => ({
  type: types.LOAD_RESELLERS_TP_SALES_PAGED_SUCCESS,
  resellerTPSalesEventPaged
})

export const loadResellersTPSalesPagedFailure = (error) => ({
  type: types.LOAD_RESELLERS_TP_SALES_PAGED_FAILURE,
  error
})

export const loadResellersTPSalesAttempt = () => ({
  type: types.LOAD_RESELLERS_TP_SALES_ATTEMPT
})

export const loadResellersTPSalesSuccess = (resellerTPSalesEvent) => ({
  type: types.LOAD_RESELLERS_TP_SALES_SUCCESS,
  resellerTPSalesEvent
})

export const loadResellersTPSalesFailure = (error) => ({
  type: types.LOAD_RESELLERS_TP_SALES_FAILURE,
  error
})

export const loadResellerTPSalesDetailsAttempt = () => ({
  type: types.LOAD_RESELLERS_TP_SALES_DETAILS_ATTEMPT
})

export const loadResellerTPSalesDetailsSuccess = (
  resellerTPSalesEventDetails
) => ({
  type: types.LOAD_RESELLERS_TP_SALES_DETAILS_SUCCESS,
  resellerTPSalesEventDetails
})

export const loadResellerTPSalesDetailsFailure = (error) => ({
  type: types.LOAD_RESELLERS_TP_SALES_DETAILS_FAILURE,
  error
})

//rrpp cash

export const loadResellerCashSalesPagedAttempt = (reqParams) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_PAGED_ATTEMPT,
  reqParams
})

export const loadResellerCashSalesPagedSuccess = (resellerCashSalesPaged) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_PAGED_SUCCESS,
  resellerCashSalesPaged
})

export const loadResellerCashSalesPagedFailure = (error) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_PAGED_FAILURE,
  error
})

export const loadResellerCashSalesAttempt = () => ({
  type: types.LOAD_RESELLERS_CASH_SALES_ATTEMPT
})

export const loadResellerCashSalesSuccess = (resellerCashSales) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_SUCCESS,
  resellerCashSales
})

export const loadResellerCashSalesFailure = (error) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_FAILURE,
  error
})

export const loadResellerCashSalesDetailsAttempt = () => ({
  type: types.LOAD_RESELLERS_CASH_SALES_DETAILS_ATTEMPT
})

export const loadResellerCashSalesDetailsSuccess = (
  resellerCashSalesDetails
) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_DETAILS_SUCCESS,
  resellerCashSalesDetails
})

export const loadResellerCashSalesDetailsFailure = (error) => ({
  type: types.LOAD_RESELLERS_CASH_SALES_DETAILS_FAILURE,
  error
})

export const filtersSet = (filters, searchKey) => ({
  type: types.SET_FILTERS,
  filters,
  searchKey
})

export const reset = () => ({
  type: types.RESET
})

export const resetActions = () => ({
  type: types.RESET_ACTIONS
})

export const resetParamsResellers = () => ({
  type: types.RESET_PARAMS
})

export const generatePinAttempt = () => ({
  type: types.GENERATE_PIN_ATTEMPT
})

export const generatePinSuccess = (pin) => ({
  type: types.GENERATE_PIN_SUCCESS,
  pin
})

export const generatePinFailure = (error) => ({
  type: types.GENERATE_PIN_FAILURE,
  error
})

export const resellerTypeSet = (resellerSelected) => ({
  type: types.SET_RESELLER_TYPE,
  resellerSelected
})

export const loadEventsWithTicketsAttempt = () => ({
  type: types.LOAD_EVENT_WITH_TICKETS_ATTEMPT
})

export const loadEventsWithTicketsSuccess = (events) => ({
  type: types.LOAD_EVENT_WITH_TICKETS_SUCCESS,
  events
})

export const loadEventsWithTicketsFailure = (error) => ({
  type: types.LOAD_EVENT_WITH_TICKETS_FAILURE,
  error
})

export const generateQRAttempt = () => ({
  type: types.GENERATE_QR_ATTEMPT
})

export const generateQRSuccess = (qr) => ({
  type: types.GENERATE_QR_SUCCESS,
  qr
})

export const generateQRFailure = (error) => ({
  type: types.GENERATE_QR_FAILURE,
  error
})

export const loadPage =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageNumber, pageSize })
      )

      const proxy = new ResellersProxy()
      const pagedList = await proxy.get(
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(pagedListSuccess(pagedList))
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }

export const loadReseller = (userId) => async (dispatch) => {
  try {
    dispatch(loadAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.getById(userId)

    dispatch(loadSuccess(response))
    return true
  } catch (error) {
    dispatch(loadFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const create = (reseller) => async (dispatch) => {
  try {
    dispatch(createAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.post(reseller)

    dispatch(createSuccess(response))
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const update = (userId, reseller) => async (dispatch) => {
  try {
    dispatch(updateAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.put(userId, reseller)

    dispatch(updateSuccess(response))
  } catch (error) {
    dispatch(updateFailure(error))
    throw error
  }
}

export const deleteReseller = (userId) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.delete(userId)

    dispatch(deleteSuccess(response))
    return true
  } catch (error) {
    dispatch(deleteFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

//rrpp

export const loadEventResellerPaged =
  (eventId, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(loadEventResellersPagedAttempt({ searchParams, order, orderBy }))

      const proxy = new ResellersProxy()
      const pagedList = await proxy.getResellerEventPaged(
        eventId,
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(loadEventResellerPagedSuccess(pagedList))
    } catch (error) {
      dispatch(loadEventResellerPagedFailure(error))
      throw error
    }
  }

export const loadEventResellerPage = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventResellersAttempt())
    const proxy = new ResellersProxy()
    const all = await proxy.getResellerEvent(eventId)
    const response = {
      content: all.content.sort((a, b) => b.Sales - a.Sales)
    }
    dispatch(loadEventResellerSuccess(response))
  } catch (error) {
    dispatch(loadEventResellerFailure(error))
    throw error
  }
}

export const loadSalesResellerDetails =
  (resellerId, eventId) => async (dispatch) => {
    try {
      dispatch(loadSalesResellerDetailsAttempt({}))

      const proxy = new ResellersProxy()
      const response = await proxy.loadSalesResellerDetails(resellerId, eventId)
      dispatch(loadSalesResellerDetailsSuccess(response))
    } catch (error) {
      dispatch(loadSalesResellerDetailsFailure(error))
      throw error
    }
  }

//rrpp TP

export const loadResellersTPSalesPaged =
  (eventId, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        loadResellersTPSalesPagedAttempt({ searchParams, order, orderBy })
      )

      const proxy = new ResellersProxy()
      const pagedList = await proxy.getResellersTPSalesPaged(
        eventId,
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(loadResellersTPSalesPagedSuccess(pagedList))
    } catch (error) {
      dispatch(loadResellersTPSalesPagedFailure(error))
      throw error
    }
  }

export const loadResellersTPSales = (eventId) => async (dispatch) => {
  try {
    dispatch(loadResellersTPSalesAttempt({}))

    const proxy = new ResellersProxy()
    const all = await proxy.loadResellersTPSales(eventId)
    const response = {
      content: all.content.sort((a, b) => b.Sales - a.Sales)
    }
    dispatch(loadResellersTPSalesSuccess(response))
  } catch (error) {
    dispatch(loadResellersTPSalesFailure(error))
    throw error
  }
}

export const loadResellerTPSalesDetails =
  (resellerId, eventId) => async (dispatch) => {
    try {
      dispatch(loadResellerTPSalesDetailsAttempt({}))

      const proxy = new ResellersProxy()
      const response = await proxy.loadResellerTPDetails(resellerId, eventId)

      dispatch(loadResellerTPSalesDetailsSuccess(response))
    } catch (error) {
      dispatch(loadResellerTPSalesDetailsFailure(error))
      throw error
    }
  }

//rrpp cash

export const loadResellerCashSalesPaged =
  (eventId, { searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        loadResellerCashSalesPagedAttempt({ searchParams, order, orderBy })
      )

      const proxy = new ResellersProxy()
      const pagedList = await proxy.getSalesResellerCashPaged(
        eventId,
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(loadResellerCashSalesPagedSuccess(pagedList))
    } catch (error) {
      dispatch(loadResellerCashSalesPagedFailure(error))
      throw error
    }
  }

export const loadResellerCashSales = (eventId) => async (dispatch) => {
  try {
    dispatch(loadResellerCashSalesAttempt({}))

    const proxy = new ResellersProxy()
    const all = await proxy.loadSalesResellerCash(eventId)
    const response = {
      content: all.content.sort((a, b) => b.Sales - a.Sales)
    }
    dispatch(loadResellerCashSalesSuccess(response))
  } catch (error) {
    dispatch(loadResellerCashSalesFailure(error))
    throw error
  }
}

export const loadResellerCashSalesDetails =
  (resellerId, eventId) => async (dispatch) => {
    try {
      dispatch(loadResellerCashSalesDetailsAttempt({}))

      const proxy = new ResellersProxy()
      const response = await proxy.loadSalesInfoResellerCash(
        resellerId,
        eventId
      )
      dispatch(loadResellerCashSalesDetailsSuccess(response))
    } catch (error) {
      dispatch(loadResellerCashSalesDetailsFailure(error))
      throw error
    }
  }

export const setFilters = (filters, searchKey) => (dispatch) => {
  dispatch(filtersSet({ status: filters, searchKey }))
}

//PIN

export const generatePin = (pin) => async (dispatch) => {
  try {
    dispatch(generatePinAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.generatePIN(pin)

    dispatch(generatePinSuccess(response))
  } catch (error) {
    dispatch(generatePinFailure(error))
    throw error
  }
}

export const updatePin = (updatedPin) => async (dispatch) => {
  try {
    dispatch(generatePinAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.updatePIN(updatedPin)

    dispatch(generatePinSuccess(response))
  } catch (error) {
    dispatch(generatePinFailure(error))
    throw error
  }
}

export const resetPin = (userId, pin) => async (dispatch) => {
  try {
    dispatch(generatePinAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.resetPIN(userId, pin)

    dispatch(generatePinSuccess(response))
  } catch (error) {
    dispatch(generatePinFailure(error))
    throw error
  }
}

export const setResellerType = (resellerSelected) => (dispatch) => {
  dispatch(resellerTypeSet(resellerSelected))
}

export const loadEventsWithTickets = () => async (dispatch) => {
  try {
    dispatch(loadEventsWithTicketsAttempt())

    const proxy = new ResellersProxy()
    const response = await proxy.getEventsWithTicketsCash()

    dispatch(loadEventsWithTicketsSuccess(response))
  } catch (error) {
    dispatch(loadEventsWithTicketsFailure(error))
    throw error
  }
}

export const generateQR =
  (userId, eventId, ticketsOrder, pin) => async (dispatch) => {
    try {
      dispatch(generateQRAttempt())

      const proxy = new ResellersProxy()
      const response = await proxy.generateQR(
        userId,
        eventId,
        ticketsOrder,
        pin
      )

      dispatch(generateQRSuccess(response))
    } catch (error) {
      dispatch(generateQRFailure(error))
      throw error
    }
  }
