import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ROLES } from 'config/constants'
import PrivateRoute from 'views/routes/PrivateRoute'
import { ROUTES } from '~/views/routes'
import {
  LandingPage,
  LoginPage,
  ProfilePage,
  DashboardPage,
  LocationsPage,
  OrganizersPage,
  ProducersPage,
  ResellersPage,
  EventsPage,
  UsersPage,
  NotificationsToAllPage,
  NotificationsCustomToAllPage,
  NotificationsCustomToProducersPage,
  NotificationsByEventPage,
  NotificationsByEventByTicketPage,
  CustomNotificationsPage,
  NotificationsCustomByEventsPage,
  RolesPage,
  SellForCashPage,
  OrdersPage,
  TermsPage,
  PrivacyPage,
  AccountsPage
} from 'views/pages'
import { useEffect } from 'react'
import { restoreCountry } from 'state/modules/app/actions'

export const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(restoreCountry())
  }, [dispatch])

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PUBLIC.ROOT}
        render={() => <Redirect to={ROUTES.PUBLIC.LANDING} />}
      />
      <Route exact path={ROUTES.PUBLIC.LANDING} component={LandingPage} />
      <Route path={ROUTES.PUBLIC.LOGIN} component={LoginPage} />
      <Route path={ROUTES.PUBLIC.TERMS} component={TermsPage} />
      <Route path={ROUTES.PUBLIC.PRIVACY} component={PrivacyPage} />
      <Route
        exact
        path={ROUTES.ADMIN.ROOT}
        render={() => <Redirect to={ROUTES.ADMIN.DASHBOARD.INDEX} />}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.ROOT}
        roles={[ROLES.ADMIN, ROLES.PRODUCER]}
        component={DashboardPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.PROFILE}
        roles={[
          ROLES.ADMIN,
          ROLES.PRODUCER,
          ROLES.RESELLER,
          ROLES.USER,
          ROLES.WITHDRAWPOINT,
          ROLES.FACEBOOK
        ]}
        component={ProfilePage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ADMIN.DASHBOARD.INDEX}
        roles={[
          ROLES.ADMIN,
          ROLES.PRODUCER,
          ROLES.RESELLER,
          ROLES.USER,
          ROLES.WITHDRAWPOINT,
          ROLES.FACEBOOK
        ]}
        component={DashboardPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.LOCATIONS.INDEX}
        roles={[ROLES.ADMIN]}
        component={LocationsPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.ORGANIZERS.INDEX}
        roles={[ROLES.ADMIN, ROLES.PRODUCER]}
        component={OrganizersPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.RESELLERS.INDEX}
        roles={[ROLES.ADMIN]}
        component={ResellersPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.EVENTS.INDEX}
        roles={[
          ROLES.ADMIN,
          ROLES.PRODUCER,
          ROLES.RESELLER,
          ROLES.USER,
          ROLES.WITHDRAWPOINT,
          ROLES.FACEBOOK
        ]}
        component={EventsPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.SELL.INDEX}
        roles={[ROLES.PRODUCER, ROLES.RESELLER]}
        component={SellForCashPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.USERS.INDEX}
        roles={[ROLES.ADMIN]}
        component={UsersPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.ALL}
        roles={[ROLES.ADMIN]}
        component={NotificationsToAllPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.CUSTOM_ALL}
        roles={[ROLES.ADMIN]}
        component={NotificationsCustomToAllPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.PRODUCERS}
        roles={[ROLES.ADMIN]}
        component={NotificationsCustomToProducersPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.EVENT}
        roles={[ROLES.ADMIN]}
        component={NotificationsByEventPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.TICKET}
        roles={[ROLES.ADMIN]}
        component={NotificationsByEventByTicketPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.CUSTOM}
        roles={[ROLES.ADMIN]}
        component={CustomNotificationsPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.NOTIFICATIONS.EVENTS}
        roles={[ROLES.ADMIN]}
        component={NotificationsCustomByEventsPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.SUPPORT.INDEX}
        roles={[ROLES.ADMIN]}
        component={OrdersPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.ROLES.INDEX}
        roles={[ROLES.ADMIN]}
        component={RolesPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.PRODUCERS.INDEX}
        roles={[ROLES.PRODUCER]}
        component={ProducersPage}
      />
      <PrivateRoute
        path={ROUTES.ADMIN.ACCOUNTS.INDEX}
        roles={[ROLES.ADMIN]}
        component={AccountsPage}
      />
    </Switch>
  )
}

export default App
