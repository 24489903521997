import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  field: {
    margin: theme.spacing()
  },
  fieldRowSelect: {
    marginBlock: theme.spacing(1)
  },
  fieldSelect: {
    marginBottom: theme.spacing(2),
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      height: 300,
      minWidth: 350
    }
  },
  containerDialog: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: 16,
    paddingBottom: 12
  },
  dialogButton: {
    width: 120,
    alignSelf: 'end'
  },
  textButton: {
    textTransform: 'none',
    minHeight: 55,
    marginTop: 10,
    marginInline: theme.spacing(1),
    marginBottom: 5,
    paddingInline: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  containerSelectButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& span': {
      whiteSpace: 'normal'
    }
  },
  items: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  }
}))

export default useStyles
