import * as types from './types'
import * as moment from 'moment'
import { PAYMENTS_STATUS } from 'config/constants'

const initialState = {
  ordersList: null,
  pagedListByEvent: null,
  totalTickets: 0,
  totalWithdrawn: 0,
  order: null,
  orders: null,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null,
  paymentStatus: '',
  filters: { status: 'Approved', searchKey: '' },
  filtersSearch: {
    MercadoPagoId: '',
    OrderId: '',
    PaymentStatus: PAYMENTS_STATUS.APPROVED,
    StartDate: moment().subtract(1, 'month').subtract(3, 'hours'),
    EndDate: moment().subtract(3, 'hours'),
    MinPrice: '',
    MaxPrice: ''
  },
  ordersUser: null,
  ticketsUser: null,
  orderObj: null,
  orderEvent: [],
  postSuccess: null,
  refundObj: null,
  refundPending: false,
  transferObj: null,
  transferPending: false,
  pagedList: null,
  ordersPaged: null,
  pendingOrdersPaged: false,
  ordersPagedList: null,
  pendingOrders: false,
  pendingPayments: false,
  assignPaymentSuccess: null,
  refundPaymentSuccess: null,
  pendingHandlePayment: false,
  ticketsAmount: 0,
  ticketsRecovered: null,
  recoverPending: false,
  billingGenerated: null,
  pendingBilling: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ORDERS_LIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        ordersList: null
      }
    case types.LOAD_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        ordersList: action.ordersList
      }
    case types.LOAD_ORDERS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        ordersList: null
      }
    case types.LOAD_TOTAL_TICKETS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        totalTickets: 0,
        totalWithdrawn: 0
      }
    case types.LOAD_TOTAL_TICKETS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        totalTickets: action.totalTickets,
        totalWithdrawn: action.totalWithdrawn
      }
    case types.LOAD_TOTAL_TICKETS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        totalTickets: 0,
        totalWithdrawn: 0
      }
    case types.LOAD_ORDERS_BY_TICKET_ATTEMPT:
      return {
        ...state,
        orders: null,
        pending: true,
        error: null
      }
    case types.LOAD_ORDERS_BY_TICKET_SUCCESS:
      return {
        ...state,
        orders: action.orders,
        pending: false,
        error: null
      }
    case types.LOAD_ORDERS_BY_TICKET_FAILURE:
      return {
        ...state,
        orders: null,
        pending: false,
        error: action.error
      }
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        order: null,
        pending: true,
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        order: action.order,
        pending: false,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        event: null,
        pending: false,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        order: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        order: action.order,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        order: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        // order: null,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        order: action.order,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        order: null,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        actionSuccess: false,
        error: null
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        actionPending: false,
        actionSuccess: true,
        error: null
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        actionPending: false,
        actionSuccess: false,
        error: action.error
      }
    case types.LOAD_ORDERS_EVENT_ATTEMPT:
      return {
        ...state,
        orderEvent: [],
        pending: true,
        error: null
      }
    case types.LOAD_ORDERS_EVENT_SUCCESS:
      return {
        ...state,
        orderEvent: action.orderEvent,
        pending: false,
        error: null
      }
    case types.LOAD_ORDERS_EVENT_FAILURE:
      return {
        ...state,
        orderEvent: [],
        pending: false,
        error: action.error
      }
    case types.LOAD_ORDERS_EVENT_PAGED_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedListByEvent: null,
        reqParams: action.reqParams
      }
    case types.LOAD_ORDERS_EVENT_PAGED_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedListByEvent: action.pagedListByEvent
      }
    case types.LOAD_ORDERS_EVENT_PAGED_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedListByEvent: null
      }
    case types.LOAD_ORDERS_HISTORY_ATTEMPT:
      return {
        ...state,
        orderEvent: [],
        pending: true,
        error: null
      }
    case types.LOAD_ORDERS_HISTORY_SUCCESS:
      return {
        ...state,
        orderEvent: action.orderEvent,
        pending: false,
        error: null
      }
    case types.LOAD_ORDERS_HISTORY_FAILURE:
      return {
        ...state,
        orderEvent: [],
        pending: false,
        error: action.error
      }
    case types.LOAD_ORDER_HISTORY_PAGED_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.LOAD_ORDER_HISTORY_PAGED_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.LOAD_ORDER_HISTORY_PAGED_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_PAYMENTS_PAGED_ATTEMPT:
      return {
        ...state,
        pendingPayments: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.LOAD_PAYMENTS_PAGED_SUCCESS:
      return {
        ...state,
        pendingPayments: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.LOAD_PAYMENTS_PAGED_FAILURE:
      return {
        ...state,
        pendingPayments: false,
        error: action.error,
        pagedList: null
      }
    case types.LOAD_ORDER_DETAILS_ATTEMPT:
      return {
        ...state,
        order: null,
        pending: true,
        error: null
      }
    case types.LOAD_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        order: action.order,
        pending: false,
        error: null
      }
    case types.LOAD_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        order: null,
        pending: false,
        error: action.error
      }
    case types.LOAD_TICKETS_PER_ORDER_ATTEMPT:
      return {
        ...state,
        orderEvent: [],
        pending: true,
        error: null
      }
    case types.LOAD_TICKETS_PER_ORDER_SUCCESS:
      return {
        ...state,
        orderEvent: action.orderEvent,
        pending: false,
        error: null
      }
    case types.LOAD_TICKETS_PER_ORDER_FAILURE:
      return {
        ...state,
        orderEvent: [],
        pending: false,
        error: action.error
      }
    case types.MANUAL_UPDATE_PAYMENT_STATE_ATTEMPT:
      return {
        ...state,
        paymentStatus: '',
        pending: true,
        error: null
      }
    case types.MANUAL_UPDATE_PAYMENT_STATE_SUCCESS:
      return {
        ...state,
        paymentStatus: action.paymentStatus,
        pending: false,
        error: null
      }
    case types.MANUAL_UPDATE_PAYMENT_STATE_FAILURE:
      return {
        ...state,
        paymentStatus: '',
        pending: false,
        error: action.error
      }
    case types.LOAD_ORDERS_USER_ATTEMPT:
      return {
        ...state,
        orderEvent: null,
        pending: true,
        error: null
      }
    case types.LOAD_ORDERS_USER_SUCCESS:
      return {
        ...state,
        ordersUser: action.ordersUser,
        pending: false,
        error: null
      }
    case types.LOAD_ORDERS_USER_FAILURE:
      return {
        ...state,
        order: null,
        pending: false,
        error: action.error
      }
    case types.LOAD_ORDERS_PAGED_ATTEMPT:
      return {
        ...state,
        ordersPaged: null,
        pendingOrdersPaged: true,
        error: null,
        reqParams: action.reqParams
      }
    case types.LOAD_ORDERS_PAGED_SUCCESS:
      return {
        ...state,
        ordersPaged: action.ordersPaged,
        pendingOrdersPaged: false,
        error: null
      }
    case types.LOAD_ORDERS_PAGED_FAILURE:
      return {
        ...state,
        ordersPaged: null,
        pendingOrdersPaged: false,
        error: action.error
      }
    case types.LOAD_ORDERS_USER_PAGED_ATTEMPT:
      return {
        ...state,
        ordersPagedList: null,
        pendingOrders: true,
        error: null,
        reqParams: action.reqParams
      }
    case types.LOAD_ORDERS_USER_PAGED_SUCCESS:
      return {
        ...state,
        ordersPagedList: action.ordersPagedList,
        pendingOrders: false,
        error: null
      }
    case types.LOAD_ORDERS_USER_PAGED_FAILURE:
      return {
        ...state,
        ordersPagedList: null,
        pendingOrders: false,
        error: action.error
      }
    case types.LOAD_TICKETS_USER_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.LOAD_TICKETS_USER_SUCCESS:
      return {
        ...state,
        ticketsUser: action.ticketsUser,
        pending: false,
        error: null
      }
    case types.LOAD_TICKETS_USER_FAILURE:
      return {
        ...state,
        order: null,
        pending: false,
        error: action.error
      }
    case types.SEND_GIFT_TICKET_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.SEND_GIFT_TICKET_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null
      }
    case types.SEND_GIFT_TICKET_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.REFRESH_ORDER_MP_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        orderObj: null,
        error: null
      }
    case types.REFRESH_ORDER_MP_SUCCESS:
      return {
        ...state,
        actionPending: false,
        orderObj: action.orderObj,
        error: null
      }
    case types.REFRESH_ORDER_MP_FAILURE:
      return {
        ...state,
        actionPending: false,
        orderObj: null,
        error: action.error
      }
    case types.REFUND_ORDER_ATTEMPT:
      return {
        ...state,
        refundPending: true,
        refundObj: null,
        error: null
      }
    case types.REFUND_ORDER_SUCCESS:
      return {
        ...state,
        refundPending: false,
        refundObj: action.refundObj,
        error: null
      }
    case types.REFUND_ORDER_FAILURE:
      return {
        ...state,
        refundPending: false,
        refundObj: null,
        error: action.error
      }
    case types.TRANSFER_ORDER_ATTEMPT:
      return {
        ...state,
        transferPending: true,
        transferObj: null,
        error: null
      }
    case types.TRANSFER_ORDER_SUCCESS:
      return {
        ...state,
        transferPending: false,
        transferObj: action.transferObj,
        error: null
      }
    case types.TRANSFER_ORDER_FAILURE:
      return {
        ...state,
        transferPending: false,
        transferObj: null,
        error: action.error
      }
    case types.POST_QR_ATTEMPT:
      return {
        ...state,
        postSuccess: null,
        pending: true,
        error: null
      }
    case types.POST_QR_SUCCESS:
      return {
        ...state,
        postSuccess: action.postSuccess,
        pending: false,
        error: null
      }
    case types.POST_QR_FAILURE:
      return {
        ...state,
        postSuccess: null,
        pending: false,
        error: action.error
      }
    case types.ASSIGN_PAYMENT_ATTEMPT:
      return {
        ...state,
        assignPaymentSuccess: null,
        pendingHandlePayment: true,
        error: null
      }
    case types.ASSIGN_PAYMENT_SUCCESS:
      return {
        ...state,
        assignPaymentSuccess: action.assignPaymentSuccess,
        pendingHandlePayment: false,
        error: null
      }
    case types.ASSIGN_PAYMENT_FAILURE:
      return {
        ...state,
        assignPaymentSuccess: null,
        pendingHandlePayment: false,
        error: action.error
      }
    case types.REFUND_PAYMENT_ATTEMPT:
      return {
        ...state,
        refundPaymentSuccess: null,
        pendingHandlePayment: true,
        error: null
      }
    case types.REFUND_PAYMENT_SUCCESS:
      return {
        ...state,
        refundPaymentSuccess: action.refundPaymentSuccess,
        pendingHandlePayment: false,
        error: null
      }
    case types.REFUND_PAYMENT_FAILURE:
      return {
        ...state,
        refundPaymentSuccess: null,
        pendingHandlePayment: false,
        error: null
      }
    case types.LOAD_TICKETS_AMOUNT_ATTEMPT:
      return {
        ...state,
        ticketsAmount: 0,
        pending: true,
        error: null
      }
    case types.LOAD_TICKETS_AMOUNT_SUCCESS:
      return {
        ...state,
        ticketsAmount: action.ticketsAmount,
        pending: false,
        error: null
      }
    case types.LOAD_TICKETS_AMOUNT_FAILURE:
      return {
        ...state,
        ticketsAmount: 0,
        pending: false,
        error: action.error
      }
    case types.RECOVER_TICKETS_ATTEMPT:
      return {
        ...state,
        ticketsRecovered: null,
        recoverPending: true,
        error: null
      }
    case types.RECOVER_TICKETS_SUCCESS:
      return {
        ...state,
        ticketsRecovered: action.ticketsRecovered,
        recoverPending: false,
        error: null
      }
    case types.RECOVER_TICKETS_FAILURE:
      return {
        ...state,
        ticketsRecovered: null,
        recoverPending: false,
        error: action.error
      }
    case types.GENERATE_BILLING_ATTEMPT:
      return {
        ...state,
        billingGenerated: null,
        pendingBilling: true,
        error: null
      }
    case types.GENERATE_BILLING_SUCCESS:
      return {
        ...state,
        billingGenerated: action.billingGenerated,
        pendingBilling: false,
        error: null
      }
    case types.GENERATE_BILLING_FAILURE:
      return {
        ...state,
        billingGenerated: null,
        pendingBilling: false,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      }
    case types.SET_FILTERS_SEARCH:
      return {
        ...state,
        filtersSearch: action.filtersSearch
      }
    default:
      return state
  }
}
