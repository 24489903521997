import React, { useState } from 'react'
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { useStyles } from './ExpandedRowMp.style'
import { MpActionMenu } from './MpActionMenu'
import { round } from 'views/utils/functions'

export const ExpandedRowMp = ({ mpData }) => {
  const classes = useStyles()
  const { Id, UserId, Email, ExpirationDate, Commission, Vat } = mpData
  const [commission, setCommission] = useState(Commission)
  const [vat, setVat] = useState(Vat)
  const daysDifference = moment(ExpirationDate).diff(moment(), 'days')
  const isBefore = moment().isBefore(ExpirationDate)

  const changeCommissions = (com, iva) => {
    setCommission(com)
    setVat(iva)
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant='h5' className={classes.title} component='div'>
        Datos de la Cuenta
      </Typography>
      <Table size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>UserId</TableCell>
            <TableCell className={classes.cellHead}>Email</TableCell>
            <TableCell className={classes.cellHead}>
              Fecha de Expiración
            </TableCell>
            <TableCell className={classes.cellHead}>Estado</TableCell>
            <TableCell className={classes.cellHead}>Comisión</TableCell>
            <TableCell className={classes.cellHead}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.bodyStyle}>
          <TableRow>
            <TableCell className={classes.cellRow}>{UserId}</TableCell>
            <TableCell className={classes.cellRow}>{Email}</TableCell>
            <TableCell
              className={classes.cellRow}
              title={
                daysDifference > 0
                  ? `Está a ${daysDifference} dias de vencer`
                  : ''
              }>
              {moment(ExpirationDate).format('DD/MM/YYYY HH:mm')}
            </TableCell>
            <TableCell className={classes.cellRow}>
              <Typography
                className={
                  isBefore ? classes.activeStatus : classes.inactiveStatus
                }>
                {isBefore ? 'Activo' : 'Inactivo'}
              </Typography>
            </TableCell>
            <TableCell
              className={clsx({
                [classes.cellRow]: true,
                [classes.noCommissions]: commission == null
              })}>
              {commission != null
                ? `${round(commission * 100)}% - IVA: ${round(vat * 100)}%`
                : 'Sin configurar'}
            </TableCell>
            <TableCell className={classes.cellRow}>
              <MpActionMenu
                mpId={Id}
                commission={commission}
                vat={vat}
                changeCommissions={changeCommissions}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

export default ExpandedRowMp
