import {
  Card,
  CardContent,
  CircularProgress,
  Typography
} from '@material-ui/core'
import React from 'react'

export const TotalizatorWidget = ({ title, value, values, loading }) => {
  return (
    <Card style={{ height: '100%' }}>
      <CardContent
        style={{
          padding: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        {values ? (
          values.map((v, i) => (
            <div key={i} style={{ flex: 1 }}>
              <div>
                <Typography color='textSecondary' gutterBottom variant='h6'>
                  {v.title}
                </Typography>
                <Typography color='textPrimary' variant='h3'>
                  {!loading ? (
                    v.value
                  ) : (
                    <CircularProgress color={'primary'} size={24} />
                  )}
                </Typography>
              </div>
            </div>
          ))
        ) : (
          <div>
            <Typography color='textSecondary' gutterBottom variant='h6'>
              {title}
            </Typography>
            <Typography color='textPrimary' variant='h3'>
              {!loading ? (
                value
              ) : (
                <CircularProgress color={'primary'} size={24} />
              )}
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default TotalizatorWidget
