import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadPage, setFilters } from 'state/modules/dashboard'
import {
  createColumn,
  createCustomColumn,
  TableGridOrder,
  TableGrid
} from 'views/shared'
import * as moment from 'moment'
import {
  IconButton,
  MenuItem,
  Paper,
  Select,
  Button,
  TextField
} from '@material-ui/core'
import { ROUTES } from 'views/routes'
import history from 'config/history'
import { Launch } from '@material-ui/icons'
import useStyles from './DashboardIndexPage.style'
import numeral from 'numeral'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = (dispatch, fetchUpcomingEvents) => [
  createCustomColumn(
    (c) => (
      <IconButton
        onClick={() => {
          history.push(
            ROUTES.ADMIN.DASHBOARD.VIEW.replace(':eventId', c.EventId)
          )
        }}>
        <Launch />
      </IconButton>
    ),
    ''
  ),
  createColumn('EventId', 'ID'),
  createColumn('EventTitle', 'Nombre de Evento'),
  createColumn('EventStartDateTime', 'Fecha de Inicio', 'left', (x) =>
    moment(x).format('DD/MM/YYYY HH:mm')
  ),
  createColumn(
    'days',
    `Días ${fetchUpcomingEvents ? 'Restantes' : 'Pasados'}`,
    'left',
    (x, c) => {
      const date = moment(c.EventStartDateTime)
      const now = moment().set('hour', 0).set('minute', 0).set('second', 0)
      return Math.abs(date.diff(now, 'days'))
    }
  ),
  createColumn('TotalTickets', 'Total Entradas'),
  createColumn('TotalSales', 'Total Vendidas'),
  !fetchUpcomingEvents &&
    createColumn('TotalProfit', 'Facturación Bruta', 'left', (x) =>
      numeral(x).format('$0,0[.]00')
    )
]

export const DashboardIndexPage = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { pending, pagedList, reqParams, filters } = useSelector(
    (state) => state.dashboard
  )

  const performSearch = async (
    upcomingEvents = filters.fetchUpcomingEvents,
    searchKey = filters.searchKey
  ) => {
    dispatch(setFilters(searchKey, upcomingEvents))
    try {
      await dispatch(
        loadPage(upcomingEvents, {
          searchParams: searchKey,
          pageNumber: 0,
          pageSize: 5,
          orderBy: reqParams?.orderBy || 'RemainingDays',
          order: reqParams?.order || TableGridOrder.DESC
        })
      )
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const handleChangeEventType = (upcomingEvents) => {
    dispatch(setFilters('', upcomingEvents))
    performSearch(upcomingEvents, '')
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Select
          className={classes.field}
          value={filters.fetchUpcomingEvents}
          required
          onChange={(v) => {
            handleChangeEventType(v.target.value)
          }}
          size='small'>
          <MenuItem value={true}>Eventos Próximos</MenuItem>
          <MenuItem value={false}>Eventos Finalizados</MenuItem>
        </Select>
        <div className={classes.form}>
          <TextField
            fullWidth
            className={classes.input}
            placeholder='Filtrar por Nombre o Id'
            value={filters.searchKey}
            onChange={(e) =>
              dispatch(setFilters(e.target.value, filters.fetchUpcomingEvents))
            }
            onKeyPress={handleSearchKeyPress}
          />
          <Button
            variant='contained'
            color='primary'
            disabled={pending}
            onClick={() => performSearch()}>
            Buscar
          </Button>
        </div>
      </Paper>
      <TableGrid
        title={`Eventos ${
          filters.fetchUpcomingEvents ? 'Próximos' : 'Finalizados'
        }`}
        columns={makeColumns(dispatch, filters.fetchUpcomingEvents)}
        loadItemsPage={(x) =>
          dispatch(
            loadPage(filters.fetchUpcomingEvents, {
              searchParams: x.searchParams,
              pageNumber: x.pageNumber || reqParams?.pageNumber,
              pageSize: x.pageSize,
              orderBy: x.orderBy,
              order: x.order
            })
          )
        }
        isLoading={pending}
        searchParams={filters.searchKey}
        pagedList={pagedList}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.DESC}
        orderBy={reqParams?.orderBy || 'RemainingDays'}
        useNewFormat
      />
    </>
  )
}

export default DashboardIndexPage
