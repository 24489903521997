import React from 'react'
import { Paper } from '@material-ui/core'
import { Typography, Button } from '@material-ui/core'
import ReactCountryFlag from 'react-country-flag'
import { useStyles } from './LandingPage.style'

export const LandingPage = () => {
  const classes = useStyles()

  const setCountry = (url) => {
    window.location = url
  }

  return (
    <div className={classes.container}>
      <div className={classes.buttons}>
        <Button
          color='primary'
          variant='contained'
          type='submit'
          className={classes.button}
          onClick={() => setCountry(process.env.REACT_APP_ADMIN_SITE_URL_AR)}>
          <ReactCountryFlag countryCode='AR' svg className={classes.flag} />
          <span>ARGENTINA</span>
        </Button>
        <Button
          color='primary'
          variant='contained'
          type='submit'
          className={classes.button}
          onClick={() => setCountry(process.env.REACT_APP_ADMIN_SITE_URL_UY)}>
          <ReactCountryFlag countryCode='UY' svg className={classes.flag} />
          <span>URUGUAY</span>
        </Button>
      </div>
      <div>
        <Paper className={classes.loginContainer}>
          <div className={classes.logo} />
        </Paper>
        <Typography variant='h5' className={classes.subTitle}>
          #ticketPass Manager
        </Typography>
      </div>
    </div>
  )
}

export default LandingPage
