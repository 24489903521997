import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Toolbar, Typography, Tooltip, IconButton } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { withStyles, lighten } from '@material-ui/core/styles'

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyItems: 'end'
  },
  title: {
    flex: '0 0 auto'
  },
  info: {
    flex: 1
  }
})

const TableGridToolbar = ({
  title,
  description,
  numSelected,
  updatePath,
  deleteAction,
  actions,
  onRequestAction,
  allowSelection,
  classes
}) => (
  <Toolbar
    className={clsx(classes.root, {
      [classes.highlight]: numSelected > 0
    })}>
    <div className={classes.info}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color='inherit' variant='subtitle1'>
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant='h6' id='tableTitle'>
            {title}
          </Typography>
        )}
      </div>
      <div> {description} </div>
    </div>
    {/*<div className={classes.spacer} />*/}
    <div className={classes.actions}>
      {numSelected > 0 ? (
        <>
          {actions
            .filter(
              (x) => (!x.allowMultiple && numSelected === 1) || x.allowMultiple
            )
            .map((x) => (
              <Tooltip title={x.title} key={x.title}>
                <IconButton
                  aria-label={x.title}
                  onClick={() => onRequestAction(x)}>
                  {x.icon}
                </IconButton>
              </Tooltip>
            ))}
        </>
      ) : (
        allowSelection && (
          <Tooltip title='Filter list'>
            <IconButton aria-label='Filter list'>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )
      )}
    </div>
  </Toolbar>
)

TableGridToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  allowSelection: PropTypes.bool
}

export default withStyles(styles)(TableGridToolbar)
