import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import {
  reset,
  sendNotificationToMaillistEveryone
} from '~/state/modules/notifications'
import {
  PaperContainer,
  AppContainer,
  PreviewDialog,
  ConfirmationDialog
} from '~/views/shared'
import { NotificationsCustomToAllForm } from './NotificationsCustomToAllForm'
import { showSnackbarError } from 'views/utils/snackbar'

export const NotificationsCustomToAllPage = () => {
  const { actionPending, actionSuccess } = useSelector(
    (state) => state.notifications
  )
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [notification, setNotification] = useState()

  const formikRef = useRef()

  const handleCloseConfirmation = () => {
    setOpenDialogConfirmation(false)
  }

  const handleClose = () => {
    dispatch(reset())
    setOpen(false)
  }

  const handleSubmit = (values) => {
    const notification = {
      Subject: values.Subject,
      Message: values.Message
    }
    setNotification(notification)
    setOpenDialogConfirmation(true)
  }

  const handleSubmitConfirmation = async () => {
    setOpenDialogConfirmation(false)
    const payload = {
      Subject: notification.Subject,
      Message: notification.Message
    }
    try {
      await dispatch(sendNotificationToMaillistEveryone(payload))
      formikRef.current.resetForm()
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handlePreview = (values) => {
    const notification = {
      Subject: values.Subject,
      Message: values.Message
    }
    setNotification(notification)
    setOpenPreview(true)
  }

  const values = {
    Subject: '',
    Message: '',
    showHtmlMessage: false
  }

  return (
    <AppContainer>
      <PaperContainer title='Enviar email personalizado a todos'>
        <NotificationsCustomToAllForm
          values={values}
          formikRef={formikRef}
          actionText='Enviar'
          openPreview={handlePreview}
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
        />
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>Notificación Enviada!</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDialogConfirmation}
        loading={actionPending}
        handleConfirmation={handleSubmitConfirmation}
        onCancel={handleCloseConfirmation}
        title='ADVERTENCIA'
        message={'Está por enviar el email a todos los usuarios de ticketPass.'}
      />
      <PreviewDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        subject={notification?.Subject}
        message={notification?.Message}
        userName='Estimado Cliente'
      />
    </AppContainer>
  )
}

export default NotificationsCustomToAllPage
