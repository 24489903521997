import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useStyles } from './SearchComponent.style'
import { CircularProgress } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export const SearchComponent = ({
  performSearch,
  searchValue,
  placeholder,
  pending,
  performSearchFilter
}) => {
  const [searchKey, setSearchKey] = useState(searchValue)
  const [searchActive, setSearchActive] = useState(false)
  const classes = useStyles()

  const handleSearchOnChange = (searchKey) => {
    if (performSearchFilter != null && !pending) {
      performSearchFilter(searchKey.trim())
    }
  }

  const handleSearchKeyPress = (event) => {
    if (performSearchFilter != null) {
      if (event.key === 'Enter' && searchKey.length >= 3) {
        performSearch(searchKey.trim())
        setSearchActive(true)
      }
    } else {
      if (event.key === 'Enter') {
        performSearch(searchKey.trim())
      }
    }
  }

  return (
    <div>
      <div className={classes.form}>
        <TextField
          placeholder={placeholder}
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            searchActive && handleSearchOnChange(e.target.value)
          }}
          onKeyPress={handleSearchKeyPress}
          className={classes.autocomplete}
        />
        <div className={classes.containerButton}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              performSearch(searchKey.trim())
              setSearchActive(true)
            }}
            disabled={
              pending || (performSearchFilter != null && searchKey.length < 3)
            }
            startIcon={<SearchIcon />}>
            {pending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Buscar'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default SearchComponent
