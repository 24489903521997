import { makeStyles } from '@material-ui/core/styles'
import logoImg from '~/assets/img/logo.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    backgroundImage: `url(${logoImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 200,
    height: 200
  },
  button: {
    marginTop: theme.spacing(2)
  },
  flag: {
    marginInline: theme.spacing(1),
    fontSize: 20
  },
  versionNumber: {
    marginTop: theme.spacing()
  },
  containerButtons: {
    display: 'flex',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  staticPageButton: {
    textAlign: 'center'
  }
}))

export default useStyles
