import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import EmailIcon from '@material-ui/icons/Email'
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Dialog
} from '@material-ui/core'
import clsx from 'clsx'
import { ArrowDropDown } from '@material-ui/icons'
import RichTextEditor from 'react-rte'
import { FilterComponent } from '~/views/shared'

import { useStyles } from './NotificationsCustomByEventsForm.style'

const validationSchema = Yup.object().shape({
  Subject: Yup.string()
    .trim()
    .required('Es requerido ingresar un Asunto')
    .max(60, "La cantidad máxima de caracteres es de '60'"),
  EventIds: Yup.array()
    .min(1, 'Es requerido seleccionar al menos un evento')
    .required('Es requerido seleccionar al menos un evento'),
  PaymentStatus: Yup.string().required('El estado es requerido'),
  Message: Yup.string()
    .transform((value) => value.replace(/(<([^>]+)>|&nbsp;)/gi, ''))
    .trim()
    .required('Es requerido ingresar un mensaje')
    .max(10000, "La cantidad máxima de caracteres es de '10.000'")
})

export const NotificationsCustomByEventsForm = ({
  values,
  events,
  paymentStatus,
  formikRef,
  actionText,
  handleSubmit,
  actionPending,
  openPreview,
  successNotification,
  handleChangeSuccess
}) => {
  const classes = useStyles()
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  )

  const [openEvent, setOpenEvent] = useState(false)
  const [eventsSelected, setEventsSelected] = useState(values.EventsSelected)
  const [eventIds, setEventIds] = useState(values.EventIds)
  const [searchValueEvent, setSearchValueEvent] = useState('')
  const [eventsFilter, setEventsFilter] = useState(events)

  const handleClickOpenEvents = () => {
    setSearchValueEvent('')
    setEventsFilter(events)
    setOpenEvent(true)
  }

  const performSearchEvents = (searchKey) => {
    setSearchValueEvent(searchKey)
    setEventsFilter(
      events?.filter((e) =>
        e?.Title?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  const handleClickSelect = (e) => {
    const eventId = e.Id
    handleChangeSuccess()

    const isSelected = eventIds.includes(eventId)

    const updatedEventIds = isSelected
      ? eventIds.filter((id) => id !== eventId)
      : [...eventIds, eventId]

    setEventIds(updatedEventIds)

    const updatedEventTitles = events
      .filter((event) => updatedEventIds.includes(event.Id))
      .map((event) => event.Title)

    setEventsSelected(updatedEventTitles.join(', '))
  }

  const toolbarConfig = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'TEXT_ALIGNMENT_BUTTON',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
      'IMAGE_BUTTON'
    ],
    INLINE_STYLE_BUTTONS: [
      { label: 'Negrita', style: 'BOLD' },
      { label: 'Cursiva', style: 'ITALIC' },
      { label: 'Subrayado', style: 'UNDERLINE' },
      { label: 'Tachado', style: 'STRIKETHROUGH' },
      { label: 'Código', style: 'CODE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Título 1', style: 'header-one' },
      { label: 'Título 2', style: 'header-two' },
      { label: 'Título 3', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'Viñetas', style: 'unordered-list-item' },
      { label: 'Numeración', style: 'ordered-list-item' }
    ]
  }

  useEffect(() => {
    if (successNotification) {
      setEventsSelected(null)
      setEventIds([])
      setEditorValue(RichTextEditor.createEmptyValue())
    }
  }, [successNotification])

  useEffect(() => {
    formikRef.current.setValues({
      ...formikRef.current.values
    })
  }, [values, formikRef])

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Subject && touched.Subject && errors.Subject) ||
                  'Asunto'
                }
                name='Subject'
                value={values.Subject}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Subject && Boolean(errors.Subject)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
              <InputLabel shrink={true} required>
                {(errors.EventIds && touched.EventIds && errors.EventIds) ||
                  'Eventos'}
              </InputLabel>
              <Button
                disableFocusRipple
                onClick={handleClickOpenEvents}
                variant='outlined'
                className={classes.textButton}>
                <div className={classes.containerSelectButton}>
                  <span
                    style={
                      !eventsSelected || eventsSelected === []
                        ? { color: 'gray' }
                        : { color: 'black' }
                    }>
                    {eventsSelected && eventsSelected !== []
                      ? eventsSelected
                      : 'Seleccione uno o más eventos'}
                  </span>
                  <ArrowDropDown />
                </div>
              </Button>
              <Dialog open={openEvent} onClose={() => setOpenEvent(false)}>
                <div className={classes.containerDialog}>
                  <div>
                    <FilterComponent
                      performSearch={performSearchEvents}
                      searchValue={searchValueEvent}
                      placeholder='Ingrese Nombre del evento...'
                    />
                  </div>
                  <Select
                    multiple
                    native
                    labelId='event-label'
                    name='EventIds'
                    id='EventIds-select'
                    value={eventIds}
                    onChange={handleChange}
                    className={classes.fieldSelect}>
                    {eventsFilter &&
                      eventsFilter.map((e) => (
                        <option
                          key={e.Id}
                          className={classes.items}
                          value={e.Id.toString()}
                          onClick={() => handleClickSelect(e)}>
                          {e.Title}
                        </option>
                      ))}
                  </Select>
                  <Button
                    onClick={() => setOpenEvent(false)}
                    color='primary'
                    className={classes.dialogButton}
                    variant='contained'>
                    OK
                  </Button>
                </div>
              </Dialog>
            </FormControl>
            <FormControl variant='outlined' className={classes.fieldRow}>
              <InputLabel required style={{ marginTop: 8, marginLeft: 4 }}>
                {(errors.PaymentStatus &&
                  touched.PaymentStatus &&
                  errors.PaymentStatus) ||
                  'Selecciona un estado'}
              </InputLabel>
              <Select
                name='PaymentStatus'
                value={values.PaymentStatus}
                onChange={(e) => handleChange(e)}
                className={classes.field}
                fullWidth
                required
                size='small'
                label='Selecciona un estado'>
                {paymentStatus &&
                  paymentStatus.map((status) => (
                    <MenuItem
                      key={status.value}
                      value={status.value}
                      className={classes.menuItem}>
                      {status.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.fieldRow}>
            <InputLabel
              shrink={true}
              required
              className={clsx({
                [classes.inputLabel]: true,
                [classes.inputLabelError]:
                  errors.Message && touched.Message && errors.Message
              })}>
              {(errors.Message && touched.Message && errors.Message) ||
                'Mensaje'}
            </InputLabel>
            <RichTextEditor
              value={editorValue}
              toolbarConfig={toolbarConfig}
              className={clsx({
                [classes.fieldEditorText]: true,
                [classes.fieldEditorTextError]:
                  errors.Message && touched.Message && errors.Message
              })}
              placeholder='Escriba aquí su mensaje...'
              onChange={(value) => {
                setEditorValue(value)
                handleChange({
                  target: {
                    name: 'Message',
                    value: value.toString('html')
                  }
                })
              }}
              onBlur={handleBlur}
            />
          </div>
          <FormControlLabel
            className={classes.containerCheckbox}
            control={
              <Checkbox
                color='primary'
                size='small'
                checked={values.showHtmlMessage}
                onChange={() => {
                  setFieldValue('showHtmlMessage', !values.showHtmlMessage)
                }}
                name='showHTML'
              />
            }
            label='Mostrar HTML'
          />
          {values.showHtmlMessage && (
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Message && touched.Message && errors.Message) ||
                  'Mensaje'
                }
                name='Message'
                value={values.Message}
                onChange={(e) => {
                  handleChange(e)
                  const editorValue = RichTextEditor.createValueFromString(
                    e.target.value,
                    'html'
                  )
                  setEditorValue(editorValue)
                }}
                onBlur={handleBlur}
                error={touched.Message && Boolean(errors.Message)}
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          )}
          <div className={classes.actionsContainer}>
            <Button
              onClick={() => openPreview(values)}
              disabled={
                values.Message.replace(/(<([^>]+)>|&nbsp;)/gi, '').trim() ===
                  '' ||
                values.Subject.trim() === '' ||
                values.PaymentStatus === ''
              }
              color='secondary'
              variant='contained'>
              Vista Previa
            </Button>
            <Button
              onClick={() => {
                values.EventIds = eventIds
                values.EventsSelected = eventsSelected?.split(', ')
                handleSubmit()
              }}
              disabled={actionPending}
              color='primary'
              variant='contained'
              startIcon={<EmailIcon />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default NotificationsCustomByEventsForm
