import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: '300px'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  time: {
    textAlignLast: 'end',
    marginTop: -30,
    marginBottom: 10
  }
}))

export default useStyles
