import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './EventsIndexPage.style'
import { ROLES } from 'config/constants'
import {
  createColumn,
  createCustomColumn,
  TableGrid,
  TableGridOrder
} from 'views/shared'
import { Add, Delete, Edit, LocalActivity } from '@material-ui/icons'
import {
  Button,
  TextField,
  Badge,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core'
import {
  deleteEvent,
  loadPage,
  setTicketType,
  reset,
  resetActions
} from 'state/modules/events'
import { reset as resetResellers } from 'state/modules/resellers'
import * as moment from 'moment'
import { EventActionMenus } from '../components'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { DeleteDialog } from 'views/shared'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = (
  performSearch,
  setSelectedEvent,
  setShowDeleteDialog,
  isReseller,
  isWithDrawPoint,
  classes,
  dispatch
) => {
  return [
    createColumn('Id', 'ID'),
    createColumn('Title', 'Titulo', 'left', (x, row) =>
      !moment().subtract(3, 'hours').isSameOrBefore(row.StartDateTime) ||
      row?.IsEnabled
        ? x
        : x + ' (No)'
    ),
    !isReseller &&
      !isWithDrawPoint &&
      createCustomColumn(
        (c) => (
          <Badge
            badgeContent={c.DrawsQuantity}
            max={100}
            color='secondary'
            onClick={() =>
              c.DrawsQuantity > 0 &&
              history.push(
                ROUTES.ADMIN.EVENTS.DRAWS.INDEX.replace(':eventId', c.Id)
              )
            }
            className={`${classes.badge} ${
              c.DrawsQuantity > 0 && classes.DrawsQuantity
            }`}>
            {c.DrawsQuantity === 0 && (
              <Typography className={classes.typography}>
                Sin Sorteos
              </Typography>
            )}
          </Badge>
        ),
        'Sorteos'
      ),
    createColumn('OrganizerName', 'Organizador'),
    createColumn('LocationName', 'Lugar'),
    createColumn('StartDateTime', 'Fecha de Inicio', 'left', (x) =>
      moment(x).format('DD/MM/YYYY HH:mm')
    ),
    ...(!isReseller && !isWithDrawPoint
      ? [
          createCustomColumn(
            (c) => (
              <div style={{ marginLeft: -16 }}>
                <Tooltip title='Editar evento'>
                  <IconButton
                    style={{ color: 'black' }}
                    onClick={() => {
                      history.push(
                        ROUTES.ADMIN.EVENTS.UPDATE.replace(':eventId', c.Id)
                      )
                    }}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Crear / Editar Tickets'>
                  <IconButton
                    style={{ color: 'black' }}
                    onClick={() => {
                      dispatch(setTicketType(-1))
                      history.push(
                        ROUTES.ADMIN.EVENTS.TICKETS.INDEX.replace(
                          ':eventId',
                          c.Id
                        )
                      )
                    }}>
                    <LocalActivity />
                  </IconButton>
                </Tooltip>
              </div>
            ),
            'Editar'
          ),
          createCustomColumn(
            (c) => (
              <Tooltip title='Eliminar Evento'>
                <IconButton
                  style={{ color: 'black' }}
                  onClick={() => {
                    setSelectedEvent(c)
                    setShowDeleteDialog(true)
                  }}>
                  <Delete />
                </IconButton>
              </Tooltip>
            ),
            'Eliminar'
          )
        ]
      : []),

    createCustomColumn(
      (c) => (
        <EventActionMenus
          event={c}
          performSearch={performSearch}
          isReseller={isReseller}
          isWithDrawPoint={isWithDrawPoint}
        />
      ),
      'Acciones'
    )
  ]
}

const makeFooterActions = () => [
  {
    title: 'Crear',
    icon: <Add />,
    action: () => {
      history.push(ROUTES.ADMIN.EVENTS.CREATE)
    }
  }
]

const hideFooterActions = () => []

export const EventsIndexPage = ({ handleStepChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pagedList, pending, actionPending, reqParams } = useSelector(
    (state) => state.events
  )
  const { user } = useSelector((state) => state.login)
  const rolesArray = user?.roles?.split(',') || []
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [requestParams, setRequestParams] = useState({})
  const [search, setSearch] = useState(reqParams?.searchParams || '')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  useEffect(() => {
    dispatch(resetResellers())
  }, [dispatch])

  const handleDelete = async () => {
    try {
      await dispatch(deleteEvent(selectedEvent?.Id))
      setShowDeleteDialog(false)

      await dispatch(reset())
      setSearch('')
      await dispatch(loadPage(requestParams))
    } catch (error) {
      showSnackbarError(error)
    } finally {
      dispatch(resetActions())
      handleStepChange()
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    dispatch(
      loadPage({
        searchParams: search,
        pageNumber: 0,
        pageSize: reqParams.pageSize,
        orderBy: reqParams.orderBy,
        order: TableGridOrder.DESC
      })
    )
  }

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Titulo'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button variant='contained' color='primary' onClick={performSearch}>
          Buscar
        </Button>
      </div>
    )
  }

  return (
    <>
      <TableGrid
        title='Eventos'
        rowClasses={(x) =>
          !(
            !moment().subtract(3, 'hours').isSameOrBefore(x.StartDateTime) ||
            x?.IsEnabled
          ) && `${classes.conditionalRow}`
        }
        columns={makeColumns(
          performSearch,
          setSelectedEvent,
          setShowDeleteDialog,
          rolesArray?.includes(ROLES.RESELLER) && rolesArray?.length === 1,
          rolesArray?.includes(ROLES.WITHDRAWPOINT),
          classes,
          dispatch
        )}
        footerActions={
          (rolesArray?.includes(ROLES.RESELLER) && rolesArray?.length === 1) ||
          rolesArray?.includes(ROLES.WITHDRAWPOINT) ||
          rolesArray?.includes(ROLES.FACEBOOK)
            ? hideFooterActions()
            : makeFooterActions()
        }
        searchForm={makeSearchForm}
        searchParams={search}
        loadItemsPage={(x) => {
          setRequestParams(x)
          dispatch(
            loadPage({
              searchParams: search,
              pageNumber: x.pageNumber || reqParams?.pageNumber,
              pageSize: x.pageSize,
              orderBy: x.orderBy,
              order: x.order
            })
          )
        }}
        isLoading={pending}
        pagedList={pagedList}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.DESC}
        orderBy={reqParams?.orderBy || 'StartDateTime'}
        useNewFormat
      />
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title='Eliminar Evento'
        message='Está por eliminar el evento'
        entityIdentifier={selectedEvent?.Title}
      />
    </>
  )
}

export default EventsIndexPage
