import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { useStyles } from './ProfileForm.style'

const validationSchema = Yup.object().shape({
  BankAccountNumber: Yup.number()
    .integer('Formato incorrecto')
    .min(0, 'Formato incorrecto'),
  MercadoPagoEmail: Yup.string().email('Ingrese un email válido')
})

export const ProfileForm = ({
  values,
  actionText,
  handleSubmit,
  actionPending,
  generatePIN,
  changePIN
}) => {
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.BankAccountNumber &&
                    touched.BankAccountNumber &&
                    errors.BankAccountNumber) ||
                  'CBU'
                }
                name='BankAccountNumber'
                value={values.BankAccountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                type='number'
                error={
                  touched.BankAccountNumber && Boolean(errors.BankAccountNumber)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={(errors.Cuit && touched.Cuit && errors.Cuit) || 'CUIT'}
                name='Cuit'
                translate='no'
                value={values.Cuit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Cuit && Boolean(errors.Cuit)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.MercadoPagoEmail &&
                    touched.MercadoPagoEmail &&
                    errors.MercadoPagoEmail) ||
                  'MercadoPago Email'
                }
                name='MercadoPagoEmail'
                value={values.MercadoPagoEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.MercadoPagoEmail && Boolean(errors.MercadoPagoEmail)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={handleSubmit}
              disabled={actionPending}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
            {values.HasPINGenerated ? (
              <Button onClick={changePIN} color='primary'>
                Cambiar PIN
              </Button>
            ) : (
              <Button onClick={generatePIN} color='primary'>
                Generar PIN
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ProfileForm
