import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update } from '~/state/modules/locations'
import { PaperContainer } from '~/views/shared'
import { CircularProgress } from '@material-ui/core'
import { LocationForm } from '../components/LocationForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { loadLocation } from '~/state/modules/locations'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const LocationUpdatePage = ({ onSuccess, match }) => {
  const { location, pending, actionPending, actionSuccess } = useSelector(
    (state) => state.locations
  )

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadLocation(match.params.id))
    }

    fetchData().then()
  }, [dispatch, match])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(update(values.Id, values))
      onSuccess()
      showSnackbarSuccess('Localidad Actualizada!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    Id: location?.Id,
    Name: location?.Name,
    FullAddress: location?.FullAddress,
    Longitude: location?.Longitude,
    Latitude: location?.Latitude
  }

  return (
    <PaperContainer title='Editar lugar'>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <LocationForm
          values={values}
          actionText='Editar'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          onCancel={() => history.push(ROUTES.ADMIN.LOCATIONS.INDEX)}
        />
      )}
    </PaperContainer>
  )
}

export default LocationUpdatePage
