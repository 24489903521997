import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { addBillingCredential, loadOrganizer } from '~/state/modules/organizers'
import { PaperContainer } from '~/views/shared'
import { BillingCredentialsForm } from './BillingCredentialsForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const BillingCredentialsPage = ({ match }) => {
  const dispatch = useDispatch()
  const { pending, pendingCredential, organizer } = useSelector(
    (state) => state.organizers
  )
  const organizerId = match?.params?.id
  const initialValues = useMemo(
    () => ({
      Username: '',
      Password: '',
      CompanyId: ''
    }),
    []
  )
  const [values, setValues] = useState(initialValues)

  const handleSubmit = async (values) => {
    try {
      await dispatch(addBillingCredential(organizerId, values))
      showSnackbarSuccess(
        'La Credencial de Facturación se guardó correctamente.'
      )
    } catch (error) {
      showSnackbarError(error, 4500)
    } finally {
      dispatch(loadOrganizer(organizerId))
    }
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(organizerId))
    }
    fetchData().then()
  }, [dispatch, organizerId])

  useEffect(() => {
    if (!!organizer) {
      if (!!organizer.BillingProviderCredential) {
        const { Username, Password, CompanyId } = JSON.parse(
          organizer.BillingProviderCredential
        )
        setValues({
          Username: Username || '',
          Password: Password || '',
          CompanyId: CompanyId || ''
        })
      }
    }
    return () => {
      setValues(initialValues)
    }
  }, [organizer, initialValues])

  return (
    <PaperContainer title='Credencial de Facturación'>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <BillingCredentialsForm
          values={values}
          handleSubmit={handleSubmit}
          actionPending={pendingCredential}
          organizerId={organizerId}
          credentialsEnabled={!!organizer?.BillingProviderCredential}
          onCancel={() => history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)}
        />
      )}
    </PaperContainer>
  )
}

export default BillingCredentialsPage
