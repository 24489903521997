import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { loadEvent } from '~/state/modules/events'
import { loadByEventPage } from '~/state/modules/draws'
import { useStyles } from './DrawsIndexPage.style'
import { EventDrawList } from '../../components/EventDrawList'
import { resetDraws } from 'state/modules/draws'
import { ROUTES } from '~/views/routes'
import { Add } from '@material-ui/icons'
import history from '~/config/history'

export const DrawsIndexPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { drawList, pending } = useSelector((state) => state.draws)
  const { event } = useSelector((state) => state.events)

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadByEventPage(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  const handleBack = () => {
    history.push(ROUTES.ADMIN.EVENTS.INDEX)
  }

  const onSuccess = async () => {
    dispatch(resetDraws())
    await dispatch(loadByEventPage(match.params.eventId))
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer
        title={
          <div className={classes.titleContainer}>
            <Typography variant='h6'>SORTEOS DEL EVENTO</Typography>
            <Button
              variant='text'
              color='primary'
              key='Crear'
              onClick={() => {
                history.push(
                  ROUTES.ADMIN.EVENTS.DRAWS.CREATE.replace(
                    ':eventId',
                    match.params.eventId
                  )
                )
              }}>
              Crear Sorteo <Add />
            </Button>
          </div>
        }>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            {drawList &&
              drawList.map((draw, i) => (
                <EventDrawList
                  draw={draw}
                  onSuccess={onSuccess}
                  eventId={match.params.eventId}
                  key={i}
                />
              ))}

            <div className={classes.actionsContainer}>
              <Button onClick={handleBack} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default DrawsIndexPage
