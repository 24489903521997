import BaseProxy from './BaseProxy'
import qs from 'qs'
import axios from 'axios'

const CLIENT_SECRET = process.env.REACT_APP_FACEBOOK_CLIENT_SECRET
const CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID

export class UsersProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/authentication`
  }

  async getUser(searchKey) {
    const response = await axios.get(
      `${this._baseUrl}/users/search/${searchKey}/`
    )
    return response.data
  }

  async getUserById(userId) {
    const response = await axios.get(`${this._baseUrl}/users/${userId}/`)
    return response.data
  }

  async changePassword(userId, passwordChanged) {
    const response = await axios.post(
      `${this._baseUrl}/user/${userId}/changepassword`,
      passwordChanged
    )
    return response.data
  }

  async mergeAccounts(ids) {
    const response = await axios.post(`${this._baseUrl}/mergeaccounts`, ids)
    return response.data
  }

  async getUsers(payload) {
    const response = await axios.post(`${this._baseUrl}/users/search`, payload)
    return response.data
  }

  async loginFacebook(accessToken) {
    const response = await axios({
      method: 'post',
      url: `${this._baseUrl}/v2/externallogin`,
      data: qs.stringify({
        ClientId: CLIENT_ID,
        ClientSecret: CLIENT_SECRET,
        Provider: 'Facebook',
        ExternalAccessToken: accessToken
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })

    let newObj = response.data
    newObj.status = response.status
    return newObj
  }
}

export default UsersProxy
