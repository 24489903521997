import React from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { deleteImage } from 'state/modules/events'

export const EventImageDeleteDialog = ({
  eventId,
  imageId,
  actionPending,
  actionSuccess,
  open,
  onSuccess,
  onCancel
}) => {
  const dispatch = useDispatch()

  const handleDelete = async () => {
    try {
      const success = await dispatch(deleteImage(eventId, imageId))
      if (success) {
        onSuccess()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      backdropClick={actionPending || actionSuccess}
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>Eliminar imagen</DialogTitle>
      <DialogContent>
        <Typography>
          Está por eliminar la imagen con id{' '}
          <Typography color='primary' component='span'>
            {imageId}
          </Typography>
          .
        </Typography>
        <Typography>¿Desea continuar?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          disabled={actionPending || actionSuccess}
          color='primary'>
          Cancelar
        </Button>
        <Button
          onClick={handleDelete}
          disabled={actionPending || actionSuccess}
          color='primary'
          variant='contained'
          autoFocus>
          {actionPending ? (
            <CircularProgress size={24} color='secondary' />
          ) : (
            'Eliminar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
