import React from 'react'
import PropTypes from 'prop-types'
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel
} from '@material-ui/core'

class TableGridHead extends React.Component {
  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props
    onRequestSort(event, property)
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columns,
      allowSelection
    } = this.props

    return (
      <TableHead>
        <TableRow>
          {allowSelection && (
            <TableCell padding='checkbox'>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'Select all desserts' }}
              />
            </TableCell>
          )}
          {columns.map((c) =>
            !c.content ? (
              <TableCell
                key={c.key}
                align={c.align}
                style={{ fontWeight: 'bold' }}
                padding='normal'
                sortDirection={orderBy === c.key ? order : false}>
                <TableSortLabel
                  active={orderBy === c.key}
                  direction={order}
                  onClick={this.createSortHandler(c.key)}>
                  {c.label}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={c.label}
                align={c.align}
                padding='normal'
                style={{ fontWeight: 'bold' }}>
                {c.label}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    )
  }
}

TableGridHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

export default TableGridHead
