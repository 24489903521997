import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  TextField,
  Button,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core'
import { useStyles } from './SearchUserComponent.style'
import { isNotIntegerNumber } from 'views/utils/functions'
import SearchIcon from '@material-ui/icons/Search'

export const SearchUserComponent = ({
  performSearch,
  searchValue,
  placeholder,
  pending
}) => {
  const { country } = useSelector((state) => state.app)
  const documentType = country === 'ar' ? 'DNI' : 'CI'
  const [searchKey, setSearchKey] = useState(searchValue)
  const [documentId, setDocumentId] = useState('')
  const [IDtype, setIDtype] = useState('Todos')
  const [labelId, setLabelId] = useState('número de identificación')
  const classes = useStyles()

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (searchKey.trim().length >= 3 || documentId.trim().length >= 3) {
        handleSearch()
      }
    }
  }

  const validateTypeId = () => {
    if (documentId.length === 0 || IDtype === 'Todos') {
      return null
    } else {
      return IDtype
    }
  }

  const handleSearch = () => {
    const payload = {
      IdentificationType: validateTypeId(),
      IdentificationNumber: documentId.trim(),
      SearchPattern: searchKey.trim()
    }
    performSearch(payload)
  }

  return (
    <div>
      <div className={classes.form}>
        <FormControl variant='outlined' className={classes.fieldRow}>
          <div style={{ display: 'flex' }}>
            <InputLabel id='type-id'>Tipo de identificación</InputLabel>
            <Select
              labelId='type-id'
              className={classes.field}
              label='Tipo de identificación'
              value={IDtype}
              required
              onChange={(v) => {
                setIDtype(v.target.value)
                setDocumentId('')
                v.target.value !== documentType
                  ? setLabelId('número de identificación')
                  : setLabelId(documentType)
              }}>
              <MenuItem value='Todos'>Todos</MenuItem>
              <MenuItem value={documentType}>{documentType}</MenuItem>
              <MenuItem value='OTRO'>Otro</MenuItem>
            </Select>
            <TextField
              label={labelId}
              name='DNI'
              className={classes.documentField}
              value={documentId}
              autoComplete='off'
              onChange={(e) => setDocumentId(e.target.value)}
              inputProps={{ maxLength: IDtype !== documentType ? 20 : 8 }}
              onKeyPress={handleSearchKeyPress}
              onKeyDown={(e) =>
                IDtype === documentType && isNotIntegerNumber(e)
              }
              size='small'
              variant='outlined'
            />
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <TextField
              placeholder={placeholder}
              value={searchKey}
              className={classes.searchKeyField}
              onChange={(e) => {
                setSearchKey(e.target.value)
              }}
              fullWidth
              onKeyPress={handleSearchKeyPress}
            />
            <div className={classes.containerButton}>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={handleSearch}
                disabled={
                  pending ||
                  (searchKey.trim().length < 3 && documentId.trim().length < 3)
                }
                startIcon={<SearchIcon />}>
                {pending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  'Buscar'
                )}
              </Button>
            </div>
          </div>
        </FormControl>
      </div>
    </div>
  )
}
export default SearchUserComponent
