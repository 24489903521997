import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  list: {
    display: 'flex'
  },
  listColumn: {
    width: '-webkit-fill-available !important'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 400
  },
  button: {
    marginRight: theme.spacing(1)
  },
  buttons: {
    margin: theme.spacing(2)
  },
  imagePickerInfo: {
    flexGrow: '1'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  labelsContainer: {
    marginRight: 20,
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    paddingTop: theme.spacing(1)
  },
  labelName: {
    color: '#ff1744'
  },
  participantsList: {
    margin: theme.spacing(1)
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

export default useStyles
