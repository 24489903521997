import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  buttonFacebook: {
    marginTop: 10,
    color: 'white',
    textTransform: 'none',
    backgroundColor: '#415DAE',
    '&:hover': {
      backgroundColor: '#303F9F'
    }
  },
  facebookDialog: {
    display: 'none'
  },
  facebookIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default useStyles
