import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Save, NotListedLocation } from '@material-ui/icons'
import { useStyles } from './LocationForm.style'

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Es requerido ingresar un nombre')
    .max(250, 'La cantidad máxima es de 250 caracteres'),
  FullAddress: Yup.string()
    .required('Es requerido ingresar la dirección del lugar')
    .max(500, 'La cantidad máxima es de 500 caracteres'),
  Latitude: Yup.number().required('Es requerido ingresar la latitud'),
  Longitude: Yup.number().required('Es requerido ingresar la longitud')
})

export const LocationForm = ({
  values,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const [latitude, setLatitude] = useState(values?.Latitude)
  const [longitude, setLongitude] = useState(values?.Longitude)

  const redirectMap = () => {
    const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`
    window.open(googleMapsLink, '_blank')
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del Lugar'
                }
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.FullAddress &&
                    touched.FullAddress &&
                    errors.FullAddress) ||
                  'Dirección (Calle Número, Ciudad, Provincia)'
                }
                name='FullAddress'
                value={values.FullAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.FullAddress && Boolean(errors.FullAddress)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Latitude && touched.Latitude && errors.Latitude) ||
                  'Latitud'
                }
                name='Latitude'
                type='number'
                value={values.Latitude}
                onChange={(e) => {
                  setLatitude(e.target.value)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                error={touched.Latitude && Boolean(errors.Latitude)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
              <TextField
                label={
                  (errors.Longitude && touched.Longitude && errors.Longitude) ||
                  'Longitud'
                }
                name='Longitude'
                type='number'
                value={values.Longitude}
                onChange={(e) => {
                  setLongitude(e.target.value)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                error={touched.Longitude && Boolean(errors.Longitude)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
              <Tooltip
                component='span'
                placement='top'
                title={<Typography variant='subtitle2'>ver mapa</Typography>}>
                <IconButton
                  disabled={values.Latitude === '' || values.Longitude === ''}
                  autoFocus={false}
                  onClick={redirectMap}>
                  <NotListedLocation
                    style={{ fontSize: 26, marginInline: 2 }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={onCancel}
              disabled={actionSuccess || isSubmitting}
              autoFocus={false}
              color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                values.Name = values.Name.trim()
                values.FullAddress = values.FullAddress.trim()
                handleSubmit()
              }}
              disabled={actionPending || actionSuccess || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<Save />}
              autoFocus={false}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default LocationForm
