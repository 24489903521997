import axios from 'axios'
import BaseProxy from './BaseProxy'

export class NotificationsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/push`
  }

  async sendToAll(notification) {
    const response = await axios.post(`${this._baseUrl}/to/all`, notification)

    return response.data
  }

  async sendByEvent(eventId, paymentStatus, notification) {
    const response = await axios.post(
      `${this._baseUrl}/to/event/${eventId}/${paymentStatus}`,
      notification
    )

    return response.data
  }

  async sendByEventByTicket(eventId, paymentStatus, ticketId, notification) {
    const response = await axios.post(
      `${this._baseUrl}/to/event/${eventId}/${paymentStatus}/ticket/${ticketId}`,
      notification
    )

    return response.data
  }

  async sendNotificationToUser(userName, notification) {
    const response = await axios.post(
      `${this._baseUrl}/${userName}/`,
      notification
    )

    return response.data
  }

  async sendNotificationToMaillistEveryone(payload) {
    const response = await axios.post(
      `${this._baseUrl}/to/mailList/Everyone`,
      payload
    )

    return response.data
  }

  async sendNotificationToMaillistCustom(payload) {
    const response = await axios.post(
      `${this._baseUrl}/to/mailList/Custom`,
      payload
    )

    return response.data
  }

  async sendNotificationToMaillistEvents(payload) {
    const response = await axios.post(
      `${this._baseUrl}/to/mailList/Event`,
      payload
    )

    return response.data
  }

  async sendNotificationToMaillistProducers(payload) {
    const response = await axios.post(
      `${this._baseUrl}/to/mailList/Producer`,
      payload
    )

    return response.data
  }
}

export default NotificationsProxy
