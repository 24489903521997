import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import { SearchComponent, PaperContainer } from '~views/shared'
import { loadEvent } from '~/state/modules/events'
import { useStyles } from './DrawsDetailsPage.style'
import { IDENTIFICATION_TYPES } from 'config/constants'
import {
  loadDraw,
  loadParticipants,
  runDraw,
  deliverAwards,
  resetDraws
} from '~/state/modules/draws'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import * as moment from 'moment'

export const DrawsDetailsPage = ({ match, onBack }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { draw, deliverPending, participantList, pending, actionPending } =
    useSelector((state) => state.draws)
  const { event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const [finalized, setFinalized] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [participantsFilter, setParticipantsFilter] = useState()
  const [searchValueWinners, setSearchValueWinners] = useState('')
  const [winnersFilter, setWinnersFilter] = useState()

  const performSearch = (searchKey) => {
    setSearchValue(searchKey)
    setParticipantsFilter(
      participantList.filter(
        (p) =>
          p.FullName.toUpperCase().includes(searchKey.toUpperCase()) ||
          p.DNI?.toString().startsWith(searchKey)
      )
    )
  }

  const performSearchWinners = (searchKey) => {
    setSearchValueWinners(searchKey)
    setWinnersFilter(
      participantList
        .filter((p) => p.Result === 'Winner')
        .filter(
          (p) =>
            p.FullName.toUpperCase().includes(searchKey.toUpperCase()) ||
            p.DNI?.toString().startsWith(searchKey)
        )
    )
  }

  const createRow = (label, value, i) => {
    return (
      <TableRow className={classes.tableRow} key={i}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>{value}</TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    setParticipantsFilter(participantList)
    setWinnersFilter(participantList.filter((p) => p.Result === 'Winner'))
  }, [participantList, setParticipantsFilter, setWinnersFilter])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadDraw(match.params.drawId))
      await dispatch(loadParticipants(match.params.drawId))
    }

    fetchData().then()

    return () => {
      dispatch(resetDraws())
    }
  }, [dispatch, match])

  useEffect(() => {
    if (draw?.Status === 'Finalized' || draw?.Status === 2) {
      setFinalized(true)
    }
    if (draw?.Status === 'InProgress' || draw?.Status === 1) {
      setInProgress(true)
    }
  }, [draw])

  const run = async () => {
    try {
      await dispatch(runDraw(match.params.drawId))
      await dispatch(loadParticipants(match.params.drawId))
      showSnackbarSuccess('Sorteo Realizado!')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const deliver = async () => {
    try {
      await dispatch(deliverAwards(match.params.drawId))
      showSnackbarSuccess('Sorteo Finalizado!')
      dispatch(loadDraw(match.params.drawId))
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const status = {
    0: 'Pending',
    1: 'InProgress',
    2: 'Finalized',
    3: 'Cancelled'
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Detalles del Sorteo'>
        <Table>
          <TableBody>
            {createRow('Id', pending ? '' : draw?.Id)}
            {createRow('Nombre', pending ? '' : draw?.Title)}
            {createRow(
              'Fecha del Sorteo',
              pending
                ? ''
                : moment(draw?.ScheduledOn).format('DD/MM/yyyy HH:mm')
            )}
            {createRow(
              'Status',
              pending
                ? ''
                : !isNaN(draw?.Status)
                  ? status[draw?.Status]
                  : draw?.Status
            )}
            {createRow(
              'Cantidad de participantes',
              pending ? '' : participantList?.length
            )}
          </TableBody>
        </Table>
        <div className={classes.button}>
          <Button
            className={classes.buttons}
            onClick={() => run()}
            variant='contained'
            disabled={pending || actionPending || finalized}
            color='primary'>
            Sortear Participantes
          </Button>
          <Button
            onClick={() => deliver()}
            variant='contained'
            disabled={pending || deliverPending || !inProgress || finalized}
            color='primary'>
            Entregar Premios y Finalizar Sorteo
          </Button>
        </div>
        <div className={classes.list}>
          <PaperContainer title='Participantes' className={classes.listColumn}>
            <SearchComponent
              performSearch={performSearch}
              searchValue={searchValue}
              placeholder={`Ingresar Nombre o ${documentId}`}
            />
            <div className={classes.participantsList}>
              {participantsFilter &&
                participantsFilter.map((participant, i) => (
                  <div className={classes.labelsContainer} key={i}>
                    <Typography
                      variant='subtitle1'
                      className={classes.labelName}>
                      {participant?.FullName}
                    </Typography>
                    <Typography variant='subtitle1' component='span'>
                      {`${
                        IDENTIFICATION_TYPES[participant?.IdentificationType]
                      }: ${participant?.DNI}`}
                    </Typography>
                  </div>
                ))}
            </div>
          </PaperContainer>
          <PaperContainer title='Ganadores' className={classes.listColumn}>
            <SearchComponent
              performSearch={performSearchWinners}
              searchValue={searchValueWinners}
              placeholder={`Ingresar Nombre o ${documentId}`}
            />
            <div className={classes.participantsList}>
              {winnersFilter &&
                winnersFilter.map((participant, i) => (
                  <div className={classes.labelsContainer} key={i}>
                    <Typography
                      variant='subtitle1'
                      className={classes.labelName}>
                      {participant?.FullName}
                    </Typography>
                    <Typography variant='subtitle1' component='span'>
                      {`${
                        IDENTIFICATION_TYPES[participant?.IdentificationType]
                      }: ${participant?.DNI}`}
                    </Typography>
                  </div>
                ))}
            </div>
          </PaperContainer>
        </div>

        <div className={classes.actionsContainer}>
          <Button onClick={() => onBack(match.params.eventId)} color='primary'>
            Volver
          </Button>
        </div>
      </PaperContainer>
    </>
  )
}

export default DrawsDetailsPage
