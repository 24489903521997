import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { sendByEventByTicket, reset } from '~/state/modules/notifications'
import { loadLastEvents, loadTicketPage } from 'state/modules/events'
import { PaperContainer, AppContainer } from '~/views/shared'
import { NotificationsByEventByTicketForm } from './NotificationsByEventByTicketForm'
import { showSnackbarError } from 'views/utils/snackbar'

export const NotificationsByEventByTicketPage = () => {
  const { actionPending, actionSuccess } = useSelector(
    (state) => state.notifications
  )
  const {
    events,
    actionPending: pendingEvents,
    ticketList,
    pending
  } = useSelector((state) => state.events)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false)

  const [notification, setNotification] = useState()
  const [event, setEvent] = useState()
  const [paymentStatus, setPaymentStatus] = useState()
  const [ticketType, setTicketType] = useState()
  const formikRef = useRef()

  const handleCloseConfirmation = () => {
    setOpenDialogConfirmation(false)
  }

  const handleClose = () => {
    dispatch(reset())
    setOpen(false)
  }

  const handleChangeEvent = async (eventId) => {
    await dispatch(loadTicketPage(eventId))
  }

  const handleSubmit = async (values) => {
    const notification = {
      Title: values.Title,
      Message: values.Message
    }
    await setNotification(notification)
    await setEvent(values.Event)
    await setPaymentStatus(values.PaymentStatus)
    await setTicketType(values.TicketTypeId)
    setOpenDialogConfirmation(true)
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadLastEvents(5))
    }
    fetchData().then()
  }, [dispatch])

  const handleSubmitConfirmation = async () => {
    setOpenDialogConfirmation(false)
    try {
      await dispatch(
        sendByEventByTicket(
          event.Id,
          paymentStatus,
          ticketType.Id,
          notification
        )
      )
      formikRef.current.resetForm()
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const paymentStatusArray = [
    { label: 'Aprobada', value: 'Approved' },
    { label: 'Pendiente', value: 'Pending' },
    { label: 'Devuelta', value: 'Refunded' },
    { label: 'En Progreso', value: 'InProgress' },
    { label: 'Cancelada', value: 'Cancelled' },
    { label: 'Contracargo', value: 'ChargedBack' },
    { label: 'En Mediación', value: 'InMediation' },
    { label: 'Rechazada', value: 'Rejected' }
  ]

  const paymentStatusLabel = {
    Approved: 'Aprobada',
    Pending: 'Pendiente',
    Refunded: 'Devuelta',
    InProgress: 'En Progreso',
    Cancelled: 'Cancelada',
    ChargedBack: 'Contracargo',
    InMediation: 'En Mediación',
    Rejected: 'Rechazada'
  }

  const values = {
    Title: '',
    Message: '',
    Event: '',
    PaymentStatus: '',
    TicketTypeId: ''
  }

  return (
    <AppContainer>
      <PaperContainer title='Enviar por Evento, Estado y Ticket'>
        {pendingEvents ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <NotificationsByEventByTicketForm
            values={values}
            events={events}
            paymentStatus={paymentStatusArray}
            tickets={ticketList}
            pendingTickets={pending}
            onChangeEvent={handleChangeEvent}
            formikRef={formikRef}
            actionText='Enviar'
            handleSubmit={handleSubmit}
            actionPending={actionPending}
            actionSuccess={actionSuccess}
          />
        )}
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>Notificación Enviada!</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogConfirmation}>
        <DialogTitle>Envío de notificación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Título: '}
            {notification?.Title}
          </DialogContentText>
          <DialogContentText>
            {'Mensaje: '}
            {notification?.Message}
          </DialogContentText>
          <DialogContentText>
            {'Evento: '}
            {event?.Title}
          </DialogContentText>
          <DialogContentText>
            {'Estado: '}
            {paymentStatusLabel[paymentStatus]}
          </DialogContentText>
          <DialogContentText>
            {'Ticket: '}
            {ticketType?.Name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={handleSubmitConfirmation}
            color='primary'
            disabled={actionPending}
            autoFocus>
            {actionPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Enviar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </AppContainer>
  )
}

export default NotificationsByEventByTicketPage
