import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  containerButtons: {
    display: 'flex',
    alignItems: 'center'
  },
  containerList: {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: '#F2F3F4',
    '&:hover': {
      backgroundColor: '#EAEDED'
    }
  },
  button: {
    display: 'block',
    fontSize: 12
  },
  labels: {
    display: 'flex',
    marginLeft: 10
  },
  labelContainer: {
    marginRight: 20
  },
  labelKeys: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bold',
    marginBlock: 10,
    marginLeft: 10
  },
  separator: {
    backgroundColor: 'black',
    border: 0,
    height: 1,
    marginBottom: 1
  },
  nameStyle: {
    display: 'flex',
    marginBottom: -2
  },
  ticketUsage: {
    marginLeft: 15,
    marginTop: 10
  },
  badgeInactive: {
    marginLeft: 55,
    marginTop: 21
  },
  badgeSoldOut: {
    marginLeft: 41,
    marginTop: 21,
    '& span': {
      backgroundColor: 'gray',
      color: 'white'
    }
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '45rem'
    }
  },
  jsonPretty: {
    display: 'flex',
    width: 'fit-content'
  }
}))

export default useStyles
