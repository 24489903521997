import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

export const DeleteDialog = ({
  open,
  loading,
  handleDelete,
  onCancel,
  title,
  message,
  entityIdentifier,
  optionalMessage,
  secondEntity
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      backdropClick={loading}
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>
          {message}
          {entityIdentifier && (
            <Typography color='primary' component='span'>
              &nbsp;{entityIdentifier}&nbsp;
            </Typography>
          )}
          {optionalMessage}
          {secondEntity && (
            <Typography color='primary' component='span'>
              &nbsp;{secondEntity}
            </Typography>
          )}
        </Typography>
        <Typography>¿Desea continuar?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading} color='primary'>
          Cancelar
        </Button>
        <Button
          onClick={handleDelete}
          disabled={loading}
          color='primary'
          variant='contained'
          startIcon={<Delete />}
          autoFocus>
          {loading ? (
            <CircularProgress size={24} color='secondary' />
          ) : (
            'Eliminar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
