import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: '0 auto',
    minHeight: 600,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  appContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    minWidth: 300,
    overflowX: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  }
}))

export default useStyles
