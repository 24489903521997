import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import EmailIcon from '@material-ui/icons/Email'
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { useStyles } from './NotificationsByEventForm.style'

const validationSchema = Yup.object().shape({
  Title: Yup.string()
    .required('Es requerido ingresar un título')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  Message: Yup.string()
    .required('Es requerido ingresar un mensaje')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  Event: Yup.object().required('El evento es requerido'),
  PaymentStatus: Yup.string().required('El estado es requerido')
})

export const NotificationsByEventForm = ({
  values,
  events,
  paymentStatus,
  formikRef,
  actionText,
  handleSubmit,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Title && touched.Title && errors.Title) || 'Título'
                }
                name='Title'
                value={values.Title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Title && Boolean(errors.Title)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Message && touched.Message && errors.Message) ||
                  'Mensaje'
                }
                name='Message'
                value={values.Message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Message && Boolean(errors.Message)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl variant='outlined' className={classes.fieldRow}>
              <InputLabel required className={classes.inputLabel}>
                {(errors.Event && touched.Event && errors.Event) ||
                  'Selecciona un evento'}
              </InputLabel>
              <Select
                name='Event'
                value={values.Event}
                onChange={(e) => handleChange(e)}
                className={classes.field}
                fullWidth
                required
                size='small'
                label='Selecciona un evento'>
                {events &&
                  events.map((event) => (
                    <MenuItem
                      key={event.Id}
                      value={event}
                      className={classes.menuItem}>
                      {event.Title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant='outlined' className={classes.fieldRow}>
              <InputLabel required className={classes.inputLabel}>
                {(errors.PaymentStatus &&
                  touched.PaymentStatus &&
                  errors.PaymentStatus) ||
                  'Selecciona un estado'}
              </InputLabel>
              <Select
                name='PaymentStatus'
                value={values.PaymentStatus}
                onChange={(e) => handleChange(e)}
                className={classes.field}
                fullWidth
                required
                size='small'
                label='Selecciona un estado'>
                {paymentStatus &&
                  paymentStatus.map((status) => (
                    <MenuItem
                      key={status.value}
                      value={status.value}
                      className={classes.menuItem}>
                      {status.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={handleSubmit}
              disabled={actionPending || actionSuccess || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<EmailIcon />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default NotificationsByEventForm
