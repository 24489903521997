import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import { useStyles } from './SellForCashIndexPage.style'
import { EventsList } from '../EventsList'
import { resetUsers, loadUsers } from '~/state/modules/users'
import { generateQR, loadEventsWithTickets } from '~/state/modules/resellers'
import { postQR } from '~/state/modules/orders'
import { CropFree } from '@material-ui/icons'
import { PaperContainer, FilterComponent } from '~/views/shared'
import { UserSearchComponent } from '../UserSearchComponent/UserSearchComponent'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { validPin } from 'views/utils/functions'
import QRCode from 'react-qr-code'
import numeral from 'numeral'

export const SellForCashIndexPage = ({ events, pending }) => {
  const dispatch = useDispatch()
  const { pendingUsers } = useSelector((state) => state.users)
  const { qr, actionPending } = useSelector((state) => state.resellers)
  const { pending: pendingOrder } = useSelector((state) => state.orders)
  const { country } = useSelector((state) => state.app)
  const documentType = country === 'ar' ? 'DNI' : 'CI'
  const classes = useStyles()
  const [user, setUser] = useState()
  const { user: userProfile } = useSelector((state) => state.login)
  const rolesArray = userProfile?.roles?.split(',') || []
  const [searchValue, setSearchValue] = useState('')
  const [userSearchValue, setUserSearchValue] = useState('')
  const [eventsFilter, setEventsFilter] = useState([])
  const [openDialogPIN, setOpenDialogPIN] = useState(false)
  const [openDialogQR, setOpenDialogQR] = useState(false)
  const [pin, setPin] = useState()
  const [totalEntries, setTotalEntries] = useState(0)
  const [totalToPay, setTotalToPay] = useState(0)
  const [eventIds, setEventIds] = useState([])
  const [ticketsOrder, setTicketsOrder] = useState([])
  const [remoteSell, setRemoteSell] = useState(false)
  const [isForeign, setIsForeign] = useState(false)
  const [ticketType, setTicketType] = useState('R')

  const handleChange = (event) => {
    const value = event?.target?.value
    setTotalToPay(0)
    setTotalEntries(0)
    setEventsFilter(events)
    setSearchValue('')
    setEventIds([])
    setTicketsOrder([])
    setTicketType(value)
  }

  const handleDecrementUnits = (idEvent) => {
    let isEmpty = false

    const selectedEvent = events.find((e) => e.Id === idEvent)
    const selectedTickets = ticketsOrder.filter((t) =>
      selectedEvent.TicketTypes.some((tt) => tt.Id === t.TicketTypeId)
    )

    if (selectedTickets.every((t) => t.Units === 0)) {
      isEmpty = true
    }

    if (isEmpty) {
      setEventIds(eventIds.filter((id) => id !== idEvent))
    }
  }

  const handleOrder = (newTicket, newEvent) => {
    if (newEvent != null && !eventIds.includes(newEvent)) {
      setEventIds([...eventIds, newEvent])
    }

    if (!ticketsOrder.find((t) => t.TicketTypeId === newTicket.TicketTypeId)) {
      setTicketsOrder([...ticketsOrder, newTicket])
    } else {
      ticketsOrder.forEach((t) => {
        if (t.TicketTypeId === newTicket.TicketTypeId) {
          t.Units = newTicket.Units
        }
      })
      setTicketsOrder(ticketsOrder.filter((t) => t.Units !== 0))
    }
  }

  useEffect(() => {
    if (events !== null) {
      setEventsFilter(events)
      setSearchValue('')
    }
  }, [events])

  const performSearch = (searchKey) => {
    setSearchValue(searchKey)
    setEventsFilter(
      events?.filter((event) =>
        event?.Title?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  const performSearchUsers = async (isForeign, searchKey) => {
    const message = `No existen usuarios con el ${
      isForeign ? 'N° de identificación' : documentType
    } ingresado. El cliente debe registrarse en ticketPass y completar todos sus datos en su perfil.`
    if (searchKey.trim().length > 3) {
      const payload = {
        IdentificationType: isForeign ? 'OTRO' : documentType,
        IdentificationNumber: searchKey.trim(),
        SearchPattern: ''
      }
      const users = await dispatch(loadUsers(payload))
      if (users.length === 0) {
        showSnackbarError(message, 5000)
      } else {
        setUser(users[0])
      }
    }
    setUserSearchValue(searchKey)
  }

  const handleGenerateQR = async () => {
    try {
      await dispatch(generateQR(user.Id, eventIds[0], ticketsOrder, pin))
      setTimeout(() => {
        dispatch(loadEventsWithTickets())
        setTotalToPay(0)
        setTotalEntries(0)
        setEventIds([])
        setTicketsOrder([])
      }, 400)
      if (!ticketsOrder[0]?.AllowRemoteSell) {
        setOpenDialogQR(true)
      } else {
        setRemoteSell(true)
      }
    } catch (error) {
      showSnackbarError(error)
    }
    setOpenDialogPIN(false)
    setPin(null)
  }

  const handleKeyPressQR = (event) => {
    if (event.key === 'Enter') {
      handleGenerateQR()
    }
  }

  const handleForeignChange = (isForeign) => {
    setIsForeign(isForeign)
  }

  useEffect(() => {
    if (qr != null && remoteSell) {
      async function fetchData() {
        try {
          await dispatch(postQR(qr))
          showSnackbarSuccess('¡QR Generado!')
        } catch (error) {
          showSnackbarError(error, 4000)
        }
        setRemoteSell(false)
        setOpenDialogPIN(false)
      }

      fetchData().then()
    }
  }, [dispatch, qr, remoteSell])

  useEffect(() => {
    return () => {
      dispatch(resetUsers())
    }
  }, [dispatch])

  return (
    <>
      <PaperContainer
        title={
          <div className={classes.titleContainer}>
            <Typography variant='h5'>Venta En Efectivo</Typography>
            <Tooltip
              title={
                totalEntries !== 0 && !user
                  ? 'Seleccione el Cliente.'
                  : totalEntries === 0 && user
                    ? 'Seleccione al menos un Ticket.'
                    : ''
              }
              placement='left-end'>
              <span>
                <Button
                  variant='text'
                  color='primary'
                  key='Crear'
                  disabled={user == null || totalEntries === 0}
                  onClick={() => {
                    if (eventIds.length > 1) {
                      showSnackbarError(
                        'Seleccione solo tickets de un mismo Evento.'
                      )
                      return
                    }
                    var isProducer = rolesArray.includes('Producer')
                    if (!isProducer) {
                      setOpenDialogPIN(true)
                    } else {
                      handleGenerateQR()
                    }
                  }}
                  className={classes.buttonQR}>
                  Generar Venta &nbsp; <CropFree />
                </Button>
              </span>
            </Tooltip>
          </div>
        }>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <div className={classes.searchComponent}>
              <div className={classes.filterComponentEvents}>
                <FilterComponent
                  performSearch={performSearch}
                  searchValue={searchValue}
                  placeholder='Nombre del evento'
                  isRemote={ticketType === 'R'}
                />
              </div>
              <div
                className={
                  isForeign
                    ? classes.filterComponentUsersForeign
                    : classes.filterComponentUsers
                }>
                <UserSearchComponent
                  performSearch={performSearchUsers}
                  searchValue={userSearchValue}
                  pending={pendingUsers}
                  isForeign={isForeign}
                  handleForeignChange={handleForeignChange}
                />
                {user != null && (
                  <Typography variant='h5' className={classes.texts}>
                    Usuario:&nbsp;
                    <Typography className={classes.labelsResult}>
                      {user?.FullName}
                    </Typography>
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.totalsContainer}>
              <RadioGroup
                name='controlled-radio-buttons-group'
                value={ticketType}
                className={`${
                  user == null ? classes.radioGroup : classes.radioGroupUser
                }`}
                onChange={handleChange}>
                <FormControlLabel
                  value='R'
                  control={<Radio color='primary' />}
                  label='Remota'
                />
                <FormControlLabel
                  value='P'
                  control={<Radio color='primary' />}
                  label='Presencial'
                />
              </RadioGroup>
              <div style={{ width: 200 }}>
                <Typography variant='h5' className={classes.texts}>
                  Total Entradas:&nbsp;
                  <Typography className={classes.labelsResult}>
                    {totalEntries}
                  </Typography>
                </Typography>
                <Typography variant='h5' className={classes.texts}>
                  Total A Pagar:&nbsp;
                  <Typography className={classes.labelsResult}>
                    {numeral(totalToPay).format('$0,0[.]00')}
                  </Typography>
                </Typography>
              </div>
            </div>
            <div className={classes.events}>
              {eventsFilter &&
                eventsFilter.map((event, i) => (
                  <EventsList
                    event={event}
                    totalEntries={totalEntries}
                    setTotalEntries={setTotalEntries}
                    totalToPay={totalToPay}
                    setTotalToPay={setTotalToPay}
                    handleOrder={handleOrder}
                    handleDecrementUnits={handleDecrementUnits}
                    isRemote={ticketType === 'R'}
                    key={i}
                  />
                ))}
            </div>
          </>
        )}
      </PaperContainer>
      <Dialog
        open={openDialogPIN}
        onClose={() => {
          setOpenDialogPIN(false)
          setPin(null)
        }}>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            autoComplete='off'
            id='pin'
            label={'Ingrese su PIN:'}
            type='string'
            placeholder='****'
            inputProps={{ maxLength: 4 }}
            onKeyPress={handleKeyPressQR}
            onKeyDown={(e) => validPin(e)}
            onChange={(e) => setPin(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialogPIN(false)
              setPin(null)
            }}
            color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => handleGenerateQR()}
            color='primary'
            disabled={pin?.length !== 4 || actionPending || pendingOrder}>
            {actionPending || pendingOrder ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Ok'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogQR} onClose={() => setOpenDialogQR(false)}>
        <DialogContent>
          {qr != null && <QRCode value={qr} size={250} />}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SellForCashIndexPage
