import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import {
  loadPage,
  deleteReseller,
  resetActions
} from '~/state/modules/resellers'
import {
  TableGrid,
  createColumn,
  createCustomColumn,
  TableGridOrder,
  DeleteDialog
} from '~/views/shared'
import useStyles from './ResellersIndexPage.style'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { showSnackbarError } from 'views/utils/snackbar'

const makeColumns = (setSelectedReseller, setShowDeleteDialog) => {
  return [
    createColumn('Id', 'ID'),
    createColumn('DisplayName', 'Nombre', 'left', (x, row) =>
      row?.Active ? x : x + ' (No)'
    ),
    createColumn('BankAccountNumber', 'CBU'),
    createColumn('Cuit', <Typography translate='no'>Cuit</Typography>),
    createColumn('MercadoPagoEmail', 'MercadoPago Email'),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            history.push(
              ROUTES.ADMIN.RESELLERS.UPDATE.replace(':userId', c.UserId)
            )
          }}>
          <Edit />
        </Button>
      ),
      'Editar'
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            setSelectedReseller(c)
            setShowDeleteDialog(true)
          }}>
          <Delete />
        </Button>
      ),
      'Eliminar'
    )
  ]
}

const makeFooterActions = () => [
  {
    title: 'Crear',
    icon: <Add />,
    action: () => {
      history.push(ROUTES.ADMIN.RESELLERS.CREATE)
    }
  }
]

export const ResellersIndexPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pagedList, pending, actionPending, reqParams } = useSelector(
    (state) => state.resellers
  )
  const [selectedReseller, setSelectedReseller] = useState(null)
  const [search, setSearch] = useState(reqParams?.searchParams || '')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClose = async () => {
    setOpen(false)
    await dispatch(resetActions())
    dispatch(
      loadPage({
        searchParams: search,
        pageNumber: 0,
        pageSize: 5,
        orderBy: reqParams?.orderBy || 'DisplayName',
        order: reqParams?.order || TableGridOrder.ASC
      })
    )
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteReseller(selectedReseller?.UserId))
      setShowDeleteDialog(false)
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    dispatch(
      loadPage({
        searchParams: search,
        pageNumber: 0,
        pageSize: 5,
        orderBy: reqParams?.orderBy || 'DisplayName',
        order: reqParams?.order || TableGridOrder.ASC
      })
    )
  }

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Nombre o Id'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button variant='contained' color='primary' onClick={performSearch}>
          Buscar
        </Button>
      </div>
    )
  }

  return (
    <>
      <TableGrid
        title={<Typography translate='no'>RRPP</Typography>}
        rowClasses={(r) => !r?.Active && `${classes.conditionalRow}`}
        columns={makeColumns(setSelectedReseller, setShowDeleteDialog)}
        footerActions={makeFooterActions()}
        searchForm={makeSearchForm}
        searchParams={search}
        loadItemsPage={(x) => {
          dispatch(
            loadPage({
              searchParams: search,
              pageNumber: x.pageNumber || reqParams?.pageNumber,
              pageSize: x.pageSize,
              orderBy: x.orderBy,
              order: x.order
            })
          )
        }}
        isLoading={pending}
        pagedList={pagedList}
        keyName='Id'
        pageSize={reqParams?.pageSize || 5}
        order={reqParams?.order || TableGridOrder.ASC}
        orderBy={reqParams?.orderBy || 'DisplayName'}
        useNewFormat
      />
      <DeleteDialog
        open={showDeleteDialog}
        loading={actionPending}
        handleDelete={handleDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title={'Eliminar Reseller'}
        message={'Está por eliminar el Reseller'}
        entityIdentifier={selectedReseller?.DisplayName}
      />
      <Dialog open={open}>
        <DialogTitle>Reseller eliminado</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResellersIndexPage
