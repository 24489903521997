import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import {
  colors,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const PieChartWidget = ({
  title,
  dataset,
  pending,
  colorArray,
  hidePercentage,
  valueFormatter,
  ...props
}) => {
  // La propiedad `datasets` es requerida
  const [data, setData] = useState({ datasets: [] })
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
      tooltipEl.style.borderRadius = '3px'
      tooltipEl.style.color = 'white'
      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, 0)'
      tooltipEl.style.transition = 'all .1s ease'

      const table = document.createElement('table')
      table.style.margin = '0px'

      tooltipEl.appendChild(table)
      chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
  }

  const [options, setOptions] = useState({})

  const [backgroundColors, setBackgroundColors] = useState([
    colors.blue[800],
    colors.red[500],
    colors.orange[600],
    '#020887',
    '#47be4d',
    '#664147',
    colors.pink[700],
    colors.teal[400],
    '#4A4E69',
    '#FFC800',
    '#e776e5',
    '#d05733',
    colors.lime[600],
    '#6035e1'
  ])

  const getPercentage = (value) => {
    if (value != null) {
      let total = dataset.reduce((total, b) => (b.value || b.Value) + total, 0)
      const percentage = (value * 100) / total
      return Number.isInteger(percentage) ? percentage : percentage.toFixed(2)
    } else {
      return 0
    }
  }

  useEffect(() => {
    setOptions({
      animation: false,
      layout: { padding: 0 },
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label: function (context) {
              let label = context.label || ''
              if (dataset != null) {
                const index = dataset?.findIndex((i) => i.Label === label)
                const data = dataset[index]
                let total = dataset.reduce(
                  (total, b) => (b.value || b.Value) + total,
                  0
                )
                const percentage = ((data.Value || data.value) * 100) / total
                label += `: ${data?.Value || data?.value} (${
                  Number.isInteger(percentage)
                    ? percentage
                    : percentage.toFixed(2)
                }%)`
              }
              return label
            }
          },
          external: (context) => {
            // Tooltip Element
            const { chart, tooltip } = context
            const tooltipEl = getOrCreateTooltip(chart)

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0
              return
            }

            // Set Text
            if (tooltip.body) {
              const titleLines = tooltip.title || []
              const bodyLines = tooltip.body.map((b) => b.lines)

              const tableHead = document.createElement('thead')

              titleLines.forEach((title) => {
                const tr = document.createElement('tr')
                tr.style.borderWidth = 0

                const th = document.createElement('th')
                th.style.borderWidth = 0
                const text = document.createTextNode(title)

                th.appendChild(text)
                tr.appendChild(th)
                tableHead.appendChild(tr)
              })

              const tableBody = document.createElement('tbody')
              bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i]

                const span = document.createElement('span')
                span.style.background = colors.backgroundColor
                span.style.borderColor = colors.borderColor
                span.style.borderWidth = '2px'
                span.style.marginRight = '10px'
                span.style.height = '10px'
                span.style.width = '10px'
                span.style.display = 'inline-block'

                const tr = document.createElement('tr')
                tr.style.backgroundColor = 'inherit'
                tr.style.borderWidth = 0

                const td = document.createElement('td')
                td.style.borderWidth = 0
                td.style.whiteSpace = 'nowrap'

                const text = document.createTextNode(body)

                td.appendChild(span)
                td.appendChild(text)
                tr.appendChild(td)
                tableBody.appendChild(tr)
              })

              const tableRoot = tooltipEl.querySelector('table')

              // Remove old children
              while (tableRoot.firstChild) {
                tableRoot.firstChild.remove()
              }

              // Add new children
              tableRoot.appendChild(tableHead)
              tableRoot.appendChild(tableBody)
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1
            tooltipEl.style.left = positionX + tooltip.caretX + 80 + 'px'
            tooltipEl.style.top = positionY + tooltip.caretY + 'px'
            tooltipEl.style.font = tooltip.options.bodyFont.string
            tooltipEl.style.padding =
              tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
          }
        }
      }
    })
  }, [dataset])

  useEffect(() => {
    if (dataset) {
      let datasets = dataset.map((d) => d.Value || d.value)
      let total = dataset.reduce((total, b) => (b.value || b.Value) + total, 0)

      const calculatePercentage = (value) => {
        const percentage = (value * 100) / total
        if (percentage < 1) {
          return total * 0.01
        } else {
          return value
        }
      }
      datasets = datasets.map((d) => calculatePercentage(d))
      setData({
        datasets: [
          {
            data: datasets,
            backgroundColor: backgroundColors,
            borderWidth: 2,
            borderColor: colors.common.white,
            hoverBorderColor: colors.common.white
          }
        ],
        labels: dataset.map((d) => d.Label || d.label)
      })
    }
  }, [dataset, backgroundColors])

  useEffect(() => {
    if (!!colorArray) {
      setBackgroundColors(colorArray)
    }
  }, [colorArray])

  return (
    <Card
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      {...props}>
      <CardHeader
        title={
          typeof title === 'function'
            ? title(
                dataset
                  ? dataset.reduce(
                      (total, b) => (b.value || b.Value) + total,
                      0
                    )
                  : 0
              )
            : `${title} (${
                dataset
                  ? dataset.reduce(
                      (total, b) => (b.value || b.Value) + total,
                      0
                    )
                  : 0
              })`
        }
      />
      <Divider />
      <CardContent
        style={{
          flex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {pending ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress size={24} style={{ marginRight: '.5rem' }} />
            <Typography>Cargando información...</Typography>
          </div>
        ) : dataset && dataset.length > 0 ? (
          <Grid container>
            <Grid item lg={6}>
              <Box
                sx={{
                  height: 200,
                  position: 'relative'
                }}>
                <Pie data={data} options={options} />
              </Box>
            </Grid>
            <Grid item lg={6}>
              {pending ? (
                <>
                  <Skeleton variant={'text'} />
                  <Skeleton variant={'text'} width={75} />
                  <Skeleton variant={'text'} width={210} />
                  <Skeleton variant={'text'} width={240} />
                </>
              ) : (
                <>
                  {dataset &&
                    dataset.map((d, i) => (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <div
                          style={{
                            backgroundColor: backgroundColors[i] || 'black',
                            display: 'inline-block',
                            width: '8px',
                            height: '8px',
                            borderRadius: '1rem',
                            marginRight: '.5rem'
                          }}
                        />
                        <Typography
                          style={{
                            color: 'black',
                            display: 'inline'
                          }}>
                          {d.Label || d.label}:
                          <Typography
                            component={'span'}
                            style={{ color: 'black' }}>
                            {` ${
                              valueFormatter
                                ? valueFormatter(d.Value || d.value || 0, d)
                                : d.Value || d.value || 0
                            }${
                              !hidePercentage
                                ? ` (${getPercentage(d.Value || d.value)}%)`
                                : ''
                            }`}
                          </Typography>
                        </Typography>
                      </div>
                    ))}
                </>
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography>No hay suficiente información.</Typography>
        )}
      </CardContent>
    </Card>
  )
}
