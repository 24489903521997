import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadEventsWithTickets, resetActions } from '~/state/modules/resellers'
import { AppContainer } from '~/views/shared'
import { SellForCashIndexPage } from './components/SellForCashIndexPage'
import { Route } from 'react-router-dom'
import { ROUTES } from '~/views/routes'

export const SellForCashPage = () => {
  const dispatch = useDispatch()
  const { events, pending } = useSelector((state) => state.resellers)
  const [eventsWithUnits, setEventsWithUnits] = useState([])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEventsWithTickets())
    }
    fetchData().then()

    return () => {
      dispatch(resetActions())
    }
  }, [dispatch])

  useEffect(() => {
    if (events != null) {
      events.forEach((e) => {
        e.TicketTypes.forEach((t) => {
          t.Units = 0
        })
      })
      setEventsWithUnits(events)
    }
  }, [events])

  return (
    <AppContainer title='VENDER EN EFECTIVO'>
      <Route
        exact
        path={ROUTES.ADMIN.SELL.INDEX}
        render={(props) => (
          <SellForCashIndexPage
            {...props}
            events={eventsWithUnits}
            pending={pending}
          />
        )}
      />
    </AppContainer>
  )
}

export default SellForCashPage
