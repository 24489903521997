import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons'
import { resetFilters } from 'state/modules/dashboard'
import { logout } from '~/state/modules/login'
import ReactCountryFlag from 'react-country-flag'
import { useStyles } from './AppToolbar.style'

export const AppToolbar = ({ title }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { country } = useSelector((state) => state.app)

  const handleLogout = () => {
    dispatch(resetFilters())
    dispatch(logout())
  }

  return (
    <AppBar className={classes.appBar} position='relative'>
      <Toolbar>
        <Typography variant='h6' noWrap className={classes.title}>
          {title}
        </Typography>
        <Typography variant='h4'>
          {country} - &nbsp;
          <ReactCountryFlag countryCode={country} svg />
        </Typography>
        <IconButton edge='end' onClick={handleLogout} color='inherit'>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AppToolbar
