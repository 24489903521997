import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Input,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { Save, ArrowDropDown } from '@material-ui/icons'
import { Formik } from 'formik'
import * as Yup from 'yup'
import numeral from 'numeral'
import { useStyles } from '../EventTicketForm.style'
import { TicketRules } from 'views/pages/EventsPage/components/TicketRules'
import { TicketsComboDialog } from 'views/pages/EventsPage/components/TicketsCombo'
import { isNotNumber, round } from 'views/utils/functions'
import { ResellerCommission, PlatformCommission } from './components'
import {
  COMMISSION_TYPE,
  COMMISSION_USED,
  TICKET_USAGE
} from 'config/constants'
import { useDispatch, useSelector } from 'react-redux'
import { loadEvents, loadTicketsOfEvent } from 'state/modules/events'

const isValidArray = (ids) => {
  let isValid = true
  const idsArray = ids?.split(',')
  const arrayFiltered = idsArray?.filter((v, i) => idsArray?.indexOf(v) === i)
  if (arrayFiltered?.length < idsArray?.length) {
    isValid = false
  }
  return isValid
}

const validationSchema = (byPercentPlatform, byPercentReseller) =>
  Yup.object().shape({
    Name: Yup.string()
      .required('Es requerido ingresar nombre del ticket')
      .max(250, "La cantidad máxima de caracteres es de '250'"),
    Price: Yup.number()
      .typeError('Subtotal debe ser un número')
      .min(0, 'Subtotal al público no puede ser menor a 0'),
    PercentageCost: Yup.number()
      .typeError('El margen de utilidad debe ser un número')
      .required('Es requerido ingresar margen de utilidad')
      .moreThan(0, 'El margen de utilidad debe ser mayor a 0'),
    CommissionRrpp:
      byPercentReseller &&
      Yup.number()
        .typeError('La comisión RRPP debe ser un número')
        .min(0, 'La comisión RRPP no puede ser menor a 0')
        .lessThan(101, 'La comisión RRPP no puede ser mayor a 100'),
    PlatformCommission:
      byPercentPlatform &&
      Yup.number()
        .typeError('La comisión ticketPass debe ser un número')
        .min(0, 'La comisión ticketPass no puede ser menor a 0')
        .lessThan(101, 'La comisión ticketPass no puede ser mayor a 100'),
    Cost: Yup.number()
      .typeError('El valor que se rinde a la productora debe ser un número')
      .required('Es requerido ingresar el valor que se rinde a la productora')
      .moreThan(0, 'El valor que se rinde a la productora debe ser mayor a 0'),
    Remaining: Yup.number()
      .required('Es requerido ingresar cantidad de tickets')
      .integer('Solo se permiten valores enteros')
      .min(0, 'Cantidad no puede ser menor a 0'),
    ComboTickets: Yup.string()
      .matches('^[0-9]+(?:,[0-9]+)*$', 'Formato incorrecto')
      .test('combo-tickets', 'No se permiten Ids repetidos', (ids) =>
        isValidArray(ids)
      ),
    Rules: Yup.string(),
    MaxQuantityToSale: Yup.number()
      .nullable(true)
      .integer('Solo se permiten valores enteros')
      .typeError('Solo se permiten valores numéricos')
      .min(0, 'La cantidad no puede ser menor a 0')
  })

export const TicketSaleFormV2 = ({
  values,
  isCloned,
  getComboTickets,
  getIdsTickets,
  commissionsMp,
  vatList,
  isProducer,
  actionText,
  isOldCommissions,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    checkedC: !values.DisabledDatesForm,
    checkedD: false
  })

  const [disableFormA, setDisableFormA] = useState(values.DisabledDatesForm)
  const [open, setOpen] = useState(false)
  const [openDialogTickets, setOpenDialogTickets] = useState(false)
  const [byPercentReseller, setByPercentReseller] = useState(
    values?.ByPercentReseller
  )
  const [byPercentPlatform, setByPercentPlatform] = useState(
    values?.ByPercentPlatform
  )
  const [newValues, setNewValues] = useState()

  values.MaxQuantityToSale =
    actionText === 'Crear' && !isCloned ? 2 : values.MaxQuantityToSale

  const handleWheel = (e) => {
    e.preventDefault() // Evitar el desplazamiento
  }

  const message =
    'Ten en cuenta que este monto es un subtotal, sin incluir la comisión de la pasarela de pago. Recuerda que la configuración de cobro de la pasarela de pago debe realizarse en la sección MIS CUENTAS.'

  const toggleA = (values, setFieldValue) => {
    setDisableFormA((d) => !d)
    setFieldValue('DisabledDatesForm', !values.DisabledDatesForm)
  }
  const [hideRules, setHideRules] = useState(true)

  const toggleRules = () => setHideRules((d) => !d)

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const validateNaNValue = (calculation) => {
    if (isNaN(calculation) || !isFinite(calculation)) {
      return 0
    }
    return calculation
  }

  const truncate = (value, positions = 2) => {
    return parseFloat(value.toFixed(positions))
  }

  const calculateFields = (
    value,
    field,
    values,
    setFieldValue,
    commission,
    byPercentPlatform,
    byPercentReseller
  ) => {
    const { Commissions, Cost } = values
    const floatCost = validateNaNValue(parseFloat(Cost))
    const floatValue = validateNaNValue(parseFloat(value))
    //update commissions
    const updateCommissions = Commissions.map((v) => {
      if (v.CommissionType === commission.CommissionType) {
        return {
          ...v,
          [field]: floatValue
        }
      } else {
        return v
      }
    })
    setFieldValue('Commissions', updateCommissions)

    let helperText = 0
    let commissionReseller = updateCommissions.find(
      (c) => c.CommissionType === COMMISSION_TYPE.RESELLER
    )
    let commissionPlatform = updateCommissions.find(
      (c) => c.CommissionType === COMMISSION_TYPE.PLATFORM
    )
    let taxAmountPlatform = 0
    let texAmountReseller = 0
    //calculated Price
    let calculatedPrice = 0
    let sum = 0
    updateCommissions.forEach((v) => {
      if (v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY) {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          if (!byPercentReseller) {
            sum += v.CommissionWithTaxAmount
          } else {
            texAmountReseller =
              (floatCost * commissionReseller.Commission) / 100

            sum += texAmountReseller
          }
        }

        if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
          if (!byPercentPlatform) {
            sum += v.CommissionWithTaxAmount
          } else {
            helperText = commissionPlatform.Commission * (1 + v.CommissionVat)

            if (byPercentReseller) {
              texAmountReseller =
                (floatCost * commissionReseller.Commission) / 100
            } else {
              texAmountReseller = commissionReseller.CommissionWithTaxAmount
            }
            taxAmountPlatform =
              ((floatCost + texAmountReseller) * helperText) / 100
            sum += taxAmountPlatform
          }
        }
      }
    })

    calculatedPrice = floatCost + sum
    if (!byPercentReseller) {
      texAmountReseller = commissionReseller.CommissionWithTaxAmount
    }
    //set Commissions
    const newCommissions = updateCommissions.map((v) => {
      if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
        if (byPercentPlatform) {
          helperText = commissionPlatform.Commission * (1 + v.CommissionVat)

          return {
            ...v,
            CommissionWithTaxAmount: validateNaNValue(
              ((floatCost + texAmountReseller) * helperText) / 100
            )
          }
        } else {
          helperText =
            (v.CommissionWithTaxAmount / (floatCost + texAmountReseller)) * 100

          return {
            ...v,
            Commission: validateNaNValue(helperText / (1 + v.CommissionVat))
          }
        }
      } else if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        if (byPercentReseller) {
          return {
            ...v,
            CommissionWithTaxAmount:
              (floatCost * commissionReseller.Commission) / 100
          }
        } else {
          return {
            ...v,
            Commission: validateNaNValue(
              (v.CommissionWithTaxAmount / floatCost) * 100
            )
          }
        }
      } else {
        return v
      }
    })

    setFieldValue('Commissions', newCommissions)

    setFieldValue('Price', calculatedPrice)

    // PercentageCost calculated
    const calculatedPercentCost = validateNaNValue(
      (floatCost * 100) / calculatedPrice
    )
    setFieldValue('PercentageCost', calculatedPercentCost)

    // TicketPrice & ServiceCharge Calculated
    let totalTicketPrice = 0
    let totalServiceCharge = 0
    newCommissions.forEach((v) => {
      const withTaxAmount = v.CommissionWithTaxAmount
      if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        totalTicketPrice = floatCost + withTaxAmount
      } else {
        totalServiceCharge += withTaxAmount
      }
    })
    setFieldValue('TicketPrice', totalTicketPrice)
    setFieldValue('ServiceCharge', totalServiceCharge)
  }

  const calculateFieldsByCost = (
    value,
    values,
    setFieldValue,
    byPercentPlatform,
    byPercentReseller
  ) => {
    const { Commissions } = values
    const floatValue = validateNaNValue(parseFloat(value))
    //calculated Price
    let commissionReseller = Commissions.find(
      (c) => c.CommissionType === COMMISSION_TYPE.RESELLER
    )
    let commissionPlatform = Commissions.find(
      (c) => c.CommissionType === COMMISSION_TYPE.PLATFORM
    )
    let helperText = 0
    let calculatedPrice = 0
    let sum = 0
    let taxAmountPlatform = 0
    let texAmountReseller = 0
    Commissions.forEach((v) => {
      if (v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY) {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          if (!byPercentReseller) {
            sum += v.CommissionWithTaxAmount
          } else {
            texAmountReseller =
              (floatValue * commissionReseller.Commission) / 100

            sum += texAmountReseller
          }
        }

        if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
          if (!byPercentPlatform) {
            sum += v.CommissionWithTaxAmount
          } else {
            helperText = commissionPlatform.Commission * (1 + v.CommissionVat)

            if (byPercentReseller) {
              texAmountReseller =
                (floatValue * commissionReseller.Commission) / 100
            } else {
              texAmountReseller = commissionReseller.CommissionWithTaxAmount
            }
            taxAmountPlatform =
              ((floatValue + texAmountReseller) * helperText) / 100
            sum += taxAmountPlatform
          }
        }
      }
    })

    calculatedPrice = floatValue + sum

    setFieldValue('Price', calculatedPrice)
    if (!byPercentReseller) {
      texAmountReseller = commissionReseller.CommissionWithTaxAmount
    }
    //set Commissions
    const newCommissions = Commissions.map((v) => {
      if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
        if (byPercentPlatform) {
          helperText = commissionPlatform.Commission * (1 + v.CommissionVat)

          return {
            ...v,
            CommissionWithTaxAmount: validateNaNValue(
              ((floatValue + texAmountReseller) * helperText) / 100
            )
          }
        } else {
          helperText =
            (v.CommissionWithTaxAmount / (floatValue + texAmountReseller)) * 100

          return {
            ...v,
            Commission: validateNaNValue(helperText / (1 + v.CommissionVat))
          }
        }
      } else if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        if (byPercentReseller) {
          return {
            ...v,
            CommissionWithTaxAmount:
              (floatValue * commissionReseller.Commission) / 100
          }
        } else {
          return {
            ...v,
            Commission: validateNaNValue(
              (v.CommissionWithTaxAmount / floatValue) * 100
            )
          }
        }
      } else {
        return v
      }
    })

    setFieldValue('Commissions', newCommissions)

    setFieldValue('Price', calculatedPrice)

    // PercentageCost Calculated
    const calculatedPercentCost = validateNaNValue(
      (floatValue * 100) / calculatedPrice
    )
    setFieldValue('PercentageCost', calculatedPercentCost)

    // TicketPrice & ServiceCharge Calculated
    let totalTicketPrice = 0
    let totalServiceCharge = 0
    newCommissions.forEach((v) => {
      const withTaxAmount = v.CommissionWithTaxAmount
      if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        totalTicketPrice = floatValue + withTaxAmount
      } else {
        totalServiceCharge += withTaxAmount
      }
    })
    setFieldValue('TicketPrice', totalTicketPrice)
    setFieldValue('ServiceCharge', totalServiceCharge)
  }

  const calculatePercentage = (commissions, totalPrice) => {
    let total = 0
    const platformCommission = commissions.find(
      (e) => e.CommissionType === COMMISSION_TYPE.PLATFORM
    )
    const amountPlatformCommission = platformCommission?.CommissionWithTaxAmount
    total = (amountPlatformCommission / totalPrice) * 100
    return round(total) === 100 ? 0 : total
  }

  const calculatePercentageReseller = (commissions, totalPrice) => {
    let total = 0
    const commissionReseller = commissions.find(
      (c) => c.CommissionType === COMMISSION_TYPE.RESELLER
    )
    total = (commissionReseller.CommissionWithTaxAmount / totalPrice) * 100
    return round(total) === 100 ? 0 : total
  }

  const calculateHelperTextPlatform = (commissions) => {
    let helperText = 0
    const commissionPlatform = commissions.find(
      (e) => e.CommissionType === COMMISSION_TYPE.PLATFORM
    )
    helperText =
      commissionPlatform.Commission * (1 + commissionPlatform.CommissionVat)
    return round(helperText) === 100 ? 0 : helperText
  }

  const validateValues = (values, handleSubmit, errors) => {
    const validatedValues = JSON.parse(JSON.stringify(values))
    if (Object.keys(errors).length === 0 && validatedValues.Name !== '') {
      validatedValues.Cost = parseFloat(validatedValues.Cost)
      if (isCloned) {
        validatedValues.Commissions = validatedValues.Commissions.map(
          ({ Id, TicketTypeId, CommissionAmount, ...rest }) => {
            if (rest.CommissionType === COMMISSION_TYPE.RESELLER) {
              return {
                ...rest,
                Commission: truncate(rest.Commission / 100, 6),
                CommissionUsed: byPercentReseller
                  ? COMMISSION_USED.BY_PERCENT
                  : COMMISSION_USED.BY_AMOUNT
              }
            } else {
              return {
                ...rest,
                Commission: round(rest.Commission / 100, 6),
                CommissionUsed: byPercentPlatform
                  ? COMMISSION_USED.BY_PERCENT
                  : COMMISSION_USED.BY_AMOUNT
              }
            }
          }
        ).filter((v) => v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY)
      } else {
        validatedValues.Commissions = validatedValues.Commissions.map((v) => {
          if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
            return {
              ...v,
              Commission: truncate(v.Commission / 100, 6),
              CommissionUsed: byPercentReseller
                ? COMMISSION_USED.BY_PERCENT
                : COMMISSION_USED.BY_AMOUNT
            }
          } else {
            return {
              ...v,
              Commission: round(v.Commission / 100, 6),
              CommissionUsed: byPercentPlatform
                ? COMMISSION_USED.BY_PERCENT
                : COMMISSION_USED.BY_AMOUNT
            }
          }
        }).filter((v) => v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY)
      }
    }
    setNewValues(validatedValues)
    handleSubmit(validatedValues)
  }

  // Being rules section
  const {
    events,
    ruleTickets: tickets,
    actionPending: pendingEvents,
    ruleTicketsPending: pendingTickets
  } = useSelector((state) => state.events)

  const fetchEvents = useCallback(async () => {
    await dispatch(loadEvents())
  }, [dispatch])

  const fetchTicketsOfEvent = useCallback(
    async (eventId) => {
      return await dispatch(loadTicketsOfEvent(eventId))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!hideRules) {
      fetchEvents().then()
    }
  }, [fetchEvents, hideRules])
  // End rules section

  useEffect(() => {
    if (values) {
      values.PercentageCost = Math.floor(values.PercentageCost * 100) / 100
      values.Rules?.trim() !== '' && setHideRules(false)
      if (commissionsMp && vatList) {
        if (values.PaymentGatewayCommission === '') {
          values.PaymentGatewayCommission =
            commissionsMp[0].Fee * (1 + vatList[0])
        }
        if (actionText === 'Crear' && !isCloned) {
          values.Commissions = values.Commissions.map((v) => {
            if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
              return {
                ...v,
                CommissionVat: vatList[0]
              }
            }
            return v
          })
        } else {
          values.Commissions = values.Commissions.map((v) => {
            return {
              ...v,
              Commission: round(v.Commission * 100, 4)
            }
          })
        }
      }
    }
  }, [values, commissionsMp, actionText, vatList, isCloned])

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema(byPercentPlatform, byPercentReseller)}
      onSubmit={(values) => {
        if (hideRules) {
          values.Rules = ''
        }
        handleSubmit(newValues)
      }}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            {isOldCommissions && (
              <Alert severity='warning' style={{ margin: 8 }}>
                ATENCIÓN: A este tipo de ticket no se le permite modificar las
                comisiones, ya que corresponde a un ticket creado sin la
                actualización de Comisiones. Si desea cambiar alguna comisión
                deberá editarlo desde la AppManager.
              </Alert>
            )}
            <div className={classes.actionsContainerTop}>
              <Button
                onClick={onCancel}
                disabled={actionSuccess}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  values.DisabledDatesForm = state.checkedC
                  values.TicketUsage = TICKET_USAGE.SALE
                  setOpen(true)
                }}
                disabled={actionPending || actionSuccess}
                color='primary'
                variant='contained'
                startIcon={<Save />}>
                {actionPending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  actionText
                )}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del ticket (visible al usuario)'
                }
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onFocus={(v) => {
                  handleBlur(v)
                }}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
                autoFocus
              />
            </div>

            {!isOldCommissions && (
              <>
                <div className={classes.containerInputs}>
                  <TextField
                    label={
                      (errors.Cost && touched.Cost && errors.Cost) ||
                      'Valor al que se rinde la productora'
                    }
                    id='standard-start-adornment'
                    style={{ width: 300 }}
                    type='string'
                    name='Cost'
                    error={Boolean(touched.Cost && errors.Cost)}
                    value={round(values.Cost)}
                    onChange={(e) => {
                      handleChange(e)
                      calculateFieldsByCost(
                        e.target.value,
                        values,
                        setFieldValue,
                        byPercentPlatform,
                        byPercentReseller
                      )
                    }}
                    onBlur={handleBlur}
                    onKeyPress={(e) => isNotNumber(e)}
                    onKeyDown={(e) => isNotNumber(e)}
                    onKeyUp={handleChange}
                    helperText='&zwnj;'
                    inputProps={{ maxLength: 8 }}
                    className={`${classes.fieldCommission} ${classes.fieldCommissionAmount}`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      )
                    }}
                    variant='standard'
                  />
                  <FormControl
                    variant='standard'
                    className={`${classes.formControl} ${classes.formControlCommission}`}>
                    <Input
                      id='standard-adornment-weight'
                      name='PercentageCost'
                      value={validateNaNValue(
                        numeral(round(values.PercentageCost)).format('0.00')
                      )}
                      onChange={handleChange}
                      disabled
                      onBlur={handleBlur}
                      error={Boolean(errors.PercentageCost)}
                      endAdornment={
                        <InputAdornment position='end'>%</InputAdornment>
                      }
                      className={`${classes.fieldCommission} ${classes.fieldCommissionPercent}`}
                      aria-describedby='standard-weight-helper-text'
                      onKeyPress={(e) => isNotNumber(e)}
                      onKeyDown={(e) => isNotNumber(e)}
                      inputProps={{
                        'aria-label': 'weight',
                        maxLength: 3
                      }}
                    />
                    <FormHelperText
                      id='standard-weight-helper-text'
                      error={Boolean(errors.PercentageCost)}>
                      {errors.PercentageCost || 'Margen de utilidad'}
                    </FormHelperText>
                  </FormControl>
                </div>
                <ResellerCommission
                  byPercentPlatform={byPercentPlatform}
                  byPercentReseller={byPercentReseller}
                  setByPercentReseller={setByPercentReseller}
                  helperTextPercent={
                    (errors.CommissionRrpp &&
                      touched.CommissionRrpp &&
                      errors.CommissionRrpp) ||
                    '\u0020'
                  }
                  handleBlur={handleBlur}
                  commission={values.Commissions.find(
                    (e) => e.CommissionType === COMMISSION_TYPE.RESELLER
                  )}
                  error={
                    errors.CommissionRrpp &&
                    touched.CommissionRrpp &&
                    errors.CommissionRrpp
                  }
                  setFieldValue={setFieldValue}
                  values={values}
                  calculateFields={calculateFields}
                  validateNaNValue={validateNaNValue}
                  calculatePercentage={calculatePercentageReseller(
                    values.Commissions,
                    values.Price
                  )}
                />
                <PlatformCommission
                  byPercentReseller={byPercentReseller}
                  byPercentPlatform={byPercentPlatform}
                  setByPercentPlatform={setByPercentPlatform}
                  helperTextPercent={
                    (errors.PlatformCommission &&
                      touched.PlatformCommission &&
                      errors.PlatformCommission) ||
                    'Sin IVA'
                  }
                  handleBlur={handleBlur}
                  commission={values.Commissions.find(
                    (e) => e.CommissionType === COMMISSION_TYPE.PLATFORM
                  )}
                  vatList={vatList}
                  setFieldValue={setFieldValue}
                  values={values}
                  error={
                    errors.PlatformCommission &&
                    touched.PlatformCommission &&
                    errors.PlatformCommission
                  }
                  calculateFields={calculateFields}
                  validateNaNValue={validateNaNValue}
                  isProducer={isProducer}
                  helperTextPlatform={calculateHelperTextPlatform(
                    values.Commissions
                  )}
                  calculatePercentage={calculatePercentage(
                    values.Commissions,
                    values.Price
                  )}
                />
              </>
            )}
            <div className={classes.containerPrice}>
              <div
                className={`${classes.containerInputs} ${classes.inputPrice}`}>
                <TextField
                  disabled
                  label={errors.Price || 'Subtotal'}
                  name='Price'
                  value={truncate(values.Price)}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors.Price)}
                  style={{ width: 520 }}
                  required
                  size='small'
                  variant='outlined'
                  className={classes.field}
                />
                {!isOldCommissions && (
                  <>
                    <div className={classes.containerInputs}>
                      <Typography variant='h6'>
                        Precio del ticket:&nbsp;
                        <Typography
                          variant='h6'
                          component='span'
                          className={classes.summary}>
                          {values.TicketPrice.toString().split('.')[1]?.length >
                          2
                            ? round(values.TicketPrice)
                            : values.TicketPrice}
                        </Typography>
                      </Typography>
                      <Typography variant='h6'>
                        Cargo por servicio:&nbsp;
                        <Typography
                          variant='h6'
                          component='span'
                          className={classes.summary}>
                          {values.ServiceCharge.toString().split('.')[1]
                            ?.length > 2
                            ? round(values.ServiceCharge)
                            : values.ServiceCharge}
                        </Typography>
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Remaining && touched.Remaining && errors.Remaining) ||
                  'Cantidad'
                }
                name='Remaining'
                type='string'
                onKeyPress={(e) => isNotNumber(e)}
                onKeyDown={(e) => isNotNumber(e)}
                value={values.Remaining}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Remaining && Boolean(errors.Remaining)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow} onWheel={handleWheel}>
              <TextField
                label={
                  (errors.MaxQuantityToSale &&
                    touched.MaxQuantityToSale &&
                    errors.MaxQuantityToSale) ||
                  'Cantidad máxima de Tickets para vender'
                }
                name='MaxQuantityToSale'
                type='string'
                onKeyPress={(e) => isNotNumber(e)}
                onKeyDown={(e) => isNotNumber(e)}
                value={values.MaxQuantityToSale}
                inputProps={{ maxLength: 9 }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.MaxQuantityToSale && Boolean(errors.MaxQuantityToSale)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>

            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
              <InputLabel shrink={true}>
                {(errors.ComboTickets &&
                  touched.ComboTickets &&
                  errors.ComboTickets) ||
                  'Tickets Ids de Combos'}
              </InputLabel>
              <Button
                disableFocusRipple
                onClick={() => setOpenDialogTickets(true)}
                variant='outlined'
                className={classes.textButton}
                endIcon={<ArrowDropDown />}>
                {values.ComboTickets
                  ? values.ComboTickets
                  : 'Seleccione tickets'}
              </Button>
              <TicketsComboDialog
                comboTickets={getComboTickets(values?.ComboTicketModel)}
                idsTickets={getIdsTickets(values.ComboTickets)}
                openDialogTickets={openDialogTickets}
                setOpenDialogTickets={setOpenDialogTickets}
                setFieldValue={setFieldValue}
              />
            </FormControl>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsTransferable}
                    onChange={handleChange}
                    name='IsTransferable'
                  />
                }
                label='Es transferible?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Active}
                    onChange={handleChange}
                    name='Active'
                  />
                }
                label='Habilitado?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.ShowRemaining}
                    onChange={handleChange}
                    name='ShowRemaining'
                  />
                }
                label='Mostrar cantidad?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Billable}
                    onChange={handleChange}
                    name='Billable'
                  />
                }
                label='Facturable?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedC}
                    onChange={handleChangeCheck}
                    onClick={() => {
                      toggleA(values, setFieldValue)
                    }}
                    name='checkedC'
                  />
                }
                label='Habilitar fecha de inicio y de fin de venta de ticket?'
              />
            </div>
            <span>
              {!disableFormA && (
                <div className={classes.fieldsContainer}>
                  <FormControl variant='outlined' className={classes.fieldRow}>
                    <DatePicker
                      locale='es'
                      id='SaleStartDate'
                      format='DD/MM/YYYY'
                      name='SaleStartDate'
                      value={values.SaleStartDate}
                      label={
                        (errors.SaleStartDate &&
                          touched.SaleStartDate &&
                          errors.SaleStartDate) ||
                        'Fecha de Inicio'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleStartDate', value)
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.SaleStartDate && Boolean(errors.SaleStartDate)
                      }
                      disableToolbar
                      className={classes.field}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                      animateYearScrolling
                    />
                    <TimePicker
                      locale='es'
                      name='SaleStartDate'
                      className={classes.field}
                      value={values.SaleStartDate}
                      label={
                        (errors.SaleStartDate &&
                          touched.SaleStartDate &&
                          errors.SaleStartDate) ||
                        'Hora de Inicio'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleStartDate', value)
                      }}
                      error={
                        touched.SaleStartDate && Boolean(errors.SaleStartDate)
                      }
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  <FormControl variant='outlined' className={classes.fieldRow}>
                    <DatePicker
                      locale='es'
                      id='SaleEndDate'
                      format='DD/MM/YYYY'
                      name='SaleEndDate'
                      value={values.SaleEndDate}
                      label={
                        (errors.SaleEndDate &&
                          touched.SaleEndDate &&
                          errors.SaleEndDate) ||
                        'Fecha de Fin'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleEndDate', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.SaleEndDate && Boolean(errors.SaleEndDate)}
                      disableToolbar
                      className={classes.field}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                      animateYearScrolling
                    />
                    <TimePicker
                      locale='es'
                      name='SaleEndDate'
                      className={classes.field}
                      value={values.SaleEndDate}
                      label={
                        (errors.SaleEndDate &&
                          touched.SaleEndDate &&
                          errors.SaleEndDate) ||
                        'Hora de Fin'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleEndDate', value)
                      }}
                      error={touched.SaleEndDate && Boolean(errors.SaleEndDate)}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>
              )}
            </span>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'hasRules'}
                    checked={!hideRules}
                    onClick={toggleRules}
                  />
                }
                label='Reglas?'
              />
            </div>
            <span>
              {!hideRules && (
                <div className={classes.fieldsContainer}>
                  <TicketRules
                    value={values.Rules}
                    events={events}
                    tickets={tickets}
                    pendingEvents={pendingEvents}
                    pendingTickets={pendingTickets}
                    onRequestTicketsOfEvent={(event) =>
                      fetchTicketsOfEvent(event.Id)
                    }
                    onChange={(rules) => {
                      setFieldValue('Rules', rules)
                    }}
                  />
                </div>
              )}
            </span>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={actionSuccess} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                values.DisabledDatesForm = state.checkedC
                values.TicketUsage = TICKET_USAGE.SALE
                setOpen(true)
              }}
              disabled={actionPending || actionSuccess}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle>¡ADVERTENCIA!</DialogTitle>
            <DialogContent>
              <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  setOpen(false)
                  validateValues(values, handleSubmit, errors)
                }}
                color='primary'
                disabled={actionPending}
                variant='contained'>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  )
}

export default TicketSaleFormV2
