import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  warningRow: {
    backgroundColor: '#fec950',
    '&:hover': {
      backgroundColor: '#F7B217 !important'
    }
  },
  inactiveRow: {
    backgroundColor: '#ffcccc',
    '&:hover': {
      backgroundColor: '#fcb0b1 !important'
    }
  },
  form: {
    display: 'flex'
  },
  input: {
    flexGrow: 1
  },
  textBadge: {
    fontSize: '14px',
    backgroundColor: 'green',
    color: 'white',
    paddingInline: '10px',
    paddingBlock: '4px',
    borderRadius: '18px'
  },
  button: {
    textTransform: 'none',
    borderRadius: theme.spacing(2)
  }
}))

export default useStyles
