import BaseProxy from './BaseProxy'
import axios from 'axios'

export class StatisticsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/statistics`
  }

  async getStatisticsOfEventById(eventId) {
    const response = await axios.get(`${this._baseUrl}/event/${eventId}`)

    return response.data
  }

  async getStatisticsOfEventByTicketType(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/tickettype`
    )

    return response.data.sort((a, b) =>
      a.TicketName.localeCompare(b.TicketName)
    )
  }

  async updateStatistics(eventId) {
    const response = await axios.post(`${this._baseUrl}/event/${eventId}`)

    return response.data
  }
}

export default StatisticsProxy
