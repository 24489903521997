import React from 'react'
import { useDispatch } from 'react-redux'
import { resetActions } from 'state/modules/locations'
import { AppContainer } from '~/views/shared'
import { AccountsIndexPage } from './AccountsIndexPage'
import { AccountsCreatePage } from './AccountsCreatePage'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'

export const AccountsPage = () => {
  const dispatch = useDispatch()

  const handleSuccessfulSubmit = async () => {
    await dispatch(resetActions())
    history.push(ROUTES.ADMIN.ACCOUNTS.INDEX)
  }

  return (
    <AppContainer title='Cuentas'>
      <Switch>
        <Route
          path={ROUTES.ADMIN.ACCOUNTS.INDEX}
          component={AccountsIndexPage}
          exact
        />

        <Route
          path={ROUTES.ADMIN.ACCOUNTS.CREATE}
          render={(props) => (
            <AccountsCreatePage {...props} onSuccess={handleSuccessfulSubmit} />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default AccountsPage
