import * as types from './types'

// InitialState
const initialState = {
  isDrawerOpen: true,
  snackbarNotification: null,
  country: 'ar'
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: true
      }
    case types.CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false
      }
    case types.SET_SNACKBAR_NOTIFICATION:
      return {
        ...state,
        snackbarNotification: action.notification
      }
    case types.SET_COUNTRY:
      return {
        ...state,
        country: action.country
      }
    default:
      return state
  }
}
