export const PAGEDLIST_ATTEMPT = 'admin/locations/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/locations/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/locations/PAGEDLIST_FAILURE'

export const LOAD_ATTEMPT = 'admin/locations/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/locations/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/locations/LOAD_FAILURE'

export const CREATE_ATTEMPT = 'admin/locations/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'admin/locations/CREATE_SUCCESS'
export const CREATE_FAILURE = 'admin/locations/CREATE_FAILURE'

export const UPDATE_ATTEMPT = 'admin/locations/UPDATE_ATTEMPT'
export const UPDATE_SUCCESS = 'admin/locations/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'admin/locations/UPDATE_FAILURE'

export const DELETE_ATTEMPT = 'admin/locations/DELETE_ATTEMPT'
export const DELETE_SUCCESS = 'admin/locations/DELETE_SUCCESS'
export const DELETE_FAILURE = 'admin/locations/DELETE_FAILURE'

export const RESET = 'admin/locations/RESET'
export const RESET_ACTIONS = 'admin/locations/RESET_ACTIONS'
export const RESET_PARAMS = 'admin/locations/RESET_PARAMS'
