export const ROUTES = {
  PUBLIC: {
    ROOT: '/',
    LANDING: '/landing',
    LOGIN: '/login',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    UNAUTHORIZED: '/unauthorized'
  },
  ADMIN: {
    ROOT: '/admin',
    PROFILE: '/profile',
    DASHBOARD: {
      INDEX: '/dashboard',
      VIEW: '/dashboard/:eventId',
      DETAILS: '/dashboard/:eventId/:userId'
    },
    LOCATIONS: {
      INDEX: '/locations',
      CREATE: '/locations/create',
      VIEW: '/locations/:id',
      UPDATE: '/locations/:id/update'
    },
    EVENTS: {
      INDEX: '/events',
      CREATE: '/events/create',
      VIEW: '/events/:eventId',
      UPDATE: '/events/:eventId/update',
      IMAGES: '/events/:eventId/images',
      RESELLERS: {
        INDEX: '/events/:eventId/resellers',
        VIEW: '/events/:eventId/resellers/:userId/sales', // rrpp
        CASHVIEW: '/events/:eventId/resellers/:userId/sales/cash', //rrpp $
        TPVIEW: '/events/:eventId/reseller/:userId/' // rrpp tp
      },
      INVITATIONS: '/events/:eventId/invitations',
      TICKETS: {
        INDEX: '/events/:eventId/tickets',
        CREATE: '/events/:eventId/tickets/create/:ticketUsage',
        CLONE: '/events/:eventId/tickets/:ticketUsage/cloned/:originalId',
        ORDERS: '/events/:eventId/tickets/orders',
        VIEW: '/events/:eventId/tickets/:ticketId',
        UPDATE: '/events/:eventId/tickets/:ticketId/update'
      },
      ORDERS: {
        INDEX: '/events/:eventId/orders',
        HISTORY: '/events/:eventId/orders/:orderId/history',
        DETAILS: '/events/:eventId/orders/:orderId/:status/details',
        TICKETS: '/events/:eventId/orders/:orderId/tickets'
      },
      DRAWS: {
        INDEX: '/events/:eventId/draws',
        CREATE: '/events/:eventId/draws/create',
        UPDATE: '/events/:eventId/draws/:drawId/update',
        DETAILS: '/events/:eventId/draws/:drawId/details'
      }
    },
    ORGANIZERS: {
      INDEX: '/organizers',
      CREATE: '/organizers/create',
      VIEW: '/organizers/:id',
      UPDATE: '/organizers/:id/update',
      INVITATIONS: '/organizers/:id/invitations',
      ACCOUNTS: '/organizers/:organizerId/accounts',
      BILLING: {
        CREDENTIALS: '/organizers/:id/billing/credentials',
        SETTINGS: '/organizers/:id/billing/settings'
      },
      CONTROLLERS: {
        INDEX: '/organizers/:id/controllers',
        CREATE: '/organizers/:id/controllers/create'
      },
      RESELLERS: {
        INDEX: '/organizers/:id/resellers',
        CREATE: '/organizers/:id/resellers/create'
      },
      PRODUCERS: {
        INDEX: '/organizers/:id/producers',
        CREATE: '/organizers/:id/producers/create'
      }
    },
    RESELLERS: {
      INDEX: '/resellers',
      CREATE: '/resellers/create',
      VIEW: '/resellers/:userId',
      UPDATE: '/resellers/:userId/update',
      SEARCH: '/resellers/search'
    },
    ACCOUNTS: {
      INDEX: '/accounts',
      CREATE: '/accounts/create'
      //VIEW: '/accounts/:userId',
      //UPDATE: '/accounts/:userId/update',
      //SEARCH: '/accounts/search'
    },
    USERS: {
      INDEX: '/users',
      VIEW: '/users/:searchKey',
      ORDERS: {
        INDEX: '/users/:userId/orders',
        DETAILS: '/users/:userId/orders/:orderId/details',
        HISTORY: '/users/events/:eventId/orders/:orderId/history',
        TICKETS: '/users/events/:eventId/orders/:orderId/tickets',
        TRANSFER: '/users/events/:eventId/orders/:orderId/transfer',
        PAYMENTS: '/users/events/:eventId/orders/:orderId/payments'
      },
      TICKETS: {
        INDEX: '/users/:userId/tickets',
        DETAILS: '/users/:userId/orders/:orderId/details/bytickets',
        PAYMENTS: '/users/events/:eventId/orders/:orderId/payments/bytickets',
        TRANSFER: '/users/events/:eventId/orders/:orderId/transfer/bytickets',
        TICKET_LIST: '/users/events/:eventId/orders/:orderId/tickets/bytickets',
        HISTORY: '/users/events/:eventId/orders/:orderId/history/bytickets'
      },
      UNIFY: {
        INDEX: '/users/:userId/unify',
        SEARCH: '/users/:userId/unify/search'
      },
      ROLES: {
        INDEX: '/users/:userId/roles'
      }
    },
    NOTIFICATIONS: {
      ALL: '/notifications/to/all',
      CUSTOM_ALL: '/notifications/custom/to/all',
      EVENT: '/notifications/by/event',
      TICKET: '/notifications/by/event/by/ticket',
      CUSTOM: '/notifications/to/maillist/custom',
      EVENTS: '/notifications/custom/by/events',
      SEARCH: '/notifications/to/maillist/custom/search',
      PRODUCERS: '/notifications/custom/to/producers'
    },
    ROLES: {
      INDEX: '/role/:roleId',
      SEARCH: '/role/:roleId/search'
    },
    PRODUCERS: {
      INDEX: '/producers'
    },
    SUPPORT: {
      INDEX: '/support',
      ORDERS: {
        INDEX: '/support/orders/index',
        DETAILS: '/support/orders/:orderId/details',
        HISTORY: '/support/events/:eventId/orders/:orderId/history',
        TICKETS: '/support/events/:eventId/orders/:orderId/tickets',
        TRANSFER: '/support/events/:eventId/orders/:orderId/transfer',
        PAYMENTS: '/support/events/:eventId/orders/:orderId/payments'
      }
    },
    SELL: {
      INDEX: '/sellForCash'
    }
  }
}
