import axios from 'axios'
import BaseProxy from './BaseProxy'

export class OrganizersProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/organizers`
  }

  async get(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getAll() {
    const response = await axios.get(`${this._baseUrl}`)

    return response.data
  }

  async getById(id) {
    const response = await axios.get(`${this._baseUrl}/${id}`)

    return response.data
  }

  async post(organizer) {
    const response = await axios.post(`${this._baseUrl}`, organizer)

    return response.data
  }

  async put(id, organizer) {
    const response = await axios.put(`${this._baseUrl}/${id}`, organizer)

    return response.data
  }

  async delete(id) {
    const response = await axios.delete(`${this._baseUrl}/${id}`)

    return response.data
  }

  async getAllResellers(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/resellers/all`)

    return response.data
  }

  async getControllers(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/withdrawpoints`)

    return response.data.sort((a, b) => a.FullName.localeCompare(b.FullName))
  }

  async deleteController(id, userId) {
    const response = await axios.delete(
      `${this._baseUrl}/${id}/withdrawpoint/${userId}`
    )

    return response.data
  }

  async postController(id, userId) {
    const response = await axios.post(
      `${this._baseUrl}/${id}/withdrawpoint/${userId}`
    )

    return response.data
  }

  async getResellers(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/resellers`)

    return response.data.sort((a, b) =>
      a.DisplayName.localeCompare(b.DisplayName)
    )
  }

  async deleteReseller(id, resellerId) {
    const response = await axios.delete(
      `${this._baseUrl}/${id}/resellers/${resellerId}`
    )

    return response.data
  }

  async postReseller(id, userId) {
    const response = await axios.post(
      `${this._baseUrl}/${id}/resellers/userid/${userId}`
    )

    return response.data
  }

  async getProducers(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/producers`)

    return response.data.sort((a, b) => a.FullName.localeCompare(b.FullName))
  }

  async deleteProducer(id, userId) {
    const response = await axios.delete(
      `${this._baseUrl}/${id}/producer/${userId}`
    )

    return response.data
  }

  async postProducer(id, userId) {
    const response = await axios.post(
      `${this._baseUrl}/${id}/producer/${userId}`
    )

    return response.data
  }

  async getEvents(id) {
    const response = await axios.get(
      `${this._baseUrl}/${id}/events/invitations`
    )

    return response.data.sort((a, b) =>
      a.StartDateTime.localeCompare(b.StartDateTime)
    )
  }

  async getUrl(organizerId) {
    const response = await axios.get(
      `${this._baseUrl}/${organizerId}/mpauthorize`
    )

    return response.data
  }

  async getOrganizersPaged(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/me/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async getOrganizers() {
    const response = await axios.get(`${this._baseUrl}/me`)

    return response.data
  }

  async getMpInfo(id) {
    const response = await axios.get(`${this._baseUrl}/${id}/mp`)

    return response.data
  }

  async enableBilling(organizerId) {
    const response = await axios.put(
      `${this._baseUrl}/${organizerId}/billing/enable`
    )

    return response.data
  }

  async disableBilling(organizerId) {
    const response = await axios.put(
      `${this._baseUrl}/${organizerId}/billing/disable`
    )

    return response.data
  }

  async addBillingCredential(organizerId, payload) {
    const response = await axios.put(
      `${this._baseUrl}/${organizerId}/billing/credential`,
      payload
    )

    return response.data
  }

  async addBillingSettings(organizerId, payload) {
    const response = await axios.put(
      `${this._baseUrl}/${organizerId}/billing/settings`,
      payload
    )

    return response.data
  }
}

export default OrganizersProxy
