import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update } from '~/state/modules/organizers'
import { loadCommissions } from 'state/modules/mercadoPago'
import { PaperContainer } from '~/views/shared'
import { CircularProgress } from '@material-ui/core'
import { OrganizerForm } from '../components/OrganizerForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { loadOrganizer } from '~/state/modules/organizers'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const OrganizerUpdatePage = ({ onSuccess, match }) => {
  const { organizer, pending, pendingUpdate } = useSelector(
    (state) => state.organizers
  )
  const { commissions, pending: pendingCommissions } = useSelector(
    (state) => state.mercadoPago
  )

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(match.params.id))
      await dispatch(loadCommissions())
    }

    fetchData().then()
  }, [dispatch, match])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(update(values.Id, values))
      onSuccess()
      showSnackbarSuccess('Organizador Actualizado!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    Id: organizer?.Id,
    Name: organizer?.Name,
    Commission: organizer?.Commission,
    FacebookPixelId: organizer?.FacebookPixelId || '',
    TaxIdentificationNumber: organizer?.TaxIdentificationNumber || '',
    CustomerName: organizer?.CustomerName || '',
    CustomerType: organizer?.CustomerType || 0,
    Address: organizer?.Address || '',
    Phone: organizer?.Phone || '',
    Email: organizer?.Email || '',
    DefaultCommissionMp:
      organizer?.DefaultCommissionMp || commissions?.mpCommissions[0].Fee
  }

  return (
    <PaperContainer title='Edición de Organizador'>
      {pending || pendingCommissions ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <OrganizerForm
          values={values}
          mpCommissions={commissions?.mpCommissions}
          mpAutorized={organizer?.MercadoPagoAuthorized}
          mpUserId={organizer?.MercadoPagoUserId}
          mpExpirationDate={organizer?.MercadoPagoExpirationDate}
          actionText='Editar'
          handleSubmit={handleSubmit}
          actionPending={pendingUpdate}
          onCancel={() => history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)}
          AutomaticInvoiceEnabled={!!organizer?.AutomaticInvoiceEnabled}
        />
      )}
    </PaperContainer>
  )
}

export default OrganizerUpdatePage
