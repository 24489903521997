import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonQR: {
    fontSize: 'medium'
  },
  searchComponent: {
    display: 'flex'
  },
  filterComponentEvents: {
    minWidth: 270,
    width: '-webkit-fill-available'
  },
  filterComponentUsers: {
    minWidth: 400,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  filterComponentUsersForeign: {
    minWidth: 470,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  texts: {
    display: 'flex',
    marginLeft: 11
  },
  labelsResult: {
    fontWeight: 'bold'
  },
  events: {
    overflowY: 'scroll',
    maxHeight: '60vh'
  },
  totalsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingInline: 32,
    marginTop: 8
  },
  radioGroup: {
    width: 'max-content',
    flexDirection: 'row',
    marginTop: -16
  },
  radioGroupUser: {
    width: 'max-content',
    flexDirection: 'row',
    marginTop: -48
  }
}))

export default useStyles
