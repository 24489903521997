import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

export default useStyles
