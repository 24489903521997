export const PAGEDLIST_ATTEMPT = 'admin/dashboard/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/dashboard/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/dashboard/PAGEDLIST_FAILURE'

export const LOAD_TOTALIZATORS_ATTEMPT =
  'admin/dashboard/LOAD_TOTALIZATORS_ATTEMPT'
export const LOAD_TOTALIZATORS_SUCCESS =
  'admin/dashboard/LOAD_TOTALIZATORS_SUCCESS'
export const LOAD_TOTALIZATORS_FAILURE =
  'admin/dashboard/LOAD_TOTALIZATORS_FAILURE'

export const LOAD_TOTAL_TICKETS_ATTEMPT =
  'admin/dashboard/LOAD_TOTAL_TICKETS_ATTEMPT'
export const LOAD_TOTAL_TICKETS_SUCCESS =
  'admin/dashboard/LOAD_TOTAL_TICKETS_SUCCESS'
export const LOAD_TOTAL_TICKETS_FAILURE =
  'admin/dashboard/LOAD_TOTAL_TICKETS_FAILURE'

export const LOAD_INVITATIONS_DATA_ATTEMPT =
  'admin/dashboard/LOAD_INVITATIONS_DATA_ATTEMPT'
export const LOAD_INVITATIONS_DATA_SUCCESS =
  'admin/dashboard/LOAD_INVITATIONS_DATA_SUCCESS'
export const LOAD_INVITATIONS_DATA_FAILURE =
  'admin/dashboard/LOAD_INVITATIONS_DATA_FAILURE'

export const GET_INVITATIONS_REPORT_ATTEMPT =
  'admin/dashboard/GET_INVITATIONS_REPORT_ATTEMPT'
export const GET_INVITATIONS_REPORT_SUCCESS =
  'admin/dashboard/GET_INVITATIONS_REPORT_SUCCESS'
export const GET_INVITATIONS_REPORT_FAILURE =
  'admin/dashboard/GET_INVITATIONS_REPORT_FAILURE'

export const LOAD_EVENT_OPERATIONS_ATTEMPT =
  'admin/dashboard/LOAD_EVENT_OPERATIONS_ATTEMPT'
export const LOAD_EVENT_OPERATIONS_SUCCESS =
  'admin/dashboard/LOAD_EVENT_OPERATIONS_SUCCESS'
export const LOAD_EVENT_OPERATIONS_FAILURE =
  'admin/dashboard/LOAD_EVENT_OPERATIONS_FAILURE'

export const GET_EVENT_OPERATIONS_REPORT_ATTEMPT =
  'admin/dashboard/GET_EVENT_OPERATIONS_REPORT_ATTEMPT'
export const GET_EVENT_OPERATIONS_REPORT_SUCCESS =
  'admin/dashboard/GET_EVENT_OPERATIONS_REPORT_SUCCESS'
export const GET_EVENT_OPERATIONS_REPORT_FAILURE =
  'admin/dashboard/GET_EVENT_OPERATIONS_REPORT_FAILURE'

export const GET_TICKET_TYPES_REPORT_ATTEMPT =
  'admin/dashboard/GET_TICKET_TYPES_REPORT_ATTEMPT'
export const GET_TICKET_TYPES_REPORT_SUCCESS =
  'admin/dashboard/GET_TICKET_TYPES_REPORT_SUCCESS'
export const GET_TICKET_TYPES_REPORT_FAILURE =
  'admin/dashboard/GET_TICKET_TYPES_REPORT_FAILURE'

export const GET_TICKET_CODES_REPORT_ATTEMPT =
  'admin/dashboard/GET_TICKET_CODES_REPORT_ATTEMPT'
export const GET_TICKET_CODES_REPORT_SUCCESS =
  'admin/dashboard/GET_TICKET_CODES_REPORT_SUCCESS'
export const GET_TICKET_CODES_REPORT_FAILURE =
  'admin/dashboard/GET_TICKET_CODES_REPORT_FAILURE'

export const GET_OPERATIONS_PERCENTAGES_ATTEMPT =
  'admin/dashboard/GET_OPERATIONS_PERCENTAGES_ATTEMPT'
export const GET_OPERATIONS_PERCENTAGES_SUCCESS =
  'admin/dashboard/GET_OPERATIONS_PERCENTAGES_SUCCESS'
export const GET_OPERATIONS_PERCENTAGES_FAILURE =
  'admin/dashboard/GET_OPERATIONS_PERCENTAGES_FAILURE'

export const LOAD_TOTAL_SALES_PERCENTAGES_ATTEMPT =
  'admin/dashboard/LOAD_TOTAL_SALES_PERCENTAGES_ATTEMPT'
export const LOAD_TOTAL_SALES_PERCENTAGES_SUCCESS =
  'admin/dashboard/LOAD_TOTAL_SALES_PERCENTAGES_SUCCESS'
export const LOAD_TOTAL_SALES_PERCENTAGES_FAILURE =
  'admin/dashboard/LOAD_TOTAL_SALES_PERCENTAGES_FAILURE'

export const GET_RRPP_SALES_REPORT_ATTEMPT =
  'admin/dashboard/GET_RRPP_SALES_REPORT_ATTEMPT'
export const GET_RRPP_SALES_REPORT_SUCCESS =
  'admin/dashboard/GET_RRPP_SALES_REPORT_SUCCESS'
export const GET_RRPP_SALES_REPORT_FAILURE =
  'admin/dashboard/GET_RRPP_SALES_REPORT_FAILURE'

export const GET_RRPP_ONLINE_REPORT_ATTEMPT =
  'admin/dashboard/GET_RRPP_ONLINE_REPORT_ATTEMPT'
export const GET_RRPP_ONLINE_REPORT_SUCCESS =
  'admin/dashboard/GET_RRPP_ONLINE_REPORT_SUCCESS'
export const GET_RRPP_ONLINE_REPORT_FAILURE =
  'admin/dashboard/GET_RRPP_ONLINE_REPORT_FAILURE'

export const GET_ORDERS_REPORT_ATTEMPT =
  'admin/dashboard/GET_ORDERS_REPORT_ATTEMPT'
export const GET_ORDERS_REPORT_SUCCESS =
  'admin/dashboard/GET_ORDERS_REPORT_SUCCESS'
export const GET_ORDERS_REPORT_FAILURE =
  'admin/dashboard/GET_ORDERS_REPORT_FAILURE'

export const LOAD_CASH_SALES_ATTEMPT = 'admin/dashboard/LOAD_CASH_SALES_ATTEMPT'
export const LOAD_CASH_SALES_SUCCESS = 'admin/dashboard/LOAD_CASH_SALES_SUCCESS'
export const LOAD_CASH_SALES_FAILURE = 'admin/dashboard/LOAD_CASH_SALES_FAILURE'

export const LOAD_ONLINE_SALES_ATTEMPT =
  'admin/dashboard/LOAD_ONLINE_SALES_ATTEMPT'
export const LOAD_ONLINE_SALES_SUCCESS =
  'admin/dashboard/LOAD_ONLINE_SALES_SUCCESS'
export const LOAD_ONLINE_SALES_FAILURE =
  'admin/dashboard/LOAD_ONLINE_SALES_FAILURE'

export const LOAD_SERVICE_CHARGE_SALES_ATTEMPT =
  'admin/dashboard/LOAD_SERVICE_CHARGE_SALES_ATTEMPT'
export const LOAD_SERVICE_CHARGE_SALES_SUCCESS =
  'admin/dashboard/LOAD_SERVICE_CHARGE_SALES_SUCCESS'
export const LOAD_SERVICE_CHARGE_SALES_FAILURE =
  'admin/dashboard/LOAD_SERVICE_CHARGE_SALES_FAILURE'

export const RESET_DASHBOARD = 'admin/dashboard/RESET_DASHBOARD'

export const RESET_FILTERS = 'admin/dashboard/RESET_FILTERS'

export const RESET_PARAMS = 'admin/dashboard/RESET_PARAMS'

export const SET_FILTERS = 'admin/dashboard/SET_FILTERS'
