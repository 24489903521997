import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  containerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200
  },
  containerList: {
    width: '100%',
    marginLeft: 20
  },
  delete: {
    display: 'block',
    fontSize: 20,
    marginInline: 5,
    borderRadius: 100,
    backgroundColor: '#F2F3F4',
    '&:hover': {
      backgroundColor: '#EAEDED'
    }
  },
  labelContainer: {
    marginLeft: 20
  },
  labelSubtitle: {
    color: 'gray',
    marginTop: 5
  },
  separator: {
    backgroundColor: 'gainsboro',
    border: 0,
    height: 1,
    marginTop: theme.spacing(1)
  },
  title: {
    color: 'darkslategrey'
  }
}))

export default useStyles
