import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaperContainer } from '~views/shared'
import { loadTicketsUser, setFilters } from '~/state/modules/orders'
import { useStyles } from './UserTicketPage.style'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { ArrowBack } from '@material-ui/icons'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { UserTicketsFilter } from './UserTicketsFilter'
import * as moment from 'moment'

export const UserTicketsPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { ticketsUser, pending, filters } = useSelector((state) => state.orders)
  const { user } = useSelector((state) => state.users)

  const [searchValue, setSearchValue] = useState(filters.searchKey)
  const [eventGroups, setEventGroups] = useState([])
  const [filteredGroups, setFilteredGroups] = useState([])

  const filterResults = useCallback(
    (searchKey) => {
      if (searchKey != null && searchKey.trim() !== '') {
        setFilteredGroups(
          eventGroups.filter((e) => {
            return (
              e.eventName.toUpperCase().includes(searchKey.toUpperCase()) ||
              e.codes.find((c) => {
                return c.toUpperCase().includes(searchKey.toUpperCase())
              })
            )
          })
        )
      } else {
        setFilteredGroups(eventGroups)
      }
    },
    [eventGroups]
  )

  const performSearch = useCallback(
    (searchKey) => {
      setSearchValue(searchKey)
      dispatch(setFilters('', searchKey))
      filterResults(searchKey)
    },
    [dispatch, filterResults]
  )

  const handleBack = () => {
    history.push(
      ROUTES.ADMIN.USERS.VIEW.replace(':searchKey', match.params.userId)
    )
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadTicketsUser(match.params.userId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    const groupEvents = () =>
      ticketsUser == null
        ? {}
        : ticketsUser.reduce((g, c) => {
            ;(g[
              `${moment(c.EventDate).format('yyyy/MM/DD')} - ${c.EventName}`
            ] =
              g[
                `${moment(c.EventDate).format('yyyy/MM/DD')} - ${c.EventName}`
              ] || []).push(c)
            return g
          }, {})
    const groupedEvents = groupEvents()

    const results = []
    Object.keys(groupedEvents).forEach((e) => {
      const event = groupedEvents[e]
      const codes = []
      event.forEach((t) => {
        codes.push(t.ConfirmationCode)
      })
      results.push({
        eventName: e,
        tickets: event,
        codes
      })
    })
    setEventGroups(results)
    setFilteredGroups(results)
  }, [ticketsUser])

  useEffect(() => {
    const { searchKey } = filters
    filterResults(searchKey)
  }, [filters, filterResults])

  return (
    <PaperContainer
      title={
        <div className={classes.titleContainer}>
          <Typography variant='h6'>
            TICKETS POR USUARIO {match.params.eventId}
          </Typography>
          <Button
            onClick={handleBack}
            color='primary'
            startIcon={<ArrowBack />}>
            Volver
          </Button>
        </div>
      }>
      <div>
        <UserTicketsFilter
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder='Ingresar datos...'
          userId={match?.params?.userId}
        />
      </div>
      {pending ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {filteredGroups?.map((e, i) => (
            <div key={i}>
              <Typography className={classes.typographyTitle} variant='h5'>
                {e.eventName}
              </Typography>
              <div>
                {e.tickets.map((ticket, i) => (
                  <div
                    className={classes.containerCard}
                    key={i}
                    onClick={() => {
                      history.push(
                        ROUTES.ADMIN.USERS.TICKETS.DETAILS.replace(
                          ':userId',
                          user?.Id
                        ).replace(':orderId', ticket.OrderId)
                      )
                    }}>
                    <Typography variant='h6' className={classes.typography}>
                      {ticket.Type}
                    </Typography>
                    <div className={classes.containerDates}>
                      <Typography variant='h6'>
                        Código: &nbsp;
                        <Typography variant='h6' component='span'>
                          {ticket?.ConfirmationCode}
                        </Typography>
                        &nbsp;
                      </Typography>
                      <Typography variant='h6'>
                        Retirada: &nbsp; {ticket?.Withdrawn ? 'Si' : 'No'}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className={classes.actionsContainer}>
            <Button onClick={handleBack} color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default UserTicketsPage
