import React from 'react'
import {
  FormControl,
  InputAdornment,
  TextField,
  Input,
  FormHelperText,
  Select,
  MenuItem
} from '@material-ui/core'
import { useStyles } from '../EventTicketForm.style'
import { isNotNumber } from 'views/utils/functions'
import { COMMISSION_TYPE } from 'config/constants'

export const ComissionComponentCash = ({
  label,
  amountName,
  percentName,
  helperTextAmount,
  helperTextPercent,
  helperTextVat,
  handleBlur,
  commission,
  calculatePercentage,
  vatList,
  setFieldValue,
  values,
  error,
  calculateFields,
  isProducer = false,
  validateNaNValue,
  truncate,
  round
}) => {
  const classes = useStyles()

  return (
    <div className={classes.containerInputs}>
      <div>
        <TextField
          label={label}
          id='standard-start-adornment'
          style={{ width: 300 }}
          type='string'
          name={amountName}
          value={truncate(commission?.CommissionWithTaxAmount)}
          onBlur={handleBlur}
          disabled={commission.CommissionType === COMMISSION_TYPE.PLATFORM}
          onChange={(e) => {
            calculateFields(e.target.value, false, values, setFieldValue)
            setFieldValue(amountName, e.target.value)
          }}
          onKeyPress={(e) => isNotNumber(e)}
          onKeyDown={(e) => isNotNumber(e)}
          className={`${classes.fieldCommission} ${classes.fieldCommissionAmount}`}
          helperText={
            commission.CommissionType === COMMISSION_TYPE.PLATFORM
              ? `(${validateNaNValue(
                  round(calculatePercentage)
                )}%) (${validateNaNValue(
                  round(
                    commission?.Commission +
                      commission?.Commission * commission?.CommissionVat
                  )
                )}%)`
              : helperTextAmount
          }
          inputProps={{ maxLength: 8 }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          variant='standard'
        />
        <FormControl
          variant='standard'
          className={`${classes.formControl} ${classes.formControlCommission}`}>
          <Input
            name={percentName}
            value={validateNaNValue(round(commission?.Commission))}
            error={Boolean(error)}
            type='string'
            onChange={(e) => {
              calculateFields(e.target.value, true, values, setFieldValue)
              setFieldValue(
                percentName,
                validateNaNValue(parseFloat(e.target.value))
              )
            }}
            onBlur={handleBlur}
            disabled={
              commission.CommissionType === COMMISSION_TYPE.RESELLER ||
              isProducer
            }
            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
            className={`${classes.fieldCommission} ${classes.fieldCommissionPercent}`}
            aria-describedby='standard-weight-helper-text'
            onKeyPress={(e) => isNotNumber(e)}
            onKeyDown={(e) => isNotNumber(e)}
            inputProps={{
              'aria-label': 'weight',
              maxLength: 5
            }}
          />
          <FormHelperText
            id='standard-weight-helper-text'
            error={Boolean(error)}>
            {helperTextPercent}
          </FormHelperText>
        </FormControl>
        {helperTextVat && (
          <>
            <FormControl className={classes.commissionVat}>
              <Select
                name='CommissionVat'
                value={commission?.CommissionVat || ''}
                onChange={(e) => {
                  calculateFields(e.target.value, false, values, setFieldValue)
                }}
                onBlur={handleBlur}
                size='small'
                renderValue={(value) => `${value * 100}%`}>
                {vatList &&
                  !vatList.find((c) => c === commission?.CommissionVat) && (
                    <MenuItem value={commission?.CommissionVat}>
                      {commission?.CommissionVat * 100}% (IVA No Vigente)
                    </MenuItem>
                  )}
                {vatList &&
                  vatList.map((c, i) => (
                    <MenuItem key={i} value={c}>
                      {c * 100}%
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{helperTextVat}</FormHelperText>
            </FormControl>
          </>
        )}
      </div>
    </div>
  )
}

export default ComissionComponentCash
