import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 400
  },
  actionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    [theme.breakpoints.up('1500')]: {
      justifyContent: 'space-between'
    },
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing()
  },
  textDialogBold: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1)
  },
  textDialog: {
    fontStyle: 'italic'
  },
  separator: {
    marginBlock: -1,
    borderTop: 'groove',
    borderBottom: 'none'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      padding: 8
    },
    '& .MuiDialogContentText-root': {
      marginBottom: 16
    }
  }
}))

export default useStyles
