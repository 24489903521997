import React, { useState } from 'react'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

export const createMenuItem = (
  label,
  icon,
  onClick,
  show = true,
  disabled = false
) => ({
  label,
  icon,
  onClick,
  show,
  disabled
})

export const createMenuSeparator = (show = true) => ({
  separator: true,
  show
})

export const ActionsMenu = ({ items }) => {
  const [anchor, setAnchor] = useState(null)

  const handleIconClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleIconClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={handleClose}>
        {items?.map((item, index) =>
          item.show ? (
            item.separator ? (
              <hr key={index} />
            ) : (
              <MenuItem
                key={index}
                onClick={() => item.onClick && item.onClick()}
                disabled={item.disabled}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            )
          ) : null
        )}
      </Menu>
    </>
  )
}
