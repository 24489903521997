import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { PaperContainer, SearchComponent } from '~views/shared'
import { loadOrdersList, loadTotalTickets, reset } from '~/state/modules/orders'
import { loadEvent } from '~/state/modules/events'
import { useStyles } from './TicketOrdersIndexPage.style'
import { EventTicketOrder } from 'views/pages/EventsPage/components'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import moment from 'moment'

export const TicketOrdersIndexPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { ordersList, totalTickets, pending, totalWithdrawn, actionPending } =
    useSelector((state) => state.orders)
  const { event } = useSelector((state) => state.events)
  const { country } = useSelector((state) => state.app)
  const documentId = country === 'ar' ? 'DNI' : 'CI'
  const [searchValue, setSearchValue] = useState('')
  const [ordersFilter, setOrdersFilter] = useState([])

  const performSearch = async (searchKey) => {
    if (searchKey.length >= 3) {
      setSearchValue(searchKey)
      await dispatch(loadOrdersList(searchKey, match.params.eventId))
    }
  }

  const performSearchFilter = (searchKey) => {
    setSearchValue(searchKey)
    setOrdersFilter(
      ordersList?.filter(
        (order) =>
          order.FullName?.toUpperCase()
            .trim()
            .includes(searchKey.toUpperCase().trim()) ||
          order.DNI?.trim().startsWith(searchKey.trim()) ||
          order.Tickets.some((t) =>
            t.ConfirmationCode?.toUpperCase()
              .trim()
              .includes(searchKey.toUpperCase().trim())
          )
      )
    )
  }

  useEffect(() => {
    ordersList && setOrdersFilter(ordersList)
  }, [ordersList])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadTotalTickets(match.params.eventId))
    }
    fetchData().then()

    return () => {
      dispatch(reset())
    }
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  const onSuccess = async () => {
    await dispatch(loadTotalTickets(match.params.eventId))
    dispatch(loadOrdersList(searchValue, match.params.eventId))
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='TICKETS'>
        <SearchComponent
          performSearch={performSearch}
          searchValue={searchValue}
          placeholder={`Ingresar código, ${documentId} ó Nombre`}
          pending={pending}
          performSearchFilter={performSearchFilter}
        />
        <div className={classes.tickets}>
          <Typography>
            Tickets: {totalTickets} ({totalWithdrawn}) &nbsp;
            {moment().subtract(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}
          </Typography>
        </div>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            {ordersFilter?.map((order, i) => (
              <EventTicketOrder
                order={order}
                onSuccess={onSuccess}
                id={match.params.eventId}
                pending={actionPending}
                documentId={documentId}
                key={i}
              />
            ))}

            <div className={classes.actionsContainer}>
              <Button
                onClick={() => history.push(ROUTES.ADMIN.EVENTS.INDEX)}
                color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default TicketOrdersIndexPage
