import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { Button, CircularProgress } from '@material-ui/core'
import { Facebook as FacebookIcon } from '@material-ui/icons'
import FacebookLogin from 'react-facebook-login'
import { useStyles } from './Facebook.style'
import { loginFacebook } from '~/state/modules/login'
import { AccountCreationDialog } from './AccountCreationDialog'

export const Facebook = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { newExternalUser, pending } = useSelector((state) => state.login)
  const [loadLoginFb, setLoadLoginFb] = useState(false)
  const [showAccountCreationDialog, setShowAccountCreationDialog] =
    useState(false)

  const handleFacebookButtonClick = () => {
    if (window.FB) {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          window.FB.logout(() => {
            loginWithFacebook()
          })
        } else {
          loginWithFacebook()
        }
      })
    } else {
      setLoadLoginFb(false)
    }
  }

  const loginWithFacebook = () => {
    window.FB.login((response) => {
      if (response.status === 'connected') {
        setLoadLoginFb(true)
      } else {
        setLoadLoginFb(false)
      }
    })
  }

  const responseFacebook = async (response) => {
    if (!response || !response.accessToken) {
      console.error('No se pudo obtener un token de acceso de Facebook.')
      setLoadLoginFb(false)
      return
    }
    try {
      const resp = await dispatch(
        loginFacebook(response.accessToken, response.picture?.data.url)
      )
      if (resp.status === 203) {
        setShowAccountCreationDialog(true)
      } else {
        history.push(ROUTES.ADMIN.EVENTS.INDEX)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadLoginFb(false)
    }
  }

  return (
    <>
      <Button
        className={classes.buttonFacebook}
        variant='contained'
        type='submit'
        disabled={loadLoginFb}
        onClick={handleFacebookButtonClick}>
        {loadLoginFb ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <FacebookIcon className={classes.facebookIcon} />
            <span>Continuar con Facebook</span>
          </>
        )}
      </Button>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={loadLoginFb}
        fields='name,email,picture'
        cssClass={classes.facebookDialog}
        callback={responseFacebook}
        icon={<FacebookIcon className={classes.facebookIcon} />}
      />
      <AccountCreationDialog
        open={showAccountCreationDialog}
        onRegistrationSuccess={() => {
          setShowAccountCreationDialog(false)
          history.push(ROUTES.ADMIN.EVENTS.INDEX)
        }}
        onCancel={() => {
          setShowAccountCreationDialog(false)
          setLoadLoginFb(false)
        }}
        newExternalUser={newExternalUser}
        pending={pending}
      />
    </>
  )
}

export default Facebook
