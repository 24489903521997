import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: 300,
    minWidth: 300
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  containerTable: {
    padding: 5
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    fontWeight: 'normal'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '45rem'
    }
  },
  jsonPretty: {
    display: 'flex',
    width: 'fit-content'
  },
  button: {
    textTransform: 'none',
    marginBlock: -10
  },
  textButton: {
    display: 'flex',
    margin: 8
  },
  textDetails: {
    whiteSpace: 'break-spaces',
    minWidth: 100
  }
}))

export default useStyles
