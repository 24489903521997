import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import { MoreVert, Edit } from '@material-ui/icons'
import { updateCommission } from '~/state/modules/accounts'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { CommissionFormDialog } from 'views/pages/OrganizersPage/AccountsPage/CommissionFormDialog'

export const MacroActionMenu = ({
  macroId,
  commission,
  vat,
  changeCommissions
}) => {
  const dispatch = useDispatch()
  const { pendingUpdate } = useSelector((state) => state.accounts)
  const [anchorEl, setAnchorEl] = useState(null)
  const [account, setAccount] = useState()
  const [open, setOpen] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpdateCommission = async (updatedAccount) => {
    try {
      const { Commission = 0, Vat = 0 } = updatedAccount

      const updatedValues = {
        ...updatedAccount,
        Commission: Commission / 100,
        Vat: Vat / 100
      }

      await dispatch(updateCommission(updatedValues))
      showSnackbarSuccess('Se ha actualizado la comisión correctamente!')
      changeCommissions(updatedValues.Commission, updatedValues.Vat)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            setAccount({
              Id: macroId,
              Vat: vat,
              Commission: commission
            })
            setOpen(true)
          }}
          disabled={pendingUpdate}>
          <ListItemIcon style={{ marginRight: -20 }}>
            <Edit fontSize='small' />
          </ListItemIcon>
          Editar Comisión
        </MenuItem>
      </Menu>
      <CommissionFormDialog
        open={open}
        loading={pendingUpdate}
        handleSubmit={handleUpdateCommission}
        onCancel={() => setOpen(false)}
        values={account}
      />
    </>
  )
}

export default MacroActionMenu
