import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { loadEvent } from '~/state/modules/events'
import { useStyles } from './TicketsPerOrderPage.style'
import { loadTicketPerOrderPage } from '~/state/modules/orders'
import { useHistory } from 'react-router-dom'

export const TicketsPerOrderPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tickets, setTickets] = useState([])
  const { orderEvent, pending } = useSelector((state) => state.orders)
  const { event } = useSelector((state) => state.events)
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      const { orderId } = match.params
      await dispatch(loadTicketPerOrderPage(orderId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    if (orderEvent) {
      setTickets(Object.values(orderEvent))
    }
  }, [orderEvent])

  const createRow = (label, value, formatter) => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {formatter ? formatter(value) : value}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}{' '}
        </Typography>
      )}
      <PaperContainer title='Tickets por Orden'>
        {pending ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            {tickets &&
              tickets.map((o, i) => (
                <div key={i}>
                  <Table>
                    <TableBody>
                      {createRow('Ticket', o?.Type)}
                      {createRow('Nombre', `${o?.FullName} (${o?.DNI})`)}
                      {createRow('Codigo', o?.ConfirmationCode)}
                      {createRow('Retirada', o?.Withdrawn ? 'Si' : 'No')}
                      {createRow(
                        'Cancelado',
                        o?.Cancelled ? 'Si' : 'No',
                        (value) => (
                          <span
                            style={{
                              color: value === 'Si' ? 'darkred' : 'darkgreen'
                            }}>
                            {value}
                          </span>
                        )
                      )}
                    </TableBody>
                  </Table>
                  <br />
                </div>
              ))}
            <div className={classes.actionsContainer}>
              <Button onClick={() => history.goBack()} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default TicketsPerOrderPage
