import React from 'react'
import { useStyles } from './UsersList.style'
import { Typography } from '@material-ui/core'

export const UsersList = ({ onSuccess, user }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div onClick={() => onSuccess(user)} className={classes.containerList}>
        <div className={classes.nameStyle}>
          <Typography variant='h5' className={classes.title}>
            {user?.FullName} &nbsp;&nbsp;({user?.Email})
          </Typography>
        </div>
        <div className={classes.nameStyle}>
          <Typography variant='h6' color='primary' component='span'>
            {user?.Id}
          </Typography>
        </div>
        <hr className={classes.separator} />
      </div>
    </div>
  )
}

export default UsersList
