import * as types from './types'

const dashboardState = {
  totalizators: null,
  salesReport: null,
  pendingRRPPSalesReport: false,
  operationsPercentages: null,
  cashSales: null,
  onlineSales: null,
  serviceChargeSales: null,
  totalTickets: null,
  operations: null,
  invitationsData: null,
  operationsReport: null,
  totalSalesPercentages: null,
  invitationsReport: null,
  ticketTypesReport: null,
  ordersReport: null,
  pendingOrdersReport: false,
  pendingCashSales: false,
  pendingOnlineSales: false,
  pendingServiceChargeSales: false,
  pendingTicketTypesReport: false,
  pendingTotalizators: false,
  pendingTotalTickets: false,
  pendingInvitations: false,
  pendingOperations: false,
  pendingOperationsReport: false,
  pendingOperationsPercentages: false,
  pendingTotalSalesPercentages: false,
  pendingTicketCodesReport: false,
  pendingInvitationsReport: false,
  pendingRRPPOnlineReport: false
}

const initialState = {
  pagedList: null,
  reqParams: null,
  pending: false,
  error: null,
  filters: { searchKey: '', fetchUpcomingEvents: true },
  ...dashboardState
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pagedList: null,
        reqParams: action.reqParams,
        pending: true,
        error: null
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pagedList: action.pagedList,
        pending: false,
        error: null
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pagedList: null,
        pending: false,
        error: action.error
      }

    case types.LOAD_TOTALIZATORS_ATTEMPT:
      return {
        ...state,
        totalizators: null,
        pendingTotalizators: true,
        error: null
      }
    case types.LOAD_TOTALIZATORS_SUCCESS:
      return {
        ...state,
        totalizators: action.totalizators,
        pendingTotalizators: false,
        error: null
      }
    case types.LOAD_TOTALIZATORS_FAILURE:
      return {
        ...state,
        totalizators: null,
        pendingTotalizators: false,
        error: action.error
      }
    case types.LOAD_TOTAL_TICKETS_ATTEMPT:
      return {
        ...state,
        totalTickets: null,
        pendingTotalTickets: true,
        error: null
      }
    case types.LOAD_TOTAL_TICKETS_SUCCESS:
      return {
        ...state,
        totalTickets: action.totalTickets,
        pendingTotalTickets: false,
        error: null
      }
    case types.LOAD_TOTAL_TICKETS_FAILURE:
      return {
        ...state,
        totalTickets: null,
        pendingTotalTickets: false,
        error: action.error
      }
    case types.LOAD_INVITATIONS_DATA_ATTEMPT:
      return {
        ...state,
        invitationsData: null,
        pendingInvitations: true,
        error: null
      }
    case types.LOAD_INVITATIONS_DATA_SUCCESS:
      return {
        ...state,
        invitationsData: action.invitationsData,
        pendingInvitations: false,
        error: null
      }
    case types.LOAD_INVITATIONS_DATA_FAILURE:
      return {
        ...state,
        invitationsData: null,
        pendingInvitations: false,
        error: action.error
      }
    case types.LOAD_EVENT_OPERATIONS_ATTEMPT:
      return {
        ...state,
        operations: null,
        pendingOperations: true,
        error: null
      }
    case types.LOAD_EVENT_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: action.operations,
        pendingOperations: false,
        error: null
      }
    case types.LOAD_EVENT_OPERATIONS_FAILURE:
      return {
        ...state,
        operations: null,
        pendingOperations: false,
        error: action.error
      }
    case types.GET_EVENT_OPERATIONS_REPORT_ATTEMPT:
      return {
        ...state,
        operationsReport: null,
        pendingOperationsReport: true,
        error: null
      }
    case types.GET_EVENT_OPERATIONS_REPORT_SUCCESS:
      return {
        ...state,
        operationsReport: action.report,
        pendingOperationsReport: false,
        error: null
      }
    case types.GET_EVENT_OPERATIONS_REPORT_FAILURE:
      return {
        ...state,
        operationsReport: null,
        pendingOperationsReport: false,
        error: action.error
      }
    case types.GET_OPERATIONS_PERCENTAGES_ATTEMPT:
      return {
        ...state,
        operationsPercentages: null,
        pendingOperationsPercentages: true,
        error: null
      }
    case types.GET_OPERATIONS_PERCENTAGES_SUCCESS:
      return {
        ...state,
        operationsPercentages: action.percentages,
        pendingOperationsPercentages: false,
        error: null
      }
    case types.GET_OPERATIONS_PERCENTAGES_FAILURE:
      return {
        ...state,
        operationsPercentages: null,
        pendingOperationsPercentages: false,
        error: action.error
      }
    case types.LOAD_TOTAL_SALES_PERCENTAGES_ATTEMPT:
      return {
        ...state,
        totalSalesPercentages: null,
        pendingTotalSalesPercentages: true,
        error: null
      }
    case types.LOAD_TOTAL_SALES_PERCENTAGES_SUCCESS:
      return {
        ...state,
        totalSalesPercentages: action.percentages,
        pendingTotalSalesPercentages: false,
        error: null
      }
    case types.LOAD_TOTAL_SALES_PERCENTAGES_FAILURE:
      return {
        ...state,
        totalSalesPercentages: null,
        pendingTotalSalesPercentages: false,
        error: action.error
      }
    case types.LOAD_CASH_SALES_ATTEMPT:
      return {
        ...state,
        cashSales: null,
        pendingCashSales: true,
        error: null
      }
    case types.LOAD_CASH_SALES_SUCCESS:
      return {
        ...state,
        cashSales: action.sales,
        pendingCashSales: false,
        error: null
      }
    case types.LOAD_CASH_SALES_FAILURE:
      return {
        ...state,
        cashSales: null,
        pendingCashSales: false,
        error: action.error
      }
    case types.LOAD_ONLINE_SALES_ATTEMPT:
      return {
        ...state,
        onlineSales: null,
        pendingOnlineSales: true,
        error: null
      }
    case types.LOAD_ONLINE_SALES_SUCCESS:
      return {
        ...state,
        onlineSales: action.sales,
        pendingOnlineSales: false,
        error: null
      }
    case types.LOAD_ONLINE_SALES_FAILURE:
      return {
        ...state,
        onlineSales: null,
        pendingOnlineSales: false,
        error: action.error
      }
    case types.LOAD_SERVICE_CHARGE_SALES_ATTEMPT:
      return {
        ...state,
        serviceChargeSales: null,
        pendingServiceChargeSales: true,
        error: null
      }
    case types.LOAD_SERVICE_CHARGE_SALES_SUCCESS:
      return {
        ...state,
        serviceChargeSales: action.sales,
        pendingServiceChargeSales: false,
        error: null
      }
    case types.LOAD_SERVICE_CHARGE_SALES_FAILURE:
      return {
        ...state,
        serviceChargeSales: null,
        pendingServiceChargeSales: false,
        error: action.error
      }
    case types.GET_INVITATIONS_REPORT_ATTEMPT:
      return {
        ...state,
        invitationsReport: null,
        pendingInvitationsReport: true,
        error: null
      }
    case types.GET_INVITATIONS_REPORT_SUCCESS:
      return {
        ...state,
        invitationsReport: action.report,
        pendingInvitationsReport: false,
        error: null
      }
    case types.GET_INVITATIONS_REPORT_FAILURE:
      return {
        ...state,
        invitationsReport: null,
        pendingInvitationsReport: false,
        error: action.error
      }
    case types.GET_RRPP_SALES_REPORT_ATTEMPT:
      return {
        ...state,
        salesReport: null,
        pendingRRPPSalesReport: true,
        error: null
      }
    case types.GET_RRPP_SALES_REPORT_SUCCESS:
      return {
        ...state,
        salesReport: action.report,
        pendingRRPPSalesReport: false,
        error: null
      }
    case types.GET_RRPP_SALES_REPORT_FAILURE:
      return {
        ...state,
        salesReport: null,
        pendingRRPPSalesReport: false,
        error: action.error
      }
    case types.GET_RRPP_ONLINE_REPORT_ATTEMPT:
      return {
        ...state,
        onlineReport: null,
        pendingRRPPOnlineReport: true,
        error: null
      }
    case types.GET_RRPP_ONLINE_REPORT_SUCCESS:
      return {
        ...state,
        onlineReport: action.report,
        pendingRRPPOnlineReport: false,
        error: null
      }
    case types.GET_RRPP_ONLINE_REPORT_FAILURE:
      return {
        ...state,
        onlineReport: null,
        pendingRRPPOnlineReport: false,
        error: action.error
      }
    case types.GET_TICKET_TYPES_REPORT_ATTEMPT:
      return {
        ...state,
        ticketTypesReport: null,
        pendingTicketTypesReport: true,
        error: null
      }
    case types.GET_TICKET_TYPES_REPORT_SUCCESS:
      return {
        ...state,
        ticketTypesReport: action.report,
        pendingTicketTypesReport: false,
        error: null
      }
    case types.GET_TICKET_TYPES_REPORT_FAILURE:
      return {
        ...state,
        ticketTypesReport: null,
        pendingTicketTypesReport: false,
        error: action.error
      }
    case types.GET_TICKET_CODES_REPORT_ATTEMPT:
      return {
        ...state,
        ticketCodesReport: null,
        pendingTicketCodesReport: true,
        error: null
      }
    case types.GET_TICKET_CODES_REPORT_SUCCESS:
      return {
        ...state,
        ticketCodesReport: action.report,
        pendingTicketCodesReport: false,
        error: null
      }
    case types.GET_TICKET_CODES_REPORT_FAILURE:
      return {
        ...state,
        ticketCodesReport: null,
        pendingTicketCodesReport: false,
        error: action.error
      }
    case types.GET_ORDERS_REPORT_ATTEMPT:
      return {
        ...state,
        ordersReport: null,
        pendingOrdersReport: true,
        error: null
      }
    case types.GET_ORDERS_REPORT_SUCCESS:
      return {
        ...state,
        ordersReport: action.ordersReport,
        pendingOrdersReport: false,
        error: null
      }
    case types.GET_ORDERS_REPORT_FAILURE:
      return {
        ...state,
        ordersReport: null,
        pendingOrdersReport: false,
        error: action.error
      }
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      }
    case types.RESET_DASHBOARD: {
      return {
        ...state,
        ...dashboardState,
        error: null
      }
    }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    case types.RESET_FILTERS: {
      return {
        ...state,
        filters: { searchKey: '', fetchUpcomingEvents: true }
      }
    }
    default:
      return state
  }
}
