import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  useTheme,
  colors,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Box
} from '@material-ui/core'
import useStyles from './StackedBarChartWidget.style'

const colorArray = [
  colors.indigo[800],
  colors.yellow[800],
  colors.cyan[500],
  colors.green[300],
  colors.blue[800],
  colors.pink[300],
  colors.red[800],
  colors.amber[800],
  colors.brown[400],
  colors.yellow[600],
  colors.lime[400],
  colors.green[600]
]

export const StackedBarChartWidget = ({
  title,
  dataset,
  invitationsData,
  eventId,
  pending,
  labels,
  ...props
}) => {
  const theme = useTheme()
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
      tooltipEl.style.borderRadius = '3px'
      tooltipEl.style.color = 'white'
      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, 0)'
      tooltipEl.style.transition = 'all .1s ease'

      const table = document.createElement('table')
      table.style.margin = '0px'

      tooltipEl.appendChild(table)
      chart.canvas.parentNode.appendChild(tooltipEl)
    }
    return tooltipEl
  }

  const [options] = useState({
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: {},
    maintainAspectRatio: false,
    responsive: true,
    offsetGridLines: true,
    drawTicks: true,
    offset: true,
    scales: {
      x: {
        stacked: true,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          padding: 5,
          fontColor: theme.palette.text.secondary,
          stepSize: 1,
          callback: function (value, index, values) {
            if (isValidDate(this.getLabelForValue(value).replace(/ /g, ''))) {
              return this.getLabelForValue(value)
            }
          }
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          stepSize: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: false,
          generateLabels: (chart) => {
            const { data } = chart
            if (data.labels.length && data.datasets.length) {
              const { datasets } = data
              return datasets.map((d, i) => {
                const totalValue = d.data.reduce(
                  (total, dataset) => dataset + total,
                  0
                )
                const fill = colorArray[i]

                return {
                  index: i,
                  text: `${d.label} (${totalValue})`,
                  fillStyle: fill,
                  strokeStyle: fill,
                  hidden:
                    isNaN(datasets[0].data[i]) ||
                    chart.getDatasetMeta(0).data[i].hidden
                }
              })
            }
            return []
          }
        }
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || ''
            if (context.parsed.y === 0) {
              return null
            } else {
              return `${label}: ${context.parsed.y}`
            }
          }
        },
        external: (context) => {
          // Tooltip Element
          const { chart, tooltip } = context
          const tooltipEl = getOrCreateTooltip(chart)

          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set Text
          if (tooltip.body) {
            const titleLines = tooltip.title || []
            const bodyLines = tooltip.body
              .map((b) => b.lines)
              .reverse()
              .filter((b) => b.length > 0)

            const tableHead = document.createElement('thead')

            titleLines.forEach((title) => {
              const tr = document.createElement('tr')
              tr.style.borderWidth = 0

              const th = document.createElement('th')
              th.style.borderWidth = 0
              const text = document.createTextNode(title)

              th.appendChild(text)
              tr.appendChild(th)
              tableHead.appendChild(tr)
            })

            const tableBody = document.createElement('tbody')

            const newArrayColor = tooltip.dataPoints
              .filter((d) => d.formattedValue !== '0')
              .map((d) => d.dataset)
              .reverse()

            bodyLines.forEach((body, i) => {
              const colors = newArrayColor[i]
              const span = document.createElement('span')
              span.style.background = colors.backgroundColor
              span.style.borderWidth = '2px'
              span.style.marginRight = '10px'
              span.style.height = '10px'
              span.style.width = '10px'
              span.style.display = 'inline-block'

              const tr = document.createElement('tr')
              tr.style.backgroundColor = 'inherit'
              tr.style.borderWidth = 0

              const td = document.createElement('td')
              td.style.borderWidth = 0
              td.style.whiteSpace = 'nowrap'

              const text = document.createTextNode(body)

              td.appendChild(span)
              td.appendChild(text)
              tr.appendChild(td)
              tableBody.appendChild(tr)
            })

            const tableRoot = tooltipEl.querySelector('table')

            // Remove old children
            while (tableRoot.firstChild) {
              tableRoot.firstChild.remove()
            }

            // Add new children
            tableRoot.appendChild(tableHead)
            tableRoot.appendChild(tableBody)
          }

          const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
          const itemsCount = tooltip.dataPoints.filter(
            (d) => d.formattedValue !== '0'
          ).length
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          if (tooltip.xAlign === 'left') {
            tooltipEl.style.left = positionX + tooltip.caretX + 150 + 'px'
          } else {
            tooltipEl.style.left = positionX + tooltip.caretX + -150 + 'px'
          }
          if (itemsCount > 5) {
            tooltipEl.style.top = positionY + tooltip.caretY + -150 + 'px'
          } else {
            tooltipEl.style.top = positionY + tooltip.caretY + -50 + 'px'
          }
          tooltipEl.style.font = tooltip.options.bodyFont.string
          tooltipEl.style.padding =
            tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
        }
      }
    }
  })
  const classes = useStyles()
  // La propiedad `datasets` es requerida
  const [data, setData] = useState({ datasets: [] })
  const [xLabels, setXlabels] = useState()

  const isValidDate = (date) => {
    //format: dd/MM/yyyy
    let pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/
    if (date == null || date === '' || !pattern.test(date.replace(/ /g, ''))) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (dataset != null) {
      const labels = []
      const datasets = []
      const groupNames = []
      const dataGroups = []

      dataset.forEach((d) => {
        const { label, values: datasetValues } = d
        labels.push(label)
        datasetValues.forEach((v) => {
          if (!groupNames.includes(v.label)) {
            groupNames.push(v.label)
          }
        })
      })
      setXlabels(invitationsData)

      groupNames.forEach((n) => {
        const values = []

        dataset.forEach((d) => {
          const { values: groupValues } = d
          const group = groupValues.find((g) => g.label === n)

          values.push(group?.value || 0)
        })

        dataGroups.push({
          label: n,
          values
        })
      })

      dataGroups.forEach((d, i) => {
        datasets.push({
          backgroundColor: colorArray[i],
          categoryPercentage: 1,
          barThickness: 120,
          stack: 'stack',
          label: d.label,
          data: d.values
        })
      })

      setData({
        datasets,
        labels: labels
      })
    }
  }, [dataset, invitationsData])

  const redirectDetailsPage = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Card style={{ height: 350 }} {...props}>
      <CardHeader title={title} />
      <Divider />
      <CardContent
        style={{
          height: '100%'
        }}>
        <div>
          <Box
            style={{
              height: 250
            }}
            sx={{
              position: 'relative'
            }}>
            <Bar pointstyle='star' data={data} options={options} />
          </Box>
          <div className={classes.labels}>
            {xLabels &&
              xLabels?.Details.map(
                (l) =>
                  !isValidDate(l.CreatedByName) && (
                    <Typography
                      variant='subtitle2'
                      key={l.CreatedByName}
                      onClick={() => {
                        sessionStorage.setItem(
                          'invitationsDetails',
                          JSON.stringify(l)
                        )
                        redirectDetailsPage(
                          `${window.location.href}/${l.CreatedById}`
                        )
                      }}
                      className={classes.XAxisLabel}>
                      {l.CreatedByName}
                    </Typography>
                  )
              )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default StackedBarChartWidget
