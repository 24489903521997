import * as types from './types'
import { NotificationsProxy } from '~/services'

export const sendNotificationAttempt = () => ({
  type: types.SEND_NOTIFICATION_ATTEMPT
})

export const sendNotificationSuccess = (notification) => ({
  type: types.SEND_NOTIFICATION_SUCCESS,
  notification
})

export const sendNotificationFailure = (error) => ({
  type: types.SEND_NOTIFICATION_FAILURE,
  error
})

export const sendNotificationToUserAttempt = () => ({
  type: types.SEND_NOTIFICATION_TO_USER_ATTEMPT
})

export const sendNotificationToUserSuccess = (response) => ({
  type: types.SEND_NOTIFICATION_TO_USER_SUCCESS,
  response
})

export const sendNotificationToUserFailure = (error) => ({
  type: types.SEND_NOTIFICATION_TO_USER_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const sendToAll = (notification) => async (dispatch) => {
  try {
    dispatch(sendNotificationAttempt())

    const proxy = new NotificationsProxy()
    const notifications = await proxy.sendToAll(notification)
    dispatch(sendNotificationSuccess(notifications))
  } catch (error) {
    dispatch(sendNotificationFailure(error))
    throw error
  }
}

export const sendByEvent =
  (eventId, paymentStatus, notification) => async (dispatch) => {
    try {
      dispatch(sendNotificationAttempt())

      const proxy = new NotificationsProxy()
      const notifications = await proxy.sendByEvent(
        eventId,
        paymentStatus,
        notification
      )
      dispatch(sendNotificationSuccess(notifications))
    } catch (error) {
      dispatch(sendNotificationFailure(error))
      throw error
    }
  }

export const sendByEventByTicket =
  (eventId, paymentStatus, ticketId, notification) => async (dispatch) => {
    try {
      dispatch(sendNotificationAttempt())

      const proxy = new NotificationsProxy()
      const notifications = await proxy.sendByEventByTicket(
        eventId,
        paymentStatus,
        ticketId,
        notification
      )
      dispatch(sendNotificationSuccess(notifications))
    } catch (error) {
      dispatch(sendNotificationFailure(error))
      throw error
    }
  }

export const sendNotificationToMaillistCustom =
  (payload) => async (dispatch) => {
    try {
      dispatch(sendNotificationAttempt())

      const proxy = new NotificationsProxy()
      const notifications =
        await proxy.sendNotificationToMaillistCustom(payload)
      dispatch(sendNotificationSuccess(notifications))
    } catch (error) {
      dispatch(sendNotificationFailure(error))
      throw error
    }
  }

export const sendNotificationToMaillistEvents =
  (payload) => async (dispatch) => {
    try {
      dispatch(sendNotificationAttempt())

      const proxy = new NotificationsProxy()
      const notifications =
        await proxy.sendNotificationToMaillistEvents(payload)
      dispatch(sendNotificationSuccess(notifications))
    } catch (error) {
      dispatch(sendNotificationFailure(error))
      throw error
    }
  }

export const sendNotificationToMaillistEveryone =
  (payload) => async (dispatch) => {
    try {
      dispatch(sendNotificationAttempt())

      const proxy = new NotificationsProxy()
      const notifications =
        await proxy.sendNotificationToMaillistEveryone(payload)
      dispatch(sendNotificationSuccess(notifications))
    } catch (error) {
      dispatch(sendNotificationFailure(error))
      throw error
    }
  }

export const sendNotificationToMaillistProducers =
  (payload) => async (dispatch) => {
    try {
      dispatch(sendNotificationAttempt())

      const proxy = new NotificationsProxy()
      const notifications =
        await proxy.sendNotificationToMaillistProducers(payload)
      dispatch(sendNotificationSuccess(notifications))
    } catch (error) {
      dispatch(sendNotificationFailure(error))
      throw error
    }
  }

export const sendNotificationToUser =
  (userName, notification) => async (dispatch) => {
    try {
      dispatch(sendNotificationToUserAttempt())

      const proxy = new NotificationsProxy()
      const response = await proxy.sendNotificationToUser(
        userName,
        notification
      )
      dispatch(sendNotificationToUserSuccess(response))
    } catch (error) {
      dispatch(sendNotificationToUserFailure(error))
      throw error
    }
  }
