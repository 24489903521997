import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core'
import {
  AccountBalance,
  MoreVert,
  MonetizationOn,
  ConfirmationNumber,
  NotInterested,
  CheckCircleOutline,
  InsertInvitation,
  Send,
  Wc,
  SupervisorAccount
} from '@material-ui/icons'
import history from '~/config/history'
import {
  loadUrl,
  enableBilling,
  disableBilling
} from 'state/modules/organizers'
import { ConfirmationDialog } from '~/views/shared'
import { ROUTES } from '~/views/routes'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import copy from 'copy-to-clipboard'

export const OrganizerActionsMenu = ({ organizer, refreshItems }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [message, setMessage] = useState('')
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const { generatedUrl, pendingTogglingBilling } = useSelector(
    (state) => state.organizers
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(loadUrl(organizer.Id))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const copyToClipBoard = async () => {
    try {
      await copy(generatedUrl)
      showSnackbarSuccess(
        'La URL se copió en el portapapeles. ',
        'Autorización creada con éxito!'
      )
    } catch (err) {
      console.log(err)
      showSnackbarError(err)
    }
  }

  const handleTogglingBilling = async () => {
    try {
      if (organizer?.AutomaticInvoiceEnabled) {
        await dispatch(disableBilling(organizer.Id))
        showSnackbarSuccess('¡Se Deshabilitó la Facturación Automática!')
      } else {
        await dispatch(enableBilling(organizer.Id))
        showSnackbarSuccess('¡Se Habilitó la Facturación Automática!')
      }
      refreshItems()
    } catch (err) {
      console.error(err)
      showSnackbarError(err, 7500)
    } finally {
      setOpenConfirmation(false)
    }
  }

  const handleOpenConfirmation = async (isEnable) => {
    handleClose()
    if (isEnable) {
      setMessage('Está por deshabilitar la facturación automática.')
    } else {
      setMessage('Está por habilitar la facturación automática.')
    }
    setOpenConfirmation(true)
  }

  return (
    <div>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.ACCOUNTS.replace(
                ':organizerId',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <AccountBalance fontSize='small' />
          </ListItemIcon>
          Mis Cuentas
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.PRODUCERS.INDEX.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <SupervisorAccount fontSize='small' />
          </ListItemIcon>
          Mis Usuarios Administradores
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.RESELLERS.INDEX.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <Wc fontSize='small' />
          </ListItemIcon>
          Mis RRPP
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.CONTROLLERS.INDEX.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <ConfirmationNumber fontSize='small' />
          </ListItemIcon>
          Mis Controladores de Ingreso
        </MenuItem>
        <hr />
        <MenuItem onClick={() => copyToClipBoard()}>
          <ListItemIcon>
            <Send fontSize='small' />
          </ListItemIcon>
          Enviar Autorización
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.INVITATIONS.replace(':id', organizer.Id)
            )
          }>
          <ListItemIcon>
            <InsertInvitation fontSize='small' />
          </ListItemIcon>
          Enviar Invitaciones
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() =>
            history.push(
              ROUTES.ADMIN.ORGANIZERS.BILLING.CREDENTIALS.replace(
                ':id',
                organizer.Id
              )
            )
          }>
          <ListItemIcon>
            <MonetizationOn fontSize='small' />
          </ListItemIcon>
          Facturación
        </MenuItem>
        {organizer?.AutomaticInvoiceEnabled ? (
          <MenuItem
            onClick={() => handleOpenConfirmation(true)}
            disabled={pendingTogglingBilling}>
            <ListItemIcon>
              <NotInterested fontSize='small' />
            </ListItemIcon>
            Deshabilitar Facturación automática
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => handleOpenConfirmation(false)}
            disabled={pendingTogglingBilling}>
            <ListItemIcon>
              <CheckCircleOutline fontSize='small' />
            </ListItemIcon>
            Habilitar Facturación automática
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        open={openConfirmation}
        loading={pendingTogglingBilling}
        handleConfirmation={handleTogglingBilling}
        onCancel={() => setOpenConfirmation(false)}
        title={'ADVERTENCIA!'}
        message={message}
      />
    </div>
  )
}

export default OrganizerActionsMenu
