import { Typography } from '@material-ui/core'
import { TagSection } from 'views/shared'
import { useStyles } from './PrivacyPage.style'

export const PrivacyPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant='h3' className={classes.title}>
        Políticas de privacidad
      </Typography>
      <br />
      <TagSection
        title={'1. POLÍTICA DE PRIVACIDAD Y SU ACEPTACIÓN'}
        body={
          <>
            <b>1.1.</b> La presente Política de Privacidad (en adelante la
            “Política de Privacidad”) se aplica a la utilización de los
            servicios (en conjunto los “Servicios”, y cada uno de ellos un
            “Servicio”), ofrecidos o puestos a disposición de los Usuarios de
            internet por ticketpass.com.ar. (en adelante la “Empresa”), a través
            del sitio de internet www.ticketpass.com.ar y nuestras apps (en
            adelante el “Sitio”). <br />
            <b>1.2.</b> En caso de ser necesario, la Empresa podrá complementar
            la Política de Privacidad con información y/o términos y condiciones
            específicos con relación a un determinado Servicio. <br />
            <b>1.3.</b> La utilización de los Servicios atribuye a la condición
            de “Usuario del Sitio” (en adelante, el “Usuario”) y expresa la
            aceptación plena y sin reservas, por el Usuario, de todas y cada una
            de las cláusulas de la Política de Privacidad en la versión
            publicada por la Empresa en el momento mismo en que el Usuario
            utilice los Servicios. En consecuencia, la Política de Privacidad
            constituirá un acuerdo válido y obligatorio entre el Usuario y la
            Empresa con relación a la privacidad. Asimismo, la utilización de
            los Servicios expresa la aceptación plena y sin reservas del Usuario
            de los Términos y Condiciones de Utilización de los Servicios (los
            “Términos y Condiciones”) publicados por la Empresa en{' '}
            <a href='https://manager.ticketpass.com.ar/#/terms'>
              https://manager.ticketpass.com.ar/#/terms
            </a>{' '}
            <br />
            <b>1.4.</b> La Empresa podrá modificar la Política de Privacidad en
            cualquier momento. Las nuevas versiones de la Política de Privacidad
            serán notificadas mediante: (i) publicación de dicha nueva versión
            en{' '}
            <a href='https://manager.ticketpass.com.ar/#/privacy'>
              https://manager.ticketpass.com.ar/#/privacy
            </a>{' '}
            y/o (ii) por mensaje de correo electrónico dirigido al Usuario.{' '}
            <br />
            <b>1.5.</b> El Usuario acepta que será dado por notificado de
            cualquier modificación a la Política de Privacidad una vez que la
            Empresa hubiera publicado las mismas en
            https://manager.ticketpass.com.ar/#/privacy y que la continuación
            del Usuario en el uso de los Servicios una vez publicada dicha nueva
            versión se considerará como aceptación de dichas modificaciones a la
            Política de Privacidad. En consecuencia, el Usuario acepta: (i)
            chequear mensualmente; y (ii) leer cualquier mensaje de correo
            electrónico que sea enviado por la Empresa con cualquier
            modificación o nueva versión. <br />
            <b>1.6.</b> Para la Empresa la privacidad de los datos personales es
            muy importante. Si tiene dudas con respecto a la Política de
            Privacidad, por favor escribanos a{' '}
            <a href='mailto:info@ticketpass.app'>info@ticketpass.app</a>
          </>
        }
      />
      <TagSection
        title={'2. LOS SERVICIOS'}
        body={
          <>
            El Sitio es una página de internet y aplicaciones móbiles, a través
            del cual los Usuarios pueden acceder a un sistema de ofrecimiento de
            último momento y con descuento, de entradas a espectáculos teatrales
            y musicales, shows, eventos, entre otros, cuyos Términos y
            Condiciones pueden encontrarse en{' '}
            <a href='https://manager.ticketpass.com.ar/#/terms'>
              https://manager.ticketpass.com.ar/#/terms
            </a>
          </>
        }
      />
      <TagSection
        title={'3. RECOLECCIÓN DE INFORMACIÓN DE LOS USUARIOS. FINES.'}
        body={
          <>
            <b>3.1.</b> El Usuario acepta que la Empresa pueda recolectar
            información acerca del comportamiento de sus Usuarios utilizando
            (cookies*), (tags*) y otros métodos (tales como la información
            proporcionada por los Usuarios al registrarse en el Sitio). (*Un
            cookie es un pequeño archivo de texto mediante el cual se puede
            identificar en forma exclusiva un navegador de internet, y que suele
            ser instalado, enviado o escrito en el ordenador de los Usuarios de
            internet, cuando los mismos ingresan a determinados sitios de
            internet, a efectos de obtener automáticamente información acerca de
            la conducta de los Usuarios cuando navegan por dichos sitios de
            internet. Un archivo cookie generalmente contendrá el nombre del
            dominio desde donde la cookie ha sido transferida, la fecha de
            creación, y un código de identificación. La única información
            personal que un cookie puede contener es información que el Usuario
            mismo suministra con su navegación por internet, tales como la
            dirección de IP, las páginas visitadas y en qué orden, el tiempo de
            permanencia en cada página, la fecha y la hora, etc. (la
            “Información de Actividad”). Un cookie no puede leer datos del disco
            duro del Usuario ni leer los archivos cookie creados por otros
            sitios de internet.) <br />
            b) (*Un tag es una imagen pequeña ubicada en un sitio de internet
            para que el dueño del sitio, o un tercero, pueda monitorear quién
            está viendo el sitio recolectando su dirección IP (Protocolo de
            Internet), el URL de la página de donde viene y el momento en que
            fue visto. Estos tags pueden utilizarse junto a las cookies.) <br />
            <b>3.2.</b> La Empresa puede recopilar información de los Usuarios
            para mejorar la calidad de sus servicios gracias a que almacena las
            preferencias de los Usuarios y a que supervisa las tendencias de
            comportamiento, por ejemplo, el tipo de búsquedas de ofertas que
            realizan. Y también para los servicios publicitarios, para ayudar a
            los anunciantes a proporcionar y a gestionar anuncios, promociones y
            ofertas de sus bienes y/o servicios en internet. <br />
            <b>3.3.</b> Asimismo la Empresa puede recopilar y/o almacenar el
            número o dirección de IP (Protocolo de Internet) de los Usuarios a
            los fines de mejorar la calidad del servicio que brinda a través del
            Sitio. El almacenamiento por parte de la Empresa del número de IP de
            los dispositivos de los Usuarios permite identificarlos al momento
            de su ingreso a sus cuentas individuales. Asimismo facilita
            diagnosticar los eventuales problemas de conexión que puedan llegar
            a existir, mejorando la calidad de los Servicios. A dichos efectos,
            se entiende por número o dirección de IP (Protocolo de Internet) a
            la identificación numérica que distingue a un dispositivo
            electrónico informático (sea este una computadora, un teléfono
            personal con acceso a redes, una tableta, una PDA, etc.) que se
            conecta a una red informática. <br />
            <b>3.4.</b> La Empresa podrá solicitar al Usuario su dirección de
            correo electrónico (que podrá hacer las veces de Nombre de Usuario),
            Contraseña, Nombre y Apellido; Teléfono; Dirección; Código Postal;
            Ciudad; Región; y País de residencia y la información sobre el o los
            Métodos de Pago, como se definen en los Términos y Condiciones.
          </>
        }
      />
      <TagSection
        title={'4. USO DE LA INFORMACIÓN DE LOS USUARIOS RECOLECTADA.'}
        body={
          <>
            <b>4.1.</b> La Empresa podrá recolectar información de los Usuarios
            utilizando cookies (cuando el Usuario visite el Sitio) y/o tags. La
            información que recopile la Empresa podrá incluir el comportamiento
            de navegación, dirección IP, logs, y otros tipos de información. Sin
            embargo, la Empresa no recolectará información personal
            identificable de manera directa de ningún Usuario usando cookies o
            tags. <br />
            <b>4.2.</b> La Empresa utilizará la información provista por el
            Usuario, y la recolectada por la Empresa, con el fin de proveer
            mejores servicios al Usuario intentando ajustarse a sus necesidades.
            Adicionalmente, la información que provista por un Usuario y la que
            la Empresa recolecte directamente en nombre de dicho Usuario, podrá
            ser utilizada por la Empresa establecer comunicaciones más efectivas
            con los Usuarios a los fines de proveerlos de informaciones de
            interés y de distintas ofertas y promociones de los distintos
            proveedores de bienes y/o servicios. <br />
            <b>4.3.</b> La Empresa utilizará la información provista por el
            Usuario, y la recolectada por la Empresa, para enviar al Usuario,
            así como notificaciones, noticias y novedades de su interés, además
            de aquellas que revistan el carácter de notificaciones de índole
            institucional, comercial o legal. <br />
            <b>4.4.</b> La Empresa utilizará la información provista por el
            Usuario, y la recolectada por la Empresa, para analizar las
            conductas y comportamientos de los Usuarios en carácter de tales,
            sus ubicaciones geográficas, sus costumbres y/o preferencias de
            consumo, a los efectos de intentar mejorar sus servicios e intentar
            proveerlos de mejores soluciones a sus necesidades. <br />
            <b>4.5.</b> Asimismo la Empresa utilizará la información provista
            por el Usuario, y la recolectada por la Empresa para proponerle
            espectáculos de manera personalizada, según sus intereses,
            costumbres y hábitos. <br />
            <b>4.6.</b> La Empresa utilizará la información provista por el
            Usuario, y la recolectada por la Empresa en caso de ser solicitada
            por tribunales, u organismos estatales nacionales o internacionales
            que así lo requieran y lo soliciten en la forma correspondiente.{' '}
            <br />
            <b>4.7.</b> La Empresa no estará obligada a retener la información
            durante ningún plazo establecido y dispondrá la eliminación de la
            misma cuando lo juzgue conveniente. <br />
            <b>4.8.</b> La Empresa utiliza cookies para registrar patrones de
            Usuarios. En caso que un Usuario del Sitio no desee aceptar estas
            cookies, podrá configurar su navegador para que le otorgue la opción
            de aceptar cada cookie y rechazar las que no desee. La Empresa
            también podrá utilizar herramientas de análisis de los cookies, log
            files y tags. <br />
            <b>4.9.</b> La empresa podrá contener enlaces a otros sitios de
            internet que no sean propiedad de la Empresa. En consecuencia la
            Empresa no será responsable por el actuar de dichos sitios de
            internet, a los cuales no se aplicará la presente Política de
            Privacidad. Recomendamos examinar la política de privacidad
            detallada en aquellos sitios de internet para entender los
            procedimientos de recolección de información que utilizan y como
            protegen sus datos personales. <br />
            <b>4.10.</b> Las disposiciones de la Política de Privacidad se
            aplicarán a asimismo a los Usuarios del sitio, estén o no
            debidamente registrados.
          </>
        }
      />
      <TagSection
        title={'5. NO VENTA DE INFORMACIÓN.'}
        body={
          <>
            <b>5.1.</b> La Empresa no venderá, alquilará ni negociará ningún
            dato personal a ningún tercero para fines promocionales. Cualquier
            persona que hubiera provisto información de contacto personal a
            través de RO podrá enviar un correo electrónico a{' '}
            <a href='mailto:info@ticketpass.app'>info@ticketpass.app</a> a fin
            de actualizar, borrar y/o corregir su información personal de
            contacto. La Empresa responderá dicho requerimiento dentro de los 30
            (treinta) días siguientes a la recepción del mismo vía correo
            electrónico.
          </>
        }
      />
      <TagSection
        title={'6. MENORES DE EDAD.'}
        body={
          <>
            <b>6.1.</b> Los Servicios están permitidos sólo a quienes tengan
            edad legal para contratar y no se encuentren inhibidos legalmente o
            de algún modo vedados de ejercer actos jurídicos, derechos y/u
            obligaciones. Habida cuenta de ello, los menores de 18 años no
            tienen permitido el ingreso al Sitio y/o los Servicios ni el
            suministro de ningún dato personal u otro tipo de información.{' '}
            <br />
            <b>6.2.</b> Asimismo, toda vez que los menores de edad pueden no
            alcanzar a comprender debidamente la Política de Privacidad y sus
            implicancias, ni decidir válidamente sobre las opciones disponibles
            a través de sus servicios y/o sitio de internet, la Empresa insta a
            todos los padres o representantes, tutores o adultos bajo cuya
            supervisión se encuentren los menores que accedan al Sitio y/o a los
            Servicios, a participar activa y cuidadosamente en las actividades
            que el menor realice en internet, a los servicios on-line que
            utilicen dichos menores, a la información a la que estos accedan, ya
            sea cuando dichos menores visiten el Sitio o cualquier otro sitio de
            terceros, a enseñarles y a guiarlos en cómo proteger su propia
            información personal mientras estén navegando en internet.
          </>
        }
      />
      <TagSection
        title={'7. CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACIÓN.'}
        body={
          <>
            <b>7.1.</b> La Empresa ha adoptado medidas de seguridad razonables
            para proteger la información de los Usuarios, e impedir el acceso no
            autorizado a sus datos o cualquier modificación, divulgación o
            destrucción no autorizada de los mismos. La información recolectada
            por la Empresa será mantenida de manera estrictamente confidencial.
            El acceso a los datos personales está restringido a aquellos
            empleados, contratistas y representantes de la Empresa que necesitan
            conocer tales datos para desempeñar sus funciones y desarrollar o
            mejorar nuestros servicios. La Empresa exige a sus proveedores los
            mismos estándares de confidencialidad. La Empresa no permite el
            acceso a esta información a terceros ajenos a la Empresa, a
            excepción de un pedido expreso del Usuario. <br />
            <b>7.2.</b> Sin perjuicio de lo expuesto, considerando que internet
            es un sistema abierto, de acceso público, la Empresa no puede
            garantizar que terceros no autorizados no puedan eventualmente
            superar las medidas de seguridad y utilizar la información de los
            Usuarios en forma indebida.
          </>
        }
      />
      <TagSection
        title={'8. CAMBIOS EN LA ESTRUCTURA CORPORATIVA.'}
        body={
          <>
            <b>8.1.</b> La Empresa se reserva el derecho de transferir la
            información recolectada en caso de venta o fusión de la Empresa, o
            de una adquisición de los activos principales de la Empresa, o
            cualquier otra clase de transferencia de la Empresa a otra entidad.
            En dicho supuesto, la Empresa deberá adoptar las medidas razonables
            a efectos de asegurar que dicha información sea utilizada de una
            manera consistente con la Política de Privacidad.
          </>
        }
      />
      <TagSection
        title={'9. DERECHOS DE LOS USUARIOS SOBRE LA INFORMACIÓN.'}
        body={
          <>
            <b>9.1.</b> La mayoría de los navegadores están configurados para
            aceptar cookies, pero los Usuarios podrán reconfigurar su navegador
            de internet para rechazar todas las cookies o para que el sistema le
            indique en qué momento se envía una. Sin embargo, si las cookies
            están inhabilitadas, es posible que algunas características y
            servicios de los sitios de internet no funcionen de manera adecuada.{' '}
            <br />
            <b>9.2.</b> La Empresa tratará por todos los medios a su alcance de
            facilitar a los Usuarios sobre los cuales haya recopilado o
            almacenado información personal, el acceso a sus datos personales
            (“Derecho de Acceso”), así como la rectificación, modificación o
            actualización de los mismos (“Derecho de Rectificación”), o incluso
            la cancelación de dichos datos personales (“Derecho de Remoción”), a
            menos que la Empresa pueda denegar dichas Solicitudes, en caso que
            se encuentre obligada o tenga derecho a conservar dichos datos
            personales de acuerdo a la legislación aplicable. <br />
            a) A dichos efectos, el Usuario deberá enviar su Solicitud mediante
            el envío de un correo electrónico con el asunto “Acceso a Datos
            Personales” a info@ticketpass.app. La Empresa podrá requerir a dicho
            Usuario que se identifique, lo que podrá ser verificado por la
            Empresa, así como que precise los datos personales a los cuales
            desean acceder, rectificar o remover. <br />
            b) La Empresa podrá rechazar la tramitación de solicitudes que sean
            irrazonablemente repetitivas o sistemáticas, que requieran un
            esfuerzo técnico desproporcionado, que pongan en peligro la
            privacidad de los demás Usuarios, o que se consideren poco
            prácticas, o para las que no sea necesario acceder a los datos.{' '}
            <br />
            c) El servicio de acceso, rectificación y remoción de datos
            personales será prestado por la Empresa en forma gratuita, excepto
            en caso que requiriera un esfuerzo desproporcionado o irrazonable,
            en cuyo caso podrá cobrarse un cargo de administración. <br />
            <b>9.3</b> Los Usuarios tendrán y deberán hacer uso responsable de
            su cuenta en el Sitio. En cualquier momento, el Usuario podrá
            solicitar la baja como Usuario y la eliminación de su cuenta e
            información de la base de datos de la Empresa, como así también
            acceder y actualizar a sus datos personales. <br />
            <b>9.4</b> Si el usuario desea eliminar sus datos personales de
            nuestro sistema, deberá enviar un mail con el título “Eliminar datos
            personales” y en el cuerpo del mail su nombre, apellido, n° de
            identificación y un teléfono de contacto a baja@ticketpass.app para
            iniciar el proceso de baja de usuario. <br />
            <br />
            EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL
            DERECHO DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO
            INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO
            AL EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO 14, INCISO 3 DE LA
            LEY Nº 25.326 DE LA REPUBLICA ARGENTINA. LA AUTORIDAD DE APLICACIÓN
            (EN ARGENTINA, LA DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS
            PERSONALES, ÓRGANO DE CONTROL DE LA LEY Nº 25.326), TIENE LA
            ATRIBUCIÓN DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN
            CON RELACIÓN AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE
            DATOS PERSONALES. <br />
            <br />
            Para contactar a la Dirección Nacional de Protección de Datos
            Personales: <br />
            SARMIENTO 1118, 5º PISO (C1041AAX) <br />
            TEL. 4383-8510/12/13/15 <br />
            WWW.JUS.GOV.AR/DATOSPERSONALES <br />
            INFODNPDP@JUS.GOV.AR
          </>
        }
      />
      <TagSection
        title={'10. EXCEPCIONES'}
        body={
          <>
            <b>10.1.</b> No obstante cualquier otra provisión en contrario en la
            Política de Privacidad, la Empresa podrá divulgar cierta información
            personal de los Usuarios, cuando crea de buena fe que esa
            divulgación sea razonablemente necesaria para: <br />
            a) evitar una responsabilidad legal; <br />
            b) cumplir una exigencia legal tal como una orden de allanamiento,
            una citación judicial, una orden judicial; <br />
            c) cumplir un requerimiento de una autoridad gubernamental o
            reguladora; <br />
            d) proteger los derechos, propiedad o seguridad de la Empresa, de
            los Usuarios, o de un tercero.
          </>
        }
      />
      <TagSection
        title={'11. SERVICIOS DE TERCEROS'}
        body={
          <>
            <b>11.1.</b> La Empresa podrá utilizar y utiliza servicios de
            terceros de los que no es responsable por la información que
            recolectan ni la forma en que protegen su información personal. Para
            ello, cada uno de estos servicios de terceros cuentan con sus
            propias políticas de privacidad y proveen, en los casos que se
            encuentre disponible, un método con el que Usuario podrá hacer un
            opt-out; entre ellos:
          </>
        }
      />
      <TagSection
        title={'12. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD'}
        body={
          <>
            <b>12.1.</b> La Empresa podrá modificar la presente Política de
            Privacidad en caso que lo considere oportuno. En caso que las
            modificaciones sean sustanciales con relación al tratamiento de los
            datos personales recolectados en virtud de los Servicios, las mismas
            serán notificadas mediante la publicación de un aviso destacado en
            esta página. En caso que el Usuario deseara ser notificado vía
            correo electrónico de dichas modificaciones, deberá enviar un correo
            electrónico con el asunto “Lista de Cambios de Política de
            Privacidad”, a{' '}
            <a href='mailto:info@ticketpass.app'>info@ticketpass.app</a> desde
            la dirección de correo electrónico en la cual desea recibir dichas
            notificaciones.
          </>
        }
      />
      <TagSection
        title={'13. CONTACTO'}
        body={
          <>
            <b>13.1.</b> En caso que el Usuario tenga alguna duda acerca de la
            Política de Privacidad, o sobre la aplicación de la misma, deberá
            ponerse en contacto con la Empresa, en cualquier momento, vía correo
            electrónico a{' '}
            <a href='mailto:info@ticketpass.app'>info@ticketpass.app</a>
          </>
        }
      />
      <TagSection
        title={'14. USO DE LA CÁMARA EN LAS APLICACIONES'}
        body={
          <>
            <b>14.1.</b> Las aplicaciones de tickePass sólo usan la cámara de
            los celulares para escanear código QR y códigos de barras.
          </>
        }
      />
      <Typography variant='body2' className={classes.lastUpdate}>
        Fecha de última actualización: 12/06/2018
      </Typography>
    </div>
  )
}

export default PrivacyPage
