import React from 'react'
import {
  FormControl,
  InputAdornment,
  TextField,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useStyles } from './PlatformCommission.style'
import { isNotNumber, round } from 'views/utils/functions'

export const PlatformCommission = ({
  byPercentReseller,
  byPercentPlatform,
  setByPercentPlatform,
  helperTextPercent,
  handleBlur,
  commission,
  vatList,
  setFieldValue,
  values,
  error,
  calculateFields,
  validateNaNValue,
  isProducer,
  helperTextPlatform,
  calculatePercentage
}) => {
  const classes = useStyles()

  return (
    <div className={classes.containerInputs}>
      <FormControlLabel
        className={isProducer ? classes.checkDisabled : classes.textCheckBox}
        control={
          <Checkbox
            checked={byPercentPlatform}
            onChange={(e) => {
              !isProducer && setByPercentPlatform(e.target.checked)
            }}
            name='ByPercentPlatform'
          />
        }
        label='Por porcentaje?'
      />
      <div>
        <TextField
          label='Comisión ticketPass (IVA incluido)'
          id='standard-start-adornment'
          style={{ width: 300 }}
          type='string'
          name='PlatformCommissionAmount'
          value={round(commission?.CommissionWithTaxAmount)}
          disabled={byPercentPlatform || isProducer}
          onBlur={handleBlur}
          onChange={(e) => {
            calculateFields(
              e.target.value,
              'CommissionWithTaxAmount',
              values,
              setFieldValue,
              commission,
              byPercentPlatform,
              byPercentReseller
            )
            setFieldValue('PlatformCommissionAmount', e.target.value)
          }}
          onKeyPress={(e) => isNotNumber(e)}
          onKeyDown={(e) => isNotNumber(e)}
          className={`${classes.fieldCommission} ${classes.fieldCommissionAmount}`}
          helperText={`(${validateNaNValue(round(calculatePercentage))}%) (${validateNaNValue(
            round(helperTextPlatform)
          )}%)`}
          inputProps={{ maxLength: 8 }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          variant='standard'
        />

        <FormControl
          variant='standard'
          className={`${classes.formControl} ${classes.formControlCommission}`}>
          <Input
            name='PlatformCommission'
            value={validateNaNValue(round(commission?.Commission))}
            error={Boolean(error)}
            type='string'
            onChange={(e) => {
              calculateFields(
                e.target.value,
                'Commission',
                values,
                setFieldValue,
                commission,
                byPercentPlatform,
                byPercentReseller
              )
              setFieldValue(
                'PlatformCommission',
                validateNaNValue(parseFloat(e.target.value))
              )
            }}
            onBlur={handleBlur}
            disabled={!byPercentPlatform || isProducer}
            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
            className={`${classes.fieldCommission} ${classes.fieldCommissionPercent}`}
            aria-describedby='standard-weight-helper-text'
            onKeyPress={(e) => isNotNumber(e)}
            onKeyDown={(e) => isNotNumber(e)}
            inputProps={{
              'aria-label': 'weight',
              maxLength: 5
            }}
          />
          <FormHelperText
            id='standard-weight-helper-text'
            error={Boolean(error)}>
            {helperTextPercent}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.commissionVat}>
          <Select
            name='CommissionVat'
            value={commission?.CommissionVat || ''}
            onChange={(e) => {
              calculateFields(
                e.target.value,
                'CommissionVat',
                values,
                setFieldValue,
                commission,
                byPercentPlatform,
                byPercentReseller
              )
            }}
            onBlur={handleBlur}
            size='small'
            renderValue={(value) => `${value * 100}%`}>
            {vatList &&
              !vatList.find((c) => c === commission?.CommissionVat) && (
                <MenuItem value={commission?.CommissionVat}>
                  {commission?.CommissionVat * 100}% (IVA No Vigente)
                </MenuItem>
              )}
            {vatList &&
              vatList.map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c * 100}%
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{'IVA ticketPass'}</FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

export default PlatformCommission
