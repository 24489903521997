import * as types from './types'
import { DashboardProxy } from 'services'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const loadTotalizatorsAttempt = () => ({
  type: types.LOAD_TOTALIZATORS_ATTEMPT
})

export const loadTotalizatorsSuccess = (totalizators) => ({
  type: types.LOAD_TOTALIZATORS_SUCCESS,
  totalizators
})

export const loadTotalizatorsFailure = (error) => ({
  type: types.LOAD_TOTALIZATORS_FAILURE,
  error
})

export const loadTotalTicketsAttempt = () => ({
  type: types.LOAD_TOTAL_TICKETS_ATTEMPT
})

export const loadTotalTicketsSuccess = (totalTickets) => ({
  type: types.LOAD_TOTAL_TICKETS_SUCCESS,
  totalTickets
})

export const loadTotalTicketsFailure = (error) => ({
  type: types.LOAD_TOTAL_TICKETS_FAILURE,
  error
})

export const loadInvitationsDataAttempt = () => ({
  type: types.LOAD_INVITATIONS_DATA_ATTEMPT
})
export const loadInvitationsDataSuccess = (invitationsData) => ({
  type: types.LOAD_INVITATIONS_DATA_SUCCESS,
  invitationsData
})
export const loadInvitationsDataFailure = (error) => ({
  type: types.LOAD_INVITATIONS_DATA_FAILURE,
  error
})

export const loadEventOperationsAttempt = () => ({
  type: types.LOAD_EVENT_OPERATIONS_ATTEMPT
})
export const loadEventOperationsSuccess = (operations) => ({
  type: types.LOAD_EVENT_OPERATIONS_SUCCESS,
  operations
})
export const loadEventOperationsFailure = (error) => ({
  type: types.LOAD_EVENT_OPERATIONS_FAILURE,
  error
})

export const getEventOperationsReportAttempt = () => ({
  type: types.GET_EVENT_OPERATIONS_REPORT_ATTEMPT
})
export const getEventOperationsReportSuccess = (report) => ({
  type: types.GET_EVENT_OPERATIONS_REPORT_SUCCESS,
  report
})
export const getEventOperationsReportFailure = (error) => ({
  type: types.GET_EVENT_OPERATIONS_REPORT_FAILURE,
  error
})

export const getOperationsPercentagesAttempt = () => ({
  type: types.GET_OPERATIONS_PERCENTAGES_ATTEMPT
})
export const getOperationsPercentagesSuccess = (percentages) => ({
  type: types.GET_OPERATIONS_PERCENTAGES_SUCCESS,
  percentages
})
export const getOperationsPercentagesFailure = (error) => ({
  type: types.GET_OPERATIONS_PERCENTAGES_FAILURE,
  error
})

export const loadTotalSalesPercentagesAttempt = () => ({
  type: types.LOAD_TOTAL_SALES_PERCENTAGES_ATTEMPT
})
export const loadTotalSalesPercentagesSuccess = (percentages) => ({
  type: types.LOAD_TOTAL_SALES_PERCENTAGES_SUCCESS,
  percentages
})
export const loadTotalSalesPercentagesFailure = (error) => ({
  type: types.LOAD_TOTAL_SALES_PERCENTAGES_FAILURE,
  error
})

export const getInvitationsReportAttempt = () => ({
  type: types.GET_INVITATIONS_REPORT_ATTEMPT
})
export const getInvitationsReportSuccess = (report) => ({
  type: types.GET_INVITATIONS_REPORT_SUCCESS,
  report
})
export const getInvitationsReportFailure = (error) => ({
  type: types.GET_INVITATIONS_REPORT_FAILURE,
  error
})

export const getTicketTypesReportAttempt = () => ({
  type: types.GET_TICKET_TYPES_REPORT_ATTEMPT
})
export const getTicketTypesReportSuccess = (report) => ({
  type: types.GET_TICKET_TYPES_REPORT_SUCCESS,
  report
})
export const getTicketTypesReportFailure = (error) => ({
  type: types.GET_TICKET_TYPES_REPORT_FAILURE,
  error
})

export const getTicketCodesReportAttempt = () => ({
  type: types.GET_TICKET_CODES_REPORT_ATTEMPT
})
export const getTicketCodesReportSuccess = (report) => ({
  type: types.GET_TICKET_CODES_REPORT_SUCCESS,
  report
})
export const getTicketCodesReportFailure = (error) => ({
  type: types.GET_TICKET_CODES_REPORT_FAILURE,
  error
})

export const loadCashSalesAttempt = () => ({
  type: types.LOAD_CASH_SALES_ATTEMPT
})
export const loadCashSalesSuccess = (sales) => ({
  type: types.LOAD_CASH_SALES_SUCCESS,
  sales
})
export const loadCashSalesFailure = (error) => ({
  type: types.LOAD_CASH_SALES_FAILURE,
  error
})

export const loadOnlineSalesAttempt = () => ({
  type: types.LOAD_ONLINE_SALES_ATTEMPT
})
export const loadOnlineSalesSuccess = (sales) => ({
  type: types.LOAD_ONLINE_SALES_SUCCESS,
  sales
})
export const loadOnlineSalesFailure = (error) => ({
  type: types.LOAD_ONLINE_SALES_FAILURE,
  error
})

export const loadServiceChargeSalesAttempt = () => ({
  type: types.LOAD_SERVICE_CHARGE_SALES_ATTEMPT
})
export const loadServiceChargeSalesSuccess = (sales) => ({
  type: types.LOAD_SERVICE_CHARGE_SALES_SUCCESS,
  sales
})
export const loadServiceChargeSalesFailure = (error) => ({
  type: types.LOAD_SERVICE_CHARGE_SALES_FAILURE,
  error
})

export const getRRPPSalesReportAttempt = () => ({
  type: types.GET_RRPP_SALES_REPORT_ATTEMPT
})
export const getRRPPSalesReportSuccess = (report) => ({
  type: types.GET_RRPP_SALES_REPORT_SUCCESS,
  report
})
export const getRRPPSalesReportFailure = (error) => ({
  type: types.GET_RRPP_SALES_REPORT_FAILURE,
  error
})

export const getRRPPOnlineReportAttempt = () => ({
  type: types.GET_RRPP_ONLINE_REPORT_ATTEMPT
})
export const getRRPPOnlineReportSuccess = (report) => ({
  type: types.GET_RRPP_ONLINE_REPORT_SUCCESS,
  report
})
export const getRRPPOnlineReportFailure = (error) => ({
  type: types.GET_RRPP_ONLINE_REPORT_FAILURE,
  error
})

export const getOrdersReportAttempt = () => ({
  type: types.GET_ORDERS_REPORT_ATTEMPT
})
export const getOrdersReportSuccess = (report) => ({
  type: types.GET_ORDERS_REPORT_SUCCESS,
  report
})
export const getOrdersReportFailure = (error) => ({
  type: types.GET_ORDERS_REPORT_FAILURE,
  error
})

export const resetDashboard = () => ({
  type: types.RESET_DASHBOARD
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS
})

export const resetParamsDashboard = () => ({
  type: types.RESET_PARAMS
})

export const filtersSet = (filters, fetchUpcomingEvents) => ({
  type: types.SET_FILTERS,
  filters,
  fetchUpcomingEvents
})

export const loadPage =
  (
    fetchUpcomingEvents,
    { searchParams, pageNumber, pageSize, orderBy, order }
  ) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageSize, pageNumber })
      )

      const proxy = new DashboardProxy()
      const response = fetchUpcomingEvents
        ? await proxy.getUpcomingEvents(
            searchParams,
            pageNumber,
            pageSize,
            orderBy,
            order
          )
        : await proxy.getPastEvents(
            searchParams,
            pageNumber,
            pageSize,
            orderBy,
            order
          )

      dispatch(pagedListSuccess(response))
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }

export const loadTotalizators = (eventId) => async (dispatch) => {
  try {
    dispatch(loadTotalizatorsAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.fetchTotalizatorsData(eventId)

    dispatch(loadTotalizatorsSuccess(response))
  } catch (error) {
    dispatch(loadTotalizatorsFailure(error))
    throw error
  }
}

export const loadTotalTickets = (eventId) => async (dispatch) => {
  try {
    dispatch(loadTotalTicketsAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.fetchTicketTypes(eventId)

    dispatch(loadTotalTicketsSuccess(response))
  } catch (error) {
    dispatch(loadTotalTicketsFailure(error))
    throw error
  }
}

export const loadInvitationsData = (eventId) => async (dispatch) => {
  try {
    dispatch(loadInvitationsDataAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.fetchInvitationsData(eventId)

    dispatch(loadInvitationsDataSuccess(response))
  } catch (error) {
    dispatch(loadInvitationsDataFailure(error))
    throw error
  }
}

export const loadEventOperations = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventOperationsAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.fetchOperationsByDay(eventId)

    dispatch(loadEventOperationsSuccess(response))
  } catch (error) {
    dispatch(loadEventOperationsFailure(error))
    throw error
  }
}

export const getEventOperationsReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getEventOperationsReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getOperationsByDayReport(eventId)

    dispatch(getEventOperationsReportSuccess(response))
  } catch (error) {
    dispatch(getEventOperationsReportFailure(error))
    throw error
  }
}

export const loadOperationsPercentages = (eventId) => async (dispatch) => {
  try {
    dispatch(getOperationsPercentagesAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getOperationsPercentages(eventId)

    dispatch(getOperationsPercentagesSuccess(response))
  } catch (error) {
    dispatch(getOperationsPercentagesFailure(error))
    throw error
  }
}

export const loadTotalSalesPercentages = (eventId) => async (dispatch) => {
  try {
    dispatch(loadTotalSalesPercentagesAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getTotalSalesPercentages(eventId)

    dispatch(loadTotalSalesPercentagesSuccess(response))
  } catch (error) {
    dispatch(loadTotalSalesPercentagesFailure(error))
    throw error
  }
}

export const getInvitationsReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getInvitationsReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getInvitationsReport(eventId)

    dispatch(getInvitationsReportSuccess(response))
  } catch (error) {
    dispatch(getInvitationsReportFailure(error))
    throw error
  }
}

export const getTicketTypesReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getTicketTypesReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getTicketsReport(eventId)

    dispatch(getTicketTypesReportSuccess(response))
  } catch (error) {
    dispatch(getTicketTypesReportFailure(error))
    throw error
  }
}

export const getTicketCodesReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getTicketCodesReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getTicketsCodesReport(eventId)

    dispatch(getTicketCodesReportSuccess(response))
  } catch (error) {
    dispatch(getTicketCodesReportFailure(error))
    throw error
  }
}

export const getRRPPSalesReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getRRPPSalesReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getRRPPSalesReport(eventId)

    dispatch(getRRPPSalesReportSuccess(response))
  } catch (error) {
    dispatch(getRRPPSalesReportFailure(error))
    throw error
  }
}

export const getRRPPOnlineReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getRRPPOnlineReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getRRPPOnlineReport(eventId)

    dispatch(getRRPPOnlineReportSuccess(response))
  } catch (error) {
    dispatch(getRRPPOnlineReportFailure(error))
    throw error
  }
}

export const getOrdersReport = (eventId, paymentStatus) => async (dispatch) => {
  try {
    dispatch(getOrdersReportAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getOrdersReport(eventId, paymentStatus)

    dispatch(getOrdersReportSuccess(response))
  } catch (error) {
    dispatch(getOrdersReportFailure(error))
    throw error
  }
}

export const loadCashSales = (eventId) => async (dispatch) => {
  try {
    dispatch(loadCashSalesAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getCashSales(eventId)

    dispatch(loadCashSalesSuccess(response))
  } catch (e) {
    dispatch(loadCashSalesFailure(e))
    throw e
  }
}

export const loadOnlineSales = (eventId) => async (dispatch) => {
  try {
    dispatch(loadOnlineSalesAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getOnlineSales(eventId)

    dispatch(loadOnlineSalesSuccess(response))
  } catch (e) {
    dispatch(loadOnlineSalesFailure())
    throw e
  }
}

export const loadServiceChargeSales = (eventId) => async (dispatch) => {
  try {
    dispatch(loadServiceChargeSalesAttempt())

    const proxy = new DashboardProxy()
    const response = await proxy.getServiceChargeSales(eventId)

    dispatch(loadServiceChargeSalesSuccess(response))
  } catch (e) {
    dispatch(loadServiceChargeSalesFailure())
    throw e
  }
}

export const setFilters = (filters, fetchUpcomingEvents) => (dispatch) => {
  dispatch(filtersSet({ searchKey: filters, fetchUpcomingEvents }))
}
