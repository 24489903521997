export const SEND_NOTIFICATION_ATTEMPT =
  'admin/notifications/SEND_NOTIFICATION_ATTEMPT'
export const SEND_NOTIFICATION_SUCCESS =
  'admin/notifications/SEND_NOTIFICATION_SUCCESS'
export const SEND_NOTIFICATION_FAILURE =
  'admin/notifications/SEND_NOTIFICATION_FAILURE'

export const SEND_NOTIFICATION_TO_USER_ATTEMPT =
  'admin/notifications/SEND_NOTIFICATION_TO_USER_ATTEMPT'
export const SEND_NOTIFICATION_TO_USER_SUCCESS =
  'admin/notifications/SEND_NOTIFICATION_TO_USER_SUCCESS'
export const SEND_NOTIFICATION_TO_USER_FAILURE =
  'admin/notifications/SEND_NOTIFICATION_TO_USER_FAILURE'

export const RESET = 'admin/notifications/RESET'
