import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400
  },
  fieldRow: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  field: {
    margin: theme.spacing()
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& button': {
      marginRight: theme.spacing(1)
    }
  }
}))

export default useStyles
