import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Paper, Typography, Button } from '@material-ui/core'
import packageInfo from '~/../package.json'
import { LoginForm, Facebook } from './components'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import ReactCountryFlag from 'react-country-flag'
import { useStyles } from './LoginPage.style'

export const LoginPage = () => {
  const classes = useStyles()
  const { country } = useSelector((state) => state.app)

  useEffect(() => {
    if (!country) {
      history.push(ROUTES.PUBLIC.LANDING)
    }
  }, [country])

  return (
    <div className={classes.container}>
      <Paper className={classes.loginContainer}>
        <div className={classes.logo} />
        <LoginForm />
        Ó
        <Facebook />
      </Paper>
      <Button
        variant='contained'
        type='submit'
        translate='no'
        className={classes.button}
        onClick={() => history.push(ROUTES.PUBLIC.LANDING)}>
        <span>{country}</span>
        <ReactCountryFlag countryCode={country} svg className={classes.flag} />
        <span>CAMBIAR DE PAIS</span>
      </Button>
      <Typography variant='caption' className={classes.versionNumber}>
        {`v${packageInfo.version}`}
      </Typography>
      {country === 'ar' && (
        <div className={classes.containerButtons}>
          <Button
            href='#/privacy'
            color='primary'
            className={classes.staticPageButton}>
            Política de Privacidad
          </Button>
          <Button
            href='#/terms'
            className={classes.staticPageButton}
            color='primary'>
            Términos y Condiciones
          </Button>
        </div>
      )}
    </div>
  )
}

export default LoginPage
