import * as types from './types'

const initialState = {
  roles: [],
  pending: false,
  error: null,
  users: [],
  actionPending: false,
  reqParams: null,
  pagedList: null,
  pendingPagedList: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        pending: true,
        roles: [],
        error: null
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        roles: action.roles,
        error: null
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        pending: false,
        roles: [],
        error: action.error
      }
    case types.LOAD_USERS_BY_ROLE_ATTEMPT:
      return {
        ...state,
        pending: true,
        users: [],
        error: null
      }
    case types.LOAD_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        pending: false,
        users: action.users,
        error: null
      }
    case types.LOAD_USERS_BY_ROLE_FAILURE:
      return {
        ...state,
        pending: false,
        users: [],
        error: action.error
      }
    case types.REMOVE_USER_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        error: null
      }
    case types.REMOVE_USER_SUCCESS:
      return {
        ...state,
        actionPending: false,
        error: null
      }
    case types.REMOVE_USER_FAILURE:
      return {
        ...state,
        actionPending: false,
        error: action.error
      }
    case types.ADD_USER_ATTEMPT:
      return {
        ...state,
        actionPending: true,
        error: null
      }
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        actionPending: false,
        error: null
      }
    case types.ADD_USER_FAILURE:
      return {
        ...state,
        actionPending: false,
        error: action.error
      }
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pendingPagedList: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pendingPagedList: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pendingPagedList: false,
        error: action.error,
        pagedList: null
      }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
