import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { AppContainer } from '~/views/shared'
import { loadImages, reset, resetTable } from 'state/modules/events'
import { EventsIndexPage } from './EventsIndexPage'
import { EventsCreatePage } from './EventsCreatePage'
import { EventsUpdatePage } from './EventsUpdatePage'
import { EventsImagePage } from './EventsImagePage'
import { EventsInfoPage } from './EventsInfoPage'
import { InvitationIndexPage } from './Invitations/InvitationIndexPage'
import { ResellerEventPage } from './Resellers/ResellerEventPage'
import { ResellerInfoSalesPage } from './Resellers/ResellerInfoSalesPage'
import {
  TicketOrdersIndexPage,
  TicketsCreatePage,
  TicketsUpdatePage,
  TicketsIndexPage
} from './Tickets'
import {
  OrderRecordPage,
  OrderDetailsPage,
  OrdersIndexPage,
  TicketsPerOrderPage
} from './Orders'
import {
  DrawsIndexPage,
  DrawsCreatePage,
  DrawsUpdatePage,
  DrawsDetailsPage
} from './Draws'

export const EventsPage = () => {
  const dispatch = useDispatch()
  const [creationStep, setCreationStep] = useState(false)
  const [fromImages, setFromImages] = useState(false)

  const handleSuccessfulEventCreation = (eventId) => {
    if (!creationStep) {
      setCreationStep(true)
      dispatch(reset())
      history.push(ROUTES.ADMIN.EVENTS.IMAGES.replace(':eventId', eventId))
    }
  }

  const handleSuccessfulImageUpload = async (eventId) => {
    setTimeout(() => {
      dispatch(resetTable())
      if (creationStep) {
        setFromImages(true)
        history.push(
          ROUTES.ADMIN.EVENTS.TICKETS.INDEX.replace(':eventId', eventId)
        )
      } else {
        dispatch(loadImages(eventId))
      }
    }, 2000)
  }

  const redirectToIndex = async (addDelay = true) => {
    setTimeout(
      () => {
        setCreationStep(false)
        dispatch(resetTable())
        history.push(ROUTES.ADMIN.EVENTS.INDEX)
      },
      addDelay ? 2000 : 0
    )
  }

  const redirectToIndexTickets = async (eventId) => {
    dispatch(resetTable())
    history.push(ROUTES.ADMIN.EVENTS.TICKETS.INDEX.replace(':eventId', eventId))
  }

  const redirectToIndexOrders = (eventId) => {
    dispatch(reset())
    history.push(ROUTES.ADMIN.EVENTS.ORDERS.INDEX.replace(':eventId', eventId))
  }

  const redirectToIndexDraws = (eventId) => {
    history.push(ROUTES.ADMIN.EVENTS.DRAWS.INDEX.replace(':eventId', eventId))
  }

  const handleStepChange = () => {
    setCreationStep(false)
  }

  return (
    <AppContainer title='Eventos'>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.INDEX}
          render={(props) => (
            <EventsIndexPage {...props} handleStepChange={handleStepChange} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.CREATE}
          render={(props) => (
            <EventsCreatePage
              {...props}
              onSuccess={handleSuccessfulEventCreation}
              onCancel={() => redirectToIndex(false)}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.VIEW}
          render={(props) => <EventsInfoPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.RESELLERS.INDEX}
          render={(props) => <ResellerEventPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.RESELLERS.VIEW}
          render={(props) => (
            <ResellerInfoSalesPage {...props} resellerType={0} />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.RESELLERS.TPVIEW}
          render={(props) => (
            <ResellerInfoSalesPage {...props} resellerType={1} />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.RESELLERS.CASHVIEW}
          render={(props) => (
            <ResellerInfoSalesPage {...props} resellerType={2} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.UPDATE}
          render={(props) => (
            <EventsUpdatePage
              {...props}
              onSuccess={redirectToIndex}
              onCancel={() => redirectToIndex(false)}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.IMAGES}
          render={(props) => (
            <EventsImagePage
              {...props}
              onAddImage={handleSuccessfulImageUpload}
              onCancel={() => redirectToIndex(false)}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.INVITATIONS}
          render={(props) => <InvitationIndexPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.ORDERS.INDEX}
          render={(props) => <OrdersIndexPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.ORDERS.HISTORY}
          render={(props) => (
            <OrderRecordPage {...props} onBack={redirectToIndexOrders} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.ORDERS.DETAILS}
          render={(props) => (
            <OrderDetailsPage {...props} onBack={redirectToIndexOrders} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.ORDERS.TICKETS}
          render={(props) => <TicketsPerOrderPage {...props} />}
        />

        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.TICKETS.INDEX}
          render={(props) => (
            <TicketsIndexPage
              {...props}
              onSuccess={redirectToIndex}
              onCancel={() => redirectToIndex(false)}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.TICKETS.CREATE}
          render={(props) => (
            <TicketsCreatePage
              {...props}
              onSuccess={fromImages ? redirectToIndex : redirectToIndexTickets}
              onCancel={() =>
                fromImages ? redirectToIndex(false) : redirectToIndexTickets()
              }
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.TICKETS.CLONE}
          render={(props) => (
            <TicketsCreatePage
              {...props}
              onSuccess={fromImages ? redirectToIndex : redirectToIndexTickets}
              onCancel={() =>
                fromImages ? redirectToIndex(false) : redirectToIndexTickets()
              }
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.TICKETS.ORDERS}
          render={(props) => (
            <TicketOrdersIndexPage {...props} onSuccess={redirectToIndex} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.TICKETS.UPDATE}
          render={(props) => (
            <TicketsUpdatePage
              {...props}
              onSuccess={redirectToIndexTickets}
              onCancel={() => {
                redirectToIndexTickets()
              }}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.EVENTS.DRAWS.INDEX}
          render={(props) => (
            <DrawsIndexPage
              {...props}
              onSuccess={redirectToIndex}
              onCancel={() => redirectToIndex(false)}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.DRAWS.CREATE}
          render={(props) => (
            <DrawsCreatePage {...props} onSuccess={redirectToIndexDraws} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.DRAWS.UPDATE}
          render={(props) => (
            <DrawsUpdatePage {...props} onSuccess={redirectToIndexDraws} />
          )}
        />
        <Route
          path={ROUTES.ADMIN.EVENTS.DRAWS.DETAILS}
          render={(props) => (
            <DrawsDetailsPage {...props} onBack={redirectToIndexDraws} />
          )}
        />
      </Switch>
    </AppContainer>
  )
}

export default EventsPage
