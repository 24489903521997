import * as types from './types'

const initialState = {
  pagedList: null,
  macroAccount: null,
  pendingCreate: false,
  pending: false,
  actionPending: false,
  actionSuccess: false,
  error: null,
  reqParams: null,
  accountData: null,
  pendingRefresh: false,
  account: null,
  pendingUpdate: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGEDLIST_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        pagedList: null,
        reqParams: action.reqParams
      }
    case types.PAGEDLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        pagedList: action.pagedList
      }
    case types.PAGEDLIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        pagedList: null
      }
    case types.CREATE_ACCOUNT_ATTEMPT:
      return {
        ...state,
        macroAccount: null,
        pendingCreate: true,
        error: null
      }
    case types.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        macroAccount: action.macroAccount,
        pendingCreate: false,
        error: null
      }
    case types.CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        macroAccount: null,
        pendingCreate: false,
        error: action.error
      }
    case types.REFRESH_TOKEN_ATTEMPT:
      return {
        ...state,
        accountData: null,
        pendingRefresh: true,
        error: null
      }
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        accountData: action.accountData,
        pendingRefresh: false,
        error: null
      }
    case types.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        accountData: null,
        pendingRefresh: false,
        error: action.error
      }
    case types.ENABLE_ACCOUNT_ATTEMPT:
      return {
        ...state,
        accountData: null,
        pendingRefresh: true,
        error: null
      }
    case types.ENABLE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountData: action.accountData,
        pendingRefresh: false,
        error: null
      }
    case types.ENABLE_ACCOUNT_FAILURE:
      return {
        ...state,
        accountData: null,
        pendingRefresh: false,
        error: action.error
      }
    case types.UPDATE_COMMISSION_ATTEMPT:
      return {
        ...state,
        account: null,
        pendingUpdate: true,
        error: null
      }
    case types.UPDATE_COMMISSION_SUCCESS:
      return {
        ...state,
        account: action.account,
        pendingUpdate: false,
        error: null
      }
    case types.UPDATE_COMMISSION_FAILURE:
      return {
        ...state,
        account: null,
        pendingUpdate: false,
        error: action.error
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    case types.RESET_ACTIONS:
      return {
        ...state,
        pending: false,
        actionPending: false,
        actionSuccess: false
      }
    case types.RESET_PARAMS:
      return {
        ...state,
        reqParams: null
      }
    default:
      return state
  }
}
