import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { create } from '~/state/modules/organizers'
import { loadCommissions } from 'state/modules/mercadoPago'
import { PaperContainer } from '~/views/shared'
import { OrganizerForm } from '../components/OrganizerForm'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const OrganizerCreatePage = ({ onSuccess }) => {
  const { pending, pendingCreate } = useSelector((state) => state.organizers)
  const { commissions, pending: pendingCommissions } = useSelector(
    (state) => state.mercadoPago
  )

  const dispatch = useDispatch()

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await dispatch(create(values))
      onSuccess()
      showSnackbarSuccess('Organizador Creado!')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const values = {
    Name: '',
    Commission: 6,
    FacebookPixelId: '',
    TaxIdentificationNumber: '',
    CustomerName: '',
    CustomerType: 0,
    Address: '',
    Phone: '',
    Email: '',
    DefaultCommissionMp: commissions?.mpCommissions[0].Fee || 0
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadCommissions())
    }
    fetchData().then()
  }, [dispatch])

  return (
    <PaperContainer title='Creación de Organizador'>
      {pending || pendingCommissions ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <OrganizerForm
          values={values}
          mpCommissions={commissions?.mpCommissions}
          actionText='Crear'
          handleSubmit={handleSubmit}
          actionPending={pendingCreate}
          onCancel={() => history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)}
        />
      )}
    </PaperContainer>
  )
}

export default OrganizerCreatePage
