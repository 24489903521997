import React from 'react'
import { useStyles } from './PaperContainer.style'
import { Paper, Typography, CircularProgress } from '@material-ui/core'

export const PaperContainer = ({ title, children, loading }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        {loading && <CircularProgress size={24} color='secondary' />}
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <div className={classes.main}>{children}</div>
    </Paper>
  )
}
