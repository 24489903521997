import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { showSnackbarSuccess, showSnackbarError } from 'views/utils/snackbar'
import {
  ConfirmationDialog,
  PreviewDialog,
  TableGridOrder
} from '~/views/shared'
import {
  assignPayment,
  refundPayment,
  loadPaymentsPaged,
  loadOrderHistoryPage
} from '~/state/modules/orders'

export const OrderActionsMenu = ({
  paymentId,
  status,
  orderId,
  transactionAmount
}) => {
  const dispatch = useDispatch()
  const { orderEvent, pendingHandlePayment, pendingOrders } = useSelector(
    (state) => state.orders
  )
  const userName = orderEvent[0]?.OriginUser?.FirstName
  const providerPaymentId = orderEvent[0]?.Order?.ProviderPaymentId
  const orderStatus = orderEvent[0]?.Order?.PaymentStatus?.toLowerCase()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openReason, setOpenReason] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [reason, setReason] = useState('')
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event) => {
    setReason(event.target.value.trim())
  }

  const cleanValues = () => {
    setOpenReason(false)
    setMessage('')
    setReason('')
    setSubject('')
    handleClose()
  }

  const handlePayment = async () => {
    try {
      if (subject === 'Asignar Pago') {
        await dispatch(assignPayment(orderId, paymentId, reason))
        showSnackbarSuccess('Pago asignado a la orden!')
      } else if (subject === 'Devolver Pago') {
        await dispatch(refundPayment(orderId, paymentId, reason))
        showSnackbarSuccess('Pago devuelto!')
      }
      await setTimeout(() => {
        dispatch(loadOrderHistoryPage(orderId))
        dispatch(
          loadPaymentsPaged(orderId, {
            searchParams: '',
            pageNumber: 0,
            orderBy: 'id',
            order: TableGridOrder.DESC
          })
        )
      }, 2500)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      cleanValues()
    }
  }

  const handleAction = (subject, message) => {
    handleClose()
    setMessage(message)
    setSubject(subject)
    setOpenConfirmDialog(true)
  }

  return (
    <div>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          disabled={providerPaymentId === paymentId || status !== 'approved'}
          onClick={() =>
            handleAction(
              'Devolver Pago',
              `Está por devolver el pago de ${transactionAmount}.`
            )
          }>
          Devolver Pago
        </MenuItem>
        <MenuItem
          disabled={providerPaymentId === paymentId || status === orderStatus}
          onClick={() =>
            handleAction(
              'Asignar Pago',
              `Está por realizar el cambio en la orden con el nuevo número de operación ${paymentId}.`
            )
          }>
          Asignar Pago
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={openConfirmDialog}
        handleConfirmation={() => {
          setOpenConfirmDialog(false)
          setOpenReason(true)
        }}
        onCancel={() => setOpenConfirmDialog(false)}
        title='¡ADVERTENCIA!'
        message={message}
      />
      <Dialog
        open={openReason}
        onClose={cleanValues}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <TextField
            label='Motivo'
            fullWidth
            autoFocus
            inputProps={{ maxLength: 250 }}
            placeholder='Ingrese una pequeña descripción de las causas o motivos por la que realiza esta acción.'
            onChange={handleChange}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cleanValues} color='primary'>
            Cancelar
          </Button>
          {subject === 'Devolver Pago' && (
            <Button
              onClick={() => setOpenPreview(true)}
              disabled={
                reason.replace(/(<([^>]+)>|&nbsp;)/gi, '').trim() === ''
              }
              color='secondary'
              variant='contained'>
              Vista Previa
            </Button>
          )}
          <Button
            onClick={handlePayment}
            color='primary'
            variant='contained'
            disabled={
              reason.length < 3 || pendingHandlePayment || pendingOrders
            }
            autoFocus>
            {pendingHandlePayment ? <CircularProgress size={24} /> : subject}
          </Button>
        </DialogActions>
      </Dialog>
      <PreviewDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        subject={subject}
        message={reason}
        userName={userName}
      />
    </div>
  )
}

export default OrderActionsMenu
