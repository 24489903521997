import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    display: 'block',
    marginTop: 1,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  field: {
    margin: theme.spacing(),
    '& .MuiInput-formControl': {
      marginTop: '20px'
    }
  },
  fieldSelect: {
    marginInline: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      height: 300,
      minWidth: 350
    }
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  actionsContainerTop: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: -theme.spacing(4),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  textButton: {
    textTransform: 'none',
    height: 50,
    marginTop: 10,
    marginInline: theme.spacing(1),
    marginBottom: 5,
    justifyContent: 'space-between',
    width: '100%'
  },
  fieldRowSelect: {
    marginBlock: 10
  },
  dialogs: {
    padding: 10
  },
  items: {
    fontSize: 'larger',
    marginBottom: 3,
    '&:hover': {
      backgroundColor: '#EAEDED'
    }
  },
  admissionAge: {
    width: 205,
    marginLeft: theme.spacing(1),
    marginBlock: theme.spacing(1)
  },
  warning: {
    marginTop: 8,
    alignItems: 'center',
    fontSize: 16,
    fontStyle: 'italic',
    '& svg': {
      fontSize: 30
    }
  },
  alertAccount: {
    maxWidth: 'fit-content',
    paddingBlock: 1,
    marginTop: 8
  }
}))

export default useStyles
