import React from 'react'
import {
  FormControl,
  InputAdornment,
  TextField,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useStyles } from '../EventTicketForm.style'
import { isNotNumber } from 'views/utils/functions'
import { COMMISSION_TYPE } from 'config/constants'

export const ComissionComponent = ({
  label,
  byPercent,
  setByPercent,
  amountName,
  percentName,
  helperTextAmount,
  helperTextPercent,
  helperTextVat,
  handleBlur,
  commission,
  commissionsMp,
  vatList,
  setChangeCommission,
  setFieldValue,
  values,
  error,
  calculateFields,
  isProducer = false,
  validateNaNValue,
  truncate,
  round
}) => {
  const classes = useStyles()

  const getAccreditationDays = (fee) => {
    const commissionMp = commissionsMp.find((e) => e.Fee === fee)
    return commissionMp.AccreditationDays
  }

  return (
    <div className={classes.containerInputs}>
      {commission.CommissionType === COMMISSION_TYPE.RESELLER && (
        <FormControlLabel
          className={classes.textCheckBox}
          control={
            <Checkbox
              checked={byPercent}
              onChange={(e) => setByPercent(e.target.checked)}
              name='ByPercent'
            />
          }
          label='Por porcentaje?'
        />
      )}
      <div>
        <TextField
          label={label}
          id='standard-start-adornment'
          style={{ width: 300 }}
          type='string'
          name={amountName}
          value={truncate(commission?.CommissionWithTaxAmount)}
          disabled={
            byPercent || commission.CommissionType !== COMMISSION_TYPE.RESELLER
          }
          onBlur={handleBlur}
          onChange={(e) => {
            calculateFields(
              e.target.value,
              'CommissionWithTaxAmount',
              values,
              setFieldValue,
              commission,
              byPercent
            )
            setFieldValue(amountName, e.target.value)
          }}
          onKeyPress={(e) => isNotNumber(e)}
          onKeyDown={(e) => isNotNumber(e)}
          className={`${classes.fieldCommission} ${classes.fieldCommissionAmount}`}
          helperText={
            commission.CommissionType !== COMMISSION_TYPE.RESELLER
              ? `(${validateNaNValue(
                  round(
                    commission?.Commission +
                      commission?.Commission * commission?.CommissionVat
                  )
                )}%)`
              : helperTextAmount
          }
          inputProps={{ maxLength: 8 }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          variant='standard'
        />
        {!commissionsMp ? (
          <FormControl
            variant='standard'
            className={`${classes.formControl} ${classes.formControlCommission}`}>
            <Input
              name={percentName}
              value={validateNaNValue(truncate(commission?.Commission))}
              error={Boolean(error)}
              type='string'
              onChange={(e) => {
                calculateFields(
                  e.target.value,
                  'Commission',
                  values,
                  setFieldValue,
                  commission,
                  byPercent
                )
                setFieldValue(
                  percentName,
                  validateNaNValue(parseFloat(e.target.value))
                )
              }}
              onBlur={handleBlur}
              disabled={
                (!byPercent &&
                  commission.CommissionType === COMMISSION_TYPE.RESELLER) ||
                isProducer
              }
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              className={`${classes.fieldCommission} ${classes.fieldCommissionPercent}`}
              aria-describedby='standard-weight-helper-text'
              onKeyPress={(e) => isNotNumber(e)}
              onKeyDown={(e) => isNotNumber(e)}
              inputProps={{
                'aria-label': 'weight',
                maxLength: 5
              }}
            />
            <FormHelperText
              id='standard-weight-helper-text'
              error={Boolean(error)}>
              {helperTextPercent}
            </FormHelperText>
          </FormControl>
        ) : (
          <FormControl
            variant='standard'
            className={`${classes.formControl} ${classes.formControlCommission}`}>
            <Select
              name={percentName}
              value={commission?.Commission}
              onChange={(e) => {
                setChangeCommission(true)
                setFieldValue(
                  'PaymentGatewayAccreditationDays',
                  getAccreditationDays(e.target.value)
                )
                calculateFields(
                  e.target.value,
                  'Commission',
                  values,
                  setFieldValue,
                  commission,
                  byPercent
                )
              }}
              onBlur={handleBlur}
              size='small'>
              {commissionsMp &&
                !commissionsMp.find(
                  (c) => c.Fee === commission?.Commission
                ) && (
                  <MenuItem value={commission?.Commission}>
                    {commission?.Commission}% (Comisión MP No Vigente)
                  </MenuItem>
                )}
              {commissionsMp &&
                commissionsMp.map((c) => (
                  <MenuItem key={c.Fee} value={c.Fee}>
                    {c.TypeFee}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText id='standard-weight-helper-text'>
              {helperTextPercent}
            </FormHelperText>
          </FormControl>
        )}
        {helperTextVat && (
          <FormControl className={classes.commissionVat}>
            <Select
              name='CommissionVat'
              value={commission?.CommissionVat || ''}
              onChange={(e) => {
                calculateFields(
                  e.target.value,
                  'CommissionVat',
                  values,
                  setFieldValue,
                  commission,
                  byPercent
                )
              }}
              onBlur={handleBlur}
              size='small'
              renderValue={(value) => `${value * 100}%`}>
              {vatList &&
                !vatList.find((c) => c === commission?.CommissionVat) && (
                  <MenuItem value={commission?.CommissionVat}>
                    {commission?.CommissionVat * 100}% (IVA No Vigente)
                  </MenuItem>
                )}
              {vatList &&
                vatList.map((c, i) => (
                  <MenuItem key={i} value={c}>
                    {c * 100}%
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{helperTextVat}</FormHelperText>
          </FormControl>
        )}
      </div>
    </div>
  )
}

export default ComissionComponent
