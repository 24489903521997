import React, { useCallback, useEffect, useState } from 'react'
import RuleForm from './RuleForm'

export const TicketRules = ({
  value,
  events,
  tickets,
  pendingEvents,
  pendingTickets,
  onRequestTicketsOfEvent,
  onChange
}) => {
  const [parsedValue, setParsedValue] = useState(null)

  const handleChange = useCallback(
    (values) => {
      onChange(JSON.stringify([values]))
    },
    [onChange]
  )

  useEffect(() => {
    if (parsedValue == null && value) {
      const valueObj = JSON.parse(value)
      setParsedValue(valueObj[0])
    }
  }, [value, parsedValue])

  return (
    <RuleForm
      values={parsedValue}
      events={events}
      tickets={tickets}
      pendingEvents={pendingEvents}
      pendingTickets={pendingTickets}
      onSubmit={handleChange}
      onRequestTicketsOfEvent={onRequestTicketsOfEvent}
    />
  )
}

export default TicketRules
