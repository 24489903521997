import * as types from './types'

const initialState = {
  pending: false,
  error: null,
  eventStatistics: null,
  ticketsStatistics: [],
  updateStatistics: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_EVENT_STATISTICS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        eventStatistics: null
      }
    case types.LOAD_EVENT_STATISTICS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        eventStatistics: action.eventStatistics
      }
    case types.LOAD_EVENT_STATISTICS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        eventStatistics: null
      }
    case types.LOAD_TICKETTYPE_STATISTICS_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null
      }
    case types.LOAD_TICKETTYPE_STATISTICS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        ticketsStatistics: action.ticketsStatistics
      }
    case types.LOAD_TICKETTYPE_STATISTICS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        ticketsStatistics: []
      }
    case types.UPDATE_STATISTICS_ATTEMPT:
      return {
        ...state,
        error: null,
        updateStatistics: null
      }
    case types.UPDATE_STATISTICS_SUCCESS:
      return {
        ...state,
        error: null,
        updateStatistics: action.eventStatistics
      }
    case types.UPDATE_STATISTICS_FAILURE:
      return {
        ...state,
        error: action.error,
        updateStatistics: null
      }
    case types.RESET:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
