export const LOAD_ORDERS_LIST_ATTEMPT = 'admin/orders/LOAD_ORDERS_LIST_ATTEMPT'
export const LOAD_ORDERS_LIST_SUCCESS = 'admin/orders/LOAD_ORDERS_LIST_SUCCESS'
export const LOAD_ORDERS_LIST_FAILURE = 'admin/orders/LOAD_ORDERS_LIST_FAILURE'

export const LOAD_TOTAL_TICKETS_ATTEMPT =
  'admin/orders/LOAD_TOTAL_TICKETS_ATTEMPT'
export const LOAD_TOTAL_TICKETS_SUCCESS =
  'admin/orders/LOAD_TOTAL_TICKETS_SUCCESS'
export const LOAD_TOTAL_TICKETS_FAILURE =
  'admin/orders/LOAD_TOTAL_TICKETS_FAILURE'

export const LOAD_ATTEMPT = 'admin/orders/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'admin/orders/LOAD_SUCCESS'
export const LOAD_FAILURE = 'admin/orders/LOAD_FAILURE'

export const LOAD_ORDERS_BY_TICKET_ATTEMPT =
  'admin/orders/LOAD_ORDERS_BY_TICKET_ATTEMPT'
export const LOAD_ORDERS_BY_TICKET_SUCCESS =
  'admin/orders/LOAD_ORDERS_BY_TICKET_SUCCESS'
export const LOAD_ORDERS_BY_TICKET_FAILURE =
  'admin/orders/LOAD_ORDERS_BY_TICKET_FAILURE'

export const CREATE_ATTEMPT = 'admin/orders/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'admin/orders/CREATE_SUCCESS'
export const CREATE_FAILURE = 'admin/orders/CREATE_FAILURE'

export const UPDATE_ATTEMPT = 'admin/orders/UPDATE_ATTEMPT'
export const UPDATE_SUCCESS = 'admin/orders/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'admin/orders/UPDATE_FAILURE'

export const DELETE_ATTEMPT = 'admin/orders/DELETE_ATTEMPT'
export const DELETE_SUCCESS = 'admin/orders/DELETE_SUCCESS'
export const DELETE_FAILURE = 'admin/orders/DELETE_FAILURE'

export const LOAD_ORDERS_EVENT_ATTEMPT =
  'admin/orders/LOAD_ORDERS_EVENT_ATTEMPT'
export const LOAD_ORDERS_EVENT_SUCCESS =
  'admin/orders/LOAD_ORDERS_EVENT_SUCCESS'
export const LOAD_ORDERS_EVENT_FAILURE =
  'admin/orders/LOAD_ORDERS_EVENT_FAILURE'

export const LOAD_ORDERS_EVENT_PAGED_ATTEMPT =
  'admin/orders/LOAD_ORDERS_EVENT_PAGED_ATTEMPT'
export const LOAD_ORDERS_EVENT_PAGED_SUCCESS =
  'admin/orders/LOAD_ORDERS_EVENT_PAGED_SUCCESS'
export const LOAD_ORDERS_EVENT_PAGED_FAILURE =
  'admin/orders/LOAD_ORDERS_EVENT_PAGED_FAILURE'

export const LOAD_ORDERS_HISTORY_ATTEMPT =
  'admin/orders/LOAD_ORDERS_HISTORY_ATTEMPT'
export const LOAD_ORDERS_HISTORY_SUCCESS =
  'admin/orders/LOAD_ORDERS_HISTORY_SUCCESS'
export const LOAD_ORDERS_HISTORY_FAILURE =
  'admin/orders/LOAD_ORDERS_HISTORY_FAILURE'

export const LOAD_ORDER_HISTORY_PAGED_ATTEMPT =
  'admin/orders/LOAD_ORDER_HISTORY_PAGED_ATTEMPT'
export const LOAD_ORDER_HISTORY_PAGED_SUCCESS =
  'admin/orders/LOAD_ORDER_HISTORY_PAGED_SUCCESS'
export const LOAD_ORDER_HISTORY_PAGED_FAILURE =
  'admin/orders/LOAD_ORDER_HISTORY_PAGED_FAILURE'

export const LOAD_PAYMENTS_PAGED_ATTEMPT =
  'admin/orders/LOAD_PAYMENTS_PAGED_ATTEMPT'
export const LOAD_PAYMENTS_PAGED_SUCCESS =
  'admin/orders/LOAD_PAYMENTS_PAGED_SUCCESS'
export const LOAD_PAYMENTS_PAGED_FAILURE =
  'admin/orders/LOAD_PAYMENTS_PAGED_FAILURE'

export const LOAD_ORDER_DETAILS_ATTEMPT =
  'admin/orders/LOAD_ORDER_DETAILS_ATTEMPT'
export const LOAD_ORDER_DETAILS_SUCCESS =
  'admin/orders/LOAD_ORDER_DETAILS_SUCCESS'
export const LOAD_ORDER_DETAILS_FAILURE =
  'admin/orders/LOAD_ORDER_DETAILS_FAILURE'

export const LOAD_TICKETS_PER_ORDER_ATTEMPT =
  'admin/orders/LOAD_TICKET_PER_ORDER_ATTEMPT'
export const LOAD_TICKETS_PER_ORDER_SUCCESS =
  'admin/orders/LOAD_TICKET_PER_ORDER_SUCCESS'
export const LOAD_TICKETS_PER_ORDER_FAILURE =
  'admin/orders/LOAD_TICKET_PER_ORDER_FAILURE'

export const REFRESH_ORDER_MP_SUCCESS = 'admin/orders/REFRESH_ORDER_MP_SUCCESS'
export const REFRESH_ORDER_MP_FAILURE = 'admin/orders/REFRESH_ORDER_MP_FAILURE'
export const REFRESH_ORDER_MP_ATTEMPT = 'admin/orders/REFRESH_ORDER_MP_ATTEMPT'

export const CANCEL_ORDER_ATTEMPT = 'admin/orders/CANCEL_ORDER_ATTEMPT'
export const CANCEL_ORDER_SUCCESS = 'admin/orders/CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_FAILURE = 'admin/orders/CANCEL_ORDER_FAILURE'

export const REFUND_ORDER_ATTEMPT = 'admin/orders/REFUND_ORDER_ATTEMPT'
export const REFUND_ORDER_SUCCESS = 'admin/orders/REFUND_ORDER_SUCCESS'
export const REFUND_ORDER_FAILURE = 'admin/orders/REFUND_ORDER_FAILURE'

export const TRANSFER_ORDER_ATTEMPT = 'admin/orders/TRANSFER_ORDER_ATTEMPT'
export const TRANSFER_ORDER_SUCCESS = 'admin/orders/TRANSFER_ORDER_SUCCESS'
export const TRANSFER_ORDER_FAILURE = 'admin/orders/TRANSFER_ORDER_FAILURE'

export const MANUAL_UPDATE_PAYMENT_STATE_SUCCESS =
  'admin/orders/CHANGE_STATUS_MANUALLY_SUCCESS'
export const MANUAL_UPDATE_PAYMENT_STATE_FAILURE =
  'admin/orders/CHANGE_STATUS_MANUALLY_FAILURE'
export const MANUAL_UPDATE_PAYMENT_STATE_ATTEMPT =
  'admin/orders/CHANGE_STATUS_MANUALLY_ATTEMPT'

export const LOAD_ORDERS_USER_ATTEMPT = 'admin/orders/LOAD_ORDERS_USER_ATTEMPT'
export const LOAD_ORDERS_USER_SUCCESS = 'admin/orders/LOAD_ORDERS_USER_SUCCESS'
export const LOAD_ORDERS_USER_FAILURE = 'admin/orders/LOAD_ORDERS_USER_FAILURE'

export const LOAD_ORDERS_PAGED_ATTEMPT =
  'admin/orders/LOAD_ORDERS_PAGED_ATTEMPT'
export const LOAD_ORDERS_PAGED_SUCCESS =
  'admin/orders/LOAD_ORDERS_PAGED_SUCCESS'
export const LOAD_ORDERS_PAGED_FAILURE =
  'admin/orders/LOAD_ORDERS_PAGED_FAILURE'

export const LOAD_ORDERS_USER_PAGED_ATTEMPT =
  'admin/orders/LOAD_ORDERS_USER_PAGED_ATTEMPT'
export const LOAD_ORDERS_USER_PAGED_SUCCESS =
  'admin/orders/LOAD_ORDERS_USER_PAGED_SUCCESS'
export const LOAD_ORDERS_USER_PAGED_FAILURE =
  'admin/orders/LOAD_ORDERS_USER_PAGED_FAILURE'

export const LOAD_TICKETS_USER_ATTEMPT =
  'admin/orders/LOAD_TICKETS_USER_ATTEMPT'
export const LOAD_TICKETS_USER_SUCCESS =
  'admin/orders/LOAD_TICKETS_USER_SUCCESS'
export const LOAD_TICKETS_USER_FAILURE =
  'admin/orders/LOAD_TICKETS_USER_FAILURE'

export const SEND_GIFT_TICKET_ATTEMPT = 'admin/orders/SEND_GIFT_TICKET_ATTEMPT'
export const SEND_GIFT_TICKET_SUCCESS = 'admin/orders/SEND_GIFT_TICKET_SUCCESS'
export const SEND_GIFT_TICKET_FAILURE = 'admin/orders/SEND_GIFT_TICKET_AILURE'

export const POST_QR_ATTEMPT = 'admin/orders/POST_QR_ATTEMPT'
export const POST_QR_SUCCESS = 'admin/orders/POST_QR_SUCCESS'
export const POST_QR_FAILURE = 'admin/orders/POST_QR_FAILURE'

export const ASSIGN_PAYMENT_ATTEMPT = 'admin/orders/ASSIGN_PAYMENT_ATTEMPT'
export const ASSIGN_PAYMENT_SUCCESS = 'admin/orders/ASSIGN_PAYMENT_SUCCESS'
export const ASSIGN_PAYMENT_FAILURE = 'admin/orders/ASSIGN_PAYMENT_FAILURE'

export const REFUND_PAYMENT_ATTEMPT = 'admin/orders/REFUND_PAYMENT_ATTEMPT'
export const REFUND_PAYMENT_SUCCESS = 'admin/orders/REFUND_PAYMENT_SUCCESS'
export const REFUND_PAYMENT_FAILURE = 'admin/orders/REFUND_PAYMENT_FAILURE'

export const LOAD_TICKETS_AMOUNT_ATTEMPT =
  'admin/orders/LOAD_TICKETS_AMOUNT_ATTEMPT'
export const LOAD_TICKETS_AMOUNT_SUCCESS =
  'admin/orders/LOAD_TICKETS_AMOUNT_SUCCESS'
export const LOAD_TICKETS_AMOUNT_FAILURE =
  'admin/orders/LOAD_TICKETS_AMOUNT_FAILURE'

export const RECOVER_TICKETS_ATTEMPT = 'admin/orders/RECOVER_TICKETS_ATTEMPT'
export const RECOVER_TICKETS_SUCCESS = 'admin/orders/RECOVER_TICKETS_SUCCESS'
export const RECOVER_TICKETS_FAILURE = 'admin/orders/RECOVER_TICKETS_FAILURE'

export const GENERATE_BILLING_ATTEMPT = 'admin/orders/GENERATE_BILLING_ATTEMPT'
export const GENERATE_BILLING_SUCCESS = 'admin/orders/GENERATE_BILLING_SUCCESS'
export const GENERATE_BILLING_FAILURE = 'admin/orders/GENERATE_BILLING_FAILURE'

export const RESET = 'admin/orders/RESET'

export const SET_FILTERS = 'admin/orders/SET_FILTERS'
export const SET_FILTERS_SEARCH = 'admin/orders/SET_FILTERS_SEARCH'
