import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Typography,
  CircularProgress,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import {
  PaperContainer,
  createColumn,
  createCustomColumn,
  TableGrid,
  TableGridOrder
} from '~views/shared'
import { useStyles } from './UserRolesPage.style'
import { loadAllRoles, loadOrganizersPaged } from 'state/modules/profiles'
import { ROLES } from 'config/constants'
import { Check, Clear } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const makeColumns = (classes) => {
  return [
    createColumn('OrganizerName', 'Nombre de la Productora'),
    createCustomColumn(
      (c) =>
        c.Roles.some((r) => r.Name === ROLES.PRODUCER) ? (
          <Check className={classes.checkProducer} />
        ) : (
          <Typography className={classes.textProducer}>-</Typography>
        ),
      'Productor'
    ),
    createCustomColumn(
      (c) =>
        c.Roles.some((r) => r.Name === ROLES.RESELLER) ? (
          <Check className={classes.checkReseller} />
        ) : (
          <Typography className={classes.textReseller}>-</Typography>
        ),
      'RRPP'
    ),
    createCustomColumn(
      (c) =>
        c.Roles.some((r) => r.Name === ROLES.WITHDRAWPOINT) ? (
          <Check className={classes.checkWithdrawpoint} />
        ) : (
          <Typography className={classes.textWithdrawpoint}>-</Typography>
        ),
      'Controlador de Acceso'
    )
  ]
}
const makeFooterActions = () => []

export const UserRolesPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user, pendingUser, reqParams, pagedList, pendingOrganizers } =
    useSelector((state) => state.profiles)
  const rolesArray = user?.Roles?.split(',') || []
  const [reseller, setReseller] = useState(null)
  const history = useHistory()

  const createLabelRole = (label, checked) => {
    return (
      <div className={classes.containerRoles}>
        <Checkbox
          className={`${checked ? classes.checked : classes.notChecked}`}
          checked={checked}
          disabled
          icon={<Clear />}
          checkedIcon={<Check />}
        />
        <Typography variant='h6' className={classes.label}>
          {label}
        </Typography>
      </div>
    )
  }

  const createRow = (label, value) => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell className={classes.tableCell}>{value}</TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(loadAllRoles(match.params.userId))
      if (response?.Reseller != null) {
        setReseller(response.Reseller)
      }
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(
        loadOrganizersPaged(match.params.userId, {
          searchParams: '',
          pageNumber: 0,
          orderBy: 'OrganizerId',
          order: TableGridOrder.DESC
        })
      )
    }

    fetchData().then()
  }, [dispatch, match])

  return pendingUser ? (
    <div className={classes.loadingCard}>
      <CircularProgress size={24} style={{ marginRight: '1rem' }} />
      <Typography variant={'caption'}>Cargando...</Typography>
    </div>
  ) : (
    <PaperContainer title={`Roles de usuario de ${user?.User?.FullName}`}>
      {pendingOrganizers ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <>
          {createLabelRole('Administrador', rolesArray?.includes(ROLES.ADMIN))}
          {createLabelRole('Productor', rolesArray?.includes(ROLES.PRODUCER))}
          {createLabelRole('RRPP', rolesArray?.includes(ROLES.RESELLER))}
          {createLabelRole(
            'Controlador de Acceso',
            rolesArray?.includes(ROLES.WITHDRAWPOINT)
          )}
          {reseller != null && (
            <>
              <hr className={classes.separator} />
              <Typography
                variant='h6'
                style={{ marginLeft: 10, marginBottom: 10 }}>
                El usuario se encuentra como RRPP
              </Typography>
              <Table>
                <TableBody>
                  {createRow('Nombre de Usuario', reseller?.UserName)}
                  {createRow('Nombre a Mostrar', reseller?.DisplayName)}
                  {createRow('¿Está Activo?', reseller?.Active ? 'Si' : 'No')}
                  {createRow(
                    'CUIT',
                    reseller?.Cuit == null || reseller?.Cuit === ''
                      ? '-'
                      : reseller?.Cuit
                  )}
                  {createRow(
                    'Número de Cuenta Bancaria',
                    reseller?.BankAccountNumber == null ||
                      reseller?.BankAccountNumber === ''
                      ? '-'
                      : reseller?.BankAccountNumber
                  )}
                  {createRow(
                    'Email de MercadoPago',
                    reseller?.MercadoPagoEmail == null ||
                      reseller?.MercadoPagoEmail === ''
                      ? '-'
                      : reseller?.MercadoPagoEmail
                  )}
                  {createRow(
                    '¿Tiene PIN Generado?',
                    reseller?.HasPINGenerated ? 'Si' : 'No'
                  )}
                  {createRow(
                    '¿Es RRPP de TicketPass?',
                    reseller?.SellForTicketPass ? 'Si' : 'No'
                  )}
                </TableBody>
              </Table>
              <br></br>
            </>
          )}
          {pagedList?.items == null ||
            (pagedList?.items?.length > 0 && (
              <TableGrid
                title='Vínculo con Productoras'
                columns={makeColumns(classes)}
                footerActions={makeFooterActions()}
                searchForm={() => {}}
                searchParams={''}
                loadItemsPage={() => {}}
                isLoading={pendingOrganizers}
                pagedList={pagedList}
                keyName='Id'
                pageSize={reqParams?.pageSize || 5}
                order={reqParams?.order || TableGridOrder.ASC}
                orderBy={reqParams?.orderBy || 'CreatedDate'}
                useNewFormat
                hideFooter
              />
            ))}
          <div className={classes.actionsContainer}>
            <Button onClick={() => history.goBack()} color='primary'>
              Volver
            </Button>
          </div>
        </>
      )}
    </PaperContainer>
  )
}

export default UserRolesPage
