import BaseProxy from './BaseProxy'
import axios from 'axios'

export class OrdersProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/orders`
  }

  async getTicketsByEvent(id) {
    const response = await axios.get(`${this._baseUrl}/event/${id}/tickets`)

    return response.data
  }

  async getOrdersByTicket(idEvent, idTicket) {
    const response = await axios.get(
      `${this._baseUrl}/event/${idEvent}/tickettype/${idTicket}/tickets`
    )

    return response.data
  }

  getTotalTickets(ticketsByEvent) {
    let totalTickets = 0
    ticketsByEvent &&
      ticketsByEvent.forEach((order) => {
        totalTickets += order.Tickets?.length
      })
    return totalTickets
  }

  getTotalWithdrawn(ticketsByEvent) {
    let totalWithdrawn = 0
    ticketsByEvent &&
      ticketsByEvent.forEach((order) => {
        order.Tickets.forEach((ticket) => {
          if (ticket.Withdrawn) totalWithdrawn += 1
        })
      })
    return totalWithdrawn
  }

  async put(orderObj) {
    const response = await axios.post(
      `${this._baseUrl}/withdraw/ticket`,
      orderObj
    )

    return response.data
  }

  async getOrdersByEvent(id) {
    const response = await axios.get(`${this._baseUrl}/event/${id}/sales/list`)
    return response.data
  }

  async getOrdersByEventByStatus(eventId, paymentStatus, searchKey) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/${paymentStatus}/list/${searchKey}`
    )
    return response.data
  }

  async getOrdersByEventByStatusPaged(
    eventId,
    paymentStatus,
    searchParams,
    pageNumber,
    pageSize,
    orderBy,
    order
  ) {
    const response = await axios.get(
      `${
        this._baseUrl
      }/event/${eventId}/${paymentStatus}/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )
    return response.data
  }

  async getHistoryByOrder(orderId) {
    const response = await axios.get(`${this._baseUrl}/${orderId}/history`)
    return response.data
  }

  async getOrderById(orderId) {
    const response = await axios.get(`${this._baseUrl}/search/${orderId}`)
    return response.data
  }

  async getTicketPerOrder(orderId) {
    const response = await axios.get(
      `${this._baseUrl}/search/tickets/by/orderid/${orderId}/list`
    )
    return response.data
  }

  async cancelOrder(orderId) {
    const response = await axios.post(`${this._baseUrl}/${orderId}/cancel`)
    return response.data
  }

  async refreshOrder(orderId) {
    const response = await axios.post(`${this._baseUrl}/${orderId}/refresh`)
    return response.data
  }

  async refundOrder(orderId, changeReason) {
    const response = await axios.post(`${this._baseUrl}/v2/${orderId}/refund`, {
      changeReason
    })
    return response.data
  }

  async transferOrder(orderId, userId) {
    const payload = {
      TargetUserId: userId
    }
    const response = await axios.post(
      `${this._baseUrl}/${orderId}/transfer`,
      payload
    )
    return response.data
  }

  async manualUpdatePaymentStatusOrder(orderId, paymentStatus, changeReason) {
    const payload = {
      changeReason
    }
    const response = await axios.post(
      `${this._baseUrl}/v2/${orderId}/manual/update/${paymentStatus}`,
      payload
    )
    return response.data
  }

  async getOrdersUser(userId) {
    const response = await axios.get(
      `${this._baseUrl}/search/by/userid/${userId}/list`
    )
    return response.data
  }

  async getOrdersPaged(
    payload,
    searchParams,
    pageNumber,
    pageSize,
    orderBy,
    order
  ) {
    const response = await axios.get(`${this._baseUrl}/manager/search/paged`, {
      params: {
        ...payload,
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      }
    })

    return response.data
  }

  async getTicketsUser(userId) {
    const response = await axios.get(
      `${this._baseUrl}/search/tickets/by/userId/${userId}/list`
    )

    return response.data.sort((a, b) => b.EventDate.localeCompare(a.EventDate))
  }

  async sendGiftTicket(giftTicket) {
    const response = await axios.post(`${this._baseUrl}/gift`, giftTicket)

    return response.data
  }

  async getPaymentsByOrder(orderId) {
    const response = await axios.get(
      `${this._baseUrl}/${orderId}/searchPayments`
    )
    return response.data
  }

  async assignPayment(orderId, providerPaymentId, changeReason) {
    const response = await axios.post(
      `${this._baseUrl}/${orderId}/payment/${providerPaymentId}/assign`,
      {
        ChangeReason: changeReason
      }
    )

    return response.data
  }

  async refundPayment(orderId, providerPaymentId, changeReason) {
    const response = await axios.post(
      `${this._baseUrl}/${orderId}/payment/${providerPaymentId}/refund`,
      {
        ChangeReason: changeReason
      }
    )

    return response.data
  }

  async getTicketsAmount(eventId, status) {
    const response = await axios.get(
      `${this._baseUrl}/event/${eventId}/${status}/count`
    )

    return response.data
  }

  async postQR(code) {
    const payload = {
      Code: code
    }
    const response = await axios.post(`${this._baseUrl}/sale/remote`, payload)
    return response.data
  }

  async recoverTickets(orderId) {
    const response = await axios.post(
      `${this._baseUrl}/${orderId}/recover/transferred`
    )
    return response.data
  }

  async generateBilling(orderId, type, billingDate) {
    const response = await axios.put(
      `${this._baseUrl}/${orderId}/billing/generate/${type}/${billingDate}`
    )
    return response.data
  }
}

export default OrdersProxy
