import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { sendToAll, reset } from '~/state/modules/notifications'
import { PaperContainer, AppContainer } from '~/views/shared'
import { NotificationsToAllForm } from './NotificationsToAllForm'
import { showSnackbarError } from 'views/utils/snackbar'

export const NotificationsToAllPage = () => {
  const { actionPending, actionSuccess } = useSelector(
    (state) => state.notifications
  )
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false)
  const [valuesState, setValuesState] = useState()
  const formikRef = useRef()

  const handleCloseConfirmation = () => {
    setOpenDialogConfirmation(false)
  }

  const handleClose = () => {
    dispatch(reset())
    setOpen(false)
  }

  const handleSubmit = async (values) => {
    await setValuesState(values)
    setOpenDialogConfirmation(true)
  }

  const handleSubmitConfirmation = async () => {
    setOpenDialogConfirmation(false)
    try {
      await dispatch(sendToAll(valuesState))
      formikRef.current.resetForm()
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const values = {
    Title: '',
    Message: ''
  }

  return (
    <AppContainer>
      <PaperContainer title='Enviar a Todos'>
        <NotificationsToAllForm
          values={values}
          formikRef={formikRef}
          actionText='Enviar'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
        />
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>Notificación Enviada!</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogConfirmation}>
        <DialogTitle>Envío de notificación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Título: '}
            {valuesState?.Title}
          </DialogContentText>
          <DialogContentText>
            {'Mensaje: '}
            {valuesState?.Message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={handleSubmitConfirmation}
            color='primary'
            disabled={actionPending}
            autoFocus>
            {actionPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Enviar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </AppContainer>
  )
}

export default NotificationsToAllPage
