import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  CircularProgress
} from '@material-ui/core'
import { MoreVert, Update, Edit } from '@material-ui/icons'
import { refreshToken, updateCommission } from '~/state/modules/accounts'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { CommissionFormDialog } from 'views/pages/OrganizersPage/AccountsPage/CommissionFormDialog'

export const MpActionMenu = ({ mpId, commission, vat, changeCommissions }) => {
  const dispatch = useDispatch()
  const { pendingRefresh, pendingUpdate } = useSelector(
    (state) => state.accounts
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [account, setAccount] = useState()
  const [open, setOpen] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRefreshToken = async () => {
    try {
      await dispatch(refreshToken(mpId))
      showSnackbarSuccess('Se ha actualizado la cuenta correctamente!')
      handleClose()
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleUpdateCommission = async (updatedAccount) => {
    try {
      updatedAccount.Commission = updatedAccount.Commission / 100
      updatedAccount.Vat = updatedAccount.Vat / 100
      await dispatch(updateCommission(updatedAccount))
      showSnackbarSuccess('Se ha actualizado la comisión correctamente!')
      changeCommissions(updatedAccount.Commission, updatedAccount.Vat)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='customized-menu'
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => handleRefreshToken()}
          disabled={pendingRefresh}>
          <ListItemIcon style={{ marginRight: -20 }}>
            {pendingRefresh ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              <Update fontSize='small' />
            )}
          </ListItemIcon>
          Actualizar Token
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            setAccount({
              Id: mpId,
              Vat: vat,
              Commission: commission
            })
            setOpen(true)
          }}
          disabled={pendingUpdate}>
          <ListItemIcon style={{ marginRight: -20 }}>
            <Edit fontSize='small' />
          </ListItemIcon>
          Editar Comisión
        </MenuItem>
      </Menu>
      <CommissionFormDialog
        open={open}
        loading={pendingUpdate}
        handleSubmit={handleUpdateCommission}
        onCancel={() => setOpen(false)}
        values={account}
      />
    </>
  )
}

export default MpActionMenu
